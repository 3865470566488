import classes from "./media_manager_sidebar.module.scss";
import { BiEditAlt, BiRotateLeft } from "react-icons/bi";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { mediaManager } from "@common-reducers/MediaManagerReducer";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import Stack from "@mui/material/Stack";

const MediaManagerSideBar = () => {
    const dispatch = useAppDispatch()
    const showVersions = useAppSelector((state) => state.MediaManagerReducer.showVersionTab);
    const showEdit = useAppSelector((state) => state.MediaManagerReducer.showEditTab);
    const { taskId, columnId } = useAppSelector((state) => state.MediaManagerReducer);


    function toggleVersions(showVersions) {
        dispatch(
            mediaManager.setVersionTab(showVersions)
        );
    }

    function toggleEdit(showEdit) {
        dispatch(
            mediaManager.setEditTab(showEdit)
        );
    }

    return (
        <Stack spacing={3} className={classes.mediaSideBar}>
            <span className={`${showVersions ? classes.versionsSelected : ''}`} onClick={() => toggleVersions(!showVersions)}><BiRotateLeft style={{ fontSize: '30px' }} />Versions</span>

            <span className={`${showEdit ? classes.versionsSelected : ''}`} onClick={() => toggleEdit(!showEdit)}><BiEditAlt style={{ fontSize: '30px' }} />Edit</span>

        </Stack>
    )
}

export default MediaManagerSideBar