import { useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ onSearch }) => {
  const { t } = useTranslation(namespace);
  const [searchQuery, setSearchQuery] = useState('');
  const inputTheme = {
    width: '100%',
    ".MuiFormControl-root": {
      width: '100%'
    },
    ".MuiInputBase-root": {
      background: 'var(--default-background)',
      border: '1px solid #DEEEF2',
      borderRadius: '8px',
      '& .MuiInputBase-input': {
        padding: '6.5px 10px',
        fontSize: '14px'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        boxShadow: '2px 6px 12px rgba(80, 187, 238, 0.0625)'
      },
    }
  }
  return (
    <TextField
      sx={inputTheme}
      value={searchQuery}
      placeholder={t('searchInput.filterColumns')}
      onChange={(e) => {
        setSearchQuery(e.target.value);
        onSearch(e.target.value);
      }}
      InputProps={{
        endAdornment: searchQuery === '' ? <SearchIcon sx={{ fontSize: 16 }} /> : <CloseIcon onClick={() => {
          setSearchQuery('');
          onSearch('');
        }}
          sx={{ fontSize: 12, cursor: 'pointer' }}
        />
      }}
    />
  );
};

export default SearchInput;
