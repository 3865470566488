import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import { IconButton } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function StopSequence({ localChatGPTState, setLocalChatGPTState }: Props) {

    const addStopSequenceInput = () => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                stopSequence: [...(prev?.advanced?.stopSequence ?? []), '']
            }
        }));
    };

    const handleClickRemoveStopSequenceInput = (index: number) => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                stopSequence: prev?.advanced?.stopSequence.filter((_, i) => i !== index)
            }
        }));
    };

    const stopSequenceInputUI = (stopS, index) => <>
        <div key={`ss_${index}`} style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            {title(`Stop Sequence ${index + 1}`, { marginTop: '20px', })}
            <IconButton sx={{
                zIndex: 2,
                width: '30px',
                height: '30px',
                borderRadius: '8px',
                backgroundColor: '#FEE7E7',
                color: 'red',
                ".MuiSvgIcon-root": {
                    width: '20px',
                    height: '20px',
                }
            }} onClick={() => handleClickRemoveStopSequenceInput(index)}><RemoveCircle /></IconButton>
        </div>

        <BotInputField
            value={stopS ?? ''}
            onChange={(newValue) => {
                if (newValue.length <= 100) {
                    setLocalChatGPTState({
                        ...localChatGPTState,
                        advanced: {
                            ...localChatGPTState.advanced,
                            stopSequence: localChatGPTState?.advanced?.stopSequence.map((sS, i) => {
                                if (i === index) {
                                    return newValue
                                }
                                return sS
                            })
                        }
                    })
                }
            }}
        />

        <span style={{
            fontSize: '12px',
            color: stopS?.length === 100 ? 'red' : '#666666',
            marginTop: '10px',
            display: 'block',
        }}>
            {stopS?.length ?? 0}/100
        </span>
    </>

    const addStopSequenceButton = () => {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
            }}>
                <BTbotbutton styleType='dashed'
                    onClick={addStopSequenceInput}
                    disabled={localChatGPTState?.advanced?.stopSequence?.length >= 4}
                >
                    Add stop sequence
                </BTbotbutton>
            </div>
        )
    }

    return (
        <>
            {title('Stop Sequence', { marginTop: '20px', })}
            <div>
                {(localChatGPTState?.advanced?.stopSequence ?? []).map((stopS, index) => {
                    return stopSequenceInputUI(stopS, index)
                })}

                {addStopSequenceButton()}

                {secondaryTitleWithMaybeLink('A maximum of 4 sequences (100 characters each) can be specified to signal OpenAI to stop generating further text. The returned text will exclude these stop sequences', {})}
            </div>
        </>
    );
}
