import BoardsLoader from '@common-components/tasks-panel/recents-boards/boards-loader';
import { User } from '@common-models/user';
import { Stack } from '@mui/material';
import { selectUser } from '@common-reducers/UserSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

export type RedirectCallback = (user: User) => string;

export interface GuardedRouteProps {
    element: JSX.Element;
    auth?: boolean;
    redirect?: string | RedirectCallback;
    redirectBack?: boolean;
    role?: string;
}

export default function GuardedRoute({
    element,
    auth,
    redirect,
    role,
    redirectBack = true
}: GuardedRouteProps) {
    // Please do not delete this line as it enables the page to refresh without any exceptions from translation.
    useTranslation(namespace);
    const authenticating = useAppSelector((state) => state.UserReducer.authenticating);
    const user = useAppSelector(selectUser);
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const currentRoute = window.location.pathname
    const finalRedirect = useMemo(() => (searchParams.get('redirect') ?? redirect) as string, [redirect, searchParams]);

    if (authenticating && currentRoute !== '/login' && currentRoute !== '/signup') {
        return (
            <Stack sx={{ width: '100vw', height: '100vh' }} justifyContent="center" alignItems="center">
                <BoardsLoader />
            </Stack>
        );
    }

    if ((auth && !user) || (auth === false && user) || (role && user?.role !== role)) {
        let search = '';
        if (redirectBack) {
            searchParams.set('redirect', window.location.pathname + window.location.search);
            search = `?${searchParams.toString()}`;
        }

        let _redirect = typeof redirect === 'function'
            ? redirect(new User(user))
            : finalRedirect ?? '/login';

        if (window.location.pathname === _redirect) {
            search = '/tasks';
        }

        const url = new URL(_redirect + search, window.location.origin);

        return window.location.pathname !== _redirect ? (
            <Navigate
                to={{
                    pathname: url.pathname,
                    search: url.search,
                }}
                replace={true}
            />
        ) : element;
    }

    return element;
}
