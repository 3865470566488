import RowStack from "@common-components/common/row-stack/RowStack";
import { Box } from "@mui/material";
import { calculatedName, extractPhoneNumber, selectWhatsappContactById } from "@common-reducers/WhatsAppSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { useMemo } from "react";
import { CellProps } from "../cell-interface";
import { PhoneDisplay } from "../phone-number-cell/phone-display";
import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";

export const WhatsappIdScrollingCell = ({
    cellValue,
    taskId,
    columnId,
}: CellProps) => {
    const contact = useAppSelector((state) => selectWhatsappContactById(state, cellValue));
    const phoneNumber = useMemo(() => extractPhoneNumber(cellValue), [cellValue]);
    const contactName = useMemo(() => contact ? calculatedName(contact) : '', [contact]);
    const isGroup = useMemo(() => cellValue?.includes && cellValue?.includes('@g.us'), [cellValue]);

    return (

        <RowStack
            sx={{
                px: 1,
                overflow: 'hidden',
            }}
            flex={1}
            justifyContent={'center'}
        >
            {cellValue ?
                <>
                    <ContactAvatar
                        sx={{ mr: 1 }}
                        contactId={cellValue}
                        size={24}
                        textSize={12}
                        shouldGetProfilePic={false}
                    />
                    {contactName && <Box sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        {contactName}
                    </Box>}

                    {!contactName && !isGroup && <PhoneDisplay phone={phoneNumber} />}
                </> :
                <Box sx={{ fontStyle: 'italic', fontSize: 12, width: 1, textAlign: 'center' }}>
                    Click to add a contact
                </Box>}
        </RowStack>

    )
}