import { useState, useCallback } from "react";
import {
    Button,
    Menu,
    MenuItem,
    Divider,
    Grid,
    Typography,
    Stack,
    Tooltip
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { Workspace } from "@common-models/workspace";
import { workspaceSelectByQuery } from "@common-reducers/DBServiceThunks";
import { selectCurrentWorkspace } from "@common-reducers/UserSelectors";
import { useRouter } from "@common-reducers/hooks/use-router";
import { switchWorkspace } from "@common-reducers/UserThunks";
import { isBoardPanelDrawerOpen } from '@common-reducers/BoardsSelectors';
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";
import { appConfig } from "@common-config/app.config";

function WorkspaceDropDown({isSupportMode = false}) {
    const { t } = useTranslation(namespace);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useAppDispatch()
    const userId = useAppSelector((state) => state.UserReducer.userId);

    const router = useRouter();
    const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
    const [showTooltip, setShowTooltip] = useState(true);
    const handleClick = (event) => {
        setShowTooltip(false);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const workspace = useAppSelector(selectCurrentWorkspace);

    const userWorkspaces: Workspace[] = useAppSelector((state) =>
        workspaceSelectByQuery(state.DBWorkspaceReducer, { deleted: { $ne: true }})
    );
    const handleCloseSwitchWorkspace = useCallback(async (workspaceId?: string) => {

        if (isSupportMode){
            const userUrl = `${appConfig.APP_ENDPOINT}/account?supportedUserId=${userId}&workspaceId=${workspaceId}`;

            window.location.href = userUrl;
            setAnchorEl(null);
            return;
           // window.open(userUrl, '_blank');
        }

        console.log('workspaceId', workspaceId);
        setAnchorEl(null);
        if (userId && workspaceId) {
            await dispatch(switchWorkspace({ workspaceId }))

            router.navigate({
                pathname: 'tasks',
                search: ''
            })
        }
    }, [userId, dispatch, router]);

    return (
        <Tooltip
            disableHoverListener={!showTooltip}
            componentsProps={{
                tooltip: {
                    sx: {
                        display: drawerIsOpen ? "none" : "flex",
                        bgcolor: "#fff",
                        padding: "4px 18px",
                        maxWidth: "150px",
                        height: "35px",
                        justifyContent: "center",
                        border: '1px solid transparent',
                        alignItems: "center",
                        color: "rgb(0, 55, 80)",
                        fontSize: "14px",
                        fontFamily: "'Inter', sans-serif",
                        boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                        "& .MuiTooltip-arrow": {
                            color: "#fff",
                        },
                    },
                },
            }}
            arrow
            title={t('workspace.title')}
            placement="right-start">
            <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={drawerIsOpen ? "space-between" : "center"}
                sx={{
                    margin: "16px 0 0 0", paddingInline: "16px"
                }}>


                <Button onClick={handleClick}
                    sx={{
                        width: "100%",
                        display: "flex", justifyContent: drawerIsOpen ? "space-between" : "center", alignItems: "center",
                        color: "var(--primary)", textTransform: "capitalize",
                    }}>
                    <Stack direction="row" alignItems={"center"} >
                        <WorkspacesIcon sx={{ marginInlineEnd: drawerIsOpen ? "5px" : "" }} />
                        {drawerIsOpen ? !isSupportMode ?  t('workspace.title') : "Choose workspace to support" : ""}
                    </Stack>
                    {drawerIsOpen ? !anchorEl ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> : null}
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <Typography style={{ margin: '10px', fontSize: "12px", color: "var(--primary)", textTransform: "capitalize" }}>{t('workspace.myWorkspaces')}</Typography>


                    {userWorkspaces?.map((ws, index) => <MenuItem key={`switch-ws-${index}`} disabled={workspace?._id === ws._id} onClick={() => handleCloseSwitchWorkspace(ws._id as string)}>{ws.name}</MenuItem>)}

                    <Divider />
                   {!isSupportMode &&   <Grid gap={2} container justifyContent="space-between" style={{ padding: '10px' }}>
                        <Link to="/new-workspace">
                            <Button
                                sx={{ color: "var(--primary)", textTransform: "capitalize" }}
                                startIcon={<AddIcon />}
                                size="small"
                                onClick={handleClose}
                            >
                                {t('workspace.addWorkspace')}
                            </Button>
                        </Link>
                        <Link to="/account-workspace">
                            <Button
                                sx={{ color: "var(--primary)", textTransform: "capitalize" }}

                                startIcon={<ViewCompactIcon />}
                                size="small"
                                onClick={handleClose}
                            >
                                {t('workspace.browseAll')}
                            </Button>
                        </Link>
                    </Grid>}
            
                  
                </Menu>
            </Stack>
        </Tooltip>
    );
}

export default WorkspaceDropDown;
