import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Asset {
  url: string;
  type: string;
  filename: string;
}

interface State {
  index: number;
  shown: boolean;
  taskId: string;
  columnId: string;
  showFileDialog: boolean;
  currentFiles: any;
  showVersionTab: boolean;
  showEditTab: boolean;
  isNewVersion: boolean;
  isVersionPreview: boolean;
  versionIndex: number;
  filesToDecideNewVersionOrNewFile: Asset[];
  filesTotal: Asset[];
  isViewOnly:boolean;

}

const initialState: State = {
  index: 0,
  shown: false,
  taskId: "",
  columnId: "",
  showFileDialog: false,
  currentFiles: [],
  showVersionTab: false,
  showEditTab:false,
  isNewVersion: false,
  isVersionPreview: false,
  versionIndex: 0,
  filesToDecideNewVersionOrNewFile: [],
  filesTotal:[],
  isViewOnly:false,
};
const mediaManagerSlice = createSlice({
  name: "mediaManagerSlice",
  initialState,
  reducers: {
    setSelectedCellFilesAndCurrentindex(
      state,
      { payload }: PayloadAction<any>
    ) {
      state.taskId = payload.taskId;
      state.columnId = payload.columnId;
      state.index = payload.index;
      state.shown = payload.shown;
      state.filesTotal = payload.filesTotal;
      state.isViewOnly = payload.isViewOnly;
    },
    updateVersionOrAddFile(state, { payload }: PayloadAction<any>) {
      state.index = payload.index;
      state.currentFiles = payload.currentFiles;
      state.isNewVersion = payload.isNewVersion;
      state.showFileDialog = payload.showFileDialog;
    },
    closeDialog(state, { payload }: PayloadAction<any>) {
      state.shown = payload.shown;
    },
    switchFile(state, { payload }: PayloadAction<any>) {
      state.index = payload.index;
      state.isVersionPreview = payload.isVersionPreview;
      state.versionIndex = payload.versionIndex;
    },
    closeFileExistsDialog(state, { payload }: PayloadAction<any>) {
      state.showFileDialog = payload.showFileDialog;
    },
    setFilesToDecideNewVersionOrNewFile(
      state,
      { payload }: PayloadAction<any>
    ) {
      state.filesToDecideNewVersionOrNewFile =
        payload.filesToDecideNewVersionOrNewFile;
      state.taskId = payload.taskId;
      state.columnId = payload.columnId;
    },
    setVersionTab(state, { payload }: PayloadAction<any>) {
      state.showVersionTab = payload;
    },
    setEditTab(state, { payload }: PayloadAction<any>) {
      state.showEditTab = payload;
    },
    updateDuplicateFilesState(state, { payload }: PayloadAction<any>) {
      state.filesToDecideNewVersionOrNewFile = payload;
    },
    setVersionPreview(state, { payload }: PayloadAction<any>) {
      state.isVersionPreview = payload.isVersionPreview;
      state.versionIndex = payload.versionIndex;
    },
    setTaskId(state, { payload }: PayloadAction<any>) {
      state.taskId = payload;
    }
  },
});

export const mediaManager = mediaManagerSlice.actions;
export default mediaManagerSlice.reducer;

