import { CalendarColors, UserMessage } from "@common-models/user-message";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import { addScheduledMessageButtonActions } from "./AddScheduledMessageButtonReducer";
import { DBUserMessageThunks, DBWaChatThunks, DBWaContactThunks, userMessagesSelectByQuery } from "@common-reducers/DBServiceThunks";
import {  selectUserObject } from "./UserSelectors";

import { patchRuleSet, toRuleSet } from "@common-components/schedule-picker/recurrence-utils";
import { CommonRootState } from "@common/types/common-root-state-type";
import { CalendarEvent, confirmationDialogOption, EventPL } from "@common-views/calendar/message-calendar";
import { scheduleMessage } from "@common/thunks/UserMessagesThunks";
import { selectCurrentUserSession } from "./UserSelectors";

type HandleNewMessageDialogThunkParams = {
    slotInfo: any;
};

export const handleNewMessageDialogThunk = createAsyncThunk<any, HandleNewMessageDialogThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ slotInfo }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;
        let slotStart = moment(state.WhatsAppReducer.hoveredDate ?? slotInfo?.start);

        slotStart = slotStart.isSame(moment(), 'day')
            ? slotStart.set({ hour: moment().hour(), minute: moment().minute(), second: moment().second() })
            : slotStart.set({ hour: 12, minute: 0, second: 0 });

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: slotStart.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);

let intervalId: number | null = null;

export const getContactsAndChatsThunk = createAsyncThunk<any, void, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async (_, { dispatch, getState }) => {
        const state = getState();
        const session = selectCurrentUserSession(state);

        dispatch(DBWaContactThunks.find({ $paginate: false }));
        dispatch(DBWaChatThunks.find({ $paginate: false }));

        if (session?.isFinishSavingChats && session?.isFinishSavingContacts) {
            return true;
        }

        return false;
    }
);

export const stopBackgroundTask = createAsyncThunk(
    'background/stopTask',
    async () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    }
);

type RecurringDialogCloseThunkParams = {
    value?: confirmationDialogOption;
    event: CalendarEvent | EventPL;
};

export const onRecurringDialogCloseThunk = createAsyncThunk<any, RecurringDialogCloseThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ value, event }, { getState, dispatch }) => {
        if (!value) return;

        const state = getState();
        const messages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });

        let original = messages.find((m) => m._id === event._id);
        const originalRuleset = toRuleSet(original?.rruleset);

        if (value === "This message") {
            originalRuleset.exdate(moment.utc(event.dueDate).startOf("minute").toDate());
            original = { ...original, rruleset: originalRuleset.toString() };
            dispatch(scheduleMessage(original));
        } else if (value === "This and following messages") {
            original = {
                ...original,
                rruleset: patchRuleSet(originalRuleset, {
                    until: moment.utc(event.dueDate).subtract(1, "minute").toDate(),
                }).toString(),
            };
            dispatch(scheduleMessage(original));
        } else {
            dispatch(DBUserMessageThunks.delete({ entity: original }));
        }
    }
);

type ColorsRecurringDialogCloseThunkParams = {
    value?: confirmationDialogOption;
    event: EventPL;
    eventColor: string;
};

export const onColorsRecurringDialogCloseThunk = createAsyncThunk<any, ColorsRecurringDialogCloseThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ value, eventColor, event }, { getState, dispatch }) => {
        if (!value) return;

        const state = getState();
        const messages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });

        let original = messages.find((m) => m._id === event._id);
        let colors = original.calendarColors ?? ({ specific: [], ranges: [] } as CalendarColors);

        if (value === "This message") {
            colors = {
                ...colors,
                specific: [...colors.specific, { messageTime: event.dueDate, color: eventColor }],
            };
        } else if (value === "This and following messages") {
            colors = {
                ...colors,
                ranges: [...colors.ranges, { fromTime: event.dueDate, color: eventColor }],
            };
        } else {
            colors = { defaultColor: eventColor, specific: [], ranges: [] };
        }

        original = { ...original, calendarColors: colors };
        dispatch(scheduleMessage(original));
    }
);

type HandleNewMessageThunkParams = {
    slotStart: moment.Moment;
};

export const handleNewMessageThunk = createAsyncThunk<any, HandleNewMessageThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ slotStart }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: slotStart.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);

type HandleClickWeedTimeSlotThunkParams = {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    date: any;
};

export const handleClickWeedTimeSlotThunk = createAsyncThunk<any, HandleClickWeedTimeSlotThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ event, date }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;

        event.stopPropagation();
        const dueDate = moment(date);

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: dueDate.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);
