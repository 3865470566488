import { userDisplayName } from "@common-models/user";
import { Avatar, Tooltip, Typography } from "@mui/material";
import { CSSProperties, useMemo } from "react";
import { groupcolors } from "@common/theme";

export interface PersonAvatarProps {
  isName?: boolean;
  size?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  profileImg?: string;
  toolTip?: boolean;
  textSize?: number;
  style?: CSSProperties;
  bgcolor?: string;
  textWidth?: string;
}

export const PersonAvatar = (props: PersonAvatarProps) => {
  const fullName = userDisplayName(props);
  const person = { fullName: fullName, email: props.email }
  const generateInitials = (person) => {
    if (person?.fullName !== '') {
      const namesAsList: string[] = person.fullName.split(" ");
      if (namesAsList.length === 2) {
        const initials = `
        ${namesAsList[0]?.slice(0, 1)}${namesAsList[1]?.slice(0, 1)}`;
        return initials;
      } else {
        return `${namesAsList[0]?.slice(0, 1)}`;
      }
    } else {
      return person.email?.slice(0, 1);
    }
  };

  const tooltip = (fullName || props?.email) ?? ''

  const getDeterministicColor = (email) => {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    return groupcolors[Math.abs(hash) % groupcolors.length];
  };

  const bgColor = useMemo(() => {
    return props.bgcolor ? props.bgcolor : getDeterministicColor(props.email ?? '');
  }, [props.bgcolor, props.email]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", ...props.style }}>

      <Tooltip arrow placement="bottom" disableInteractive title={props?.toolTip ? tooltip : ""}>
        <Avatar
          style={{
            width: props.size ? props.size : 28,
            height: props.size ? props.size : 28,
            fontSize: props.textSize ? props.textSize : 12,
            color: "var(--default-background)",
            fontWeight: "bold",
            backgroundColor: bgColor,
            boxShadow: "rgb(0 0 0 / 10%) 2px 2px 2px",
            textTransform: 'uppercase',
            border: 'none',
          }}
          src={props.profileImg}
        >
          {(!props.profileImg || groupcolors.indexOf(props.profileImg) !== -1) &&
            generateInitials(person)
          }
        </Avatar>
      </Tooltip>


      {props.isName && (
        <Typography variant="inherit" noWrap
          sx={{
            margin: "0 0 0 10px",
            width: props?.textWidth ?? 140,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "var(--text-color)",
          }}
        >
          {fullName}
        </Typography>
      )}
    </div>
  );
}

export default PersonAvatar;

