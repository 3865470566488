import { useState } from "react";
import { CellProps } from "../cell-interface";
import classes from "./checkbox_cell.module.scss";
import { Box } from "@mui/material";
import Checkbox from "@common-components/tasks/custom-checkbox/checkbox";
import { TasksGroup } from "@common-models/task-group";
import { taskgroupsSelectors } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from "@common-reducers/hooks/store.hook";

export default function CheckboxCell({ cellValue, groupId, onUpdate,readOnly }: CellProps) {
  const [isChecked, setCheckbox] = useState<boolean>(false);
  const tasksGroup: TasksGroup = useAppSelector((state) => {
    return taskgroupsSelectors.selectById(state, groupId)
  }
  );
  function updateCheckbox(e) {
    setCheckbox(!isChecked);
    onUpdate(!isChecked);
  }

  return (
    < div className={classes.checkboxCell} >
      <Box onClick={updateCheckbox}>
        <Checkbox disabled={readOnly} checked={isChecked || cellValue} color={tasksGroup?.colorAccent} />
      </Box>
    </div>

  );
}
