
export const colors = [
  "#d0d1df",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#c5a5ff",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#b7a616",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#2BD8AD",
  "#adadad",
  "#464646",
  "#ff5ac4"
];





