import { safeCreateObjectURL } from "@common-services/utils";
import { isExtensionContext } from "@common/services/detect-context";
import { theme } from "@common/theme";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { AttachFile, AudioFile, VideoFile } from "@mui/icons-material";
import { Box, CircularProgress, Paper } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { memo, useMemo } from "react";

// Create a new QueryClient instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      retry: 2,
    },
  },
});

export interface AttachmentPreviewProps {
  attachment?: File | null;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
  isFromCampaigns?: boolean;
  previewImageWidth?: string;
  previewMaxHeight?: string;
}

const fetchMedia = async (url: string, isExtension: boolean) => {
  if (isExtension) {
    const result = await ContentMessageServiceActions.fetchUrlContentAsBlob(url);
    if (!result) {
      throw new Error('No data received from background script');
    }
    return result;
  } else {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  }
};

export const AttachmentPreviewInner = (props: AttachmentPreviewProps) => {
  const fileUrl = useMemo(
    () => props.attachment ? safeCreateObjectURL(props.attachment) : undefined,
    [props.attachment]
  );

  const { data: dataUrl, isLoading, error } = useQuery({
    queryKey: ['media', props.attachmentUrl],
    queryFn: () => fetchMedia(props.attachmentUrl!, isExtensionContext()),
    enabled: !!props.attachmentUrl && !fileUrl,
    staleTime: 1000 * 60 * 60, // Consider data fresh for 1 hour
  });

  const attachmentUrl = useMemo(
    () => fileUrl ?? dataUrl,
    [fileUrl, dataUrl]
  );

  const attachmentType = useMemo(
    () => props.attachment?.type ?? props.attachmentType,
    [props.attachment?.type, props.attachmentType]
  );

  const isImage = (type?: string) => {
    const imageTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/svg+xml"
    ];
    return type ? imageTypes.includes(type) : false;
  };

  const isVideo = (type?: string) => {
    return type?.startsWith("video/");
  };

  const isAudio = (type?: string) => {
    return type?.startsWith("audio/");
  };

  const isPdf = (type?: string) => {
    return type === "application/pdf";
  };

  const renderFileInfo = (icon: JSX.Element) => (
    <>{(props.attachmentName ?? props.attachment?.name) && <Box sx={{ width: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          padding: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          backgroundColor: 'transparent',
        }}
      >
        {icon}
        <Box component="span" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          {props.attachmentName ?? props.attachment?.name}
        </Box>
      </Paper>
    </Box>}
    </>
  );

  const renderLoading = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2)
    }}>
      <CircularProgress size={24} />
    </Box>
  );

  const renderError = () => (
    <Box sx={{
      color: 'error.main',
      padding: theme.spacing(1),
      textAlign: 'center'
    }}>
      {error.message}
    </Box>
  );

  const renderPdfPreview = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      width: '100%'
    }}>
      <Box
        component="iframe"
        sx={{
          width: '100%',
          height: '200px',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          maxHeight: props.previewMaxHeight ?? '200px'
        }}
        src={attachmentUrl}
      />
    </Box>
  );

  const attachmentPreview = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (error) {
      return renderError();
    }

    if (!attachmentUrl) {
      return <Box />;
    }

    if (isPdf(attachmentType)) {
      return renderPdfPreview();
    }

    if (isImage(attachmentType)) {
      return (
        <>
          <Box
            component="img"
            sx={{
              height: props?.isFromCampaigns ? '74px' : props.previewMaxHeight ?? 'auto',
              maxWidth: '100%',
              objectFit: 'contain',
              maxHeight: '200px'
            }}
            src={attachmentUrl}
            alt={props.attachmentName ?? props.attachment?.name ?? ""}
          />
        </>
      );
    }

    if (isVideo(attachmentType)) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          width: '100%',
        }}>
          <Box
            component="video"
            controls
            sx={{
              maxWidth: '100%',
              '& video': {
                maxHeight: props.previewMaxHeight ?? '200px'
              }

            }}
          >
            <source src={attachmentUrl} type={attachmentType} />
            Your browser does not support the video tag.
          </Box>
          {renderFileInfo(<VideoFile />)}
        </Box>
      );
    }

    if (isAudio(attachmentType)) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          width: '100%'
        }}>
          <Box
            component="audio"
            controls
            sx={{
              width: '100%'
            }}
          >
            <source src={attachmentUrl} type={attachmentType} />
            Your browser does not support the audio tag.
          </Box>
          {renderFileInfo(<AudioFile />)}
        </Box>
      );
    }

    return renderFileInfo(<AttachFile />);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        maxWidth: '100%',
        width: props.previewImageWidth,
      }}
    >
      {attachmentPreview()}
    </Box>
  );
};

// Wrap the main component with QueryClientProvider
export const AttachmentPreview = memo((props: AttachmentPreviewProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AttachmentPreviewInner {...props} />
    </QueryClientProvider>
  );
});

export default AttachmentPreview;