import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectPersonPickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentAction } from "../automations-helper";

const ClearAssignessAction = ({ index }) => {
    const dispatch = useAppDispatch();
    const boardId = useAppSelector((state) => getSelectedBoardId(state));
    const filteredColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);

    const [anchorEl, setAnchorEl] = useState(null);
    const [columnName, setColumnName] = useState<string>('assignees');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleColumnChange = (id, name) => {
        const updatedAction = setCurrentAction(actions, index, { actionType: actions[index].actionType, columnId: id });
        dispatch(automation.setActions(updatedAction));
        setColumnName(name);
        handleClose();
    };

    useEffect(() => {
        if (actions) {
            const selectedColumnId = actions[index].columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            if (column) {
                setColumnName(column.name);
                dispatch(automation.fieldValidityChanged({ component: 'ClearAssignessAction', index, field: 'columnId', isValid: !!selectedColumnId }));
            }
        }
    }, [actions, dispatch, filteredColumns, index]);

    const prefix = index === 0 ? 'Then clear' : 'and then clear';

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix}
                <AutomationButton
                    componentName='ClearAssignessAction'
                    index={index}
                    option='columnId'
                    label={columnName}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"}  componentName="ClearAssignessAction"/>
            <PickerColumn
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                filteredColumns={filteredColumns}
                handleColumnChange={handleColumnChange}
            />
        </Stack>
    );
};

export default ClearAssignessAction;
