import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";
import { UserMessage } from "@common/models/user-message";
import { Box } from "@mui/system";
import moment from "moment";
import { memo, SyntheticEvent } from "react";
import { EventProps } from "react-big-calendar";
import { EventPL } from "./message-calendar";
import classes from "./message-calendar.module.scss";

interface P {
  eventProps: EventProps<UserMessage>;
  onRightClick: (pl: EventPL) => void;
}

const handleRightClick = (e: SyntheticEvent, props: P) => {
  e.preventDefault();
  const { left, top, bottom, right } = e.currentTarget.getBoundingClientRect();
  props.onRightClick({ ...props.eventProps.event, left, top, bottom, right });
};

export const CustomMonthEvent = memo((props: P) => {
  const { title, event } = props.eventProps;



  return (

    <Box
      className={classes.monthEvent}
      onContextMenu={(e: SyntheticEvent) => handleRightClick(e, props)}
    >
      <div style={{ width: '25px' }}>
        <ContactAvatar
          contactId={props.eventProps?.event?.contactList[0]?.id}
          size={25}
          sx={{
            m: '0px 0px 0px 2px',
          }}
        />
      </div>
      <div style={{
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        backgroundColor: event.messageColor ?? 'grey',
        marginLeft: '4px',
      }} />
      <div style={{ width: '52px' }} className={classes.time}>
        {moment(event.dueDate).format("hh:mma")}
      </div>
      <div style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
      {props.eventProps?.event?.asset?.url &&
        <img
          alt={props.eventProps?.event?.asset?.url}
          src={props.eventProps?.event?.asset?.url}
          style={{
            width: '27px',
            height: '27px',
            objectFit: 'cover',
            objectPosition: 'center',
            marginInlineEnd: '3px',
          }} />
      }
    </Box>
  );
});




// export const CustomEvent = (props: P) => {
//   const dispatch = useAppThunkDispatch();

//   const { title, event } = props.eventProps;


//   const isFirstHalfHour = moment(props.eventProps.event?.dueDate).minutes() <= 29 && moment(props.eventProps.event?.dueDate).minutes() >= 0;



//   const onSelectSlot = (slotInfo) => {
//     let slotStart = moment(slotInfo);
//     dispatch(handleNewMessageThunk({ slotStart }));
//   };


//   return (
//     <div className={classes.customEventWrapper}>
//       <Box
//         className={classes.customEvent}
//         onContextMenu={(e: SyntheticEvent) => handleRightClick(e, props)}
//         sx={{ backgroundColor: event.messageColor, }}
//       >
//         <div className={classes.time}>
//           {moment(event.dueDate).format("hh:mma")}
//         </div>
//         <div style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
//       </Box >
//       <IconButton className={classes.customEventAddButton} sx={{
//         top: isFirstHalfHour ? '-2px' : '-1px',
//       }} onClick={(e) => {
//         e.stopPropagation()
//         onSelectSlot(props?.eventProps?.event?.dueDate)
//       }}>
//         <AddIcon sx={{ width: "16px" }} />
//       </IconButton>
//     </div>
//   );
// };
