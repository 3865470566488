import { userDisplayName } from '@common-models/user';
import { isBoardPanelDrawerOpen } from '@common-reducers/BoardsSelectors';
import { setSelectedBoardId } from '@common-reducers/BoardsThunks';
import { DBUserThunks, boardsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { selectUser } from '@common-reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useRouter } from '@common-reducers/hooks/use-router';
import { appConfig } from '@common/config/app.config';
import { theme } from '@common/theme';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import classes from './recents-boards.module.scss';
// import 'intro.js/introjs.css';

// import { Steps } from 'intro.js-react';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';
import { selectUserId } from '@common/reducers/UserSelectors';



function RecentsBoards() {
    console.log("Running ResumeJourney rendering")
    const { t } = useTranslation(namespace);

    const router = useRouter()
    const dispatch = useAppDispatch()
    // const pendingInvitesToBoard = useAppSelector(selectPendingBoardUsers);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [seeAll, setSeeAll] = useState<string>('All')
    // const [isIntroEnabled, setIntro] = useState<boolean>(true)


    // const steps = [
    //     {
    //       element: '#step-0',
    //       intro: 'Welcome, we created the first board for you',
    //       position: 'right',
    //       tooltipClass: 'myTooltipClass',
    //       highlightClass: 'myHighlightClass',
    //     },
    //     {
    //       element: '#step-1',
    //       intro: 'Lets get started, first fill you personal details here.',
    //       position: 'right',
    //       tooltipClass: 'myTooltipClass',
    //       highlightClass: 'myHighlightClass',
    //     },
    //     {
    //       element: '#step-2',
    //       intro: 'Invite a team member so you can plan your tasks together.',
    //       position: 'top',
    //       tooltipClass: 'myTooltipClass',
    //       highlightClass: 'myHighlightClass',
    //     },
    //   ];
    const userId = useAppSelector(selectUserId)

    const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
    const recentBoards = useAppSelector((state) => boardsSelectByQuery(
        state.DBBoardsReducer,
        {
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
        [
            "_id",
            "name",
            "titleIcon",
            "owner",
            "updatedAt",
            "workspace"
        ],
        {
            updatedAt: -1,
        }
    ));
    const user = useAppSelector(selectUser);


    function selectBoard(boardId: string) {
        dispatch(setSelectedBoardId({ boardId }));
        router.navigate(`/tasks?boardID=${boardId}`)
    }




    useEffect(() => {
        document.title = `${appConfig.DEFAULT_TITLE} ${t('recentBoards.pageTitleDefault')}`;
        // && !creatingFirstBoard

    }, []);


    // const handleInviteAction = async (invite) => {

    //     await dispatch(DBBoardUserThunks.patch({
    //         entity: {
    //             _id: invite._id,
    //             status: "active",
    //         }
    //     })).unwrap();
    //     await dispatch(DBBoardsThunks.find({}));


    // };
    // const onIntroExit = () => {
    //     setIntro(false)
    // }

    return (
        <div style={{ width: "100%" }} className={drawerIsOpen ? classes.container : classes.__container_withDrawerOpen}>
            <div className={classes.header}
                style={{
                    backgroundImage: `url('/img/header-bg-img.png')`,
                    backgroundSize: 'auto 100%', /* Sets the width of the background image to 400px */
                    backgroundRepeat: 'no-repeat', /* Ensures the image does not repeat */
                    backgroundPosition: isMobile ? 'unset' : 'top right', /* Positions the image at the top right corner */
                }}>
                {!isMobile ? <div style={{
                    flexBasis: '40%',
                    background: 'linear-gradient(to left, rgb(255 255 255 / 0%) 0%'
                }}>
                    <h3>{t('recentBoards.welcomeGreeting')} {userDisplayName(user)}</h3>
                </div> : <IconButton
                    edge="start"
                    onClick={() => dispatch(DBUserThunks.patch({
                        entity: {
                            _id: userId,
                            isBoardsPanelOpen: !drawerIsOpen,
                        }
                    }))}
                    sx={{ m: 0, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>}


            </div>

            {/* {pendingInvitesToBoard.length > 0 &&
                <div className={classes.contentWrapper}>
                    <div className={classes.labelWrap}>
                        <p className={classes.labelTitle}>{t('recentBoards.pendingInvites')}</p>

                    </div>
                    <Stack direction="row" flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"} className={classes.mappedRecentBoards} spacing={2} >

                        {pendingInvitesToBoard.map((item: any, index) => (
                            <div onClick={() => selectBoard(item._id)} key={index} className={classes.boardItem}>
                                <div className={classes.boardIcon}>{item?.boardTitle ?? '😜'}</div>
                                <div className={classes.boardItemWrap} >
                                    <h3 style={{
                                        width: 120,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{item?.boardName}</h3>
                                    <BTButton sx={{ mt: 1 }} onClick={() => handleInviteAction(item)}>
                                        {t('recentBoards.accentInvite')}
                                    </BTButton>
                                </div>
                            </div>
                        ))}
                    </Stack>
                </div>
            } */}


            {/* <Steps
  enabled={isIntroEnabled}
  steps={steps}
  initialStep={0}
  onExit={onIntroExit}
/> */}
            {recentBoards?.length > 0 &&

                <div style={{ padding: isMobile ? 0 : "25px" }} className={`${seeAll && classes.seeAll} ${classes.contentWrapper}`}>
                    {seeAll !== 'notifications' && (
                        <div className={classes.recentBoardsWrapper}>


                            <div className={classes.mappedRecentBoards}>

                                <Stack direction="row" flexWrap={"wrap"} gap={8} >
                                    {/* <Box width="100%">
                                <div className={classes.labelWrap} style={{marginBottom:isMobile ? "15px" : "-40px"}} >
                                            <p className={classes.labelTitle}>{t('recentBoards.recentBoardsTitle')}</p>

                                        </div>
                           
                                    <CustomizedSteppers/>
                                </Box> */}
                                    <Stack spacing={5} width="100%">
                                        <div className={classes.labelWrap}>
                                            <p className={classes.labelTitle}>Recent Boards</p>

                                        </div>
                                        <Grid container gap={4} >
                                            {recentBoards.slice(0, 5).map((item, index) => (
                                                <Grid minWidth="250px" sx={{ padding: "24px 0" }} item xs={12} sm={6} md={5.5} lg={2.1} key={index} onClick={() => selectBoard(item._id)} className={classes.boardItem}>
                                                    <div className={classes.boardIcon}>{item?.titleIcon ?? '😜'}</div>
                                                    <div className={classes.boardItemWrap}>
                                                        <h3 style={{

                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>{item?.name}</h3>
                                                        {/* <p>{item.workspace?.name}</p> */}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </div>
                        </div>

                    )}
                    {/* {seeAll !== 'boards' && (
                    <div className={classes.notificationsWrapper}>
                        <div className={classes.labelWrap}>
                            <p className={classes.labelTitle}>Notifications</p>
                            <p onClick={() => setSeeAll(seeAll ? '' : 'notifications')} className={classes.buttonLink}>
                                See {seeAll === 'notifications' ? ' Less' : ' All'}
                            </p>
                        </div>
                        <div className={classes.mappedNotifications}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                <div key={index} className={classes.notificationItem}>
                                    <div className={classes.iconCol}>💰</div>
                                    <div className={classes.userAvatarCol}></div>
                                    <div className={classes.titleCol}>   Notification item {item}</div>
                                    <div className={classes.dateCol}> <span className={classes.dateClocIcon}><BsClock /></span>12 mins </div>
                                </div>
                            ))}

                        </div>
                    </div>

                    )} */}
                </div>
            }

        </div>
    )
}

export default memo(RecentsBoards)