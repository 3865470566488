import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import StartIcon from '@mui/icons-material/Start';
import { Box, Stack } from "@mui/material";
import { getActions, getConditions, getTrigger, selectAllColumns, selectOptionPickersColumns, selectMappedTaskGroups, selectNumberColumns, selectPersonPickerColumns } from "@common-reducers/AutomationsSelectors";
import { getActiveBoards, getSelectedBoardCompleteUserListEqual, getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { taskColumnOptionSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { useMemo } from 'react';
import { extractName } from "./conditions/person-is-someone-condition";
import { generateRuleDescription, parseRRule } from './dropdowns/every-time-period';
const TranslateAutomationToText = ({ id }) => {
    const trigger = useAppSelector((state) => getTrigger(state, id));
    const conditions = useAppSelector((state) => getConditions(state, id));
    const actions = useAppSelector((state) => getActions(state, id));
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectAllColumns(state, boardId));
    const groups = useAppSelector(selectMappedTaskGroups);
    const boards = useAppSelector(getActiveBoards);

    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual); const mappedUserList = extractName(userList);


    return (
        <Stack direction="row">
            <Stack direction="row" alignItems={"center"}>    {trigger?.isCron ? <HistoryToggleOffIcon sx={{ px: 1 }} /> : <AutoAwesomeMosaicIcon sx={{ px: 1 }} />}
                <StartIcon sx={{ pr: 2 }} /></Stack>
            <Stack direction="row" alignItems="center" flexWrap={"wrap"} >
                <TranslateTrigger trigger={trigger} filteredColumns={filteredColumns} boardId={boardId} groups={groups} />
                {conditions.map((conditions, index) => <TranslateCondition key={index} conditions={conditions} groups={groups} filteredColumns={filteredColumns} boardId={boardId} />)}

                {actions.map((action, index) => <TranslateAction key={index} filteredColumns={filteredColumns} actions={action} index={index} mappedArray={mappedUserList} boards={boards} groups={groups} boardId={boardId} />)}
            </Stack>
        </Stack>
    );
};

export default TranslateAutomationToText;

const getGroupName = (groups, id) => groups.find(item => item._id === id)?.label;
const getUserNameById = (users, id) => users.find(item => item._id === id)?.label;
const getUserNameByUserId = (users, id) => users.find(item => item.userId === id)?.label;
const getColumnOption = (options, id) => options.find(item => item._id === id)?.label;
const getColumnName = (columns, id) => columns.find(item => item.id === id)?.name;
const getBoardName = (boards, id) => boards.find(item => item._id === id)?.name;

const TranslateTrigger = ({ trigger, filteredColumns, boardId, groups }) => {
    const personPickerColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));
    const columnId = trigger?.columnId;
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))

    const translateTriggerToText = useMemo(() => {

        switch (trigger?.triggerType) {
            case "complexity-picker":
            case "status-option-picker":
            case "option-picker":
                return `When ${getColumnName(filteredColumns, trigger?.columnId)} changes to ${getColumnOption(columnOptions, trigger?.optionId)}`;
            case "person-picker":
                return `When ${getColumnName(personPickerColumns, trigger?.columnId)} is assigned`;
            case "task-name-changes":
                return "When Task text changes";
            case "task-created":
                return "When Task created";
            case "task-extra-data-update":
                return "When an Update is created";
            case "task-moved-to-group":
                return `When Task moved to ${getGroupName(groups, trigger?.groupId)}`;
            case "task-moved-to-board":
                return `When Task moved to this board`;
            case "column-change":
                return `When ${getColumnName(filteredColumns, trigger?.columnId)} changes`;
            case "date-passed":
                return `Everyday if, ${getColumnName(filteredColumns, trigger?.columnId)} has passed`;
            case "every-time-period":
                let rrule = parseRRule(trigger.rruleset);
                return `${generateRuleDescription(rrule)}`;
            case "date-arrives":
                const senetence = trigger?.cronDateArrivesOption === "optionA" ? `When ${getColumnName(filteredColumns, trigger?.columnId)} arrives` : `${trigger.cronNumberOfPeriod} ${trigger.cronNumberOfPeriod === 1 ? trigger.timePeriod.slice(0, -1) : trigger.timePeriod} ${trigger.cronBeforeOrAfter} ${getColumnName(filteredColumns, trigger?.columnId)} arrives `
                return senetence

            default:
                return "";
        }
    }, [trigger?.triggerType, trigger?.rruleset, trigger?.cronDateArrivesOption, trigger?.cronNumberOfPeriod, trigger?.timePeriod, trigger?.cronBeforeOrAfter, trigger?.columnId, trigger?.optionId, trigger?.groupId, filteredColumns, columnOptions, personPickerColumns, groups, filteredColumns]);

    return <Box sx={{ pr: 0.9, fontSize: "26px" }}>{translateTriggerToText}</Box>;
};

const TranslateCondition = ({ conditions, groups, filteredColumns, boardId }) => {

    const columnId = conditions?.columnId;
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))
    const personPickerColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));

    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual);
    const mappedUserList = extractName(userList);
    const numberColumns = useAppSelector((state) => selectNumberColumns(state, boardId));


    const translateConditionToText = useMemo(() => {
        switch (conditions?.conditionType) {
            case "status-is-something":
                const getOptionName = getColumnOption(columnOptions, conditions?.optionId);
                return `and only if ${getColumnName(filteredColumns, conditions?.columnId)} is ${getOptionName}`;
            case "task-is-in-group":
                return `and only if task is in ${getGroupName(groups, conditions?.groupId)}`;
            case "person-is-someone":
                const personColumnName = getColumnName(personPickerColumns, conditions?.columnId);
                const optionSelected = getUserNameById(mappedUserList, conditions?.optionId);
                return `and only if ${personColumnName} is assigned to ${optionSelected}`;
            case "number-meets-condition":
                const numberColumnName = getColumnName(numberColumns, conditions?.columnId);
                return `and only if ${numberColumnName} is ${conditions.mathOption} ${conditions.numberAmount}`;
            default:
                return "";
        }
    }, [conditions?.conditionType, conditions?.columnId, conditions?.optionId, conditions?.groupId, conditions?.mathOption, conditions?.numberAmount, filteredColumns, columnOptions, groups, personPickerColumns, mappedUserList, numberColumns]);

    return <Box sx={{ pr: 0.9, fontSize: "26px" }}>{translateConditionToText}</Box>;
};





const TranslateAction = ({ filteredColumns, actions, index, mappedArray, groups, boardId, boards }) => {
    const prefix = index === 0 ? "Then" : 'and then';
    const personPickerColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));

    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual);
    const mappedUserList = extractName(userList);

    const columnId = actions?.columnId;
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))
    const translateActionToText = useMemo(() => {
        switch (actions.actionType) {
            case "move-task-to-group":
                return `${prefix} move task to ${getGroupName(groups, actions?.groupId)}`;
            case "duplicate-group":
                return `${prefix} duplicate group ${getGroupName(groups, actions?.groupId)}`;
            case "notify-person":
                return `${prefix} notify to ${getUserNameByUserId(mappedUserList, actions?.userId)}`;
            case "create-task-update":
                return `${prefix} create an update`;
            case "assign-person":
                const assignedName = getUserNameById(mappedUserList, actions?.userId);
                const personColumnName = getColumnName(personPickerColumns, actions?.columnId);
                return `${prefix} ${actions.actionOption} ${assignedName} as ${personColumnName}`;
            case "change-status":
                const getOptionName = getColumnOption(columnOptions, actions?.optionId);
                return `${prefix} set ${getColumnName(filteredColumns, actions?.columnId)} to ${getOptionName}`;
            case "create-task":
                return `${prefix} create a task`;
            case "create-sub-task":
                return `${prefix} create a subtask`;
            case "create-group":
                return `${prefix} create a group`;
            case "create-board":
                return `${prefix} create a board`;
            case "delete-task":
                return `${prefix} delete task`;
            case "archive-task":
                return `${prefix} archive task`;
            case "duplicate-task":
                return `${prefix} duplicate task`;
            case "increase-or-decrease":
                return `${prefix} ${actions.mathOption} ${getColumnName(filteredColumns, actions?.columnId)} by ${actions.numberAmount} `;
            case "set-date-to-today":
                return `${prefix} set ${getColumnName(filteredColumns, actions?.columnId)} to today `;
            case "clear-column":
            case "clear-assignees":
                return `${prefix} clear ${getColumnName(filteredColumns, actions?.columnId)}`;
            case "create-task-in-board":
                return `${prefix} create a task in ${getBoardName(boards, actions?.boardId)}`;
            default:
                return "";
        }
    }, [actions.actionType, actions.actionOption, actions.mathOption, actions.numberAmount, actions?.groupId, actions?.userId, actions?.columnId, actions?.optionId, actions?.boardId, groups, prefix, mappedUserList, personPickerColumns, filteredColumns, columnOptions, boards]);
    
    return <Box sx={{ pr: 0.5, fontSize: "26px" }}>{translateActionToText}</Box>;
}    
