import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import RowStack from "@common-components/common/row-stack/RowStack";
import { Close } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { getNodeDataByNodeId } from "@common-reducers/ChatbotSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useMemo, useState } from 'react';
import classes from "./chatgpt-node.module.scss";
import ConnectToOpenAI from "./connect-to-openai";
import ConnectionDropdown from "./connection-dropdown";
import MethodDropdown from "./method-dropdown";
import ModelDropdown from "./model-dropdown";
import GPTPromptInput from "./gpt-prompt-input";
import MaxTokensInput from "./max-tokens-input";
import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import AdvancedSettings from "./advanced-settings/advanced-settings";
import TestButton from "./test-button";
import { changeNodeDataThunk } from "@common-reducers/ChatbotThunks";
import { setCurrentBot, useStore } from "@common/reducers/ChatbotReducer";
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";

type Props = {
    onClose: () => void
    id?: string;
}

export default function AddChatGPTDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()
    const { setNodes } = useStore();

    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)
    const data = useAppSelector(state => getNodeDataByNodeId(state,id))


    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)
    const [localChatGPTState, setLocalChatGPTState] = useState<any>(data?.chatGPT ?? {
        connection: currentBot?.botSettings?.api?.chatGPT?.[0], // selected connection from connections dropdown
        method: 'chat completion', // 'chat completion' or 'prompt completion'
        model: '', // model name (based on connection)
        maxTokens: 0, // maximum number of tokens
        advancedSettings: false, // advanced settings
    })
    // format:
    //     connection: {connectionName: string,apiKey: string, organizationId: string},
    //     method: 'chat completion' | 'prompt completion',
    //     model: string (from the list of models returned by the open ai api),
    //     maxTokens: number,
    //     messages: { role: string, message: string }[],
    //     prompt: string,
    //     includeConversationHistory: boolean,
    //     advanced: {
    //             temperature: number,
    //             topP: number,
    //             number: number,
    //             frequencyPenalty: number,
    //             presencePenalty: number,
    //             tokenProbability: { id: string, probability: string }[],
    //             responseFormat: 'Empty' | 'Text' | 'JSON Object',
    //             seed: number,
    //             stopSequence: string[],
    //             otherInputParameters: { name: string, type: string, value: string }[]
    //         },
    const [models, setModels] = useState<any[]>([]);
    const [addNewConncetion, setAddNewConnection] = useState(false)


    const hasConnection = useMemo(() => {
        return (
            localCurrentBot?.botSettings?.api?.chatGPT && localCurrentBot?.botSettings?.api?.chatGPT.length > 0
        )
    }, [localCurrentBot?.botSettings?.api?.chatGPT])


    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const onChangeVariableName = (value) => {
        setLocalCurrentBot({
            ...localCurrentBot,
            botSettings: {
                ...localCurrentBot?.botSettings,
                variables: {
                    ...localCurrentBot?.botSettings?.variables,
                    [id]: value
                }
            }

        })
    }

    const addVariable = () => {
        return (
            <Stack>
                {title(`Save ChatGPT's response in a variable`, { marginTop: '20px', })}
                < BotInputField
                    value={localCurrentBot?.botSettings?.variables?.[id] ?? ''}
                    onChange={(newValue) => onChangeVariableName(newValue)}
                    placeholder={'Type a new variable'} />
            </Stack>
        )
    }


    const basicSettings = () => <>
        <ConnectionDropdown
            localChatGPTState={localChatGPTState}
            setLocalChatGPTState={setLocalChatGPTState}
            setAddNewConnection={setAddNewConnection}
            setModels={setModels}
        />

        <MethodDropdown
            localChatGPTState={localChatGPTState}
            setLocalChatGPTState={setLocalChatGPTState}
        />

        <ModelDropdown
            localChatGPTState={localChatGPTState}
            setLocalChatGPTState={setLocalChatGPTState}
            models={models}
            setModels={setModels}
        />

        <div className={classes.divider} />

        <GPTPromptInput
            localChatGPTState={localChatGPTState}
            setLocalChatGPTState={setLocalChatGPTState} />

        <MaxTokensInput
            localChatGPTState={localChatGPTState}
            setLocalChatGPTState={setLocalChatGPTState} />

        {addVariable()}
    </>



    const saveChatGPTData = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: { chatGPT: localChatGPTState }
        }))

        dispatch(setCurrentBot(localCurrentBot))

        onClose()
    }


    return (
        <>
            <RowStack className={classes.add_chatgpt_dialog_header}>
                <span>ChatGPT</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </RowStack>

            {!hasConnection &&
                <ConnectToOpenAI
                    onClose={onClose}
                    setModels={setModels}
                    localChatGPTState={localChatGPTState}
                    setLocalChatGPTState={setLocalChatGPTState}
                    localCurrentBot={localCurrentBot}
                    setLocalCurrentBot={setLocalCurrentBot} />
            }

            {hasConnection &&
                <>
                    {!addNewConncetion &&
                        <>
                            <Stack sx={{
                                paddingRight: '10px',
                                width: 'calc(100% + 5px)',
                            }} className={classes.scrollContainer}>
                                {basicSettings()}

                                <TestButton localChatGPTState={localChatGPTState} />

                                {localChatGPTState.advancedSettings &&
                                    <AdvancedSettings
                                        localChatGPTState={localChatGPTState}
                                        setLocalChatGPTState={setLocalChatGPTState} />
                                }
                            </Stack>


                            < RowStack sx={{ justifyContent: 'space-between', marginTop: '30px', }}>
                                <BotToggleSwitch
                                    onChange={() => setLocalChatGPTState({
                                        ...localChatGPTState,
                                        advancedSettings: !localChatGPTState.advancedSettings
                                    })}
                                    checked={localChatGPTState.advancedSettings}
                                    isOptional={false}
                                    sx={{ marginBottom: '20px', }} label='Advanced settings' />
                                <RowStack>
                                    <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                                    <BTbotbutton onClick={saveChatGPTData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
                                </RowStack>
                            </RowStack >
                        </>
                    }
                    {addNewConncetion &&
                        <ConnectToOpenAI
                            onClose={() => setAddNewConnection(false)}
                            setModels={setModels}
                            localChatGPTState={localChatGPTState}
                            setLocalChatGPTState={setLocalChatGPTState}
                            localCurrentBot={localCurrentBot}
                            setLocalCurrentBot={setLocalCurrentBot} />
                    }
                </>
            }
        </>
    )
}