import { Stack, Typography } from '@mui/material';

import { BoardsAccordion } from './my-work-boards-accordion';

import { MyWorkUsersSelect } from './my-work-user-selector';
import { ColumnAccordion } from './my-work-column-accordion';
import { BiCustomize } from 'react-icons/bi';

const MyWorkFilter = () => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1} fontSize={18}>
                    <BiCustomize />
                    <Typography fontSize={24}> Customize you'r work</Typography>
                </Stack>
      <MyWorkUsersSelect/>


      <BoardsAccordion/>


      <ColumnAccordion
        type="status"
      />
      <ColumnAccordion
        type="date"
      />

    </Stack>
  )
}

export default MyWorkFilter