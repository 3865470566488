import freeTrial from "@common-assets/free2.png";
import BTButton from '@common-components/bt-button/bt-button';
import { Box, Stack } from '@mui/material';
import { DBSubscriptionThunks, DBWorkspaceThunks } from "@common-reducers/DBServiceThunks";
import { modalActions } from "@common-reducers/ModalReducer";
import { useStartOrExtendTrialMutation } from "@common-reducers/backend-api/backend-api";
import { useAppDispatch } from '@common-reducers/hooks/store.hook';

const TrialModal = () => {
    const dispatch = useAppDispatch();
    const [startOrExtendTrial] = useStartOrExtendTrialMutation();

    const startUserTrial = async () => {
        dispatch(modalActions.setAnimationComponentToRender('TrialModalActivateAnimation'));
        const trial: any = {
            subscription_id: "",
            referenceNumber: "",
            plan_id: "trial",
            status: "active",
            type: 'trial'
        }

        await startOrExtendTrial({ trial }).unwrap()
        //  await dispatch(DBUserThunks.find({}));
        await dispatch(DBSubscriptionThunks.find({}));
        await dispatch(DBWorkspaceThunks.find({}));
        dispatch(modalActions.setComponentToRender(''));
    };

    return (
        <>
            <Stack justifyContent={"center"} alignItems={"center"} p={4} sx={{
                maxWidth: "450px",
                borderRadius: "8px",
                position: "relative",
                margin: "auto",
            }}>
                <Stack sx={{ lineHeight: 1.6 }} mt={2} mb={2} justifyContent={"center"} alignItems={"center"}>
                    <img width="250" height="300" src={freeTrial} alt="Gift Given" />
                    <Box textAlign={"center"} fontWeight={800}>Unlock the "Manager Plan" for 21 Days for Free!
                    </Box>
                    <Box textAlign={"center"}>Explore all features without commitment</Box>
                    <Box textAlign={"center"} fontWeight={800}>No Credit card required</Box>
                    <BTButton variant="contained" color="primary"
                        onClick={() => startUserTrial()} sx={{ boxShadow: "none", }}  >
                        Activate Your 21-Day Free Trial Now
                    </BTButton>

                    <Box textAlign={"center"} mt={2} style={{
                        fontSize: "14px",
                    }}>
                        *Don't worry, even after 21 days, you can still use Tudoboard.
                    </Box>
                </Stack>

            </Stack>

        </>

    );
};

export default TrialModal;