
import {
  ReactElement, useEffect, useState
} from "react";
import classes from "./NewExtraData.module.scss";
import { CreateExtraDataAndEntitySuscribersThunk } from "@common-reducers/ExtraDataThunks";
import { ExtraDataItem } from "@common-models/ExtraDataItem";
import {
  DBTasksExtraDataThunks, _tasksExtraDataSelectOneObjectByQuery, tasksExtraDataSelectByQuery, tasksSelectors
} from "@common-reducers/DBServiceThunks";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import SlateEditor from "./editor/SlateEditor";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";

export default function NewExtraData(): ReactElement {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const [showAddNewExtraDataEditor, setShowAddNewExtraDataEditor] = useState(false);
  const displayExtraDataOfTaskById = useAppSelector(
    (state) => state.ExtraDataReducer.displayExtraDataOfTaskById)
  // const initialDraft = JSON.parse(localStorage.getItem(`draft_${displayExtraDataOfTaskById}`))
  // const [draft, setDraft] = useState(initialDraft);

   
 




  function createNewExtraData(editorValue) {
    dispatch(CreateExtraDataAndEntitySuscribersThunk({
      editorValue,
      taskId: displayExtraDataOfTaskById,
    }))

    setShowAddNewExtraDataEditor(false);
  }


  const draft = JSON.parse(localStorage.getItem(`draft_${displayExtraDataOfTaskById}`));
  useEffect(() => {
    if (draft ) {
  
      setShowAddNewExtraDataEditor(false);
      // setTimeout(() => {
      //   setShowAddNewExtraDataEditor(true);
      // }, 0);
     // setDraft(JSON.parse(localStorage.getItem(`draft_${displayExtraDataOfTaskById}`)))
      setShowAddNewExtraDataEditor(true);
    } else {
      setShowAddNewExtraDataEditor(false);

    }


  }, [displayExtraDataOfTaskById])

  return (
    <div style={{paddingTop:"20px"}}>
      {showAddNewExtraDataEditor ? ( 
        
        <SlateEditor  value={draft ? draft : null} onValueUpdate={createNewExtraData} addBorder={true} id={displayExtraDataOfTaskById} isDraft={showAddNewExtraDataEditor} />
      ) : (
        <div style={{paddingTop:"20px"}} className={classes.input_wrapper}>
          <button onClick={() => setShowAddNewExtraDataEditor(true)}>
            {t('newExtraData.writeAnUpdate')}
          </button>
        </div>
      )}

    </div>

  );
}
