import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { modalTextStyle } from "@common-components/bt-chatbot/bot-components/bt-chatbot-modal";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useRef, useState } from 'react';
import classes from "./chatgpt-node.module.scss";

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
    setAddNewConnection: React.Dispatch<React.SetStateAction<boolean>>;
    setModels: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ConnectionDropdown({ localChatGPTState, setLocalChatGPTState, setAddNewConnection, setModels }: Props) {
    const containerRef = useRef(null);
    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)

    const [isSelectConnectionMenuOpen, setIsSelectConnectionMenuOpen] = useState<boolean>(false);

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitleWithMaybeLink = (
        secondaryTitle: string,
        style?: React.CSSProperties,
        linkString?: string,
        linkURL?: string
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
                {linkString && linkURL && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {linkString}
                    </a>
                )}
            </div>
        );
    };

    const toggleSelectConnectionMenu = (e) => {
        setIsSelectConnectionMenuOpen(prev => !prev);
    }

    const fetchModels = async (connection) => {
        try {
            const headers: HeadersInit = {
                'Authorization': `Bearer ${connection.apiKey}`,
                'Content-Type': 'application/json'
            };

            // Conditionally add the OpenAI-Organization header if organizationId is not empty
            if (connection.organizationId) {
                headers['OpenAI-Organization'] = connection.organizationId;
            }

            const response = await fetch('https://api.openai.com/v1/models', {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                const errorData = await response.json();
            }

            const data = await response.json();
            setModels(data.data);

            return true
        } catch (err) {

        }
    }

    const handleCloseSelectConnectionMenu = (connection) => {
        fetchModels(connection)

        setLocalChatGPTState({
            ...localChatGPTState,
            connection: connection,
        });



        setIsSelectConnectionMenuOpen(false);
    }


    const handleBlur = (event) => {
        if (containerRef.current && containerRef.current.contains(event.relatedTarget)) {
            // Focus is shifting within the container, so don't close the menu
            return;
        }
        // Otherwise, close the menu
        setIsSelectConnectionMenuOpen(false);
    };

    return (
        <div ref={containerRef} onBlur={handleBlur}>
            {title('Connection', { marginTop: '20px', })}
            <BotInputField
                onClick={toggleSelectConnectionMenu}
                value={localChatGPTState?.connection?.connectionName ?? ''}
                endAdornment={
                    <>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }} onClick={toggleSelectConnectionMenu}>
                            {!isSelectConnectionMenuOpen && <KeyboardArrowDown />}
                            {isSelectConnectionMenuOpen && <KeyboardArrowUp />}
                        </IconButton>
                    </>
                }
            />
            {isSelectConnectionMenuOpen &&
                <>
                    {currentBot?.botSettings?.api?.chatGPT?.map((connection, index) => (
                        <BotInputField
                            key={index}
                            value={connection.connectionName}
                            onClick={() => handleCloseSelectConnectionMenu(connection)}
                            sx={{
                                ".MuiOutlinedInput-input": {
                                    ...modalTextStyle,
                                    zIndex: 3,
                                    lineHeight: '19px',
                                    cursor: 'pointer',
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                    backgroundColor: 'rgb(245, 246, 250)',
                                    cursor: 'pointer'
                                },
                                "&: hover": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        backgroundColor: 'rgb(204, 208, 222)',
                                    },
                                },
                            }} />
                    ))}
                    < BotInputField
                        key={'add new connection'}
                        value={'Add'}
                        onClick={() => setAddNewConnection(true)}
                        sx={{
                            ".MuiOutlinedInput-input": {
                                ...modalTextStyle,
                                zIndex: 3,
                                lineHeight: '19px',
                                cursor: 'pointer',
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                                backgroundColor: 'rgb(245, 246, 250)',
                                cursor: 'pointer'
                            },
                            "&: hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    backgroundColor: 'rgb(204, 208, 222)',
                                },
                            },
                        }} />
                </>
            }


            {secondaryTitleWithMaybeLink('Get your organization ID at ', {}, 'OpenAI Organization Settings', 'https://platform.openai.com/settings/organization/general')}
        </div>
    )
}