import BTInputField from "@common-components/bt-input-field/bt-input-field";
import RowStack from "@common-components/common/row-stack/RowStack";
import { CheckBoxOutlined as CheckBoxIcon } from '@mui/icons-material';
import { Checkbox, MenuItem, Select } from "@mui/material";
import { Stack } from "@mui/system";
import { modalTextStyle } from "./bt-chatbot-modal";
import { onChangeParams } from "./bot-settings";


type Props = {
    isFallbackMessage: boolean;
    fallbackMessageTrigger: number;
    onChange: (params: onChangeParams) => void;
    fallbackMessage?: string;
}



const FallbackMessage = ({ isFallbackMessage, fallbackMessage, fallbackMessageTrigger, onChange }: Props) => {

    const customInputStyle = {
        sx: {
            ".MuiOutlinedInput-notchedOutline": {
                border: "0",
                backgroundColor: 'rgb(245, 246, 250)',
            },
            "&.Mui-focused": {
                ".MuiOutlinedInput-notchedOutline": {
                    border: "0",
                },
            },
            "&: hover": {
                ".MuiOutlinedInput-notchedOutline": {
                    border: "0",
                },
            },
            ".MuiOutlinedInput-input": {
                ...modalTextStyle,
                zIndex: 3,
                lineHeight: '19px',
            },
            height: '40px',
            width: '100%',
            margin: '0px 0px 8px 0px',
        }
    };

    const options = [1, 2, 3];


    const enableFallbackMessageCheckbox = () =>
        <RowStack sx={{ marginBottom: '15px' }}>
            <Checkbox
                checkedIcon={<CheckBoxIcon />}
                sx={{
                    '& .MuiSvgIcon-root': {
                        color: 'rgb(35, 164, 85)',
                        marginRight: '10px',
                        fontSize: '1rem',
                    },
                    padding: '0px',
                }}
                checked={isFallbackMessage}
                onChange={() => onChange({ type: 'isFallbackMessage' })}
                disableRipple />
            <span>Enable fallback message</span>
        </RowStack>


    const selectNumberOfFallbackMessages = () =>
        <RowStack sx={{ marginBottom: '20px' }}>
            <span>Fallback message will be triggered up to</span>
            <Select
                value={fallbackMessageTrigger}
                onChange={(event) => onChange({ type: 'fallbackMessageTrigger', event })}
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            paddingTop: '0 !important',
                            paddingBottom: '0 !important',
                        },
                    },
                }}
                sx={{
                    margin: '0px 10px',
                    width: '58px',
                    height: '40px',
                    backgroundColor: 'rgb(245, 246, 250)',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    "&.Mui-focused": {
                        '.MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #23a455',
                        },
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        disableRipple
                        sx={{
                            display: option === fallbackMessageTrigger ? 'none' : 'block',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(245, 246, 250)',
                            },
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <span>times before chatbot ends.
            </span>
        </RowStack>


    return (
        <Stack sx={{ fontSize: '13px' }}>
            <span style={{ fontSize: '15px', marginBottom: '20px' }}>Fallback Message</span>
            {enableFallbackMessageCheckbox()}

            {isFallbackMessage && (
                <Stack>
                    <RowStack sx={{ justifyContent: 'space-between' }}>
                        <span style={{ marginBottom: '10px' }}>Set fallback message if no keyword is matched in the chatbot:
                        </span>
                        <span style={{
                            fontSize: '10px',
                            fontWeight: fallbackMessage.length > 1024 && '700',
                            color: fallbackMessage.length > 1024 && 'red',
                        }}>
                            {fallbackMessage.length}/1024
                        </span>
                    </RowStack>

                    <BTInputField
                        value={fallbackMessage ?? ''}
                        type="text"
                        multiline={true}
                        rows={6}
                        onChange={({ target }: any) => onChange({ type: 'fallbackMessage', value: target.value })}
                        sx={{ ...customInputStyle.sx, height: 'auto', marginBottom: '20px' }}
                    />

                    {selectNumberOfFallbackMessages()}
                </Stack>
            )
            }
        </Stack >
    )
}

export default FallbackMessage;
