import { getCurrentBoard } from "@common-reducers/BoardsSelectors";

import { useAddNewBoardMemberApiMutation } from "@common-reducers/backend-api/backend-api";
import { AnalyticsService } from "@common-services/analytics-service";
import { Chat } from "@wa-communication-layer/whatsapp";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InviteUserTable from "./invite-user-table";
// interface MemberTable {
//   id?: string,
//   img?: string,
//   name?: string,
//   memberType?: string,
//   memberPermission?: string
// }

export default function InviteUserModal() {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const currentBoard = useAppSelector(getCurrentBoard);
  const [email, setEmail] = useState<string>("");
  const [showEmailNotValidWarning, setShowEmailNotValidWarning] = useState<boolean>(false);
  const [addNewBoardMemberAPI] = useAddNewBoardMemberApiMutation();

  const analyticsService: AnalyticsService = new AnalyticsService();
  const currentChat: Chat = useAppSelector(
    (state) => state.WhatsAppReducer.currentChat
  );

  //   async function sendShareLink() {
  //     if (!currentChat) {
  //       console.log("No currentChat defined!");
  //       return;
  //     }
  //     const link = `${appConfig.APP_ENDPOINT}/join-board/${currentBoard?.shareId}`;

  //     const shareImageUrl = getRuntimeUrl("img/board-share.jpeg");
  //     const response = await fetch(shareImageUrl);
  //     const data = await response.blob();
  //     const metadata = {
  //       type: "image/jpeg",
  //     };
  //     const image = new File([data], "", metadata);
  //     dispatch(sendMessageThunk({
  //       id: randomstring.generate(10),
  //       image,
  //       contactList: [
  //         {
  //           id: currentChat?.id?._serialized,
  //         },
  //       ],
  //       message: `${t('inviteUserModal.hi')} ${currentChat?.displayName ?? currentChat?.name},
  // ${t('inviteUserModal.shareMyTaskBoard')}
  // ${t('inviteUserModal.invitationToCollaborate')} "${currentChat?.displayName ?? currentChat?.name
  //         }".

  // ${t('inviteUserModal.invitationToCollaborate')}
  // ${link}

  // * ${t('inviteUserModal.makeSureForExtension')}
  // https://blueticks.co`,
  //       state: MessageState.pending,
  //       dueDate: moment().toDate().toISOString(),
  //       recurenceUnit: "minute",
  //       recurrenceQuantity: 0,
  //       isRecurring: false,
  //       useSpintax: false,
  //     }));

  //     analyticsService.event("group_shared", {
  //       category: "tasks",
  //       action: "share_group",
  //     });
  //   }
  /*
    function addNewBoardMember(e: any) {
      setShowEmailNotValidWarning(false);
  
      if (!EmailValidator.validate(email)) {
        setShowEmailNotValidWarning(true);
        return;
      }
      addNewBoardMemberAPI(email);
      setEmail("");
      e?.target?.blur();
    }
  
    function copyLink() {
      const link = `${appConfig.APP_ENDPOINT}/join-board/${currentBoard?.shareId}`;
      navigator.clipboard.writeText(link);
    }
  
    function closeTaskViewSideDrawer() {
      dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
    }
  
    async function toggleIsShareByLink(
      event: React.ChangeEvent<HTMLInputElement>
    ) {
      let _board: Board = {
        _id: currentBoard?._id,
        isShareByLink: event.target.checked,
      };
      _board.shareId = event.target.checked ? nanoid() : "";
      dispatch(
        DBBoardsThunks.patch({
          entity: _board,
        })
      );
    }
  
  
    const superheroes = [
      "Peter Parker",         // Spider-Man
      "Tony Stark",           // Iron Man
      "Steve Rogers",         // Captain America
      "Thor Odinson",         // Thor
      "Bruce Banner",         // Hulk
      "Logan Howlett",        // Wolverine
      "Stephen Strange",      // Doctor Strange
      "Matt Murdock",         // Daredevil
      "Wade Wilson",          // Deadpool
      "Clark Kent",           // Superman
      "Bruce Wayne",          // Batman
      "Barry Allen",          // The Flash
      "Arthur Curry",         // Aquaman
      "Oliver Queen",         // Green Arrow
      "Hank Pym",             // Ant-Man
      "Scott Lang",           // Ant-Man (Scott Lang)
      "Wanda Maximoff",       // Scarlet Witch
      "Carol Danvers",        // Captain Marvel
      "Diana Prince",         // Wonder Woman
      "Natasha Romanoff",     // Black Widow
      "Kara Zor-El",          // Supergirl
      "Selina Kyle",          // Catwoman
      "Ororo Munroe",         // Storm
      "Jean Grey",            // Jean Grey
      "Harleen Quinzel",      // Harley Quinn
      "Barbara Gordon",       // Batgirl
      "Raven Darkhölme"       // Mystique
    ];
  
  
    const handleSave = () => {
      const randomIndex = Math.floor(Math.random() * superheroes.length);
      const randomName = superheroes[randomIndex];
      dispatch(createMockPersonThunk({
        columnId: undefined,
        newPersonName: randomName,
        imgUrl: '',
        imgColor: '',
      }));
    }
    */

  return (
    <div>

      <>
        <div style={{
          marginTop: '8px',
          height: '36px',
          marginBottom: '10px',
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center'
        }}>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "700",
              color: "var(--secondary-text-color)",
              fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif"
            }}
          >
            {t('inviteUserModal.membersAndGuests')}
          </div>
          {/* DON'T DELETE COMMENT BELOW - RELEVANT FOR V2.0 */}
          {/* <Button
              onClick={() => setIsMember(false)}
              sx={{
                height: "24px",
                padding: "0",
                textTransform: "none",
                fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif",
              }}
              variant="text"
            >
              Join requests&nbsp;<span>(0)</span>

            </Button> */}
        </div>
        <div style={{
          marginBottom: '24px',
          fontSize: '14px',
          fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif",
          color: 'var(--secondary-text-color)',
        }}>
          <ul>
            <li>
              <b>{t('inviteUserModal.users')}</b>
              <ul>
                <li>
                  <b>{t('inviteUserModal.members')}</b> {t('inviteUserModal.membersCan')}
                </li>
                <li><b>{t('inviteUserModal.guests')}</b> {t('inviteUserModal.guestsCan')}
                </li>
              </ul>
            </li>
            <li>
              <b>{t('inviteUserModal.workspaceMembers')}</b> {t('inviteUserModal.workspaceMembersAre')}
            </li>
            <li>
              <b>{t('inviteUserModal.virtualMembers')}</b> {t('inviteUserModal.virtualMembersAre')}
            </li>
          </ul>
        </div>


        <InviteUserTable />
      </>

      {/* DON'T DELETE COMMENT BELOW - RELEVANT FOR V2.0 */}
      {/* <>
          <div style={{
            marginTop: '32px',
            height: '36px',
            marginBottom: '10px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center'
          }}>
            <div
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "var(--secondary-text-color)",
                fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif"
              }}
            >
              {t('inviteUserModal.requestsToJoin')}
            </div>
            <Button
              onClick={() => setIsMember(true)}
              sx={{
                height: "24px",
                padding: "0",
                textTransform: "none",
                fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif",
              }}
              variant="text"
            >
              {t('inviteUserModal.membersAndGuests')}
            </Button>
          </div>
          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            {t('inviteUserModal.approveOrDecline')}
          </span>
        </> */}



      {/* <div style={{
        marginTop: '25px',
        fontFamily: "'Poppins', 'Inter', 'Assistant', sans-serif",
        marginBottom: '24px',
      }}>
        <div
          style={{
            fontSize: "24px",
            fontWeight: "700",
            color: "var(--secondary-text-color)",
          }}>
          {t('inviteUserModal.mockUsers')}
        </div>
        <div style={{ fontSize: '14px' }}>
          {t('inviteUserModal.addMockUsersInfo')}
        </div>
        <TableContainer sx={{
          background: 'unset',
          overflow: 'hidden',
          boxShadow: 'none',


        }} component={Paper}>
          <Table sx={{ width: '100%', }}>
            <TableHead sx={{
              "& .MuiTableRow-root th": {
                border: 0,
              },
              background: 'unset'
            }}>
              <TableRow>
                <TableCell align="left" key={2} sx={{
                  fontFamily: '"Inter"',
                  padding: '0 0 0 4px',
                  color: '#809BA8',
                  fontSize: '12px',
                  minWidth: '157px',
                }} >Name</TableCell>

                {isCurrentBoardOwner && <TableCell key={4} />}
              </TableRow>
            </TableHead>
            <TableBody sx={{
              width: '100%',
              background: '#fff',
              borderRadius: '8px',
              "& .MuiTableRow-root:last-child td": {
                border: 0,
              },
              "& .MuiTableRow-root:first-of-type td:first-of-type": {
                borderTopLeftRadius: "12px",
              },
              "& .MuiTableRow-root:first-of-type td:last-child": {
                borderTopRightRadius: "12px",
              },
              "& .MuiTableRow-root:last-child td:first-of-type": {
                borderBottomLeftRadius: "12px",
              },
              "& .MuiTableRow-root:last-child td:last-child": {
                borderBottomRightRadius: "12px",
              },
            }}>
              {mockUserList.map((mockUser, index) =>
                <ShareBoardMockUserRow
                  key={mockUser._id}
                  person={mockUser}
                  index={index}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{
          my: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <BTButton
            data-testid='tasks-header-new-group-button'
            sx={{ minWidth: "150px", span: { margin: "0" } }}
            onClick={handleSave}
          >
            <FaPlus style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
            <p style={{
              margin: '0px 5px',
              fontFamily: "'Inter', sans-serif",
              fontWeight: "600",
              fontSize: 14,
            }}>
              {t('inviteUserModal.newMockUser')}
            </p>
          </BTButton>
        </Box>
      </div> */}

    </div>
  );
}
