import  { useEffect, useState } from 'react';
import { CircularProgress, Stack, TextField } from '@mui/material';

function TextFieldFilterRow({ valueField, handleChangeTextValueField }) {
    const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout>();
    const [value, setValue] = useState<string>(valueField)
    const [isLoading, setIsLoading] = useState(false);

    const handleTextFieldChange = (e) => {
        const targetValue = e.target.value;
        setIsLoading(true)
        setValue(targetValue)
        if (updateTimeout) {
            clearTimeout(updateTimeout);
        }

        const newTimeout = setTimeout(() => {
            handleChangeTextValueField(targetValue);
            setIsLoading(false)
        }, 1500);

        setUpdateTimeout(newTimeout);
    };
    useEffect(() => {
        setValue(valueField)

    }, [valueField])

    return (
        <Stack direction={'row'} alignItems={'flex-end'} gap={2.5}>
            <div style={{ position: 'relative', width: '100%' }}>
                <TextField
                    name="results"
                    value={value ?? valueField}
                    autoComplete="off"
                    onChange={handleTextFieldChange}
                    sx={{
                        width: '100%', height: '47px', fontSize: '14px',
                        ".MuiInputLabel-root ": {
                            fontSize: '14px',
                            height: '47px',
                        },
                        '& .MuiInputBase-root.MuiOutlinedInput-root': {
                            fontSize: '14px',
                            height: '47px',
                        },
                        '.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important',
                        },
                        ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: 'rgba(0, 0, 0, 0.87) !important',
                        },
                    }}
                />
                {isLoading && <CircularProgress style={{
                    position: 'absolute',
                    top: '30%',
                    right: '5px',
                    transform: 'translateY(-50%)'
                }} size={20} />}
            </div>

        </Stack>
    );
}

export default TextFieldFilterRow;
