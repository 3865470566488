import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";

import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddAndRemove from "../add-and-remove";
import _ from 'lodash';
import { StyledTextField, errorStyle, setCurrentAction,buttonStyle } from "../automations-helper";
import { getBoard } from "@common-reducers/TaskSelectors";

const CreateBoardAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const [boardName, setBoardName] = useState('Board name');
    const prefix = index === 0 ? 'Then' : 'and then'
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const { workspaceId } = useAppSelector(getBoard);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);

    const debouncedDispatch = useMemo(() =>
        _.debounce((value) => {
            dispatch(automation.setActions(setCurrentAction(actions, index, { workspaceId: workspaceId, boardName: value })));
        }, 300), [dispatch, actions, index, workspaceId]);

    const handleBoardNameChange = useCallback(({ target }: any) => {
        setBoardName(target.value);
        debouncedDispatch(target.value);
    }, [debouncedDispatch]);


    useEffect(() => {
        if (actions) {
            setBoardName(actions[index].boardName);
            dispatch(automation.fieldValidityChanged({ component: 'CreateBoardAction', index, field: 'boardName', isValid: !!actions[index].boardName }));
        }
    }, [actions, dispatch, index]);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} create a
                <StyledTextField onChange={handleBoardNameChange} value={boardName ?? ""} variant={"standard"} placeholder="Board name" sx={{
                    ...buttonStyle,
                    ...(attemptedSubmission && validationStates[`CreateBoardAction_${index}`]?.boardName === false ? errorStyle : {}),
                }} />
            </Box>
            <AddAndRemove elements={actions} index={index} componentName="CreateBoardAction" type={"actions"} />

        </Stack>
    );
};

export default CreateBoardAction;
