// GoogleSheetAuthButton.jsx
import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { appConfig } from '@common/config/app.config'; // Ensure this contains your client ID

const GoogleSheetAuthButton = ({ onAuthSuccess }) => {
  const [GoogleLoginComponent, setGoogleLoginComponent] = useState(null);

  useEffect(() => {
    import('@react-oauth/google')
      .then(module => {
        setGoogleLoginComponent(() => module.GoogleLogin);
      });
  }, []);

  const handleSuccess = (credentialResponse) => {
    const credential = credentialResponse.credential;
    console.log('Credential Response:', credentialResponse);

    // Decode the JWT token
    const decoded: any = jwtDecode(credential);
    console.log('Decoded JWT:', decoded);

    // Extract the email from the decoded token
    const email = decoded.email;
    console.log('User Email:', email);

    onAuthSuccess({credential, email});
  };

  return (
    <GoogleOAuthProvider clientId={appConfig.google_client_id}>
      {GoogleLoginComponent && (
        <GoogleLoginComponent
          onSuccess={handleSuccess}
          onError={error => {
            console.error('Login Failed:', error);
          }}
          scope="https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets.readonly"
        />
      )}
    </GoogleOAuthProvider>
  );
};

export default GoogleSheetAuthButton;
