import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import { Box } from '@mui/system';
import { waContactSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { contactLabel } from '@common-reducers/WhatsAppSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';

type Props = {
    itemId?: string;
    index?: number;
    onClick: (contact: any) => any;
    contactFromExtension?: any;
}

export default function ContactRow({
    itemId,
    index,
    onClick,
    contactFromExtension,
}: Props) {
    const _contact = useAppSelector(state => waContactSelectOneObjectByQuery(state.DBWaContactReducer, { 'id._serialized': itemId }));
    let contact
    if (contactFromExtension) {
        contact = contactFromExtension
    } else {
        contact = _contact
    }

    const contactDisplayString = () => {
        if (contact.id.server === 'c.us' && !contact.isMyContact) {
            return `+${contact.id?.user}`
        } else return contactLabel(contact)
    }
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
                width: '290px',
                '&:hover': {
                    backgroundColor: 'var(--button-hovered-color)',
                },
            }}
            onClick={() => onClick(contact)}
        >
            <ContactAvatar
                size={24}
                textSize={12}
                contactId={contact?.id?._serialized}
                shouldGetProfilePic={false}
            />
            <Box ml={2} sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{contactDisplayString()}</Box>
        </Box>
    )
}