import { logoutThunk } from "@common-reducers/UserThunks";
import { selectCurrentUserEmail } from "@common-reducers/UserSelectors";
import { useAcceptBoardInventionMutation, useAcceptWorkspaceInventionMutation } from "@common-reducers/backend-api/backend-api";
import { useRouter } from "@common-reducers/hooks/use-router";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useEffect, useState } from "react";

interface UseAcceptInvention {
  loading: boolean;
  message: string;
  authorized?: boolean;
  showDialog: boolean;
  workspaceId: string;
  handleLoginWithDifferentUser: () => void;
  hideDialog: () => void;
}

export const useAcceptInvention = (id: string, inviteId: string, isWorkspace = true): UseAcceptInvention => {
  const [message, setMessage] = useState("");
  const [authorized, setAuthorized] = useState<boolean | undefined>();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const email = useAppSelector((state) => selectCurrentUserEmail(state));
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [acceptWorkspaceInvitationMutation] = useAcceptWorkspaceInventionMutation();
  const [acceptBoardInvitationMutation] = useAcceptBoardInventionMutation();
  const [workspaceId, setWorkspaceId] = useState();

  const handleLoginWithDifferentUser = () => {
    dispatch(logoutThunk());
    const redirectPath = `/join-${isWorkspace ? "workspace" : "board"}/${id}?inviteId=${inviteId}`;
    router.navigate(`/login?redirect=${encodeURIComponent(redirectPath)}`);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const acceptInventionCallback = useCallback(async () => {
    try {
      setLoading(true);

      let response;
      if (isWorkspace) {
        response = await acceptWorkspaceInvitationMutation({ workspaceId: id, inviteId }).unwrap();
      } else {
        response = await acceptBoardInvitationMutation({ boardId: id, inviteId }).unwrap();
      }
      setLoading(false);
      if (!response.authorized) {
        setMessage("Unauthorized!");
        setAuthorized(false);
      } else {
        setAuthorized(true);
        if (response.joined) {
          setMessage(`Successfully joined the ${isWorkspace ? "workspace" : "board"} 🎉`);
          //Well done! We are excited to have you onboard
        }
        else {
          setMessage(`You are already in the ${isWorkspace ? "workspace" : "board"}`);
        }
        setWorkspaceId(response.workspaceId);
      }
      if (response.error) {
        if (response.error === "invitation-not-intended") {
          setMessage(`The invitation is not intended for you (${email})`);
          setShowDialog(true);
        } else {
          setMessage(response.error);
        }
      }
    } catch (error) {
      setMessage(`Error accepting ${isWorkspace ? "workspace" : "board"} invitation`);
      setLoading(false);
    }
  }, [isWorkspace, acceptWorkspaceInvitationMutation, id, inviteId, acceptBoardInvitationMutation, email]);

  useEffect(() => {
    acceptInventionCallback();
  }, [acceptInventionCallback]);

  return {
    loading,
    message,
    authorized,
    showDialog,
    handleLoginWithDifferentUser,
    hideDialog,
    workspaceId
  };
};
