import { getHandleId } from '@common-services/utils';
import { Handle, Position } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import NodeContainer from '../node-container';
import classes from "./send-message-node.module.scss";
import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';



export default function SendMessageNode({ id, data }) {
    const type = data?.type

    const nodeColor = nodeTypes?.[type]?.color





    return (
        <>
            <Handle type="target" position={Position.Left} id={getHandleId(data.handles, 'target', 0)}
                style={{ backgroundColor: nodeColor, zIndex: 3, }} />
            <NodeContainer type={type} id={id} />
            <CustomSourceHandle type="source" position={Position.Right} id={getHandleId(data.handles, 'source', 0)}
                className={type === 'sendMessage' ? classes.send_message_source_handle : classes.message_contact_source_handle} connectioncount={1} />
        </>
    )
}