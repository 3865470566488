
import { Button, ButtonProps, CircularProgress, SxProps, Theme } from "@mui/material";
import React from "react";
import classes from './ws-button.module.scss';


export interface WSButtonProps extends ButtonProps<any> {
    onClick?: () => void;
    showloader?: boolean | number | undefined;
    disabled?: boolean;
    style?: React.CSSProperties;
    target?: string;
    children?: JSX.Element[] | JSX.Element | string | any[];
    sx?: SxProps<Theme>;
}

export interface WSButtonState {

}

const WSButton = ({type, target, href, sx, ...props}: WSButtonProps) => {
    return (<Button
        type={type ?? "button"}
        className={classes.button}
        color="primary"
        target={target}
        href={href}
        {...props}
        disabled={(!!props?.showloader || props?.disabled) ?? false}
        onClick={props.onClick}
        sx={sx}>
        {props.children}
        {!!props?.showloader && <CircularProgress size={24} style={{ color: 'white' }} />}
    </Button>);
}

export default WSButton;