import { appConfig } from "@common-config/app.config";

export interface VznLyticsEvent {
  event_name?: string;
  label?: string;
  triggered_by?: "click" | "view" | "scroll";
  category?: string;
  sub_category?: string;
  value?: any;
  entity_id?: string;
  entity_display_name?: string;
  entity_type?: string;
  data?: any;
  email?: string;
}

export class VznLytics {
  static Endpoint = appConfig.VZNLYTICS_ENDPOINT;

  private getUrlParams(): string {
    if (typeof window !== 'undefined' && window.location) {
      return new URLSearchParams(window.location.search).toString();
    }
    return '';
  }

  event(event: VznLyticsEvent) {
    try {
      const urlParams = this.getUrlParams();
      const url = urlParams ? `${VznLytics.Endpoint}/events?${urlParams}` : `${VznLytics.Endpoint}/events`;

      // console.log('Sending analytics event:', event);
      // console.log('URL:', url);

      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event)
      }).catch(error => console.error('Error sending analytics event:', error));

      // console.log('Analytics event sent:', event);      
    } catch (e) {
      console.error('Error in VznLytics.event:', e);
    }
  }
}
