import { Button, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/types';
import React from 'react'

type Props = {
    onClick: any;
    buttonString: string;
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string};
    buttonWidth?: string;
}

export default function BulkButton({
    onClick,
    buttonString,
    Icon,
    buttonWidth,
}: Props) {
    return (
        <Button
            startIcon={<Icon style={{ color: 'var(--bulk-button-text)' }} />}
            sx={{
                backgroundColor: 'var(--bulk-button-bg)',
                ':hover': {
                    backgroundColor: 'var(--bulk-button-bg-hover)',
                },
                color: 'var(--bulk-button-text)',
                width: buttonWidth ? buttonWidth :'fit-content',
                marginLeft: '10px',
                boxShadow: 'none',
                borderRadius: '10px',
                fontSize: '15px',
                fontWeight: 900,
                textTransform: 'none',
                textWrap: 'nowrap',
                paddingInline: '30px',
            }}
            variant="contained"
            onClick={onClick}
        >
            {buttonString}
        </Button>
    )
}