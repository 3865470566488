import BTButton from "@common-components/bt-button/bt-button";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import {
  isTempId
} from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { createWAContactBoardThunk } from "@extension-context-content/thunks/WhatsappThunks";
import { ReactElement } from "react";
import { BiTaskX } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import classes from "./tasks-panel.module.scss";

export default function OpenWaBoard(): ReactElement {

  console.log("OpenWaBoard rendering");
  const dispatch = useAppDispatch()
  const selectedBoardId = useAppSelector(getSelectedBoardId);


  async function addNewWABoard() {
    dispatch(createWAContactBoardThunk({}))
  }


  return (
    <div className={classes.emptyBoard}>
      <div>
        <div className={classes.taskXIcon}>
          <BiTaskX />
        </div>
        <p style={{ margin: "8px 0" }}>
          This <b>chat</b> not have board yet.
        </p>

        <BTButton
          sx={{ width: '100%', maxWidth: "250px", span: { margin: "0" } }}
          onClick={addNewWABoard}
          disabled={!!selectedBoardId && isTempId(selectedBoardId)}
          loading={!!selectedBoardId && isTempId(selectedBoardId)}
          startIcon={<FaPlus style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />}
        >
          Create tasks board
        </BTButton>

      </div>
    </div>
  );
}

