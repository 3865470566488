import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectOptionPickersColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { taskColumnOptionSelectByQuery } from "@common-reducers/DBServiceThunks";
import { NullableId } from "@common/types/interface";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";

import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import ColumnOptionsSelector from "../dropdowns/options-dropdown";
import { setCurrentCondition } from "../automations-helper";

const StatusIsSomethingCondition = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnId, setColumnId] = React.useState<NullableId>(null);
    const [columnName, setColumnName] = React.useState<string>('status');
    const [optionName, setOptionName] = React.useState<string>('something');
    const [dropdown, setDropdown] = React.useState<string>('');

    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectOptionPickersColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
        setDropdown(dropdownOption);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))


    const handleColumnChange = (id, name) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, columnId: id })));
        setColumnName(name);
        setOptionName('something')
        setColumnId(id);
        handleClose();
    };

    const clickedOption = (option) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType,optionId: option._id })));
        setOptionName(option.label)
        handleClose();
    };

    useEffect(() => {
        if (conditions) {
            const selectedColumnId = conditions[index].columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnId(column?.id);
            setColumnName(column?.name ?? "status")
            dispatch(automation.fieldValidityChanged({ component: 'StatusIsSomethingCondition', index, field: 'columnId', isValid: !!column?.name }));
            const selectedOptionSelectedId = conditions[index].optionId;
            const optionSelected = columnOptions.find(item => item._id === selectedOptionSelectedId);
            setOptionName(optionSelected?.label ?? "something");
            dispatch(automation.fieldValidityChanged({ component: 'StatusIsSomethingCondition', index, field: 'optionId', isValid: !!optionSelected?.label }));
        }
    }, [conditions, columnOptions, filteredColumns, index, dispatch]);

    return (
        <Stack>
            <Stack key={index} sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    and only if
                    <AutomationButton
                        componentName='StatusIsSomethingCondition'
                        index={index}
                        option='columnId'
                        label={columnName}
                        handleClick={(e) => handleClick(e, 'pickerColumn')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> is
                    <AutomationButton
                        componentName='StatusIsSomethingCondition'
                        index={index}
                        option='optionId'
                        label={optionName}
                        handleClick={(e) => handleClick(e, 'columnOptions')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                </Box>
                <AddAndRemove elements={conditions} index={index} type={"conditions"} componentName={"StatusIsSomethingCondition"} />
            </Stack>

            {dropdown === 'pickerColumn' && (
                <PickerColumn
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filteredColumns={filteredColumns}
                    handleColumnChange={handleColumnChange}
                />)}

            {dropdown === 'columnOptions' && (
                <ColumnOptionsSelector boardId={boardId} columnId={columnId} onSelectOption={clickedOption} anchorEl={anchorEl}
                    handleClose={handleClose}
                />)}

        </Stack>


    )
}
export default StatusIsSomethingCondition