import { Stack } from '@mui/system';
import React from 'react'

type Props = {
    htmlTitle?: string;
    htmlSubTitle?: string;
    title?: string;
    subTitle?: string;
    sx?: any;
}

export default function CampaignTabHeader({
    htmlTitle,
    htmlSubTitle,
    title,
    subTitle,
    sx
}: Props) {
    return (
        <Stack direction='column' sx={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
            ...sx
        }}>
            {htmlTitle ? (
                <div dangerouslySetInnerHTML={{ __html: htmlTitle }} style={{
                    textAlign: 'center',
                    fontSize: '36px',
                    fontWeight: '700',
                    marginBottom: '10px',
                }} />
            ) : (
                <h1 style={{
                    textAlign: 'center',
                    fontSize: '36px',
                    fontWeight: '700',
                    marginBottom: '10px',
                    marginTop: '15px',

                }}>{title}</h1>
            )}
            {htmlSubTitle ? (
                <div dangerouslySetInnerHTML={{ __html: htmlSubTitle }} style={{
                    textAlign: 'center',
                    fontSize: '17px',
                    fontWeight: '400',
                    marginBottom: '10px',
                }} />
            ) : (
                <span style={{
                    textAlign: 'center',
                    fontSize: '17px',
                    fontWeight: '400',
                    marginBottom: '10px',
                }}>{subTitle}</span>
            )}
        </Stack >
    )
}
