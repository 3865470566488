import { isTempId } from "@common-reducers/DBServiceThunks";
import {
  setExtraDataOfTaskId
} from "@common-reducers/ExtraDataReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { BsChatSquareTextFill } from "react-icons/bs";
import classes from "./extraDataButton.module.scss";
import { getTaskExtradataLength } from "@common-reducers/TaskSelectors";

export interface Props {
  taskId: string;
}

function ExtraDataButton({ taskId }: Props) {
  const dispatch = useAppDispatch()
  const taskExtradataLength = useAppSelector((state) => getTaskExtradataLength(state, taskId));

  function openExtraDataPanel() {
    updateURLParameter(taskId)
    dispatch(setExtraDataOfTaskId(taskId));
    dispatch(taskActions.setTaskViewSideDrawerOpenWith("ExtraDataPanel"));
  }
  function updateURLParameter(taskId) {
    let currentUrl = window.location.href;
    let newUrl;
    if (currentUrl.includes('taskId')) {
      newUrl = currentUrl.replace(/taskId=[^&]+/, `taskId=${taskId}`);
    } else{
    newUrl = currentUrl + `&taskId=${taskId}`;
    }
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
  return (

    <button className={classes.convsBtn}
      disabled={isTempId(taskId)}
      onClick={openExtraDataPanel} >
      <BsChatSquareTextFill color={isTempId(taskId) ? 'lightgrey' :"#809ba8"} size={18} />

      {taskExtradataLength > 0 && (
        <div className={classes.convsBtnBadge}>{taskExtradataLength}</div>
      )}
    </button>
  );
}

export default ExtraDataButton;
