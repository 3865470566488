
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import classes from './bt-button.module.scss';
interface BTButtonProps {
  children: React.ReactNode;
  sx?: any;
  iconsrcbefore?: string;
  iconsrcafter?: string;
  iconW?: number;
  iconH?: number;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  btvariant?: 'primary' | 'secondary' | 'text' | 'pink';
}

function BTButton(props: BTButtonProps & LoadingButtonProps) {
  let buttonTheme;
  switch (props.btvariant) {
    case 'secondary': {
      buttonTheme = {
        cursor: "pointer",
        margin: "0",
        textDecoration: "none",
        borderRadius: "8px",
        backgroundColor: "var(--billing-btn-bg)",
        color: "var(--link-text-color)",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "16px",
        padding: "6px",
        textAlign: "center",
        textTransform: "capitalize",
        maxWidth: "80%",
        fontFamily: "'Poppins', 'Inter', sans-serif",
      };
      break;
    }
    case 'text': {
      buttonTheme = {
        cursor: "pointer",
        margin: "0",
        textDecoration: "none",
        borderRadius: "8px",
        color: "var(--link-text-color)",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "16px",
        padding: "6px",
        textAlign: "center",
        textTransform: "capitalize",
        maxWidth: "80%",
        background: 'transparent',
        fontFamily: "'Poppins', 'Inter', sans-serif",
      };
      
      break;
    }
    case 'pink': {
      buttonTheme = {
        cursor: "pointer",
        margin: "0",
        textDecoration: "none",
        borderRadius: "8px",
        color: "var(--background-default)",
        fontSize: "14px",
        padding: "6px",
        textAlign: "center",
        textTransform: "capitalize",
        fontFamily: "'Poppins', 'Inter', sans-serif",
        fontWeight: "800",
        background: "linear-gradient(87.82deg, #f656a1 0%, #ffa3ce 100%)",
        ...props.sx,
      };
      
      break;
    }
    case 'primary':
    default: {
      buttonTheme = {
        "&:disabled": {
          opacity: '0.8',
          color: "#fff"
        },
        "& .MuiButton-startIcon": {
          display: props.loading && "none",
        },
        "& .MuiLoadingButton-loadingIndicator": {
          // left: props.startIcon ? '30%' : "8%",
          position: "inherit",
          left: "unset",
          marginRight: "8px",
          color: "#fff"
        },
        transition: '0.2s',
        color: "#ffffff",
        fontWeight: "600",
        lineHeight: "21px",
        borderRadius: "8px",
        padding: "7.5px 28px",
        background: "linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)",
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        fontFamily: "'Poppins', 'Inter', sans-serif",
        ...props.sx,
      };
      break;
    }
  }

  return (
    <LoadingButton
      {...props}
      className={classes.btButton}
      loadingPosition={props.startIcon ? 'start' : undefined}
      sx={buttonTheme}
    >
      {!props.loading && (
        <img src={props.iconsrcbefore}
          alt=""
          style={{
            marginRight: props.iconsrcbefore ? "5px" : '0',
            width: props.iconW,
            height: props.iconH,
          }} />

      )}

      {props.children}

      {!props.loading &&
        <img src={props.iconsrcafter} alt="" style={{ marginLeft: props.iconsrcafter ? "5px" : '' }}></img>
      }
    </LoadingButton >
  );
}
export default BTButton;
