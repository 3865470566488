import React from 'react';
import { Button } from '@mui/material';
import { buttonSelectedStyle, errorStyle, buttonStyle } from "./automations-helper";

const AutomationButton = ({
  componentName, 
  index, 
  option, 
  label, 
  handleClick,
  validationStates,
  attemptedSubmission,

}) => {
  return (
    <Button 
      sx={{
        ...buttonStyle,
        ...(validationStates[`${componentName}_${index}`]?.[option] ? buttonSelectedStyle : {}),
        ...(attemptedSubmission && !validationStates[`${componentName}_${index}`]?.[option] ? errorStyle : {})
      }} 
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default AutomationButton;