import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { IconButton } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function TokenProbability({ localChatGPTState, setLocalChatGPTState }: Props) {

    const handleClickRemoveTokenProbabilityInput = (index: number) => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                tokenProbability: prev.advanced?.tokenProbability?.filter((_, i) => i !== index)
            }
        }));
    };

    const addTokenProbabilityInput = () => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                tokenProbability: [...(prev?.advanced?.tokenProbability ?? []), { id: '', probability: '' }]
            }
        }));
    };

    const tokenProbabilityInputUI = (tokenP, index) => <>
        <div key={`tp_${index}`} style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            {title(`Token Probability ${index + 1}`, { marginTop: '20px', })}
            <IconButton sx={{
                zIndex: 2,
                width: '30px',
                height: '30px',
                borderRadius: '8px',
                backgroundColor: '#FEE7E7',
                color: 'red',
                ".MuiSvgIcon-root": {
                    width: '20px',
                    height: '20px',
                }
            }} onClick={() => handleClickRemoveTokenProbabilityInput(index)}><RemoveCircle /></IconButton>
        </div>

        {title('Token ID', { marginTop: '20px', })}
        <BotInputField
            value={tokenP.id ?? ''}
            onChange={(newValue) => {
                setLocalChatGPTState({
                    ...localChatGPTState,
                    advanced: {
                        ...localChatGPTState.advanced,
                        tokenProbability: localChatGPTState.advanced?.tokenProbability?.map((tp, i) => {
                            if (i === index) {
                                return {
                                    ...tp,
                                    id: newValue
                                }
                            }
                            return tp
                        })
                    }
                })
            }}
        />

        {secondaryTitleWithMaybeLink(`To obtain token IDs, see the `, {}, 'OpenAI Tokenizer tool', 'https://platform.openai.com/tokenizer?view=bpe', ' (available for GPT-2, GPT-3 and GPT-4)')}

        {title('Probability', { marginTop: '20px', })}
        <BotInputField
            value={tokenP?.probability ?? ''}
            type='number'
            onChange={(newValue) => {
                if (/^-?\d*\.?\d*$/.test(newValue) && +newValue >= -100 && +newValue <= 100) {
                    setLocalChatGPTState({
                        ...localChatGPTState,
                        advanced: {
                            ...localChatGPTState.advanced,
                            tokenProbability: localChatGPTState.advanced?.tokenProbability?.map((tp, i) => {
                                if (i === index) {
                                    return {
                                        ...tp,
                                        probability: newValue
                                    }
                                }
                                return tp
                            })
                        }
                    })
                }
            }}
        />

        {secondaryTitleWithMaybeLink(`The likelihood of selecting a specific Token ID in the completion can be adjusted. For example, a value of -1 decreases the likelihood of selection, while a value of 1 increases it. A value of -100 completely bans the token, and a value of 100 enforces its exclusive selection`, {})}
        {secondaryTitleWithMaybeLink(`Must be a number between -100 and 100`, {})}
    </>

    const addTokenProbabilityButton = () => {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
            }}>
                <BTbotbutton styleType='dashed' onClick={addTokenProbabilityInput}>Add token probability</BTbotbutton>
            </div>
        )
    }

    return (
        <>
            {title('Token Probability', { marginTop: '20px', })}
            <div>
                {(localChatGPTState?.advanced?.tokenProbability ?? []).map((tokenP, index) => {
                    return tokenProbabilityInputUI(tokenP, index)
                })}

                {addTokenProbabilityButton()}

                {secondaryTitleWithMaybeLink('Configuration of specific token occurrences in the generated response', {})}
            </div>
        </>
    );
}
