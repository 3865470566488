import { getCreditCardLogo } from "@common-components/payment-form/payment-form";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { subscriptionSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { selectUser } from "@common-reducers/UserSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import moment from "moment";
import { useLocation } from 'react-router-dom';

export interface GroupedTransaction {
    transaction: ChargebeeTransaction | null;
    refunds: ChargebeeTransaction[];
}

interface Props {
    group: GroupedTransaction;
    index: number;
    handleOpenModal: (event: any, invoiceId: string) => void
}

const GroupedChargebeeTransaction: React.FC<Props> = ({ group, index, handleOpenModal }) => {
    const hasRefunds = group.refunds.length > 0;
    const panelId = `panel${index}`;
    const user = useAppSelector(selectUser);
    const subscription = useAppSelector((state) => subscriptionSelectOneObjectByQuery(state.DBSubscriptionReducer, { subscription_id: group.transaction.subscription_id }));

    const planName = subscription?.plan_id;
    const paymentMethodDetails: PaymentMethodDetails = JSON.parse(group.transaction.payment_method_details);
    const location = useLocation();

    const buildCreditCard = () => {
        return <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: 140
            }}
        >
            <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                <img style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                }} src={getCreditCardLogo(paymentMethodDetails.card.brand)} alt="" />
            </Stack>
            {<span>****</span>}
            <span>{paymentMethodDetails.card.last4}</span>
        </div>
    }

    let paymentMethodElement = null;
    switch (group.transaction.payment_method) {
        case 'card':
            paymentMethodElement = buildCreditCard();
            break;
    }

    const buildPaypal = (invoice) => {
        return <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: 140
            }}
        >
            <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                <img style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                }} src={getCreditCardLogo('Paypal')} alt="" />
            </Stack>
        </div>
    }

    return <Accordion
        expanded={group.refunds.length > 0}
        sx={{
            "& .MuiAccordionSummary-content": {
                cursor: 'default',
            },
        }}
    >
        <AccordionSummary
            aria-controls={`${panelId}bh-content`}
            id={`${panelId}bh-header`}
        >
            <Stack>
                {planName && (
                    <Box>
                        {planName}
                    </Box>
                )}
                {planName && <Divider sx={{ my: 1 }} />}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(7, 1fr)',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <div style={{ userSelect: 'text' }}>{group.transaction.id}</div>
                    <Box sx={{ width: 90 }}>{moment.utc(group.transaction.date).format('DD-MMM-YY')}</Box>
                    {paymentMethodElement}
                    <div style={{ textAlign: 'center' }}>{group.transaction.amount / 100}</div>
                    <div style={{ textAlign: 'center' }}>{group.transaction.currency_code}</div>
                    <Button
                        sx={{
                            cursor: 'pointer',
                            margin: '0',
                            textDecoration: 'none',
                            borderRadius: '12px',
                            backgroundColor: 'var(--billing-btn-bg)',
                            color: 'var(--link-text-color)',
                            fontSize: '12px',
                            fontWeight: '600',
                            lineHeight: '16px',
                            padding: '6px',
                            textAlign: 'center',
                            textTransform: 'capitalize',
                        }}
                        onClick={
                            () => alert('Implement chargebee invoice view here')
                        }
                    >
                        View
                    </Button>
                    {(user?.role === 'support' || user?.role === 'admin') && (
                        <Button
                            sx={{
                                cursor: 'pointer',
                                margin: '0',
                                textDecoration: 'none',
                                borderRadius: '12px',
                                backgroundColor: 'var(--billing-btn-bg)',
                                color: 'var(--link-text-color)',
                                fontSize: '12px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                padding: '6px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                            }}
                            onClick={(e) => handleOpenModal(e, group.transaction.id)}
                        >
                            Refund
                        </Button>
                    )}
                </Box>
            </Stack>
        </AccordionSummary>
        {hasRefunds && (
            <AccordionDetails>
                <Typography component="div">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {group.refunds.length > 0 && (
                            <Box>
                                <b>Refunds:</b>
                                {group.refunds.map((refund, refundIndex) => (
                                    <Box
                                        key={refundIndex}
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(7, 1fr)',
                                            alignItems: 'center',
                                            py: 2,
                                            px: 0,
                                            gap: 2,
                                            borderBottom: '1px solid lightgrey',
                                        }}
                                    >
                                        <div>{refund['invoice-id']}</div>
                                        <div>{refund['financial-transactions']['financial-transaction']['date-created']}</div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                                                <img
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                    src={getCreditCardLogo(refund['financial-transactions']['financial-transaction']['credit-card']?.['card-type'])}
                                                    alt=""
                                                />
                                            </Stack>
                                            <span>{refund['financial-transactions']['financial-transaction']['credit-card']?.['card-last-four-digits'].toString().padStart(4, '0')}</span>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>{refund['financial-transactions']['financial-transaction'].amount}</div>
                                        <div style={{ textAlign: 'center' }}>{refund['financial-transactions']['financial-transaction'].currency}</div>
                                        <Button
                                            sx={{
                                                cursor: 'pointer',
                                                margin: '0',
                                                textDecoration: 'none',
                                                borderRadius: '12px',
                                                backgroundColor: 'var(--billing-btn-bg)',
                                                color: 'var(--link-text-color)',
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                lineHeight: '16px',
                                                padding: '6px',
                                                textAlign: 'center',
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => window.open(`/invoices/${refund['invoice-id']}${location.search}`, '_blank')}
                                        >
                                            View
                                        </Button>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Typography>
            </AccordionDetails>
        )}
    </Accordion>
}

export default GroupedChargebeeTransaction;