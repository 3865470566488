import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import RowStack from "@common-components/common/row-stack/RowStack";
import { RemoveCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getChatbotTempId } from "@common-services/utils";
import classes from "./webhook-node.module.scss";

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
    localEntitiesArrayToDelete: any;
    setLocalEntitiesArrayToDelete: (localEntitiesArrayToDelete: any) => void;
    localNodeHandles: any;
    setLocalNodeHandles: (localNodeHandles: any) => void;
}

export default function ResponseRouting({
    localWebhookState,
    setLocalWebhookState,
    localEntitiesArrayToDelete,
    setLocalEntitiesArrayToDelete,
    localNodeHandles,
    setLocalNodeHandles,
}: Props) {



    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitle = (secondaryTitle: string, style?: any) => {
        return <div className={classes.secondary_title} style={style}>{secondaryTitle}</div>
    }


    const setResponseRoute = (newValue: string, index: number) => {
        const responseRoutes = [...localWebhookState.responseRouting.responseRoutes]
        responseRoutes[index] = {
            ...responseRoutes[index],
            statusCode: newValue,
        }
        setLocalWebhookState({
            ...localWebhookState,
            responseRouting: {
                ...localWebhookState.responseRouting,
                responseRoutes: responseRoutes,
            }
        })
    }


    const handleClickRemoveResponseRoute = (index: number) => {
        const responseRoutes = [...localWebhookState.responseRouting.responseRoutes]

        setLocalEntitiesArrayToDelete([...localEntitiesArrayToDelete, responseRoutes?.[index]?.handleId])
        
        responseRoutes.splice(index, 1)
        setLocalWebhookState({
            ...localWebhookState,
            responseRouting: {
                ...localWebhookState.responseRouting,
                responseRoutes: responseRoutes,
            }
        })
        setLocalNodeHandles({
            ...localNodeHandles,
            outputs: localNodeHandles.outputs.filter((output) => output.id !== responseRoutes?.[index]?.handleId)
        })
    }


    const responseRouteRow = (responseRoute, index) => {
        return (
            <div key={index} style={{ marginBottom: '15px', }}>
                < BotInputField
                    value={responseRoute.statusCode}
                    onChange={(newValue) => setResponseRoute(newValue, index)
                    }
                    placeholder='200'
                    endAdornment={
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            height: '30px',
                            color: 'red',
                            borderRadius: '5px',
                            backgroundColor: '#FFE7E7',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            ".MuiSvgIcon-root:hover": {
                                color: 'red',
                            },
                            '&:hover': {
                                backgroundColor: '#F6EBEF',
                            }
                        }} onClick={() => handleClickRemoveResponseRoute(index)}><RemoveCircle /></IconButton>
                    }
                />
            </div>
        )
    }


    return (
        <div className={classes.divider}>
            <RowStack sx={{ justifyContent: 'space-between' }}>
                {title('Response Routing')}

                <BotToggleSwitch
                    onChange={() => {
                        let responseRoutes = localWebhookState.responseRouting.responseRoutes
                        if (responseRoutes.length === 0) {
                            responseRoutes = [{ statusCode: '', handleId: getChatbotTempId() }]
                            setLocalNodeHandles({
                                ...localNodeHandles,
                                outputs: [...localNodeHandles.outputs, { id: responseRoutes[0].handleId, type: 'source' }]
                            })
                        }
                        setLocalWebhookState({
                            ...localWebhookState,
                            responseRouting: {
                                ...localWebhookState.responseRouting,
                                isResponseRouting: !localWebhookState.responseRouting.isResponseRouting,
                                responseRoutes,
                            }
                        })
                    }}
                    checked={localWebhookState.responseRouting.isResponseRouting}
                    isOptional={true}
                    sx={{ marginBottom: '20px', }} />
            </RowStack>
            {secondaryTitle('Split your chatbot based on response status codes (200, 400, 500, etc).')}

            {localWebhookState.responseRouting.isResponseRouting &&
                <>
                    {title('Expected Statuses', { marginTop: '10px', })}
                    {(localWebhookState?.responseRouting?.responseRoutes ?? []).map((responseRoute, index) => (
                        responseRouteRow(responseRoute, index)
                    ))}
                    <BTbotbutton
                        sx={{ width: '165px', }}
                        styleType="dashed"
                        onClick={() => {
                            const newHandleId = getChatbotTempId()
                            setLocalWebhookState({
                                ...localWebhookState,
                                responseRouting: {
                                    ...localWebhookState.responseRouting,
                                    responseRoutes: [
                                        ...localWebhookState.responseRouting.responseRoutes,
                                        { statusCode: '', handleId: newHandleId }
                                    ],
                                }
                            })
                            setLocalNodeHandles({
                                ...localNodeHandles,
                                outputs: [...localNodeHandles.outputs, { id: newHandleId, type: 'source' }]
                            })
                        }
                        }>
                        Add Expected Status
                    </BTbotbutton>
                </>
            }
        </div>
    )
}