
type Props = {
    id: string;
}


export default function GoogleSheetsContainer({ id }: Props) {

    return (
        <></>
    )
}