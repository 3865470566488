import { Checkbox, CheckboxProps } from '@mui/material';
import React from 'react';


export const BTCheckbox = React.forwardRef((props: CheckboxProps, ref: any) => {
    return <Checkbox sx={{
        ...props.sx,
        color: 'var(--primary)',
    }} {...props} />
});
