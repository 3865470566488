import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectOptionPickersColumns } from "@common-reducers/AutomationsSelectors";
import { taskColumnOptionSelectByQuery } from "@common-reducers/DBServiceThunks";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";

import { useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import ColumnOptionsSelector from "../dropdowns/options-dropdown";
import { setCurrentAction } from "../automations-helper";

const ChangeStatusAction = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = useState(null);
    const prefix = index === 0 ? 'Then set' : 'and then set';
    const [columnData, setColumnData] = useState({ columnId: null, columnName: 'status', optionName: 'something' });
    const [dropdown, setDropdown] = useState<string>('');
    const boardId = useAppSelector(getSelectedBoardId);
    const filteredColumns = useAppSelector((state) => selectOptionPickersColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId: columnData.columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type) => {
        setAnchorEl(event.currentTarget);
        setDropdown(type);
    };


    const handleColumnChange = (id, name) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, columnId: id })));
        setColumnData(prev => ({ ...prev, columnName: name, columnId: id }));
        setAnchorEl(null);
    };

    const handleOptionSelect = (option) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, optionId: option._id })));
        setColumnData(prev => ({ ...prev, optionName: option.label }));
        setAnchorEl(null);
    };

    useEffect(() => {
        if (actions) {
            const selectedColumn = filteredColumns.find(item => item.id === actions[index].columnId);
            const selectedOption = columnOptions.find(item => item._id === actions[index].optionId);

            setColumnData({
                columnId: selectedColumn?.id,
                columnName: selectedColumn?.name ?? "status",
                optionName: selectedOption?.label ?? "something",
            });

            dispatch(automation.fieldValidityChanged({ component: 'ChangeStatusAction', index, field: 'columnId', isValid: !!actions[index].columnId }));
            dispatch(automation.fieldValidityChanged({ component: 'ChangeStatusAction', index, field: 'optionId', isValid: !!actions[index].optionId }));
        }
    }, [actions, columnOptions, dispatch, filteredColumns, index]);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix}

                <AutomationButton
                    componentName='ChangeStatusAction'
                    index={index}
                    option='columnId'
                    label={columnData.columnName}
                    handleClick={(e) => handleClick(e, 'pickerColumn')}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
                <AutomationButton
                    componentName='ChangeStatusAction'
                    index={index}
                    option='optionId'
                    label={columnData.optionName}
                    handleClick={(e) => handleClick(e, 'columnOptions')}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />

            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="ChangeStatusAction" />

            {dropdown === 'pickerColumn' && (
                <PickerColumn
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    filteredColumns={filteredColumns}
                    handleColumnChange={handleColumnChange}
                />)}

            {dropdown === 'columnOptions' && (
                <ColumnOptionsSelector
                    boardId={boardId}
                    columnId={columnData.columnId}
                    onSelectOption={handleOptionSelect}
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                />)}
        </Stack>
    );
};

export default ChangeStatusAction;
