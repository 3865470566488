
type Props = {
    id: string;
}


export default function ConditionNodeContainer({ id }: Props) {

    return (
        <div style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            width: '100%',
        }}>This is condition content</div >
    )
}