import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { getHandleId } from '@common-services/utils';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Handle, Position } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import classes from "./question-node.module.scss";
import AttachmentPreview from '@common-components/attachment-preview/attachment-preview';
import RowStack from '@common-components/common/row-stack/RowStack';
import { DeleteOutline, Download } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { orangeStyle } from '@common/reusable/commonStyles';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';
import { useStore } from '@common/reducers/ChatbotReducer';

type Props = {
    width?: string;
    disabled?: boolean;
    id: string;
}

export default function QuestionNodeContainer({ width, disabled, id }: Props) {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => getNodeDataByNodeId(state, id))

    const { setNodes } = useStore();

    let handleIds = Object.keys(data?.answers ?? {})
    if (data?.type === 'status') {
        handleIds = handleIds.sort((a, b) => data.answers[a].order - data.answers[b].order)
    }
    const nodeColor = nodeTypes?.[data?.type]?.color



    const removeAttachment = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: { questionAsset: undefined }
        }))
    };

    async function downloadAttachment(attachmentUrl: string, attachmentName: string) {
        try {
            const response = await fetch(attachmentUrl);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.setAttribute('download', attachmentName || 'download');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed', error);
        }
    }

    const attachmentBox = () => <Stack spacing={2}>
        {data?.questionAsset &&
            <div style={{
                position: "relative",
            }}>
                <Stack alignItems="center" justifyContent="center">
                    <RowStack sx={{ mb: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<DeleteOutline />}
                            onClick={removeAttachment}
                            style={{
                                ...orangeStyle,
                                marginRight: '10px',
                            }}
                        >
                            REMOVE ATTACHMENT
                        </Button>
                        <IconButton onClick={() => downloadAttachment(data?.questionAsset?.url, data?.questionAsset?.name)}>
                            <Download />
                        </IconButton>
                    </RowStack>

                    <AttachmentPreview
                        attachmentName={data?.questionAsset?.name}
                        attachmentType={data?.questionAsset?.type}
                        attachmentUrl={data?.questionAsset?.url}
                        previewImageWidth={'450px'}
                    />
                </Stack>
            </div>
        }
    </Stack >



    return (
        <>
            <div className={`${classes.question} ${classes.question_node_wrapper}`}>
                <p style={{ margin: 0 }}>{data?.question}</p>
                {attachmentBox()}
            </div>
            <div className={classes.answers}>
                {handleIds.map((handleId, i) => {
                    const answer = data?.type === 'question' ? data?.answers?.[handleId] : data?.answers?.[handleId].label
                    return (
                        <div className={classes.answer} key={i} style={{
                            backgroundColor: data?.type === 'status' && data?.answers?.[handleId]?.bgcolor,
                            color: data?.type === 'status' && 'white',
                        }}>
                            {answer}
                            <CustomSourceHandle
                                key={i}
                                type="source"
                                position={Position.Right}
                                id={getHandleId(data.handles, 'source', i)}
                                className={data?.type === 'question' ? classes.question_source_handle : classes.status_source_handle}
                                style={{
                                    backgroundColor: nodeColor,
                                }} connectioncount={1} />
                        </div>
                    )
                })}
            </div>
        </>
    )
}