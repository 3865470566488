import { CircularProgress } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { selectUserId } from "@common-reducers/UserSelectors";;
import { getRuntimeUrl } from '@common-services/storage';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { DetailedHTMLProps, JSXElementConstructor, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import classes from "./lazy-loading-virtuoso.module.scss"

type Props = {
    height: number;
    rowHeightString?: string;
    filterObj: any;
    DBThunks: any;
    DBReducer: (state: any) => any;
    selectByQuery: (state: any, filterObj: any, projectionArray: string[], sortObj: any) => any;
    selector?: (state: any) => any;
    Component: JSXElementConstructor<any>;
    isFullWidth?: boolean;
};

const LazyLoadingVirtuoso = ({
    height, rowHeightString = 'auto', DBThunks, DBReducer, selectByQuery, Component, filterObj, isFullWidth = false, selector
}: Props) => {
    const dispatch = useAppDispatch()
    const noAudiencesFoundImgSrc = getRuntimeUrl("/img/no-audiences-found.svg")

    // const [hasScroll, setHasScroll] = useState(false);
    //const virtuosoRef = useRef(null); // Ref for accessing Virtuoso component

    // Function to calculate if scrolling is needed based on actual rendered content
    // const checkForScroll = () => {
    //     if (virtuosoRef.current) {
    //         const containerHeight = virtuosoRef.current.clientHeight;
    //         const contentHeight = virtuosoRef.current.scrollHeight;

    //         setHasScroll(contentHeight > containerHeight);
    //     }
    // };

    const ownerId = useAppSelector(selectUserId)
    const query = useMemo(() => {
        return {
            ...(filterObj ?? {}),
            owner: ownerId,
        };
    }, [filterObj, ownerId]);
    const isItemsLoaded = useAppSelector(state => DBReducer(state)?.itemsLoaded);

    const fliterdList = useAppSelector((state) => {
        if (selector) {
            return selector(state);
        } else {
            const rawList = selectByQuery(DBReducer(state), {
                deleted: false,
                owner: ownerId,
                ...query,
            },
                [],
                {
                    createdAt: -1
                }
            );
            return rawList
        }
    });

    const memoizedData = useMemo(() => fliterdList, [fliterdList]);

    // Check for scroll after the component and data have been loaded
    // useEffect(() => {
    //     checkForScroll();
    //     // Optionally, add an event listener for window resize if you want to re-calculate on resize
    //     window.addEventListener('resize', checkForScroll);

    //     return () => window.removeEventListener('resize', checkForScroll);
    // }, [memoizedData]); // You might need to adjust this dependency array based on your state and props

    const HeightPreservingItem = useCallback(
        ({
            children,
            ...props
        }: PropsWithChildren<
            DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
        >) => {
            return (
                <div
                    {...props}
                    className="height-preserving-container"
                    style={{ height: rowHeightString, minWidth: '400px' }}
                >
                    {children}
                </div>
            );
        },
        []
    );

    const loadMore = () => {
        //if (hasScroll) {
        const skipCount = memoizedData.length - 1;

        dispatch(DBThunks.find({
            ...query,
            deleted: false,
            $sort: {
                updatedAt: -1
            },
            $skip: skipCount,
        }));
        //}
    };

    useEffect(() => {
        dispatch(DBThunks.find({
            ...query,
            $sort: {
                updatedAt: -1
            },
        }))
    }, []);

    return (
        <Stack sx={{ width: isFullWidth && '100%', }}>
            {!isItemsLoaded &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <CircularProgress />
                </Box>
            }
            {isItemsLoaded && fliterdList.length > 0 &&
                <Virtuoso
                    components={{
                        Item: HeightPreservingItem,
                    }}
                    endReached={loadMore}
                    className={classes.virtuoso_scroller}
                    increaseViewportBy={250}
                    data={memoizedData}
                    style={{
                        height: height + 'px',
                        minWidth: '400px',
                    }}
                    totalCount={memoizedData.length}
                    itemContent={(index, item) => {
                        return (
                            <Component itemId={item?._id} index={index} />
                        );
                    }}
                />
            }
            {isItemsLoaded && fliterdList.length === 0 &&
                <Stack
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: '22px',
                    }}>
                    <img src={noAudiencesFoundImgSrc} alt="" style={{ height: "120px", marginBottom: '16px' }} />
                    <span style={{ fontSize: '16px', fontWeight: 700, }}>No Results found</span>
                </Stack>
            }
        </Stack>
    )
}
export default LazyLoadingVirtuoso;
