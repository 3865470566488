import React, { useEffect, useState } from "react";
import classes from "./webbook-guide-view.module.scss";

export default function WebHookGuideView() {
  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "24px",
        }}
      >
        <div>
          <img alt="logo" src="img/logo.png"></img>
          <h1 style={{ margin: 0 }}>Webhook guide</h1>
        </div>

        <h2 style={{ margin: 0 }}>📘 Creating a quick webhook for testing 🧪</h2>
      </div>
      <h4>Step 1 - Generate a webhook URL </h4>
      <p>
        Go to <a href="https://webhook.sites">https://webhook.site</a>
        <br />
        Locate your unique URL and copy it, you can use the "Copy to clipboard"
        button Keep this tab open, this is where you will see the incoming
        messages.
      </p>
      <div className={classes.imgContainer}>
        <img alt="whatsapp-api-webhook-guide-step1" src="img/whatsapp-api-webhook-guide-step1.jpg"></img>
      </div>

      <h4>Step 2 - paste the webhook URL </h4>
      <p>
        Go back to the API playground. And then open the "READ MESSAGES" tab.{" "}
        <br />
        Paste the webhook URL and click the "ADD HOOK" button.
      </p>
      <div className={classes.imgContainer}>
        <img alt="whatsapp-api-webhook-guide-step2" src="img/whatsapp-api-webhook-guide-step2.jpg"></img>
      </div>
      <h4>Step 3 - read incoming message from the web hook </h4>
      <p>
        Switch back to the tab where your webhok was generated. Incoming
        messages should appear on the left side of the page.
      </p>
      <div className={classes.imgContainer}>
        <img alt="whatsapp-api-webhook-guide-step3" src="img/whatsapp-api-webhook-guide-step3.jpg"></img>
      </div>

      <div
        style={{
          textAlign: "center",
          backgroundImage: `url(img/api-bg.svg)`,
          backgroundSize: "contain",
          marginBottom: "24px",
          minHeight: "150px",
        }}
      ></div>
    </div>
  );
}
