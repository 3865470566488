import { Box, Stack, Tooltip } from "@mui/material";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { memo } from "react";

interface Props {
    values: any;
}

function BatterySumCell({
    values,
}: Props) {
    const selectedOptions = values.selectedOptionsCloned.filter((obj, index, self) => {
        return index === self.findIndex((t) => t?._id === obj?._id);
    });
    const direction = useAppSelector((state) => state.UserReducer.direction);

    const sortedSelectedOptions = selectedOptions.sort((a, b) => a?.order - b?.order);

    const getPercentage = (optionCount: number, totalTasks: number): number => {
        const percentage = (optionCount / totalTasks * 100);
        return Number(percentage.toFixed(2));
    }
    let completedTasks = sortedSelectedOptions.reduce((total, option) => total + (option ? option.count : 0), 0);

    let remainingPercentage = getPercentage(values.tasksLength - completedTasks, values.tasksLength);


    const getBorderRadius = (
        direction: string,
        index: number,
        isStart: boolean,
        isEnd: boolean
    ) => {
        if (direction === "ltr") {
            return {
                borderTopLeftRadius: isStart ? "5px" : "0px",
                borderBottomLeftRadius: isStart ? "5px" : "0px",
                borderTopRightRadius: isEnd ? "5px" : "0px",
                borderBottomRightRadius: isEnd ? "5px" : "0px"
            };
        } else {
            return {
                borderTopRightRadius: isStart ? "5px" : "0px",
                borderBottomRightRadius: isStart ? "5px" : "0px",
                borderTopLeftRadius: isEnd ? "5px" : "0px",
                borderBottomLeftRadius: isEnd ? "5px" : "0px"
            };
        }
    };

    return (
        <Stack direction="row" alignItems={"center"} flexGrow={1} textAlign={"center"}
            sx={{ background: 'var(--task-background-color)', height: "80%", padding: "0 10px" }} >
            {sortedSelectedOptions.map((column, index) => (
                <Tooltip key={index} disableInteractive componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: "#fff",
                            padding: "4px 18px",
                            justifyContent: "center",
                            border: '1px solid transparent',
                            alignItems: "center",
                            color: "rgb(0, 55, 80)",
                            boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                            "& .MuiTooltip-arrow": {
                                color: "#fff",
                            },
                        },
                    },
                }} title={
                    <Stack justifyContent={"center"} p={2} minWidth={"140px"} spacing={2} >
                        <Stack justifyContent={"center"} alignItems={"center"} sx={{
                            background: column?.bgcolor,
                            color: "#fff",
                            textTransform: "capitalize",
                            boxShadow: "none",
                            fontSize: 12,
                            borderRadius: "5px",
                            fontWeight: "600",
                            fontFamily: "'Inter', 'Assistant', sans-serif",
                            height: 28,
                        }}>
                            {column?.label}
                        </Stack>

                        <Stack direction="row" justifyContent={"center"} sx={{ fontSize: 14 }}>{column?.count}/{values.tasksLength} -
                            {getPercentage(column?.count, values.tasksLength) + "%"}</Stack>
                    </Stack>
                } arrow placement="top">
                    <Box key={"battery_" + index}
                        sx={{
                            width: column?.count / values.tasksLength * 100 + "%",
                            background: column?.bgcolor,
                            height: "20px",
                            ...getBorderRadius(
                                direction,
                                index,
                                index === 0,
                                index === sortedSelectedOptions.length - 1 && remainingPercentage === 0
                            )
                        }}>
                    </Box>
                </Tooltip>
            ))}
            {remainingPercentage > 0 && <Box sx={{
                width: remainingPercentage + "%",
                background: "#c4c4c4f5",
                height: "20px",
                ...getBorderRadius(
                    direction,
                    -1,  // Using -1 since this isn't part of sortedSelectedOptions array
                    remainingPercentage === 100,
                    remainingPercentage > 0
                )
            }}></Box>}
        </Stack>
    );
}

export default memo(BatterySumCell);
