import { TaskColumnOption } from '@common-models/task-column-option'
import { DBTaskColumnOptionThunks, isTempId, taskColumnOptionSelectByQuery, taskColumnSelectByQuery } from "@common-reducers/DBServiceThunks"
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer'
import { updateCellValueThunk } from '@common-reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { MenuList } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { PickerProps } from '../cell-interface'
// import EditableOptions from './EditableOptions'
// import MappedOptions from './MappedOptions'

export default function ComplexityPicker({
    taskId,
    boardId,
    columnId,
}: PickerProps) {
    const dispatch = useAppDispatch()
    const [isEditing, setIsEditing] = useState(false);
    const [column] = useAppSelector((state) => taskColumnSelectByQuery(state.DBTaskColumnReducer, { _id: columnId }))
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))


    const toggleMenu = () => {
        dispatch(pickerDialogActions.closePickerDialog());
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Tab") {
            event.preventDefault();
            toggleMenu();
        }
    }

    const updateTask = (option: TaskColumnOption) => {
        dispatch(updateCellValueThunk({
            taskId,
            columnId,
            data: {
                value: option._id
            }
        }));
        toggleMenu();
    }

    const onEditCancel = () => {
        dispatch(pickerDialogActions.setEditModeOptions(false))
        setIsEditing(false);
    }

    const onEditChange = (options: TaskColumnOption[]) => {
        // Create new columns
        options
            .filter(option => !option._id)
            .forEach(option => {
                dispatch(DBTaskColumnOptionThunks.create({
                    ...option,
                    boardId,
                    columnId,
                }))
            })
        // Patch existing columns
        const updatedOptions = options
            .filter(option => option._id && !isTempId(option._id))
            .map(option => ({
                id: option._id,
                changes: option
            }))
        dispatch(DBTaskColumnOptionThunks.patchMany(updatedOptions));
        // Delete options that are not returned
        const newOptionIdList = options
            .filter(option => option._id && !isTempId(option._id))
            .map(option => option._id);
        const deletedOptions = columnOptions
            .filter(option => newOptionIdList.indexOf(option._id) === -1)
            .map(option => ({
                id: option._id,
                changes: {
                    deleted: true
                }
            }));
        dispatch(DBTaskColumnOptionThunks.patchMany(deletedOptions));
        setIsEditing(false);
    }

    const canEdit = useMemo(() => column?._id && !isTempId(column?._id), [column?._id])

    return (
        <MenuList
            autoFocusItem={true}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
            sx={{
                backgroundColor: 'var(--default-background)',
                border: '2px solid var(--default-background)',
                borderLeft: '20px solid var(--default-background)',
                borderRight:'20px solid var(--default-background)',
                borderRadius: '10px',
                margin: '-4px',
            }}
        >
            {/* {isEditing ? (
                <EditableOptions
                    onChange={onEditChange}
                    onCancel={onEditCancel}
                    canEdit={canEdit}
                    options={columnOptions} />
            ) : (
                <MappedOptions
                    options={columnOptions}
                    handleValueSelected={updateTask}
                    canEdit={canEdit}
                    onEdit={() => setIsEditing(true)}
                />
            )} */}
        </MenuList>
    )
}