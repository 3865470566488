import { getHandleId } from '@common-services/utils';
import { Handle, Position } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import NodeContainer from '../node-container';


export default function QuestionNode({ id, data }) {
    const type = data?.type

    const nodeColor = nodeTypes?.[type]?.color



    return (
        <>
            <Handle type="target" position={Position.Left} id={getHandleId(data.handles, 'target', 0)}
                style={{ backgroundColor: nodeColor, zIndex: 3, }} />
            <NodeContainer type={type} id={id} />
        </>
    )
}