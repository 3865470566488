import { AppConfig } from "./app.config";

const appConfigTudoStage = {
  DEFAULT_TITLE: 'Tudo Board',
  asset_key: 'tudoboard',
  google_client_id: '171213536785-40uq3jeidmqcuabjsous4deaj8t99870.apps.googleusercontent.com',
  GTM_ID: 'GTM-P5TZZS2V',
  GA_ID: '',
  FB_PIXEL_ID: '',
  CLARITY_ID:'',
  TIKTOK_ID:'',
  AW_ID: '',
  WS_ID: '',
  HJ_ID: 0,
  HJ_SV: 0,
  EXTENTION_ID: "faalgfonbpmhjboamhgopbjnbolehpcl",
  API_ENDPOINT: "https://stg-api.tudoboard.com",
  APP_ENDPOINT: "https://staging--app-tudo.netlify.app",
  NOTIFIER_ENDPOINT: "https://stg-notify.tudoboard.com/",
  VZNLYTICS_ENDPOINT: "https://analytics.tudoboard.com",
  GATEWAY_ENDPOINT: "https://gateway.blueticks.co",
  WS_ENDPOINT: "https://tudoboard-notifier-staging-a666c7d25ace.herokuapp.com/",
  // API_ENDPOINT: "https://blueticks-staging.herokuapp.com",
  PRICING_PAGE: "https://app.tudoboard.com/pricing",
  // APP_ENDPOINT: "https://app.blueticks.co",
  HOMEPAGE: "https://tudoboard.com",
  LOGO:"/img/tudu-logo.svg",
  LOGO2:"/img/tudo-logo-icon.svg",
  PAYPAL_CLIENT_ID:
    "ARQG3WFWCo5c_cl0idcg5fPastyJNcC3Gpzgh-7y7wHqliqw8heanLqZZLDQK-JNc5Bt2tHNJUkVx5pC",
  ENV: "tudostage",
  BLUESNAP_ENDPOINT: "https://sandbox.bluesnap.com",
  BLUESNAP_STORE_ID: "296929",
  BLUESNAP_REDIRECT_ENC:
    "qOU7VjlOZpEXrX8r1i62voZwKP3M6kkP%2Br1vzvsSupdiE0Qg2F3CyOGQycNZgCn8K%2Fiy2kZ%2BIvFADXUZTrmv3PI4fjyTt8EwZwOPRxwLX1QmAv5wdoRog4pA4aYqzoCxhfs1hP7gmhsxfqUumgZTT7vPVFc44W6Y45Y4AkQkilLhQKTU05HCFjx8eh30pkIzYgQYco52LMwbGjdPK%2FNJ6TC43C8TZTPnUSjELG7cJNyPc91K8YHmJqVijKcmtkWq",
  // BLUESNAP_ENDPOINT: "https://bluesnap.com",
  // BLUESNAP_STORE_ID: "648495",
  // BLUESNAP_REDIRECT_ENC:
  //   "y3BO7mSu04vXEh55QSFI51vnnO%2FrL2uZ10%2FPUF2Fh8urn%2BFdpIn4tyxpPqhcCnm%2BVOdueGkdnZpbpSjw%2Fj215nog250lMwUyqKry1OkfDap8daL1Nxv4dJeDeSXd9Ai6vUKYhCKeZaQ%2ByyfLkDPwJwlx1BGnPQYlRYkYiZBknRcVCl6kAgHv5ZTKEc3YMGPO4kc9iJJQnqXsotsbI3Z5jgJ5rXvslcQJXPihvCycO2%2FJfYH%2F2cr5s6ZLhSZZZaZY",
  extensionStoreLink: "https://chrome.google.com/webstore/detail/blueticks/adgnjhngogijkkppficiiepmjebijinl",
  PRODUCTS: {
    personal: "PROD-72S99618JL130302",
    professional: "PROD-47W322527S599700R",
  },
  PLANS: {
    // Prod plans
    "P-2EV08809U66066511L45HVQQ": {
      id: "P-2EV08809U66066511L45HVQQ",
      key: "personal-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: false,
      order: 1,
    },
    "P-4CT097158P9389945L45HWKY": {
      id: "P-4CT097158P9389945L45HWKY",
      key: "personal-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: false,
      order: 1,
    },
    "P-5LL88227H7791351XMC3LKOA": {
      id: "P-5LL88227H7791351XMC3LKOA",
      key: "tasks-annual",
      planName: "Tasks - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 240,
      order: 2,
    },
    "P-6BE32461V8593561LMC3LIMI": {
      id: "P-6BE32461V8593561LMC3LIMI",
      key: "tasks-monthly",
      planName: "Tasks - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 29,
      order: 2,
    },
    "P-1DS464590R230570GL52SZ7I": {
      id: "P-1DS464590R230570GL52SZ7I",
      key: "professional-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
    },
    "P-1CW95607EC450134SL52S6DY": {
      id: "P-1CW95607EC450134SL52S6DY",
      key: "professional-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
    },
    // dev plans
    "P-69U602591G622303FL4WIK5A": {
      id: "P-69U602591G622303FL4WIK5A",
      key: "personal-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: false,
      price: 5,
      order: 1,
    },
    "P-8HF747276X955703PL4WIFMY": {
      id: "P-8HF747276X955703PL4WIFMY",
      key: "personal-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: false,
      price: 36,
      order: 1,
    },
    "P-82N59649C2985174VMDG5NTQ": {
      id: "P-82N59649C2985174VMDG5NTQ",
      key: "tasks-annual",
      planName: "Tasks - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 240,
      order: 2,
    },
    "P-8CM2055424428610AMDG5PEI": {
      id: "P-8CM2055424428610AMDG5PEI",
      key: "tasks-monthly",
      planName: "Tasks - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 29,
      order: 2,
    },
    "P-06L82419PV9017747MDG5O3A": {
      id: "P-06L82419PV9017747MDG5O3A",
      key: "professional-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: true,
      price: 49,
      order: 3,
    },
    "P-4KN135463M447572VL52SW4Y": {
      id: "P-4KN135463M447572VL52SW4Y",
      key: "professional-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: true,
      price: 348,
      order: 3,
    },
  },
} as AppConfig;
export default appConfigTudoStage;
