import { Board } from "@common-models/board";
import { BoardRowType, Task } from "@common-models/task";
import { TasksGroup } from "@common-models/task-group";


export interface TaskElement {
  key: string;
  groups: TasksGroup[];
}

export interface BoardRowElement {
  id: string;
  parentTaskId?: string;
  type: BoardRowType;
  taskId?: string;
  groupId?: string;
  boardId?: string;
  order?: number;
  tasksCount?: number;
  showSubTasks?: boolean;
  isFirstTaskInGroup?: boolean;
  isLastTaskInGroup?: boolean;
  isLastTaskInSubTasksGroup?: boolean;
  isCollapsed?: boolean;
  footer_type?: BoardRowType;
  groupIndex?: number;
  groupTaskListIndex?: number;
  colorAccent?: string;
  title?: string;
  customData?: any;
}

function getSubTasks(tasks, parentTaskId: string, groupId: string) {
  return tasks.filter(
    (subTask) =>
      subTask.parentTaskId === parentTaskId && subTask.groupId === groupId
  );
}

function pushSubTask(
  subTask: Task,
  boardId,
  group: TasksGroup,
  isFirstTaskInGroup: boolean,
  isLastTaskInGroup: boolean
): BoardRowElement {
  return {
    id: subTask._id,
    type: BoardRowType.subTask,
    boardId: boardId,
    groupId: group._id,
    taskId: subTask._id,
    parentTaskId: subTask.parentTaskId,
    colorAccent: group.colorAccent ?? "",
    order: subTask.order ?? 0,
    isFirstTaskInGroup,
    isLastTaskInGroup,
  };
}

function subFooterToPush(
  parentTaskId: string,
  group: TasksGroup,
  boardId: string,
  isLastTaskInSubTasksGroup: boolean
): BoardRowElement {
  return {
    id: `subfooter_${group._id}`,
    type: BoardRowType.newTask,
    footer_type: BoardRowType.subTask,
    parentTaskId,
    groupId: group._id,
    boardId,
    isLastTaskInSubTasksGroup,
    colorAccent: group.colorAccent ?? "",
  };
}

function getGroupTaskList(tasks, groupId) {
  let groupTaskList = [];
  groupTaskList = tasks.filter(
    (task) => task.groupId === groupId && !task.parentTaskId
  );
  return groupTaskList;
}

function getBoardTaskList(tasks, boardId) {
  let groupTaskList = [];
  groupTaskList = tasks.filter((task) => task.boardId === boardId);
  return groupTaskList;
}

export const mergeGroupsAndTasks = (
  boardId: string,
  groups: TasksGroup[],
  tasks: Task[],

  hideEmptyGroups?: boolean,
  isGroupDragging?: boolean,
  toggleTasksMap?: Record<string, boolean>
) => {
  let elementsList: BoardRowElement[] = [];

  let group: any;
  let task: any;

  elementsList.push({
    id: "",
    type: BoardRowType.spacer,
  });

  for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
    group = groups[groupIndex];

    const groupTaskList = getGroupTaskList(tasks, group._id);
    if (groupTaskList.length === 0 && hideEmptyGroups) {
      continue;
    }

    

    const collapsed = group.isCollapsed || isGroupDragging;

    elementsList.push({
      type: BoardRowType.groupHeader,
      groupId: group._id,
      order: group.order ?? 0,
      isCollapsed: group.isCollapsed,
      boardId,
      tasksCount: groupTaskList?.length ?? 0,
      id: group._id,
    });

    if (!collapsed) {
      for (
        let groupTaskListIndex = 0;
        groupTaskListIndex < groupTaskList.length;
        groupTaskListIndex++
      ) {
        task = groupTaskList[groupTaskListIndex];
        const showSubTasks = toggleTasksMap[task?._id];
        const isFirstTaskInGroup = groupTaskListIndex === 0 ? true : false;
        const isLastTaskInGroup =
          groupTaskListIndex === groupTaskList.length - 1 ? true : false;

        elementsList.push({
          id: task._id,
          boardId,
          groupId: group._id,
          taskId: task._id,
          type: BoardRowType.task,
          colorAccent: group.colorAccent,
          order: task.order ?? 0,
          showSubTasks: showSubTasks,
          isFirstTaskInGroup,
          isLastTaskInGroup,
          customData:task.customData
        });

        // show sub tasks when showSubTasks for a task is true
        if (showSubTasks) {
          const subTasks = getSubTasks(tasks, task._id, group._id);

          for (
            let subTaskListIndex = 0;
            subTaskListIndex < subTasks.length;
            subTaskListIndex++
          ) {
            const subTask = subTasks[subTaskListIndex];
            const isFirstSubTaskInGroup = subTaskListIndex === 0 ? true : false;
            const isLastSubTaskInGroup =
              subTaskListIndex === groupTaskList.length - 1 ? true : false;

            elementsList.push(
              pushSubTask(
                subTask,
                boardId,
                group,
                isFirstSubTaskInGroup,
                isLastSubTaskInGroup
              )
            );
          }
          elementsList.push(
            subFooterToPush(task?._id, group, boardId, isLastTaskInGroup)
          );
        }
      }
      // elementsList.push({
      //   id: `cell_footer_${group._id}`,
      //   type: BoardRowType.footer,
      //   groupId: group._id,
      //   boardId,
      // });
      // push the group footer if c is equall to taskList length
      elementsList.push({
        id: `footer_${group._id}`,
        footer_type: BoardRowType.newTask,
        type: BoardRowType.newTask,
        groupId: group._id,
        boardId,
        colorAccent: group.colorAccent ?? "",
      });
      elementsList.push({
        id: "",
        groupId: group._id,
        type: BoardRowType.spacer,
      });
      elementsList.push({
        id: "",
        groupId: group._id,
        type: BoardRowType.spacer,
      });
    } else {
      elementsList.push({
        id: "",
        type: BoardRowType.spacer,
        groupId: group._id,
        groupIndex: 99999999999,
        groupTaskListIndex: 99999999994,
      });
      elementsList.push({
        id: "",
        groupId: group._id,
        type: BoardRowType.spacer,
        groupIndex: 99999999999,
        groupTaskListIndex: 99999999995,
      });
    }
  }
  elementsList.push({
    id: "",
    type: BoardRowType.newGroup,
  });
  return elementsList;
};

export const mergeBoardsOrDatesAndTasks = (
  boards: Board[],
  tasks: Task[],
  viewMode: string,
  hideEmptyBoards?: boolean,
  myWorkPreferences?: any
): BoardRowElement[] => {
  let elementsList: BoardRowElement[] = [];
  let board: any;
  let task: any;
  elementsList.push({
    id: "",
    type: BoardRowType.spacer,
  });
  if (viewMode === "board") {
    const filteredTasks = tasks?.filter(
      (task) => !myWorkPreferences?.collapsedBoards?.includes(task.boardId)
    );
    for (let boardIndex = 0; boardIndex < boards?.length; boardIndex++) {
      board = boards[boardIndex];

      const boardTaskList = getBoardTaskList(filteredTasks, board._id);
      const boardTaskCount = getBoardTaskList(tasks, board._id)?.length ?? 0;
      if (boardTaskList?.length === 0 && hideEmptyBoards) {
        continue;
      }

      elementsList.push({
        type: BoardRowType.boardHeader,
        boardId: board._id,
        order: board.order ?? 0,
        tasksCount: boardTaskCount,
        id: board._id,
      });

      for (
        let boardTaskListIndex = 0;
        boardTaskListIndex < boardTaskList.length;
        boardTaskListIndex++
      ) {
        task = boardTaskList[boardTaskListIndex];

        elementsList.push({
          id: task._id,
          boardId: board._id,
          taskId: task._id,
          type: BoardRowType.task,
          colorAccent: board.colorAccent,
          order: task.order ?? 0,
        });
      }

      // elementsList.push({
      //   id: "",
      //   boardId: "",
      //   type: BoardRowType.spacer,
      // });
      // elementsList.push({
      //   id: "",
      //   boardId: "",
      //   type: BoardRowType.spacer,
      // });
    }
  } else if (viewMode === "date") {
    const categorizedTasks = categorizeTasksByDate(tasks, myWorkPreferences);

    elementsList.push({
      id: "today",
      type: BoardRowType.boardHeader,
      boardId: "Today",
      order: 0,
      tasksCount: categorizedTasks.today?.length,
    });

    if (
      categorizedTasks.today?.length &&
      !myWorkPreferences.collapsedBoards.includes("Today")
    ) {
      categorizedTasks.today.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "today",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }

    // elementsList.push({
    //   id: "",
    //   boardId: "Today",
    //   type: BoardRowType.spacer,
    // });

    elementsList.push({
      id: "pastDates",
      type: BoardRowType.boardHeader,
      boardId: "Past dates",
      order: 1,
      tasksCount: categorizedTasks.pastDates?.length,
    });
    if (
      categorizedTasks.pastDates?.length &&
      !myWorkPreferences.collapsedBoards.includes("Past dates")
    ) {
      categorizedTasks.pastDates.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "pastDates",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }

    // elementsList.push({
    //   id: "",
    //   boardId: "Past dates",
    //   type: BoardRowType.spacer,
    // });

    elementsList.push({
      id: "thisWeek",
      type: BoardRowType.boardHeader,
      boardId: "This week",
      order: 2,
      tasksCount: categorizedTasks.thisWeek?.length,
    });

    if (
      categorizedTasks.thisWeek?.length &&
      !myWorkPreferences.collapsedBoards.includes("This week")
    ) {
      categorizedTasks.thisWeek.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "thisWeek",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }

    // elementsList.push({
    //   id: "",
    //   boardId: "This week",
    //   type: BoardRowType.spacer,
    // });

    elementsList.push({
      id: "nextWeek",
      type: BoardRowType.boardHeader,
      boardId: "Next week",
      order: 3,
      tasksCount: categorizedTasks.nextWeek?.length,
    });
    if (
      categorizedTasks.nextWeek?.length &&
      !myWorkPreferences.collapsedBoards.includes("Next week")
    ) {
      categorizedTasks.nextWeek.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "nextWeek",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }

    // elementsList.push({
    //   id: "",
    //   boardId: "",
    //   type: BoardRowType.spacer,
    // });

    elementsList.push({
      id: "later",
      type: BoardRowType.boardHeader,
      boardId: "Later",
      order: 3,
      tasksCount: categorizedTasks.later?.length,
    });

    if (
      categorizedTasks.later.length &&
      !myWorkPreferences.collapsedBoards.includes("Later")
    ) {
      categorizedTasks.later.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "later",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }

    // elementsList.push({
    //   id: "",
    //   boardId: "",
    //   type: BoardRowType.spacer,
    // });
    elementsList.push({
      id: "withoutDate",
      type: BoardRowType.boardHeader,
      boardId: "Without Date",
      order: 4,
      tasksCount: categorizedTasks.withoutDate.length,
    });
    if (
      categorizedTasks.withoutDate.length &&
      !myWorkPreferences.collapsedBoards.includes("Without Date")
    ) {
      categorizedTasks.withoutDate.forEach((task) => {
        elementsList.push({
          id: task._id,
          boardId: "withoutDate",
          taskId: task._id,
          type: BoardRowType.task,
          order: task.order ?? 0,
        });
      });
    }
  }

  return elementsList;
};

const categorizeTasksByDate = (tasks, myWorkPreferences?: any) => {
  const now = new Date();
  const daysUntilSunday = (7 - now.getDay()) % 7;
  
  const endOfWeek = new Date(now);
  endOfWeek.setDate(now.getDate() + daysUntilSunday); 
  const nextWeek = new Date(endOfWeek);
  nextWeek.setDate(endOfWeek.getDate() + 7);

  const categorized = {
    pastDates: [],
    today: [],
    thisWeek: [],
    nextWeek: [],
    later: [],
    withoutDate: [],
  };

  tasks.forEach((task) => {
    let taskColumnId = myWorkPreferences.selectedColumns?.[task?.boardId]
      ? myWorkPreferences?.selectedColumns[task?.boardId]?.date
      :  task?.datePickerColumnId;

    const taskDate = task.customData?.[taskColumnId]?.value
      ? new Date(task.customData[taskColumnId].value)
      : null;

    if (!taskDate) {
      categorized.withoutDate.push(task);
    } else if (taskDate.toDateString() === now.toDateString()) {
      categorized.today.push(task);
    } else if (taskDate < now) {
      categorized.pastDates.push(task);
    } else if (taskDate <= endOfWeek) {
      categorized.thisWeek.push(task);
    } else if (taskDate <= nextWeek) {
      categorized.nextWeek.push(task);
    } else {
      categorized.later.push(task);
    }
  });

  return categorized;
};
