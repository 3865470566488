import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import PersonPickerCell from '@common-components/tasks/columns-cells/person-picker-cell/person-picker-cell';
import { notificationsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function PersonColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))



    return (
        <>
            {notification.actionType === 'subscribed' &&
                <NotificationStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{notification.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[{ profile: notification?.newUser }]}
                            readOnly={true}
                            cellComponent={PersonPickerCell}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>to</span>&nbsp;
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{notification.taskName}</span>&nbsp;
                </NotificationStack>
            }
            {notification.actionType === 'unsubscribed' &&
                <NotificationStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{notification.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[{ profile: notification?.oldUser }]}
                            readOnly={true}
                            cellComponent={PersonPickerCell}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>from</span>&nbsp;
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{notification.taskName}</span>&nbsp;
                </NotificationStack>
            }
        </>
    )
}