
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import sift from "sift";



export interface WhatsAppViewState {

  contactFilterName: string;

}

const initialState: WhatsAppViewState = {
  contactFilterName: "",
};

const WhatsAppViewSlice = createSlice({
  name: "WhatsAppView",
  initialState,
  reducers: {
    setContactFilterName(state, action: PayloadAction<string>) {
      state.contactFilterName = action.payload;
    },

  },
});

export const getWhatsappContactsFilterd = createSelector(
  [
    (state) => state,
    (state) => state.WhatsAppViewReducer.contactFilterName,
  ],
  (state, contactFilterName: string) => {

    let contactsFilter: any = {}

    if (contactFilterName) {
      contactsFilter.waName = new RegExp(contactFilterName, "i")
    }

    const whatsappContactsFilterd = state.WhatsAppReducer.contactList.filter(sift(contactsFilter))

    return whatsappContactsFilterd
  }
);

export const {
  setContactFilterName,
} = WhatsAppViewSlice.actions;
export const WhatsAppViewActions = WhatsAppViewSlice.actions;

export default WhatsAppViewSlice.reducer;
