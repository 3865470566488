import { Divider, Stack, Tooltip } from "@mui/material";
import { CellProps } from "../cell-interface";

export default function EmptyCell({ cellValue, readOnly, onUpdate, groupId, boardId }: CellProps) {
 

  return (
    <Stack sx={{ cursor: 'pointer' }} direction="row" alignItems="center">
    <Tooltip arrow title="Column not exists on board" placement="top" disableInteractive>
      <Divider sx={{ width: "100px", height: "20px", opacity: 0 }} />
    </Tooltip>
  </Stack>
  );
}
