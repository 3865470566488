import { Alert, Dialog, DialogContent, Snackbar, Stack } from '@mui/material';
import { recycleActions } from '@common-reducers/RecycleBinReducer';
import { fetchArchivedOrDeletedData } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useEffect } from 'react';
import ArchivedOrDeletedBulkDialog from './archived-or-deleted-bulk-dialog';
import { ArchivedOrDeletedDataTable } from './archived-or-deleted-data-table';
import { ArchivedOrDeletedFilterRow } from './archived-or-deleted-filter-row';
import { ArchivedOrDeletedTabsAndHeader } from './archived-or-deleted-tabs-and-header';

const Archive = () => {
    const dispatch = useAppDispatch();
    const archivedOrDeletedBulkItems = useAppSelector(state => state.RecycleBinReducer.archivedOrDeletedBulkItems);
    const archiveOrDeletedSnackMessage = useAppSelector(state => state.RecycleBinReducer.archiveOrDeletedSnackMessage);
    const archiveOrDeletedAlertType = useAppSelector(state => state.RecycleBinReducer.archiveOrDeletedAlertType);
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);

    const closeArchive = () => {
        dispatch(recycleActions.setArchiveShown(false));
        dispatch(recycleActions.setArchivedOrDeletedData([]));
        dispatch(recycleActions.setSearchText(''));
        dispatch(recycleActions.setArchivedOrDeletedBulkItems([]));
        dispatch(recycleActions.setArchivedOrDeletedSnackMessage({ message: '', type: archiveOrDeletedAlertType }))
        dispatch(recycleActions.setFilters({
            type: [],
            date: '',
            board: []
        }));

    };

    const handleCloseSnackbar = () => {
        dispatch(recycleActions.setArchivedOrDeletedSnackMessage({ message: '', type: archiveOrDeletedAlertType }))
    }

    useEffect(() => {
        dispatch(fetchArchivedOrDeletedData({
            mode: currentTab,
            itemId: "",
            itemUpdatedAt: "",
            showLoader: true

        }));
    }, []);

    return (
        <Dialog onClose={closeArchive} fullWidth maxWidth="xl" open={true}>
            <DialogContent sx={{ padding: "0px", overflow: 'hidden' }}>
                <Stack p={4}>
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!archiveOrDeletedSnackMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <Alert onClose={handleCloseSnackbar} severity={archiveOrDeletedAlertType || 'info'} sx={{ width: '100%' }}>
                            {archiveOrDeletedSnackMessage}
                        </Alert>
                    </Snackbar>

                    <ArchivedOrDeletedTabsAndHeader />

                    <ArchivedOrDeletedFilterRow />

                    <ArchivedOrDeletedDataTable />

                    {archivedOrDeletedBulkItems.length > 0 ? <ArchivedOrDeletedBulkDialog /> : null}
                </Stack>
            </DialogContent>
        </Dialog >
    )
};

export default Archive;










