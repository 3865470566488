
import { Board } from "@common-models/board";
import { BoardView } from "@common-models/board-views";
import { EntitySubscriber } from "@common-models/entity-subscriber";
import { activeSubscriptionSelector, isUserWorkSpaceOwner, selectCurrentWorkspace } from "@common-reducers/UserSelectors";
import { AnalyticsService } from "@common-services/analytics-service";
import { isExtensionContext } from "@common-services/detect-context";
import { AppThunkDispatch} from "@common/types/common-root-state-type";

import { appConfig } from "@common/config/app.config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { saveAs } from 'file-saver';
import getRandomEmoji from 'get-random-emoji';
import Papa from "papaparse";
import { boardsActions } from "./BoardsReducer";
import { getCurrentBoardId, getSelectedBoardId } from "./BoardsSelectors";
import { bulkActions } from "./BulkActionsReducer";
import { setActiveTab } from "./CampaignReducer";
import { createNewColumnsForImportExcelThunk, getBoardEntitiesForImportModalThunk, saveNewContactsFromWhatsappForExistingCampaignAudienceThunk, saveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingThunk, saveNewContactsFromWhatsappForNewCampaignAudienceThunk } from "./CampaignThunks";
import { DBBoardUserThunks, DBBoardsThunks, DBEntitySubscribersThunks, DBFormThunks, DBGanttThunks, DBKanbanThunks, DBNotificationsThunks, DBTaskColumnOptionThunks, DBTaskColumnThunks, DBTaskgroupsThunks, DBTasksExtraDataFilesThunks, DBTasksExtraDataThunks, DBTasksThunks, DBTimeTrackingSessionsThunks, DBViewFiltersThunks, DBWorkloadThunks, TaskColumnOptionReducerActions, boardUserSelectByQuery, boardUserSelectOneObjectByQuery, boardViewSelectByQuery, boardsSelectByQuery, taskgroupsReducerActions, tasksReducerActions, workspaceUserSelectByQuery } from "@common-reducers/DBServiceThunks";
import { importItemsActions } from "./ImportItemsReducer";
import { MODAL, modalActions } from "./ModalReducer";
import { notifierSubscribeThunk } from "./NotifierThunks";
import { subscriptionPopupActions } from "./SubscriptionPopupReducer";
import { dynamicColumnList, resetMenusState } from "./TaskThunks";
import { taskActions } from "./TasksReducer";
import { ImportExcelToBoardOptions, addExistingWorkspaceUserToBoard, addNonExistingWorkspaceUserToBoard, duplicateAudienceBoardForCampaigns } from "./backend-api/backend-api";
import { CommonRootState } from "@common/types/common-root-state-type";



const analyticsService = new AnalyticsService();

export interface SetSelectedBoardIdOptions {
  boardId: string;
}

export const setSelectedBoardId = createAsyncThunk<void, SetSelectedBoardIdOptions, { state: CommonRootState }>(
  "setSelectedBoardId",
  async (options: SetSelectedBoardIdOptions, thunkApi) => {
    const { dispatch } = thunkApi;
    const state: CommonRootState = thunkApi.getState();
    dispatch(boardsActions.privateSetSelectedBoardId(options.boardId ?? state.BoardsReducer.selectedBoardId))
    dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: options.boardId }))

  }
);

export const setSelectedKanbanId = createAsyncThunk<void, SetSelectedBoardIdOptions, { state: CommonRootState }>(
  "setSelectedKanbanId",
  async (options: SetSelectedBoardIdOptions, thunkApi) => {
    const { dispatch } = thunkApi;
    const state: CommonRootState = thunkApi.getState();

    dispatch(boardsActions.privateSetSelectedBoardId(options.boardId ?? state.BoardsReducer.selectedBoardId))

    dispatch(notifierSubscribeThunk({ entityType: 'kanbans', entityId: options.boardId }))

  }
);

export const setSelectedGanttId = createAsyncThunk<void, SetSelectedBoardIdOptions, { state: CommonRootState }>(
  "setSelectedGanttId",
  async (options: SetSelectedBoardIdOptions, thunkApi) => {
    const { dispatch } = thunkApi;
    const state: CommonRootState = thunkApi.getState();

    dispatch(boardsActions.privateSetSelectedBoardId(options.boardId ?? state.BoardsReducer.selectedBoardId))

    dispatch(notifierSubscribeThunk({ entityType: 'gantts', entityId: options.boardId }))

  }
);




export const getBoardDataThunk = createAsyncThunk<Promise<any>, { boardId: string }, { state: CommonRootState }>(
  "getBoardData",
  async ({ boardId }, thunkApi) => {
    const { dispatch } = thunkApi;
    const state: CommonRootState = thunkApi.getState();
    const currentBoardView = state.TasksReducer.currentBoardView;
    const [boardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: boardId, _id: currentBoardView.id });
    const viewId = boardView?._id ?? boardId
    const promiseList = [];
    const isExtension = isExtensionContext();
    const userId = state.UserReducer.userId;

    dispatch(taskActions.clearDeletedBoardName())

    dispatch(tasksReducerActions.removeAll())
    dispatch(taskgroupsReducerActions.removeAll())
    dispatch(TaskColumnOptionReducerActions.removeAll())



    console.log("get all board users");
    promiseList.push(dispatch(
      DBBoardUserThunks.find({
        boardId,
        deleted: false,
        $paginate: false,
        returnAlsoBoardUserNameAndPorfileImg: true,
      })
    ));

    console.log("get all board groups");
    promiseList.push(dispatch(
      DBTaskgroupsThunks.find({
        boardId,
        deleted: false,
        archived: { $ne: true },
        $paginate: false,
      })
    ));

    console.log("get all board tasks");
    promiseList.push(dispatch(
      DBTasksThunks.find({
        boardId,
        deleted: false,
        archived: { $ne: true },
        $paginate: false,
      })
    ));

    console.log("get all board columns");
    promiseList.push(dispatch(
      DBTaskColumnThunks.find({
        boardId,
        deleted: false,
        $paginate: false,
      })
    ));

    console.log("get all board columns options");
    promiseList.push(dispatch(
      DBTaskColumnOptionThunks.find({
        boardId,
        deleted: false,
        $paginate: false,
      })
    ));

    if (!isExtension) {
      console.log("get all board extra data");
      promiseList.push(dispatch(
        DBTasksExtraDataThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));


      console.log("get all board extra data");
      promiseList.push(dispatch(
        DBTasksExtraDataFilesThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));

      console.log("get all board kanbans");
      promiseList.push(dispatch(
        DBKanbanThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));
      console.log("get all board gantts");
      promiseList.push(dispatch(
        DBGanttThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));

      console.log("get all board forms");
      promiseList.push(dispatch(
        DBFormThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));

      console.log("get all board workloads");
      promiseList.push(dispatch(
        DBWorkloadThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));

      console.log("get all board timetracking");
      promiseList.push(dispatch(
        DBTimeTrackingSessionsThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));
      // console.log("get all board views");
      // promiseList.push(dispatch(
      //   DBBoardViewThunks.find({
      //     boardId,
      //     deleted: false,
      //     // $paginate: false,
      //   })
      // ));

      console.log("get all board filters view");
      promiseList.push(dispatch(
        DBViewFiltersThunks.find({
          viewId,
          deleted: false,
          // $paginate: false,
        })
      ));



      // console.log("get all board logs");
      // promiseList.push(dispatch(
      //   DBLogsThunks.find({
      //     boardId,
      //     deleted: false,
      //     $paginate: false,
      //   })
      // ))
    }


    // console.log("get all board entity subscriptions");
    // promiseList.push(dispatch(
    //   DBEntitySubscribersThunks.find({
    //     $paginate: false,
    //     boardId,
    //     deleted: false,
    //   })
    // ));

    console.log("get all board notifications");
    promiseList.push(dispatch(
      DBNotificationsThunks.find({
        owner: userId,
        deleted: false,
      })
    ))
    return Promise.all(promiseList);
  }

);


export const getCampaignContactTasks = createAsyncThunk<Promise<any>, { boardIDArray: string[] }, { state: CommonRootState }>(
  "getBoardData",
  async ({ boardIDArray }, thunkApi) => {
    const { dispatch } = thunkApi;

    const promiseList = [];


    for (const boardId of boardIDArray) {
      promiseList.push(dispatch(
        DBTasksThunks.find({
          boardId,
          deleted: false,
          $paginate: false,
        })
      ));
    }



    return Promise.all(promiseList);
  }
);


export const addUserToBoardThunk = createAsyncThunk<any, string, { state: CommonRootState }>
  ("boards/addUserToBoardThunk", async (userId, thunkAPI) => {

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state)
    const [workspaceUser] = workspaceUserSelectByQuery(state.DBWorkspaceUserReducer, {
      userId
    })

    const res = await dispatch(addExistingWorkspaceUserToBoard.initiate({ boardId, userId, role: workspaceUser?.role }))
    return res;
  })

export const addUserToBoardAsMemberOrGuestThunk = createAsyncThunk<any, { role: string, email: string }, { state: CommonRootState }>
  ("boards/addUserToBoardAsMemberOrGuestThunk", async ({ role, email }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state);
    const boardUsers = boardUserSelectByQuery(state.DBBoardUserReducer, { boardId });
    const userExists = boardUsers.some(user => user.email === email);
    if (userExists) {
      return { success: false, error: "User already exists in the board" };
    }

    const existingBoardUser = boardUserSelectOneObjectByQuery(state.DBBoardUserReducer, {
      email
    });

    const stateEntity = await dispatch(
      DBBoardUserThunks.createOptimisticUpdate({
        ...existingBoardUser,
        boardId,
        email
      })
    ).unwrap();
    const dirtySessionId = stateEntity.dirtySessionId;

    try {
      const res = await dispatch(addNonExistingWorkspaceUserToBoard.initiate({ boardId, email, role })).unwrap();
      dispatch(
        DBBoardUserThunks.syncOptimisticUpdate({
          ...res,
          dirtySessionId,
        })
      );
      return res;
    } catch (error) {
      console.log(error);
      dispatch(DBBoardUserThunks.undoOptimisticUpdate(dirtySessionId));
      return { success: false, error: error.data.message || "An unexpected error occurred." };  // Return error object
    }
  });



export const addNewBoardThunk = createAsyncThunk<string, { boardName: string; emoji: string, template: string }, { state: CommonRootState }>(
  "addNewBoardThunk",
  async (args, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const { dispatch } = thunkApi;

    const randomEmoji = args.emoji || getRandomEmoji();
    const userId = state.UserReducer.userId;
    const workspace = selectCurrentWorkspace(state);
    const activeSubscription = activeSubscriptionSelector(state);
    const isWorkSpaceOwner = isUserWorkSpaceOwner(state);

    const boardList = boardsSelectByQuery(state.DBBoardsReducer, {
      workspaceId: workspace._id
    });

    const userScore = 0.5;
    if (boardList.length >= (state.TasksReducer?.limits?.boards ?? 2) && (!activeSubscription || !activeSubscription.hasTasks()) && userScore < 1 && appConfig.asset_key === 'tudoboard') {
      if (isWorkSpaceOwner) {
        dispatch(modalActions.setComponentToRender('TRIAL_EXTEND'));

      } else {
        dispatch(modalActions.setComponentToRender(MODAL.CONTACT_WORKSPACE_ADMIN));

      }

      return;
    } else if (boardList.length >= (state.TasksReducer?.limits?.boards ?? 2) && (!activeSubscription || !activeSubscription.hasTasks())) {
      dispatch(subscriptionPopupActions.openPopup('boards'));
      return;
    }


    const boardName = args.boardName ? args.boardName : (args.template === 'default' ? 'New board' : args.template);

    const board: Board = {
      name: boardName,
      titleIcon: randomEmoji,
      owner: userId,
      workspaceId: workspace?._id?.toString(),
      template: args.template === '' ? 'default' : args.template
    };

    const res: any = await dispatch(DBBoardsThunks.create(board));
    const boardId = res?.payload?._id;

    const newEntitySubscriber: EntitySubscriber = {
      userId,
      entityType: 'board',
      boardId,
      deleted: false,
    }
    dispatch(DBEntitySubscribersThunks.create(newEntitySubscriber))

    dispatch(setSelectedBoardId({ boardId }));
    dispatch(taskActions.setPersonFilter(""));
    dispatch(bulkActions.setBulkTasksMap({}));

    analyticsService.event("new_board_created", {
      category: "board",
      action: "board-created",
    });

    return boardId ?? "";

  }

);


export const onResizeStopThunk = createAsyncThunk<void, { columnId: string; width: number; boardId: string }, { state: CommonRootState }>(
  "columns/onResizeStop",
  async ({ columnId, width, boardId }, thunkApi) => {
    const { dispatch } = thunkApi;

    if (columnId !== "row-prefix") {
      const maxWidth = Math.max(width, 70);

      dispatch(DBTaskColumnThunks.patch({
        entity: {
          _id: columnId,
          width: maxWidth
        }
      }));
      //dispatch(boardsActions.resetColumnWidths());

    } else {
      const adjustedWidth = Math.max(width, 420);
      dispatch(DBBoardsThunks.patch({
        entity: {
          _id: boardId,
          textColumnWidth: adjustedWidth
        }
      }));
      //  dispatch(boardsActions.resetColumnWidths());

    }
    dispatch(boardsActions.setResizing(false));
  }
);

export interface BulkAudienceSelectBoardThunkParams {
  boardId: string;
}

export const bulkAudienceSelectBoardThunk = createAsyncThunk<
  void,
  BulkAudienceSelectBoardThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (BulkAudienceSelectBoardThunkParams: BulkAudienceSelectBoardThunkParams, thunkAPI) => {
    const { boardId } = BulkAudienceSelectBoardThunkParams;
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    dispatch(setSelectedBoardId({ boardId }));
    dispatch(taskActions.setCurrentBoardView({
      type: 'board',
      id: boardId
    }
    ))
    dispatch(taskActions.setPersonFilter(""));
    dispatch(bulkActions.setBulkTasksMap({}));
    dispatch(bulkActions.setMovingTo(''));
    dispatch(resetMenusState());

  }
);


export interface DuplicateAudienceBoardForCampaignsThunkParams {
  oldBoardId: string;
}

export const duplicateAudienceBoardForCampaignsThunk = createAsyncThunk<
  any,
  DuplicateAudienceBoardForCampaignsThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (DuplicateAudienceBoardForCampaignsThunkParams: DuplicateAudienceBoardForCampaignsThunkParams, thunkAPI) => {
    const { oldBoardId } = DuplicateAudienceBoardForCampaignsThunkParams;
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    const res = await dispatch(duplicateAudienceBoardForCampaigns.initiate(oldBoardId))

    if ('data' in res) {
      // Now TypeScript knows response is of the first type
      console.log(res.data);
    } else if ('error' in res) {
      // Now TypeScript knows response is of the second type
      console.error(res.error);
    }


  }
);


export interface GetBoardSampleCSVThunkParams {
  supportedColumnTypes: string[];
}

export const getBoardSampleCSVThunk = createAsyncThunk<
  void,
  GetBoardSampleCSVThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (GetBoardSampleCSVThunkParams: GetBoardSampleCSVThunkParams, thunkAPI) => {
    const { supportedColumnTypes } = GetBoardSampleCSVThunkParams;
    const state: CommonRootState = thunkAPI.getState();
    const boardId = getCurrentBoardId(state)
    const _columnsList = dynamicColumnList(state, boardId)

    const taskNameArray = ['Task Name', 'Task Name', 'Task Name']

    const columnValueByType = {
      'text-cell': ['Text to Import', 'Text to Import', 'Text to Import'],
      'number': ['23', '11', '1988'],
      'datepicker': ['14/03/2024', '14/03/2024', '14/03/2024'],
      'phone-cell': ['11234567890', '11234567890', '11234567890'],
      'option-picker': ['Option Label', 'Option Label', 'Option Label'],
      'status-option-picker': ['Status Label', 'Status Label', 'Status Label'],
    }

    let boardSampleCSV = []

    const headerRow = ['Task Name', ..._columnsList
      .filter(column => supportedColumnTypes.includes(column.type))
      .map(column => column.title ?? column.type)
    ];
    boardSampleCSV.push(headerRow);

    for (let i = 0; i < taskNameArray.length; i++) {
      let row = [taskNameArray[i]]
      for (let column of _columnsList) {
        if (supportedColumnTypes.includes(column.type)) {
          const value = columnValueByType[column.type][i]
          row.push(value)
        }
      }
      boardSampleCSV.push(row)
    }

    // Convert array of objects to CSV string
    const csv = Papa.unparse(boardSampleCSV);

    // Convert CSV string to a Blob
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    // Use file-saver to save Blob as a file
    saveAs(blob, 'sample.csv');
  }
);



export interface InitialAutoMappingExcelToBoardColumnsThunksParams {
  columnsList: any;
}

export const initialAutoMappingExcelToBoardColumnsThunks = createAsyncThunk<
  void,
  InitialAutoMappingExcelToBoardColumnsThunksParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (InitialAutoMappingExcelToBoardColumnsThunksParams: InitialAutoMappingExcelToBoardColumnsThunksParams, thunkAPI) => {
    const { columnsList } = InitialAutoMappingExcelToBoardColumnsThunksParams;
    const state: CommonRootState = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const _importJsonData = state.ImportItemsReducer.importJsonData
    const openedby = state.ModalReducer.openedby


    let joinedObject = {};
    for (const row of _importJsonData) {
      joinedObject = { ...joinedObject, ...row };
    }
    const _excelColumns = Object.keys(joinedObject)

    let _importDataMapping = {}

    const primitiveExcelColumns = _excelColumns?.map(col => col.replace(/\s+/g, '').toLowerCase())

    if (openedby === 'campaign') {
      if (primitiveExcelColumns?.some(col => col.includes('display'))) {
        const elementIndex = primitiveExcelColumns?.findIndex(col => col.includes('display'))
        _importDataMapping = {
          ..._importDataMapping,
          [_excelColumns[elementIndex]]: 'taskName',
        }
        dispatch(importItemsActions.setSelectedColumn(_excelColumns[elementIndex]))
      }
      if (primitiveExcelColumns?.some(col => col.includes('first'))) {
        const elementIndex = primitiveExcelColumns?.findIndex(col => col.includes('first'))
        const [column] = columnsList.filter(column => column.title.replace(/\s+/g, '').toLowerCase().includes('first'))
        if (column) {
          _importDataMapping = {
            ..._importDataMapping,
            [_excelColumns[elementIndex]]: column._id,
          }
        }
      }
      if (primitiveExcelColumns?.some(col => col.includes('last'))) {
        const elementIndex = primitiveExcelColumns?.findIndex(col => col.includes('last'))
        const [column] = columnsList.filter(column => column.title.replace(/\s+/g, '').toLowerCase().includes('last'))
        if (column) {

          _importDataMapping = {
            ..._importDataMapping,
            [_excelColumns[elementIndex]]: column._id,
          }
        }
      }
      if (primitiveExcelColumns?.some(col => col.includes('phone')) || primitiveExcelColumns?.some(col => col.includes('mobile'))) {
        const elementIndex = primitiveExcelColumns?.findIndex(col => col.includes('phone') || col.includes('mobile'))
        const [column] = columnsList.filter(column => column.title.replace(/\s+/g, '').toLowerCase().includes('phone') || column.title.replace(/\s+/g, '').toLowerCase().includes('mobile'))
        if (column) {
          _importDataMapping = {
            ..._importDataMapping,
            [_excelColumns[elementIndex]]: column._id,
          }
        }
      }

    }
    else {
      const mappedColumnIds = []
      for (let i = 0; i < primitiveExcelColumns?.length; i++) {
        const excelColumnName = primitiveExcelColumns[i]
        const column = columnsList?.find(column => (column.title ?? column.type).replace(/\s+/g, '').toLowerCase() === excelColumnName)
        if (column && !mappedColumnIds.includes(column._id)) {
          _importDataMapping = {
            ..._importDataMapping,
            [_excelColumns[i]]: column._id,
          }
          mappedColumnIds.push(column._id)
        }
      }

    }
    dispatch(importItemsActions.setImportDataMapping(_importDataMapping))
  }
);








export interface StartImportToBoardThunkParams {
  selectedBoardId: string;
  handleFinishImporting?: () => void;
  importExcelToBoard: MutationTrigger<MutationDefinition<ImportExcelToBoardOptions, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, string, void, "backendApi">>;
}




function jsonToCsvFile(jsonObj) {
  let csv = '';

  if (!Array.isArray(jsonObj) || jsonObj.length === 0) {
    return null;
  }

  // Extract header (column names)
  let header = Object.keys(jsonObj[0]);
  csv += header.join(',') + '\n';

  // Extract content
  for (let row of jsonObj) {
    let csvRow = header.map(fieldName => {
      let value = row[fieldName];

      // Escape double quotes and surround with quotes
      if (typeof value === 'string') {
        value = '"' + value.replace(/"/g, '""') + '"';
      }
      return value;
    });
    csv += csvRow.join(',') + '\n';
  }

  // Convert to File
  return new File([csv], 'data.csv', { type: 'text/csv', lastModified: new Date().getTime() });
}



export const startImportToBoardThunk = createAsyncThunk<
  void,
  StartImportToBoardThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (StartImportToBoardThunkParams: StartImportToBoardThunkParams, thunkAPI) => {
    const { selectedBoardId, handleFinishImporting, importExcelToBoard } = StartImportToBoardThunkParams;
    const state: CommonRootState = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const importJsonData = state.ImportItemsReducer.importJsonData
    const importFileName = state.ImportItemsReducer.importFileName
    const selectedColumn = state.ImportItemsReducer.selectedColumn
    const importDataMapping = state.ImportItemsReducer.importDataMapping
    const sheetName = state.ImportItemsReducer.workSheetName
    const file = state.ImportItemsReducer.file
    const importedContactsArray = state.WhatsAppReducer.importedContactsArray
    const newAudienceName = state.ImportItemsReducer.newAudienceName
    const openedby = state.ModalReducer.openedby
    const importType = state.ModalReducer.importType
    const newColumnList = state.ImportItemsReducer.newColumnList


    dispatch(importItemsActions.setImportInProgress(true));
    let boardId
    if (importType === 'whatsapp') {

      if (selectedBoardId) {
        boardId = await dispatch(saveNewContactsFromWhatsappForExistingCampaignAudienceThunk({
          waContactList: importedContactsArray,
        })).unwrap()
      }
      else {
        boardId = await dispatch(saveNewContactsFromWhatsappForNewCampaignAudienceThunk({
          displayName: newAudienceName,
          waContactList: importedContactsArray,
        })).unwrap()
      }


    }
    if (importType === 'whatsappWithMapping') {

      boardId = await dispatch(saveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingThunk({
        waContactList: importedContactsArray,
        importDataMapping,
      })).unwrap()

    }
    if (importType !== 'whatsapp' && importType !== 'whatsappWithMapping') {
      let _selectedColumn
      let _importDataMapping = {}
      if (!selectedBoardId) {
        const [_boardId, firstNameColumnId, lastNameColumnId, contactColumnId] = await dispatch(getBoardEntitiesForImportModalThunk({
          displayName: newAudienceName,
        })).unwrap()

        boardId = _boardId;

        const mappingKeys = Object.keys(importDataMapping)
        for (let key of mappingKeys) {
          let value = undefined
          if (importDataMapping[key] === 'fakeFirstNameColumnId012') {
            value = firstNameColumnId
          }
          if (importDataMapping[key] === 'fakeLastNameColumnId0123') {
            value = lastNameColumnId
          }
          if (importDataMapping[key] === 'fakeContactColumnId01234') {
            value = contactColumnId
          }
          if (importDataMapping[key]?.includes('NEWCOLUMN')) {
            //   const newColumn = newColumnList.find(column => column._id === importDataMapping[key])
            //   const res = await dispatch(DBTaskColumnThunks.create({
            //     boardId,
            //     type: newColumn.type,
            //     title: newColumn.title,
            //   })).unwrap()

            value = importDataMapping[key]
          }
          if (value) {
            _importDataMapping = {
              ..._importDataMapping,
              [key]: value,
            }
          }
        }

        await dispatch(importItemsActions.setImportDataMapping(_importDataMapping))
      }
      else {
        boardId = selectedBoardId
        let mappingKeys = Object.keys(importDataMapping) ?? []
        mappingKeys.forEach(async key => {
          if (importDataMapping[key] !== 'taskName') {
            _importDataMapping[key] = importDataMapping[key];
          }
          else {
            _selectedColumn = key
          }
        });
        await dispatch(importItemsActions.setImportDataMapping(_importDataMapping))
      }
      const newColumnsMapping = await (await dispatch(createNewColumnsForImportExcelThunk({ boardId })))?.payload
      const mappingKeys = Object.keys(importDataMapping);
      for (let key of mappingKeys) {
        if (importDataMapping[key].includes('NEWCOLUMN')) {
          _importDataMapping = {
            ..._importDataMapping,
            [newColumnsMapping[importDataMapping[key]]?.excelColumn]: newColumnsMapping[importDataMapping[key]]?._id,
          };
        }
      }
      await dispatch(importItemsActions.setImportDataMapping(_importDataMapping));

      const _file = () => {
        if (importType === 'csv') {
          return file
        }
        if (importType === 'google') {
          const googleSheetFile = jsonToCsvFile(importJsonData)
          return googleSheetFile
        }
      }
      const __file = await _file()


      await importExcelToBoard({
        importDataMapping: _importDataMapping,
        selectedColumn: _selectedColumn ?? selectedColumn ?? '',
        boardId,
        sheetName,
        importFileName,
        file: __file,
      });
    }




    if (!selectedBoardId) {
      handleFinishImporting()
    }

    dispatch(modalActions.setComponentToRender(''))

    dispatch(modalActions.setImportType());
    dispatch(importItemsActions.resetImport())
    dispatch(importItemsActions.setNewAudienceName(null));
    if (openedby === 'campaign') {
      dispatch(setActiveTab('audiences'))
    }

    await dispatch(DBBoardsThunks.find({
      _id: boardId,
      deleted: false,
      type: 'audience',
      $paginate: false,
    }))
    await dispatch(DBTaskgroupsThunks.find({
      boardId,
      deleted: false,
      archived: { $ne: true },
      $paginate: false,
    }))
    await dispatch(DBTasksThunks.find({
      boardId,
      deleted: false,
      archived: { $ne: true },
      $paginate: false,
    }))
    await dispatch(DBTaskColumnOptionThunks.find({
      boardId,
      deleted: false,
      archived: { $ne: true },
      $paginate: false,
    }))
    await dispatch(DBTaskColumnThunks.find({
      boardId,
      deleted: false,
      archived: { $ne: true },
      $paginate: false,
    }))
  }
);
