import { CustomNode, initialNodes, nodeTypes } from "@common-components/bt-chatbot/bt-nodes/bt-nodes-def";
import { Bot } from "@common-models/bot";
import { NodeType } from "@common-models/bot-node";
import { getChatbotTempId } from "@common-services/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import moment from "moment";
import { Connection, Edge, Node, SetViewport } from "reactflow";
import { clearDeletedEntities, patchDeletedEntities, setCurrentBot, setIsReadyToLoadFlow, useStore } from "./ChatbotReducer";
import { getIsOnlyNode, getNodeByNodeId } from "./ChatbotSelectors";
import { botSelectByQuery, DBBotThunks, DBEdgeThunks, DBHandleThunks, DBNodeThunks, edgeSelectByQuery, handleSelectByQuery, nodeSelectByQuery, resetBotState, resetEdgeState, resetHandleState, resetNodeState } from "@common-reducers/DBServiceThunks";





type SaveBotThunkParams = {
    setNodes: (nodes: Node[]) => void;
    setEdges: (edges: Edge[]) => void;
    setViewport: (viewport: any) => void;
    viewport: any;
    localCurrentBot?: Bot;
}

export const saveBotThunk = createAsyncThunk<
    any,
    SaveBotThunkParams,
    { state: CommonRootState }
>(
    "campaigns/saveBotThunk",
    async (SaveBotThunkParams: SaveBotThunkParams, thunkAPI) => {
        const { viewport, setNodes, setEdges, setViewport, localCurrentBot } = SaveBotThunkParams
        const { dispatch } = thunkAPI;
        const state = thunkAPI.getState();


        const currentBot: Bot = state.ChatbotReducer.currentBot
        const deletedEntities = state.ChatbotReducer.deletedEntities
        const nodes = useStore.getState().nodes;
        const edges = useStore.getState().edges;

        const flowData = {
            nodes,
            edges,
            deletedEntities,
        }

        let bot = localCurrentBot ? localCurrentBot : currentBot

        const newBot = {
            ...bot,
            viewport,
            flowData,
        }

        let createOrPatchRes
        if (bot?._id) { // this bot exists in the DB
            createOrPatchRes = await dispatch(DBBotThunks.patch({
                entity: newBot,
            }))
        } else { // this bot does not exist in the DB
            const name = bot?.name === 'New Chatbot (unsaved)' ? `Chatbot ${moment().format('DD-MM-YYYY HH:mm')}` : bot?.name
            createOrPatchRes = await dispatch(DBBotThunks.create({
                ...newBot,
                name,
            }))
            const res2 = await dispatch(setCurrentBot(createOrPatchRes.payload as Bot))
        }



        await dispatch(clearDeletedEntities({}))

        await dispatch(clearBotEntitiesFromStateThunk())
        dispatch(DBBotThunks.find({ $paginate: false })) // return a fresh bot list from the DB

        const newBotFromDB = await dispatch(getAllChatbotDataFromDBThunk(createOrPatchRes?.payload?._id))

        const userChatbots = await botSelectByQuery(state.DBBotReducer, {})
        const selectedBot = userChatbots?.find(bot => bot._id === createOrPatchRes?.payload?._id)

        await dispatch(setCurrentBot(newBotFromDB?.payload ?? selectedBot))

        await dispatch(changeFlowDataThunk({ setNodes, setEdges, setViewport }))

        await dispatch(setIsReadyToLoadFlow(true))
    })


type AddNodeParams = {
    type: string;
    position: any;
    menuPosition?: any;
    startingNode?: { id: string, type: string, nodeId: string };
    isOnlyNode?: boolean;
};

const addNodeHelper = ({ type, position, menuPosition, startingNode, isOnlyNode }
    : AddNodeParams): { newEdgeConnection: Connection, newNode: Partial<CustomNode> } => {
    const id = getChatbotTempId();

    // Calculate random shift within ±10% of viewport dimensions
    const randomShiftX = (Math.random() - 0.8) * 0.5 * window.innerWidth;
    const randomShiftY = (Math.random() - 0.8) * 0.5 * window.innerHeight;

    // Apply random shift to the center position
    const positionWithOffset = {
        x: position.x + randomShiftX,
        y: position.y + randomShiftY,
    };
    const positionOnMouseDown = () => {
        if (menuPosition) {
            return {
                x: position.x
                    + ((menuPosition.x - 1065) / position.zoom),
                y: position.y
                    + ((menuPosition.y - 600) / position.zoom)
            }
        } else return { x: 0, y: 0 }
    }

    let handles = nodeTypes?.[type].handles(id)
    let extraData = nodeTypes?.[type]?.extraData(id)



    let newEdgeConnection
    if (startingNode) {
        if (startingNode.type === 'source') {
            newEdgeConnection = {
                source: startingNode.nodeId,
                sourceHandle: startingNode.id,
                target: id,
                targetHandle: handles.inputs[0].id,
            }
        } else if (startingNode.type === 'target' && type !== 'question') {
            newEdgeConnection = {
                source: id,
                sourceHandle: handles.outputs[0].id,
                target: startingNode.nodeId,
                targetHandle: startingNode.id,
            }
        }
    }

    return {
        newEdgeConnection,
        newNode: {
            id,
            position: menuPosition ? positionOnMouseDown() : positionWithOffset,
            data: {
                isStartNode: isOnlyNode,
                label: `Node ${id}`,
                handles,
                ...extraData,
            },
            type,
        }
    };
};



type AddNodeThunkParams = {
    type: string;
    addNodes: (node: any) => void; // Adjust the type as necessary
    startingNode?: { id: string, type: string, nodeId: string };
};

export const addNodeThunk = createAsyncThunk<any, AddNodeThunkParams, { state: CommonRootState }>(
    "campaigns/addNode",
    async (params: AddNodeThunkParams, thunkAPI) => {
        const state = thunkAPI.getState();
        const { type, addNodes, startingNode } = params;

        const currentReactFlowPosition = state.ChatbotReducer.currentReactFlowPosition;
        const menuPosition = state.ChatbotReducer.menuPosition;
        const isOnlyNode = getIsOnlyNode(state)

        const newNode = await addNodeHelper({ type, position: currentReactFlowPosition, menuPosition, startingNode, isOnlyNode });

        await addNodes(newNode.newNode);

        return newNode.newEdgeConnection
    }
);



export const getAllChatbotDataFromDBThunk = createAsyncThunk<any, string, { state: CommonRootState }>(
    "campaigns/getAllChatbotDataFromDBThun",
    async (botId, thunkAPI) => {
        const { dispatch } = thunkAPI;

        let res
        if (botId) {
            res = await dispatch(DBBotThunks.find({ $paginate: false, _id: botId }))
            await dispatch(DBNodeThunks.find({ $paginate: false, botId }))
            await dispatch(DBHandleThunks.find({ $paginate: false, botId }))
            await dispatch(DBEdgeThunks.find({ $paginate: false, botId }))
        } else {
            await dispatch(DBBotThunks.find({ $paginate: false }))
            await dispatch(DBNodeThunks.find({ $paginate: false }))
            await dispatch(DBHandleThunks.find({ $paginate: false }))
            await dispatch(DBEdgeThunks.find({ $paginate: false }))
        }

        if (res?.payload?.[0]) {
            return res.payload?.[0]
        }
    }
);


type ChangeFlowDataThunkParams = {
    setNodes: (nodes: Node[]) => void;
    setEdges: (edges: Edge[]) => void;
    setViewport: (viewport: any) => void;
}

export const changeFlowDataThunk = createAsyncThunk<any, ChangeFlowDataThunkParams, { state: CommonRootState }>(
    "campaigns/changeFlowDataThunk",
    async (ChangeFlowDataThunkParams: ChangeFlowDataThunkParams, thunkAPI) => {
        const { setNodes, setEdges, setViewport } = ChangeFlowDataThunkParams
        const state = thunkAPI.getState();

        const currentBot = state.ChatbotReducer.currentBot

        const viewport = currentBot?.viewport ? currentBot.viewport : { x: 0, y: 0, zoom: 1 }


        let nodes: Node[]
        let edges: Edge[]

        if (currentBot?._id) {
            const BTNodes = nodeSelectByQuery(state.DBNodeReducer, { botId: currentBot._id });
            const BTEdges = edgeSelectByQuery(state.DBEdgeReducer, { botId: currentBot._id });
            const BTHandles = handleSelectByQuery(state.DBHandleReducer, { botId: currentBot._id });

            nodes = BTNodes.map((node) => {
                const handles = {
                    inputs: BTHandles
                        .filter(handle => handle.nodeId === node._id && handle.type === 'target')
                        .map(handle => ({ id: handle._id, type: 'target' })),
                    outputs: BTHandles
                        .filter(handle => handle.nodeId === node._id && handle.type === 'source')
                        .map(handle => ({ id: handle._id, type: 'source' })),
                }
                const isStartNode = node.isStartNode
                return {
                    id: node._id,
                    position: node.position,
                    data: { ...node.data, handles, isStartNode },
                    type: node.type,
                }
            })

            edges = BTEdges.map((edge) => {
                return {
                    id: edge._id,
                    source: BTHandles.find(handle => handle._id === edge.sourceHandleId)?.nodeId,
                    target: BTHandles.find(handle => handle._id === edge.targetHandleId)?.nodeId,
                    sourceHandle: edge.sourceHandleId,
                    targetHandle: edge.targetHandleId,
                    type: 'custom-edge',
                }
            })
        } else {
            nodes = initialNodes
            edges = []
        }

        setNodes(nodes)
        setEdges(edges)
        setViewport(viewport);
    }
)



type ChangeNodeDataThunkParams = {
    setNodes: (nodes: Node[]) => void;
    id: string;
    dataToPatch: any;
}

export const changeNodeDataThunk = createAsyncThunk<any, ChangeNodeDataThunkParams, { state: CommonRootState }>(
    "campaigns/changeNodeDataThunk",
    async (ChangeNodeDataThunkParams: ChangeNodeDataThunkParams, thunkAPI) => {
        const { setNodes, id, dataToPatch } = ChangeNodeDataThunkParams
        const nodes = useStore.getState().nodes;

        const updatedNodes = nodes.map((node: Node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        ...dataToPatch
                    }
                };
            }
            return node;
        });

        await setNodes(updatedNodes)
    }
)


type SetNewStartNodeThunkParams = {
    setNodes: (nodes: Node[]) => void;
    id: string;
}

export const setNewStartNodeThunk = createAsyncThunk<any, SetNewStartNodeThunkParams, { state: CommonRootState }>(
    "campaigns/setNewStartNodeThunk",
    async (SetNewStartNodeThunkParams: SetNewStartNodeThunkParams, thunkAPI) => {
        const { setNodes, id } = SetNewStartNodeThunkParams
        const nodes = useStore.getState().nodes;

        const updatedNodes = nodes.map((node: Node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isStartNode: true
                    }
                };
            } else if (node.data.isStartNode) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isStartNode: false
                    }
                };
            }
            return node;
        });

        setNodes(updatedNodes)
    }
)





export const removeHandleAndConnectedEdgesThunk = createAsyncThunk<string, string, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesThunk",
    async (handleId, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const edges = useStore.getState().edges;

        let edgeId = ''

        const connectedEdges = edges.filter(edge => edge.sourceHandle === handleId || edge.targetHandle === handleId)
        for (const edge of connectedEdges) {
            edgeId = edge.id
            dispatch(patchDeletedEntities([edge.id, 'edge']))
        }

        dispatch(patchDeletedEntities([handleId, 'handle']))

        return edgeId
    }
)


export const removeHandleAndConnectedEdgesByHandleIdArrayThunk = createAsyncThunk<string[], string[], { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async (handleIdArray, thunkAPI) => {
        const { dispatch } = thunkAPI;

        let returnedEdgeIdArray: string[] = []

        for (const handleId of handleIdArray) {
            const resultAction = await dispatch(removeHandleAndConnectedEdgesThunk(handleId));
            if (removeHandleAndConnectedEdgesThunk.fulfilled.match(resultAction)) {
                returnedEdgeIdArray.push(resultAction.payload);
            }
        }

        return returnedEdgeIdArray
    }
)

type CopyNodeThunkParams = {
    id: string;
    type: NodeType
}

export const copyNodeThunk = createAsyncThunk<Node, CopyNodeThunkParams, { state: CommonRootState }>(
    "campaigns/copyNodeThunk",
    async (CopyNodeThunkParams: CopyNodeThunkParams, thunkAPI) => {
        const { id, type } = CopyNodeThunkParams

        const state = thunkAPI.getState();
        const nodeToCopy = getNodeByNodeId(state, id)
        const newNodeId = getChatbotTempId()
        const newNodeHandles = nodeTypes?.[type]?.handles(newNodeId)
        const newNodeData = {
            ...nodeToCopy.data,
            handles: newNodeHandles,
            isStartNode: false,
        }
        const newNode: Node = {
            id: newNodeId,
            data: newNodeData,
            position: {
                x: nodeToCopy.position.x + 50,
                y: nodeToCopy.position.y + 50,
            },
            type,
        };

        return newNode
    }
)


type HandleSelectChatbotThunkParams = {
    id: string;
    setViewport: SetViewport;
    setNodes: (nodes: Node[]) => void;
    setEdges: (edges: Edge[]) => void;

}

export const handleSelectChatbotThunk = createAsyncThunk<void, HandleSelectChatbotThunkParams, { state: CommonRootState }>(
    "campaigns/handleSelectChatbotThunk",
    async (HandleSelectChatbotThunkParams: HandleSelectChatbotThunkParams, thunkAPI) => {
        const { id, setViewport, setNodes, setEdges } = HandleSelectChatbotThunkParams
        const { dispatch } = thunkAPI
        const state = thunkAPI.getState();

        const userChatbots = botSelectByQuery(state.DBBotReducer, {})

        await dispatch(setIsReadyToLoadFlow(false))
        await dispatch(getAllChatbotDataFromDBThunk(id))
        const selectedBot = userChatbots?.find(bot => bot._id === id)

        await dispatch(setCurrentBot(selectedBot))

        dispatch(changeFlowDataThunk({ setNodes, setEdges, setViewport }))
        await dispatch(setIsReadyToLoadFlow(true))
    }
)



export const setActiveBotAsCurrentBotOnMountThunk = createAsyncThunk<string, void, { state: CommonRootState }>(
    "campaigns/setActiveBotAsCurrentBotOnMountThunk",
    async (_, thunkAPI) => {
        const { dispatch } = thunkAPI
        const state = thunkAPI.getState();

        const bots = botSelectByQuery(state.DBBotReducer, {})

        let activeBot
        for (let bot of (bots ?? [])) {
            if (bot.isActive) {
                activeBot = bot
                break
            }
        }

        return activeBot._id
    }
)



export const clearBotEntitiesFromStateThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
    "campaigns/setActiveBotAsCurrentBotOnMountThunk",
    async (_, thunkAPI) => {
        const { dispatch } = thunkAPI


        dispatch(resetNodeState());
        dispatch(resetEdgeState());
        dispatch(resetHandleState());
        dispatch(resetBotState());
    }
)
