import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper'
import ChatCell from '@common-components/tasks/columns-cells/chat-cell/chat-cell'
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs'
import RowMenuCell from '@common-components/tasks/columns-cells/row-menu-cell/row-menu-cell'
import EditTaskInput from '@common-components/tasks/edit-task-input/edit-task-input'
import { Tooltip } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { tasksSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks"
import { getTaskAccentColor } from '@common-reducers/TaskSelectors'
import { useAppSelector } from '@common-reducers/hooks/store.hook'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'

type Props = {
    snapshot: DraggableStateSnapshot,
    taskId: string;
    provided: DraggableProvided;
    setKanbanCard: (event, taskId) => void;
    columnId: string;
    _columnsForDisplay: any;
}

export default function KanbanTask({
    taskId,
    provided,
    setKanbanCard,
    columnId,
    _columnsForDisplay,
    snapshot
}: Props) {
    const task = useAppSelector((state) => tasksSelectOneObjectByQuery(state.DBTasksReducer, {
        _id: taskId,
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ]
    },
        ['_id', 'groupColor', 'groupId', 'boardId', 'text'],
    ))
    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
    const {colorAccent,name} = useAppSelector((state) => getTaskAccentColor(currentBoardView.type, task.groupId, state, taskId, columnId))

    return (
        <Stack
            key={`task_${task._id}_stack_${task._id}`}
            direction="column"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // style={styleWithOffset}
            ref={provided.innerRef}
            sx={{
                backgroundColor: "var(--task-background-color)",
                border: "1px solid var(--separator-line)",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
                marginBottom: "8px",
                boxShadow: "0px 2px 8px rgb(207 204 204 / 20%)",
                padding: "10px 5px",
                cursor: "pointer!important"

            }}
            onClick={(event) => {
                setKanbanCard(event, task._id)
                event.stopPropagation();

            }}
        >
          
                {/* task name and buttons */}
                <Stack
                    direction="row"
                    justifyContent={'space-between'}
                    sx={{
                        display: "flex",
                        zIndex: 1,
                        height: 'auto',
                    }}
                    onClick={(event) => {
                        setKanbanCard(event, task._id)
                        event.stopPropagation();
                    }}
                >
                    <Stack flexDirection="row"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            overflow: "hidden",
                            pr:1
                          
                        }}
                      
                    >

                        <EditTaskInput taskId={taskId}
                            isMultiline={true}
                            rowsMax={4}
                            boxSx={{
                                padding:"0"
                            }}
                            inputSx={{
                                padding:"0"
                            }}
                    
                            
                            />
                    </Stack>
                    {/* chat and menu */}
                    <Stack flexDirection="row" alignItems="center" onClick={(event) => {
                        setKanbanCard(event, task._id)
                        event.stopPropagation();

                    }}>
                        {
                            <Stack direction='row' alignItems={'center'}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}

                              >
                                    
                                <Tooltip title={`Assigned to group: ${name}`} arrow placement='top' >
                                <span style={{ backgroundColor: colorAccent, width: 8, height: 8, borderRadius: "50%", marginInlineEnd: 4 }}>
                                </span>
                                </Tooltip>

                           
                                <CellWrapper
                                    key={`${task._id}_chat`}
                                    columnId={columnId}
                                    taskId={task?._id}
                                    groupId={task?.groupId}
                                    boardId={task?.boardId}
                                    cellComponent={ChatCell}
                                />

                                <CellWrapper
                                    key={`${task._id}_menu`}
                                    columnId={columnId}
                                    taskId={task?._id}
                                    groupId={task?.groupId}
                                    boardId={task?.boardId}
                                    isOnKanban={true}
                                    cellComponent={RowMenuCell}
                                />

                            </Stack>
                        }
                    </Stack>
                </Stack>
                {/* spacer */}
                {!!_columnsForDisplay.length && (
                    <>

                        <div
                            style={{
                                height: 1,
                            }}
                        >
                            <div
                                style={{
                                    opacity: 0.4,
                                    height: 1,
                                    background: "var(--separator-line)",
                                }}
                            ></div>
                        </div>
                        {/* columns for display */}
                        <Stack
                            direction="column"
                            style={{
                                paddingBlock: "6px 8px",
                                paddingInline: "12px",
                                gap: 6,
                            }}
                        >
                            {_columnsForDisplay.map(
                                (column, displayIndex) => {
                                    const [cell] = columnTypeList.filter(
                                        (columnType) =>
                                            columnType.type === column.type
                                    );
                                    return (
                                        <>
                                            <Stack
                                                key={`stack_${displayIndex}_${task._id}_${column._id}`}
                                                direction="row"
                                                alignItems="center"
                                                sx={{
                                                    height: "28px",
                                                    fontSize: "11px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    position: "relative",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "40%",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        fontWeight: 500,
                                                        opacity: 0.75,
                                                    }}
                                                >
                                                    {column.title ?? column.type}:&nbsp;
                                                </div>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{
                                                        width: "60%",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                >
                                                    <CellWrapper
                                                        columnId={column._id}
                                                        taskId={task?._id}
                                                        groupId={task?.groupId}
                                                        boardId={task?.boardId}
                                                        cellComponent={cell.cellComponent}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </>
                                    );
                                }
                            )}
                        </Stack>
                    </>
                )}
        </Stack>
    )
}