import { HighlightOff } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import classes from "./task-subscribers-dialog.module.scss";

import BTInputField from '@common-components/bt-input-field/bt-input-field';
import { getFilteredUserListForSubscriptionManagement, getTaskSubscribedUsersList } from '@common-reducers/ExtraDataThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { forwardRef, useState } from 'react';
import SubscriptionManagementEntityRow from './subscription-management-entity-row';

type Props = {
    onClose: () => void;
}

const ManageSubscribersModal = forwardRef<HTMLDivElement, Props>(({
    onClose,
}: Props, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [isUpdateingSubscribers, setIsUpdatingSubscribers] = useState<boolean>(false)
    const filteredUserList = useAppSelector((state) => getFilteredUserListForSubscriptionManagement(state, (searchTerm ?? '')))
    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))



    return (
        <Stack sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 560,
            height: 506,
            bgcolor: 'var(--default-background)',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
        }}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                style={{ position: 'absolute', right: 8, top: 8 }}>
                <HighlightOff />
            </IconButton>
            <h2>Task Subscribers</h2>
            {!isUpdateingSubscribers &&
                <>
                    <div style={{
                        border: '1px solid var(--border-color)',
                        borderRadius: '8px',
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '10px',
                    }}>
                        <BTInputField className={classes.search_input}
                            sx={{ width: '100%' }}
                            type="text"
                            onChange={({ target }: any) => setSearchTerm(target.value)}
                            placeholder="Search board members" />
                        {filteredUserList.map((user, index) => {
                            return (
                                <SubscriptionManagementEntityRow key={index} user={user} index={index} setIsUpdatingSubscribers={setIsUpdatingSubscribers} />

                            )
                        })}
                        {filteredUserList?.length === 0 &&
                            <Stack sx={{ alignItems: 'center', }}>
                                <p>There are no more user to subscribe to this task</p>
                                <p>Click the 'Share Board' button to invite new board members</p>
                            </Stack>
                        }
                    </div>
                    <div style={{
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '10px',
                    }}>
                        {subscribedUsersList.map((user, index) => {
                            return (
                                <SubscriptionManagementEntityRow key={index} user={user} index={index} setIsUpdatingSubscribers={setIsUpdatingSubscribers} />
                            )
                        })}
                    </div>
                </>
            }
            {isUpdateingSubscribers &&
                <Stack sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <CircularProgress sx={{
                        width: '50px !important',
                        height: '50px !important',
                    }} />
                    <p>Updating task's subscribers</p>
                </Stack>
            }
        </Stack >
    )
})

export default ManageSubscribersModal;