import { ReactComponent as AddIcon } from "@common-assets/add.svg";
import { RiCloseCircleFill } from "react-icons/ri";
import { CellProps } from "../cell-interface";
import classes from "./date_cell.module.scss";
import { ReactComponent as DateIcon } from "./small-calendar.svg";

import { Skeleton } from "@mui/material";
import { isTempId } from "@common-reducers/DBServiceThunks";
import moment from "moment";

export const DateCell = ({
  cellValue,
  taskId,
  onUpdate,
  columnId,
  togglePicker
}: CellProps) => {
  const dateFormatted = moment(cellValue).isSame(new Date(), 'year') ? moment(cellValue).format("MMM DD") : moment(cellValue).format("MMM DD, YYYY");
  const anchorId = `date_${taskId}_${columnId}`;

  function handleClearValue(e) {
    e.stopPropagation()
    onUpdate(undefined);
  }

  return (
    isTempId(columnId) ? 
    ( <Skeleton  variant="rectangular" />)
    :
    <div className={classes.dateContainer} onClick={() => togglePicker({
      anchorId: anchorId,
      pickerType: 'date-picker'
    })} id={anchorId}>
        <div className={classes.dateCell}>
          {!cellValue && (
            <>
              <AddIcon className={classes.addIcon} />
              <DateIcon className={classes.dateIcon} />
            </>
          )}
          <div>{cellValue && (<>{cellValue ? dateFormatted : ""}</>)}</div>
        </div>
      {cellValue && (
        <RiCloseCircleFill
          onClick={(e) => handleClearValue(e)}
          className={classes.clearIcon}
        />
      )}
    </div>
  );
}

export default DateCell;