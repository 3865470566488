import { useCallback, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import { AccountPage } from "@common-components/account-page/account-page";
import BTThemeProvider from "@common-components/bt-theme-provider";

import { DBWorkspaceThunks } from "@common-reducers/DBServiceThunks";
import { useRouter } from "@common-reducers/hooks/use-router";
import { workspaceActions } from '@common-reducers/WorkspaceReducer';
import { getSelectedWorkspace } from "@common-reducers/WorkspaceSelectors"
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";

import WorkspaceForm from "@common-components/workspace-form/workspace-form";

import { SideBarItem } from "@common-components/sidebar/sidebar";

export interface WorkspaceViewProps {
  editMode?: boolean;
}

export interface WorkspaceViewState {

}

const WorkspaceView = ({ editMode }: WorkspaceViewProps) => {
  const router = useRouter();

  const selectedWorkspace = useAppSelector(getSelectedWorkspace);

  const dispatch = useAppDispatch();

  const getWorkspaces = useCallback(() => {
    dispatch(
      DBWorkspaceThunks.find({
        returnAlsoWorkspaceOwnerName: true,
        deleted: { $ne: true },
        $sort: {
          name: 1,
        },
        $limit: -1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getWorkspaces()
  }, [getWorkspaces]);

  useEffect(() => {
    if (editMode && 'ws' in router.query) {
      dispatch(workspaceActions.setSelectedWorkspaceId(router.query.ws as string));
    }
  }, [editMode, dispatch, router]);

  const workspaceView = () => {
    return (
      editMode ? (selectedWorkspace && selectedWorkspace._id === router.query.ws ? <WorkspaceForm selectedWorkspace={selectedWorkspace} /> : <Stack alignItems="center" justifyContent="center" width="100%" height="100%"><CircularProgress /></Stack>) : <WorkspaceForm />
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={workspaceView()}
        active={SideBarItem.Workspace}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default WorkspaceView;
