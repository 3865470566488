import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SubscriptionPopupState {
    isOpen: boolean;
    lockedItem?: string;
}

const initialState: SubscriptionPopupState = {
    isOpen: false
};

const SubscriptionPopupSlice = createSlice({
  name: "subscription-popup",
  initialState,
  reducers: {
    closePopup(state) {
      state.isOpen = false;
      delete state.lockedItem;
    },
    openPopup(state, action: PayloadAction<string | undefined>) {
      state.isOpen = true;
      state.lockedItem = action.payload;
    }
  },
});

export const { closePopup, openPopup } = SubscriptionPopupSlice.actions;
export const subscriptionPopupActions = SubscriptionPopupSlice.actions;
export default SubscriptionPopupSlice.reducer;
