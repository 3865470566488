import { Backdrop, Drawer, DrawerProps } from "@mui/material";
import { PropsWithChildren, useState } from "react";

interface BTDrawerProps {
  anchor?: "right" | "top" | "left" | "bottom";
  variant?: string
  open?: boolean
}

const BTDrawer = (props: PropsWithChildren<BTDrawerProps> & DrawerProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Drawer
  
      PaperProps={{sx:{width: props.variant === 'temporary' ? "100%" : "auto",
      border: "0 !important",
      boxShadow: '0 0 20px 0 var(--shadow-default-color)',
      background:'var(--secondary-background-color)'}}}
      {...props}
      hideBackdrop={!isHover}
      variant={props.variant ? props.variant : 'persistent'}
    >

      <Backdrop
        open={isHover}>
      </Backdrop>
      <div
        style={{
          height: "100%",
          zIndex: 9999
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseUp={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {props.open && props.children}
      </div>

    </Drawer>
  );
};
export default BTDrawer;
