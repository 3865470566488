import RowStack from "@common-components/common/row-stack/RowStack";
import BtEditableBox from "@common-components/tasks/bt-editable-box/bt-editable-box";
import { Check, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from "@mui/material";
import { changeFlowDataThunk, getAllChatbotDataFromDBThunk, saveBotThunk } from "@common-reducers/ChatbotThunks";
import { botSelectByQuery, DBBotThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import BotToggleSwitch from "./bot-toggle-switch";
import BTbotbutton from "./bt-bot-button";
import ChatBotTimer from "./chatbot-timer-settings";
import FallbackMessage from "./fallback-message-settings";
import { isAlreadyActiveChatbot } from "@common-reducers/ChatbotSelectors";
import { setCurrentBot, setIsReadyToLoadFlow, useStore } from "@common/reducers/ChatbotReducer";


const inputTheme = {
    padding: "0 10px",
    maxWidth: "280px",
    fontSize: "22px",
    fontWeight: "600",
    height: '33px',
    borderRadius: "8px",
    border: "1px solid #23a455",
    backgroundColor: "#e9ffe9",
    "& .MuiInputBase-input": {
        padding: "0",
    },
};
const textTheme = {
    fontSize: "22px",
    padding: "0 10px",
    borderRadius: "8px",
    border: "1px dashed transparent",
    fontWeight: "600",
    "&:hover": {
        border: "1px dashed #23a455",
    },
    height: '33px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
    boxSizing: 'border-box',
    lineHeight: '34px',
};


export type onChangeParams = {
    type: 'isFallbackMessage'
    | 'fallbackMessageTrigger'
    | 'fallbackMessage'
    | 'chatbotTimerCountdown'
    | 'isChatbotTimer'
    | 'chatbotTimerEndMessage'
    | 'chatbotTimerMessageBeforeEnd',
    event?: any,
    value?: any,
}


type Props = {
    onClose: () => void;
    id?: string;
}

const BotSettings = ({ onClose, id }: Props) => {
    const dispatch = useAppDispatch()

    const currentBot = useAppSelector((state) => state.ChatbotReducer.currentBot);
    const userChatbots = useAppSelector((state) => botSelectByQuery(state.DBBotReducer, {}))
    const hasAlreadyActiveChatbot = useAppSelector((state) => isAlreadyActiveChatbot(state))

    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)
    const [editBotName, setEditBotName] = useState(false)
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);


    const { setViewport } = useReactFlow();
    const { setNodes, setEdges } = useStore();
    const reactFlowInstance = useReactFlow();
    const viewport = reactFlowInstance.getViewport();


    const onChange = ({ type, event, value }: onChangeParams) => {
        let newValue
        switch (type) {
            case 'isFallbackMessage':
                newValue = !localCurrentBot.botSettings.isFallbackMessage
                break;
            case 'fallbackMessageTrigger':
                newValue = event.target.value
                break;
            case 'fallbackMessage':
                newValue = value
                break;
            case 'chatbotTimerCountdown':
                newValue = parseInt(value.replace(/\D/g, ''), 10);
                break;
            case 'isChatbotTimer':
                newValue = !localCurrentBot.botSettings.isChatbotTimer
                break;
            case 'chatbotTimerEndMessage':
                newValue = value
                break;
            case 'chatbotTimerMessageBeforeEnd':
                newValue = parseInt(value.replace(/\D/g, ''), 10);
                break;
        }
        setLocalCurrentBot({
            ...localCurrentBot,
            botSettings: {
                ...localCurrentBot.botSettings,
                [type]: newValue
            }
        })
    }


    const handleEditName = (value) => {
        if (typeof value === 'string' && value.trim() !== '' && value !== localCurrentBot.name) {
            setLocalCurrentBot({
                ...localCurrentBot,
                name: value
            });
        }
    }

    const openSaveConfirmationDialog = () => {
        if (hasAlreadyActiveChatbot && localCurrentBot?.isActive) {
            setIsSaveConfirmationOpen(true);
        } else {
            executeSaveNewBotSettings(); // Call the save function directly if no active chatbot exists
        }
    };

    const closeSaveConfirmationDialog = (confirmed) => {
        setIsSaveConfirmationOpen(false);
        if (confirmed) {
            executeSaveNewBotSettings();
        }
    };

    const executeSaveNewBotSettings = async () => {
        dispatch(setIsReadyToLoadFlow(false));
        await dispatch(saveBotThunk({
            viewport,
            setViewport,
            setNodes,
            setEdges,
            localCurrentBot,
        }));
        onClose();
    };


    const Header = () => {
        return (
            <RowStack sx={{ display: "flex", justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BtEditableBox
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={localCurrentBot.name}
                        forceEditMode={editBotName}
                        onBlur={(e) => {
                            setEditBotName(false)
                        }}
                        onValueUpdate={(value) => {
                            handleEditName(value);
                        }} />
                    {!editBotName &&
                        <IconButton onClick={(event) => { setEditBotName(true) }} disableRipple>
                            <EditIcon sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    }
                    {editBotName &&
                        <IconButton onClick={(event) => { setEditBotName(false) }} disableRipple>
                            <Check sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    }
                </div>
                <IconButton onClick={onClose} disableRipple>
                    <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
            </RowStack>
        )
    }

    const handleSelectChatbot = async (id) => {
        await dispatch(setIsReadyToLoadFlow(false))
        await dispatch(getAllChatbotDataFromDBThunk(id))
        const selectedBot = userChatbots?.find(bot => bot._id === id)

        await dispatch(setCurrentBot(selectedBot))

        dispatch(changeFlowDataThunk({ setNodes, setEdges, setViewport }))
        await dispatch(setIsReadyToLoadFlow(true))
    }

    const handleClickDeleteChatbot = async () => {
        await dispatch(setIsReadyToLoadFlow(false))
        await dispatch(DBBotThunks.delete({ entity: currentBot }))
        await dispatch(DBBotThunks.find({ $paginate: false }))
        handleSelectChatbot(undefined)
        await dispatch(setIsReadyToLoadFlow(true))
    }

    const Footer = () => {
        return (
            <RowStack sx={{ justifyContent: 'space-between', marginTop: '40px' }}>
                <BTbotbutton onClick={handleClickDeleteChatbot} sx={{
                    backgroundColor: '#ff0000',
                    '&:hover': {
                        backgroundColor: '#ff3737',
                    },
                }}>Delete Chatbot</BTbotbutton>
                <div>
                    <BTbotbutton styleType="empty" onClick={onClose}>Cancel</BTbotbutton>
                    <BTbotbutton
                        sx={{ marginLeft: '13px' }}
                        onClick={openSaveConfirmationDialog}
                        disabled={localCurrentBot.botSettings?.blockSaveNewBotSettings}
                    >
                        Save
                    </BTbotbutton>
                    <Dialog
                        open={isSaveConfirmationOpen}
                        onClose={() => closeSaveConfirmationDialog(false)}
                        aria-labelledby="save-confirmation-dialog-title"
                        aria-describedby="save-confirmation-dialog-description"
                    >
                        <DialogTitle id="save-confirmation-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="save-confirmation-dialog-description">
                                Activating this bot will deactivate your previously active bot. Please confirm if you wish to proceed.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => closeSaveConfirmationDialog(false)}>Cancel</Button>
                            <Button onClick={() => closeSaveConfirmationDialog(true)} autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </RowStack>
        )
    }

    const BotActivationToggleSwitch = () => {
        return (
            <RowStack style={{ width: '100%', padding: '10px', }}>
                <span style={{ fontSize: '15px', paddingRight: '15px', }}>Activate/deactivate chatbot:</span>
                <BotToggleSwitch
                    checked={localCurrentBot?.isActive ?? false}
                    onChange={(e) => {
                        setLocalCurrentBot({
                            ...localCurrentBot,
                            isActive: e.target.checked
                        })
                    }}
                />
            </RowStack>
        )
    }



    useEffect(() => {
        if (
            localCurrentBot.botSettings?.fallbackMessage?.length > 1024 ||
            localCurrentBot.botSettings?.chatbotTimerCountdown <= 1 ||
            localCurrentBot.botSettings?.chatbotTimerCountdown > 1440 ||
            localCurrentBot.botSettings?.chatbotTimerEndMessage?.length > 1024 ||
            localCurrentBot.botSettings?.chatbotTimerMessageBeforeEnd <= 0 ||
            localCurrentBot.botSettings?.chatbotTimerMessageBeforeEnd >= localCurrentBot.botSettings?.chatbotTimerCountdown) {
            setLocalCurrentBot({
                ...localCurrentBot,
                botSettings: {
                    ...localCurrentBot.botSettings,
                    blockSaveNewBotSettings: true
                }
            });
        } else {
            setLocalCurrentBot({
                ...localCurrentBot,
                botSettings: {
                    ...localCurrentBot.botSettings,
                    blockSaveNewBotSettings: false
                }
            });
        }
    }, [localCurrentBot]);

    return (
        <Stack sx={{
            color: 'black',
            gap: '30px',
            '& > *:not(:last-child)': {
                borderBottom: '1px solid rgb(227, 227, 227)',
            },
        }} onClick={(e) => e.stopPropagation()}>
            {Header()}
            {BotActivationToggleSwitch()}
            <FallbackMessage
                isFallbackMessage={localCurrentBot.botSettings?.isFallbackMessage}
                onChange={onChange}
                fallbackMessage={localCurrentBot.botSettings?.fallbackMessage}
                fallbackMessageTrigger={localCurrentBot.botSettings?.fallbackMessageTrigger}
            />
            <ChatBotTimer
                isChatbotTimer={localCurrentBot.botSettings?.isChatbotTimer}
                chatbotTimerCountdown={localCurrentBot.botSettings?.chatbotTimerCountdown}
                chatbotTimerEndMessage={localCurrentBot.botSettings?.chatbotTimerEndMessage}
                chatbotTimerMessageBeforeEnd={localCurrentBot.botSettings?.chatbotTimerMessageBeforeEnd}
                onChange={onChange}
            />
            {Footer()}
        </Stack>
    );
}

export default BotSettings;