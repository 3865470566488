import {
  CompareFunctionOptions,
  PickerProps,
  TaskColumnType
} from "@common-components/tasks/columns-cells/cell-interface";
import { columnTypeList } from "@common-components/tasks/columns-cells/column-cell-defs";
import {
  BoardRowElement, mergeGroupsAndTasks
} from "@common-components/tasks/helpers/elements-factory/elementsFactory";
import { Board } from "@common-models/board";
import { BoardView } from "@common-models/board-views";
import { Gantt } from "@common-models/gantt";
import {
  BTdragElement,
  BoardRowType,
  CustomColumnData,
  Task,
} from "@common-models/task";
import { TaskColumn } from "@common-models/task-column";
import { TasksGroup } from "@common-models/task-group";
import { workspaceUserCompareValue } from "@common-models/workspace-user";
import { AppThunkDispatch } from "@common/types/common-root-state-type";

import { groupcolors } from "@common/theme";
import randomstring from "randomstring";
import {
  AugmentedBlueticksBoardUser,
  getBoardByChat,
  getCurrentBoard,
  getCurrentBoardId,
  getMockPersonList,
  getSelectedBoardCompleteUserList,
  getSelectedBoardId
} from "./BoardsSelectors";
import { getBoardDataThunk } from "./BoardsThunks";
import { bulkActions, getBulkTaskList } from "./BulkActionsReducer";
import {
  DBBoardViewThunks,
  DBBoardsThunks,
  DBFormThunks,
  DBGanttThunks,
  DBKanbanThunks,
  DBTaskColumnOptionThunks,
  DBTaskColumnThunks,
  DBTaskgroupsThunks,
  DBTasksExtraDataFilesThunks,
  DBTasksThunks,
  DBViewFiltersThunks,
  DBWorkloadThunks,
  _taskColumnOptionSelectOneObjectByQuery,
  _tasksExtraDataFilesSelectOneObjectByQuery,
  boardUserSelectByQuery,
  boardViewSelectByQuery,
  boardViewSelectOneFieldByQuery,
  boardsSelectors,
  formSelectOneObjectByQuery,
  ganttSelectByQuery,
  ganttSelectOneFieldByQuery,
  kanbanSelectByQuery,
  kanbanSelectOneFieldByQuery,
  kanbanSelectOneObjectByQuery,
  taskColumnOptionSelectByQuery,
  taskColumnSelectByQuery,
  taskColumnSelectors,
  taskColumnsSelectByQueryOnlyMaxMin,
  taskGroupsSelectByQuery,
  taskGroupsSelectByQueryOnlyMaxMin,
  taskGroupsSelectOneFieldById,
  taskGroupsSelectOneFieldByQuery,
  taskGroupsSelectOneObjectById,
  taskGroupsSelectOneObjectByQuery,
  taskgroupsReducerActions,
  taskgroupsSelectors,
  tasksExtraDataSelectByQuery,
  tasksReducerActions,
  tasksSelectByQuery,
  tasksSelectByQueryOnlyLength,
  tasksSelectByQueryOnlyMaxMin,
  tasksSelectOneFieldById,
  tasksSelectOneObjectById,
  tasksSelectors,
  viewFiltersSelectByQuery,
  workloadSelectOneFieldByQuery
} from "@common-reducers/DBServiceThunks";
import { pickerDialogActions } from "./PickerDialogReducer";
import { subscriptionPopupActions } from "./SubscriptionPopupReducer";
import { SortOrder, taskActions } from "./TasksReducer";
import { activeSubscriptionSelector } from "./UserSelectors";
import {
  deleteItems,
  getArchivedOrDeletedData,
  restoreItems,
} from "./backend-api/backend-api";

import { ViewFilter } from "@common-components/tasks-panel/filter-button/advanced_filters";
import { FileItem } from "@common-models/ExtraDataItem";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { CreateMockPersonThunkParams, OpenPickerThunkParams, UpdateBoardPersonOptions, UpdateCellValueParams, UpdateFormAfterSubmitEventParams, UpdateFormIncludeNameQuestionParams, UpdateKanbanCardTaskMapThunkParams, UpdateKanbanOptionColumnParams, UpdateTaskOrderValueParams, updateTaskOrderInWorkloadUserParams } from "./TaskInterfaces";

import { Task as GanttTask } from "@common-components/reactgantt";
import { myWorkStaticColumnList, staticColumnList } from "@common-components/tasks/helpers/columnList";
import { TaskColumnOption } from "@common-models/task-column-option";
import { User } from "@common-models/user";
import { CommonRootState } from "@common/types/common-root-state-type";
import { ArchivedOrDeletedItem, recycleActions } from "./RecycleBinReducer";
import { baseTasks, currentTasks, getExtraDataMergedFiles, getGroupTaskListLength, getGroupTasksListWithoutSubTasks, getIncludedColumnsByBoardId, } from "./TaskSelectors";
import { selectMergedContactMapWithNamesOnly } from "./WhatsAppReducer";
import { NullableId } from "@common/types/interface";
import { GenericState } from "./DBServiceReducersInterfaces";







// let filterList = viewFiltersSelectByQuery(
//   state.DBViewFiltersReducer,
//   {
//     viewId: boardView?._id ?? boardId,
//     $or: [{ deleted: { $exists: false } }, { deleted: false }],
//   },
//   null,
//   {
//     updatedAt: 1,
//   }
// );
// if (boardView?.filterView) {
//   filterList = [...boardView?.filterView];
// }

// if (filterList.length > 0) {
//   tasksFilteredList = filteredTasks.filter((task) => {
//     return isTaskMatchingFiltersSelector(
//       state,
//       task,
//       filterList,
//       columnList
//     );
//   });
// }

// if (usersListFilter !== "") {
//   const personPickerColumns = columnList.filter(
//     (obj) => obj.type === "person-picker"
//   );
//   const personPickerColumnsIds = personPickerColumns.map(({ _id }) => _id);
//   if (personPickerColumnsIds.length > 0) {
//     filteredTasks = isTaskAssignedToOneOfThoseUsers(
//       filteredTasks,
//       usersListFilter,
//       personPickerColumnsIds
//     );
//   }
// }

// if (filterList.length > 0) {
//   filteredTasks = filteredTasks.filter((task) => {
//     return tasksFilteredList.includes(task);
//   });
// }

// filteredTasks = filteredTasks.map((task) => ({
//   ...task,
//   groupColor: getOneFieldFromTaskGroupByTaskId({
//     state,
//     taskId: task._id,
//     field: "colorAccent",
//   }),
// }));

// return filteredTasks;
//   }
// );

const compareValues = (a, b, options: CompareFunctionOptions) => {
  let compareVal;
  try {
    if (typeof a === "string" && typeof b === "string") {
      compareVal =
        options.sortOrder === "asc" ? a?.localeCompare(b) : b?.localeCompare(a);
    } else {
      a = a || Infinity;
      b = b || Infinity;
      compareVal = options.sortOrder === "asc" ? a - b : b - a;
    }
  } catch (e) {
    console.error(e);
  }

  return compareVal ?? 0;
};

const sortTasksOrderByColumn = (
  taskList: Task[],
  column: TaskColumn,
  options: CompareFunctionOptions
): Task[] => {
  const columnType: TaskColumnType = columnTypeList.find(
    (columnType) => columnType.type === column.type
  );
  const compareFunction = columnType?.compareFunction ?? compareValues;

  taskList.sort((taskA, taskB) => {
    const valueA = cellValueByTaskAndColumnId(taskA, column);
    const valueB = cellValueByTaskAndColumnId(taskB, column);
    return compareFunction(valueA, valueB, options);
  });
  return taskList;
};

const isTaskAssignedToOneOfThoseUsers = (
  tasks,
  personsToFilterBy,
  personPickerColumnsIds
) => {
  let filteredTasksByPerson = [];
  for (let i = 0; i < tasks.length; i++) {
    // console.log(`Task loop number ${i}`);
    let result = [];
    const currentTask = tasks[i];
    result = searchForPersonMatchInColumns(
      currentTask,
      personPickerColumnsIds,
      personsToFilterBy
    );
    if (result.length > 0) {
      filteredTasksByPerson.push(currentTask);
    }
  }
  return filteredTasksByPerson;
};

const isTaskEqualToOptionColumn = (tasks, columnId, columnOptionId) => { };

const findDuplicates = (selectedOptionsCloned) => {
  const duplicates = {};
  selectedOptionsCloned.forEach((obj) => {
    if (!duplicates[obj?._id]) {
      duplicates[obj?._id] = 0;
    }
    duplicates[obj?._id]++;
  });
  return duplicates;
};

const searchForPersonMatchInColumns = (
  task,
  currentColumn,
  personsToFilterBy
) => {
  let tasksMatchedByPersonSelected = [];
  let personsSelectedUserIdList = [];

  //loop columnsList
  for (let i = 0; i < currentColumn.length; i++) {
    //console.log(`Column loop number ${i}`);
    const personsSelected = task?.customData?.[currentColumn[i]]?.value;
    if (personsSelected) {
      //loop column persons selected
      personsSelected.forEach(function (item) {
        personsSelectedUserIdList.push(item.id);
      });
    }
    const isPersonMatch = personsSelectedUserIdList
      ? personsSelectedUserIdList.includes(personsToFilterBy)
      : false;
    if (isPersonMatch) {
      tasksMatchedByPersonSelected.push(task);
      return tasksMatchedByPersonSelected;
    }
    if (i === currentColumn.length - 1) {
      return tasksMatchedByPersonSelected;
    }
  }
};

export const getColumnList = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let columnsData = [];
    const currentBoardId = getSelectedBoardId(state);
    const taskColumnState: GenericState<TaskColumn> = state.DBTaskColumnReducer;
    const columnList = taskColumnState?.entities;
    columnsData = Object.values(columnList);
    const finalColumnList = columnsData.filter(
      (columnItem) => columnItem.boardId === currentBoardId
    );
    return finalColumnList;
  }
);

export const boardTaskSelector = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    return tasksSelectByQuery(state.DBTasksReducer, {
      boardId: getSelectedBoardId(state),
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
  }
);

export const createProjectItemByGroup = createSelector(
  [
    (state) => state,
    (_, taskList: any[], columnId) => ({ taskList, columnId }),
  ],
  (state: CommonRootState, { taskList, columnId }) => {
    const listOfGroupTasks = [];
    const listOfProjects = [];
    let groupId;
    let groupIdForBattery;
    for (let i = 0; i < taskList.length; i++) {
      const task = taskList[i];
      const taskGroupId = task?.groupId;
      const filterTasksByGroupID = taskList.filter((task) => {
        groupIdForBattery = task.groupId;
        return task.groupId === taskGroupId;
      });

      if (filterTasksByGroupID[0]?.groupId !== groupId) {
        groupId = filterTasksByGroupID[0]?.groupId;
        listOfGroupTasks.push(filterTasksByGroupID);
      }
    }

    const battaryGroup = getBattaryProgressGanttGroupBar(
      state,
      groupIdForBattery
    );
    console.log(battaryGroup);

    for (let i = 0; i < listOfGroupTasks.length; i++) {
      const projectTasks = listOfGroupTasks[i];
      let groupId, groupName, groupColor;

      const maxDate = new Date(
        Math.max(
          ...projectTasks.map((task) => {
            return new Date(task?.customData[columnId]?.value?.endDate);
          })
        )
      );
      const minDate = new Date(
        Math.min(
          ...projectTasks.map((task) => {
            return new Date(task?.customData[columnId]?.value?.startDate);
          })
        )
      );
      projectTasks.map((task) => {
        groupColor = task?.groupColor;
        groupId = task?.groupId;
        groupName = taskGroupsSelectOneFieldByQuery(
          state.DBTaskgroupsReducer,
          { _id: groupId },
          "name"
        );
      });

      const projectObj: any = {
        start: new Date(minDate),
        end: new Date(maxDate),
        name: groupName,
        id: groupId,
        progress: 100,
        isDisable: false,
        type: "project",
        hideChildren: false,
        styles: {
          projectBackgroundColor: groupColor,
          projectBackgroundCriticalColor: groupColor,
          projectBackgroundSelectedColor: groupColor,
          // groupProgressColor:'linear-gradient(90deg, rgba(37,154,233,1) 1%, rgba(0,212,255,0) 1%)',
          // groupProgressCriticalColor:'red',
          // projectProgressColor: "red",
          // projectProgressCriticalColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%);",
          // projectProgressSelectedColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%)",
          // projectProgressSelectedCriticalColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%)",
        },
      };
      const thisProjectExist = listOfProjects.find(
        (task) => projectObj.id === task.id
      );
      if (!thisProjectExist) {
        listOfProjects.push(projectObj);
      }
    }

    return listOfProjects;
  }
);

export const getGanttTaskItem = createSelector(
  [
    (state) => state,
    (_, taskId: any, columnId) => ({ taskId, columnId }),
  ],
  (state: CommonRootState, { taskId, columnId }) => {
    const ganttTaskList = currentTasks(state);

    for (let i = 0; i < ganttTaskList.length; i++) {
      const task = ganttTaskList[i];
      if (taskId === task._id) {
        return task.customData[columnId]?.value;
      }
    }
  }
);

export const getGanttColumnValueItem = createSelector(
  [
    (state) => state,
    (_, picker: string, taskId: any) => ({ picker, taskId }),
  ],
  (state: CommonRootState, { picker, taskId }) => {
    const boardId = getSelectedBoardId(state);
    const finalList = [];
    const columnList = columnListSelector(state, boardId);
    const allColumns = columnList.filter((column) => column.type === picker);

    allColumns.map((column, index) => {
      if (column.type === picker) {
        const ganttTask: any = getGanttTaskItem(state, taskId, column?._id);
        if (ganttTask) {
          finalList.push(...ganttTask);
        }
      }
    });
    return finalList;
  }
);

export const getFixedDependencyList = createSelector(
  [
    (state) => state,
    (_, ganttTaskList: any[]) => ({ ganttTaskList }),
  ],
  (state: CommonRootState, { ganttTaskList }) => {
    const currentBoardTasks = currentTasks(state);
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    function minusDays(date, days) {
      date.setDate(date.getDate() - days);
      return date;
    }
    for (let i = 0; i < ganttTaskList.length; i++) {
      const task = ganttTaskList[i];
      if (
        task?.type === "task" &&
        Object.keys(task?.dependencies[0] ?? {}).length !== 0
      ) {
        const arrowEndTarget = task?.dependencies[0]?.ownTarget;
        const arrowStartTarget = task?.dependencies[0]?.sourceTarget;
        const taskSendArrowId = task?.dependencies[0]?.sourceId;
        const taskSendArrow = ganttTaskList.find((task) => {
          if (taskSendArrowId === task?.id) return task;
        });

        switch (arrowEndTarget) {
          case "startOfTask":
            if (arrowStartTarget === "startOfTask") {
              if (taskSendArrow.start.getTime() > task?.start.getTime()) {
                const days =
                  (taskSendArrow.start.getTime() - task?.start.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days);
                task.end = addDays(task.end, days);
              }
            } else if (arrowStartTarget === "endOfTask") {
              if (taskSendArrow.end.getTime() > task?.start.getTime()) {
                const days =
                  (taskSendArrow.end.getTime() - task?.start.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            }
            break;
          case "endOfTask":
            if (arrowStartTarget === "startOfTask") {
              if (taskSendArrow.start.getTime() >= task?.end.getTime()) {
                const days =
                  (taskSendArrow.start.getTime() - task?.end.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            } else if (arrowStartTarget === "endOfTask") {
              if (taskSendArrow.end.getTime() >= task?.end.getTime()) {
                const days =
                  (taskSendArrow.end.getTime() - task?.end.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            }

            break;
        }
      }
    }
  }
);

export const getBattaryProgressGanttGroupBar = createSelector(
  [(state) => state, (_, groupId: string) => ({ groupId })],
  (state: CommonRootState, { groupId }) => {
    interface BatteryObject {
      bgColor: String;
      percentage: Number;
    }
    const boardId = getSelectedBoardId(state);

    const type = "status-option-picker";
    const columnList = columnListSelector(state, boardId).map(
      (column, index) =>
      ({
        type: column.type,
        _id: column._id,
        title: column.title,
      } as TaskColumn)
    );
    const columnRes = columnList.filter(
      (columnItem) => columnItem.type === type
    );
    const columnId = columnRes[0]?._id;
    const params = {
      groupId,
      columnId,
      boardId,
      columnType: "status-option-picker",
    };
    const batteryIndicator: BatteryObject[] = [];
    const values = getColumnValues(state, params);
    let percentageForTask;

    const getPercentage = (optionCount, totalTasks) => {
      const percentage = (optionCount / totalTasks) * 100;
      return percentage % 1 !== 0 ? percentage.toFixed(2) : percentage;
    };
    const selectedOptions = values?.selectedOptionsCloned?.filter(
      (obj, index, self) => {
        return index === self.findIndex((t) => t?._id === obj?._id);
      }
    );
    const sortedSelectedOptions = selectedOptions?.sort(
      (a, b) => a?.order - b?.order
    );
    let res = `linear-gradient(`;

    sortedSelectedOptions.map((column, index) => {
      // console.log(column)
      const columnColor = column?.bgcolor;

      percentageForTask = getPercentage(column?.count, values?.tasksLength);
      batteryIndicator.push({
        bgColor: columnColor,
        percentage: percentageForTask,
      });
    });
    // console.log(batteryIndicator)
    batteryIndicator.map((groupOfStatus) => {
      const itemGradient = `90deg, ${groupOfStatus.bgColor}, ${groupOfStatus.percentage},`;
      res += itemGradient;
    });
    res += " )";
    // console.log(res)
    return res;
  }
);

export const getGanttTasks = createSelector(
  [(state) => state, (_, columnId: NullableId) => columnId],
  (state: CommonRootState, columnId) => {
    const boardId = getSelectedBoardId(state);
    const tasksList = getTasksWithValueInColumnIdSelector(state, columnId);
    const projectList = createProjectItemByGroup(state, tasksList, columnId);
    const ganttsListInBoard = ganttSelectByQuery(state.DBGanttReducer, {
      boardId: boardId,
    });
    const orderTasksBy = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "orderTasksBy"
    );
    const milestoneTasks = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "milestoneTasks"
    );
    const groupView = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "showTasksByGroup"
    );

    const fixDependency = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "fixDependency"
    );

    // const defaultColors: ColorStyles = {
    //   arrowColor: "grey",
    //   arrowCriticalColor: "#ff0000",
    //   arrowWarningColor: "#000",
    //   barProgressColor: "#000",
    //   barProgressCriticalColor: "#ff1919",
    //   barProgressSelectedColor: "#8282f5",
    //   barProgressSelectedCriticalColor: "#ff0000",
    //   barBackgroundColor: "#000",
    //   barBackgroundCriticalColor: "#ff6363",
    //   barBackgroundSelectedColor: "#aeb8c2",
    //   barBackgroundSelectedCriticalColor: "#ff8e8e",
    //   groupProgressColor: "#2dbb2e",
    //   groupProgressCriticalColor: "#2dbb2e",
    //   groupProgressSelectedColor: "#28a329",
    //   groupProgressSelectedCriticalColor: "#28a329",
    //   groupBackgroundColor: "#006bc1",
    //   groupBackgroundCriticalColor: "#006bc1",
    //   groupBackgroundSelectedColor: "#407fbf",
    //   groupBackgroundSelectedCriticalColor: "#407fbf",
    //   projectProgressColor: "#7db59a",
    //   projectProgressCriticalColor: "#7db59a",
    //   projectProgressSelectedColor: "#59a985",
    //   projectProgressSelectedCriticalColor: "#59a985",
    //   projectBackgroundColor: "#fac465",
    //   projectBackgroundCriticalColor: "#fac465",
    //   projectBackgroundSelectedColor: "#f7bb53",
    //   projectBackgroundSelectedCriticalColor: "#f7bb53",
    //   milestoneBackgroundColor: "#f1c453",
    //   milestoneBackgroundCriticalColor: "#ff8e8e",
    //   milestoneBackgroundSelectedColor: "#f29e4c",
    //   milestoneBackgroundSelectedCriticalColor: "#ff0000",
    //   evenTaskBackgroundColor: "#f5f5f5",
    //   holidayBackgroundColor: "rgba(233, 233, 233, 0.3)",
    //   selectedTaskBackgroundColor: "rgba(252, 248, 227, 0.5)",
    //   todayColor: "rgba(252, 248, 227, 0.5)",
    //   contextMenuBoxShadow: "rgb(0 0 0 / 25%) 1px 1px 5px 1px",
    //   contextMenuBgColor: "#fff",
    //   contextMenuTextColor: "inherit",
    // };
    const completeGanttList = [];
    const groupTasks: TasksGroup[] = taskgroupsSelectors.selectAll(state);
    const ganttTasks: GanttTask[] = [];
    const currentDate = new Date();
    for (let i = 0; i < tasksList.length; i++) {
      const ganttTask = tasksList[i];

      // Get ID value
      const ganttTaskId = tasksList[i]?._id;
      // Get Dependencies
      const dependenciesInTask = [];
      const ganttDependency = ganttsListInBoard[0]?.tasksDependencies;
      const dependencyKeys = ganttDependency
        ? Object.keys(ganttDependency)
        : {};
      if (Object.keys(dependencyKeys).length !== 0) {
        const addToDeps = ganttDependency[ganttTaskId];
        dependenciesInTask[0] = { ...addToDeps };
      }
      // GET Date value
      const cellValue = cellValueSelector(state, tasksList[i]?._id, columnId);
      let startDate = new Date(cellValue?.startDate) ?? new Date(currentDate);
      let endDate =
        new Date(cellValue?.endDate) ?? new Date(currentDate.getDate() + 1);

      // GET Progress value
      const progress = 0;
      // Get task name value
      const ganttTaskName = tasksList[i]?.text;
      // Get groupId value
      const groupId = groupTasks.find(
        (groupId) => groupId._id === ganttTask.groupId
      );
      // Get Color Value
      const groupColor = ganttTask?.groupColor;
      if (startDate >= endDate) {
        const helper = startDate;
        startDate = endDate;
        endDate = helper;
      }
      const x = milestoneTasks?.find((id) => ganttTaskId === id);

      const taskObj: GanttTask = {
        start: new Date(startDate),
        end: new Date(endDate),
        name: ganttTaskName,
        id: ganttTaskId,
        type: x ? "milestone" : "task",
        progress: progress,
        isDisabled: false,
        dependencies: dependenciesInTask,
        parent: groupId?._id,
        styles: {
          barBackgroundColor: groupColor,
          barBackgroundSelectedColor: groupColor,
        },
      };

      const thisTaskExist = ganttTasks.find((task) => taskObj.id === task.id);
      if (!thisTaskExist) {
        ganttTasks.push(taskObj);
      }
    }
    if (groupView) {
      completeGanttList.push(...ganttTasks, ...projectList);
    } else {
      completeGanttList.push(...ganttTasks);
    }
    let sortedGanttList = getSortedGanttTasksFromTasksOrder(
      state,
      completeGanttList,
      ganttsListInBoard[0]
    );

    if (fixDependency === true) {
      getFixedDependencyList(state, sortedGanttList);
    }
    if (orderTasksBy === "customOrder" || orderTasksBy === undefined) {
      sortedGanttList = getSortedGanttTasksFromTasksOrder(
        state,
        completeGanttList,
        ganttsListInBoard[0]
      );
    } else if (orderTasksBy === "startDate") {
      sortedGanttList = completeGanttList.sort(function (a, b) {
        return a.start - b.start;
      });
    } else if (orderTasksBy === "endDate") {
      sortedGanttList = completeGanttList.sort(function (a, b) {
        return b.end - a.end;
      });
    }

    return sortedGanttList;
  }
);
export const getTasksType = createSelector(
  [
    (state) => state,
    (_state, taskId: NullableId, gantt: Gantt) => ({ taskId, gantt }),
  ],
  (state: CommonRootState, { taskId, gantt }) => {
    const milestoneTasks = gantt?.milestoneTasks;
    console.log(milestoneTasks);

    if (milestoneTasks?.find((taskIdFromList) => taskId === taskIdFromList)) {
      return "milestone";
    } else {
      return "task";
    }
  }
);

export const getSortedGanttTasksFromTasksOrder = createSelector(
  [
    (state) => state,
    (_state, ganttTaskList: any[], gantt: Gantt) => ({ ganttTaskList, gantt }),
  ],
  (state: CommonRootState, { ganttTaskList, gantt }) => {
    const ganttTasksOrderList = gantt?.tasksOrder;
    let sortedList = [];
    if (ganttTasksOrderList?.length > 1) {
      for (const taskId in ganttTasksOrderList) {
        const taskOrderItemId = Object.keys(ganttTasksOrderList[taskId]);
        Object.values(ganttTasksOrderList[taskId]);
        const task = ganttTaskList.find(
          (task) => task.id === taskOrderItemId[0]
        );
        sortedList.push(task);
      }
      const listOfItemThatNotInTasksOrder = ganttTaskList.filter(
        (task) => !sortedList.includes(task)
      );
      sortedList.push(...listOfItemThatNotInTasksOrder);
    } else {
      sortedList = [...ganttTaskList];
    }
    return sortedList;
  }
);

export const currentGroups = createSelector(
  [(state) => state],
  (state) => {
    return taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId: getSelectedBoardId(state),
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      [
        "_id",
        "isCollapsed",
        "colorAccent",
        "order",
        "isFooterAutoFocused",
        "isSubFooterAutoFocused",
      ],
      {
        order: 1,
      }
    );
  }
);

export const selectElementsList = createSelector(
  (state: CommonRootState) => state,
  currentTasks,
  currentGroups,
  getSelectedBoardId,
  (state, currentTasks, currentGroups, selectedBoardId) => {
    let hideEmptyGroups = false;

    const currentBoardView = state.TasksReducer.currentBoardView;
    const [boardView]: BoardView[] = boardViewSelectByQuery(
      state.DBBoardViewReducer,
      { boardId: selectedBoardId, _id: currentBoardView.id }
    );

    let viewFilterList = viewFiltersSelectByQuery(
      state.DBViewFiltersReducer,
      {
        viewId: boardView?._id ?? selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      null,
      {
        updatedAt: 1,
      }
    );

    if (
      state.TasksReducer.tasksFilter.text ||
      state.TasksReducer.personFilter ||
      viewFilterList.length > 0
    ) {
      hideEmptyGroups = true;
    }

    const elementsList = mergeGroupsAndTasks(
      selectedBoardId,
      currentGroups,
      currentTasks,
      hideEmptyGroups,
      state.TasksReducer.isGroupDragging,
      state.TasksReducer.toggleTasksMap
    );

    return elementsList;
  }
);

export const columnTaskSelector = createSelector(
  [
    (state) => state,
    (state, columnId: NullableId, columnValue, isDefault) => ({
      columnId,
      columnValue,
      isDefault,
    }),
  ],
  (state: CommonRootState, { columnId, columnValue, isDefault }) => {
    const currentBoardTasks = currentTasks(state);
    const columnOptionsWithoutDefault = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId: getSelectedBoardId(state),
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      undefined,
      {
        order: 1,
      }
    )
      .filter((option) => option._id !== columnValue)
      .map((option) => option._id);

    let matchingList = [];
    let unMatchingList = [];
    for (let i = 0; i < currentBoardTasks.length; i++) {
      let isMatchingCustomValue = false;
      const task = currentBoardTasks[i];
      if (task.customData) {
        if (task.customData[columnId]) {
          if (task.customData[columnId].value === columnValue) {
            isMatchingCustomValue = true;
          }
        }
      }

      if (isMatchingCustomValue) {
        matchingList.push(task);
      } else if (isDefault) {
        if (task.customData) {
          if (task.customData[columnId] === undefined) {
            unMatchingList.push(task);
          } else if (
            columnOptionsWithoutDefault.indexOf(
              task.customData[columnId].value
            ) === -1
          ) {
            unMatchingList.push(task);
          }
        } else {
          unMatchingList.push(task);
        }
      }
    }

    const finalList = [...matchingList, ...unMatchingList].sort((a, b) => {
      const aOrder =
        a.customData === undefined ||
          a.customData[columnId] === undefined ||
          a.customData[columnId].order === undefined
          ? -1
          : a.customData[columnId].order;
      const bOrder =
        b.customData === undefined ||
          b.customData[columnId] === undefined ||
          b.customData[columnId].order === undefined
          ? -1
          : b.customData[columnId].order;
      return aOrder - bOrder;
    });

    return finalList;
  }
);

export const getKanbanColumnTasksByOrder = createSelector(
  [
    getSelectedBoardId,
    (state) => state,
    (_, kanbanId) => kanbanId,
    (state, __, columnId, columnValue, isDefault) =>
      columnTaskSelector(state, columnId, columnValue, isDefault),
  ],
  (boardId, state, kanbanId: NullableId, columnOptionTasks: Task[]) => {
    const kanban = kanbanSelectOneObjectByQuery(state.DBKanbanReducer, {
      boardId,
      _id: kanbanId,
    });
    let orderedTasksList: Task[] = [];
    const numberOfTasks = columnOptionTasks.length;
    if (kanban.tasksOrder) {
      orderedTasksList = columnOptionTasks.sort((a, b) => {
        let aOrder = numberOfTasks;
        if (kanban.tasksOrder[a._id] !== undefined) {
          aOrder = kanban.tasksOrder[a._id];
        }

        let bOrder = numberOfTasks;
        if (kanban.tasksOrder[b._id] !== undefined) {
          bOrder = kanban.tasksOrder[b._id];
        }

        return aOrder - bOrder;
      });
    } else {
      orderedTasksList = columnOptionTasks;
    }

    const returnList = orderedTasksList.map(
      (task, index) => ({ _id: task._id } as Task)
    );

    return returnList;
  }
);

export const cellValueByTaskAndColumnId = (task: Task, column: TaskColumn) => {
  const columnType: TaskColumnType = columnTypeList.find(
    (columnType) => columnType.type === column?.type
  );
  // Use a custom cell value function or the default custom data selector if it is not defined
  let value;
  if (columnType?.cellValueFunction) {
    value = columnType.cellValueFunction(task);
  } else {
    const customColumnData: CustomColumnData | undefined =
      task?.customData && task?.customData[column?._id];
    value = customColumnData?.value;
  }
  return value;
};

export const cellValueSelector = createSelector(
  [
    (state) => state,
    (state, taskId, columnId) => ({ taskId, columnId }),
  ],
  (state: CommonRootState, { taskId, columnId }) => {
    const taskState: GenericState<Task> = state.DBTasksReducer;
    const taskColumnState: GenericState<TaskColumn> = state.DBTaskColumnReducer;
    const [task] = tasksSelectByQuery(taskState, {
      _id: taskId,
    });
    const [column] = taskColumnSelectByQuery(taskColumnState, {
      _id: columnId,
    });
    // return columnType?.cellValueFunction ? columnType.cellValueFunction(task) : cellValueByTaskAndColumnId(task, column);
    return cellValueByTaskAndColumnId(task, column);
  }
);

export const getGroupListOnlyIdsAndNames = createSelector(
  [(state) => state, getSelectedBoardId],
  (state, boardId) => {
    const groupListOnlyIdsAndNames = taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );

    return groupListOnlyIdsAndNames;
  }
);

export const columnListSelector = createSelector(
  [
    (state, boardId) =>
      taskColumnSelectByQuery(
        state.DBTaskColumnReducer,
        {
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          boardId: boardId ?? "No Board", // if no board add a filter so no columns will be returned
        },
        [],
        {
          order: 1,
        }
      ),
    (state, __, groupId?) => getGroupTaskListLength(state, groupId),
  ],
  (dynamicColumnList, groupTaskListLength) => {
    let columnList;

    columnList = [...staticColumnList, ...dynamicColumnList].sort(
      (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
    );
    if (groupTaskListLength === 0) {
      columnList = columnList.splice(0, 2);
    }
    return columnList;
  }
);
export const getColumnTypeById = createSelector(
  [
    getSelectedBoardId,
    (state, columnId) => state,
    (state, columnId) => columnId,
  ],
  (state, columnId) => {
    const boardId = getSelectedBoardId(state);

    const columnList = getColumnList(state);
    const columnType = columnList.find((column) => columnId === column?._id);
    return columnType;
  }
);
export const columnListSelectorForKanban = createSelector(
  [
    getSelectedBoardId,
    (state, kanbanId) => state,
    (state, kanbanId) => kanbanId,
  ],
  (boardId, state, kanbanId) => {
    const includedColumns = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "includedColumns"
    );

    const dynamicColumnList = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        boardId,
      },
      ["_id", "type", "title"],
      {
        order: 1,
      }
    );

    let columnList;

    columnList = [...dynamicColumnList]
      .filter((column) => includedColumns && includedColumns[column?._id])
      .sort((colA, colB) => (colA?.order ?? 0) - (colB?.order ?? 0));

    return columnList.map(
      (column, index) =>
      ({
        _id: column?._id,
        type: column?.type,
        title: column?.title,
      } as TaskColumn)
    );
  }
);

export const getGroupIdForKanbanColumns = createSelector(
  [(state) => state, getSelectedBoardId],
  (state, boardId) => {
    const groupId = taskGroupsSelectOneFieldByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id"],
      {
        order: -1,
      }
    );

    return groupId;
  }
);

export const filterTaskColumns = (state, boardId) =>
  taskColumnSelectByQuery(
    state.DBTaskColumnReducer,
    {
      $and: [
        { $or: [{ deleted: { $exists: false } }, { deleted: false }] },
        { $or: [{ isVisible: true }, { isVisible: { $exists: false } }] },
      ],
      boardId: boardId ?? "No Board", // if no board add a filter so no columns will be returned
    },
    [],
    {
      order: 1,
    }
  );

export const columnListSelectorForBoardView = createSelector(
  [getSelectedBoardId, (state, _) => state, (_, boardViewId) => boardViewId],
  (boardId, state, boardViewId) => {
    const includedColumns = boardViewSelectOneFieldByQuery(
      state.DBBoardViewReducer,
      { _id: boardViewId },
      "includedColumns"
    );

    const dynamicColumnList = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        boardId,
      },
      ["_id", "type", "title"],
      {
        order: 1,
      }
    );

    let columnList;
    columnList = [...dynamicColumnList]
      .filter((column) => includedColumns && includedColumns[column?._id])
      .sort((colA, colB) => (colA?.order ?? 0) - (colB?.order ?? 0));
    return columnList.map(
      (column, index) =>
      ({
        _id: column?._id,
        type: column?.type,
        title: column?.title,
      } as TaskColumn)
    );
  }
);

export const getBoardView = createSelector(
  [getSelectedBoardId, (state) => state],
  (_, state) => {
    const boardId = getSelectedBoardId(state);

    const currentBoardView = state.TasksReducer.currentBoardView;
    const [boardViewId] = boardViewSelectByQuery(state.DBBoardViewReducer, {
      boardId: boardId,
      _id: currentBoardView.id,
    });
    return boardViewId;
  }
);
export const columnListForDisplaySelector = createSelector(
  [(state) => state, filterTaskColumns, (_, __, groupId?) => groupId],
  (state, dynamicColumnList, groupId) => {
    let columnList;
    let viewMode = state.TasksReducer.viewMode;
    if (viewMode === "tasks") {
      const boardId = getSelectedBoardId(state);

      const currentBoardView = state.TasksReducer.currentBoardView;
      const boardViewId = boardViewSelectByQuery(state.DBBoardViewReducer, {
        boardId: boardId,
        _id: currentBoardView.id,
      });

      if (boardViewId.length > 0) {
        const includedBoardViewColumnList = columnListSelectorForBoardView(
          state,
          currentBoardView.id
        );
        columnList = [...staticColumnList, ...includedBoardViewColumnList].sort(
          (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
        );
      } else {
        const groupTaskListLength = getGroupTasksListWithoutSubTasks(
          state,
          groupId
        );
        const isCollapsed = taskGroupsSelectOneFieldByQuery(
          state.DBTaskgroupsReducer,
          {
            _id: groupId,
          },
          ["isCollapsed"]
        );

        columnList = [...staticColumnList, ...dynamicColumnList].sort(
          (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
        );
        if (groupTaskListLength === 0 || isCollapsed) {
          columnList = columnList.splice(0, 2);
        }
      }
    } else {
      const myWorkPreferences = state.MyWorkReducer.myWorkPreferences;
      columnList = getMyWorkColumnsList(myWorkPreferences, dynamicColumnList);
    }

    return columnList;
  }
);

const getMyWorkColumnsList = (myWorkPreferences, dynamicColumnList) => {
  let columnList = [];
  const desiredTypesOrder = [
    "person-picker",
    "datepicker",
    "status-option-picker",
  ];
  const newTitles = {
    "person-picker": "Owner",
    "status-option-picker": "Status",
    datepicker: "Date",
  };

  let foundTypes = {};
  const desiredColumns = dynamicColumnList
    .filter((col) => {
      if (desiredTypesOrder.includes(col.type) && !foundTypes[col.type]) {
        foundTypes[col.type] = true;
        return true;
      }
      return false;
    })
    .map((col) => ({
      ...col,
      width: 180,
      title: newTitles[col.type],
    }))
    .sort(
      (a, b) =>
        desiredTypesOrder.indexOf(a.type) - desiredTypesOrder.indexOf(b.type)
    );
  // Insert 'empty-cell' columns for missing types
  desiredTypesOrder.forEach((type, index) => {
    if (!foundTypes[type]) {
      const insertPosition = desiredColumns.findIndex(
        (col) => desiredTypesOrder.indexOf(col.type) > index
      );

      const emptyColumn = {
        _id: "empty-cell",
        type: "empty-cell",
        title: newTitles[type],
        width: 180,
        static: true,
        outerCell: true,
        order: index,
      };

      if (insertPosition === -1) {
        desiredColumns.push(emptyColumn);
      } else {
        desiredColumns.splice(insertPosition, 0, emptyColumn);
      }
    }
  });

  if (myWorkPreferences?.selectedColumns) {
    desiredColumns.forEach((col, index) => {
      const selectedCol = myWorkPreferences?.selectedColumns[col.boardId];
      if (selectedCol) {
        if (selectedCol.status && col.type === "status-option-picker") {
          const replacement = dynamicColumnList.find(
            (dCol) => dCol._id === selectedCol.status
          );
          if (replacement) {
            desiredColumns[index] = {
              ...replacement,
              width: 250,
              title: newTitles[replacement.type],
            };
          }
        } else if (selectedCol.date && col.type === "datepicker") {
          const replacement = dynamicColumnList.find(
            (dCol) => dCol._id === selectedCol.date
          );
          if (replacement) {
            desiredColumns[index] = {
              ...replacement,
              width: 100,
              title: newTitles[replacement.type],
            };
          }
        }
      }
    });
  }

  columnList = [...myWorkStaticColumnList, ...desiredColumns];

  return columnList;
};

export const dynamicColumnList = createSelector(
  [(state, boardId) => columnListSelector(state, boardId)],
  (columnList: TaskColumn[]) => {
    return columnList.filter((column) => !column.static);
  }
);

export const getColumnsForDisplayForWorkloadUserCard = createSelector(
  [
    (state) => state,
    (state, boardId) => dynamicColumnList(state, boardId),
    (state, boardId, workloadId) => workloadId,
  ],
  (state, columnList: TaskColumn[], workloadId) => {
    const includedColumns = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "includedColumns"
    );

    const columnsForDisplay = columnList.filter(
      (column) => includedColumns && includedColumns[column._id]
    );

    return columnsForDisplay;
  }
);

export const getOrderedTaskListForWorkloadUserCard = createSelector(
  [
    getSelectedBoardId,
    (state) => state,
    (_, userId) => userId,
    (__, ___, workloadId) => workloadId,
  ],
  (boardId, state, userId, workloadId) => {
    const personPickerId = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "personPickerId"
    );

    const _tasksList = tasksSelectByQuery(
      state.DBTasksReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      [],
      {}
    );

    const tasksOrderByUser = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "tasksOrderByUser"
    );

    const isUserInTask = (task: Task, userId) => {
      if (task.customData !== undefined) {
        if (task.customData[personPickerId] !== undefined) {
          if (task.customData[personPickerId].value !== undefined) {
            if (task.customData[personPickerId].value.length !== 0) {
              let counter = 0;
              for (
                let i = 0;
                i < task.customData[personPickerId].value.length;
                i++
              ) {
                if (task.customData[personPickerId].value[i].id === userId) {
                  counter++;
                }
              }
              if (counter !== 0) {
                return true;
              }
            } else {
              return userId === "emptystateuserfordisplay";
            }
          } else {
            return userId === "emptystateuserfordisplay";
          }
        } else {
          return userId === "emptystateuserfordisplay";
        }
      } else {
        return false;
      }
    };

    const setTaskOrderForSort = (taskId, length) => {
      if (tasksOrderByUser !== undefined) {
        if (tasksOrderByUser[userId] !== undefined) {
          const userOrder = tasksOrderByUser[userId];
          if (userOrder[taskId] !== undefined) {
            return userOrder[taskId];
          }
        }
      } else {
        return length;
      }
    };

    const tasksList = _tasksList
      .filter((task) => isUserInTask(task, userId))
      .sort((a, b) => {
        const tasksLength = _tasksList.length;
        const aOrder = setTaskOrderForSort(a._id, tasksLength);
        const bOrder = setTaskOrderForSort(b._id, tasksLength);
        return aOrder - bOrder;
      });

    return tasksList.map(
      (task) => ({ _id: task._id, customData: task.customData } as Task)
    );
  }
);

export const getUserCapacityList = createSelector(
  [
    (state, userId, workloadId, complexityPickerId) => state,
    (state, userId, workloadId, complexityPickerId) => userId,
    (state, userId, workloadId, complexityPickerId) => workloadId,
    (state, userId, workloadId, complexityPickerId) => complexityPickerId,
    getSelectedBoardId,
  ],
  (state, userId, workloadId, complexityPickerId, boardId) => {
    const tasksList = getOrderedTaskListForWorkloadUserCard(
      state,
      userId,
      workloadId
    );

    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: complexityPickerId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const workloadPeople = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "people"
    );

    const isUserInWorkloadPeople = (userId) => {
      if (workloadPeople && workloadPeople[userId]) {
        return true;
      } else {
        return false;
      }
    };

    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;
    let profileCapacity;
    if (isUserInWorkloadPeople(userId)) {
      profileCapacity = workloadPeople[userId].capacity ?? defaultCapacity;
    } else {
      profileCapacity = defaultCapacity;
    }

    const userCapacityList = tasksList.map((task, index) => {
      const option = columnOptions.find(
        (option) =>
          task.customData !== undefined &&
          task.customData[complexityPickerId] !== undefined &&
          task.customData[complexityPickerId].value.toString() !== "" &&
          option._id === task.customData[complexityPickerId].value
      );
      const taskComplexityPercentage =
        (option?.complexity / profileCapacity) * 100;
      const taskComplexityColor =
        option?.bgcolor ?? "var(--default-background)";
      return [taskComplexityPercentage, taskComplexityColor];
    });

    return userCapacityList;
  }
);

export const getTaskComplexityList = createSelector(
  [
    (state, userId, workloadId, complexityPickerId) => state,
    (state, userId, workloadId, complexityPickerId) => userId,
    (state, userId, workloadId, complexityPickerId) => workloadId,
    (state, userId, workloadId, complexityPickerId) => complexityPickerId,
    getSelectedBoardId,
  ],
  (state, userId, workloadId, complexityPickerId, boardId) => {
    const tasksList = getOrderedTaskListForWorkloadUserCard(
      state,
      userId,
      workloadId
    );

    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: complexityPickerId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const taskComplexityList = tasksList.map((task, index) => {
      const option = columnOptions.find(
        (option) =>
          task.customData !== undefined &&
          task.customData[complexityPickerId] !== undefined &&
          task.customData[complexityPickerId].value.toString() !== "" &&
          option._id === task.customData[complexityPickerId].value
      );
      return option?.complexity ?? 0;
    });

    return taskComplexityList;
  }
);

export const getProfileCapacity = createSelector(
  [
    (state, workloadId, userId) => state,
    (state, workloadId, userId) => workloadId,
    (state, workloadId, userId) => userId,
  ],
  (state, workloadId, userId) => {
    const workloadPeople = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "people"
    );
    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;
    const isUserInWorkloadPeople = (userId) => {
      if (workloadPeople && workloadPeople[userId]) {
        return true;
      } else {
        return false;
      }
    };

    let profileCapacity;
    if (isUserInWorkloadPeople(userId)) {
      profileCapacity = workloadPeople[userId].capacity ?? defaultCapacity;
    } else {
      profileCapacity = defaultCapacity;
    }

    return profileCapacity;
  }
);

export const getDefaultCapacity = createSelector(
  [(state, workloadId) => state, (state, workloadId) => workloadId],
  (state, workloadId) => {
    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;

    return defaultCapacity;
  }
);

export const getDynamicColumnList = createSelector(
  [getSelectedBoardId, (state) => state],
  (boardId, state) => {
    const columnList = dynamicColumnList(state, boardId);
    return columnList.filter((column) => !column.static);
  }
);

export const getDynamicColumnListForBTCheckbox = createSelector(
  [(state, displayMode) => state, (_, displayMode) => displayMode],
  (state, displayMode) => {
    let columnList: TaskColumn[];
    if (displayMode === "board") {
      columnList = getDynamicColumnList(state).map(
        (column, index) =>
        ({
          _id: column._id,
          title: column.title,
          type: column.type,
          isVisible: column.isVisible,
        } as TaskColumn)
      );
    } else {
      columnList = getDynamicColumnList(state).map(
        (column, index) =>
        ({
          _id: column._id,
          title: column.title,
          type: column.type,
        } as TaskColumn)
      );
    }

    return columnList;
  }
);

export const timelinePickerColumnList = createSelector(
  [(state, boardId) => columnListSelector(state, boardId)],
  (columnList: TaskColumn[]) => {
    return columnList.filter((column) => column.type === "timeline-picker");
  }
);

export const optionColumnList = createSelector(
  [(state) => state],
  (state) => {
    const boardId = getSelectedBoardId(state);

    const columnList = columnListSelector(state, boardId).map(
      (column, index) =>
      ({
        type: column.type,
        _id: column._id,
        title: column.title,
      } as TaskColumn)
    );

    return columnList.filter(
      (column) =>
        column.type === "option-picker" ||
        column.type === "status-option-picker"
      // || column.type === 'complexity-picker'
    );
  }
);

export const selectColumnListByType = createSelector(
  [
    (state, boardId, type) => type,
    (state, boardId, type) => columnListSelector(state, boardId),
  ],
  (type: string, columnList: TaskColumn[]) => {
    return columnList.filter((column) => column.type === type);
  }
);

export const dynamicColumnListForForms = createSelector(
  [(state, boardId) => dynamicColumnList(state, boardId)],
  (columnList: TaskColumn[]) => {
    let returnList = columnList.filter((column) => {
      const cell = columnTypeList.find(
        (columnType) => columnType.type === column.type
      );
      return cell?.isCellInForms;
    });
    const nameTask: TaskColumn = {
      _id: "name",
      type: "row-prefix",
      title: "Task Name",
    };
    returnList = [nameTask, ...returnList];

    return returnList;
  }
);

export const dynamicColumnsListOfIdsTypesAndTitles = createSelector(
  [(state, boardId) => dynamicColumnList(state, boardId)],
  (columnList: TaskColumn[]) => {
    const returnList = columnList.map((column, index) => {
      return [column._id, column.type, column.title];
    });

    return returnList;
  }
);

export const dynamicColumnFilteredById = createSelector(
  [
    (state, { columnId }) => ({ columnId }),
    (state, { boardId }) => dynamicColumnList(state, boardId),
  ],
  ({ columnId }, columnList: TaskColumn[]) => {
    return columnList.find((column) => {
      return column._id === columnId;
    });
  }
);

//get sort object from selected board
export const getBoard = createSelector(
  (state: CommonRootState) => state,
  (state) => {
    const { selectedBoardId } = state.BoardsReducer;
    return boardsSelectors.selectById(state, selectedBoardId);
  }
);

export const getSelectedPersons = createSelector(
  (state: CommonRootState) => state,
  (state) => {
    return state.TasksReducer.personFilter;
  }
);

export const getAssignedUserListToTasks = createSelector(
  [(state) => state, baseTasks, getSelectedBoardId],
  (state: CommonRootState, tasks, boardId) => {
    let currentAssignedUsers = [];
    let filteredAssignedUserList = [];
    if (tasks.length > 0) {
      const columnList = columnListSelector(state, boardId);
      const personPickerColumns = columnList.filter((obj) => {
        return obj.type === "person-picker";
      });
      const personPickerColumnsIds = personPickerColumns.map(({ _id }) => _id);
      if (personPickerColumnsIds.length > 0) {
        for (let i = 0; i < tasks.length; i++) {
          personPickerColumnsIds.forEach(function (item) {
            const currentUserAssigned = tasks[i]?.customData?.[item]?.value;
            if (currentUserAssigned) {
              currentAssignedUsers.push(currentUserAssigned);
            }
          });
        }
      }
    }
    if (currentAssignedUsers.length > 0) {
      filteredAssignedUserList = currentAssignedUsers
        .flatMap((element) => element)
        .filter(
          (user, index, array) =>
            array.findIndex((t) => t?.id === user?.id) === index
        )
        .map((user) => user.id);
    }

    const selectedBoardUsers = boardUserSelectByQuery(
      state.DBBoardUserReducer,
      {
        _id: {
          $in: filteredAssignedUserList,
        },
      }
    );

    const _mockPersonList = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        _id: {
          $in: filteredAssignedUserList,
        },
        boardId,
        imageUrl: { $exists: true },
      }
    );
    const mockPersonList = _mockPersonList.map((option) => {
      const [firstName, lastName] = option.label.split(" ");
      const workspaceUser: AugmentedBlueticksBoardUser = {
        _id: option._id,
        status: "active",
        profile: {
          firstName,
          lastName,
          profileImg: option.imageUrl,
        } as User,
        type: "option",
      };
      return workspaceUser;
    });
    const assignedUsersAndMockUser = [...selectedBoardUsers, ...mockPersonList];
    return assignedUsersAndMockUser;
  }
);

export const areAllGroupsCollapsedSelector = createSelector(
  [(state) => state, currentGroups],
  (state, currentGroupList) => {
    return currentGroupList.every((group) => group.isCollapsed);
  }
);

export const getPreviousOrNextTaskIdByCurrentTaskId = createSelector(
  [
    (state, taskId, type) => state,
    (state, taskId, type) => taskId,
    (state, taskId, type) => type,
  ],
  (state, taskId, type) => {
    const elementList = selectElementsList(state).filter(
      (e) => e?.type === "task"
    );
    const numberOfElements = elementList.length;
    const currentTaskIndex: number = elementList.indexOf(
      elementList.find((e) => e?.id === taskId)
    );
    if (type === "previous") {
      if (currentTaskIndex === 0) {
        return elementList[numberOfElements - 1]?.id;
      } else {
        return elementList[currentTaskIndex - 1]?.id;
      }
    } else {
      if (currentTaskIndex === numberOfElements - 1) {
        return elementList[0]?.id;
      } else {
        return elementList[currentTaskIndex + 1]?.id;
      }
    }
  }
);




export const selectOrderedBoardTasksAndSubtasks = createSelector(
  [(state) => state, selectElementsList],
  (state: CommonRootState, elementsList) => {
    const taskList = elementsList
      .filter(
        (element) =>
          [BoardRowType.task, BoardRowType.subTask].indexOf(element.type) > -1
      )
      .map((element) => state.DBTasksReducer.entities[element.taskId]);

    return taskList;
  }
);

export const getCurrentGroupsLength = createSelector(
  currentGroups,
  (currentGroups): number => {
    return currentGroups.length;
  }
);

const getExtradataByTaskId = createSelector(
  [(state) => state, (_: CommonRootState, taskId: string) => taskId],
  (state, taskId) => {
    const extraData = tasksExtraDataSelectByQuery(
      state.DBTasksExtraDataReducer,
      {
        taskId: taskId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id"],
      { createdAt: -1 }
    );
    return extraData;
  }
);

export const getTaskExtradataLength = createSelector(
  getExtradataByTaskId,
  (extraData): number => {
    return extraData.length ?? 0;
  }
);

export const updateBoardViewTasksOrderByOrderChangesThunk = createAsyncThunk<
  void,
  Array<{
    id: string;
    changes: { order: number; groupId?: string; dirtySessionId?: string };
  }>,
  { state: CommonRootState }
>(
  "tasks/updateBoardViewTasksOrderByOrderChangesThunk",
  async (changes, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const currentBoardId = getSelectedBoardId(state);
    const currentBoardView = state.TasksReducer.currentBoardView;

    const [boardView] = boardViewSelectByQuery(state.DBBoardViewReducer, {
      boardId: currentBoardId,
      _id: currentBoardView.id,
    });

    let tasksOrder = boardView.tasksOrder;

    // Iterate over changes and apply them to tasksOrder
    changes.forEach((change) => {
      tasksOrder = {
        ...tasksOrder,
        [change.id]: change.changes.order,
      };
    });
    // console.log(tasksOrder)

    dispatch(
      DBBoardViewThunks.patch({
        entity: {
          _id: boardView?._id,
          tasksOrder: tasksOrder,
        },
      })
    );
  }
);

export const addNewBoardViewThunk = createAsyncThunk<
  BoardView,
  { userId: string; boardId: string },
  { state: CommonRootState }
>("boardViews/addNewBoardViewThunk", async (data, thunkAPI) => {
  const { userId, boardId } = data;
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const boardView: BoardView = {
    owner: userId,
    boardId: boardId,
    name: "Board View",
  };

  await dispatch(
    taskActions.setCurrentBoardView({
      type: "loader",
      id: "",
    })
  );

  const boardViewResponse = await dispatch(DBBoardViewThunks.create(boardView));

  const newBoardView = boardViewResponse.payload as BoardView;
  await dispatch(
    taskActions.setCurrentBoardView({
      type: "boardView",
      id: newBoardView._id,
    })
  );

  return newBoardView;
});

export const saveFilterAsBoardViewThunk = createAsyncThunk<
  void,
  { userId: string; boardId: string },
  { state: CommonRootState }
>("filter/saveFilterAsBoardViewThunk", async (data, thunkAPI) => {
  const { userId, boardId } = data;
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const currentBoard = getCurrentBoard(state);
  const includedColumnsFromOriginalBoard = getIncludedColumnsByBoardId(
    state,
    boardId
  );
  const newBoardView = await dispatch(
    addNewBoardViewThunk({ userId, boardId })
  );
  const boardView = newBoardView.payload as BoardView;
  const boardViewIncludedColumns = includedColumnsFromOriginalBoard.reduce(
    (result, column) => {
      if (column.isVisible === true) {
        result[column._id] = column.isVisible;
      }
      return result;
    },
    {}
  );
  const viewFilters: ViewFilter[] = viewFiltersSelectByQuery(
    state.DBViewFiltersReducer,
    {
      viewId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    null,
    {
      updatedAt: 1,
    }
  );
  viewFilters.forEach(async (viewFilter) => {
    let viewFilterClone = {
      ...viewFilter,
    };
    viewFilterClone.viewId = boardView?._id;
    delete viewFilterClone._id;
    await dispatch(DBViewFiltersThunks.create(viewFilterClone));
  });

  dispatch(
    DBBoardViewThunks.patch({
      entity: {
        _id: boardView._id,
        includedColumns: boardViewIncludedColumns,
      },
    })
  );
});

export const updateTasksOrderAfterTaskDragEnd = createAsyncThunk<
  void,
  {
    source: BTdragElement;
    destination: BTdragElement;
    entity: string;
    dragThresholdFromIndex: number;
  },
  { state: CommonRootState }
>("tasks/updateTasksOrderAfterTaskDragEnd", async (params, thunkAPI) => {
  console.log(`updateTasksOrderAfterTaskDragEnd triggered `);

  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const { source, destination, entity, dragThresholdFromIndex } = params;

  /* if we drag a element to its sub-element - nothing should happen */
  if (source._id && source._id === destination.parent) {
    return;
  }

  /* 
  if we drag a element to the same position - support threshold 
  (default is 0 => same position) - nothing should happen 
  */
  if (
    Math.abs(source.index - destination.index) <= (dragThresholdFromIndex ?? 0)
  ) {
    return;
  }

  const dragDirection =
    source.index - destination.index > 0 ? "bottomTop" : "topBottom";

  /* 
    ignore destination elements when dragging from bottom to top
  */
  if (dragDirection === "bottomTop") {
    if (
      destination?.IgnoreElementTypesOnBottomTop?.includes(destination.type)
    ) {
      console.log(`type: ${destination.type} is not dropable`);
      return;
    }
  }
  /* 
    ignore destination elements when dragging from top to bottom
  */
  if (dragDirection === "topBottom") {
    if (
      destination?.IgnoreElementTypesOnTopBottom?.includes(destination.type)
    ) {
      console.log(`type: ${destination.type} is not dropable`);
      return;
    }
  }

  let clonedElements = [];
  let destinationIndexInclonedElementsArray = 0;
  let sourceElementsWithChanges: any = {
    _id: source._id,
  };

  if (entity === "groups") {
    let boardGroups = [];

    boardGroups = taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        _id: { $ne: source._id },
        boardId: getCurrentBoardId(state),
        $or: [{ deleted: false }, { deleted: { $exists: false } }],
      },
      null,
      {
        order: 1,
      }
    );

    clonedElements = [...boardGroups];
  }

  if (entity === "tasks") {
    if (destination.type === BoardRowType.subTask) {
      destination.parent = tasksSelectOneFieldById(
        state.DBTasksReducer,
        destination._id,
        "parentTaskId"
      );
    }

    let filterTasksObject: any = {
      _id: { $ne: source._id },
      groupId: destination.grandparent,
      $or: [{ deleted: false }, { deleted: { $exists: false } }],
      parentTaskId: destination.parent,
    };

    const tasksOrSubTasksOfTheSameGroup = tasksSelectByQuery(
      state.DBTasksReducer,
      filterTasksObject,
      null,
      {
        order: 1,
      }
    );

    clonedElements = [...tasksOrSubTasksOfTheSameGroup];

    sourceElementsWithChanges.groupId = destination.grandparent;
    sourceElementsWithChanges.parentTaskId = destination.parent;
  }

  destinationIndexInclonedElementsArray = clonedElements.findIndex(
    (element) => element._id === destination._id
  );

  // if not found element becuase its drop on not the same entity element
  // NEED TO OPTIMIZE THIS IF
  if (entity === "groups") {
    if (
      destinationIndexInclonedElementsArray === -1 &&
      dragDirection === "bottomTop"
    ) {
      destinationIndexInclonedElementsArray = 0;
    } else if (
      destinationIndexInclonedElementsArray === -1 &&
      dragDirection === "topBottom"
    ) {
      destinationIndexInclonedElementsArray = clonedElements.length;
    } else if (destination.type === "spacer" && dragDirection === "bottomTop") {
      destinationIndexInclonedElementsArray =
        destinationIndexInclonedElementsArray + 1;
    } else if (dragDirection === "topBottom") {
      destinationIndexInclonedElementsArray =
        destinationIndexInclonedElementsArray + 1;
    }

    // else if (destination.type === "group" &&
    //   dragDirection === "bottomTop") {
    //   destinationIndexInclonedElementsArray =
    //     destinationIndexInclonedElementsArray;
    // }
  } else {
    if (
      destinationIndexInclonedElementsArray === -1 &&
      dragDirection === "bottomTop"
    ) {
      destinationIndexInclonedElementsArray = clonedElements.length;
    } else if (
      destinationIndexInclonedElementsArray === -1 &&
      dragDirection === "topBottom"
    ) {
      destinationIndexInclonedElementsArray = 0;
    } else if (dragDirection === "topBottom") {
      destinationIndexInclonedElementsArray =
        destinationIndexInclonedElementsArray + 1;
    }
  }

  clonedElements.splice(
    destinationIndexInclonedElementsArray,
    0,
    sourceElementsWithChanges
  );

  const changes = clonedElements.map((element, index) => {
    let changedElement = {
      id: element._id,
      changes: {
        order: index,
        dirtySessionId: randomstring.generate(16),
      },
    };

    if (element._id === sourceElementsWithChanges._id) {
      delete sourceElementsWithChanges._id;
      changedElement.changes = {
        order: index,
        ...sourceElementsWithChanges,
      };
    }
    return changedElement;
  });
  const boardView = getBoardView(state);

  if (entity === "tasks") {
    if (boardView) {
      dispatch(updateBoardViewTasksOrderByOrderChangesThunk(changes));
    } else {
      dispatch(DBTasksThunks.patchMany(changes));
    }
  }
  if (entity === "groups") {
    if (boardView) {
      // dispatch(updateBoardViewTasksOrderByOrderChangesThunk(changes))
    } else {
      dispatch(DBTaskgroupsThunks.patchMany(changes));
    }
  }
});
export const updateGantttTaskTypeThunk = createAsyncThunk<
  void,
  {
    ganttTaskId: any;
    columnId: NullableId;
  },
  { state: CommonRootState }
>("tasks/updateGantttTaskTypeThunk", async (params, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const boardId = state.BoardsReducer.selectedBoardId;

  const ganttsList = ganttSelectByQuery(state.DBGanttReducer, {
    boardId: boardId,
  });
  const gantt = ganttsList[0];
  const milestoneTasksFromDb = ganttSelectOneFieldByQuery(
    state.DBGanttReducer,
    { _id: gantt._id },
    "milestoneTasks"
  );
  const newMilestoneList: any[] = [...milestoneTasksFromDb];

  const ganttTaskList = getGanttTasks(state, params.columnId);
  for (let i = 0; i < ganttTaskList.length; i++) {
    const task = ganttTaskList[i];
    if (task?.id === params.ganttTaskId) {
      task.type === "task" ? (task.type = "milestone") : (task.type = "task");
      if (task?.type === "milestone") {
        if (!newMilestoneList.includes(task?.id)) {
          newMilestoneList.push(task?.id);
          break;
        }
      } else {
        const index = newMilestoneList.indexOf(task?.id);
        newMilestoneList.splice(index, 1);
        break;
      }
    }
  }
  dispatch(
    DBGanttThunks.patch({
      entity: {
        _id: gantt._id,
        milestoneTasks: newMilestoneList,
      },
    })
  );
  console.log(newMilestoneList);
});

export const updateGanttOrderTasksByDragableThunk = createAsyncThunk<
  void,
  { originalTaskList: any[]; newTaskList: any[]; gantt: Gantt },
  { state: CommonRootState }
>(
  "tasks/updateGanttOrderTasksByDragableThunk",
  async ({ originalTaskList, newTaskList, gantt }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const elementList = selectElementsList(state);

    let taskMoved, indexTo, indexFrom, taskInElementList;
    const dragThresholdFromIndex = 0;
    const difference = originalTaskList.filter(
      (x) => newTaskList.indexOf(x) === -1
    );
    difference.map((task) => {
      const taskFromNewList = newTaskList.find(
        (newTask) => task.id === newTask.id
      );
      if (task.type === "task" && task.id === taskFromNewList.id) {
        const sameGroupTask = newTaskList.find(
          (sameGroupTask) => taskFromNewList.parent === sameGroupTask.parent
        );
        taskFromNewList.styles = sameGroupTask.styles;
        taskMoved = taskFromNewList;
        elementList.find((el, index) => {
          if (el.taskId === sameGroupTask.id) {
            indexTo = index;
          }
        });
      }
    });
    // for (let i = 0; i < originalTaskList.length; i++) {
    //   const oldTask = originalTaskList[i];
    //   for (let j = 0; j < newTaskList.length; j++) {
    //     const newTask = newTaskList[j];
    //     if (newTask.id === oldTask.id && newTask.parent !== oldTask.parent ) {
    //       const sameGroupTask = newTaskList.find(sameGroupTask => newTask.parent === sameGroupTask.parent)
    //       newTask.styles = sameGroupTask.styles;

    //       taskMoved = newTask;
    //       elementList.find((el, index) => {
    //         if (el.taskId === sameGroupTask.id) {
    //           indexTo = index;

    //         }
    //       })
    //       break;
    //     }

    //   }
    // }
    elementList.map((element, index) => {
      if (element.type === "task") {
        if (element.taskId === taskMoved.id) {
          taskInElementList = element;
          indexFrom = index;
        }
      }
    });
    const source: BTdragElement = {
      _id: taskMoved?.id,
      index: indexFrom,
    };
    const destination: BTdragElement = {
      _id: taskInElementList?.id,
      index: indexTo,
      parent: "",
      grandparent: taskMoved?.parent,
      type: taskMoved?.type,
      IgnoreElementTypesOnBottomTop: [
        BoardRowType.spacer,
        BoardRowType.groupHeader,
      ],
      IgnoreElementTypesOnTopBottom: [
        BoardRowType.spacer,
        BoardRowType.newTask,
      ],
    };
    const entity = "tasks";

    dispatch(
      updateTasksOrderAfterTaskDragEnd({
        source,
        destination,
        entity,
        dragThresholdFromIndex,
      })
    );
  }
);

export const moveTaskGroupByDays = createAsyncThunk<
  void,
  {
    groupId: NullableId;
    days: number;
    columnId: NullableId;
  },
  { state: CommonRootState }
>("tasks/moveTaskGroupByDays", async (params, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  let startProject,
    endProject,
    projectHasBeenChanged = undefined;
  const days = params.days * 24 * 60 * 60 * 1000;

  const ganttTaskList = getGanttTasks(state, params.columnId);

  ganttTaskList.map((task) => {
    if (task.type === "project" && task.id === params.groupId) {
      projectHasBeenChanged = task;
    }
    if (task.start < startProject || endProject === undefined) {
      console.log(task.start);
      startProject = task.start.getTime() + days;
    }
    if (task.end < endProject || endProject === undefined) {
      endProject = task.end.getTime() + days;
    }
    const newStartDate = new Date(task.start.getTime() + days);
    const newEndDate = new Date(task.end.getTime() + days);

    if (task.type === "task" && task.parent === params.groupId) {
      const value = {
        startDate: new Date(newStartDate),
        endDate: new Date(newEndDate),
      };

      dispatch(
        updateCellValueThunk({
          taskId: task.id,
          columnId: params.columnId,
          data: {
            value: value,
          },
        })
      );
    }
  });

  // console.log('new Date(startProject')
  // console.log(new Date(startProject))
  // console.log(new Date(endProject))
  // const valueForProject = {
  //   startDate:new Date(startProject),
  //   endDate:new Date(endProject)
  // }
  // console.log('valueForProject')
  // console.log(valueForProject)
  // console.log(projectHasBeenChanged)
  // projectHasBeenChanged.start
  // dispatch(updateCellValueThunk({
  //   taskId:projectHasBeenChanged.id,
  //   columnId:params.columnId,
  //   data: {
  //     value: valueForProject
  //   }
  // }));
});

export const updateColumnOrderAfterTaskDragEnd = createAsyncThunk<
  void,
  {
    sourceColumnId: NullableId;
    destinationBoardId: NullableId;
    destinationOrder: number;
    dragDirection: number;
  },
  { state: CommonRootState }
>("tasks/updateColumnOrderAfterTaskDragEnd", async (params, thunkAPI) => {
  console.log(`updateColumnOrderAfterTaskDragEnd triggered `);

  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const dirtySessionId = randomstring.generate(16);
  const { sourceColumnId, destinationBoardId, dragDirection } = params;
  let { destinationOrder } = params;

  const draggedColumn = taskColumnSelectors.selectById(state, sourceColumnId);

  console.log(dragDirection);
  console.log(params);
  destinationOrder = destinationOrder ?? 0;

  const taskColumnsForUpdatingOrder = taskColumnSelectByQuery(
    state.DBTaskColumnReducer,
    {
      boardId: destinationBoardId,
      order:
        dragDirection < 0
          ? { $gte: destinationOrder }
          : { $gt: destinationOrder },
    }
  );

  if (dragDirection > 0) {
    taskColumnsForUpdatingOrder.push(draggedColumn);
  }

  // update order (increment by one) of all tasks in destination group (destinationGroupId) from order value (destinationOrder)
  const changes = taskColumnsForUpdatingOrder.map((column) => {
    let order;
    if (column._id === draggedColumn._id) {
      order =
        dragDirection < 0 ? destinationOrder ?? 0 : (destinationOrder ?? 0) + 1;
    } else {
      order = column.order + 1;
    }
    return {
      id: column._id,
      changes: {
        order,
        boardId: destinationBoardId,
        dirtySessionId,
        dirty: true,
        version: column?.version + 1,
      },
    };
  });

  dispatch(DBTaskColumnThunks.patchMany(changes));
});

export const addNewTaskThunk = createAsyncThunk<
  void,
  Task,
  { state: CommonRootState }
>("tasks/addNewTaskThunk", async (task, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const activeSubscription = activeSubscriptionSelector(state);
  let boardId: string;
  let newTask;

  const board = getCurrentBoard(state)

  newTask = task;
  boardId = task.isQuickTask ? task.boardId : board._id;

  const taskListLength = tasksSelectByQueryOnlyLength(state.DBTasksReducer, {
    boardId: boardId,
    $or: [{ deleted: false }, { deleted: { $exists: false } }],
  });

  //const userScore = 0.5;
  //const isWorkSpaceOwner = isUserWorkSpaceOwner(state);

  const isTaskLimitReached = taskListLength >= (state.TasksReducer?.limits?.tasks ?? 10);

  const isTasksSubscriptionInactive = !activeSubscription || !activeSubscription.hasTasks();

  if (isTaskLimitReached && isTasksSubscriptionInactive && !(board?.type === "audience")) {
    //old code from tudoboard
    // if (userScore > 1) {
    //   if (isWorkSpaceOwner) {
    //     dispatch(modalActions.setComponentToRender('TRIAL_EXTEND'));
    //   } else {
    //     dispatch(modalActions.setComponentToRender('CONTACT_WORKSPACE_ADMIN'));
    //   }
    // } else {
    //   dispatch(subscriptionPopupActions.openPopup("tasks"));
    // }
    dispatch(subscriptionPopupActions.openPopup("tasks"));
    return;
  }

  const taskMaxOrder = tasksSelectByQueryOnlyMaxMin(state.DBTasksReducer,
    {
      groupId: newTask.groupId,
      deleted: false,
    },
    "order", "max") ?? 0;

  newTask.order = taskMaxOrder + 1;

  const { payload }: any = await dispatch(DBTasksThunks.create(newTask));

  if (payload?._id) return payload;
});

export const updateGroupOrderAfterDragEnd = createAsyncThunk<
  void,
  { destinationIndex: number; draggedItem: BoardRowElement },
  { state: CommonRootState }
>(
  "tasks/updateGroupOrderAfterDragEnd",
  async ({ destinationIndex, draggedItem }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();

    const elementList = selectElementsList(state);
    //const currentIndex = elementList.findIndex(element => element.id === draggedItem.id)

    elementList.splice(destinationIndex, 0, draggedItem);

    dispatch(
      DBTaskgroupsThunks.patchMany(
        elementList
          .filter((element) => element.type === BoardRowType.groupHeader)
          .map((element, index) => ({
            id: element.id,
            changes: {
              order: index,
            },
          }))
      )
    );
  }
);

export const sortTasks = createAsyncThunk<
  void,
  { sortOrder: SortOrder; sortColumnId: string },
  { state: CommonRootState }
>("tasks/sortTasks", async ({ sortOrder, sortColumnId }, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const filteredTasks = baseTasks(state);
  let columnOptions;
  let [column] = taskColumnSelectByQuery(state.DBTaskColumnReducer, {
    _id: sortColumnId,
  });
  if (sortColumnId === "row-prefix") {
    column = { type: "row-prefix" };
  } else {
    columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId: column.boardId,
        columnId: sortColumnId,
      }
    );
  }
  const sortedTasks = sortTasksOrderByColumn(filteredTasks, column, {
    sortOrder,
    columnOptions,
    state,
  });
  dispatch(
    tasksReducerActions.updateMany(
      sortedTasks.map((task, index) => ({
        id: task._id,
        changes: {
          order: index,
        },
      }))
    )
  );
  if (!state.TasksReducer.isSortActive) {
    dispatch(taskActions.setOriginalTaskOrder(filteredTasks));
  }
  dispatch(taskActions.setSortActive(true));
});

export const undoTasksSort = createAsyncThunk<void, void, { state: CommonRootState }>(
  "tasks/undoTasksSort",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const tasksList = state.TasksReducer.originalTasksOrder;
    dispatch(
      tasksReducerActions.updateMany(
        tasksList.map((task, index) => ({
          id: task._id,
          changes: {
            order: task.order,
          },
        }))
      )
    );
  }
);

export const saveTaskOrder = createAsyncThunk<void, void, { state: CommonRootState }>(
  "tasks/saveTaskOrder",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();

    const taskList = baseTasks(state);

    dispatch(
      DBTasksThunks.patchMany(
        taskList.map((task, index) => ({
          id: task._id,
          changes: {
            order: task.order,
          },
        }))
      )
    );
  }
);

export const resetMenusState = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("tasks/saveTaskOrder", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
});

export const addNewColumnThunk = createAsyncThunk<
  void,
  TaskColumn,
  { state: CommonRootState }
>("tasks/addNewColumnThunk", async (column, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const columnMaxOrder =
    taskColumnsSelectByQueryOnlyMaxMin(
      state.DBTaskColumnReducer,
      {
        boardId: column.boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      "order",
      "max"
    ) ?? 0;

  column.order = columnMaxOrder + 1;

  const allBoardColumns = taskColumnSelectByQuery(
    state.DBTaskColumnReducer,
    {
      boardId: column.boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    null,
    {
      title: 1,
    }
  );

  const newTitle = column.title || "";

  const filteredSameTitles = allBoardColumns
    .filter((col) => col.title?.substring(0, newTitle.length) === newTitle)
    .map((col) => col.title);

  let nextTitle = "";
  if (filteredSameTitles.length) {
    for (let i = 1; i < 100 && !nextTitle; i++) {
      if (!filteredSameTitles.includes(`${newTitle} ${i}`)) {
        nextTitle = `${newTitle} ${i}`;
      }
    }
  }

  column.title = nextTitle || newTitle;
  if (column.type === "number") {
    column.customData = { func: "sum" };
  }
  const res = await dispatch(DBTaskColumnThunks.create(column));
  const createdColumn = res.payload as TaskColumn;

  const columnType: TaskColumnType = columnTypeList.find(
    (columnType) => columnType.type === column.type
  );

  const defaultOptionList = columnType?.defaultOptions ?? [];
  for (const option of defaultOptionList) {
    dispatch(
      DBTaskColumnOptionThunks.create({
        ...option,
        owner: createdColumn.owner,
        boardId: createdColumn.boardId,
        columnId: createdColumn._id,
      })
    );
  }
});


export const updateBoardPersonListThunk = createAsyncThunk<
  void,
  UpdateBoardPersonOptions,
  { state: CommonRootState }
>("tasks/updateBoardPersonListThunk", async (options, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const boardPersonOptionList = taskColumnOptionSelectByQuery(state, {
    boardId: options.boardId,
    columnType: "person-picker",
  });
  const userId = state.UserReducer.userId;

  for (const [index, person] of options.personList.entries()) {
    const personUpdate = boardPersonOptionList.find(
      (_person) => _person.customData?.waId === person.waId
    );

    const columnOption = {
      ...personUpdate,
      columnType: "person-picker",
      owner: userId,
      boardId: options.boardId,
      label: person.displayName ?? person?.label,
      customData: {
        waId: person.waId,
      },
      imageUrl: person.profilePic,
      order: index,
      isDefaultLabel: false,
    };

    if (personUpdate) {
      dispatch(
        DBTaskColumnOptionThunks.patch({
          entity: columnOption,
        })
      );
    } else {
      dispatch(DBTaskColumnOptionThunks.create(columnOption));
    }
  }
});


export const createNewGroupAtBottom = createAsyncThunk<
  void,
  TasksGroup,
  { state: CommonRootState }
>("tasks/createNewGroupAtBottom", async (taskGroup, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const groupsMaxOrder = taskGroupsSelectByQueryOnlyMaxMin(
    state.DBTaskgroupsReducer,
    {
      boardId: taskGroup.boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    "order",
    "max"
  );
  taskGroup.order = groupsMaxOrder + 1;
  dispatch(DBTaskgroupsThunks.create(taskGroup));
});

export const createOrUpdateTaskExtraDataFile = createAsyncThunk<
  void,
  FileItem[],
  { state: CommonRootState }
>("extraDatFfiles/createOrUpdateTaskExtraDatFile", async (files, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const boardId = state.BoardsReducer.selectedBoardId;
  const displayExtraDataOfTaskById =
    state.ExtraDataReducer.displayExtraDataOfTaskById;

  const currentTaskExtraDataFiles = _tasksExtraDataFilesSelectOneObjectByQuery(
    state.DBTasksExtraDataFilesReducer,
    {
      taskId: displayExtraDataOfTaskById,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }
  );


  if (currentTaskExtraDataFiles) {
    const updatedFiles = [...currentTaskExtraDataFiles.files, ...files];
    const patchData = {
      entity: {
        _id: currentTaskExtraDataFiles._id,
        files: updatedFiles,
      },
    };
    await dispatch(DBTasksExtraDataFilesThunks.patch(patchData));
  } else {
    await dispatch(
      DBTasksExtraDataFilesThunks.create({
        files: files,
        taskId: displayExtraDataOfTaskById,
        boardId: boardId,
      })
    );
  }
  const res = getExtraDataMergedFiles(state)
  return res;
});

export const addNewTaskGroupThunk = createAsyncThunk<
  void,
  TasksGroup,
  { state: CommonRootState }
>("tasks/addNewTaskGroupThunk", async (taskGroup, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const groupsMaxOrder = taskGroupsSelectByQueryOnlyMaxMin(
    state.DBTaskgroupsReducer,
    {
      boardId: taskGroup.boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    "order",
    "max"
  );
  taskGroup.order = groupsMaxOrder + 1;
  dispatch(DBTaskgroupsThunks.create(taskGroup));
});

export const addNewGroupBelowTaskGroup = createAsyncThunk<
  void,
  TasksGroup,
  { state: CommonRootState }
>("tasks/addNewGroupBelowTaskGroup", async (taskGroup, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  dispatch(DBTaskgroupsThunks.create(taskGroup));
});

export const checkIfMenuActiveTaskIdInBulkAction = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("tasks/checkIfMenuActiveTaskIdInBulkAction", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const bulkTasksList = getBulkTaskList(state);
  const activeTaskid = state.TasksReducer.menuActiveTaskId;
  const taskIsChecked = bulkTasksList.find((task) => {
    return task._id === activeTaskid;
  });
  if (!taskIsChecked) {
    dispatch(bulkActions.setIsBulkActionsDialogOpen(false));
    dispatch(bulkActions.setBulkTasksMap({}));
  }
});

export const moveTaskToTopOfTheGroupAndReOrderRestOfTasks = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("tasks/checkIfMenuActiveTaskIdInBulkAction", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const activeTaskid = state.TasksReducer.menuActiveTaskId;
  const task = tasksSelectOneObjectById(state.DBTasksReducer, activeTaskid);

  const groupTasks = tasksSelectByQuery(state.DBTasksReducer, {
    groupId: task.groupId,
    parentTaskId: { $exists: false },
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  });

  const changes = groupTasks.map((task) => ({
    id: task._id,
    changes: {
      order: activeTaskid === task._id ? 0 : task.order + 1,
    },
  }));

  dispatch(DBTasksThunks.patchMany(changes));
});

export const deleteOptionFromTaskThunk = createAsyncThunk<
  void,
  { taskId: NullableId; columnId: NullableId },
  { state: CommonRootState }
>(
  "tasks/deleteOptionFromTaskThunk",
  async ({ taskId, columnId }, { dispatch, getState }) => {
    const state = getState();
    const [task] = tasksSelectByQuery(state.DBTasksReducer, { _id: taskId });
    if (!task) return;

    const bulkTasksList = getBulkTaskList(state);
    const taskIsChecked = bulkTasksList.some((obj) => obj._id === taskId);

    if (taskIsChecked) {
      const changes = bulkTasksList.map((task) => {
        // Copy task.customData excluding the value at columnId
        const { [columnId]: _, ...customDataWithoutColumn } = task.customData;
        return {
          id: task._id,
          changes: {
            customData: customDataWithoutColumn,
          },
        };
      });

      const res: any = await dispatch(DBTasksThunks.patchMany(changes));

      if (res?.payload) {
        const bulkTasksAfterUpdate = Object.assign(
          {},
          ...res.payload.map((key) => ({ [key._id]: key }))
        );
        dispatch(bulkActions.setBulkTasksMap(bulkTasksAfterUpdate));
      }
    } else {
      const { [columnId]: _, ...restOfCustomData } = task.customData;
      const patchData = {
        entity: {
          ...task,
          customData: restOfCustomData,
        },
      };
      dispatch(DBTasksThunks.patch(patchData));
    }
  }
);

export const convertSubTaskToTaskAndReOrderGroup = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("tasks/convertSubTaskToTaskAndReOrderGroup", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const activeTaskid = state.TasksReducer.menuActiveTaskId;
  const task = tasksSelectOneObjectById(state.DBTasksReducer, activeTaskid);

  if (!task) {
    throw new Error("Task not found");
  }

  const parentTask = tasksSelectByQuery(state.DBTasksReducer, {
    _id: task?.parentTaskId,
  });

  if (!parentTask || parentTask.length === 0) {
    throw new Error("Parent task not found");
  }

  let tasksToPatch = getBulkTaskList(state);
  if (tasksToPatch.length === 0) {
    tasksToPatch = [{ _id: activeTaskid }];
  }

  const subTasksChanges = tasksToPatch.map((task) => ({
    id: task._id,
    changes: {
      parentTaskId: "",
      taskType: "task",
      order: parentTask[0].order + 1,
    },
  }));

  const groupTasks = tasksSelectByQuery(state.DBTasksReducer, {
    groupId: task.groupId,
    taskType: { $ne: "subTask" },
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  });

  const changes = groupTasks.map((task) => ({
    id: task._id,
    changes: {
      order: task.order > parentTask[0].order ? task.order + 1 : task.order,
    },
  }));

  changes.push(...subTasksChanges);

  dispatch(DBTasksThunks.patchMany(changes));
});

export const closeAndOpenTasksIfOpen = createAsyncThunk<
  void,
  void
>("tasks/closeAndOpenTasksIfOpen", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState() as CommonRootState;

  if (state.TasksReducer.isTasksPanelOpen) {
    dispatch(taskActions.setIsTasksPanelOpen(false));
    setTimeout(() => {
      dispatch(taskActions.setIsTasksPanelOpen(true));
    }, 1);
  }
});

export const createNewGroupAtTopThunk = createAsyncThunk<
  void,
  TasksGroup,
  { state: CommonRootState }
>("tasks/createNewGroupAtTopThunk", async (taskGroup, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const boardId = getSelectedBoardId(state);
  const groups = taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
    boardId,
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  }) as TasksGroup[];

  const changes = groups.map((group) => ({
    id: group._id,
    changes: {
      order: group.order + 1,
    },
  }));
  dispatch(DBTaskgroupsThunks.patchMany(changes));
  await dispatch(DBTaskgroupsThunks.create(taskGroup));
});

export const moveGroupToTopOfTheGroupAndReOrderRestOfGroup = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("tasks/checkIfMenuActiveTaskIdInBulkAction", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const menuActiveGroupId = state.MainReducer.menuActiveGroupId;
  const group = taskGroupsSelectOneObjectByQuery(state.DBTaskgroupsReducer, {
    _id: menuActiveGroupId,
  });
  const boardId = getSelectedBoardId(state);
  const groups = taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
    boardId,
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  });

  const changes = groups.map((group) => ({
    id: group._id,
    changes: {
      order: menuActiveGroupId === group._id ? 0 : group.order + 1,
    },
  }));

  dispatch(DBTaskgroupsThunks.patchMany(changes));
});

export const updateAllGroupsCollapsedState = createAsyncThunk<
  void,
  boolean,
  { state: CommonRootState }
>("tasks/collapseAll", async (isCollapsed, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const currentGroupsList = currentGroups(state);

  dispatch(
    DBTaskgroupsThunks.patchMany(
      currentGroupsList.map((group) => ({
        id: group._id,
        changes: {
          isCollapsed,
        },
      }))
    )
  );
});


export const updateCellValueThunk = createAsyncThunk<
  void,
  UpdateCellValueParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (updateCellValueParams: UpdateCellValueParams, thunkAPI) => {
    const { taskId, columnId, data } = updateCellValueParams;

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();

    const [task] = tasksSelectByQuery(state.DBTasksReducer, {
      _id: taskId,
    });
    const bulkTasksList = getBulkTaskList(state);
    const taskIsChecked = bulkTasksList.find((obj) => {
      return obj._id === taskId;
    });

    const columnData: CustomColumnData =
      (task?.customData && task?.customData[columnId]) ?? {};

    if (taskIsChecked) {
      const changes = bulkTasksList.map((task) => ({
        id: task._id,
        changes: {
          customData: {
            ...task.customData,
            [columnId]: {
              value: data.value,
            },
          },
        },
      }));

      const res: any = await dispatch(DBTasksThunks.patchMany(changes));

      if (res?.payload) {
        const bulkTasksAfterUpdate = Object.assign(
          {},
          ...res.payload.map((key) => ({ [key._id]: key }))
        );
        dispatch(bulkActions.setBulkTasksMap(bulkTasksAfterUpdate));
      }
    } else {
      const patchData = {
        entity: {
          ...task,
          customData: {
            ...task.customData,
            [columnId]: {
              ...columnData,
              ...data,
            },
          },
        },
      };

      if (task) {
        dispatch(DBTasksThunks.patch(patchData));
      }
    }
  }
);


export const updateKanbanCardTaskMapThunk = createAsyncThunk<
  void,
  UpdateKanbanCardTaskMapThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (
    UpdateKanbanCardTaskMapThunkParams: UpdateKanbanCardTaskMapThunkParams,
    thunkAPI
  ) => {
    const { taskId } = UpdateKanbanCardTaskMapThunkParams;

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();

    const [task] = tasksSelectByQuery(state.DBTasksReducer, {
      _id: taskId,
    });

    dispatch(bulkActions.setBulkTasksMap({ [taskId]: task }));
  }
);


export const createMockPersonThunk = createAsyncThunk<
  void,
  CreateMockPersonThunkParams,
  { state: CommonRootState }
>(
  "tasks/updateCellValueThunk",
  async (
    CreateMockPersonThunkParams: CreateMockPersonThunkParams,
    thunkAPI
  ) => {
    const { columnId, newPersonName, imgUrl, imgColor } =
      CreateMockPersonThunkParams;

    const randomColor =
      groupcolors[Math.floor(Math.random() * groupcolors.length)];
    let _imgColor = imgColor;
    if (imgColor === "") {
      _imgColor = randomColor;
    }

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state) as NullableId;

    dispatch(
      DBTaskColumnOptionThunks.create({
        boardId,
        columnId,
        label: newPersonName,
        imageUrl: imgUrl !== "" ? imgUrl : _imgColor,
      })
    );

    // const newUser: AugmentedBlueticksBoardUser = {
    //   boardId,
    //   firstName,
    //   lastName,
    //   profile: {
    //     fullName: newPersonName,
    //     profileImg: imgUrl !== '' ? imgUrl : imgColor,
    //     firstName,
    //     lastName,
    //   } as User,
    //   type: 'option',
    // }
    // dispatch(DBBoardUserThunks.create({
    //   ...newUser
    // }))
  }
);








export const updateTaskOrderValueThunk = createAsyncThunk<
  void,
  UpdateTaskOrderValueParams,
  { state: CommonRootState }
>(
  "tasks/updateTaskOrderValueThunk",
  async (UpdateTaskOrderValueParams: UpdateTaskOrderValueParams, thunkAPI) => {
    const { taskId, optionId, isDefault, destinationIndex, kanbanId } =
      UpdateTaskOrderValueParams;
    const state = thunkAPI.getState();
    const optionColumnId = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "optionColumnId"
    );
    const boardId = getSelectedBoardId(state);
    const kanban = kanbanSelectOneObjectByQuery(state.DBKanbanReducer, {
      boardId,
      _id: kanbanId,
    });
    const _tasksList: Task[] = getKanbanColumnTasksByOrder(
      state,
      kanbanId,
      optionColumnId,
      optionId,
      isDefault
    );

    // if task is in the option's list
    const [task]: Task[] = tasksSelectByQuery(state.DBTasksReducer, {
      boardId,
      _id: taskId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });

    const tasksList = [..._tasksList.filter((task) => task._id !== taskId)];
    tasksList.splice(destinationIndex, 0, task);

    let tasksOrder = {};

    const newTasksOrderList = tasksList.map((task, taskListIndex) => ({
      NullableId: task._id,
      number: taskListIndex,
    }));

    for (let i in newTasksOrderList) {
      tasksOrder = {
        ...tasksOrder,
        [newTasksOrderList[i].NullableId]: newTasksOrderList[i].number,
      };
    }

    if (kanban.tasksOrder) {
      for (const key in kanban.tasksOrder) {
        if (tasksOrder[key] === undefined) {
          tasksOrder = {
            ...tasksOrder,
            [key]: kanban.tasksOrder[key],
          };
        }
      }
    }

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    dispatch(
      DBKanbanThunks.patch({
        entity: {
          _id: kanban._id,
          tasksOrder: tasksOrder,
        },
      })
    );
    dispatch(
      DBTasksThunks.patch({
        entity: {
          _id: taskId,
          customData: {
            ...task?.customData,
            [optionColumnId]: {
              value: optionId,
            },
          },
        },
      })
    );
  }
);


export const openPickerThunk = createAsyncThunk<
  void,
  OpenPickerThunkParams,
  { state: CommonRootState }
>(
  "tasks/openPickerThunk",
  async (OpenPickerThunkParams: OpenPickerThunkParams, thunkAPI) => {
    const { anchorId, pickerType } = OpenPickerThunkParams;

    const state = thunkAPI.getState();

    const boardId = getSelectedBoardId(state);

    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    dispatch(
      pickerDialogActions.togglePicker({
        pickerAnchorId: anchorId,
        pickerProps: {
          boardId,
          type: pickerType,
        } as PickerProps,
      })
    );
  }
);


export const updateKanbanOptionColumn = createAsyncThunk<
  void,
  UpdateKanbanOptionColumnParams,
  { state: CommonRootState }
>(
  "tasks/updateKanbanOptionColumn",
  async (
    UpdateKanbanOptionColumnParams: UpdateKanbanOptionColumnParams,
    thunkAPI
  ) => {
    const { kanbanId, columnId } = UpdateKanbanOptionColumnParams;

    const state = thunkAPI.getState();
    const [kanban] = kanbanSelectByQuery(state.DBKanbanReducer, {
      _id: kanbanId,
    });
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    dispatch(
      DBKanbanThunks.patch({
        entity: {
          ...kanban,
          optionColumnId: columnId,
        },
      })
    );
  }
);


export const updateFormIncludeNameQuestion = createAsyncThunk<
  void,
  UpdateFormIncludeNameQuestionParams,
  { state: CommonRootState }
>(
  "tasks/updateFormIncludeNameQuestion",
  async (
    UpdateFormIncludeNameQuestionParams: UpdateFormIncludeNameQuestionParams,
    thunkAPI
  ) => {
    const { formId, value } = UpdateFormIncludeNameQuestionParams;

    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state);
    const form = formSelectOneObjectByQuery(state.DBFormReducer, {
      _id: formId,
      boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    dispatch(
      DBFormThunks.patch({
        entity: {
          ...form,
          columnData: {
            ...form?.columnData,
            name: {
              ...form?.columnData["name"],
              visible: value,
            },
          },
        },
      })
    );
  }
);


export const updateFormAfterSubmitEvent = createAsyncThunk<
  void,
  UpdateFormAfterSubmitEventParams,
  { state: CommonRootState }
>(
  "tasks/updateFormAfterSubmitEvent",
  async (
    UpdateFormAfterSubmitEventParams: UpdateFormAfterSubmitEventParams,
    thunkAPI
  ) => {
    const { formId, value, field } = UpdateFormAfterSubmitEventParams;

    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state);
    const form = formSelectOneObjectByQuery(state.DBFormReducer, {
      _id: formId,
      boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;

    dispatch(
      DBFormThunks.patch({
        entity: {
          ...form,
          afterSubmit: {
            ...form?.afterSubmit,
            [field]: value,
          },
        },
      })
    );
  }
);


export const updateTaskOrderInWorkloadUserThunk = createAsyncThunk<
  void,
  updateTaskOrderInWorkloadUserParams,
  { state: CommonRootState }
>(
  "tasks/updateTaskOrderInWorkloadUserThunk",
  async (
    updateTaskOrderInWorkloadUserParams: updateTaskOrderInWorkloadUserParams,
    thunkAPI
  ) => {
    const {
      draggedTaskId,
      sourceUserId,
      destinationUserId,
      workloadId,
      destinationIndex,
    } = updateTaskOrderInWorkloadUserParams;
    const state = thunkAPI.getState();
    const personPickerId = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "personPickerId"
    );
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const tasksOrderByUser =
      workloadSelectOneFieldByQuery(
        state.DBWorkloadReducer,
        {
          _id: workloadId,
        },
        "tasksOrderByUser"
      ) ?? {};
    const destinationTasksList = getOrderedTaskListForWorkloadUserCard(
      state,
      destinationUserId,
      workloadId
    );
    const draggedTask = tasksSelectOneObjectById(state.DBTasksReducer, draggedTaskId);

    const sortUserTasks = () => {
      let userTasksOrder = {};
      const newDestinationTasksOrder = [...destinationTasksList].filter(
        (task) => task._id && task._id !== draggedTaskId
      );

      newDestinationTasksOrder.splice(destinationIndex, 0, draggedTask);

      const newUserTasksOrder = newDestinationTasksOrder.map(
        (task, taskListIndex) => ({
          NullableId: task._id,
          number: taskListIndex,
        })
      );

      for (let i in newUserTasksOrder) {
        userTasksOrder = {
          ...userTasksOrder,
          [newUserTasksOrder[i].NullableId]: newUserTasksOrder[i].number,
        };
      }

      let newTasksOrderByUser = {};

      if (Object.keys(tasksOrderByUser).length === 0) {
        /// if the workload has no data about tasks order
        newTasksOrderByUser = { [destinationUserId]: userTasksOrder };
      } else if (tasksOrderByUser[destinationUserId] === undefined) {
        ///if the workload has no data about tasks order for the specific user (destination user)
        for (let i = 0; i < Object.keys(tasksOrderByUser).length; i++) {
          newTasksOrderByUser = {
            ...newTasksOrderByUser,
            [Object.keys(tasksOrderByUser)[i]]:
              tasksOrderByUser[Object.keys(tasksOrderByUser)[i]],
          };
        }
        newTasksOrderByUser = {
          ...newTasksOrderByUser,
          [destinationUserId]: userTasksOrder,
        };
      } else {
        /// if the workload has data about tasks order for the specific user (destination user)
        for (let i = 0; i < Object.keys(tasksOrderByUser).length; i++) {
          if (Object.keys(tasksOrderByUser)[i] === destinationUserId) {
            newTasksOrderByUser = {
              ...newTasksOrderByUser,
              [destinationUserId]: userTasksOrder,
            };
          } else {
            newTasksOrderByUser = {
              ...newTasksOrderByUser,
              [Object.keys(tasksOrderByUser)[i]]:
                tasksOrderByUser[Object.keys(tasksOrderByUser)[i]],
            };
          }
        }
      }

      dispatch(
        DBWorkloadThunks.patch({
          entity: {
            _id: workloadId,
            tasksOrderByUser: newTasksOrderByUser,
          },
        })
      );
    };

    if (sourceUserId === destinationUserId) {
      /// if the tasks are dragged within the same user
      sortUserTasks();
    }

    if (sourceUserId !== destinationUserId) {
      /// if the tasks are dragged between different users
      ///========================================
      /// workplan- 1) remove the dragged task from sourceUserId in the person picker column
      ///           2) add the dragged task to destinationUserId in the person picker column
      ///           3) apply the "if (sourceUserId === destinationUserId)" algorithm, once the      dragged task is added to destinationUserId
      ///           4) snackbar message for the case where the dragged task is dropped in a destination user that is already assigned to this task
      ///========================================
      /// part #1: remove the dragged task from the sourceUserId in the person picker column:
      const _cellValue = cellValueSelector(
        state,
        draggedTaskId,
        personPickerId
      );

      const cellValue = _cellValue
        ? _cellValue.filter((el) => el.id !== sourceUserId)
        : undefined;

      dispatch(
        updateCellValueThunk({
          taskId: draggedTaskId,
          columnId: personPickerId,
          data: {
            value: cellValue,
          },
        })
      );
      /// part #2: add the dragged task to destinationUserId in the person picker column
      const personList = cellValue
        ? cellValue.concat({ id: destinationUserId, type: "user" })
        : [{ id: destinationUserId, type: "user" }];

      personList.sort((a, b) =>
        workspaceUserCompareValue(a).localeCompare(workspaceUserCompareValue(b))
      );

      dispatch(
        updateCellValueThunk({
          taskId: draggedTaskId,
          columnId: personPickerId,

          data: {
            value: personList,
          },
        })
      );
      /// part #3: apply the "if (sourceUserId === destinationUserId)" algorithm, once the dragged task is added to destinationUserId
      sortUserTasks();
    }
  }
);

export const kanbanColumnOptionsSorted = createSelector(
  [
    getSelectedBoardId,
    (state, kanbanId) => state,
    (state, kanbanId) => kanbanId,
  ],
  (boardId, state, kanbanId) => {
    const optionColumnId: NullableId = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "optionColumnId"
    );
    const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: optionColumnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      undefined,
      {
        order: 1,
      }
    );
    const kanban = kanbanSelectOneObjectByQuery(state.DBKanbanReducer, {
      boardId,
      _id: kanbanId,
    });
    const sortedOptions = columnOptions
      .sort((a, b) => {
        if (kanban.columnsOrder) {
          const numberOfOptions = columnOptions.length;

          let aOrder = numberOfOptions;
          if (kanban.columnsOrder[a._id] !== undefined) {
            aOrder = kanban.columnsOrder[a._id];
          }

          let bOrder = numberOfOptions;
          if (kanban.columnsOrder[b._id] !== undefined) {
            bOrder = kanban.columnsOrder[b._id];
          }
          return aOrder - bOrder;
        } else {
          return a.order - b.order;
        }
      })
      .map((option, index) => ({
        ...option,
        order: index,
      }));
    return sortedOptions;
  }
);

export const isKanbanOptionPickerExists = createSelector(
  [(state) => state, (_, kanbanId) => kanbanId],
  (state, kanbanId) => {
    const optionColumnId = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "optionColumnId"
    );
    return optionColumnId !== undefined;
  }
);

export const isWorkloadComplexityPickerExist = createSelector(
  [(state) => state, (_, workloadId) => workloadId],
  (state, workloadId) => {
    const complexityPickerId = kanbanSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      { _id: workloadId },
      "complexityPickerId"
    );
    return !(complexityPickerId === null || complexityPickerId === undefined);
  }
);

export const isWorkloadPersonPickerExist = createSelector(
  [(state) => state, (_, workloadId) => workloadId],
  (state, workloadId) => {
    const personPickerId = kanbanSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      { _id: workloadId },
      "personPickerId"
    );
    return !(personPickerId === null || personPickerId === undefined);
  }
);

export const workloadSortedUserList = createSelector(
  [
    (state, workloadId) => state,
    (state, workloadId) => workloadId,
    getSelectedBoardId,
  ],
  (state, workloadId, boardId) => {
    const emptyStateUser: AugmentedBlueticksBoardUser = {
      _id: "emptystateuserfordisplay", // 24 letters
      type: "user",
      boardId,
      name: "Empty Tasks",
      firstName: "Empty",
      lastName: "Tasks",
    };
    // const mockPersonList = getMockPersonList(state)
    const userList = [
      ...getSelectedBoardCompleteUserList(state),
      emptyStateUser,
    ];
    const usersOrder =
      workloadSelectOneFieldByQuery(
        state.DBWorkloadReducer,
        {
          _id: workloadId,
        },
        "usersOrder"
      ) ?? {};

    const allKeys = Object.keys(usersOrder);
    const notFound = userList.filter((user) => !allKeys.includes(user._id));

    const returnList: AugmentedBlueticksBoardUser[] = [];

    if (Object.keys(usersOrder).length !== 0) {
      for (const key in usersOrder) {
        if (Object.prototype.hasOwnProperty.call(usersOrder, key)) {
          const idx = usersOrder[key];
          const usr = userList.find((_usr) => _usr._id === key);
          if (usr) {
            returnList[idx] = usr;
          }
        }
      }

      let _returnList = returnList.filter((x) => x);
      _returnList.push(...notFound);
      return _returnList;
      // returnList = userList.sort((a, b) => {
      //   const numberOfUsers = userList.length
      //   const aOrder = usersOrder[a._id] === undefined ? (numberOfUsers + 1) : usersOrder[a._id]
      //   const bOrder = usersOrder[b._id] === undefined ? (numberOfUsers + 1) : usersOrder[b._id]
      //   return aOrder - bOrder
      //   // return bOrder - aOrder
      // })
      // return returnList
    } else {
      return userList;
    }
  }
);

export const getWorkloadUserForUserCard = createSelector(
  [(userId) => userId, (_, state) => state, (_, __, workloadId) => workloadId],
  (userId, state, workloadId) => {
    const userList = workloadSortedUserList(state, workloadId);

    return userList.find((user) => user._id === userId);
  }
);


export const getMockUserForLogs = createSelector(
  [
    (userId) => userId,
    (_, state) => state,
  ],
  (userId, state) => {

    const userList = getSelectedBoardCompleteUserList(state)

    return userList.find(user => user._id === userId)
  }
);

export const getIsShowSubTasks = createSelector(
  [(params) => params],
  (params: { state: CommonRootState; taskId }) => {
    return params.state.TasksReducer.toggleTasksMap[params.taskId];
  }
);


export const getUserForLogs = createSelector(
  [(state, value) => state, (state, value) => value],
  (state, value) => {
    const allUsersList = [
      ...getSelectedBoardCompleteUserList(state),
      ...getMockPersonList(state),
    ];
    const user = allUsersList.find((user) => user.userId === value?._id)
      ?.profile ??
      allUsersList.find((user) => user._id === value._id)?.profile ?? {
      firstName: value.firstName,
      lastName: value.lastName,
      email: "www@www.www",
    };

    return user as User;
  }
);

export const getTaskAccentColor = createSelector(
  [
    (type) => type,
    (_, groupId) => groupId,
    (_, __, state) => state,
    (_, __, ___, taskId) => taskId,
    (_, __, ___, ____, columnId) => columnId,
  ],
  (type, groupId, state, taskId, columnId) => {
    let groupColor;
    if (type === "kanban") {
      groupColor = taskGroupsSelectOneFieldByQuery(
        state.DBTaskgroupsReducer,
        {
          _id: groupId,
        },
        "colorAccent"
      );
    }
    if (type === "workload") {
      const customData = tasksSelectOneFieldById(
        state.DBTasksReducer,
        taskId,
        "customData"
      );
      let complexityOptionId;
      if (customData !== null) {
        complexityOptionId = customData[columnId];
      }
      let complexityOption;
      if (complexityOptionId) {
        complexityOption = _taskColumnOptionSelectOneObjectByQuery(
          state.DBTaskColumnOptionReducer,
          { _id: complexityOptionId.value }
        );
      }
      groupColor = complexityOption?.bgcolor;
    }

    return groupColor;
  }
);

export const getOneFieldFromTaskGroupByTaskId = createSelector(
  [(params) => params],
  (params: { state: CommonRootState; taskId; field: string }) => {
    const groupId = tasksSelectOneFieldById(
      params.state.DBTasksReducer,
      params.taskId,
      "groupId"
    );

    const selectedField = taskGroupsSelectOneFieldById(
      params.state.DBTaskgroupsReducer,
      groupId,
      params.field
    );
    return selectedField;
  }
);

export const restoreTheItems = createAsyncThunk<
  void,
  {
    items: ArchivedOrDeletedItem[];
    deleteOrArchive: string;
  },
  { state: CommonRootState }
>("restore/restoreItems", async (params, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const deleteOrArchive = params.deleteOrArchive === "Trash" ? "deleted" : "archived"
  dispatch(
    recycleActions.setArchivedOrDeletedSnackMessage({
      message: `Restoring ${params.items.length} items`,
      type: "info",
    })
  );

  let res: any = await dispatch(
    restoreItems.initiate({
      items: params.items as ArchivedOrDeletedItem[],
      deleteOrArchive,
    })
  );

  if (res) {
    const allItems: ArchivedOrDeletedItem[] =
      state.RecycleBinReducer.archivedOrDeletedData;
    const idsToFilter = params.items.map((i) => i._id);
    const filteredItems = allItems.filter(
      (item) => !idsToFilter.includes(item._id)
    );

    dispatch(recycleActions.setArchivedOrDeletedData(filteredItems));
    dispatch(recycleActions.setArchivedOrDeletedBulkItems([]))

    dispatch(
      recycleActions.setArchivedOrDeletedSnackMessage({
        message: `${params.items.length} items Successfully restored`,
        type: "success",
      })
    );
  } else {
    dispatch(
      recycleActions.setArchivedOrDeletedSnackMessage({
        message: `Failed to restore ${params.items.length} items`,
        type: "error",
      })
    );
  }

  if (res.data.modifiedTaskIds.length > 0) {
    dispatch(
      tasksReducerActions.updateMany(
        res.data.modifiedTaskIds.map((taskId, index) => ({
          id: taskId,
          changes: {
            [deleteOrArchive]: true,
          },
        }))
      )
    );
  }

  if (res.data.modifiedGroupIds.length > 0) {
    dispatch(
      taskgroupsReducerActions.updateMany(
        res.data.modifiedGroupIds.map((groupId, index) => ({
          id: groupId,
          changes: {
            [deleteOrArchive]: true,
          },
        }))
      )
    );
  }

  // const archivedOrDeletedData = state.RecycleBinReducer.archivedOrDeletedData;
  // const currentTab = state.RecycleBinReducer.currentTab;
  // const searchText = state.RecycleBinReducer.searchText;
  // const filters = state.RecycleBinReducer.filters;

  // const lastItem = archivedOrDeletedData[archivedOrDeletedData.length - 1];

  // dispatch(
  //   fetchArchivedOrDeletedData({
  //     mode: currentTab,
  //     itemId: lastItem?._id ?? "",
  //     itemUpdatedAt: lastItem?.updatedAt.toString() ?? "",
  //     searchQuery: searchText,
  //     ...filters,
  //     showLoader: false,
  //   })
  // );

  //dispatch(recycleActions.setArchivedOrDeletedIsLoading(false));

  let currentBoard: Board = getBoardByChat(
    state,
    state.WhatsAppReducer?.currentChat
  );
  await dispatch(DBBoardsThunks.find({}));
  await dispatch(getBoardDataThunk({ boardId: currentBoard?._id }));
});

export const deletePermantley = createAsyncThunk<
  void,
  {
    items: ArchivedOrDeletedItem[];
  },
  { state: CommonRootState }
>("delete/deletePermantley", async (params, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  dispatch(
    recycleActions.setArchivedOrDeletedSnackMessage({
      message: `Deleting ${params.items.length} items`,
      type: "info",
    })
  );

  const allItems: ArchivedOrDeletedItem[] =
    state.RecycleBinReducer.archivedOrDeletedData;
  const idsToFilter = params.items.map((i) => i._id);
  const filteredItems = allItems.filter(
    (item) => !idsToFilter.includes(item._id)
  );

  dispatch(recycleActions.setArchivedOrDeletedData(filteredItems));

  let res: any = await dispatch(
    deleteItems.initiate({
      items: params.items as ArchivedOrDeletedItem[],
    })
  );

  if (res) {
    dispatch(
      recycleActions.setArchivedOrDeletedSnackMessage({
        message: `${params.items.length} items Successfully deleted`,
        type: "success",
      })
    );
  } else {
    dispatch(
      recycleActions.setArchivedOrDeletedSnackMessage({
        message: `Failed to delete ${params.items.length} items`,
        type: "error",
      })
    );
  }
});

export const fetchArchivedOrDeletedData = createAsyncThunk<
  void,
  {
    mode: string;
    itemId?: string;
    itemUpdatedAt?: string;
    searchQuery?: string;
    type?: string[];
    date?: string;
    board?: string[];
    showLoader?: boolean;
  },
  { state: CommonRootState }
>("getData/fetchArchivedOrDeletedData", async (params, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  if (params.showLoader) {
    dispatch(recycleActions.setArchivedOrDeletedIsLoading(true));
  }

  let res = await dispatch(
    getArchivedOrDeletedData.initiate({
      mode: params.mode,
      itemId: params.itemId,
      itemUpdatedAt: params.itemUpdatedAt,
      searchQuery: params.searchQuery,
      type: params.type,
      date: params.date,
      board: params.board,
    })
  ).unwrap();

  const existingDataItemsIds = new Set(
    state.RecycleBinReducer.archivedOrDeletedData.map((item) => item._id)
  );

  const uniqueItemsIds = res.filter((item) => !existingDataItemsIds.has(item._id));

  const mergedItems = [
    ...state.RecycleBinReducer.archivedOrDeletedData,
    ...uniqueItemsIds,
  ];

  dispatch(recycleActions.setArchivedOrDeletedData(mergedItems));
  dispatch(recycleActions.setArchivedOrDeletedIsLoading(false));
});

//selector that returns checked tasks bool
export const getIsChecked = createSelector(
  [(params) => params],
  (params: { state: CommonRootState; taskId: string }) => {
    const bulkTasksMap = params.state.BulkActionsReducer.bulkTasksMap;
    const task = tasksSelectors.selectById(params.state, params.taskId);
    return !!bulkTasksMap[task?._id];
  }
);



// selector that counts subtasks
export const getSubTasksCount = createSelector(
  [(state) => state, (_, taskId) => taskId],
  (state, taskId) => {
    const subtasksOfTheSameTask = tasksSelectByQuery(state.DBTasksReducer, {
      parentTaskId: taskId,
      deleted: false,
    });
    return subtasksOfTheSameTask.length;
  }
);

// selector for taskcheck for UI only
export const isLastTaskInGroup = createSelector(
  (state, taskId) => ({ state, taskId }),
  selectElementsList,
  (params: any, elementsList: any) => {
    if (!params.taskId) return false;
    var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
    const task = tasks.find((el) => el?.id === params.taskId);
    tasks = tasks.filter((el) => el?.groupId === task?.groupId);
    return tasks.length && tasks[tasks.length - 1].id === params.taskId;
  }
);

// export const isLastTaskWithSubtasksInGroup = createSelector(
//   (state, taskId, subTasksCount) => ({ state, taskId, subTasksCount }),
//   selectElementsList,
//   (params, elementsList) => {
//     if (!params.taskId) return false;
//     var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
//     const task = tasks.find((el) => el?.id === params.taskId);
//     tasks = tasks.filter((el) => {
//       return el?.groupId === task?.groupId
//     });
//     tasks = tasks.filter((el) => tasks.indexOf(el) <= tasks.indexOf(task) || tasks.indexOf(el) > tasks.indexOf(task) + params.subTasksCount)
//     return tasks.length && tasks[tasks.length - 1].id == params.taskId;
//   }
// );

export const isFirstTaskInGroup = createSelector(
  (state, taskId) => ({ state, taskId }),
  selectElementsList,
  (params: any, elementsList: any) => {
    var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
    const task = tasks.find((el) => el?.id === params.taskId);
    tasks = tasks.filter((el) => el?.groupId === task?.groupId);
    return tasks.length && tasks[0].id === params.taskId;
  }
);

export const selectIsSubTask = createSelector(
  [
    (state, taskId) => ({ state, taskId })
  ],
  (params) => {
    return Boolean(tasksSelectOneFieldById(params.state.DBTasksReducer, params.taskId, "parentTaskId"))
  }
);

export const selectPrefixCellBorderRadius = createSelector(
  (state, taskId) => ({ state, taskId }),
  (state, taskId) => selectIsSubTask(state, taskId),
  (state, taskId) => isFirstTaskInGroup(state, taskId),
  (state, taskId) => isLastTaskInGroup(state, taskId),
  (params, isSubtask, isFirstTaskInGroup, isLastTaskInGroup) => {
    const direction = 'ltr'; // Assuming LTR direction for simplification
    if (direction === 'ltr') {
      return (!isSubtask && isFirstTaskInGroup && isLastTaskInGroup) ? "12px 0 0 12px" :
        (!isSubtask && isFirstTaskInGroup) ? "12px 0 0 0" :
          (!isSubtask && isLastTaskInGroup) ? "0 0 0 12px" :
            "0";
    } else {
      return (!isSubtask && isFirstTaskInGroup && isLastTaskInGroup) ? "0 12px 12px 0" :
        (!isSubtask && isFirstTaskInGroup) ? "0 12px 0 0" :
          (!isSubtask && isLastTaskInGroup) ? "0 0 12px 0" :
            "0";
    }
  }
);

const handleSubTasks = (isChecking, taskSubTasks, bulkTasksMap) => {
  let uncheckParent = false;
  for (let subTask of taskSubTasks) {
    if (isChecking) {
      bulkTasksMap[subTask._id] = subTask;
    } else {
      if (bulkTasksMap[subTask._id]) {
        delete bulkTasksMap[subTask._id];
        uncheckParent = true;
      }
    }
  }
  return {
    anchorId: isChecking
      ? taskSubTasks[taskSubTasks.length - 1]._id
      : undefined,
    uncheckParent,
  };
};

const handleTaskSelection = (isChecking, taskId, task, bulkTasksMap) => {
  isChecking ? (bulkTasksMap[taskId] = task) : delete bulkTasksMap[taskId];
  return isChecking ? taskId : undefined;
};

// select tasks with  shift
export const selectRowItemWithThunk = createAsyncThunk<
  void,
  { shiftKey: boolean; taskId: string },
  { state: any }
>("tasks/selectRowItemWithThunk", async (params, thunkAPI) => {
  const { taskId, shiftKey } = params;
  const state: CommonRootState = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  const { bulkTasksMap: originalBulkTasksMap, anchorId: originalAnchorId } =
    state.BulkActionsReducer;
  const task = tasksSelectors.selectById(state, taskId);
  const orderedBoardTaskList = selectOrderedBoardTasksAndSubtasks(state);
  const taskSubTasks = tasksSelectByQuery(state.DBTasksReducer, {
    parentTaskId: taskId,
  });

  const bulkTasksMap = { ...originalBulkTasksMap };
  let isChecking = !originalBulkTasksMap[taskId];

  let anchorId = handleTaskSelection(isChecking, taskId, task, bulkTasksMap);
  let uncheckParent = false;
  if (taskSubTasks.length > 0) {
    const subTasksResult = handleSubTasks(
      isChecking,
      taskSubTasks,
      bulkTasksMap
    );
    anchorId = subTasksResult.anchorId;
    uncheckParent = subTasksResult.uncheckParent;
  }

  if (uncheckParent && task.parentTaskId && bulkTasksMap[task.parentTaskId]) {
    const parentTask = tasksSelectors.selectById(state, task.parentTaskId);
    const parentSubTasks = tasksSelectByQuery(state.DBTasksReducer, {
      parentTaskId: task.parentTaskId,
    });

    anchorId = handleTaskSelection(
      false,
      task.parentTaskId,
      parentTask,
      bulkTasksMap
    );
    if (parentSubTasks.length > 0) {
      handleSubTasks(false, parentSubTasks, bulkTasksMap);
    }
  }

  // Check if the unchecked task is a subtask
  if (!isChecking && task.parentTaskId) {
    const parentTask = tasksSelectors.selectById(state, task.parentTaskId);
    const parentSubTasks = tasksSelectByQuery(state.DBTasksReducer, {
      parentTaskId: task.parentTaskId,
    });

    if (bulkTasksMap[task.parentTaskId]) {
      // If the parent is checked, uncheck all subtasks and the parent
      anchorId = handleTaskSelection(
        false,
        task.parentTaskId,
        parentTask,
        bulkTasksMap
      );
      handleSubTasks(false, parentSubTasks, bulkTasksMap);
    } else {
      // Only subtasks are checked, uncheck only the current subtask
      delete bulkTasksMap[task._id];
    }
  }

  if (shiftKey) {
    anchorId = state.BulkActionsReducer.anchorId;
    const interactionIndex = orderedBoardTaskList.findIndex(
      (_task) => _task._id && _task._id === task._id
    );
    const closestTopChecked = Math.max(
      orderedBoardTaskList.findIndex(
        (task, index) => index < interactionIndex && !!bulkTasksMap[task._id]
      ),
      0
    );
    const closestBottomChecked = Math.min(
      orderedBoardTaskList.findIndex(
        (task, index) => index > interactionIndex && !!bulkTasksMap[task._id]
      ),
      orderedBoardTaskList.length - 1
    );

    let anchorIndex;
    if (!anchorId) {
      anchorIndex =
        Math.abs(interactionIndex - closestTopChecked) <=
          Math.abs(interactionIndex - closestBottomChecked)
          ? closestTopChecked
          : closestBottomChecked;
    } else {
      anchorIndex = Math.max(
        orderedBoardTaskList.findIndex(
          (_task) => _task._id && _task._id === anchorId
        ),
        0
      );
    }

    console.log(
      `Interaction Index: ${interactionIndex}, Anchor index: ${anchorIndex}, [${closestTopChecked}, ${closestBottomChecked}]`
    );
    if (anchorIndex !== interactionIndex) {
      if (isChecking) {
        const rangeStart =
          anchorIndex > interactionIndex ? interactionIndex : anchorIndex;
        const rangeEnd =
          anchorIndex > interactionIndex ? anchorIndex : interactionIndex;

        console.log(`Filling range. [${rangeStart}, ${rangeEnd}]`);
        for (let i = rangeStart; i <= rangeEnd; i++) {
          const taskToCheck = orderedBoardTaskList[i];
          bulkTasksMap[taskToCheck._id] = taskToCheck;
        }

        console.log(`Anchor for task at index ${rangeStart}: ${anchorId}`);
      } else {
        let direction = interactionIndex > anchorIndex ? 1 : -1;
        let iterationIndex = interactionIndex;
        let iterationTask;
        let shouldContinue;
        do {
          iterationTask = orderedBoardTaskList[iterationIndex];
          // Skip current interaction
          if (iterationTask._id !== task._id) {
            shouldContinue = !!bulkTasksMap[iterationTask._id];
            if (iterationTask) {
              delete bulkTasksMap[iterationTask._id];
            }
          } else {
            shouldContinue = true;
          }

          iterationIndex += direction;
        } while (
          iterationTask &&
          shouldContinue &&
          (direction < 0
            ? iterationIndex >= 0
            : iterationIndex < orderedBoardTaskList.length)
        );
      }
    }
  }

  const bulkTasksList = Object.keys(bulkTasksMap).map((keys) => ({ keys }));
  console.log(bulkTasksList);

  dispatch(bulkActions.setIsBulkActionsDialogOpen(bulkTasksList.length > 0));
  console.log(`Anchor ID: ${anchorId}`);
  dispatch(
    bulkActions.setLastInteraction({
      bulkTasksMap,
      anchorId,
    })
  );
  console.log(bulkTasksMap);
});


export const setSubtaskStateForTaskList = createAsyncThunk<
  void,
  { taskIdList: string[]; expand: boolean },
  { state: CommonRootState }
>("tasks/addNewTaskGroupThunk", async ({ taskIdList, expand }, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();

  const tasksWithSubItemsIdList = taskIdList.filter((taskId) => {
    const subTasks = tasksSelectByQuery(state.DBTasksReducer, {
      parentTaskId: taskId,
    });
    return subTasks.length > 0;
  });

  if (expand) {
    dispatch(taskActions.openSubtasksForTaskList(tasksWithSubItemsIdList));
  } else {
    dispatch(taskActions.closeSubtasksForTaskList(tasksWithSubItemsIdList));
  }
});




export const selectDropdownOptionsAndPickedValues = createSelector(
  [(state) => state, (state, params: any) => params],
  (state: CommonRootState, params) => {
    const { taskId, columnId, boardId } = params;
    const cellValue = cellValueSelector(state, taskId, columnId);
    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const dropdownValues = tasksSelectByQuery(state.DBTasksReducer, {
      boardId,
      [`customData.${columnId}`]: {
        $exists: true,
      },
    });
    const flattenDropdownValuesIds = dropdownValues
      .map((obj) => obj?.customData[columnId].value)
      .flat();
    const dropdownValuesIds = flattenDropdownValuesIds.map(
      (value) => value?._id
    );
    const extractValues = columnOptions
      .filter((option) => cellValue?.some((value) => value._id === option._id))
      .map((s) => s._id);
    const optionsTopick = columnOptions.filter(
      (option) => !extractValues.includes(option._id)
    );
    const pickedOptions = columnOptions.filter((option) =>
      extractValues.includes(option._id)
    );

    return { columnOptions, optionsTopick, pickedOptions, dropdownValuesIds };
  }
);

export const getColumnValues = createSelector(
  [(state) => state, (state, params: any) => params],
  (state: CommonRootState, params) => {
    const { groupId, columnId, boardId, columnType } = params;
    const currentBoardTasks = currentTasks(state);
    const currentBoardTaskIds = currentBoardTasks.map((task) => task._id);

    const columnValues = tasksSelectByQuery(state.DBTasksReducer, {
      boardId,
      groupId,
      taskType: { $ne: "subTask" },
      [`customData.${columnId}`]: {
        $exists: true,
      },
    }).filter((task) => currentBoardTaskIds.includes(task._id));

    let res;
    const columnValuesArray = columnValues
      .map((obj) => obj?.customData[columnId].value)
      .flat();
    if (columnType === "number") {
      res = columnValuesArray;
    }
    if (
      columnType === "status-option-picker" ||
      columnType === "option-picker" ||
      columnType === "complexity-picker"
    ) {
      const tasks = tasksSelectByQuery(state.DBTasksReducer, {
        groupId,
        taskType: { $ne: "subTask" },
      }).filter((task) => currentBoardTaskIds.includes(task._id));
      const tasksLength = tasks.length;
      const columnOptions = taskColumnOptionSelectByQuery(
        state.DBTaskColumnOptionReducer,
        {
          boardId,
          columnId,
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
        }
      );

      const count = {};
      columnValuesArray.forEach((element) => {
        count[element] = (count[element] || 0) + 1;
      });

      const selectedOptions = columnValuesArray.map((id) =>
        columnOptions.find((el) => el._id === id)
      );

      const selectedOptionsCloned = JSON.parse(JSON.stringify(selectedOptions));
      const duplicates = findDuplicates(selectedOptionsCloned);
      if (duplicates) {
        selectedOptionsCloned.forEach((obj) => {
          const count = duplicates[obj?._id];
          if (obj && count && count >= 1) {
            obj.count = count;
          }
        });
      }
      res = { selectedOptionsCloned, tasksLength };
    }

    if (columnType === "files") {
      let allImages = [];
      columnValues?.flatMap((item) => {
        const files =
          item.customData[columnId]?.value?.map((obj) => ({
            ...obj,
            _id: item._id,
          })) ?? [];
        if (files.length > 0) {
          allImages.push(files);
        }
      });
      res = allImages.flat();
    }

    return res;
  }
);

export const getTasksWithValueInColumnIdSelector = createSelector(
  [(state) => state, (_, columnId: NullableId) => columnId],
  (state: CommonRootState, columnId) => {
    const currentBoardTasks = currentTasks(state);
    const matchingList = [];
    for (let i = 0; i < currentBoardTasks.length; i++) {
      const task = currentBoardTasks[i];
      if (task?.customData && task?.customData[columnId]?.value) {
        matchingList.push(task);
      }
    }
    return matchingList;
  }
);

export const updateManyTasksWithWhatsappName = createAsyncThunk<void, void, { state: CommonRootState }>(
  "tasks/updateManyTasksWithWhatsappName",
  async (_, thunkAPI) => {

    const { dispatch, getState } = thunkAPI;
    const state = getState();

    const currentBoardTasks = currentTasks(state);
    const mergedContactMapWithNamesOnly = selectMergedContactMapWithNamesOnly(state)

    let tasksForUpdate = [];
    for (let i = 0; i < currentBoardTasks.length; i++) {
      const task = currentBoardTasks[i];

      if (!task.isTaskConverted && mergedContactMapWithNamesOnly[task?.text]) {
        tasksForUpdate.push({
          id: task._id,
          changes: {
            text: mergedContactMapWithNamesOnly[task?.text].name,
            isTaskConverted: true,
          },
        });
      }
    }


    if (tasksForUpdate.length !== 0) {
      await dispatch(DBTasksThunks.patchMany(tasksForUpdate))
    }
  })

// toggle collapse for group
export const toggleCollapseGroup = createAsyncThunk<void, { groupId: string }, { state: CommonRootState }>(
  "tasks/toggleCollapseGroup",
  async ({ groupId }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState();
    const tasksGroup = taskGroupsSelectOneObjectById(state.DBTaskgroupsReducer, groupId);

    const clonedTasksGroup = { ...tasksGroup }
    clonedTasksGroup.isCollapsed = !clonedTasksGroup.isCollapsed
    dispatch(DBTaskgroupsThunks.patch({
      entity: clonedTasksGroup
    }))
  })

// update group name
export const updateGroupNameThunk = createAsyncThunk<void, { groupId: string, groupName: string }, { state: CommonRootState }>(
  "tasks/updateGroupName",
  async ({ groupId, groupName }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState();
    const tasksGroup = taskGroupsSelectOneObjectById(state.DBTaskgroupsReducer, groupId);

    const clonedTasksGroup = { ...tasksGroup }
    clonedTasksGroup.name = groupName
    dispatch(DBTaskgroupsThunks.patch({
      entity: clonedTasksGroup
    }))
  })