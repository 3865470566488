import PromptDeleteAction from '@common-components/prompt-delete-action/prompt-delete-action';
import MoveToMenu from '@common-components/tasks-panel/bulk-actions-dialog/move-to/move-to-menu';
import { BoardRowType } from "@common-models/task";
import { bulkActions, getBulkTaskList } from '@common-reducers/BulkActionsReducer';
import { DBTasksThunks, tasksSelectByQuery, tasksSelectors } from "@common-reducers/DBServiceThunks";
import { setExtraDataOfTaskId } from '@common-reducers/ExtraDataReducer';
import { mainSliceActions } from '@common-reducers/MainReducer';
import { getSubTasksCount } from '@common-reducers/TaskSelectors';
import { addNewTaskThunk, convertSubTaskToTaskAndReOrderGroup, moveTaskToTopOfTheGroupAndReOrderRestOfTasks } from '@common-reducers/TaskThunks';
import { taskActions } from '@common-reducers/TasksReducer';
import { UndoRedoAction } from '@common-reducers/UndoRedoReducer';
import { doActionThunk } from '@common-reducers/UndoRedoThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { namespace } from '@common/config/constants';
import { Box, Stack, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { BiText } from 'react-icons/bi';
import { BsArrow90DegUp, BsChevronRight, BsTrash } from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { IoIosLink } from "react-icons/io";
import { MdAdd, MdOutlineContentCopy, MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import { TbSubtask } from 'react-icons/tb';
import SelectParentTask from './select-parent-task/select-parent-task';
import classes from './task-menu.module.scss';


export default function TaskMenu() {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const menuActiveTaskId = useAppSelector((state) => state.TasksReducer.menuActiveTaskId)
    const displayPanel = useAppSelector((state) => state.TasksReducer.currentBoardView)
    const move_to = useAppSelector((state) => state.BulkActionsReducer.move_to)
    const isBoardItemMenuOpen = useAppSelector((state) => state.BoardsReducer.isBoardItemMenuOpen)
    const [open, setOpen] = useState(false);
    const task = useAppSelector((state) => tasksSelectors.selectById(state, menuActiveTaskId))
    const hasSubs = useAppSelector((state) => getSubTasksCount(state, menuActiveTaskId))
    const id = isBoardItemMenuOpen ? 'board-item-popper' : undefined;
    const bulkTasksList = useAppSelector(getBulkTaskList)
    const taskParent = useAppSelector((state) => tasksSelectByQuery(state.DBTasksReducer, {
        _id: task?.parentTaskId,
    }));
    let menuAnchorEl
    if (menuActiveTaskId) {
        menuAnchorEl = document.getElementById(`task-button-${menuActiveTaskId}`);
    }
    // menu start rename board name


    function openExtraDataPanel() {
        dispatch(setExtraDataOfTaskId(task?._id));
        dispatch(taskActions.setTaskViewSideDrawerOpenWith("ExtraDataPanel"));
        dispatch(taskActions.toggleTaskMenu(''))
    }

    // duplicate task/s
    function duplicateItem() {
        const clonedTask = { ...task }
        delete clonedTask._id;
        clonedTask.text = `${clonedTask.text} - (copy)`
        delete clonedTask.order
        dispatch(addNewTaskThunk(clonedTask))
        dispatch(taskActions.toggleTaskMenu(''))
    }

    const CopyLinkToTask = () => {
        //copy to clipboard
        const url = `${window.location.origin}/tasks?boardID=${task.boardId}&taskId=${task._id}`
        navigator.clipboard.writeText(url)
        dispatch(taskActions.toggleTaskMenu(''))
        dispatch(taskActions.setTaskSnackbarMessage(t('taskMenu.copyTaskLinkToClipboard')));
    }

    async function createNewTaskBelowItem() {

        const newTask = {
            text: t('taskMenu.newItem'),
            boardId: task?.boardId,
            groupId: task?.groupId,
            status: "",
            priority: "",
            person: "",
            order: task?.order
        };
        dispatch(taskActions.toggleTaskMenu(''))
        const res = await dispatch(DBTasksThunks.create(newTask)).unwrap();
        dispatch(taskActions.setForceEditModeByTaskid(res._id.toString()))
    }
    async function addSubItem() {
        const newTask = {
            text: t('taskMenu.newSubitem'),
            boardId: task?.boardId,
            groupId: task?.groupId,
            status: "",
            priority: "",
            person: "",
            order: task?.order,
            parentTaskId: task?._id,
            taskType: BoardRowType.subTask
        };
        dispatch(DBTasksThunks.patch({
            entity: { ...task, showSubTasks: true }
        }));
        dispatch(taskActions.toggleTask(task?._id))
        dispatch(taskActions.toggleTaskMenu(''))
        const res = await dispatch(DBTasksThunks.create(newTask)).unwrap();
        dispatch(taskActions.setForceEditModeByTaskid(res._id.toString()))

    }

    function deleteTask() {
        setOpen(true);
    }

    function setMovingTo(to) {
        dispatch(bulkActions.setMovingTo(to))
        dispatch(bulkActions.setGroupIsMoving(false))
        dispatch(bulkActions.setBulActionsMoveToId(menuActiveTaskId))
    }
    function moveTaskToTopOfGroup() {
        dispatch(moveTaskToTopOfTheGroupAndReOrderRestOfTasks());
        dispatch(taskActions.toggleTaskMenu(''))
    }

    const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            const action: UndoRedoAction<string[], string> = {
                type: 'delete-task-list',
                data: [menuActiveTaskId],
                deletedOrArchived: "deleted"
            }
            dispatch(doActionThunk({ action }),)
        }
        setOpen(false);
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

    const isSubTask = task?.parentTaskId ? true : false;

    // convert subtask to parent task
    function convertSubTaskToTask() {
        if (!isSubTask) return
        dispatch(convertSubTaskToTaskAndReOrderGroup())
        //let taskType: string = BoardRowType.task
        // dispatch(DBTasksThunks.patch({
        //     entity: { ...task, parentTaskId: '', taskType }
        // }));
        dispatch(dispatch(taskActions.closeSubtasksForTaskList([task.parentTaskId])))
        dispatch(taskActions.toggleTaskMenu(''))
        dispatch(bulkActions.setBulkTasksMap({}))
    }


    return (<>
        <PromptDeleteAction title={`${t('taskMenu.promptDeleteActionTitle')}`} handleClose={handleDeleteConfirmationClosed} open={open} />
        {menuActiveTaskId && (
            <ClickAwayListener onClickAway={() => dispatch(taskActions.toggleTaskMenu(''))}>
                <Box style={{ position: 'relative', zIndex: 1300 }}>
                    <Popper
                        id={id}
                        open={true}
                        anchorEl={menuAnchorEl}
                        style={{ zIndex: 1300 }}
                    >
                        <Box className={classes.menu_wrapper}>
                            <Box className={classes.menu_head}>
                                <button className={classes.menu_list_item} onClick={openExtraDataPanel}>
                                    <AiOutlineExpandAlt />
                                    <span style={{ marginInlineStart: 5 }}>{t('taskMenu.openButtonText')}</span>
                                </button>
                                <Box className={classes.move_to_wrapper}>
                                    <button style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }} className={classes.menu_list_item} id={`bulk-actions-menu-${menuActiveTaskId}`}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <FiArrowRight />
                                            <span style={{ marginInlineStart: 5 }}>{t('taskMenu.moveToButtonText')}</span></Stack>
                                        <BsChevronRight />
                                    </button>
                                    {move_to ?

                                        <Box className={classes.menu_component_wrap}>
                                            <MoveToMenu />
                                        </Box>
                                        : (
                                            <Box className={classes.move_to_menu}>
                                                <button onClick={() => setMovingTo("board")} className={classes.move_to_btn}>{t('taskMenu.moveToBoardButtonText')}</button>
                                                <button onClick={() => setMovingTo("group")} className={classes.move_to_btn}>{t('taskMenu.moveToGroupButtonText')}</button>
                                                {(!isSubTask && bulkTasksList.length === 1) ? (<button onClick={moveTaskToTopOfGroup} className={classes.move_to_btn}>{t('taskMenu.moveToBoardButtonText')}</button>) : null}


                                            </Box>
                                        )}


                                </Box>
                                <button className={classes.menu_list_item} onClick={duplicateItem}>
                                    <MdOutlineContentCopy />
                                    <span style={{ marginInlineStart: 5 }}>{t('taskMenu.duplicateButtonText')}</span>
                                </button>
                                {!isExtensionContextOrWhatsappView() &&
                                    <button className={classes.menu_list_item} onClick={CopyLinkToTask}>
                                        <IoIosLink />



                                        <span style={{ marginInlineStart: 5 }}>{t('taskMenu.copyTaskUrlButtonText')}</span>
                                    </button>

                                }


                                <CopyToClipboard
                                    text={task?.text}
                                    onCopy={() => {
                                        dispatch(taskActions.setTaskSnackbarMessage(t('taskMenu.copyToClipboardConfirmation')));
                                        dispatch(taskActions.toggleTaskMenu(''))
                                    }}
                                >
                                    <button className={classes.menu_list_item} >
                                        <BiText />
                                        <span style={{ marginInlineStart: 5 }}>
                                            {t('taskMenu.copyNameButtonText')}
                                        </span>
                                    </button>
                                </CopyToClipboard>


                            </Box>
                            {!isSubTask && displayPanel.type === 'board' ? (<button onClick={addSubItem} className={classes.menu_list_item}>
                                <TbSubtask />
                                <span style={{ marginInlineStart: 5 }}>{t('taskMenu.addSubitemButtonText')}</span>
                            </button>) : null}


                            <Box className={classes.convert_task_to_wrapper}>
                                <Tooltip arrow placement='right' title={hasSubs > 0 ? t('taskMenu.itemsWithSubitemsCannotBeConvertedTooltip') : ''}>
                                    <span>
                                        <button style={{ color: hasSubs > 0 ? 'lightgrey' : "", cursor: hasSubs > 0 ? "default" : "pointer", justifyContent: "space-between" }} disabled={hasSubs > 0}
                                            onClick={convertSubTaskToTask} className={classes.menu_list_item}>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                {isSubTask ? <BsArrow90DegUp /> : <MdOutlineSubdirectoryArrowRight />}
                                                <span style={{ marginInlineStart: 5 }}>
                                                    {t('taskMenu.convertTo')}{" "}
                                                    {isSubTask ? t('taskMenu.convertToItemButtonText') : t('taskMenu.convertToSubitemButtonText')}
                                                </span>
                                            </Stack>
                                            {!isSubTask ? (<BsChevronRight />) : null}

                                        </button>
                                    </span>
                                </Tooltip>

                                {!isSubTask && !hasSubs ? (
                                    <Box className={classes.select_parent_task_wrapper}
                                        sx={{
                                            right: isExtensionContextOrWhatsappView() ? '-200px' : '-220px'
                                        }}
                                    >
                                        <SelectParentTask taskId={menuActiveTaskId} />
                                    </Box>
                                ) : <></>}

                            </Box>
                            {displayPanel.type === 'board' ? (<button className={classes.menu_list_item} onClick={createNewTaskBelowItem}>
                                <MdAdd />
                                <span style={{ marginInlineStart: 5 }}>{t('taskMenu.createNewItemBelowButtonText')}</span>
                            </button>) : null}

                            <Box className={classes.menu_footer}>
                                {/* <button className={classes.menu_list_item}>
                                    <FiArchive />
                                    <span style={{ marginLeft: 5 }}>Archive</span>
                                </button> */}
                                <button onClick={deleteTask} className={classes.menu_list_item}>
                                    <BsTrash />
                                    <span style={{ marginInlineStart: 5 }}>{t('taskMenu.deleteButtonText')}</span>
                                </button>

                            </Box>
                        </Box>

                    </Popper>
                </Box>
            </ClickAwayListener >
        )
        }

    </>

    );
}



























