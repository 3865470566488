



import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const BTTimeline = ({ rangeDateInput, onValueUpdate }) => {

    const [rangeDate, setRangeDate] = useState([
        {
            startDate: rangeDateInput ? new Date(rangeDateInput.startDate) : new Date(),
            endDate: rangeDateInput ? new Date(rangeDateInput.endDate) : new Date(),
            key: 'selection'
        }
    ]);

    let output: any;



    const onChange = (item) => {
        console.log(item)
        setRangeDate([item.selection])
        let startDate = [item.selection][0].startDate;
        let endDate = [item.selection][0].endDate;
        const today = new Date();
        let progressInPercentage = ((today.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) * 100;
        if(startDate.getTime() > today.getTime()){
            progressInPercentage = 0
        }
        progressInPercentage = Math.trunc(progressInPercentage)
        output = {
            startDate,
            endDate,
            progressInPercentage,
        }

        onValueUpdate(output)


    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DateRange
                editableDateInputs={true}
                onChange={onChange}
                moveRangeOnFirstSelection={true}
                ranges={rangeDate}
            />
            {/* <BTButton sx={{ alignSelf: 'end', margin: '10px' }} color="primary" onClick={submit}>
                Save
            </BTButton> */}
        </div>

    )
}
export default BTTimeline