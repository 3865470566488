import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

type GradientIconProps = {
    Icon: React.ReactElement<SvgIconProps>;
    stopColors?: string[];
    hoverStopColors?: string[];
    defaultId: string;
    hoverId: string;
};

export const GradientIcon: React.FC<GradientIconProps> = ({ 
    Icon, 
    stopColors = ['rgba(200, 200, 200, 1)', 'rgba(150, 150, 150, 1)'],
    hoverStopColors  = ['#6CD1F1', '#269BE9'],
    defaultId, hoverId }) => {
    return (
        <React.Fragment>
            {/* Define the gradients */}
            <svg width="0" height="0">
                <defs>
                    <linearGradient id={defaultId} x1="0" y1="0" x2="1" y2="1">
                    <stop offset="0%" stopColor={stopColors[0]} />
                        <stop offset="100%" stopColor={stopColors[1]} />
                    </linearGradient>
                    {/* Define the hover gradient within an SVG */}
                    <linearGradient id={hoverId} x1="0" y1="0" x2="1" y2="1">
                        <stop offset="0%" stopColor={hoverStopColors[0]} />
                        <stop offset="100%" stopColor={hoverStopColors[1]} />
                    </linearGradient>
                </defs>
            </svg>
            {/* Apply the default gradient ID */}
            {React.cloneElement(Icon, {
                className: 'gradient-icon', // This class is used for the hover effect
                sx: {
                    fill: `url(#${defaultId})`,
                    ...Icon.props.sx,
                },
            })}
        </React.Fragment>
    );
};

export default GradientIcon;