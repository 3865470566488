import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { handleConditionOptionSelected, removeEmptyConditions } from '@common-reducers/AutomationThunks';
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import React, { useEffect, useState } from "react";
import { FaObjectGroup } from 'react-icons/fa';
import { MdOutlineGroups } from 'react-icons/md';
import { TbEqualDouble, TbNumbers } from 'react-icons/tb';
import { buttonStyle } from "./automations-helper";
import CustomDropdown from "./automation-dropdown";


const AutomationCondition = () => {
    const dispatch = useAppDispatch()
    const [text, setText] = useState('and only if');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOptionClicked, setOptionClicked] = useState<boolean>(false);
    const [isHover, setHoverState] = useState<boolean>(false);

    const ref = React.useRef();
    const hoverStyle = isHover ? { color: "#0072ff" } : {}
    const conditionsOptions = [
        { label: 'task is in group', name: 'task-is-in-group', icon: <FaObjectGroup fontSize="small" /> },
        { label: 'status is something', name: 'status-is-something', icon: <TbEqualDouble fontSize="small" /> },
        { label: 'person is someone', name: 'person-is-someone', icon: <MdOutlineGroups fontSize="small" /> },
        { label: 'number meets condition', name: 'number-meets-condition', icon: <TbNumbers fontSize="small" /> },

    ];

    const handleClose = () => {
        setAnchorEl(null);
        if (isOptionClicked) {
            setOptionClicked(true);
        } else {
            dispatch(removeEmptyConditions());
            setOptionClicked(false);
        }
    };

    const openDropdownMenu = () => {
        setAnchorEl(ref.current);
    };

    const handleOptionSelect = (option) => {
        dispatch(handleConditionOptionSelected({ name: option.name }));
        setOptionClicked(true);
    };

    const onHover = (option, show) => {
        if (show) {
            setText('and only if' + " " + option.label);
            setHoverState(true);
        } else {
            setText('and only if')
            setHoverState(false);
        }

    };

    useEffect(() => {
        openDropdownMenu();
    }, []);
    return (
        <Stack direction="row" justifyContent={"space-between"}>
            <Box ref={ref}>
                <Button sx={{ ...buttonStyle, ...hoverStyle }} onClick={openDropdownMenu} >{text}</Button>
            </Box>
            <CustomDropdown
                options={conditionsOptions}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={onHover}
            />
        </Stack>
    );
};

export default AutomationCondition;
