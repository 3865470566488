import React from "react";
import { CircularProgress } from "@mui/material";

import classes from "./provider-button.module.scss";

type Props = {
  ProviderIcon;
  clickHandler: (event) => void;
  value: string;
  loading?: boolean;
};

export default function ProviderButton({
  ProviderIcon,
  clickHandler,
  value,
  loading = false,  // default value for loading prop
}: Props) {
  return (
    <button onClick={clickHandler} className={classes.signup_with_provider} disabled={loading}>
      <span className={classes.providerIcon}>
        {loading ? (
          <CircularProgress size={21} />
        ) : (
          <ProviderIcon size={21} />
        )}
      </span>
      {value}
      {/* <span className={classes.chevronRightIcon}>
        <ChevronRight />
      </span> */}
    </button>
  );
}
