import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { RiCloseCircleFill } from "react-icons/ri";
import { CellProps } from "../cell-interface";
import classes from "./timeline-cell.module.scss";

import { namespace } from "@common-config/constants";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const TimelineCell = ({
  cellValue,
  taskId,
  onUpdate,
  columnId,
  groupId,
  readOnly,
  sx,

}: CellProps) => {
  const { t } = useTranslation(namespace);
  const [mouseOn, setMouseOn] = useState(false);
  const dispatch = useAppDispatch()
  const startDate = new Date(cellValue?.startDate);
  const endDate = new Date(cellValue?.endDate);
  const [yearInd, setYearInd] = useState<boolean>(false);
  const [yearString, setYearString] = useState<string>();
  const [progressInPercentage, setProgressInPercentage] = useState<number>();

  const diffTime = (endDate?.getTime() - startDate?.getTime());
  const daysForTask = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Formated dates
  const startDayFormated = moment(cellValue?.startDate).format("MMM Do");
  const endDayFormated = moment(cellValue?.endDate).format("MMM Do");
  // Formated progress percentage
  // Formated days to works
  let yearOfEnd, yearOfStart
  const today = new Date();

  const anchorId = `timeline_${taskId}_${columnId}`;
  function handleClick() {
    dispatch(
      pickerDialogActions.setPickerTypeAndId({
        pickerAnchorId: anchorId,
        pickerProps: {
          cellValue,
          columnId,
          taskId,
          groupId,
          type: 'timeline-picker'
        }
      })
    )
  }
  function handleClearValue(e) {
    e.stopPropagation()
    onUpdate(undefined);
  }
  useEffect(() => {
    yearOfStart = new Date(cellValue?.startDate)
    yearOfEnd = new Date(cellValue?.endDate)
    const progress = Math.abs(((today.getTime() - yearOfStart.getTime()) / (yearOfEnd.getTime() - yearOfStart.getTime())) * 100);
    setProgressInPercentage(Math.floor(progress))
    if (today.getTime() < new Date(yearOfStart).getTime()) {
      setProgressInPercentage(0)

    }


    if (yearOfStart.getFullYear() !== yearOfEnd.getFullYear()) {
      setYearString(yearOfStart.getFullYear().toString().substr(-2))
      setYearInd(true)
    } else {
      setYearString('')
      setYearInd(false)

    }
  }, [cellValue])

  return (
    <Box className={classes.dateContainer} onClick={handleClick} id={anchorId} sx={sx} >

      <div onMouseEnter={() => setMouseOn(true)} onMouseLeave={() => setMouseOn(false)} className={classes.timelineCell}>
        {isNaN(daysForTask) && (
          <div className={classes.timelineCellDataEmpty}>
            <span style={{ textAlign: 'center' }}>{mouseOn ? t('timelineCell.setDates') : '-'}</span>
          </div>
        )}
        {cellValue && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: `linear-gradient(90deg,  rgba(87,155,252,1)  ${progressInPercentage}%  , rgba(232,249,253,1) ${progressInPercentage}%)`
            }}
            className={classes.timelineCellData}>
            <span className={classes.valueText}
              style={{
                backgroundImage: `linear-gradient(90deg,  #fff ${progressInPercentage}%   , #809BA9 ${progressInPercentage}% )`, display: 'block',
                textOverflow: 'ellipsis',
                marginRight: '3px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}>
              {startDayFormated}{yearInd ? ',' + yearString : ''} - {endDayFormated} </span>
          </div>
        )}
        {!isNaN(daysForTask) &&
          <span style={{
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '12px',
            alignSelf: 'center',
            borderRadius: '4px',
            padding: '6px, 4px',
            border: '1px solid',
            borderColor: '#809BA9',
            margin: '0 3px',
            minWidth: '14px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            {!mouseOn && (daysForTask + "d")}

            {mouseOn && (
              <RiCloseCircleFill
                onClick={(e) => handleClearValue(e)}
              />
            )}
          </span>
        }
      </div>


    </Box>
  );
}

export default TimelineCell;