import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import {isCodeHotkey, isHotkey } from "is-hotkey";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo, useRef, useState
} from "react";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import classes from "./editor.module.scss";
import EditorHeader from "./EditorHeader";

import {
  createEditor,
  Descendant, Editor, Element as SlateElement, Transforms,
} from "slate";
import { withHistory } from "slate-history";

import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import { Box, CircularProgress, Portal } from "@mui/material";
import { Stack, style, styled } from "@mui/system";
import { getBoardUsersProfile } from "@common-reducers/BoardsSelectors";
import { Range } from 'slate';
import {
  CustomEditor, CustomElement, CustomText,
  EmptyText,
  MentionElement
} from "./customTypes";
import { Element, Leaf } from "./EditorElements";
import { setIsMentionsDialogOpen } from "@common-reducers/ExtraDataReducer";
import { withImages } from "./WithImages";
import { insertLink, isLinkActive, unwrapLink, withLinks } from "./WithLinks";
import { extraDataActions } from "@common-reducers/ExtraDataReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { isEqual } from "lodash";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";



const ActivityIndicatorContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: 60,
  borderRadius: 5,
  backgroundColor: '#f9f9f9e0',
}))



const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText | EmptyText;
  }
}

const LIST_TYPES = ["numbered-list", "bulleted-list"];
interface Props {
  addBorder: boolean;
  value: Descendant[];
  onValueUpdate: (value: Descendant[], isDraft: boolean) => void;
  onCancel?: () => void;
  onSave?: undefined | any;
  id?: string;
  isDraft?: boolean;
  parentItemId?: string;
}



export default function SlateEditor({ value, onValueUpdate, addBorder, onCancel, onSave, id, isDraft, parentItemId }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(namespace);
  // slate leaves
  const ref = useRef<HTMLDivElement | null>()
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editorInitialValue: Descendant[] = [
    { type: "paragraph", children: [{ text: "" }] },
  ];

  const isEditorUploading = useAppSelector((state) => state.ExtraDataReducer.isEditorUploading)

  const [editorValue, setEditorValue] = useState<Descendant[]>(value || editorInitialValue);



  useEffect(() => {
    setEditorValue(value || editorInitialValue);
    console.log(value);
  }, [id]);



  const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
      return element.type === 'mention' ? true : isInline(element)
    }

    editor.isVoid = element => {
      return element.type === 'mention' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
      return element.type === 'mention' || markableVoid(element)
    }

    return editor
  }

  function setEditorUploading(bool: boolean) {
    dispatch(extraDataActions.setEditorUploading(bool))
  }
  // const editor = useMemo(
  //   () =>
  //     withLinks(
  //       withImages(withHistory(withReact(createEditor())), setEditorUploading)
  //     ),
  //   []
  // );

  const editor = useMemo(
    () =>
      withMentions(
        withLinks(
          withImages(withHistory(withReact(createEditor())), setEditorUploading)
        )
      ),
    []
  );

  useEffect(() => {
    if (!isEqual(editor.children, value)) {
      // Reset the editor's content to the new value
      Editor.withoutNormalizing(editor, () => {
        editor.children = value || editorInitialValue;
        Transforms.deselect(editor);
      });
    }
  }, [value, editor]);

  const [headerState, setHeaderState] = useState({
    isBold: false,
    isItalic: false,
  });
  const [target, setTarget] = useState<Range | null>(null);
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(0);


  const boardUsersProfile = useAppSelector((state) => getBoardUsersProfile(state))

  const filteredMentionsList = useMemo(() => {
    return boardUsersProfile.filter(c => (c.fullName ?? '')?.toLowerCase().includes(search?.toLowerCase())
      || (c.firstName ?? '')?.toLowerCase().includes(search?.toLowerCase())
      || (c.lastName ?? '')?.toLowerCase().includes(search?.toLowerCase())
      || (c.email ?? '')?.toLowerCase().includes(search?.toLowerCase())
    );
  }, [search]); // Add search as a dependency


  interface MentionParams {
    character: string;
    characterId: string;
  }


  // Modify your insertMention function
  const insertMention = ({ character, characterId }: MentionParams) => {
    if (target) {
      // Remove the '@' and the partial name text
      Transforms.select(editor, target);
      Transforms.delete(editor);

      // Create the mention element
      const mention: MentionElement = {
        userId: characterId,
        type: 'mention', // Ensure that this type matches your schema
        character,
        children: [{ text: ' ' }],
      };

      // Insert the mention element into the editor
      Transforms.insertNodes(editor, mention);

      // Move the cursor after the mention and space
      Transforms.insertText(editor, ' ');

      Transforms.move(editor)
      // Close the mentions dropdown
      setStateBatch({
        target: null,
        search: '',
        index: 0,
      });
    }
  };

  // Helper function to batch state updates
  const setStateBatch = (newState) => {
    setTarget(newState.target);
    setSearch(newState.search);
    setIndex(newState.index);
  };


  // get current selection for block elements
  const isBlockActive = (editor: Editor, format: string) => {
    const [match]: any = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  };



  const handleSave = () => {
    let _editorValue
    if (parentItemId) {
      _editorValue = {
        parentItemId,
        editorValue,
      }
    }
    else {
      _editorValue = {
        editorValue,
      }
    }
    onValueUpdate(_editorValue, false);
    localStorage.removeItem(`draft_${id}`);
  };



  const handleBlock = (format: string, editor: Editor) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    // Transforms.unwrapNodes(editor, {
    //   match: (n) =>LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    //   split: true,
    // });

    const newProperties: any = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block: any = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  // function that handle mark for inline elements
  const handleMark = (format: string, editor: Editor) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      setHeaderState({ ...headerState, isBold: !isActive });
      Editor.removeMark(editor, format);
    } else {
      setHeaderState({ ...headerState, isBold: !isActive });
      Editor.addMark(editor, format, true);
    }
  };

  //get the current mark
  const isMarkActive = (editor: Editor, format: string) => {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const onKeyDown = useCallback(
    event => {
      if (target && filteredMentionsList.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= filteredMentionsList.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? filteredMentionsList.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            const mentionParams: MentionParams = getMentionString(filteredMentionsList[index])
            insertMention(mentionParams)
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
    },
    [filteredMentionsList, editor, index, target]
  )

  // handler for keyboard shortcuts
  function handleHotKeys(event: any) {
    onKeyDown(event)

    // Check for mod+enter separately
    if (isCodeHotkey('mod+enter', event)) {
      event.preventDefault();
      handleSave(); // Call the handleSave function when Ctrl+Enter is pressed
      return; // Exit the function early to prevent further processing
    }

    // Check for mod+z separately
    if (isCodeHotkey('mod+z', event)) {
      event.preventDefault();
      editor.undo();
      return;
    }

    // Check for mod+y separately
    if (isCodeHotkey('mod+y', event)) {
      event.preventDefault();
      editor.redo();
      return;
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event?.preventDefault();
        const mark = HOTKEYS[hotkey];
        handleMark(mark, editor);
      }
    }
    // const n: any = Editor.nodes(editor, {at:(n) =>n.});
    // console.log(n);
  }

  // add url
  const [myUrl, setMyUrl] = useState<string>("");
  function addLink() {
    const isActive = isLinkActive(editor);
    if (isActive) {
      unwrapLink(editor);
    } else {
      if (!myUrl) return;
      insertLink(editor, myUrl);
    }
  }

  //Add files
  const fileRef: any = useRef();
  function AddFiles() {
    fileRef.current.files = null;
    fileRef.current.click();
  }

  function handleFileChange(e: any) {
    const getData = () => "";
    const files = fileRef.current.files;
    const data: any = {
      files,
      getData,
    };
    editor.insertData(data);
    if (fileRef?.current) {
      fileRef.current.value = "";
    }
  }


  const onChange = (newValue) => {
    setEditorValue(newValue); // Keep the editor state updated

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      // Adjust path and offset for multi-line support
      const path = selection.anchor.path;
      const startOfLineRange = Editor.range(editor, { path, offset: 0 }, selection.anchor);
      const textToCaret = Editor.string(editor, startOfLineRange);
      const atSymbolIndex = textToCaret.lastIndexOf('@');

      let beforeAtPosition = undefined;
      if (atSymbolIndex !== -1) {
        beforeAtPosition = {
          anchor: { ...selection.anchor, offset: atSymbolIndex },
          focus: { ...selection.focus, offset: atSymbolIndex }
        };
      }

      const beforeRange = beforeAtPosition && Editor.range(editor, beforeAtPosition.anchor, selection.anchor);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/@(\w*)$/);

      if (beforeMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
      } else {
        setTarget(null);
      }
    }
    const initialValue = [
      { type: "paragraph", children: [{ text: "" }] },
    ];
    if (!isEqual(newValue, initialValue)) {
      localStorage.setItem(`draft_${id}`, JSON.stringify(newValue));
    }

  };

  const getMentionString = (profile) => {
    let mentionString = profile.email
    if (!profile.fullName && (profile.firstName || profile.lastName)) {
      mentionString = `${profile.firstName} ${profile.lastName}`
    }
    if (profile.fullName) {
      mentionString = profile.fullName
    }

    const mentionParmas: MentionParams = {
      character: mentionString,
      characterId: profile.userId,
    }

    return mentionParmas
  }

  useEffect(() => {
    const el = ref.current
    if (el && target && filteredMentionsList.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.scrollY + 24}px`
      el.style.left = `${rect.left + window.scrollX}px`
    }
  }, [filteredMentionsList.length, editor, index, search, target])


  const onInputChange = (value) => {
    setEditorValue(value);
    const initialValue = [
      { type: "paragraph", children: [{ text: "" }] },
    ];
    if (!isEqual(value, initialValue)) {
      localStorage.setItem(`draft_${id}`, JSON.stringify(value));
    }
  }

  return (
    <div className={classes.editor_container} onClick={(e) => e.stopPropagation()}>
      <div className={[classes.editor_wrapper, addBorder ? classes.blueGrayBorder : ''].join(' ')}>
        {isEditorUploading && (
          <ActivityIndicatorContainer>
            <CircularProgress />
          </ActivityIndicatorContainer>
        )}
        <div>
          <Slate
            editor={editor}
            value={editorValue}
            onChange={(value: any) => onChange(value)}>
            <div className={classes.editor_header}>
              <EditorHeader
                handleMark={handleMark}
                isMarkActive={isMarkActive}
                handleBlock={handleBlock}
                isBlockActive={isBlockActive}
                addLink={addLink}
                isLinkActive={isLinkActive}
                myUrl={myUrl}
                setMyUrl={setMyUrl}
              />
            </div>
            <div className={classes.editable_wrapper}>
              <Editable
                id="editable-id"
                style={{ minHeight: 80 }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                autoFocus
                onKeyDown={handleHotKeys}
              />
              <Portal>
                <div
                  ref={ref}
                  style={{
                    display: target && filteredMentionsList.length > 0 ? 'block' : 'none',
                    top: '-9999px',
                    left: '-9999px',
                    position: 'absolute',
                    zIndex: 99999,
                    padding: '3px',
                    background: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                  }}
                  data-cy="mentions-portal"
                >
                  {filteredMentionsList.map((mention, i) => {
                    const mentionParams = getMentionString(mention);
                    return (
                      <Stack
                        direction="row"
                        key={i}
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(setIsMentionsDialogOpen(true))
                          insertMention(mentionParams);
                          dispatch(setIsMentionsDialogOpen(false))
                          ReactEditor.focus(editor)
                        }}
                        style={{
                          padding: '1px 3px',
                          borderRadius: '3px',
                          background: i === index ? '#B4D5FF' : 'transparent',
                          gap: '10px',
                          alignItems: 'center',
                          margin: '5px',
                        }}
                      >
                        <PersonAvatar
                          size={32}
                          email={mention?.email}
                          profileImg={mention?.profileImg}
                          lastName={(mention?.lastName === 'undefined' || mention?.lastName === undefined) ? ' ' : mention?.lastName}
                          firstName={mention?.firstName}
                          toolTip={true}
                        />
                        <Stack direction='column'>
                          <span>{mentionParams?.character ?? mention?.email}</span>
                        </Stack>
                      </Stack>
                    )
                  })}
                </div>
              </Portal>
            </div>
          </Slate>
        </div>
      </div >

      <div style={{ marginBottom: "10px" }} className={[classes.editor_footer, !addBorder ? classes.editable_wrapper : ''].join(' ')}>
        <div className={classes.update_btn_wrapper}>
          <div>
            <button id="add-files" onClick={(e) => {
              e.stopPropagation()
              AddFiles()
            }} className={classes.update_btn}>
              <div className={classes.attatch_icon_wrap}>
                <AttachmentOutlinedIcon />
              </div>
              {t('slateEditor.addFiles')}
            </button>
            <input
              onChange={handleFileChange}
              ref={fileRef}
              type="file"
              multiple={true}
              hidden
            />
          </div>
          {value ? (
            <div className={classes.saveBtnWrap}>
              {!isDraft &&
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onCancel()
                  }}
                  className={classes.update_btn}
                  style={{
                    marginRight: 10,
                  }}
                >
                  {t('slateEditor.cancel')}
                </button>
              }

              <button
                id="update-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSave();
                }}
                className={classes.update_btn}
                style={{
                  background: 'linear-gradient(-90deg, #6dd1f1, #259ae9)',
                  color: '#fff',
                  border: 'none',
                  margin: 0,
                }}>
                {!isDraft ? t('slateEditor.update') : t('slateEditor.save')}
              </button>
            </div>
          ) : (
            <button onClick={(e) => {
              e.stopPropagation()
              handleSave()
            }} className={classes.update_btn}
              style={{
                background: 'linear-gradient(-90deg, #6dd1f1, #259ae9)',
                color: '#fff',
                border: 'none',
                margin: 0,
              }}>
              Save
            </button>
          )}
        </div>
      </div>
    </div >
  );
}
