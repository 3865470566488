import BTButton from '@common-components/bt-button/bt-button'
import RowStack from '@common-components/common/row-stack/RowStack'
import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box'
import { TaskColumnType } from '@common-components/tasks/columns-cells/cell-interface'
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs'
import AddColumnDialog from '@common-components/tasks/picker-dialog/pickers/add-column-dialog'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { importItemsActions } from '@common-reducers/ImportItemsReducer'
import { getIconByType } from '@common/components/tasks/columns-cells/column-cell-defs'
import { AddCircleOutlineOutlined, RemoveCircle } from '@mui/icons-material'
import { IconButton, Menu, Modal, Stack } from '@mui/material'
import { useState } from 'react'
import classes from './import-excel.module.scss'


type Props = {
    supportedColumns?: any,
}


export default function ManageColumnsForImportExcel({
    supportedColumns,

}: Props) {
    const dispatch = useAppDispatch()

    const newColumnList = useAppSelector((state) => state.ImportItemsReducer.newColumnList);
    const importDataMapping = useAppSelector((state) => state.ImportItemsReducer.importDataMapping);

    const [isManageColumnsModalOpen, setIsManageColumnsModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);


    const containerStyle = {
        background: 'white',
        width: '300px',
        minHeight: '350px',
        borderRadius: '8px',
        justifyContent: 'space-between',
    }
    const columnListWrapper = {
        border: '1px solid #DEDEDE',
        margin: '10px',
        borderRadius: '4px',
        padding: '11px 5px 5px 5px',
    }
    const inputTheme = {
        cursor: 'text',
        maxWidth: "160px",
        color: "var(--primary)",
        fontSize: '12px',
        border: '1px solid #53bdeb',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: "'Inter', sans-serif",
        fontWeight: '600',
        height: '20px',
        "& .MuiInputBase-input": {
            padding: "1px 4px 2px 0px",
            borderRadius: '4px',
        }
    }
    const textTheme = {
        cursor: " text",
        maxWidth: "160px",
        color: "var(--cell-name-color)",
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        outline: "none",
        backgroundColor: "transparent",
        fontSize: '12px',
        border: '1px solid transparent',
        borderRadius: '4px',
        overflow: "hidden",
        fontFamily: "'Inter', sans-serif",
        padding: "3px",
        lineHeight: '4px',
        "&:hover": {
            border: " 1px dashed var(--text-color);",
        }
    }
    const handleOpenMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const getColumnTitleByType = (type: string) => {
        return columnTypeList.find(columnType => columnType.type === type)?.title
    }

    const createNewColumnId = () => {
        const prefix = 'NEWCOLUMN ';
        const randomString = Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 10);
        const result = prefix + randomString.substring(0, 14);
        return result;
    }

    const addColumn = (columnDescriptor: TaskColumnType<any>) => {
        dispatch(importItemsActions.setNewColumnList([...newColumnList, { _id: createNewColumnId(), type: columnDescriptor.type, title: `New ${columnDescriptor.title}` }]))
        handleCloseMenu()
    }


    const columnRow = (column, i) => <RowStack key={i} sx={{
        margin: '0 0 11px 4px',
        gap: '8px',
        height: '20px',
    }}>
        {getIconByType(column?.type)}
        {column?.title}
        {` (${getColumnTitleByType(column?.type)})`}
    </RowStack>


    const handleRemoveNewColumn = (newColumn) => {
        dispatch(importItemsActions.setNewColumnList(newColumnList.filter(column => column !== newColumn)))

        dispatch(importItemsActions.setImportDataMapping({
            ...Object.fromEntries(Object.entries(importDataMapping).filter(([key, value]) => value !== newColumn._id))
        }))
    }

    const newColumnRow = (newColumn, i) => <RowStack key={i} sx={{
        margin: '0 0 11px 4px',
        alignItems: 'normal',
        gap: '8px',
        height: '20px',
        position: 'relative',
    }}>
        {getIconByType(newColumn?.type)}
        {/* {newColumn?.title} */}
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '130px', }}>
            <BtEditableBox
                text={newColumn?.title}
                inputSx={inputTheme}
                boxSx={textTheme}
                shouldAutoFlex={false}
                // shouldStretch={false}
                placeholder={newColumn.title}
                onChange={(value) => {
                    dispatch(importItemsActions.setNewColumnList(newColumnList.map(column => column === newColumn ? { ...column, title: value } : column)))
                }} />
        </div>
        {` (${getColumnTitleByType(newColumn?.type)})`}
        <IconButton onClick={() => handleRemoveNewColumn(newColumn)} sx={{ height: '20px', position: 'absolute', right: 0, p: '3px', }}>
            <RemoveCircle sx={{ color: 'red', width: '15px' }} />
        </IconButton>
    </RowStack >


    return (
        <>
            <div
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <BTButton
                    sx={{
                        marginTop: '20px',
                        padding: "8px 14px",
                    }}
                    onClick={() => setIsManageColumnsModalOpen(true)}>
                    Manage Columns
                </BTButton>
            </div>

            <Modal
                open={isManageColumnsModalOpen}
                onClose={() => setIsManageColumnsModalOpen(false)}
                sx={{
                    "margin": "auto",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                }}
            >
                <Stack sx={containerStyle}>
                    <Stack sx={columnListWrapper}>
                        {supportedColumns?.map((column, i) =>
                            columnRow(column, i)
                        )}
                        {newColumnList.map((newColumn, i) =>
                            newColumnRow(newColumn, i)
                        )}
                    </Stack>

                    <RowStack
                        className={classes.AddAndDoneBtnWrap}
                    >
                        {/* in menu buttons */}
                        <button
                            style={{ display: 'flex' }}
                            className={classes.backBtn}
                            onClick={handleOpenMenu}>
                            <AddCircleOutlineOutlined /> Add
                        </button>
                        <button
                            className={classes.nextBtn}
                            onClick={() => setIsManageColumnsModalOpen(false)}>
                            Done!
                        </button>
                    </RowStack>

                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                borderRadius: '6px',
                                boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
                            },
                        }}
                        MenuListProps={{
                            sx: {
                                padding: 0, // Attempting to target the padding directly
                                // If the above doesn't work, you might need a more specific target using global selectors
                                '& .MuiList-root': {
                                    padding: 0, // This is a more targeted approach
                                },
                            },
                        }}
                    >
                        <AddColumnDialog addNewColumnToImportModule={addColumn} />
                    </Menu>
                </Stack>
            </Modal>
        </>
    )
}