import { TaskColumnOption } from "@common-models/task-column-option";
import { DragIndicator } from "@mui/icons-material";
import { Box, Button, ClickAwayListener, IconButton, Popper, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { NullableId } from "@common/types/interface";
import { isTempId } from "@common-reducers/DBServiceThunks";
import { columnOptionIsInUsed } from "@common-reducers/TaskSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import randomcolor from 'randomcolor';
import randomstring from "randomstring";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, DraggableProvided, DragStart, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { MdAdd, MdClose } from "react-icons/md";
import LabelAccentPicker from "../../picker-dialog/pickers/labelAccentPicker";
import classes from "./edit-labels.module.scss";


export interface EditableOptionsProps {
  options: TaskColumnOption[];
  onChange: (options: TaskColumnOption[]) => void;
  onCancel: (_pickerOptions: any) => void
  canEdit?: boolean;
  defaultOptionId?: string;
  isComplexity?: boolean;
  onSave: () => void;
  boardId?: string;
  columnId?: string;
  saveString?: string;
  newLabelString?: string;
  defaultLabelTooltipString?: string;
  labelPlaceholderString?: string;
  complexityPlaceholderString?: string;
  defaultLabelRemoveTooltipString?: string;
  removeInUseOptionTooltipString?: string;
}

export default function EditableOptions({
  options,
  canEdit,
  defaultOptionId,
  onCancel,
  onChange,
  isComplexity,
  onSave,
  boardId,
  columnId,
  saveString,
  newLabelString,
  defaultLabelTooltipString,
  labelPlaceholderString,
  complexityPlaceholderString,
  defaultLabelRemoveTooltipString,
  removeInUseOptionTooltipString
}: EditableOptionsProps) {
  const btnRef = useRef()

  const [isLabelAccentPickerOpen, setIsLabelAccentPickerOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentPickerIndex, setCurrentPickerIndex] = useState<number>(0);
  const [localOptions, setLocalOptions] = useState<TaskColumnOption[]>(options ?? []);
  const [draggedItem, setDraggedItem] = useState<TaskColumnOption>();
  const isOptionInUse = useAppSelector((state) => columnOptionIsInUsed(state, { columnId: columnId, boardId: boardId }))
  const pickerOptions = useMemo(() => (localOptions), [localOptions])

  useEffect(() => {
    if (!isLabelAccentPickerOpen) {
      applyChanges()
    }

  }, [isLabelAccentPickerOpen])



  //add new label
  function addNewLabel() {
    const hex = randomcolor()
    const maxOrder = pickerOptions.reduce((maxOrder, option) => Math.max(maxOrder, option.order ?? 0), -1);
    const unique_id: NullableId = randomstring.generate(16);
    const newLabel: TaskColumnOption = {
      _id: unique_id,
      label: "",
      bgcolor: hex,
      order: maxOrder + 1,
      complexity: isComplexity ? 0 : undefined,

    };
    setLocalOptions([...pickerOptions, newLabel])
  }

  // onChange function for input label
  function editNewLabel(index: number, label: string) {
    const clonedOptionsList = [...pickerOptions];
    clonedOptionsList[index] = {
      ...clonedOptionsList[index],
      label
    }
    setLocalOptions(clonedOptionsList ?? []);
  }


  function editNewComplexity(index: number, complexity: number) {
    const clonedOptionsList = [...pickerOptions];
    complexity = isNaN(complexity) ? 0 : complexity;
    clonedOptionsList[index] = {
      ...clonedOptionsList[index],
      complexity
    }

    setLocalOptions(clonedOptionsList ?? []);
  }


  //funtion that opens the label accent picker
  function openLabelAccentPicker(event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, targetEL, isDefaultLabel: boolean) {
    event.stopPropagation();

    //close if use clicks on the same btn
    if (isDefaultLabel) { return; }
    if (targetEL !== anchorEl) {
      setAnchorEl(targetEL)
      setIsLabelAccentPickerOpen(true)
    } else {
      setIsLabelAccentPickerOpen(false)
      setAnchorEl(null)
    }

    setCurrentPickerIndex(index)
  }

  function editOptionColor(index: number, bgcolor: string) {
    const clonedOptionsList = [...pickerOptions];
    clonedOptionsList[index] = {
      ...clonedOptionsList[index],
      bgcolor
    }
    setLocalOptions(clonedOptionsList);
    setIsLabelAccentPickerOpen(false)
  }

  //function that deletes / removes a label
  function removeLabel(index: number, defaultLabel) {
    if (defaultLabel) return;
    const clonedOptionsList = [...pickerOptions];

    clonedOptionsList.splice(index, 1);
    setLocalOptions(clonedOptionsList)
  }

  //function that applys all the changes made
  function applyChanges() {
    const _pickerOptions = pickerOptions.map((option, index) => ({
      ...option,
      _id: isTempId(option._id) ? undefined : option._id,
      order: pickerOptions.indexOf(option),
    }))
    onChange(_pickerOptions);
    // setLocalOptions(options);
  }


  function handleClickAway() {
    const _pickerOptions = pickerOptions.map((option, index) => ({
      ...option,
      _id: isTempId(option._id) ? undefined : option._id,
      order: pickerOptions.indexOf(option),
    }))
    setAnchorEl(null)
    setCurrentPickerIndex(0)
    setIsLabelAccentPickerOpen(false)
    onCancel(_pickerOptions);
  }


  const direction = useAppSelector((state) => state.UserReducer.direction);

  return (
    <div dir={direction} className={classes.edit_label_wrapper} style={{
      width: isComplexity ? '290px' : '200px',
      boxSizing: 'border-box',
      flexDirection: 'row',
    }}>
      <div className={classes.mapped_labels}>
        {/* render custom labels */}
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper id={`popper-id-${currentPickerIndex}`} open={isLabelAccentPickerOpen} anchorEl={anchorEl} style={{ zIndex: 999999999999 }}>
            <LabelAccentPicker pickColor={(accent) => editOptionColor(currentPickerIndex, accent)} />
          </Popper>
        </ClickAwayListener>
        <DragDropContext
          onBeforeDragStart={(initial: DragStart) => {
            setDraggedItem(pickerOptions[initial.source.index])
          }}
          onDragEnd={(result: DropResult, provided: ResponderProvided) => {
            setDraggedItem(undefined)

            if (!result.destination) {
              return;
            }
            const [_, optionId] = result.draggableId.split("pickerOption_")
            const option = pickerOptions.find((option) => {
              return option._id === optionId
            })
            const finalOrder = [...pickerOptions
              .filter(option => {
                return option._id !== optionId
              })
            ]

            finalOrder.splice(result.destination.index, 0, option)

            setLocalOptions(finalOrder)
            // dispatch(DBTaskColumnOptionThunks.patchMany(finalOrder.map((option, index) => ({
            //   id: option._id,
            //   changes: {
            //     ...option,
            //     order: index
            //   }
            // }))))
          }}>
          <Droppable droppableId='picker' direction="vertical"
            renderClone={(provided, snapshot, rubric) => {
              return <Stack direction='row'
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={{ ...provided.draggableProps.style, zIndex: 9999 }}
                sx={{ boxSizing: 'border-box' }}
              >

                <RenderEditLabel
                  btnRef={btnRef}
                  item={draggedItem}
                  index={0}
                  openLabelAccentPicker={openLabelAccentPicker}
                  editNewLabel={editNewLabel}
                  editOptionColor={editOptionColor}
                  removeLabel={removeLabel}
                  handleProp={provided}
                  isComplexity={isComplexity}
                  editNewComplexity={editNewComplexity}
                  applyChanges={applyChanges}
                  columnOptionsInUse={isOptionInUse}

                />
              </Stack>
            }}>
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {pickerOptions
                  .map((item, index) => (
                    <Draggable
                      key={`pickerOption_${item._id}`}
                      draggableId={`pickerOption_${item._id}`}
                      index={index}>
                      {(draggableProvided) => (
                        <Stack direction='row' ref={draggableProvided.innerRef} key={`${item._id}_${index}`} {...draggableProvided.draggableProps} sx={{ boxSizing: 'border-box' }}>

                          <RenderEditLabel
                            btnRef={btnRef}
                            item={item}
                            index={index}
                            openLabelAccentPicker={openLabelAccentPicker}
                            editNewLabel={editNewLabel}
                            editOptionColor={editOptionColor}
                            removeLabel={removeLabel}
                            handleProp={draggableProvided}
                            isComplexity={isComplexity}
                            editNewComplexity={editNewComplexity}
                            applyChanges={applyChanges}
                            pickerOptionsLength={pickerOptions.length}
                            columnOptionsInUse={isOptionInUse}
                            defaultLabelTooltipString={defaultLabelTooltipString}
                            labelPlaceholderString={labelPlaceholderString}
                            complexityPlaceholderString={complexityPlaceholderString}
                            defaultLabelRemoveTooltipString={defaultLabelRemoveTooltipString}
                            removeInUseOptionTooltipString={removeInUseOptionTooltipString}
                          />
                        </Stack>
                      )
                      }

                    </Draggable>
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>


        <Button
          onClick={addNewLabel}
          startIcon={<MdAdd size={14} />}
          className={classes.add_new_label_button}
          style={{
            color: 'var(--option-picker-button)',
            margin: '8px 0',
            fontWeight: "600",
            fontSize: 12,
            paddingBlock: 4,
            fontFamily: "'Inter', 'Assistant', sans-serif",
            boxSizing: 'border-box',
          }}
          sx={{ ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' } }}

        >
          {newLabelString}
        </Button>
      </div>

      <div className={classes.apply_changes_button_wrap}>
        <Button
          onClick={() => {
            onSave()
          }}
          disabled={!canEdit}
          className={classes.apply_changes_button}
          style={{
            textTransform: "capitalize",
            marginTop: 5,
            boxShadow: "none",
            fontSize: 14,
            fontWeight: "600",
            fontFamily: "'Inter', 'Assistant', sans-serif",
            borderRadius: 4,
            color: 'var(--option-picker-button)',
          }}
        >
          {saveString}
        </Button>
      </div>
    </div>
  );
}

interface RenderEditLabelProps {
  btnRef: RefObject<HTMLDivElement>,
  item: TaskColumnOption,
  index: number,
  openLabelAccentPicker: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, target: EventTarget & HTMLDivElement, isDefaultLabel: boolean) => void,
  editNewLabel: (index: number, value: string) => void,
  removeLabel: (index: number, isDefaultLabel: boolean) => void,
  editOptionColor?: (index: number, color: string) => void,
  handleProp: DraggableProvided,
  isComplexity: boolean,
  editNewComplexity: (index: number, value: number) => void,
  applyChanges: () => void,
  pickerOptionsLength?: number
  columnOptionsInUse: string[]
  defaultLabelTooltipString?: string
  labelPlaceholderString?: string
  complexityPlaceholderString?: string
  defaultLabelRemoveTooltipString?: string
  removeInUseOptionTooltipString?: string
}

const RenderEditLabel = ({
  btnRef,
  item,
  index,
  openLabelAccentPicker,
  editNewLabel,
  removeLabel,
  editOptionColor,
  handleProp,
  isComplexity,
  editNewComplexity,
  applyChanges,
  pickerOptionsLength,
  columnOptionsInUse,
  defaultLabelTooltipString,
  labelPlaceholderString,
  complexityPlaceholderString,
  defaultLabelRemoveTooltipString,
  removeInUseOptionTooltipString
}: RenderEditLabelProps) => {
  const labelEditInputRef = useRef(null);
  useEffect(() => {
    if (pickerOptionsLength === index + 1) {
      labelEditInputRef.current?.focus();
    }
  }, [pickerOptionsLength, index])

  return (
    <Stack direction='row' sx={{
      width: isComplexity ? '280px' : '256px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginInlineStart: '20px',
    }} className={classes.label_wrapper}>
      {/* DRAG HANDLE */}
      <Button
        startIcon={<DragIndicator />}
        style={{ minWidth: 0, padding: 0 }}
        className={classes.drag_handle}
        sx={{
          backgroundColor: 'transparent !important',
          ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' }
        }}

        {...handleProp.dragHandleProps} />
      {/* INPUT */}
      <div ref={btnRef} className={classes.text_field_wrap}>
        <Tooltip
          id={`tooltip-accentLabel-${item.label}-${index}`}
          title={item.isDefaultLabel ? defaultLabelTooltipString : ""}
          placement="top"
          PopperProps={{
            style: { zIndex: 9999 }
          }}
          arrow>
          <div
            id={`div-${item.label}-${index}`}
            onClick={(e) => openLabelAccentPicker(e, index, e.currentTarget, item.isDefaultLabel)}
            className={classes.label_accent}
            style={{
              backgroundColor: item.bgcolor,
              cursor: item.isDefaultLabel ? 'default' : 'pointer'
            }}
          />
        </Tooltip>
        <input
          ref={labelEditInputRef}
          className={classes.label_text_field}
          onChange={({ target }) => editNewLabel(index, target.value)}
          onBlur={applyChanges}
          value={item.label}
          type="text"
          placeholder={labelPlaceholderString}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              (event.target as HTMLInputElement).blur()
            }
          }}
        />
        {isComplexity &&
          <input
            ref={labelEditInputRef}
            className={classes.label_text_field}
            style={{
              width: '55px',
              marginInlineEnd: '10px',
              boxSizing: 'border-box',

            }}
            onChange={({ target }) => editNewComplexity(index, parseInt(target.value))}
            value={item.complexity}
            type='number'
            placeholder={complexityPlaceholderString}
            onBlur={applyChanges}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                (event.target as HTMLInputElement).blur()
              }
            }}
          />
        }
      </div>
      {/* REMOVE BUTTON */}
      <div className={classes.remove_label_wrapper}>
        <Tooltip
          id={`tooltip-accentLabel-${item.label}-${index}`}
          title={item.isDefaultLabel ? defaultLabelRemoveTooltipString :
            columnOptionsInUse.includes(item._id) ? removeInUseOptionTooltipString : ""}
          placement="top"
          PopperProps={{
            style: { zIndex: 9999 }
          }}
          arrow
          disableInteractive
        >
          <span style={{ cursor: 'not-allowed', display: 'inline-block' }}>
            <IconButton
              size="small"
              disabled={columnOptionsInUse.includes(item._id) || item.isDefaultLabel}
              onClick={() => removeLabel(index, item.isDefaultLabel)}

            >
              <MdClose />
            </IconButton>
          </span>

        </Tooltip>



      </div>
    </Stack>
  );
}



