import { InputBase } from "@mui/material";
import { DefaultComponentProps, OverridableTypeMap } from "@mui/material/OverridableComponent";
import { Box, Stack, SxProps } from "@mui/system";
import { memo, useEffect, useRef, useState } from "react";
import OverflowTooltip from "../columns-cells/text-cell/overflow-tooltip";

export interface Props {
  key?: string;
  text: any;
  onValueUpdate?: (value: any) => void;
  onChange?: (value: any) => void;
  onEditModeChange?: (isEditing: boolean) => void;
  onEnterPress?: (value: any) => void;
  placeholder?: string;
  boxSx?: SxProps;
  inputSx?: SxProps;
  className?: string;
  preventClickEdit?: boolean;
  forceEditMode?: boolean;
  shouldAutoFlex?: boolean;
  inputProps?: any;
  isMultiline?: boolean;
  rowsMax?: number;
  enableOverflowTooltip?: boolean;
}

const
  BtEditableBox = ({
    text,
    onValueUpdate,
    onEditModeChange,
    onEnterPress,
    placeholder,
    boxSx,
    inputSx,
    className,
    preventClickEdit,
    forceEditMode,
    shouldAutoFlex = true,
    shouldStretch = true,
    disabled,
    inputProps,
    onChange,
    isMultiline = false,
    rowsMax = 1,
    enableOverflowTooltip = true,
    ...restOfProps }: Props & DefaultComponentProps<OverridableTypeMap>) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string | undefined>();
    //const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    const inputRef = useRef(null);

    // const checkOverflow = () => {
    //   const el = textRef.current;
    //   if (el) {
    //     setIsOverflowing(el.scrollWidth > el.clientWidth);
    //   }
    // };

    // useLayoutEffect(() => {
    //   if (enableOverflowTooltip) {


    //     checkOverflow();

    //     window.addEventListener("resize", checkOverflow);

    //     return () => {
    //       window.removeEventListener("resize", checkOverflow);
    //     };
    //   }
    // }, [value, enableOverflowTooltip]);

    function updateText(e) {
      setIsEditing(false);
      if (onEditModeChange) {
        onEditModeChange(false);
      }
      if (value === "") {
        setValue(text);
      }

      if (value !== text && onValueUpdate) {
        onValueUpdate(value ?? "")
      }
    }

    function setEditMode(e, editMode) {
      e.stopPropagation();

      setIsEditing(editMode)
      if (onEditModeChange) {
        onEditModeChange(editMode);
      }
    }

    function handleTextChange({ target }: any) {
      const value = target.value ?? "";
      setValue(value);
      if (onChange) {
        onChange(value)
      }
    }

    useEffect(() => {
      setValue(text)
    }, [text])


    useEffect(() => {
      if (isMultiline && isEditing) {
        const textarea = inputRef.current;
        if (textarea) {
          textarea.focus();
          const length = textarea.value.length;
          textarea.setSelectionRange(length, length);
        }
      }
    }, [isEditing, isMultiline]);

    return (
      <Stack sx={{
        flex: shouldAutoFlex ? 'auto' : '',
        // overflow: "hidden",
        width: shouldStretch ? '100%' : null,
        flexShrink: shouldStretch ? 1 : 0,
      }}
        direction={"row"} {...restOfProps}>

        {(forceEditMode || isEditing) ?
          (
            <InputBase
              inputRef={inputRef}
              rowsmax={rowsMax}
              value={value ?? text ?? ""}
              multiline={isMultiline}

              disabled={disabled}
              onBlur={updateText}
              onChange={handleTextChange}
              autoFocus={isEditing || forceEditMode}
              placeholder={placeholder}
              sx={{
                p: 0, background: '#fff', '.MuiInputBase-input': {
                  unicodeBidi: 'plaintext'
                }, ...inputSx
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  const target = e.target as HTMLInputElement;
                  target.blur();
                }
              }}
              {...inputProps}


            />
          ) :
          //<Tooltip placement="top" arrow title={isOverflowing ? value : ""}>
          <OverflowTooltip value={value} styling={boxSx}>
            <Box id="editable" ref={textRef} sx={{
              unicodeBidi: 'plaintext', ...boxSx
            }}
              onClick={(e) => !preventClickEdit ? setEditMode(e, true) : ""}>
              {value ?? text ?? ""}
            </Box>
          </OverflowTooltip>
          // </Tooltip>
        }
      </Stack >
    );
  }

export default memo(BtEditableBox)