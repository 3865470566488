import { NodeType } from '@common-models/bot-node';
import { ContentCopy as CopyIcon, DeleteOutline as DeleteIcon, EditOutlined as EditIcon, FlagOutlined as FlagIcon } from '@mui/icons-material';
import { Menu, MenuItem, Modal } from '@mui/material';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { copyNodeThunk, setNewStartNodeThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { useReactFlow } from 'reactflow';
import BTChatbotModal from './bt-chatbot-modal';
import { nodeTypes } from '../bt-nodes/bt-nodes-def';
import { patchDeletedEntities } from '@common/reducers/ChatbotReducer';

type Props = {
    type: NodeType;
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
    id: string;
}

export default function NodeMenu({ type, open, onClose, anchorEl, id }: Props) {
    const dispatch = useAppDispatch()

    const { setNodes, setEdges } = useReactFlow();

    const data = useAppSelector(state => getNodeDataByNodeId(state, id))

    const [modalOpen, setModalOpen] = useState(false);


    const modalType = () => {
        switch (type) {
            case 'condition':
                return 'setCondition'
            case 'question':
                return 'setQuestion'
            case 'webhook':
                return 'setWebhook'
            case 'googleSheets':
                return 'setGoogleSheet'
            case 'status':
                return 'setStatus'
            case 'chatGPT':
                return 'setChatGPT'
            default:
                return 'setSettings'
        }
    }

    const copyNode = async () => {
        const resultAction = await dispatch(copyNodeThunk({ id, type }))


        if (copyNodeThunk.fulfilled.match(resultAction)) {
            setNodes((nodes) => [...nodes, resultAction.payload]);
        }

        onClose()
    }


    const deleteNode = () => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        setEdges((edges) => edges.filter((edge) => {
            if (edge.source !== id && edge.target !== id) {
                return true
            } else {
                dispatch(patchDeletedEntities([edge.id, 'edge']))
                return false
            }
        }));
        dispatch(patchDeletedEntities([id, 'node']))
        onClose()
    };


    const handleSetStartNode = () => {
        if (data?.isStartNode) return
        else {
            dispatch(setNewStartNodeThunk({
                id,
                setNodes,
            }))
        }
        onClose()
    }

    const handleOpenModal = () => {
        onClose()
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }


    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    transform: 'translate(12px,-20px)', // Adjust this value as needed to shift the menu
                    '& .MuiList-root': {
                        padding: 0,
                    },
                    '& .MuiPaper-root': {
                        width: '150px',
                        padding: '0px 15px',
                        backgroundColor: 'white',
                        boxShadow: 'rgba(130, 130, 130, 0.15) 12px 10px 45px -12px',
                        color: '#212121;',
                    },
                    '& .MuiMenuItem-root': {
                        padding: '10px 0px',
                        borderBottom: '1px solid rgb(232, 234, 242)',
                        fontWeight: 600,
                        fontSize: '12px',
                        '&:last-child': {
                            borderBottom: 'none',
                        },
                        '&:hover': {
                            color: '#23a455',
                            backgroundColor: 'transparent',
                        },
                    },
                }}>
                {
                    nodeTypes?.[type]?.isEditable &&
                    <MenuItem onClick={handleOpenModal} disableRipple>
                        <EditIcon style={{ marginRight: '10px' }} />Edit
                    </MenuItem>
                }
                < MenuItem onClick={deleteNode} disableRipple>
                    <DeleteIcon style={{ marginRight: '10px' }} />Delete
                </MenuItem >

                <MenuItem onClick={copyNode} disableRipple>
                    <CopyIcon style={{ marginRight: '10px' }} />Copy
                </MenuItem>

                <MenuItem onClick={handleSetStartNode} disableRipple>
                    <FlagIcon style={{ marginRight: '10px' }} />Set start node</MenuItem>
            </Menu >
            <Modal
                id={modalOpen ? 'simple-popover' : undefined}
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{
                    "margin": "auto",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                }}
            >
                <BTChatbotModal type={modalType()} onClose={handleCloseModal} id={id} />
            </Modal>
        </>
    )
}