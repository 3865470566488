import { getHandleId } from '@common-services/utils';
import { Handle, Position } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import NodeContainer from '../node-container';
import classes from "./google-sheets-node.module.scss";
import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';



export default function GoogleSheetsNode({ id, data }) {

    const nodeColor = nodeTypes.googleSheets.color



    return (
        <>
            <Handle type="target" position={Position.Left} id={getHandleId(data.handles, 'target', 0)}
                style={{ backgroundColor: nodeColor, zIndex: 3, left: '-2px !important' }} />
            <NodeContainer type="googleSheets" id={id} />
            <CustomSourceHandle type="source" position={Position.Right} id={getHandleId(data.handles, 'source', 0)}
                className={classes.y_handle} connectioncount={1} />
            <CustomSourceHandle type="source" position={Position.Right} id={getHandleId(data.handles, 'source', 1)}
                className={classes.n_handle} connectioncount={1} />
        </>
    )
}