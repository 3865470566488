import React, { useState } from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import { IconButton } from "@mui/material";
import { RemoveCircle, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { title, secondaryTitleWithMaybeLink } from './utils';
import { modalTextStyle } from '@common-components/bt-chatbot/bot-components/bt-chatbot-modal';


type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

const InputTypesArray = ['Empty', 'Text', 'Number', 'Boolean', 'Date', 'Any'];

export default function OtherInputParameters({ localChatGPTState, setLocalChatGPTState }: Props) {
    const [isSelectInputTypeMenuOpen, setIsSelectInputTypeMenuOpen] = useState<number | null>(null);

    const addInputParameterInput = () => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                otherInputParameters: [...(prev?.advanced?.otherInputParameters ?? []), { name: '', type: '', value: '' }]
            }
        }));
    };

    const handleClickRemoveInputParameterInput = (index: number) => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                otherInputParameters: prev?.advanced?.otherInputParameters.filter((_, i) => i !== index)
            }
        }));
    };

    const toggleSelectInputTypeMenu = (e: React.MouseEvent<HTMLDivElement>, i: number) => {
        e.stopPropagation();
        setIsSelectInputTypeMenuOpen(isSelectInputTypeMenuOpen !== null ? null : i);
    };

    const handleCloseSelectInputTypeMenu = (inputIndex: number, inputTypeIndex: number) => {
        setLocalChatGPTState((prev: any) => ({
            ...prev,
            advanced: {
                ...prev.advanced,
                otherInputParameters: prev?.advanced?.otherInputParameters.map((oIP, i) => (
                    i === inputIndex ? { ...oIP, type: InputTypesArray[inputTypeIndex] } : oIP
                ))
            }
        }));
        setIsSelectInputTypeMenuOpen(null);
    };

    const otherInputParameterInputUI = (otherIP, index) => <>
        <div key={`inputP_${index}`} style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            {title(`Input Parameter ${index + 1}`, { marginTop: '20px', })}
            <IconButton sx={{
                zIndex: 2,
                width: '30px',
                height: '30px',
                borderRadius: '8px',
                backgroundColor: '#FEE7E7',
                color: 'red',
                ".MuiSvgIcon-root": {
                    width: '20px',
                    height: '20px',
                }
            }} onClick={() => handleClickRemoveInputParameterInput(index)}><RemoveCircle /></IconButton>
        </div>

        {title('Parameter Name', { marginTop: '20px', })}
        <BotInputField
            value={otherIP.name ?? ''}
            onChange={(newValue) => {
                setLocalChatGPTState({
                    ...localChatGPTState,
                    advanced: {
                        ...localChatGPTState.advanced,
                        otherInputParameters: localChatGPTState?.advanced?.otherInputParameters.map((oIP, i) => {
                            if (i === index) {
                                return {
                                    ...oIP,
                                    name: newValue
                                }
                            }
                            return oIP
                        })
                    }
                })
            }}
        />

        {title('Input Type', { marginTop: '20px', })}
        <BotInputField
            onClick={(e) => toggleSelectInputTypeMenu(e, index)}
            value={otherIP.type}
            sx={{
                cursor: 'pointer',

            }}
            endAdornment={
                <>
                    <IconButton disableRipple sx={{
                        zIndex: 2,
                        width: '30px',
                        color: '#666666',
                        ".MuiSvgIcon-root": {
                            width: '20px',
                            height: '20px',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        }
                    }}>
                        {!isSelectInputTypeMenuOpen && <KeyboardArrowDown />}
                        {!!isSelectInputTypeMenuOpen && <KeyboardArrowUp />}
                    </IconButton>
                </>
            }
        />
        {isSelectInputTypeMenuOpen === index &&
            <>
                {InputTypesArray?.map((role, inputTypeIndex) => (
                    <BotInputField
                        key={`it_${inputTypeIndex}`}
                        value={role}
                        onClick={() => handleCloseSelectInputTypeMenu(index, inputTypeIndex)}
                        sx={{
                            ".MuiOutlinedInput-input": {
                                ...modalTextStyle,
                                zIndex: 3,
                                lineHeight: '19px',
                                cursor: 'pointer',
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                                backgroundColor: 'rgb(245, 246, 250)',
                                cursor: 'pointer'
                            },
                            "&: hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    backgroundColor: 'rgb(204, 208, 222)',
                                },
                            },
                        }} />
                ))}
            </>
        }

        {title('Parameter Value', { marginTop: '20px', })}
        <BotInputField
            value={otherIP.value ?? ''}
            onChange={(newValue) => {
                setLocalChatGPTState({
                    ...localChatGPTState,
                    advanced: {
                        ...localChatGPTState.advanced,
                        otherInputParameters: localChatGPTState?.advanced?.otherInputParameters.map((oIP, i) => {
                            if (i === index) {
                                return {
                                    ...oIP,
                                    value: newValue
                                }
                            }
                            return oIP
                        })
                    }
                })
            }}
        />

    </>

    const addInputParameterButton = () => {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
            }}>
                <BTbotbutton styleType='dashed' onClick={addInputParameterInput}>Add input parameter</BTbotbutton>
            </div>
        )
    }

    return (
        <>
            {title('Other Input Parameters', { marginTop: '20px', })}
            <div>
                {(localChatGPTState?.advanced?.otherInputParameters ?? []).map((otherIP, index) => {
                    return otherInputParameterInputUI(otherIP, index)
                })}

                {addInputParameterButton()}

                {secondaryTitleWithMaybeLink('Additional parameters to use. For a complete list of parameters, see the ', {}, 'OpenAI API Reference', 'https://platform.openai.com/docs/api-reference/chat/create')}
            </div>
        </>
    );
}
