import { ManageAccountsOutlined, MoreVert } from '@mui/icons-material';
import { AvatarGroup, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { getTaskSubscribedUsersList } from '@common-reducers/ExtraDataThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { useMemo, useState } from 'react';
import PersonAvatar from '../person-avatar/person-avatar';
import ManageSubscribersModal from './manage-subscribers-modal';
import classes from "./task-subscribers-dialog.module.scss";




export default function TaskSubscribersDialog() {
    const [isSubscriptionManagerModalOpen, setIsSubscriptionManagerModalOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))



    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const memoizedSubscribedUsersList = useMemo(() => {
        return subscribedUsersList.sort((a, b) => a.toString().localeCompare(b.toString()));
    }, [subscribedUsersList]);


    return (
        <div className={classes.members}>
            <AvatarGroup max={5}>
                {memoizedSubscribedUsersList.map((person, index) => (
                    <div style={{ opacity: person?.status === "active" ? "1" : '0.5' }} key={index} className={classes.member}>
                        <PersonAvatar
                            size={22}
                            email={person.email}
                            profileImg={person?.profile?.profileImg}
                            lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                            firstName={person?.profile?.firstName}
                            toolTip={true}
                        />
                    </div>
                ))}
            </AvatarGroup>
            <IconButton onClick={handleMenuClick} sx={{ p: 0, }}>
                <MoreVert />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setIsSubscriptionManagerModalOpen(true)
                    handleMenuClose()
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <ManageAccountsOutlined />
                        <p>Manage subscribers</p>
                    </div>
                </MenuItem>
            </Menu>
            <Modal
                id="subscription-manager"
                open={isSubscriptionManagerModalOpen}
                onClose={() => { setIsSubscriptionManagerModalOpen(false) }}
                sx={{
                    maxWidth: "1000px",
                    minWidth: "800px",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ManageSubscribersModal onClose={() => { setIsSubscriptionManagerModalOpen(false) }} />
            </Modal>
        </div>
    )
}