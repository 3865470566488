import { workspaceUserCompareValue } from "@common-models/workspace-user";
import { MenuList } from "@mui/material";
import { AugmentedBlueticksBoardUser, cellPersonListSelector, getSelectedBoardCompleteUserList } from "@common-reducers/BoardsSelectors";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { memo, useMemo, useState } from "react";
import { PickerProps } from "../columns-cells/cell-interface";
import { PersonPickerValue } from "../columns-cells/person-picker-cell/person-picker-cell";
import PersonPickerMappedOptions from "./person-picker-mapped-options";
import { cellValueSelector } from "@common-reducers/TaskSelectors";
import { updateCellValueThunk } from "@common-reducers/TaskThunks";

function PersonPicker({
  taskId,
  columnId,
  boardId
}: PickerProps) {
  const dispatch = useAppDispatch()

  // const isBoardOwner = useAppSelector(isCurrentBoardOwnerSelector)
  const _boardMemberList: AugmentedBlueticksBoardUser[] = useAppSelector((state) => (getSelectedBoardCompleteUserList(state,boardId)));
  const cellValue: PersonPickerValue[] = useAppSelector((state) => cellValueSelector(state, taskId, columnId))
  const cellPersonList = useAppSelector(state => cellPersonListSelector(state, cellValue,boardId));
  const [searchedPersonName, setValue] = useState('')
  // const [isEditing, setIsEditing] = useState(false);
  // const [newPersonName, setNewPersonName] = useState('');
  // const [imgUrl, setImgUrl] = useState('')
  // const [imgColor, setImgColor] = useState('')

  const isMac = navigator?.platform.indexOf('Mac') > -1;
  const ctrlOrMetakey = isMac ? '⌘' : 'Ctrl'

  const boardMemberList = useMemo(() => [..._boardMemberList], [_boardMemberList])

  const selectedPersonsIds = useMemo(() => boardMemberList.filter(option => cellPersonList?.some(value => value._id === option._id)).map(s => s._id), [boardMemberList, cellPersonList])
  const filteredBoardMemberList = boardMemberList.filter(option => !selectedPersonsIds.includes(option._id));

  const filterPeopleList = (e) => {
    setValue(e.target.value)

  }
  const toggleMenu = () => {
    dispatch(pickerDialogActions.closePickerDialog());
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      toggleMenu();
    }
  }

  const cellPersonListToValue = (personList: AugmentedBlueticksBoardUser[]) => personList.map(person => {
    let val;
    switch (person.type) {
      case 'user': {
        ;
        val = { id: person._id, type: 'user' }
        break;
      }
      case 'option': {
        val = { id: person._id, type: 'option' }
        break;
      }
    }
    return val;
  })

  const onValueUpdate = (e, targetPerson: AugmentedBlueticksBoardUser) => {
    console.log(cellPersonList);
    let personList = [];

    if (e.metaKey || e.ctrlKey) {
      personList = [
        ...(cellPersonList ?? []),
        targetPerson
      ]
    } else {
      personList = [targetPerson]
    }

    personList.sort((a, b) => workspaceUserCompareValue(a).localeCompare(workspaceUserCompareValue(b)));
    const value = cellPersonListToValue(personList);
    console.log(value);

    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value
      }
    }));

    if (!e.metaKey && !e.ctrlKey) {
      toggleMenu();
    }

  }

  const clearValue = (value: AugmentedBlueticksBoardUser) => {
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value: cellPersonListToValue(cellPersonList.filter(cellPerson => cellPerson._id !== value._id))
      }
    }));
  }

  // const canEdit = useMemo(() => columnId && !isTempId(columnId), [columnId])

  // const handleSave = () => {
  //   dispatch(createMockPersonThunk({
  //     columnId,
  //     newPersonName,
  //     imgUrl,
  //     imgColor,
  //   }));
  //   setIsEditing(false)
  //   setImgColor('')
  //   setImgUrl('')
  //   setNewPersonName('')

  // }

  // const handleCancel = () => {
  //   setIsEditing(false)
  //   setImgColor('')
  //   setImgUrl('')
  //   setNewPersonName('')

  // }


  return (
    <MenuList
      autoFocusItem={true}
      id="menu-list-grow"
      onKeyDown={handleListKeyDown}
      sx={{
        backgroundColor: 'var(--default-background)',
        border: '2px solid var(--default-background)',
        borderLeft: '20px solid var(--default-background)',
        borderRight: '20px solid var(--default-background)',
        borderRadius: '10px',
        margin: '-4px',
      }}
    >

      <PersonPickerMappedOptions
        cellPersonList={cellPersonList}
        clearValue={clearValue}
        searchedPersonName={searchedPersonName}
        filterPeopleList={filterPeopleList}
        setValue={setValue}
        filteredBoardMemberList={filteredBoardMemberList}
        onValueUpdate={onValueUpdate}
        boardMemberList={boardMemberList}
        ctrlOrMetakey={ctrlOrMetakey}
        // isEditing={isEditing}
      />



      {/* currently we hide this => false */}
      {/* {false && isBoardOwner &&
        <div className={classes.edit_labels_button_wrap}>
          {!isEditing &&
            <Button
              onClick={() => setIsEditing(true)}
              disabled={!canEdit}
              className={classes.edit_labels_button}
              style={{
                textTransform: "capitalize",
                marginTop: 5,
                boxShadow: "none",
                fontSize: 12,
                borderRadius: 0,
                color: 'var(--option-picker-button)',
              }}
            >
              <BiPencil size={16} />
              <p style={{
                margin: '0px 5px',
                fontFamily: "'Inter', sans-serif",
                fontWeight: "600",
                fontSize: 14,
              }}>
                Edit People
              </p>
            </Button>
          }
          {isEditing &&
            <>
              <PersonPickerEditableOptions
                newPersonName={newPersonName}//
                setNewPersonName={setNewPersonName}//
                columnId={columnId}
                imgUrl={imgUrl} //
                setImgUrl={setImgUrl}
                imgColor={imgColor}//
                setImgColor={setImgColor}
              />
              <Stack direction='row'>
                <Button
                  onClick={() => handleSave()}
                  disabled={!(newPersonName !== '')}
                  className={classes.edit_labels_button}
                  style={{
                    textTransform: "capitalize",
                    marginTop: 5,
                    boxShadow: "none",
                    fontSize: 12,
                    borderRadius: 0,
                    color: 'var(--option-picker-button)',
                  }}
                >
                  <BiSave size={16} />
                  <p style={{
                    margin: '0px 5px',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "600",
                    fontSize: 14,
                  }}>
                    Save
                  </p>
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  className={classes.edit_labels_button}
                  style={{
                    textTransform: "capitalize",
                    marginTop: 5,
                    boxShadow: "none",
                    fontSize: 12,
                    borderRadius: 0,
                    color: 'var(--option-picker-button)',
                  }}
                >
                  <BiX size={16} />
                  <p style={{
                    margin: '0px 5px',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "600",
                    fontSize: 14,
                  }}>
                    Close
                  </p>
                </Button>
              </Stack>
            </>

          }
        </div>
      } */}

    </MenuList>
  );
}

export default memo(PersonPicker);
