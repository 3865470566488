import RenderUpdate from '@common-components/tasks/extraData/editor/RenderUpdate'
import SlateEditor from '@common-components/tasks/extraData/editor/SlateEditor'
import { Box, Stack } from '@mui/system'
import { DBFeatureThunks, featureSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks"
import { getRuntimeUrl } from '@common-services/storage'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { useEffect } from 'react'
import { Descendant } from 'slate'

type Props = {
    handlMaintenancePopupClose: () => void;
}

export default function MaintenancePopupDialog
    ({
        handlMaintenancePopupClose,
    }: Props) {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(
            DBFeatureThunks.find({
                featureName: 'maintenancePopup',
                deleted: false,
            })
        )
    }, [dispatch])
    const maintenancePopupFeature = useAppSelector((state) => featureSelectOneObjectByQuery(state.DBFeatureReducer, {
        featureName: 'maintenancePopup',
    }))


    const srcXClose = getRuntimeUrl("/img/closeXSVG.svg")
    const srcAlertYellow = getRuntimeUrl("/img/alertYellowSVG.svg")



    return (
        <Box sx={{
            width: '45%',
            height: '75%',
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 999,
            transform: 'Translate(-50%,-50%)',
            top: '50%',
            left: '50%',
            borderRadius: '8px',
            border: '1px solid grey',
            p: '20px',
            overflowY:"scroll"
        }}>
            <Stack direction='column' sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img src={srcXClose} style={{ width: '16px', height: '16px', alignSelf: 'flex-start', cursor: 'pointer', }} alt="Blueticks Logo"
                    onClick={handlMaintenancePopupClose} />
                {/* <img src={srcAlertYellow} style={{ width: '106px', height: '106px', margin: '50px', }} alt="Blueticks Logo" /> */}

          
                <Stack direction='column' sx={{ width: '70%', }}>
                <RenderUpdate update={maintenancePopupFeature?.customData?.content?.editorValue ?? []} />

                  
                </Stack>
                {/* <button style={{
                    position: 'absolute',
                    bottom: '35px',
                    border: '1px solid #e6e6e6',
                    fontWeight: 600,
                    width: '122px',
                    height: '47px',
                    borderRadius: '8px',
                    fontSize: '17px',
                }}
                    onClick={handlMaintenancePopupClose}>
                    I understand
                </button> */}
            </Stack>
        </Box>
    )
}