import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import React from 'react';
import classes from "./chatgpt-node.module.scss";

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function MaxTokensInput({ localChatGPTState, setLocalChatGPTState, }: Props) {



    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitleWithMaybeLink = (
        secondaryTitle: string,
        style?: React.CSSProperties,
        linkString?: string,
        linkURL?: string,
        additionalTextAfterLink?: string
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
                {linkString && linkURL && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {linkString}
                    </a>
                )}
                {additionalTextAfterLink && (
                    <span className={classes.additional_text}>
                        {additionalTextAfterLink}
                    </span>
                )}
            </div>
        );
    };


    return (
        <>
            {title('Max tokens', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.maxTokens}
                placeholder='Enter your prompt here...'
                type='number'
                onChange={(newValue) =>{
                    setLocalChatGPTState({
                        ...localChatGPTState,
                        maxTokens: newValue
                    })
                }}
            />

            {secondaryTitleWithMaybeLink(`The maximum number of tokens to be used in the completion. If set to 0, the model's default limit will apply. Be aware that a low value might truncate the response, while a high value, particularly in scenarios like an unending stream, could cause the model to generate the maximum number of tokens possible, potentially increasing your usage costs. For more information, please refer to the `, {}, 'OpenAI Pricing', 'https://openai.com/api/pricing/', ' page')}
        </>
    )
}