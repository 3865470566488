import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function TopP({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Top P', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.topP ?? ''}
                type='number'
                onChange={(newValue) => {
                    if (/^\d*\.?\d*$/.test(newValue) && +newValue <= 1) {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev.advanced,
                                topP: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `An alternative to sampling with temperature is top-p (nucleus) sampling, which selects tokens based on their cumulative probability. For example, a setting of 0.1 means only the tokens within the top 10% of the probability mass are considered. The default value is 1`,
                {}
            )}
            {secondaryTitleWithMaybeLink(`Must be lower than or equal to 1`, {})}
        </>
    );
}
