// mockFfmpeg.js

const mockFfmpeg = () => {
  return {
    input: () => mockFfmpeg(),
    inputFormat: () => mockFfmpeg(),
    outputOptions: () => mockFfmpeg(),
    output: () => mockFfmpeg(),
    on: (event, callback) => {
      if (event === 'end') {
        setTimeout(() => callback(), 1000);
      }
      return mockFfmpeg();
    },
    run: () => {
      console.log('Mock FFmpeg: Simulating video processing');
    },
  };
};

mockFfmpeg.ffprobe = (file, callback) => {
  console.log('Mock FFprobe: Simulating file probe');
  callback(null, { format: {}, streams: [] });
};

mockFfmpeg.setFfmpegPath = () => {};
mockFfmpeg.setFfprobePath = () => {};
mockFfmpeg.getAvailableFormats = (callback) => { callback(null, {}); };
mockFfmpeg.getAvailableCodecs = (callback) => { callback(null, {}); };
mockFfmpeg.getAvailableEncoders = (callback) => { callback(null, {}); };
mockFfmpeg.getAvailableFilters = (callback) => { callback(null, {}); };

// Mock the entire library structure
mockFfmpeg.lib = {
  fluent_ffmpeg: mockFfmpeg,
  options: {
    misc: {
      // Add any specific functions from misc.js that your code might be using
    }
  }
};

module.exports = mockFfmpeg;