


import BTButton from '@common-components/bt-button/bt-button';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { getCurrentWorkspaceUserList } from '@common-reducers/BoardsSelectors';
import { DBWorkspaceUserThunks } from "@common-reducers/DBServiceThunks";
import { modalActions } from '@common-reducers/ModalReducer';
import { taskActions } from '@common-reducers/TasksReducer';
import { selectCurrentWorkspace } from '@common-reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useMemo, useState } from 'react';


export const InviteToWorkSpace = () => {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const workspaceUserList = useAppSelector(getCurrentWorkspaceUserList);

    const currentWorkspace = useAppSelector(selectCurrentWorkspace);


    function filterWorkspaceUser(query: string) {
        setEmail(query)
    }



    const isValidEmail = useMemo(() => {
        const regex = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
        return email.match(regex);
    }, [email]);




    const inviteUser = useCallback(async () => {
        setIsLoading(true);
        const res = await dispatch(
            DBWorkspaceUserThunks.create({
                email,
                role: 'member',
                status: 'pending',
                workspaceId: currentWorkspace._id
            })
        ).unwrap();
        if ((res as any).status === 500) {
            const error = (res as any).data;
            dispatch(taskActions.setTaskSnackbarMessage(error.message));
        }

        setEmail('');
        setIsLoading(false);

    }, [currentWorkspace?._id, dispatch, email]);

    const emailExists = useMemo(
        () =>
            workspaceUserList.find((user) => user?.email === email),
        [workspaceUserList, email]
    );

    const canInvite = useMemo(
        () => isValidEmail && !isLoading && !emailExists,
        [emailExists, isLoading, isValidEmail]
    );

    const handleCloseInviteDialog = () =>
    {
        dispatch(modalActions.setComponentToRender(null));

    }

    return (
                <>

            <DialogTitle>Invite user to <b>{currentWorkspace?.name ?? 'workspace'}</b></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the user's email address
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={email}
                    onChange={({ target }: any) => filterWorkspaceUser(target.value)}
                />
            </DialogContent>
            <DialogActions>
                    <BTButton onClick={handleCloseInviteDialog} btvariant="text">Cancel</BTButton>
                    <BTButton disabled={!canInvite} onClick={inviteUser}>Invite</BTButton>
                </DialogActions>
            </>

            )

    }




