import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import { Checkbox, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { getBoardNameById } from "@common-reducers/BoardsSelectors";
import { recycleActions } from "@common-reducers/RecycleBinReducer";
import { deletePermantley,  restoreTheItems } from "@common-reducers/TaskThunks";
import { selectCurrentWorkspace } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import TimeAgo from "./archived-or-deleted-time-ago";
import PromptDeleteAction from "@common-components/prompt-delete-action/prompt-delete-action";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getWorkspaceUserById } from "@common-reducers/TaskSelectors";
const ArchivedOrDeletedDataRow = ({ item, }) => {
    const dispatch = useAppDispatch();

    const boardName = useAppSelector(state => getBoardNameById(state, item.boardId ?? item._id));
    const user = useAppSelector((state) => getWorkspaceUserById(state, item.updatedBy));
    const archivedOrDeletedBulkItems = useAppSelector(state => state.RecycleBinReducer.archivedOrDeletedBulkItems);
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);
    const userCurrentWorkspace = useAppSelector(selectCurrentWorkspace);
    const isItemChecked = archivedOrDeletedBulkItems.some(i => i._id === item._id);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const handleCheckboxChange = () => {
        let updatedItems;
        if (isItemChecked) {
            updatedItems = archivedOrDeletedBulkItems.filter(i => i._id !== item._id);
        } else {
            updatedItems = [...archivedOrDeletedBulkItems, item];
        }
        dispatch(recycleActions.setArchivedOrDeletedBulkItems(updatedItems));
    };



    const handleClick = (event, item) => {
        setCurrentItem(item);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentItem(null);
    };

    const restoreItem = (item) => {
        dispatch(restoreTheItems({ items: [item], deleteOrArchive: currentTab }))
    }

    const openDeletePrompt = () => {
        setConfirmDialogOpen(true)
    }

    const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            dispatch(deletePermantley({ items: [item] }))
        }
        setConfirmDialogOpen(false)
    }

    // const deleteItem = (item) => {
    //     dispatch(deletePermantley({ items: [item] }))
    // }

    return (
        <Stack direction="row" alignItems={"center"} py={2} key={item._id} style={{ borderBottom: '1px solid #ccc' }}>
            <div style={{ flex: 1 }}>
                <Checkbox
                    checked={isItemChecked}
                    onChange={handleCheckboxChange}
                />
            </div>
            <div style={{ flex: 5, fontSize: "14px" }}>{item.text ?? item.title ?? item.name}</div>
            <div style={{ flex: 1, fontSize: "14px" }}>{item.type}</div>
            <div style={{ flex: 2, fontSize: "14px" }}>{userCurrentWorkspace.name} {
                item.type !== "Board" ? `> ${boardName ?? item?.deletedBoardFallbackName}` : ""
            }</div>
            <div style={{ flex: 2 }}><TimeAgo updatedAt={item.updatedAt} /></div>
            <div style={{ flex: 2 }}>
                <ListItemIcon>
                    <PersonAvatar
                        email={user?.email}
                        profileImg={user?.profile?.profileImg}
                        lastName={(user?.profile?.lastName === 'undefined' || user?.profile?.lastName === undefined) ? 'By system' : user?.profile.lastName}
                        firstName={user?.profile?.firstName}
                        isName={true}
                        toolTip={false}
                    />
                </ListItemIcon>
            </div>
            <div style={{ flex: 0.85 }}>
                <IconButton onClick={(event) => handleClick(event, item)}><BsThreeDots /></IconButton >
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && currentItem?._id === item._id}
                    onClose={handleClose}
                >

                    {item.type === 'Task' || item.type === 'Sub task' ?
                        <MenuItem onClick={() => { handleClose(); }}>
                            <VisibilityIcon sx={{ fontSize: 15 }} />
                            <Typography fontSize={14} ml={1}>View </Typography>
                        </MenuItem>
                        : null
                    }

                    <MenuItem onClick={() => { restoreItem(item); handleClose(); }}>
                        <RestoreIcon sx={{ fontSize: 15 }} />
                        <Typography fontSize={14} ml={1}>Restore</Typography>
                    </MenuItem>

                    {currentTab === "Trash" ?
                        <MenuItem onClick={() => { openDeletePrompt(); handleClose(); }}>
                            <DeleteIcon sx={{ fontSize: 15 }} />
                            <Typography fontSize={14} ml={1}>Delete Permanently
                            </Typography>
                        </MenuItem> : null
                    }

                </Menu>
            </div>

            <PromptDeleteAction title={`Delete ${item.type}?`} handleClose={handleDeleteConfirmationClosed} open={confirmDialogOpen} />
        </Stack>
    );
};

export default ArchivedOrDeletedDataRow;