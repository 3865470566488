import { ReactComponent as AddIcon } from "@common-assets/add.svg";
import { Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { TfiSmallcap } from "react-icons/tfi";
import { CellProps } from "../cell-interface";
import OverflowTooltip from "./overflow-tooltip";
import classes from "./text_cell.module.scss";

export default function TextCell({ cellValue, readOnly, onUpdate }: CellProps) {
  const inputRef: any = useRef();
  const [value, setValue] = useState<string | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleTextChange = useCallback(({ target }: any) => {
    setValue(target.value ? target.value : undefined);
  }, []);

  const handleClearValue = useCallback(() => {
    setValue(undefined);
    onUpdate(undefined);
  }, [onUpdate]);

  const onEditModeChange = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const updateText = useCallback((e) => {
    onEditModeChange();
    setValue(value ?? cellValue);
    onUpdate(value ?? cellValue);
    e?.target?.blur();
  }, [onEditModeChange, onUpdate, value]);



  const clickedIconsDiv = useCallback(() => {
    // added to simulate input focus because of the overflow tooltip
    inputRef?.current?.focus()
  }, []);

  return (
    <Stack direction="row" alignItems={"center"} className={classes.textCell}>
      <OverflowTooltip value={value ?? cellValue} onBlur={(e) => updateText(e)}>
        <input onChange={handleTextChange}
          style={{
            fontFamily: 'Inter, Assistant, sans-serif',
            border: "0",
            backgroundColor: "transparent",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "14px",
            color: "#819ba8",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          type="text"
          readOnly={readOnly}
          onFocus={onEditModeChange}
          onKeyDown={(e: any) => e.key === "Enter" && e?.target?.blur()}
          ref={inputRef} value={value ?? cellValue ?? ""} />
      </OverflowTooltip>

      <div className={classes.textIcon} onClick={clickedIconsDiv}>
        {!cellValue && !isEditing && (
          <>
            <AddIcon className={classes.addIcon} />
            <TfiSmallcap className={classes.smTextIcon} />
          </>
        )}
        {(cellValue && !readOnly) && (
          <RiCloseCircleFill
            onClick={handleClearValue}
            className={classes.clearIcon}
          />
        )}
      </div>

    </Stack>
  );
}
