import { useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useGenerateApiKeyMutation } from "@common-reducers/backend-api/backend-api";
import {
  activeSubscriptionSelector,
  selectUserObject,
} from "@common-reducers/UserSelectors";

import { AccountPage } from "@common-components/account-page/account-page";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { SideBarItem } from "@common-components/sidebar/sidebar";

import classes from "@common-views/account-view/account-view.module.scss";

import { Alert, Snackbar, Typography, Button, Box, Stack } from "@mui/material";

import BTButton from "@common-components/bt-button/bt-button";
import BTtextFieldWithLabel from "@common-components/bt-input-field-with-label/bt-input-field-with-label";

import info from "@common/assets/icons/info.png";
import copyIcon from "@common/assets/icons/copyIcon.png";
import ConfirmationDialog from "@common-components/confirmation-dialog/confirmation-dialog";
import AdminApiPlayground from "./admin-api-playground";
import RowStack from "@common-components/common/row-stack/RowStack";
import { toggleSocketModal } from "@common-reducers/MainReducer";
import SocketConnectionStatusButton from "@common-components/socket-connection-status-button/socket-connection-status-button";
import { WASession } from "@common-models/wasession";
import QrModal from "@common-views/qr-view/qr-modal";
import { selectCurrentUserSession } from "@common/reducers/UserSelectors";



const AdminApiView = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserObject);
  const isWaSocketsLimitReached = useAppSelector((state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached)
  const session: WASession = useAppSelector(state => selectCurrentUserSession(state));
  const isSocketConnectedToWhatsApp = session?.active && session?.status === "connected";
  const activeSubscription = useAppSelector(activeSubscriptionSelector);

  const [generateApiKeyMutation, { isLoading }] = useGenerateApiKeyMutation();

  const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState("");

  const [isRegenerate, setIsRegenerate] = useState(false);

  const copyHandle = useCallback(async () => {
    await navigator?.clipboard?.writeText(user?.apiKey);
    setSnackbarSuccessMessage("API token was copied successfully");
  }, [user?.apiKey]);

  const generateAPIKey = useCallback(async () => {
    if (user?._id) {
      const userRes = await generateApiKeyMutation({}).unwrap();
      if (userRes?.apiKey) {
        setSnackbarSuccessMessage("API token was generated successfully");
      }
    }
  }, [generateApiKeyMutation, user?._id]);

  const generateDialogGuard = useCallback(() => {
    if (user?.apiKey) {
      setIsRegenerate(true);
    } else {
      generateAPIKey();
    }
  }, [generateAPIKey, user?.apiKey]);

  const adminApiElement = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>API</h4>
          {isSocketConnectedToWhatsApp &&
            <div style={{ marginLeft: "5px" }}
              onClick={() => {
                dispatch(toggleSocketModal(true))
              }}>
              <SocketConnectionStatusButton />
            </div>
          }
        </div>

        {!isSocketConnectedToWhatsApp &&

          <Alert severity="warning"
            icon={false}
            sx={{
              alignItems: "center",
              boxShadow: "none",
              ".MuiAlert-message": {
                width: "100%",
                textAlign: "center",
                padding: "0",
              }
            }}>
            {!isWaSocketsLimitReached &&
              <RowStack
                justifyContent={"center"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(toggleSocketModal(true))
                }}>
                <SocketConnectionStatusButton />
                Pay attention, the message will be sent only if the browser is open.

                Connect your device to send messages when the browser is closed
              </RowStack>
            }
          </Alert>

        }
        <div className={classes.accountSettingBody}>
          <div style={{ width: "475px" }}>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={2}
            >
              <Box sx={{ flexGrow: 1 }}>
                <BTtextFieldWithLabel
                  disabled={isLoading}
                  label="Your API token"
                  fullWidth={true}
                  value={user?.apiKey ?? ""}
                  placeholder="No API token - Generate a new one"
                  sx={{
                    backgroundColor: "var(--background-default)",
                  }}
                />
              </Box>

              <Stack>
                {activeSubscription?.hasBulk || true ? (
                  <Button
                    disabled={isLoading}
                    onClick={generateDialogGuard}
                    sx={{
                      color: "#53BDEB",
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontWeight: "600",
                    }}
                    variant="text"
                  >
                    {user?.apiKey ? "Regenerate" : "Generate"}
                  </Button>
                ) : null}
                {navigator?.clipboard ? (
                  <BTButton
                    loading={isLoading}
                    disabled={isLoading}
                    sx={{ width: "100px" }}
                    iconsrcbefore={copyIcon}
                    onClick={copyHandle}
                  >
                    Copy
                  </BTButton>
                ) : null}
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                padding: "8px 16px",
                borderRadius: "8px",
                background: "var(--link-preview-background)",
                marginTop: "30px",
              }}
            >
              <img width={16} height={16} src={info} alt="" />
              <Stack direction="row" spacing={0.5}>
                <Typography
                  sx={{
                    color: "var(--primary)",
                    fontSize: "12px",
                    lineHeight: "18px",
                  }}
                >
                  The API documentation can be found
                </Typography>
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://documenter.getpostman.com/view/800229/TVsrG9VS"
                >
                  <Typography
                    sx={{
                      color: "var(--link-text-color)",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    here
                  </Typography>
                </a>
              </Stack>
            </Stack>
          </div>
          {user?.apiKey ? (
            <Box sx={{ minWidth: "500px", maxWidth: "75%", my: 5 }}>
              <AdminApiPlayground apiKey={user.apiKey} />
            </Box>
          ) : null}
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.title = "API";
  }, []);

  return (
    <BTThemeProvider>
      <AccountPage
        page={adminApiElement()}
        active={SideBarItem.AdminAPI}
      ></AccountPage>

      <Snackbar
        open={!!snackbarSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarSuccessMessage("")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setSnackbarSuccessMessage("")}
          severity="success"
          variant="filled"
          sx={{
            "& .MuiButtonBase-root": {
              color: "#fff;",
            },
          }}
        >
          {snackbarSuccessMessage}
        </Alert>
      </Snackbar>

      <ConfirmationDialog
        open={isRegenerate}
        title="Regenerate API token"
        content="Are you sure you want to regenerate the API token?"
        onConfirm={() => {
          generateAPIKey();
          setIsRegenerate(false);
        }}
        onCancel={() => {
          setIsRegenerate(false);
        }}
      />
      <QrModal />
    </BTThemeProvider>
  );
};

export default AdminApiView;
