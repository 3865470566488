import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface WorkspaceState {
  selectedWorkspaceId: string;
  workSpaceUsersFilter:string;
  userTypeFilter: { owner: boolean; member: boolean; guest: boolean; };
  userStatusFilter: { pending: boolean; active: boolean; disabled: boolean; };
}

const initialState: WorkspaceState = {
  selectedWorkspaceId: '',
  workSpaceUsersFilter:'',
  userTypeFilter: { owner: false, member: false, guest: false },
  userStatusFilter: { pending: false, active: false, disabled: false },
};

const WorkspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSelectedWorkspaceId(state, { type, payload }: PayloadAction<string>) {
      state.selectedWorkspaceId = payload;
    },
    setWorkSpaceUsersFilter(state, { type, payload }: PayloadAction<string>) {
      state.workSpaceUsersFilter = payload;
    },
    setUserTypeFilter(state, action: PayloadAction<{ type: string; value: boolean }>) {
      const { type, value } = action.payload;
      state.userTypeFilter[type] = value;
    },
    setUserStatusFilter(state, action: PayloadAction<{ status: string; value: boolean }>) {
      const { status, value } = action.payload;
      state.userStatusFilter[status] = value;
    },
  },
});

export const workspaceActions = WorkspaceSlice.actions;

export default WorkspaceSlice.reducer;
