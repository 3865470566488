import { Stack } from '@mui/system';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { DBLogsThunks, logsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useEffect } from 'react';
import AvatarGenerator from '../avatar-generator';
import CheckboxColumnLog from './task-logs-components/checkbox-column-log';
import DateColumnLog from './task-logs-components/date-column-log';
import DropdownColumnLog from './task-logs-components/dropdown-column-log';
import FilesColumnLog from './task-logs-components/files-column-column-log';
import LogCreation from './task-logs-components/log-creation';
import NewDeleteRestoreTaskLog from './task-logs-components/new-delete-task-log';
import NumberLinkTextPhoneColumnLog from './task-logs-components/number-link-text-phone-column-log';
import OptionStatusComplexityColumnLog from './task-logs-components/option-status-complexity-column-log';
import PersonColumnLog from './task-logs-components/person-column-log';
import RatingColumnLog from './task-logs-components/rating-column-log';
import RenameLog from './task-logs-components/rename-log';
import TimelineColumnLog from './task-logs-components/timeline-column-log';

export default function ExtraDataPanelLog() {

    const promiseList = [];
    const dispatch = useAppDispatch()
    const boardId = useAppSelector(state => getSelectedBoardId(state))
    const displayExtraDataOfTaskById = useAppSelector(
        (state) => state.ExtraDataReducer.displayExtraDataOfTaskById
    );

    useEffect(() => {
        console.log("get all board logs 2");
        promiseList.push(dispatch(
            DBLogsThunks.find({
                boardId,
                deleted: false,
                $paginate: false,
                $sort: {
                    createdAt: -1
                },
            })
        ))
    }, [])

    const taskLogs = useAppSelector((state) => logsSelectByQuery(state.DBLogReducer, { taskId: displayExtraDataOfTaskById }, [], {
        createdAt: -1
    }))
        .sort((a, b) => {
            const aDate = new Date(a.createdAt)
            const bDate = new Date(b.createdAt)

            const aSeconds = Math.floor(aDate.getTime())
            const bSeconds = Math.floor(bDate.getTime())
            return bSeconds - aSeconds
        })

    const TaskLogData = (log) => {
        if (
            ['new task', 'delete task', 'restore task']
                .includes(log.logUpdateType) === true) {
            return (<NewDeleteRestoreTaskLog logId={log._id} />)
        }
        if (
            ['task name']
                .includes(log?.logUpdateType) === true) {
            return (<RenameLog logId={log._id} />)
        }
        if (
            ['option-picker', 'status-option-picker', 'complexity-picker']
                .includes(log.logUpdateType) === true) {
            return (<OptionStatusComplexityColumnLog logId={log._id} />)
        }
        if (
            ['person-picker']
                .includes(log.logUpdateType) === true) {
            return (<PersonColumnLog logId={log._id} />)
        }
        if (
            ['datepicker']
                .includes(log.logUpdateType) === true) {
            return (<DateColumnLog logId={log._id} />)
        }
        if (
            ['number', 'link', 'text-cell', 'phone-cell']
                .includes(log.logUpdateType) === true) {
            return (<NumberLinkTextPhoneColumnLog logId={log._id} />)
        }
        if (
            ['files']
                .includes(log.logUpdateType) === true) {
            return (<FilesColumnLog logId={log._id} />)
        }
        if (
            ['checkbox']
                .includes(log.logUpdateType) === true) {
            return (<CheckboxColumnLog logId={log._id} />)
        }
        if (
            ['rating']
                .includes(log.logUpdateType) === true) {
            return (<RatingColumnLog logId={log._id} />)
        }
        if (
            ['drop-down']
                .includes(log.logUpdateType) === true) {
            return (<DropdownColumnLog logId={log._id} />)
        }
        if (
            ['timeline-picker']
                .includes(log.logUpdateType) === true) {
            return (<TimelineColumnLog logId={log._id} />)
        }
    }

    const LogROw = (index) => {
        const log = taskLogs[index];
        if (index !== 1 || taskLogs[index].actionType !== 'restored') {
            return (
                <Stack direction='row' key={`${index}_logs_${displayExtraDataOfTaskById}`} gap={1}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: "white",
                        padding: "5px 24px 5px 16px",
                        minHeight: "80px",
                        borderBottom: index === taskLogs.length - 1 ? 'none' : '1px solid #DEEEF2',
                    }}>
                    <Stack direction='row'>
                        <AvatarGenerator itemId={log?._id} size={32} isUser={true} />&nbsp;&nbsp;
                        {TaskLogData(log)}
                    </Stack>
                    <div style={{ width: '50px', }}><LogCreation logId={log?._id} /></div>
                </Stack>
            )
        }
    }

    return (
        <Stack direction='column'
            sx={{
                borderBottom: '1px solid #DEEEF2',
                borderLeft: '1px solid #DEEEF2',
                borderRadius: "8px",
                overflow: "hidden",
            }}>
            {
                taskLogs.map((log, index) => {
                    return (LogROw(index))
                })
            }
        </Stack>
    )
}

/// Comments:
/// text logs needs to have the css fixed inside cellWrapper component.
/// files column has a problem with deleting the last file. Also, its not logged.
/// number, text, phone columns creates double logs