import BTThemeProvider from "@common-components/bt-theme-provider";
import GroupColorPicker from "@common-components/tasks/group-header/GroupColorPicker";
import GroupMenu from "@common-components/tasks/group-header/group-menu/GroupMenu";
import PickerDialog from "@common-components/tasks/picker-dialog/picker-dialog-box";
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, CircularProgress, IconButton, Skeleton, Snackbar, Stack, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { getCurrentBoard, getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { getBoardDataThunk } from "@common-reducers/BoardsThunks";
import { taskActions } from "@common-reducers/TasksReducer";
import { selectUser } from "@common-reducers/UserSelectors";
import MediaManager from "@common-views/media-manager/media-manager";
import BoardTabList from "@common-views/task-view/board-tab-list";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { isTempId } from "@common-reducers/DBServiceThunks";

import { getCurrentGroupsLength } from "@common-reducers/TaskSelectors";
import { updateManyTasksWithWhatsappName } from "@common-reducers/TaskThunks";
import { useRouter } from "@common-reducers/hooks/use-router";
import Archive from "@common-views/archive-dialog/archive";
import useMediaQuery from "usehooks-ts/dist/esm/useMediaQuery/useMediaQuery";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import CompleteDetailsBar from "../complete-details-bar/complete-details-bar";
import TaskMenu from "../tasks/task-menu/task-menu";
import AutomationPanel from "./automation/automation-panel";
import ChatTaskList from "./chat-task-list";
import FileExistsDialog from "./file-exists-dialog/file-exists-dialog";
import FormView from "./form/form-view";
import GanttView from "./gantt/gantt-view";
import GeneralScreenWithButtonAndText from "./general-screen-with-button-and-text";
import TaskUpdateModal from "./kanban/kanban-card";
import KanbanView from "./kanban/kanban-view";
import ResizableDrawer from "./resizeable-drawer";
import TasksPanelHeader from "./tasks-panel-header/tasks-panel-header";
import classes from "./tasks-panel.module.scss";
import WorkloadView from "./workload/workload-view";
type Props = {
  isDisplayTabs?: boolean;
  isDisplayTasksPanelHeader?: boolean;
  isDisplayNewGroupButton?: boolean;
  isDisplaySearchField?: boolean;
  isDisplayPersonButton?: boolean;
  isDisplayColumnsButton?: boolean;
  isDisplayImportButton?: boolean;
  isDisplayExportButton?: boolean;
  isFromCampaign?: boolean;
  height?: Number;
}

export default function TasksPanel({
  isDisplayTabs = true,
  isDisplayTasksPanelHeader = true,
  isDisplayNewGroupButton = true,
  isDisplaySearchField = true,
  isDisplayPersonButton = true,
  isDisplayColumnsButton = true,
  isDisplayImportButton = true,
  isDisplayExportButton = true,
  isFromCampaign = false,
  height
}: Props): ReactElement {
  console.log("tasks-panel: rendering");
  const dispatch = useAppDispatch();
  const currentBoard = useAppSelector(getCurrentBoard);
  const selectedBoardId = useAppSelector(getSelectedBoardId);
  const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
  const taskSnackbarMessage = useAppSelector((state) => state.TasksReducer.taskSnackbarMessage);
  const upgradeSnackbarMessage = useAppSelector((state) => state.TasksReducer.upgradeSnackbarMessage);

  const router = useRouter();
  const newWaBoardStatus = useAppSelector(
    (state) => state.BoardsReducer.newWaBoardStatus
  );

  const taskViewSideDrawerOpen = useAppSelector(
    (state) => state.TasksReducer.taskViewSideDrawerOpen
  );



  const isTaskGroupsLoaded = true;

  const isTasksLoaded = useAppSelector(
    (state) => state.DBTasksReducer.itemsLoaded
  );

  const user = useAppSelector(selectUser);

  const isTaskModalOpen = useAppSelector((state) => state.TasksReducer.isTaskModalOpen);
  const mediaMangerShown = useAppSelector((state) => state.MediaManagerReducer.shown);
  const isArchiveShown = useAppSelector((state) => state.RecycleBinReducer.isArchiveShown);
  const isAutomationsPanelShown = useAppSelector((state) => state.TasksReducer.isAutomationsPanelShown);

  const filesToDecideNewVersionOrNewFile = useAppSelector((state) => state.MediaManagerReducer.filesToDecideNewVersionOrNewFile);
  const currentGroupsLength = useSelector(getCurrentGroupsLength);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(786));

  useEffect(() => {
    document.title = `Board - ${currentBoard?.name}`;
  }, [currentBoard?.name]);

  const handleCloseSnackbar = () => {
    dispatch(taskActions.clearTaskSnackbarMessage());
  };

  const handleCloseUpgradeSnackbar = () => {
    dispatch(taskActions.setUpgradeSnackbarMessage(''));
  }

  const activePanel = useMemo(() => {
    let _activePanel;
    switch (currentBoardView.type) {
      case 'board':
      case 'boardView':
        {
          _activePanel = <ChatTaskList isDisplayNewGroupButton={isDisplayNewGroupButton} isFromCampaign={isFromCampaign} height={height} />;
          break;
        }
      case 'kanban': {
        _activePanel = <KanbanView kanbanId={currentBoardView.id} />;
        break;
      }
      case 'form': {
        _activePanel = <FormView boardId={currentBoard?._id} formId={currentBoardView.id} />;
        break;
      } case 'workload': {
        _activePanel = <WorkloadView boardId={currentBoard?._id} workloadId={currentBoardView.id} />;
        break;
      } case 'gantt': {
        _activePanel = <GanttView boardId={currentBoard?._id} ganttId={currentBoardView.id} />;
        break;

      }

      case 'loader': {
        _activePanel = <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack >;
        break;
      }
    }
    return _activePanel;
  }, [currentBoard?._id, currentBoardView.id, currentBoardView.type])

  const getBoardData = useCallback(async () => {
    if (!currentBoard?._id || isTempId(currentBoard?._id)) return;

    await dispatch(getBoardDataThunk({ boardId: currentBoard?._id }))

    console.log('uriel 0')
    // dispatch(getContactsFromRedisThunk())

    if (user?.bulkVersion === "migrated") {
      dispatch(updateManyTasksWithWhatsappName())
    }

  }, [currentBoard?._id, dispatch]);

  useEffect(() => {
    console.log("tasks-panel: run when changing selected board");
    getBoardData();
  }, [currentBoard?._id, getBoardData]);



  const TasksSkeleton = () => {
    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "15% 82%  2%",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 2,
          }}
        >
          <Skeleton
            className={classes.customSkeletonStyle}
            variant="text"
            height={36}
          />
          <Skeleton
            className={classes.customSkeletonStyle}
            variant="text"
            height={6}
          />
          <Skeleton
            className={classes.customSkeletonStyle}
            variant="rectangular"
            height={36}
          />
        </div>
        {[1, 2, 3, 4, 5].map((index) => (
          <div key={index} style={{ margin: "1px 2px " }}>
            <Skeleton
              className={classes.customSkeletonStyle}
              variant="rectangular"
              height={36}
            />
          </div>
        ))}
      </div>
    );
  };

  // async function navigateTo404Page() {
  //   if (typeof selectedBoard === 'undefined' && router.query.boardID) {
  //     //router.navigate('/page-not-found')
  //   }
  // }


  return (
    <BTThemeProvider>
      <Box sx={{ pt: 2 }}>
        <CompleteDetailsBar />
        <div id="task-panel-container" className={classes.panelContainer} style={{
          backgroundColor: 'var(--secondary-background-color)',
        }}>

          {isTaskGroupsLoaded && selectedBoardId && (<>
            {isDisplayTabs &&
              <BoardTabList />
            }

            {isDisplayTasksPanelHeader &&
              <TasksPanelHeader
                isDisplayNewGroupButton={isDisplayNewGroupButton}
                isDisplaySearchField={isDisplaySearchField}
                isDisplayPersonButton={isDisplayPersonButton}
                isDisplayColumnsButton={isDisplayColumnsButton}
                isDisplayImportButton={isDisplayImportButton}
                isDisplayExportButton={isDisplayExportButton}
                isFromCampaign={isFromCampaign}
              />
            }
          </>)}

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            className={classes.taskContainer}
          >


            {!selectedBoardId && currentBoard?._id && (
              <div>
                {((currentBoard !== null &&
                  (!isTasksLoaded || !isTaskGroupsLoaded)) ||
                  newWaBoardStatus === "creating WA board") && <TasksSkeleton />}
              </div>
            )}





            {currentBoard !== null && selectedBoardId &&
              !isTasksLoaded &&
              !isTaskGroupsLoaded &&
              currentGroupsLength === 0 &&
              newWaBoardStatus === "" &&
              (
                <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 200px)">
                  <Box width="200px">
                    <CircularProgress />
                  </Box>
                </Stack>)
            }

            {currentBoard !== null && selectedBoardId &&
              isTasksLoaded &&
              isTaskGroupsLoaded &&
              currentGroupsLength === 0 &&
              newWaBoardStatus === "" && (<GeneralScreenWithButtonAndText
                height="calc(100vh - 260px)"
                target="group"
                textValue=" This <b>board</b> has no content yet!" />)}

            {/* {!selectedBoardId && !currentBoard?._id && (<GeneralScreenWithButtonAndText
        height="calc(100vh - 260px)"
        target="group"
        textValue="Oops! <b>board</b> doen't exist" />)} */}




            {currentBoard !== null &&
              isTasksLoaded &&
              isTaskGroupsLoaded &&
              currentGroupsLength > 0 && (
                <>
                  <GroupMenu />
                  <GroupColorPicker />
                  <TaskMenu />
                  {activePanel}
                </>
              )}

            <PickerDialog boardId={currentBoard?._id} />
            {currentBoard !== null &&
              isTasksLoaded &&
              isTaskGroupsLoaded &&
              currentGroupsLength > 0 &&
              mediaMangerShown && (
                <>
                  <MediaManager />
                </>
              )}

            {isTaskModalOpen &&
              <TaskUpdateModal />

            }


            {isAutomationsPanelShown && <AutomationPanel />}
            {currentBoard !== null &&
              isTasksLoaded &&
              isTaskGroupsLoaded &&
              isArchiveShown && (
                <>
                  <Archive />

                </>
              )}

            {
              currentBoard !== null &&
              isTasksLoaded &&
              isTaskGroupsLoaded &&
              currentGroupsLength > 0 && filesToDecideNewVersionOrNewFile.length > 0 &&
              (
                <>
                  <FileExistsDialog />
                </>
              )
            }



            {currentBoard !== null && taskViewSideDrawerOpen !== "" && (
              <ResizableDrawer />
            )}
            {/* <Tooltip title="Need help?" arrow placement="top">
            <Fab onClick={() => dispatch(taskActions.setTaskViewSideDrawerOpenWith("Help"))} sx={{position:"absolute",bottom:"10px",right:"20px",background:"linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)"}}  aria-label="help" >
             <QuestionMarkIcon sx={{color:"#fff"}}/>
            </Fab>
            </Tooltip> */}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={!!taskSnackbarMessage}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              ContentProps={{
                sx: {
                  display: 'block',
                  textAlign: "center"
                }
              }}
              message={taskSnackbarMessage}
            />




            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={!!upgradeSnackbarMessage}
              autoHideDuration={8000}
              onClose={handleCloseUpgradeSnackbar}
              sx={{
                "& .MuiPaper-root": {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }

              }}
            >
              <Alert severity="info" >
                <Stack direction="row" alignItems={"center"}>

                  <Box>{upgradeSnackbarMessage}</Box>
                  {upgradeSnackbarMessage === "Seat limit exceeded" &&
                    <Button sx={{ mr: "10px", textTransform: "capitalize", color: "inherit" }} variant="text" onClick={() => router.navigate('/account-subscription')}><span style={{ borderBottom: "1px solid #000", fontWeight: "bold" }}>Add more seats</span></Button>


                  }


                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseUpgradeSnackbar}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Alert>
            </Snackbar>
          </div>
        </div>
      </Box>
    </BTThemeProvider>
  );
}
