


type Props = {
    id: string;
}

export default function ChatGPTNodeContainer({ id }: Props) {


    return (
        <>
        </>
    )
}