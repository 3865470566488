import { CardType } from "./payment-form";

export interface Currency {
    code: string;
    title: string;
    excludedCardTypes?: CardType[]
}

export const CurrencyList: Currency[] = [
    { code: 'AED', title: 'United Arab Emirates Dirham' },
    { code: 'AFN', title: 'Afghanistan Afghanis' },
    { code: 'ALL', title: 'Albanian Lek' },
    { code: 'AMD', title: 'Armenian Dram', excludedCardTypes: ['AMEX'] },
    { code: 'ANG', title: 'Netherlands Antillean Guilder' },
    { code: 'ARS', title: 'Argentine Peso' },
    { code: 'AUD', title: 'Australian Dollar' },
    { code: 'AWG', title: 'Aruban Guilder' },
    { code: 'BAM', title: 'Bosnia and Herzegovina Convertible Mark' },
    { code: 'BBD', title: 'Barbados Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'BGN', title: 'Bulgarian Lev' },
    { code: 'BHD', title: 'Bahraini Dinar' },
    { code: 'BMD', title: 'Bermuda Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'BND', title: 'Brunei Dollar' },
    { code: 'BOB', title: 'Bolivian Boliviano', excludedCardTypes: ['AMEX'] },
    { code: 'BRL', title: 'Brazilian Real' },
    { code: 'BSD', title: 'Bahamian Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'BWP', title: 'Botswana Pula' },
    { code: 'BYR', title: 'Belarusian Ruble', excludedCardTypes: ['AMEX'] },
    { code: 'CAD', title: 'Canadian Dollar' },
    { code: 'CHF', title: 'Swiss Franc' },
    { code: 'CLP', title: 'Chilean Peso' },
    { code: 'CNY', title: 'China Yuan Renminbi' },
    { code: 'COP', title: 'Colombia Peso' },
    { code: 'CRC', title: 'Costa Rican Colon', excludedCardTypes: ['AMEX'] },
    { code: 'CZK', title: 'Czech Koruna' },
    { code: 'DKK', title: 'Danish Krone' },
    { code: 'DOP', title: 'Dominican Republic Peso', excludedCardTypes: ['AMEX'] },
    { code: 'DZD', title: 'Algerian Dinar' },
    { code: 'EGP', title: 'Egyptian Pound' },
    { code: 'EUR', title: 'Euro' },
    { code: 'FJD', title: 'Fiji Dollar' },
    { code: 'GBP', title: 'British Pound Sterling' },
    { code: 'GEL', title: 'Georgian Lari', excludedCardTypes: ['AMEX'] },
    { code: 'GIP', title: 'Gibraltar Pound', excludedCardTypes: ['AMEX'] },
    { code: 'GTQ', title: 'Guatemalan Quetzal', excludedCardTypes: ['AMEX'] },
    { code: 'HKD', title: 'Hong Kong Dollar' },
    { code: 'HRK', title: 'Croatian Kuna' },
    { code: 'HUF', title: 'Hungarian Forint' },
    { code: 'IDR', title: 'Indonesian Rupiah' },
    { code: 'ILS', title: 'Israeli New Shekel' },
    { code: 'INR', title: 'Indian Rupee', excludedCardTypes: ['AMEX'] },
    { code: 'ISK', title: 'Icelandic Kr' },
    { code: 'JMD', title: 'Jamaican Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'JOD', title: 'Jordanian Dollar' },
    { code: 'JPY', title: 'Japanese Yen' },
    { code: 'KES', title: 'Kenyan Shilling' },
    { code: 'KHR', title: 'Cambodian Riel', excludedCardTypes: ['AMEX'] },
    { code: 'KRW', title: 'South Korean Won' },
    { code: 'KWD', title: 'Kuwaiti Dinar' },
    { code: 'KYD', title: 'Cayman Islands Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'KZT', title: 'Kazakhstani Tenge' },
    { code: 'LBP', title: 'Lebanese Pound' },
    { code: 'LKR', title: 'Sri Lanka Rupee' },
    { code: 'MAD', title: 'Moroccan Dirham' },
    { code: 'MDL', title: 'Moldovan Leu' },
    { code: 'MKD', title: 'Macedonian Denar' },
    { code: 'MRO', title: 'Mauritanian Ouguiya' },
    { code: 'MUR', title: 'Mauritius Rupee' },
    { code: 'MWK', title: 'Malawian Kwacha' },
    { code: 'MXN', title: 'Mexican Peso' },
    { code: 'MYR', title: 'Malaysian Ringgit' },
    { code: 'NAD', title: 'Namibian Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'NGN', title: 'Nigerian Naira' },
    { code: 'NOK', title: 'Norwegian Krone' },
    { code: 'NPR', title: 'Nepalese Rupee' },
    { code: 'NZD', title: 'New Zealand Dollar' },
    { code: 'OMR', title: 'Omani Rial' },
    { code: 'PAB', title: 'Panamanian Balboa', excludedCardTypes: ['AMEX'] },
    { code: 'PEN', title: 'Peruvian Nuevo Sol', excludedCardTypes: ['AMEX'] },
    { code: 'PGK', title: 'Papua New Guinean Kina' },
    { code: 'PHP', title: 'Philippine Peso' },
    { code: 'PKR', title: 'Pakistan Rupee', excludedCardTypes: ['AMEX'] },
    { code: 'PLN', title: 'Polish Z' },
    { code: 'QAR', title: 'Quatari Ria' },
    { code: 'RON', title: 'Romanian Leu' },
    { code: 'RSD', title: 'Serbian Dinar', excludedCardTypes: ['AMEX'] },
    { code: 'RUB', title: 'Russian Rubles' },
    { code: 'SAR', title: 'Saudi Arabian Riyal' },
    { code: 'SCR', title: 'Seychelles Rupee' },
    { code: 'SDG', title: 'Sudanese Pound' },
    { code: 'SEK', title: 'Swedish Krona' },
    { code: 'SGD', title: 'Singapore Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'THB', title: 'Thai Baht' },
    { code: 'TND', title: 'Tunisian Dollar' },
    { code: 'TRY', title: 'Turkish Lira' },
    { code: 'TTD', title: 'Trinidad & Tobago Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'TWD', title: 'Taiwan New Dollar' },
    { code: 'TZS', title: 'Tanzanian Shilling' },
    { code: 'UAH', title: 'Ukrainian Hryvnia' },
    { code: 'USD', title: 'United States Dollar' },
    { code: 'UYU', title: 'Uruguayan Peso', excludedCardTypes: ['AMEX'] },
    { code: 'UZS', title: 'Uzbekistan Som', excludedCardTypes: ['AMEX'] },
    { code: 'VEF', title: 'Venezuelan Bolivar Fuerte' },
    { code: 'VND', title: 'Vietnamese Dong', excludedCardTypes: ['AMEX'] },
    { code: 'XCD', title: 'East Caribbean Dollar', excludedCardTypes: ['AMEX'] },
    { code: 'XOF', title: 'CFA Franc BCEAO' },
    { code: 'ZAR', title: 'South African Rand', excludedCardTypes: ['AMEX'] }
]