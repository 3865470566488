import { postMessageWithResponse, postMessageToBackgroundWithResponse } from "./post-message-to-whatsapp-functions";


export class MessageServiceActions {


  public static async postMessageWithResponse<T>(cmd: string, data: any, source: string = "messageService"): Promise<T | undefined> {
    return postMessageWithResponse({ cmd, ...data }, source);
  }

  public static async postMessageToBackgroundWithResponse<T>(cmd: string, data: any, source: string = "messageService"): Promise<T | undefined> {
    return postMessageToBackgroundWithResponse({ cmd, ...data }, source);
  }



  public static async sendMessageToTab(cmd: string, data: any, source: string = "messageService"): Promise<any[]> {
    console.log(`[Message Service] [${source}] Send message to tab ${cmd}`);

    if (!chrome.tabs) {
      return [];
    }

    const urls = [
      "https://web.whatsapp.com/*",
      "http://localhost/*",
      "https://blueticks-qa.netlify.app/*",
      "https://whatsapp-scheduler.com/*",
      "https://blueticks.co/*"
    ];

    // Create an array of promises, one for each URL pattern
    const queryPromises = urls.map((url) =>
      new Promise<chrome.tabs.Tab[]>((resolve) => {
        chrome.tabs.query({ url }, (tabs) => resolve(tabs || []));
      })
    );

    // Wait for all tab queries to finish
    const allTabs = await Promise.all(queryPromises);
    // Flatten the array of tabs
    const tabs = allTabs.flat();

    // Create another array of promises for sending messages to each tab
    const messagePromises = tabs.map((tab) =>
      new Promise((resolve) => {
        if (tab.id) {
          chrome.tabs.sendMessage(tab.id, { cmd, data }, (response) => {
            if (chrome.runtime.lastError) {
              console.log(`[Message Service] [${source}] Error: ${chrome.runtime.lastError.message}`);
              resolve(null);  // Resolve with null or an appropriate value
            } else {
              console.log(`[Message Service] [${source}] Response from tab: ${response}`);
              resolve(response);
            }
          });
        } else {
          resolve(null);  // If tab.id is undefined
        }
      })
    );

    // Wait for all messages to be sent and return the responses
    return Promise.all(messagePromises);
  }



}
