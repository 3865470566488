import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import classes from "./chatgpt-node.module.scss";
import React, { useState } from 'react'
import { CircularProgress, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { modalTextStyle } from "@common-components/bt-chatbot/bot-components/bt-chatbot-modal";

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
    models: any[];
    setModels: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ModelDropdown({
    localChatGPTState,
    setLocalChatGPTState,
    models,
    setModels,
}: Props) {
    const [isSelectModelMenuOpen, setIsSelectModelMenuOpen] = useState<boolean>(false);
    const [isFetchingModels, setIsFetchingModels] = useState<boolean>(false);
    const [selectModelMenuAnchorEl, setSelectModelMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [modelvalueError, setModelValueError] = useState(false)

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitle = (
        secondaryTitle: string,
        style?: React.CSSProperties,
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
            </div>
        );
    };


    const fetchModels = async () => {
        try {
            const headers: HeadersInit = {
                'Authorization': `Bearer ${localChatGPTState.connection.apiKey}`,
                'Content-Type': 'application/json'
            };

            // Conditionally add the OpenAI-Organization header if organizationId is not empty
            if (localChatGPTState.connection.organizationId) {
                headers['OpenAI-Organization'] = localChatGPTState.connection.organizationId;
            }

            const response = await fetch('https://api.openai.com/v1/models', {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                const errorData = await response.json();
            }

            const data = await response.json();
            setModels(data.data);

            return true
        } catch (err) {

        }
    }


    const toggleSelectModelMenu = async (e) => {
        await setSelectModelMenuAnchorEl(isSelectModelMenuOpen ? null : e.currentTarget)
        await setIsSelectModelMenuOpen(isSelectModelMenuOpen ? false : true);

        if (!models || models.length === 0) {
            setIsFetchingModels(true);
            await fetchModels()
            setIsFetchingModels(false);
        }
    }


    const handleCloseSelectModelMenu = (e, model) => {
        e.stopPropagation()

        setLocalChatGPTState({
            ...localChatGPTState,
            model: model,
        });



        setIsSelectModelMenuOpen(false);
    }

    return (
        <>
            {title('Model', { marginTop: '20px', })}

            <BotInputField
                onClick={toggleSelectModelMenu}
                onBlur={() => {
                    if (localChatGPTState.model === '') {
                        setModelValueError(true)
                    } else {
                        setModelValueError(false)
                    }
                }}
                onChange={() => { }}
                value={localChatGPTState?.model?.id ?? ''}
                endAdornment={
                    <>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }} onClick={toggleSelectModelMenu}>
                            {!isSelectModelMenuOpen && <KeyboardArrowDown />}
                            {isSelectModelMenuOpen && <KeyboardArrowUp />}
                        </IconButton>
                    </>
                }
            />


            {isSelectModelMenuOpen &&
                <Menu
                    anchorEl={selectModelMenuAnchorEl}
                    open={isSelectModelMenuOpen}
                    onClose={() => setIsSelectModelMenuOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiList-root': {
                            padding: 0,
                            width: '435px',
                            height: '350px',
                            overflow: isFetchingModels ? 'hidden' : 'auto',
                        },
                    }}>
                    {isFetchingModels && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}>
                        <CircularProgress size={30} /></div>}
                    {!isFetchingModels && models
                        ?.filter(model => {
                            if (localChatGPTState?.method === 'chat completion') {
                                return model.id.startsWith('gpt-3.5-turbo') || model.id.startsWith('gpt-4')
                            } else if (localChatGPTState?.method === 'prompt completion') {
                                return model.id.startsWith('davinci') ||
                                    model.id.startsWith('curie') ||
                                    model.id.startsWith('babbage') ||
                                    model.id.startsWith('ada') ||
                                    model.id.startsWith('text-embedding')
                            } else return false
                        })
                        ?.sort((a, b) => a.id.localeCompare(b.id))
                        ?.map((model, index) => (
                            <MenuItem sx={{ p: 0 }} key={index}>
                                <BotInputField
                                    value={model.id}
                                    onClick={(e) => handleCloseSelectModelMenu(e, model)}
                                    sx={{
                                        ".MuiOutlinedInput-input": {
                                            ...modalTextStyle,
                                            zIndex: 3,
                                            lineHeight: '19px',
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            border: "0px",
                                            backgroundColor: 'rgb(245, 246, 250)',
                                        },
                                        "&: hover": {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                backgroundColor: 'rgb(204, 208, 222)',
                                            },
                                        },
                                        height: '26px',
                                        borderRadius: 0,
                                    }} />
                            </MenuItem>
                        ))}
                </Menu>
            }
            {modelvalueError && secondaryTitle('Model is required')}
        </>
    )
}