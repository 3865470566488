import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { setCurrentTrigger } from "../automations-helper";

const TaskMovedToBoardTrigger = () => {
    const dispatch = useAppDispatch()
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);

    useEffect(() => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { boardId: boardId})));
    }, [triggerObj, boardId, dispatch]);
    return (
        <Stack direction="row" justifyContent={"space-between"} sx={{ fontSize: "24px" }}>
            <Box>When a task is moved to this board</Box>
            <AddAndRemove elements={conditions} index={0} type={"trigger"} componentName={"TaskMovedToBoardTrigger"}  />
        </Stack>

    )
}
export default TaskMovedToBoardTrigger