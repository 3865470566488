import { Slide } from "@mui/material";
import { PropsWithChildren } from "react";
import classes from "./bt-slider.module.scss";

interface BTSlideProps {
    open?: boolean;

}

const BTSlide = (props: PropsWithChildren<BTSlideProps>) => {
    return (
        <Slide direction="left" in={props.open} mountOnEnter unmountOnExit>
            <div className={classes.settings_drawer_container}>
                {props.children}
            </div>
        </Slide>

    );
};
export default BTSlide;
