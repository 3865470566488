import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { getDrawerWidth } from '@common-reducers/BoardsSelectors';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';
import classes from "./log.module.scss";

type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function NumberLinkTextPhoneColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldByQuery(state.DBTasksReducer, { _id: log?.taskId }, 'text'))
    const drawerWidth = useAppSelector(state => getDrawerWidth(state))

    // const linkOrTextWidth = useMemo(() => {
    //     return [`${drawerWidth - 400}px`, `${drawerWidth - 300}px`]
    // }, [drawerWidth])

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );
    const isPhone = log.logUpdateType === 'phone-cell'
    const isLink = log.logUpdateType === 'link'
    const isNumberOrText = log.logUpdateType === 'number' || log.logUpdateType === 'text-cell'

    const oldValue = isLink ? { link: log.oldValue, linkText: undefined } : log.oldValue
    const newValue = isLink ? { link: log.newValue, linkText: undefined } : log.newValue


    let status = ''
    if (oldValue && newValue) { status = 'change data' }
    if (!oldValue && newValue) { status = 'add data' }
    if (oldValue && !newValue) { status = 'remove data' }
    if (status === '') {
        console.log('dskjfbds')
    }


    return (
        <LogStack direction='row'>
            {status === 'add data' &&
                <div style={{
                    whiteSpace: 'pre-wrap',
                    // width: linkOrTextWidth[1],
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                }}>
                    <span className={classes.log_text_element}>{log.actionType}&nbsp;</span>
                    <span className={classes.log_text_element}>to&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                            <CellWrapper
                                sx={{ display: 'contents', height: "20px" }}
                                columnId={""}
                                taskId={""}
                                groupId={""}
                                boardId={''}
                                mockValue={newValue}
                                readOnly={true}
                                cellComponent={cell.cellComponent}
                            />
                        </span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{log.newValue}</span>
                    }
                </div>
            }
            {status === 'remove data' &&
                <div style={{
                    whiteSpace: 'pre-wrap',
                    // width: linkOrTextWidth[1],
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                }}>
                    <span className={classes.log_text_element}>removed&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                            <CellWrapper
                                sx={{
                                    display: 'contents',
                                    height: "20px"
                                }}
                                columnId={""}
                                taskId={""}
                                groupId={""}
                                boardId={''}
                                mockValue={oldValue}
                                readOnly={true}
                                cellComponent={cell.cellComponent}
                            />
                        </span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{log.oldValue}</span>
                    }
                </div>
            }
            {status === 'change data' && !isLink &&
                <div style={{
                    whiteSpace: 'pre-wrap',
                    // width: linkOrTextWidth[1],
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                }}>
                    <span className={classes.log_text_element}>changed&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}><CellWrapper
                            sx={{ display: 'contents', height: "20px" }}
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={oldValue}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        /></span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{log.oldValue}</span>
                    }
                    <span className={classes.log_text_element}>&nbsp;to&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}><CellWrapper
                            sx={{
                                display: 'contents', height: "20px",
                            }}
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={newValue}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        /></span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{log.newValue}</span>
                    }
                </div>
            }
            {isLink &&
                <>
                    {oldValue.link !== undefined &&
                        <>
                            <span className={classes.log_text_element}>removed</span>
                            <span style={{
                                paddingTop: `${isLink ? 1 : 3}px`,
                                // width: linkOrTextWidth[0],
                            }}>
                                <CellWrapper
                                    sx={{ display: 'contents', height: "20px" }}
                                    columnId={""}
                                    taskId={""}
                                    groupId={""}
                                    boardId={''}
                                    mockValue={oldValue}
                                    readOnly={true}
                                    cellComponent={cell.cellComponent}
                                />
                            </span>
                        </>
                    }
                    {newValue.link !== undefined &&
                        <>
                            <span className={classes.log_text_element}> changed to </span>
                            <span style={{
                                paddingTop: `${isLink ? 1 : 3}px`,
                                // width: linkOrTextWidth[0],
                            }}>
                                <CellWrapper
                                    sx={{ display: 'contents', height: "20px" }}
                                    columnId={""}
                                    taskId={""}
                                    groupId={""}
                                    boardId={''}
                                    mockValue={newValue}
                                    readOnly={true}
                                    cellComponent={cell.cellComponent}
                                />
                            </span>
                        </>
                    }
                </>
            }
            <span className={classes.log_text_element}>{!isLink ? <>&nbsp;</> : null}in&nbsp;</span>
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span className={classes.log_text_element} style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}