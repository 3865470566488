import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function RatingColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );

    return (
        <LogStack direction='row'>
            <>
                <span>{log.actionType}</span>
                <span style={{
                    display: 'inline-flex',
                    height: '20px',
                    marginTop: '-13px',
                }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </span>
                <span>&nbsp;to</span>
                <span style={{
                    display: 'inline-flex',
                    height: '20px',
                    marginTop: '-13px',
                }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </span>
            </>
            <span>in&nbsp;</span>
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}