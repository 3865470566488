import { BlueticksForm } from '@common-models/form';
import { AddAPhotoOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DBFormThunks } from "@common-reducers/DBServiceThunks";
import { taskActions } from '@common-reducers/TasksReducer';
import { handleUpload } from '@common-services/upload';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiImageAdd } from 'react-icons/bi';

type Props = {
    formId: string
}

export default function BTUploadLogo({ formId }: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const fileRef: any = useRef();
    const [loading, showLoader] = useState(false);
    
    function addFiles(e) {
        e.stopPropagation();
        fileRef.current.files = null;
        fileRef.current.click();
    }

    const uploadFiles = useCallback(async (files: any) => {
        const fourMegabytesInBytes: number = 1024 * 4 * 1024;
        if (files) {
            const filesArray = [...files];
            const [file] = filesArray;
            if (file.size >= fourMegabytesInBytes) {
                dispatch(taskActions.setTaskSnackbarMessage(t('btUploadLogo.fileTooBigError')))
                return;
            }
            const fileType = file.type
            if (!fileType.includes('image')) {
                dispatch(taskActions.setTaskSnackbarMessage(t('btUploadLogo.fileTypeError')))
            }
            else {
                const promises = filesArray.map(async (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        const mimeType: string = file.type;
                        const filename: string = file.name;
                        const fileDetails = {};
                        reader.addEventListener("load", async () => {
                            const base64file: any = reader.result;
                            const urlLink = await uploadFile(base64file, mimeType);
                            fileDetails["url"] = urlLink;
                            fileDetails["type"] = mimeType;
                            fileDetails["filename"] = filename;
                            fileDetails["updatedAt"] = moment().format('DD/MM/YYYY HH:mm:ss');
                            fileDetails["version"] = 1;
                            fileDetails["versions"] = [{ url: urlLink, type: mimeType, filename, updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'), version: 1 }]
                            resolve(fileDetails);
                        });
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                });
                let currentFilesUploaded: any = await Promise.all(promises);
                return currentFilesUploaded;
            }
        }
    }, [dispatch])

    const onNewFilesAdded = useCallback(async (newFiles) => {
        showLoader(true);
        const [fileListUploaded] = await uploadFiles(newFiles);
        const uploadedFileUrl = JSON.stringify(fileListUploaded.url).split(`"`)[1]
        if (fileListUploaded) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    logo: uploadedFileUrl,
                }
            }))
        }
        showLoader(false);
    }, [dispatch, formId, uploadFiles])

    const uploadFile = async (base64file: string, mimeType: string) => {
        try {
            const response: any = await handleUpload(base64file, mimeType);
            if (response.uploadResponse.url) {
                return response.image_url;
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
                <Button
                onClick={(e) => {
                    addFiles(e);
                }}
                    startIcon={<BiImageAdd />}
                    style={{
                        cursor: 'pointer',
                        textTransform: 'none',
                        border: '1px dashed var(--search-input-border)',
                        borderRadius: 8,
                        height: 56,
                        color: 'var(--text-color)',
                        paddingInline: '20px',
                        width: 200,
                        maxWidth: 200,
                        fontFamily: 'inherit',
                        fontWeight: 600,
                    }}
                    value="upload image">
                    {t('btUploadLogo.uploadImageButtonText')}
                </Button>

            <p style={{
                marginTop: 6,
                textAlign: 'center',
                fontSize: 11,
                color: 'var(--text-color)',
            }}>
                {t('btUploadLogo.maxSizeText')}
            </p>
            <input
                onChange={(e) => {
                    onNewFilesAdded(e.target.files);
                }}
                ref={fileRef}
                type="file"
                multiple={false}
                hidden
            />
        </>
    )
}
/// INPUT-CLICK-FILE-UPLOAD