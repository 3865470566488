import { AutocompleteProps, Box } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(onMouseDown: any) {
  function _renderRow(props: ListChildComponentProps) {
    const { data, index, style, ...others } = props;
    const dataSet = data[index];

    return (
      <Box onMouseDown={onMouseDown} {...others} style={style}>
          {dataSet}
      </Box>
    )
  }

  return _renderRow;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  { value?: any} & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, value, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={value ?? ''} {...other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow(props.onMouseDown)}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});



export const BtAutoComplete = <
    T extends any,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({sx, ...props}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & { dark?: boolean }) => {
    return <Autocomplete 
         freeSolo
        {...props}
        PopperComponent={Popper}
        ListboxComponent={ListboxComponent}
        sx={{
          '.MuiInputBase-root': {
            height:"36px",
            border: "none",
            padding:"0"
          },
          '.MuiFormControl-root.MuiTextField-root .MuiInputBase-input':{
            padding:"0 14px",
            color:"#706b6b"
          }, 
          
        }}
     
    />;
}
