import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function Seed({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Seed', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.seed ?? ''}
                type='number'
                onChange={(newValue) => {
                    if ((/^-?\d+$/.test(newValue) && +newValue >= 0) || newValue === '') {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev?.advanced,
                                seed: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `This feature is currently in Beta. If specified, OpenAI will make a best effort to sample deterministically, meaning repeated requests with the same seed and parameters should ideally return consistent results. However, determinism is not guaranteed. You should refer to the system_fingerprint response parameter to track any changes in the backend`,
                {}
            )}
            {secondaryTitleWithMaybeLink(`Must be a non negative integer`, {})}
        </>
    );
}
