import BTInputField from "@common-components/bt-input-field/bt-input-field";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DBMyWorksThunks, taskColumnSelectByQuery } from "@common-reducers/DBServiceThunks";
import { fetchMyWorkThunk, getFirstColumnByBoardIdAndType, getMyWorkBoards } from "@common-reducers/MyWorkThunks";
import { getIconByType } from '@common/components/tasks/columns-cells/column-cell-defs';
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useState } from "react";



import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCalendar4Event } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";



  export const ColumnAccordion = ({ type }) => {
    const dispatch = useAppDispatch();
    const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);
    const boards = useAppSelector(state => getMyWorkBoards(state));

    const [searchTerm, setFilterBoards] = useState("");

    const handleColumnChange = async (boardId, newColumn, type) => {
        if (!newColumn) return;
    
        const updatedColumns = { ...myWorkPreferences.selectedColumns };
  
        if (!updatedColumns[boardId]) {
            updatedColumns[boardId] = {};
        }

        updatedColumns[boardId] = {
            ...updatedColumns[boardId],
            [type]: newColumn._id
        };
    
        await dispatch(DBMyWorksThunks.patch({
            entity: {
                _id: myWorkPreferences._id,
                selectedColumns: updatedColumns
            }
        }));
    
        dispatch(fetchMyWorkThunk());
    };

    const filteredBoards = boards.filter(board => board.name.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${type}-content`}
                id={`${type}-header`}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    {type === "status" ? <FiAlertTriangle /> : <BsCalendar4Event />}
                    <Typography textTransform={"capitalize"}>{type} Column</Typography>
                    <Tooltip title={`Each board can have multiple ${type} columns, Select the one you wanna see`} placement='top' arrow>
                        <div>
                            <AiOutlineInfoCircle />
                        </div>
                    </Tooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <BTInputField
                    sx={{
                        width: '100%',
                        marginBottom: 2,
                        padding: '0px 4px',
                        background: 'var(--search-input-background)',
                        ':focus': {
                            boxShadow: '0 5px 12px rgb(0 0 0 / 15%)',
                        }
                    }}
                    type="text"
                    onChange={({ target }) => setFilterBoards(target.value)}
                    placeholder="Search Boards..."
                />
                {filteredBoards.map(board => (
                    <BoardColumnSelector
                        key={board._id}
                        board={board}
                        type={type}
                        onColumnChange={handleColumnChange}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
};


const BoardColumnSelector = ({ board, type, onColumnChange }) => {
    const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);

    const getConvertedType = (inputType) => {
        switch(inputType) {
            case "status":
                return "status-option-picker";
            case "date":
                return "datepicker";
            default:
                return null; 
        }
    }
    
    const convertedType = getConvertedType(type);
    
    const firstColumn =   useAppSelector(state => getFirstColumnByBoardIdAndType(state, board._id, convertedType));
    
    const columnOptions =  useAppSelector((state) => taskColumnSelectByQuery(state.DBTaskColumnReducer, { boardId: board._id,type:convertedType}))

    const initialValue = {
        status: myWorkPreferences.selectedColumns?.[board._id]?.status || firstColumn?._id,
        date: myWorkPreferences?.selectedColumns?.[board._id]?.date || firstColumn?._id
    };

    const selectedOption = columnOptions.find(option => option._id === initialValue[type]);


    return (
        <div key={board._id}>
            <Typography>{board.name}</Typography>
            <Autocomplete
                sx={{ mb: 2 }}
                value={selectedOption}
                onChange={(event, newValue) => onColumnChange(board._id, newValue, type)}
                options={columnOptions || []}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} variant="standard" />}
                noOptionsText="No columns to choose from"
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ marginRight: "10px", fontSize: "6px" }}>{getIconByType(option.type)}</Box>
                        <span>{option.title}</span>
                    </li>
                )}
            />
        </div>
    );
};
