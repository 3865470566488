import RowStack from "@common-components/common/row-stack/RowStack";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from 'react';
import NodeMenu from "../bot-components/node-menu";
import classes from "../bt-chatbot.module.scss";
import { nodeTypes } from "./bt-nodes-def";
import { NodeType } from "@common-models/bot-node";

type Props = {
    type: NodeType;
    id: string;
}

export default function NodeHeader({ type, id }: Props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const nodeType = nodeTypes?.[type]
    const NodeIcon = nodeType?.nodeIcon
    const isMainNode = nodeType?.isMainNode || type === 'messageContact' || type === 'status'

    const title = () => {
        switch (type) {
            case 'sendMessage':
                return 'Message';
            case 'question':
                return 'Question';
            case 'condition':
                return 'Condition';
            case 'webhook':
                return 'Webhook';
            case 'googleSheets':
                return 'Google Spreadsheet';
            case 'messageContact':
                return 'Message Contact';
            case 'status':
                return 'Status';
            case 'chatGPT':
                return 'ChatGPT';
            default:
                return 'Node';
        }
    }

    const handleOpenNodeMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuOpen((prev => !prev))
        setAnchorEl(event.currentTarget);
    };

    const handleCloseNodeMenu = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    }



    return (
        <RowStack className={isMainNode ? classes.main_node_header : classes.secondary_node_header}
            sx={{
                backgroundColor: isMainNode ? nodeType?.color : 'white',
                zIndex: 2
            }}>
            <div style={{
                backgroundColor: '#ffffff42',
                width: isMainNode ? '36px' : 'auto',
                height: '36px',
                borderRadius: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '7px',
                fontWeight: !isMainNode && 700,
            }} >
                {NodeIcon && <NodeIcon style={{ width: '25px', color: isMainNode ? 'white' : nodeType?.color }} />}
                {!isMainNode && title()}
            </div>
            {isMainNode && title()}
            <IconButton sx={{ p: 0, color: isMainNode ? 'white' : '#666666', marginLeft: '5px' }}
                onClick={handleOpenNodeMenu}>
                <MoreVert />
            </IconButton>
            <NodeMenu type={type} open={menuOpen} onClose={handleCloseNodeMenu} anchorEl={anchorEl} id={id} />
        </RowStack>
    )
}