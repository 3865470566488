import { selectCurrentUser, selectUser, selectUserObject } from "@common-reducers/UserSelectors";
import {
  PersonPickerIDType,
  PersonPickerValue,
} from "@common-components/tasks/columns-cells/person-picker-cell/person-picker-cell";
import { Board } from "@common-models/board";
import { BlueticksBoardUser } from "@common-models/board-user";
import { Gantt } from "@common-models/gantt";
import { Kanban } from "@common-models/kanban";
import { Person } from "@common-models/person";
import { User } from "@common-models/user";
import { BlueticksWorkspaceUser } from "@common-models/workspace-user";
import { createSelector } from "@reduxjs/toolkit";
import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { Chat } from "@wa-communication-layer/whatsapp";
import moment from "moment";

import { blueticksDefultTheme, blueticksLeanTheme } from "@common/theme";
import { BoardsSelectPaginationStatus, BulkListSelectPaginationStatus, boardUserSelectByQuery, boardUserSelectOneObjectByQuery, boardViewSelectByQuery, boardsSelectByQuery, boardsSelectOneObjectById, boardsSelectOneObjectByQuery, boardsSelectors, formSelectByQuery, ganttSelectByQuery, isTempId, kanbanSelectByQuery, taskColumnOptionSelectByQuery, taskGroupsSelectOneFieldByQuery, tasksExtraDataSelectByQuery, tasksSelectOneFieldById, viewFiltersSelectByQueryOnlyLength, workloadSelectByQuery, workspaceUserSelectByQuery } from "@common-reducers/DBServiceThunks";
import { NullableId } from "@common/types/interface";
import { CommonRootState } from "@common/types/common-root-state-type";


export type AugmentedBlueticksBoardUser = Partial<BlueticksBoardUser> & {
  type: PersonPickerIDType;
};


export const getIsLoadingBoards = createSelector(
  (state) => state,
  (state: CommonRootState) => !state.DBBoardsReducer.itemsLoaded
);


export const getBoardByChat = createSelector(
  [
    (state) => state.DBBoardsReducer,
    (state) => state.BoardsReducer,
    (state: CommonRootState, chat: Chat) => chat,
    (state) => state.TasksReducer.isTasksPanelOpen,
    (state) => state.WhatsAppReducer.me,

  ],
  (DBBoardsReducer, BoardsReducer, chat: Chat, isTasksPanelOpen, userWA): Board => {
    let currentBoard: Board = null;

    if (isExtensionContextOrWhatsappView() && chat && isTasksPanelOpen) {
      let _IdByWaChatIds: string[];

      if (chat.isGroup) {
        _IdByWaChatIds = [chat?.id?._serialized];
      } else {
        _IdByWaChatIds = [chat?.id?._serialized, userWA?.id?._serialized];
      }

      const res = boardsSelectByQuery(DBBoardsReducer, {
        _IdByWaChatIds: { $all: _IdByWaChatIds },
      });

      if (res.length >= 1) {
        currentBoard = res[0];
      }
      if (res.length > 1) {
        console.error("more then one board - check it");
      }
    } else {
      if (BoardsReducer?.selectedBoardId) {
        currentBoard = boardsSelectOneObjectById(
          DBBoardsReducer,
          BoardsReducer.selectedBoardId
        );
      }
    }
    return currentBoard;
  }
);

export const getIsChatHasBoard = createSelector(
  [
    (state) => state,
  ],
  (state) => {
    let isChatHasBoard = false
    let currentBoard
    const currentChat = state.WhatsAppReducer.currentChat

    if (isExtensionContextOrWhatsappView() && currentChat) {
      let _IdByWaChatIds: string[];

      const userWA = state.WhatsAppReducer.me;

      if (currentChat.isGroup) {
        _IdByWaChatIds = [currentChat?.id?._serialized];
      } else {
        _IdByWaChatIds = [currentChat?.id?._serialized, userWA?.id?._serialized];
      }

      const res = boardsSelectByQuery(state.DBBoardsReducer, {
        _IdByWaChatIds: { $all: _IdByWaChatIds },
      });

      if (res.length >= 1) {
        currentBoard = res[0];
        if (!isTempId(currentBoard._id)) {
          isChatHasBoard = true
        }
      }
    }

    return isChatHasBoard
  }
);

export const getCurrentBoard = createSelector(
  (state) => state,
  (state: CommonRootState): Board => {
    let currentBoard: Board = getBoardByChat(
      state,
      state.WhatsAppReducer?.currentChat
    );
    return currentBoard;
  }
);

export const getCurrentBoardId = createSelector(
  (state: CommonRootState) => getCurrentBoard(state),
  (currentBoard) => currentBoard?._id
);

export const currentBoardExistsSelector = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): boolean => {
    return board !== undefined;
  }
);

export const getSelectedBoardPersonList = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): Person[] => {
    return board?.personList ?? [];
  }
);

export const getSelectedBoardId = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): string => {
    return board?._id ?? "";
  }
);

export const getMockPersonList = createSelector(
  [
    (state) => state,
    getSelectedBoardId,
  ],
  (state, boardId) => {
    const _mockPersonList = taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
      boardId,
      imageUrl: { $exists: true },
    })
    const mockPersonList = _mockPersonList.map(option => {
      const words = option.label?.split(' ') ?? [];
      const firstName = words[0];
      const lastName = words.slice(1).join(' ');

      const workspaceUser: AugmentedBlueticksBoardUser = {
        _id: option._id,
        status: 'active',
        profile: {
          firstName,
          lastName,
          profileImg: option.imageUrl,
        } as User,
        type: 'option'
      }
      return workspaceUser;
    })
    return mockPersonList
  }

);

export const getBlueticksThemeSelector = createSelector([
  (state) => state],
  (state) => {
    let theme
    if (state.BoardsReducer.boardTheme === 'lean') {
      theme = blueticksLeanTheme
    }
    else {
      theme = blueticksDefultTheme
    }
    return theme
  }
);

export const getSelectedBoardUserList = createSelector(
  [
    (state) => state,
    (state, boardId?: string) => {
      const currentBoard = boardId
        ? boardsSelectors.selectById(state, boardId)
        : getCurrentBoard(state);
      return currentBoard;
    },
  ],
  (state, board: Board): BlueticksBoardUser[] => {
    const boardUserList = boardUserSelectByQuery(state.DBBoardUserReducer, {
      boardId: board?._id,
    });
    const workspaceUserList = workspaceUserSelectByQuery(
      state.DBWorkspaceUserReducer,
      {
        workspaceId: board?.workspaceId,
      }
    );

    // we are crossing the workspaceUserList with the boardUserList to get the workspaceUser role

    return boardUserList.map((boardUser) => ({
      ...boardUser,
      workspaceUser: workspaceUserList.find(
        (workspaceUser) =>
          (workspaceUser?.userId &&
            workspaceUser.userId === boardUser.userId) ||
          (workspaceUser?.email && workspaceUser.email === boardUser.email)
      ),
    }));
  }
);

export const getCurrentWorkspaceUserList = createSelector(
  [
    (state) => state.DBWorkspaceUserReducer,
    (state) => selectCurrentUser(state),
  ],
  (state, user: User): BlueticksWorkspaceUser[] => {
    if (!user?.currentWorkspace) {
      return [];
    }

    return workspaceUserSelectByQuery(state, {
      workspaceId: user?.currentWorkspace,
    });
  }
);


export const getBoardUsersProfile = createSelector(
  [
    state => state,
  ],
  (state) => {
    // dispatch(DBUserThunks.find({ _id: user._id }))
    const boardUsers = boardUserSelectByQuery(state.DBBoardUserReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        status: 'active',
      },)
    const mentionUsersArray = boardUsers.map((user) => { return ({ ...user.profile, userId: user.userId }) })
    return (
      mentionUsersArray
    );
  }
);


export const getBoardUser = createSelector(
  [
    state => state,
  ],
  (state) => {
    const userId = state?.UserReducer?.userId
    const [boardUser] = boardUserSelectByQuery(state.DBBoardUserReducer, { deleted: false })
      .filter(user => user?.userId === userId)
    return (
      boardUser?._id
    );
  }
);


export const getSeenByUsersProfile = createSelector(
  [
    (state, seenByIdArray) => state,
    (_, seenByIdArray) => seenByIdArray,
  ],
  (state, seenByIdArray) => {
    const boardUsers = boardUserSelectByQuery(state.DBBoardUserReducer, { deleted: false })

    const returnValue = boardUsers.filter((user) => seenByIdArray.includes(user.userId)).map((user) => user.profile)

    return returnValue
  }
);


export const getLikedByUsersProfile = createSelector(
  [
    (state, likedByIdArray) => state,
    (_, likedByIdArray) => likedByIdArray,
  ],
  (state, likedByIdArray) => {
    const boardUsers = boardUserSelectByQuery(state.DBBoardUserReducer, { deleted: false })

    const returnValue = boardUsers.filter((user) => likedByIdArray.includes(user.userId)).map((user) => user.profile)

    return returnValue
  }
);


export const getTaskExtradataArrayForMessageThread = createSelector(
  [
    (state, parentItemId, displayExtraDataOfTaskById) => state,
    (state, parentItemId, displayExtraDataOfTaskById) => parentItemId,
    (state, parentItemId, displayExtraDataOfTaskById) => displayExtraDataOfTaskById,
  ],
  (state, parentItemId, taskId) => {

    let taskExtradataForMessageThread;

    const taskExtradataArray = tasksExtraDataSelectByQuery(
      state.DBTasksExtraDataReducer,
      {
        taskId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      {
        isPinedToTop: -1,
        updatedAt: -1
      }
    );

    if (parentItemId !== undefined) {
      // If parentItemId is defined, sort from oldest to newest by updatedAt
      taskExtradataForMessageThread = taskExtradataArray
        .filter((extraData) => extraData.parentItemId === parentItemId)
        .sort((a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)));
    } else {
      // If parentItemId is undefined, sort from newest to oldest by updatedAt
      taskExtradataForMessageThread = taskExtradataArray
        .filter((extraData) => !extraData.parentItemId)
        .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
    }

    // Move pinned items to the top
    taskExtradataForMessageThread.sort((a, b) => {
      if (a.isPinedToTop && !b.isPinedToTop) return -1;
      if (!a.isPinedToTop && b.isPinedToTop) return 1;
      return 0;
    });

    return taskExtradataForMessageThread;
  }
);

export const getSortedAndFilteredWorkspaceUserList = createSelector(
  [getCurrentWorkspaceUserList, (state) => state],
  (workspaceUserList, state) => {
    if (workspaceUserList.length === 0) {
      return [];
    }

    const searchFilter = state.WorkspaceReducer.workSpaceUsersFilter;
    const userTypeFilter = state.WorkspaceReducer.userTypeFilter;
    const userStatusFilter = state.WorkspaceReducer.userStatusFilter;
    const isAnyUserTypeSelected = Object.values(userTypeFilter).some(value => value);
    const isAnyUserStatusSelected = Object.values(userStatusFilter).some(value => value);

    return workspaceUserList
      .filter(user => {
        if (isTempId(user._id)) {
          return false;
        }

        // Check if user has a profile object
        const { firstName, lastName, email } = user?.profile || {};
        const searchFields = firstName || lastName ? [firstName, lastName, email] : [user?.email];
        const matchesSearch = searchFilter ? searchFields.some(field =>
          field?.toLowerCase().includes(searchFilter.toLowerCase())
        ) : true;

        const matchesUserType = isAnyUserTypeSelected ? userTypeFilter[user?.role] : true;
        const matchesUserStatus = isAnyUserStatusSelected ? userStatusFilter[user?.status] : true;

        return matchesSearch && matchesUserType && matchesUserStatus;
      })
      .sort((a, b) => {
        // Sorting logic
        if (a.role === 'owner' && b.role !== 'owner') {
          return -1;
        }
        if (a.role !== 'owner' && b.role === 'owner') {
          return 1;
        }
        return 0;
      });
  })


export const getAllKanbanIdsAndNames = createSelector(
  [
    (state, currentBoardId) =>
      kanbanSelectByQuery(state.DBKanbanReducer, currentBoardId),
  ],
  (kanban: Kanban[]) => {
    return kanban.map((kanban, index) => [kanban._id, kanban.name]);
  }
);
export const getAllGanttIdsAndNames = createSelector(
  [
    (state, currentBoardId) =>
      ganttSelectByQuery(state.DBGanttReducer, currentBoardId),
  ],
  (gantt: Gantt[]) => {
    return gantt.map((gantt, index) => [gantt._id, gantt.name]);
  }
);

export const getSelectedBoardCompleteUserListEqual = createSelector(
  [
    (state) => getSelectedBoardCompleteUserList(state),
  ],
  (userList: AugmentedBlueticksBoardUser[]) => {
    return userList;
  }
)


export const getSelectedBoardCompleteUserList = createDeepEqualSelector(
  [
    (state: CommonRootState, boardId?: string) => getSelectedBoardUserList(state, boardId),
    (state) => getMockPersonList(state),
  ],
  (
    boardUserList: BlueticksBoardUser[],
    mockPersonList
  ): AugmentedBlueticksBoardUser[] => {
    const userList: AugmentedBlueticksBoardUser[] = boardUserList.map(
      (boardUser) => ({
        ...boardUser,
        type: "user",
      })
    );
    return [...userList, ...mockPersonList];
  }
);

export const getActiveWorkspaceUserListNotInCurrentBoard = createSelector(
  [
    (state) => getCurrentWorkspaceUserList(state),
    (state) => getSelectedBoardUserList(state),
  ],
  (
    workspaceUserList: BlueticksWorkspaceUser[],
    boardUserList: BlueticksBoardUser[]
  ): BlueticksWorkspaceUser[] => {
    return workspaceUserList
      .filter((workspaceUser) => workspaceUser.status === "active")
      .filter(
        (workspaceUser) =>
          !boardUserList.find(
            (boardUser) => workspaceUser.userId === boardUser.userId
          )
      );
  }
);

export const getActiveWorkSpaceUsers = createSelector(
  [(state) => getCurrentWorkspaceUserList(state)],
  (workspaceUserList: BlueticksWorkspaceUser[]): BlueticksWorkspaceUser[] => {
    return workspaceUserList.filter(
      (workspaceUser) => workspaceUser.status === "active"
    );
  }
);

// write a selector that returns workspace members
export const isCurrentUserMemberOrOwner = createSelector(
  (state) => state,
  (state) => selectUserObject(state),
  (state) => getCurrentWorkspaceUserList(state),
  (
    state: CommonRootState,
    currentUser: User,
    workspaceUserList: BlueticksWorkspaceUser[]
  ) => {


    const workSpaceMembers = workspaceUserList.filter(
      (workspaceUser) => workspaceUser?.role === "member" || workspaceUser?.role === "owner"
    );

    //check if current user is inside workSpaceMembers return boolean value
    const isCurrentUserMember = workSpaceMembers.some((member) => member.userId === currentUser._id);


    return isCurrentUserMember;
  }
);

export const getSelectedBoardName = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): string => {
    return board?.name ?? "";
  }
);

export const getBoardNameById = (state, boardId) => {
  const board = state.DBBoardsReducer.entities[boardId];
  return board ? board.titleIcon + board.name : "";
};

export const isBoardExistforWaContact = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): boolean => {
    return board !== null;
  }
);

export const getSelectedBoardIsShareByLink = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): boolean => {
    return board?.isShareByLink;
  }
);

export const getSelectedBoardKanbanId = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): NullableId => {
    return board?.kanbanId;
  }
);
export const getSelectedBoardGanttId = createSelector(
  [(state) => getCurrentBoard(state)],
  (board: Board): NullableId => {
    return board?.ganttId;
  }
);



export const getRecentBoards = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let boardsFilter: any = {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    };

    if (state.BoardsReducer.boardsFilterName) {
      boardsFilter.name = new RegExp(state.BoardsReducer.boardsFilterName, "i");
    }

    const currentBoards = boardsSelectByQuery(
      state.DBBoardsReducer,
      boardsFilter,
      ["_id", "name", "titleIcon", "owner", "updatedAt", "workspace"],
      {
        updatedAt: -1,
      }
    );

    return currentBoards;
  }
);



export const getActiveBoards = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let boardsFilter: any = {
      $and: [
        {
          $or: [
            { archived: { $exists: false } },
            { archived: false }
          ]
        }
      ]
    };

    if (state.BoardsReducer.boardsFilterName) {
      boardsFilter.name = new RegExp(state.BoardsReducer.boardsFilterName, "i");
    }



    const currentBoards = boardsSelectByQuery(
      state.DBBoardsReducer,
      boardsFilter,
      [
        "_id",
        "name",
        "titleIcon",
        "owner",
        "updatedAt",
        "workspace"
      ],
      {
        updatedAt: -1,
      }
    );

    return currentBoards;
  }
);

export const getCurrentBoards = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let boardsFilter: any = {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    };

    if (state.BoardsReducer.boardsFilterName) {
      boardsFilter.name = new RegExp(state.BoardsReducer.boardsFilterName, "i");
    }

    const currentBoards = boardsSelectByQuery(
      state.DBBoardsReducer,
      boardsFilter,
      ["_id", "name", "order", "updatedAt"],
      {
        order: -1,
      }
    );

    return currentBoards;
  }
);

export const getAllBoards = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let boardsFilter: any = {};

    const currentBoards = boardsSelectByQuery(
      state.DBBoardsReducer,
      boardsFilter,
      ["_id", "name", "order", "updatedAt"],
      {
        order: -1,
      }
    );

    return currentBoards;
  }
);

export const getFirstBoardId = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    let boardsFilter: any = {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    };

    if (state.BoardsReducer.boardsFilterName) {
      boardsFilter.name = new RegExp(state.BoardsReducer.boardsFilterName, "i");
    }

    const [currentBoards] = boardsSelectByQuery(
      state.DBBoardsReducer,
      boardsFilter,
      ["_id", "name", "order", "updatedAt"],
      {
        order: -1,
      }
    );

    return currentBoards._id;
  }
);


export const selectPendingBoardUsers = createSelector(
  [state => state, selectCurrentUser],
  (state, user) => {

    return boardUserSelectByQuery(state.DBBoardUserReducer, {
      userId: user._id,
      workspaceId: user.currentWorkspace,
      status: "pending",
      deleted: false,
    });
  }

);


export const isCurrentBoardOwnerSelector = createSelector(
  [
    (state) => getCurrentBoard(state),
    (state) => selectCurrentUser(state),
  ],
  (board: Board, user: User) => {
    return board?.owner && board?.owner === user?._id;
  }
);

export const isCurrentUserWorkspaceOwnerOrMember = createSelector(
  [
    (state) => getCurrentWorkspaceUserList(state),
    (state) => selectCurrentUser(state),
  ],
  (workspaceUserList: any, user: User) => {
    const currentWorkspaceUser = workspaceUserList.find(
      (workspaceUser) => workspaceUser.userId === user._id
    );
    return currentWorkspaceUser?.role === "owner" || currentWorkspaceUser?.role === "member";
  }
);

// export const getViewFiltersLength = createSelector(

//     [
//       (state) => getSelectedBoardId(state),
//     ],
//     (boardId, DBViewFiltersReducer) => {
//       return viewFiltersSelectByQueryOnlyLength(DBViewFiltersReducer, {
//         viewId: boardId,
//         $or: [{ deleted: { $exists: false } }, { deleted: false }],
//       });
//     }
//   );

export const getViewFiltersLength = createSelector(
  [
    (state) => state,
    getSelectedBoardId,
  ],
  (state, boardId) => {
    return viewFiltersSelectByQueryOnlyLength(state.DBViewFiltersReducer, {
      viewId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
  }
);




export const isUserBoardOwner = createSelector(
  [(state) => state, (state, props) => props.boardId],
  (state, boardId) => {
    const user = selectCurrentUser(state);
    const board = boardsSelectOneObjectByQuery(state.DBBoardsReducer, {
      _id: boardId,
    });
    return board?.owner && board?.owner === user?._id;
  }
);
export const isCurrentWorkspaceOwnerSelector = createSelector(
  [(state) => state, (state) => selectCurrentUser(state)],
  (state: CommonRootState, user: User) => {
    if (!user?.currentWorkspace) {
      return false;
    }

    const [currentWorkspaceUser] = workspaceUserSelectByQuery(
      state.DBWorkspaceUserReducer,
      {
        userId: user._id,
        workspaceId: user?.currentWorkspace,
      }
    );

    return currentWorkspaceUser?.role === "owner";
  }
);

export const selectUserListAndCurrentUser = createSelector(
  (state) => state,
  (state) => selectUserObject(state),
  (state) => getCurrentWorkspaceUserList(state),
  (
    state: CommonRootState,
    currentUser: User,
    workspaceUserList: BlueticksWorkspaceUser[]
  ) => {
    const userList = workspaceUserList ?? [];
    const userName = currentUser?.fullName?.split(" ");
    const currentUserObj = {
      email: currentUser.email,
      profile: {
        email: currentUser?.email,
        firstName: userName?.length > 0 ? userName[0] : "",
        lastName: userName?.length > 1 ? userName[1] : "",
        profileImg: currentUser?.profileImg,
      },
      role: "member",
      status: "active",
      userId: currentUser._id,
    };
    const mergedUserList = [...userList, currentUserObj];
    return mergedUserList.filter((person) => !!person.userId);
  }
);

export const userRoleCountSelector = createSelector(
  [
    (state) => getCurrentWorkspaceUserList(state),
    (state, roleType) => roleType,
  ],
  (workspaceUserList: BlueticksWorkspaceUser[], roleType) => {
    return (workspaceUserList ?? []).reduce(
      (guestCount, user) => guestCount + (user?.role === roleType ? 1 : 0),
      0
    );
  }
);

export const getFeatureListForTasksHeaderSelector = createSelector(
  [
    (state) => state,
    (state) => getSelectedBoardId(state),
    (_, indicator) => indicator,
    (_, __, width) => width,
    (_, __, ___, tabsRef) => tabsRef,
  ],
  (state, selectedBoardId, indicator, width, tabsRef) => {
    const offset = 250;
    let widthOfParent = width - offset;
    //console.log(`length of tabs: ${tabsRef?.current?.length}`)
    let numberOfVisibleFeatures = 0;
    for (let i = 0; i < tabsRef?.current?.length; i++) {
      if (widthOfParent - tabsRef?.current[i].offsetWidth > 0) {
        widthOfParent = widthOfParent - tabsRef?.current[i].offsetWidth;
        numberOfVisibleFeatures++;
      }
    }

    // console.log(`Width: ${width}, number of visible features: ${numberOfVisibleFeatures}`)

    const _kanbansList = kanbanSelectByQuery(
      state.DBKanbanReducer,
      {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );

    const _formsList = formSelectByQuery(
      state.DBFormReducer,
      {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );

    const _ganttsList = ganttSelectByQuery(
      state.DBGanttReducer,
      {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );

    const _workloadsList = workloadSelectByQuery(
      state.DBWorkloadReducer,
      {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );
    const _boardsViewList = boardViewSelectByQuery(
      state.DBBoardViewReducer,
      {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );
    const featureList = [
      { _id: selectedBoardId, name: "Board", type: "board" },
      ..._kanbansList.map((obj) => ({ ...obj, type: "kanban" })),
      ..._formsList.map((obj) => ({ ...obj, type: "form" })),
      ..._ganttsList.map((obj) => ({ ...obj, type: "gantt" })),
      ..._workloadsList.map((obj) => ({ ...obj, type: "workload" })),
      ..._boardsViewList.map((obj) => ({ ...obj, type: "boardView" })),
    ];

    // const offset = 2
    // const numberOfFeatureToRemove = featureList.length - numberOfVisibleFeatures - offset

    return indicator === -1
      ? featureList
      : featureList.slice(0, numberOfVisibleFeatures);
  }
);

export const doesEmailExistOnBoardUsers = createSelector(
  [(state) => state, (state, email) => email],
  (state: CommonRootState, email: string) => {
    const boardId = state.BoardsReducer?.selectedBoardId;
    const foundUser = boardUserSelectOneObjectByQuery(
      state.DBBoardUserReducer,
      {
        boardId,
        email: email.toLowerCase(),
      }
    );
    return !!foundUser;
  }
);

export const getRemainingFeatureForMoreButton = createSelector(
  [
    (state, searchQuery) => state,
    (state, searchQuery) => searchQuery,
    (_, ___, width) => width,
    (_, ___, ____, tabsRef) => tabsRef,
  ],
  (state, searchQuery, width, tabsRef) => {
    const fullFeatureList = getFeatureListForTasksHeaderSelector(
      state,
      -1,
      width,
      tabsRef
    );

    const offset = 250;
    let widthOfParent = width - offset;
    //console.log(`length of tabs: ${tabsRef?.current?.length}`)
    let numberOfVisibleFeatures = 0;
    for (let i = 0; i < tabsRef?.current?.length; i++) {
      if (widthOfParent - tabsRef?.current[i].offsetWidth > 0) {
        widthOfParent = widthOfParent - tabsRef?.current[i].offsetWidth;
        numberOfVisibleFeatures++;
      }
    }

    // console.log(`Width: ${width}, number of visible features: ${numberOfVisibleFeatures}`)

    return searchQuery === ""
      ? fullFeatureList.slice(
        -(fullFeatureList.length - numberOfVisibleFeatures)
      )
      : fullFeatureList
        .slice(-(fullFeatureList.length - numberOfVisibleFeatures))
        .filter((feature) => {
          if (feature.name === "") {
            return feature.type
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase());
          } else {
            return feature.name
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase());
          }
        });
  }
);

export const cellPersonListSelector = createSelector(
  [
    (state) => state,
    (state, personPickerValueList: PersonPickerValue[]) =>
      personPickerValueList,
    (
      state: CommonRootState,
      personPickerValueList: PersonPickerValue[],
      boardId?: string
    ) => boardId ?? getCurrentBoardId(state),
  ],
  (
    state,
    personPickerValueList: PersonPickerValue[],
    boardId: string
  ): AugmentedBlueticksBoardUser[] => {
    if (!Array.isArray(personPickerValueList) || !personPickerValueList) {
      return [];
    }
    const boardUserList: AugmentedBlueticksBoardUser[] = boardUserSelectByQuery(
      state.DBBoardUserReducer,
      {
        boardId,
        _id: {
          $in: (personPickerValueList ?? [])
            .filter((value) => value?.type === "user")
            .map((value) => value?.id),
        },
      }
    ).map((workspaceUser) => ({
      ...workspaceUser,
      type: "user",
    }));
    const _optionList = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        _id: {
          $in: (personPickerValueList ?? [])
            .filter((value) => value?.type === "option" || value?.type === "user")
            .map((value) => value?.id),
        },
      }
    );
    const optionList = _optionList.map((option) => {
      const [firstName, lastName] = option?.label?.split(" ");
      const workspaceUser: AugmentedBlueticksBoardUser = {
        _id: option?._id,
        status: "active",
        profile: {
          firstName,
          lastName,
          profileImg: option?.imageUrl,
        } as User,
        type: "option",
      };
      return workspaceUser;
    });

    return [...boardUserList, ...optionList];
  }
);

export const isBoardPanelDrawerOpen = createSelector(
  [(state) => state],
  (state) => {
    const user = selectUser(state);
    return user?.isBoardsPanelOpen ?? true;
  }
);


export const getBoardsPaginationStatus = createSelector(
  [(state) => state],
  (state) => {
    const boards = BoardsSelectPaginationStatus(state.DBBoardsReducer, {
    })

    return boards
  }
);


// export const getBoardNameById = createSelector(
//   (state: CommonRootState, boardId: string) => {
//     const boards = boardsSelectByQuery(state.DBBoardsReducer, {
//       _id: boardId,
//     });
//     return boards[0];
//   },
//   (board): string | undefined => {
//     return board?.name;
//   }
// );
export const getBulkListsPaginationStatus = createSelector(
  [(state) => state],
  (state) => {
    const bulkLists = BulkListSelectPaginationStatus(state.DBBulkListReducer, {
    });

    return bulkLists
  }
);

export const getDrawerWidth = createDeepEqualSelector(
  [(state) => state],
  (state) => {
    const board = getCurrentBoard(state)
    const width = board?.drawerWidth ? board.drawerWidth : 500;

    return width
  }
);
// Selector that returns true if the boardId is for a board with type 'audience'
export const doesGroupBelongToAudienceBoard = createSelector(
  [
    (state) => state,
    (state: CommonRootState, groupId: string) => groupId,
  ],
  (state: CommonRootState, groupId: string) => {
    let boardId = taskGroupsSelectOneFieldByQuery(state.DBTaskgroupsReducer, { _id: groupId }, 'boardId');
    return boardId ? boardsSelectOneObjectByQuery(state.DBBoardsReducer, { _id: boardId })?.type === "audience" : false;
  }
);

// Selector that returns true if the taskId belongs to an audience board
export const doesTaskBelongToAudienceBoard = createSelector(
  [
    (state) => state,
    (state: CommonRootState, taskId: string) => taskId,
  ],
  (state: CommonRootState, taskId: string) => {
    let boardId = tasksSelectOneFieldById(state.DBTasksReducer, taskId, 'boardId');
    return boardId ? boardsSelectOneObjectByQuery(state.DBBoardsReducer, { _id: boardId })?.type === "audience" : false;
  }
);
