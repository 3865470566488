import { SxProps } from "@mui/system";
import { DBTasksThunks, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { taskActions } from "@common-reducers/TasksReducer";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { CSSProperties } from "react";
import BtEditableBox from "../bt-editable-box/bt-editable-box";



export interface Props {
    taskId: string;
    key?: string;
    hasBorder?: boolean;
    placeholder?: string;
    style?: CSSProperties;
    className?: string;
    isOnKanban?: boolean;
    boxSx?: SxProps;
    inputSx?: SxProps;
    isMultiline?: boolean;
    rowsMax?: number;
}

export interface InlineEditTextState {
    editing: boolean;
    internalValue?: string;

}

export default function EditTaskInput({
    taskId,
    isOnKanban = false,
    boxSx,
    inputSx,
    isMultiline = false,
    rowsMax = 1,
    placeholder,
}: Props) {
    const dispatch = useAppDispatch()
    const analyticsService: AnalyticsService = useAnalyticsService();

    const text = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "text"))
    
    function updateTaskName(value) {
        if (value) {
            dispatch(DBTasksThunks.patch({ entity: { _id: taskId, text: value, isTaskInEditMode: false } }))
            analyticsService.event("update_task", {
                category: "userEngagement",
                action: "update task",
              });
        
        }
        dispatch(taskActions.setForceEditModeByTaskid(''))
    }
    const forceEditModeByTaskId = useAppSelector((state) => state.TasksReducer.forceEditModeByTaskId)

    const clearEditMode = () => {
        if (forceEditModeByTaskId !== "") {
            dispatch(taskActions.setForceEditModeByTaskid(""))
        }
    }

    const setEditMode = (value) => {
        //setIsEditing(value)
        clearEditMode()
    }
    const boxSxInteranl: SxProps = {
        cursor: 'text',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0 5px',
        border: "1px dashed transparent",
        fontSize: isOnKanban ? '14px' : '15px',
        "&:hover": {
            border: '1px dashed var(--text-color)'
        },
        alignItems: 'center',
        borderRadius: '4px',
        fontFamily: "'Inter', 'Assistant', sans-serif",
    }
    const inputSxInteranl: SxProps = {
        width: '100%',
        cursor: 'text',
        border: '1px solid #53bdeb',
        color: "var(--primary)",
        background: 'var(--board-item-menu-hover)',
        fontSize: '15px',
        fontFamily: "'Inter', 'Assistant', sans-serif",
        padding: '0 5px',
        "& .MuiInputBase-input": {
            padding: "0"
        },
        borderRadius: '4px',
    }
    return (
      

        <BtEditableBox
            data-testid='task-name-bteditablebox'
            inputSx={{ ...inputSxInteranl, ...inputSx }}
            boxSx={{ ...boxSxInteranl, ...boxSx }}
            text={text}
            onValueUpdate={(value) => updateTaskName(value)}
            onEditModeChange={(value) => setEditMode(value)}
            shouldAutoFlex={false}
            isMultiline={isMultiline}
            rowsMax={rowsMax}
            forceEditMode={taskId ===  forceEditModeByTaskId}
            placeholder={placeholder}
        />


    );
}

