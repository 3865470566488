
import { Box, Button, IconButton, Stack } from '@mui/material';
import { recycleActions } from '@common-reducers/RecycleBinReducer';
import { deletePermantley, restoreTheItems } from '@common-reducers/TaskThunks';
import { taskActions } from '@common-reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { BsTrash } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import PromptDeleteAction from '@common-components/prompt-delete-action/prompt-delete-action';
import { useState } from 'react';


export default function ArchivedOrDeletedBulkDialog() {
  const dispatch = useAppDispatch()
  const archivedOrDeletedBulkItems = useAppSelector(state => state.RecycleBinReducer.archivedOrDeletedBulkItems);
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  

  const openDeletePrompt = () => {
    setConfirmDialogOpen(true)
}

const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
    if (shouldDelete === true) {
      dispatch(deletePermantley({ items: [...archivedOrDeletedBulkItems] }));
    }
    setConfirmDialogOpen(false)
    dispatch(recycleActions.setArchivedOrDeletedBulkItems([]))

}

  function handleClose() {
    dispatch(recycleActions.setArchivedOrDeletedBulkItems([]));
  }

  const restoreItems = () => {
    dispatch(restoreTheItems({ items: [...archivedOrDeletedBulkItems], deleteOrArchive: currentTab }));
  }



  return (
    <div id="bulkTasksOperations" style={{
      color: 'var(--primary)',
      bottom: "20px",
      background: "white",
      borderRadius: "9px",
      transformOrigin: "bottom",
      transition: ".2s cubic-bezier(.165,.84,.44,1)",
      position: 'fixed',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>

      <Stack height={"60px"} justifyContent={"center"} direction={"row"} alignItems={"center"} spacing={2} >
        <Stack justifyContent={"center"} alignItems={"center"} style={{
          borderTopLeftRadius: direction === "ltr" ? "9px" : undefined,
          borderBottomLeftRadius: direction === "ltr" ? "9px" : undefined,
          borderBottomRightRadius: direction === "rtl" ? "9px" : undefined,
          borderTopRightRadius: direction === "rtl" ? "9px" : undefined,
          background: "linear-gradient(-90deg,#6dd1f1,#259ae9)",
          color: "#fff",
          fontSize: "28px",
          width: "60px",
          height: "60px"
        }}>
          {archivedOrDeletedBulkItems.length}
        </Stack>
        <Box>Selected items</Box>

        {currentTab === "Trash" ?
          (<Button sx={{ textTransform: "capitalize" }} onClick={openDeletePrompt} variant="text" color="secondary" startIcon={<DeleteIcon />}>
            Delete
          </Button>) : null

        }

        <Button sx={{ textTransform: "capitalize" }} onClick={restoreItems} variant="text" color="secondary" startIcon={<RestoreIcon />}>
          Restore
        </Button>


        <Stack justifyContent={"center"} alignItems={"center"} style={{
          borderInlineStart: "1px solid var(--border-color)",
          height: "60px",
          width: "60px",
          borderTopLeftRadius: direction === "rtl" ? "9px" : undefined,
          borderBottomLeftRadius: direction === "rtl" ? "9px" : undefined,
          borderBottomRightRadius: direction === "ltr" ? "9px" : undefined,
          borderTopRightRadius: direction === "ltr" ? "9px" : undefined
        }}  >
          <IconButton onClick={handleClose}>
            <MdClose size={24} />
          </IconButton>
        </Stack>
      </Stack>
      
      <PromptDeleteAction title={`Delete ${archivedOrDeletedBulkItems.length} ${archivedOrDeletedBulkItems.length > 1 ? 'items' : 'item'}`} handleClose={handleDeleteConfirmationClosed} open={confirmDialogOpen} />
    </div >
  )
}

