import { CellProps } from '@common-components/tasks/columns-cells/cell-interface'
import { TextField } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useState } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'

type Props = {}

export default function TextFormCell({
    cellValue,
    taskId,
    columnId,
    groupId,
    onUpdate,
    isFormInEditMode
}: CellProps) {
    const [value, setValue] = useState<string>('')
    function handleTextChange(event) {
        setValue(event.target.value)
        onUpdate(event.target.value);
    }
    function handleClearValue() {
        setValue('')
        onUpdate(undefined);
    }
    return (
        <Stack direction='row' sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        }}>
            <TextField
                style={{ width: '260px', }}
                disabled={isFormInEditMode ? true : false}
                id="outlined-helperText"
                value={value}
                onChange={(event) => handleTextChange(event)}
            />
            {cellValue && (
                <RiCloseCircleFill
                    onClick={handleClearValue}
                // className={classes.clearIcon}
                />
            )}
        </Stack>
    )
}