import { Stack } from '@mui/system';
import classes from "./form.module.scss";
import { DBFormThunks, formSelectOneFieldByQuery, isTempId } from "@common-reducers/DBServiceThunks";
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { DragIndicator, RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import FormColumnCell from './form-column-cell';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { TaskColumn } from '@common-models/task-column';
import { DraggableProvided } from 'react-beautiful-dnd';

type Props = {
    index: number;
    formId: string;
    column: TaskColumn;
    draggableProvided: DraggableProvided;
}

export default function FormsRow({
    index,
    formId,
    column,
    draggableProvided,
}: Props) {
    const dispatch = useAppDispatch()
    const columnData = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'columnData')) ?? {}


    const handleRequiredCheckBoxChange = (value, column) => {
        dispatch(DBFormThunks.patch({
            entity: {
                _id: formId,
                columnData: {
                    ...columnData,
                    [column._id]: {
                        ...columnData[column._id],
                        required: value,
                    }
                }
            }
        }))
    }

    const isRequired = (column) => {
        if (!isTempId(formId)) {
            if (columnData && columnData[column._id] && columnData[column._id].required !== undefined) {
                return columnData[column._id].required
            }
            else {
                return false;
            }
        }

    }

    const isVisible = (column) => {
        if (!isTempId(formId)) {
            if (columnData && columnData[column._id] && columnData[column._id].visible !== undefined) {
                return columnData[column._id].visible
            }
            else {
                return true;
            }
        }

    }

    const invisibleColumnTitle = (column) => {
        const cell = columnTypeList.find(columnType => columnType.type === column.type)

        let title = (columnData && columnData.title) ? columnData.title : column.title ?? cell?.title

        return (<p style={{ color: 'grey' }}>The column <b>"{title}"</b> is hidden on this form</p>)
    }



    const handleVisibleClick = (column) => {
        dispatch(DBFormThunks.patch({
            entity: {
                _id: formId,
                columnData: {
                    ...columnData,
                    [column._id]: {
                        ...columnData[column._id],
                        visible: columnData[column._id].visible === undefined ? false : !(columnData[column._id].visible),
                    }
                }
            }
        }))
    }

    return (
        <Stack
            spacing={2}
            direction='row'
            ref={draggableProvided.innerRef} key={index}
            {...draggableProvided.draggableProps} className={classes.form_cell}>
            {isVisible(column) &&
                <>
                    <div style={{
                        display: 'flex',
                    }}
                        className={classes.drag_handle}>
                        <Button
                            sx={{
                                minWidth: 0,
                                padding: 0,
                                backgroundColor: 'transparent !important',
                            }}
                            {...draggableProvided.dragHandleProps} >
                            <DragIndicator />
                        </Button>
                    </div>
                    <div style={{ paddingInlineEnd: '236px', }}>
                        {['checkbox', 'timeline-picker', 'rating', 'datepicker', 'status-option-picker'].includes(column?.type) &&
                            <div style={{ width: '226px', }}>
                                <FormColumnCell
                                    isFormInEditMode={true}
                                    columnId={column._id}
                                    formId={formId}
                                />
                            </div>
                        }
                        {!['checkbox', 'timeline-picker', 'rating', 'datepicker', 'status-option-picker'].includes(column?.type) &&
                            <div style={{ width: '226px', }}>
                                <FormColumnCell
                                    isFormInEditMode={true}
                                    columnId={column._id}
                                    formId={formId}
                                />
                            </div>
                        }
                    </div>
                    <Stack direction='row' className={classes.required_and_visible}>
                        <div className={classes.required_checkbox}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={(event) => {
                                        handleRequiredCheckBoxChange(event.target.checked, column)
                                    }}
                                    checked={isRequired(column)}
                                />}
                                label={<span style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    fontFamily: "'Inter', 'Assistant', sans-serif",
                                    marginLeft: "-4px",
                                }} className={classes.required_label}
                                >
                                    {'Required'}
                                </span>}
                                style={{
                                    padding: '0 2px',
                                    height: 32
                                }}
                            />
                        </div>
                        <Button
                            style={{ width: 32, height: 32, minWidth: 32 }}
                            className={classes.visible}
                            onClick={(event) => { handleVisibleClick(column) }}
                        >{isVisible(column) ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}</Button>
                    </Stack>
                </>
            }
            {!isVisible(column) &&
                <>
                    <div style={{ display: 'flex', }}>
                        <Button
                            startIcon={<DragIndicator />}
                            className={classes.drag_handle}
                            sx={{
                                minWidth: '10px',
                                paddingLeft: '15px',
                                backgroundColor: 'transparent !important',
                            }}
                            {...draggableProvided.dragHandleProps} />
                        {invisibleColumnTitle(column)}
                    </div>
                    <Button
                        startIcon={isVisible(column) ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
                        onClick={(event) => {
                            handleVisibleClick(column)
                        }}
                    />
                </>
            }

        </Stack>
    )
}