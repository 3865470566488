import PromptDeleteAction from "@common-components/prompt-delete-action/prompt-delete-action";
import { appConfig } from "@common-config/app.config";
import { boardsActions } from "@common-reducers/BoardsReducer";
import { isCurrentWorkspaceOwnerSelector, isUserBoardOwner } from "@common-reducers/BoardsSelectors";
import { boardsSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { UndoRedoAction } from "@common-reducers/UndoRedoReducer";
import { doActionThunk } from "@common-reducers/UndoRedoThunks";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { ClickAwayListener, Popper } from "@mui/material";
import { memo, useState } from "react";
import { BsBoxArrowUpRight, BsPencil, BsTrash } from "react-icons/bs";
import { PickerProps } from "../columns-cells/cell-interface";
import classes from "./board-menu-picker.module.scss";


function BoardMenuPicker({ boardId }: PickerProps) {
    const dispatch = useAppDispatch()

    const pickerAnchorId = useAppSelector((state) => state.PickerDialogReducer.pickerAnchorId);
    //const isBoardOwner = useAppSelector(isCurrentBoardOwnerSelector);
    const isBoardOwner = useAppSelector(state => isUserBoardOwner(state, { boardId }));
    const isCurrentWorkspaceOwner = useAppSelector(isCurrentWorkspaceOwnerSelector);
    const boardName = useAppSelector(state => boardsSelectOneFieldByQuery(state.DBBoardsReducer, {
        _id: boardId
    }, 'name'))
    const [deletePromptOpen, setDeletePromptOpen] = useState(false);
    let menuAnchorEl = document.getElementById(pickerAnchorId);

    // menu open board in new tab
    function openBoardInNewTab() {
        const boardURL = `${appConfig.APP_ENDPOINT}/tasks/?boardID=${boardId}`;
        if (isExtensionContextOrWhatsappView()) {
            window.open(boardURL, "_blank");
            //ContentMessageServiceActions.openTab(boardURL);
        } else {
            window.open(boardURL, '_blank')
        }
        dispatch(pickerDialogActions.closePickerDialog())

    }
    const renameBoard = () => {
        dispatch(boardsActions.setBoardEditModeByBoardId(boardId))
        dispatch(pickerDialogActions.closePickerDialog())

    }
    //menu delete board
    function deleteBoard(event: React.MouseEvent) {
        dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
        setDeletePromptOpen(true);
    }
    const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            const action: UndoRedoAction<string[], string> = {
                type: 'delete-board-list',
                data: [boardId],
                deletedOrArchived:"deleted"
            }
            dispatch(doActionThunk({ action }),)
        }
        setDeletePromptOpen(false);
        dispatch(pickerDialogActions.closePickerDialog())

        menuAnchorEl = null
    }

    const archiveBoard = ()=>{
        const action: UndoRedoAction<string[], string> = {
            type: 'delete-board-list',
            data: [boardId],
            deletedOrArchived:"archived"
        }
        dispatch(doActionThunk({ action }),)
    }


    return (<>
        <PromptDeleteAction title={`Delete board ${boardName} ?`} handleClose={handleDeleteConfirmationClosed} open={deletePromptOpen} />
        <ClickAwayListener onClickAway={() => { dispatch(pickerDialogActions.closePickerDialog()) }}>
            <div style={{ position: 'relative', zIndex: 1300 }}>
                <Popper
                    anchorEl={menuAnchorEl}
                    open={!deletePromptOpen && menuAnchorEl !== null}
                    style={{ zIndex: 1300, position: 'absolute', direction: 'ltr' }}
                >
                    <div className={classes.menu_wrapper} style={{
                        position: pickerAnchorId.includes("board_panel_item") ? 'absolute' : 'unset',
                        left: pickerAnchorId.includes("board_panel_item") && 95
                    }}>
                        <div className={classes.menu_head}>
                            <button className={classes.menu_list_item} onClick={openBoardInNewTab}>
                                <BsBoxArrowUpRight />
                                <span style={{ marginLeft: 8 }}>  Open Board In New Tab</span>
                            </button>

                            <button onClick={renameBoard} className={classes.menu_list_item}>
                                <BsPencil />
                                <span style={{ marginLeft: 8 }}>Rename board</span>
                            </button>


                        </div>
                        {/* <button className={classes.menu_list_item}>
                            <MdOutlineContentCopy />
                            <span style={{ marginLeft: 8 }}>Duplicate board</span>
                        </button> */}
                        <div className={classes.menu_footer}>
                            <div data-testid="board-panel-item-menu-delete-button">
                                <button
                                    style={{
                                        color: isBoardOwner || isCurrentWorkspaceOwner ? "" : 'lightgrey',
                                        cursor: isBoardOwner || isCurrentWorkspaceOwner ? 'pointer' : 'default'
                                    }}
                                    disabled={!(isBoardOwner || isCurrentWorkspaceOwner)} 
                                    onClick={(e) => deleteBoard(e)}
                                    className={classes.menu_list_item}>
                                    <BsTrash />
                                    <span style={{ marginLeft: 8 }}>Delete board</span>
                                </button>
                            </div>

                            {/* <div data-testid="board-panel-item-menu-archive-button">
                                <button style={{ color: !isBoardOwner ? 'lightgrey' : "", cursor: !isBoardOwner ? 'default' : "pointer" }} disabled={!isBoardOwner} onClick={archiveBoard} className={classes.menu_list_item}>
                                   <FiArchive />
 
                                    <span style={{ marginLeft: 8 }}>Archive board</span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    </>
    );
}

export default memo(BoardMenuPicker);
