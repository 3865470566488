import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, MenuItem, Select } from "@mui/material";
import { Stack } from '@mui/system';
import { DBViewFiltersThunks, isTempId, viewFiltersSelectors } from "@common-reducers/DBServiceThunks";
import { getColumnList } from '@common-reducers/TaskSelectors';
import { addNewfilterConditionThunk, getFilterConfigItemByColumnType, getFilterConfigItemByColumnTypeAndSelectedOperator, getFilterDataByFilterColumnIdForFilterRow, valuesListSelector } from "@common-reducers/TasksFilterPanelThunks";
import { NullableId } from "@common/types/interface";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from '@common/config/constants';
import { memo, useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { ViewFilter } from "./advanced_filters";
import { FilterRowColumnField } from './filter_row_column_field';
import FilterRowOperatorField from './filter_row_operator_field';
import FilterRowValueField from './filter_row_value_field';

type AdvancedFilterRowProps = {
    viewFilterId: NullableId;
    isFirstFilter: boolean;
    viewId: any;
};
export const AdvancedFilterRow = ({ viewFilterId, isFirstFilter, viewId }: AdvancedFilterRowProps) => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const filter: ViewFilter = useSelector((state) => viewFiltersSelectors.selectById(state, viewFilterId));
    const filterData = useAppSelector(state => getFilterDataByFilterColumnIdForFilterRow(state, filter?.filterColumnId))
    const [andOrOperator, setAndOrOperator] = useState<string>(viewId ? filter?.filterAndOr : 'and')
    const [columnField, setColumnField] = useState<string>(viewId ? filterData?.columnTitle : "")
    const [operatorField, setOperatorField] = useState<string>(viewId ? filter?.filterOperator : "")
    const [valueField, setValueField] = useState<string>(viewId ? filter?.filterValue : "")
    const [, setPersonFilterValue] = useState<string>()
    const [selectedColumnId, setSelectedColumnId] = useState<any>(filterData.columnId);
    const [selectedColumnType, setSelectedColumnType] = useState<string>(filterData.columnType);
    const valueFilterOptionList = useAppSelector((state) => valuesListSelector(state, selectedColumnType, selectedColumnId, operatorField));
    const selectedFilterItem = useAppSelector((state) => getFilterConfigItemByColumnType(state, selectedColumnType));
    const columnList = useAppSelector(getColumnList);
    const selectedFilterItemOperator = useAppSelector((state) => getFilterConfigItemByColumnTypeAndSelectedOperator(state, selectedColumnType, operatorField));
    const resetState = useCallback(() => {
        setAndOrOperator(isFirstFilter ? undefined : 'and');
        setColumnField('');
        setOperatorField('');
        setValueField('');
        setSelectedColumnId(undefined);
        setSelectedColumnType(undefined);
    }, [isFirstFilter]);


    const andOrOperatorChange = (andOrOperatorValue) => {
        setAndOrOperator(andOrOperatorValue)
        if (filter) {
            UpdateRow({ filterAndOr: andOrOperatorValue })
        }
    }

    const handleColumnChange = (columnItemId: string) => {
        setValueField('')
        if (columnItemId === 'groupColumn') {
            setSelectedColumnType('group')
            setSelectedColumnId('groupColumn')
            setColumnField('Group')
        } else {
            const selectedColumn = columnList.find(column => columnItemId === column._id);
            setSelectedColumnId(selectedColumn?._id);
            setSelectedColumnType(selectedColumn?.type);
            setColumnField(selectedColumn?.title)
        }
        UpdateRow({ filterColumnId: columnItemId, filterValue: '' })
    };

    const handleOperatorChange = (value: string) => {
        setOperatorField(value)
        const isEmptyOrNotEmptyOperator: boolean = (value === 'is empty' || value === 'is not empty')
        const isCheckboxColumn = selectedColumnType === "checkbox" && (value === "is checked" || value === "is not checked")

        if (isCheckboxColumn) {
            if (filter) {
                UpdateRow({ filterOperator: value, filterValue: value === 'is checked' ? 'Checked' : '' });
            } else {
                addNewFilterRow({ filterOperator: value, filterValue: value === 'is checked' ? 'Checked' : '' })
            }
        } else if (isEmptyOrNotEmptyOperator) {
            if (filter) {
                UpdateRow({ filterOperator: value, filterValue: '' });
            }
            else {
                addNewFilterRow({ filterOperator: value, filterValue: '' })
            }

        } else {
            if (filter) {
                if (value === 'contains' || value === 'does not contain') {
                    setValueField('')
                    UpdateRow({ filterOperator: value, filterValue: '' });
                } else {
                    UpdateRow({ filterOperator: value });
                }
            } else {
                addNewFilterRow({ filterOperator: value, filterValue: '' })
            }
        }
    };

    const handleChangeValueField = (value) => {
        if (value === 'is checked') {
            setValueField('Checked')
        } else if (value === 'is not checked') {
            setValueField('blank')
        }

        const selectedPerson = valueFilterOptionList.find(personObject => personObject?.id === valueField);
        if (selectedPerson) {
            setPersonFilterValue(selectedPerson.value)
        }
        setValueField(value)
        if (filter) {
            UpdateRow({ filterValue: value });
        } else {
            addNewFilterRow({ filterValue: value })
        }
    }

    const addNewFilterRow = useCallback(async (filterCondition: ViewFilter) => {
        const operatorFieldIsEmptyOrNotEmpty = filterCondition.filterOperator === 'is empty' || filterCondition.filterOperator === 'is not empty'
        if (selectedColumnId && (filterCondition.filterOperator || operatorField) && (operatorFieldIsEmptyOrNotEmpty || (valueField || filterCondition.filterValue))) {
            const clonedFilterCondition = {
                filterColumnId: selectedColumnId,
                filterAndOr: andOrOperator,
                filterOperator: filterCondition.filterOperator || operatorField,
                filterValue: filterCondition.filterValue || valueField
            }
            resetState();
            await dispatch(addNewfilterConditionThunk(clonedFilterCondition));
        }
    }, [selectedColumnId, operatorField, valueField, andOrOperator, resetState, dispatch]);

    const UpdateRow = useCallback(async (filterCondition: ViewFilter) => {
        if (isTempId(filter?._id)) {
            return;
        }
        const patchEntity = {
            entity: {
                _id: filter?._id,
                ...filterCondition,
            },
        };
        await dispatch(DBViewFiltersThunks.patch(patchEntity));
    },
        [dispatch, filter?._id]
    );

    function deleteFilterRow(filterId) {
        dispatch(DBViewFiltersThunks.delete({ entity: { _id: filterId } }));
        setColumnField('')
        setValueField('')
    }



    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', gap: '10px', position: 'relative', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 2fr 2fr 3fr 0.5fr', gap: '10px', position: 'relative', alignItems: 'center', width: '100%' }}>
                    {(isFirstFilter) &&
                        <div style={{
                            width: '70px',
                            height: '47px',
                            display: 'flex',
                            alignItems: 'center',

                        }}>{t('advancedFilterRow.where')}</div>}
                    {!isFirstFilter && (
                        <div style={{ width: '70px' }} >
                            <Select
                                className="and-or-select"
                                id=""
                                label=""
                                onChange={(e) => andOrOperatorChange(e.target.value)}
                                name="column"
                                value={andOrOperator ?? ''}
                                sx={{
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    height: '47px',
                                    padding: '0',

                                    '&.and-or-select .MuiOutlinedInput-input ': {
                                        paddingRight: '0 !important',
                                    },

                                }}
                                placeholder="Column"
                            >
                                <MenuItem selected value={andOrOperator === 'and' ? 'and' : 'or'}> {andOrOperator === 'and' ? <span style={{ fontSize: "14px" }}>And</span> : <span style={{ fontSize: "14px", }}>Or</span>}
                                </MenuItem>
                                <MenuItem selected value={andOrOperator === 'and' ? 'or' : 'and'}> {andOrOperator === 'and' ? <span style={{ fontSize: "14px" }}>Or</span> : <span style={{ fontSize: "14px", }}>And</span>}
                                </MenuItem>
                            </Select>
                        </div>
                    )}
                    <Stack sx={{ width: '100%' }}>
                        <FilterRowColumnField
                            columnField={columnField}
                            onColumnChange={handleColumnChange}
                        />
                    </Stack>
                    <Stack sx={{ width: '100%' }}>
                        <FilterRowOperatorField
                            operatorField={operatorField}
                            onOperatorChange={handleOperatorChange}
                            selectedColumnType={selectedColumnType}
                        />
                    </Stack>
                    {columnField && !selectedFilterItemOperator?.isHideValueField && !(selectedFilterItem.isHideValueField) ? (
                        <Stack sx={{ width: '100%' }}>
                            <FilterRowValueField
                                isDisableTempId={isTempId(filter?._id)}
                                selectedColumnType={selectedColumnType}
                                selectedColumnId={selectedColumnId}
                                operatorField={operatorField}
                                valueField={valueField ?? ''}
                                handleChangeValueField={handleChangeValueField}
                            />
                        </Stack>
                    ) : (<div style={{ width: '100%' }}></div>)}
                    {
                        filter &&
                        <IconButton
                            sx={{ color: 'rgba(0, 0, 0, 0.26)', borderRadius: '50%', minWidth: 'unset', justifySelf: 'flex-end', width: '28px', height: '28px', '&:hover': { color: 'rgba(0, 0, 0, 0.4)' } }}
                            onClick={() => deleteFilterRow(filter?._id)}  >
                            <CancelIcon />
                        </IconButton>
                    }
                </div>
            </div>
        </div >
    );
};
export default memo(AdvancedFilterRow);
