import { ViewFilter } from "@common-components/tasks-panel/filter-button/advanced_filters";
import {
  FilterConfig,
  filtersListConfig as filtersConfig,
} from "@common-components/tasks-panel/filter-button/filtersList";
import { BoardView } from "@common-models/board-views";
import { TaskColumn } from "@common-models/task-column";
import { AppThunkDispatch } from "@common/types/common-root-state-type";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import moment from "moment";

import { getSelectedBoardCompleteUserList, getSelectedBoardId } from "./BoardsSelectors";
import {
  DBViewFiltersThunks,
  boardViewSelectByQuery,
  isTempId,
  taskColumnOptionSelectByQuery,
  taskGroupsSelectByQuery,
  viewFiltersSelectByQuery,
  viewFiltersSelectors
} from "@common-reducers/DBServiceThunks";

import { Task } from "@common-models/task";
import { TaskColumnOption } from "@common-models/task-column-option";
import { TasksGroup } from "@common-models/task-group";
import { CommonRootState } from "@common/types/common-root-state-type";
import { NullableId } from "@common/types/interface";
import { boardTaskSelector, getColumnList, getGroupTaskListLength } from "./TaskSelectors";

type BaseFilterItemObjectType = {
  id?: string;
  name?: string;
  count?: number;
  dateColumnId?: any;
  bgColor?: string;
  isPerson?: boolean;
  viewFilterId?: NullableId;
  isBlank?: boolean
};


moment.defineLocale('custom-en', {
  parentLocale: 'en',
  week: {
    dow: 0 // Sunday is the first day of the week
  }
});

// Functions

function isTaskMatchingOperatorForUndefinedValuesFilter(operatorValue, filterValues, columnFilterType) {
  const isRatingFilter = columnFilterType === 'rating';
  const isDropDownFilter = columnFilterType === 'drop-down';
  const isComplexityFilter = columnFilterType === 'complexity-picker';
  const filterValueIsArray = Array.isArray(filterValues)
  const isOnlyOperatorFilter = operatorValue === 'is empty' || operatorValue === 'is not empty' || operatorValue === 'is checked' || operatorValue === 'is not checked'
  if ((filterValues.length === 0 || filterValues === '') && !isOnlyOperatorFilter) return true
  if (operatorValue === 'includes') return false
  if (operatorValue === '!includes') return true


  switch (operatorValue) {
    case "is empty":
      return true;
    case "is not empty":
      return false;
    case "is not":
    case "!==":
      if (filterValueIsArray) {
        if ((filterValues.some(filterValue => filterValue.label === 'Blank') || filterValues.includes('Blank')) || (isRatingFilter && filterValues.includes(0)) || (isComplexityFilter && filterValues.some(filterValue => filterValue?.id === 'undefined')) || (isDropDownFilter && filterValues.some(filterValue => filterValue.id === 'undefined'))) {
          return false;
        } else {
          return true;
        }
      } else {
        return
      }
    case "is":
    case "===":
      if (filterValueIsArray) {
        if (typeof filterValues !== 'number' && filterValues) {
          return filterValues?.length === 0 ||
            filterValues.includes('Blank') ||
            filterValues.some(item => item.label === 'Blank') ||
            (isRatingFilter && filterValues.includes(0)) ||
            (isComplexityFilter && filterValues.some(val => val.id === 'undefined')) ||
            (isDropDownFilter && filterValues.some(val => val.id === 'undefined'))
        }
      }
      break;

    case "is not checked":
      return true

  }
};

const includesFilterMatch = (taskCellValue, filterValue, columnType) => {
  switch (columnType) {
    case "link":
      if (taskCellValue) {
        let linkMatches = taskCellValue.link ? taskCellValue.link.includes(filterValue) : false;
        let linkTextMatches = taskCellValue.linkText ? taskCellValue.linkText.includes(filterValue) : false;

        return linkMatches || linkTextMatches;
      }
      break;
    case "drop-down":
      return taskCellValue.some(taskValue => taskValue?.label !== undefined ? taskValue?.label?.includes(filterValue) : false);

    case "text-cell":
      return taskCellValue.includes(filterValue)
  }
}

function isTaskMatchingOperatorFilter(operatorValue, taskCellValue, filterValues, blankId, columnFilterType) {
  const isMultiselectValue = (Array.isArray(filterValues))
  const isDatesValue = (columnFilterType === 'datepicker' || columnFilterType === 'created' || columnFilterType === 'last-updated' || columnFilterType === 'timeline-picker')
  const isRatingFilter = columnFilterType === 'rating'
  const isDropDownFilter = columnFilterType === 'drop-down'
  const isLinkCellFilter = columnFilterType === 'link'
  const isTextCellFilter = columnFilterType === 'text-cell'
  const isOnlyOperatorFilter = operatorValue === 'is empty' || operatorValue === 'is not empty' || operatorValue === 'is checked' || operatorValue === 'is not checked'

  if ((filterValues.length === 0 || filterValues === '') && !isOnlyOperatorFilter) return true
  if (operatorValue === "is empty") {
    if (taskCellValue?.length === 0 || taskCellValue === blankId) {
      return true;
    }
  }
  // if there is no filter value show all
  if (Array.isArray(filterValues)) {
    if (filterValues?.length === 0) {
      return true;
    }
  }
  // for multiselect values
  if (isMultiselectValue && !isDatesValue) {
    switch (operatorValue) {
      case "===":
      case "is":
        return filterValues.find(
          (filterValue) => {
            return filterValue?.id === taskCellValue || (isRatingFilter && filterValue === taskCellValue) ||
              (isDropDownFilter && filterValues.some(filter => taskCellValue.some(taskCell => taskCell.label.includes(filter)))
                && filterValue?.id !== 'undefined') || (isTextCellFilter && filterValues.includes(taskCellValue)) ||
              (isLinkCellFilter && (filterValues.includes(taskCellValue?.linkText) || filterValues.includes(taskCellValue?.link)))
          }
        );
      case "is not":
      case "!==":
        return !filterValues.find(
          (filterValue) => {
            return !!(filterValue?.id === taskCellValue || (isRatingFilter && filterValue === taskCellValue) ||
              (isDropDownFilter && filterValues.some(filter => taskCellValue.some(taskCell => taskCell.label.includes(filter) || taskCell.label.includes(filter)))) ||
              (isTextCellFilter && filterValues.includes(taskCellValue)) ||
              (isLinkCellFilter && (filterValues.includes(taskCellValue?.linkText) || filterValues.includes(taskCellValue?.link))))
          });
      default:
        return false;
    }

    // for dates values
  } else if (isDatesValue) {
    switch (operatorValue) {
      case "is":
        let commonValues = taskCellValue.filter((value) =>
          filterValues.includes(value)
        );

        return commonValues.length > 0;
      case "is not":
        const doesNotContainSharedValue = Array.isArray(filterValues) ? !filterValues.some(value => taskCellValue.includes(value)) : undefined;
        return doesNotContainSharedValue;
      default:
        return false;
    }
    // for the rest
  }
  if (Array.isArray(taskCellValue) && Array.isArray(filterValues)) {
    switch (operatorValue) {
      case '===':
        return taskCellValue.some(task =>
          filterValues.some(user => user.id === task._id))

      case '!==':
        return !(taskCellValue.some(task =>
          filterValues.some(user => user.id === task._id)))
    }
  }
  else {
    switch (operatorValue) {
      case "===":
      case "is":
        return taskCellValue == filterValues;
      case "is not":
      case "!==":
        return taskCellValue != filterValues;
      case ">=":
        return taskCellValue >= filterValues;
      case ">":
        return taskCellValue > filterValues;
      case "<=":
        return taskCellValue <= filterValues;
      case "<":
        return taskCellValue < filterValues;
      case "undefined":
        return taskCellValue === undefined;
      case "!== undefined":
        return taskCellValue !== undefined;
      case "true":
        return taskCellValue === true;
      case "false":
        return taskCellValue === false;
      case "includes":
        return includesFilterMatch(taskCellValue, filterValues, columnFilterType)
      case "!includes":
        return !includesFilterMatch(taskCellValue, filterValues, columnFilterType)
      case "is checked":
        return taskCellValue === true;
      case "is not checked":
        return taskCellValue === false;
      default:
        return false;
    }
  }
};

function isTaskMatchingSpecialDateOperatorFilter(operatorValue: string, periodTimeValue: string, TaskDateCellValue) {
  const today = new Date();
  let newDateValueByTimePeriod: Date;
  let newDateValueBySelectOption: { startDate: number, endDate: number };
  const isPast: boolean = true
  newDateValueBySelectOption = getDateValueByDateSelectOptions(periodTimeValue);
  switch (operatorValue) {
    case "in the next":
      newDateValueByTimePeriod = getNewDateValueByTimePeriod(periodTimeValue, !isPast);
      return (
        new Date(TaskDateCellValue) >= today &&
        new Date(TaskDateCellValue) <= new Date(newDateValueByTimePeriod)
      );
    case "in the last":
      newDateValueByTimePeriod = getNewDateValueByTimePeriod(periodTimeValue, isPast);
      return (
        new Date(TaskDateCellValue) < today &&
        new Date(TaskDateCellValue) > new Date(newDateValueByTimePeriod)
      );
    case "is on or after":
      return newDateValueBySelectOption?.startDate <= new Date(TaskDateCellValue).getTime()
    case "is after":
      return newDateValueBySelectOption?.endDate < new Date(TaskDateCellValue).getTime()
    case "is on or before":
      return newDateValueBySelectOption?.endDate >= new Date(TaskDateCellValue).getTime()
    case "is before":
      return newDateValueBySelectOption?.startDate > new Date(TaskDateCellValue).getTime()
    case "is only":
      return newDateValueBySelectOption?.startDate == new Date(TaskDateCellValue).getTime() || newDateValueBySelectOption?.endDate == new Date(TaskDateCellValue).getTime() || (newDateValueBySelectOption?.startDate <= new Date(TaskDateCellValue).getTime() && newDateValueBySelectOption?.endDate >= new Date(TaskDateCellValue).getTime())
    case "is anything but":
      return !(newDateValueBySelectOption?.startDate == new Date(TaskDateCellValue).getTime() || newDateValueBySelectOption?.endDate == new Date(TaskDateCellValue).getTime() || (newDateValueBySelectOption?.startDate <= new Date(TaskDateCellValue).getTime() && newDateValueBySelectOption?.endDate >= new Date(TaskDateCellValue).getTime()))
    case "is between":
      if (!Array.isArray(periodTimeValue)) {
        const startDate = Number(periodTimeValue.split("isbetween")[0]);
        const endDate = Number(periodTimeValue.split("isbetween")[1]);
        return (
          new Date(endDate) > new Date(TaskDateCellValue) &&
          new Date(startDate) <= new Date(TaskDateCellValue)
        );
      } else {
        return
      }

    default:
      break;
  }
};

function isTaskMatchingSpecialTimelineOperatorFilter(operatorValue: string, filterValue, taskTimelineCellValue: { startDate: Date, endDate: Date, progressInPercentage: number }) {
  moment.updateLocale('en', {
    week: {
      dow: 0, // Sunday is the first day of the week
      doy: 6  // The week that contains Jan 6th is the first week of the year
    }
  });
  let newDateValueBySelectOption = getDateValueByDateSelectOptions(filterValue);
  const isFilterTimelineIsOrNotEqualToValue = isTimelineCellValueIsEqualToTimelineSelectedOption(filterValue, taskTimelineCellValue)
  if (filterValue.length === 0) { return true }
  if (typeof newDateValueBySelectOption !== 'object') {
    return
  }

  switch (operatorValue) {
    case '===':
      return isFilterTimelineIsOrNotEqualToValue
    case '!==':
    case 'is not':

      return !isFilterTimelineIsOrNotEqualToValue

    case "ends between":
      if (!Array.isArray(filterValue)) {
        const startDate = Number(filterValue.split("isbetween")[0]);
        const endDate = Number(filterValue.split("isbetween")[1]);
        return (
          new Date(endDate) > new Date(taskTimelineCellValue?.endDate) &&
          new Date(startDate) <= new Date(taskTimelineCellValue?.endDate)
        );
      }
      break;

    case "ends on":
      return newDateValueBySelectOption?.startDate <= new Date(taskTimelineCellValue?.endDate).getTime() &&
        newDateValueBySelectOption?.endDate >= new Date(taskTimelineCellValue?.endDate).getTime();

    case "ends after":
      return newDateValueBySelectOption?.endDate < new Date(taskTimelineCellValue?.endDate).getTime();


    case "ends before":
      return newDateValueBySelectOption?.startDate > new Date(taskTimelineCellValue?.endDate).getTime();

    case "end on or before":
      return newDateValueBySelectOption?.endDate >= new Date(taskTimelineCellValue?.endDate).getTime();


    case "ends on or after":
      return newDateValueBySelectOption?.startDate <= new Date(taskTimelineCellValue?.endDate).getTime();


    case "start between":
      if (!Array.isArray(filterValue)) {
        const startDate = Number(filterValue.split("isbetween")[0]);
        const endDate = Number(filterValue.split("isbetween")[1]);
        return (
          new Date(endDate) > new Date(taskTimelineCellValue?.startDate) &&
          new Date(startDate) <= new Date(taskTimelineCellValue?.startDate)
        );
      }
      break;

    case "start on":
      return newDateValueBySelectOption?.startDate <= new Date(taskTimelineCellValue?.startDate).getTime() &&
        newDateValueBySelectOption?.endDate >= new Date(taskTimelineCellValue?.startDate).getTime();


    case "start after":
      return newDateValueBySelectOption?.endDate < new Date(taskTimelineCellValue?.startDate).getTime();


    case "start before":
      return newDateValueBySelectOption?.startDate > new Date(taskTimelineCellValue?.startDate).getTime();


    case "start on or before":
      return newDateValueBySelectOption?.endDate >= new Date(taskTimelineCellValue?.startDate).getTime();

    case "start on or after":
      return newDateValueBySelectOption?.startDate <= new Date(taskTimelineCellValue?.startDate).getTime()
    default:
      break;
  }
};

function isTaskMatchingGroupOperatorFilter(operatorValue: string, filterValueAsGroupId: string | NullableId, taskGroupValue: any[]) {
  if (taskGroupValue.length === 0) { return true }
  if (taskGroupValue) {
    switch (operatorValue) {
      case '===':
        return taskGroupValue.some(groupItem => groupItem.id === filterValueAsGroupId)
      case '!==':
        return !(taskGroupValue.some(groupItem => groupItem.id === filterValueAsGroupId))
    }
  }
};

function getNewDateValueByTimePeriod(periodTimeValue: string, isPast: boolean) {
  const [num, unit] = typeof periodTimeValue === 'string' ? periodTimeValue.split("-") : '';
  const currentDate = moment().utc();
  let newDateValue;
  if (isPast) {
    switch (unit) {
      case "days":
        newDateValue = currentDate.subtract(num, unit).startOf('day')
        break;
      case 'weeks':
        newDateValue = currentDate.subtract(num, unit).startOf('week')
        break;
      case 'months':
        newDateValue = currentDate.subtract(num, unit).startOf('month')
        break;
    }
  } else {
    switch (unit) {
      case "days":
        newDateValue = currentDate.add(num, unit).startOf('day')
        break;
      case 'weeks':
        newDateValue = currentDate.add(num, unit).startOf('week')
        break;
      case 'months':
        newDateValue = currentDate.add(num, unit).startOf('month')
        break;

    }
  }
  return newDateValue;
};

function getDateValueByDateSelectOptions(dateSelectValue: string) {
  moment.updateLocale('en', {
    week: {
      dow: 0, // Sunday is the first day of the week
      doy: 6  // The week that contains Jan 6th is the first week of the year
    }
  });
  const today = moment.utc().startOf('day');
  const localToday = moment(); // Using local time
  if (!Array.isArray(dateSelectValue) && dateSelectValue.startsWith('exactDate')) {
    const exactDateValue = dateSelectValue.replace('exactDate', '');
    const exactDate = moment(exactDateValue);
    return {
      startDate: exactDate.clone().startOf('day').valueOf(),
      endDate: exactDate.clone().endOf('day').valueOf()
    }
  }
  else {
    switch (dateSelectValue) {
      case "Today":
        return {
          startDate: localToday.startOf('day').valueOf(),
          endDate: localToday.endOf('day').valueOf(),
        };

      case "Tomorrow":
        const tomorrowStart = localToday.clone().add(1, 'day').startOf('day');
        const tomorrowEnd = localToday.clone().add(1, 'day').endOf('day');
        return {
          startDate: tomorrowStart.valueOf(),
          endDate: tomorrowEnd.valueOf()
        };

      case "Yesterday":

        const yesterdayStart = localToday.clone().subtract(1, 'day').startOf('day');
        const yesterdayEnd = localToday.clone().subtract(1, 'day').endOf('day');
        return {
          startDate: yesterdayStart.valueOf(),
          endDate: yesterdayEnd.valueOf()
        };

      case "This week":
        return {
          startDate: today.clone().startOf('week').valueOf(),
          endDate: today.clone().endOf('week').valueOf()
        };

      case "Last week":
        return {
          startDate: today.clone().subtract(1, 'week').startOf('week').valueOf(),
          endDate: today.clone().subtract(1, 'week').endOf('week').valueOf()
        };

      case "Next week":
        return {
          startDate: today.clone().add(1, 'week').startOf('week').valueOf(),
          endDate: today.clone().add(1, 'week').endOf('week').valueOf()
        };

      case "This month":
        return {
          startDate: today.clone().startOf('month').valueOf(),
          endDate: today.clone().endOf('month').valueOf()
        };

      case "Last month":
        return {
          startDate: today.clone().subtract(1, 'month').startOf('month').valueOf(),
          endDate: today.clone().subtract(1, 'month').endOf('month').valueOf()
        };

      case "Next month":
        return {
          startDate: today.clone().add(1, 'month').startOf('month').valueOf(),
          endDate: today.clone().add(1, 'month').endOf('month').valueOf()
        };

      case "Past Dates":
        return {
          startDate: moment("1900-01-01").valueOf(),
          endDate: today.clone().subtract(1, 'day').valueOf()
        };

      case "Future Dates":
        return {
          startDate: today.clone().add(1, 'day').valueOf(),
          endDate: moment("9999-12-31").valueOf()
        };
      default:
        return null;  // Return null as default (or adjust as needed)
    }
  }
};

function isTimelineCellValueIsEqualToTimelineSelectedOption(timelineSelectValue, timelineCellValue) {
  moment.updateLocale('en', {
    week: {
      dow: 0, // Sunday is the first day of the week
      doy: 6  // The week that contains Jan 6th is the first week of the year
    }
  });

  const today = moment.utc().startOf('day');
  const timelineCellValueStartDate = moment.utc(timelineCellValue?.startDate).valueOf();
  const timelineCellValueEndDate = moment.utc(timelineCellValue?.endDate).valueOf();

  for (const userValue of timelineSelectValue) {
    switch (userValue) {
      case "Past Dates":
        if (timelineCellValueEndDate <= today.clone().subtract(1, 'day').startOf('day').valueOf()) {
          return true;
        }
        break;

      case "Future Dates":
        if (timelineCellValueStartDate > today.endOf('day').valueOf()) {
          return true;
        }
        break;

      case "Current":
        if (timelineCellValueStartDate <= today.endOf('day').valueOf() && timelineCellValueEndDate >= today.valueOf() - 1) {
          return true;
        }
        break;

      case "Due Today":
        if (timelineCellValue !== undefined && moment(timelineCellValueEndDate).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
          return true;
        }
        break;

      case "Blank":
        if (timelineCellValue === undefined) {
          return true;
        }
        break;
    }
  }
  return false;
};

const taskCellValueByColumnIdAndColumnType = (task: Task, columnFilterType: string, columnFilterId: NullableId | string) => {
  let taskDataValue;
  switch (columnFilterType) {
    case "person-picker":
      if (task.customData) {
        taskDataValue =
          task.customData[columnFilterId]?.value[0]?.id;
      } else {
        taskDataValue = undefined
      }
      break;
    case 'group':
      break;
    case "status-option-picker":
    case "rating":
    case "option-picker":
    case "complexity-picker":
    case "number":
      if (task.customData) {
        taskDataValue = task.customData[columnFilterId]?.value;
      } else {
        taskDataValue = undefined
      }
      break;

    case "last-updated":
      if (task) {
        const lastUpdateResult = task.updatedAt;
        taskDataValue =
          getInitialQuickFilterOptionsButtonsForDateValues(lastUpdateResult);
      } else {
        taskDataValue = undefined
      }
      break;
    case "created":
      if (task) {
        const createdAtResult = task.createdAt;
        taskDataValue =
          getInitialQuickFilterOptionsButtonsForDateValues(createdAtResult);
      } else {
        taskDataValue = undefined
      }
      break;

    case "datepicker":
      if (task.customData) {
        const dateValue = task.customData[columnFilterId]?.value;
        taskDataValue = getInitialQuickFilterOptionsButtonsForDateValues(dateValue);
      } else {
        taskDataValue = undefined
      }
      break;
    case "link":
      if (task.customData) {
        taskDataValue = {
          linkText: task.customData[columnFilterId]?.value?.linkText,
          link: task.customData[columnFilterId]?.value?.link
        }

      } else {
        taskDataValue = undefined
      }
      break;

    case "phone-cell":
    case "text-cell":
      if (task.customData) {
        taskDataValue = task.customData[columnFilterId]?.value;
      } else {
        taskDataValue = undefined
      }
      break;

    case "checkbox":
      if (task.customData) {
        taskDataValue = task.customData[columnFilterId]?.value;
      } else {
        taskDataValue = undefined
      }
      break;

    case "drop-down":
      if (task.customData) {
        taskDataValue = task.customData[columnFilterId]?.value;
      } else {
        taskDataValue = undefined
      }
      break;

    case "timeline-picker":
      if (task.customData) {
        const timelineValue = task.customData[columnFilterId]?.value;
        taskDataValue = getInitialQuickFilterOptionsButtonsForTimelineValues(
          timelineValue?.startDate, timelineValue?.endDate
        );
      } else {
        taskDataValue = undefined
      }
      break;
    default:
      break;
  }
  return taskDataValue
};

const getSpecialCasesFilterOperator = (filterType: string) => {
  switch (filterType) {
    case "number":
      return "=";
    case "checkbox":
      return "is checked";
    default:
      return 'is'
  }
};

const getFilterValueIsObjectForToggleThunk = (columnType: string) => {
  switch (columnType) {
    case "datepicker":
    case "created":
    case "last-updated":
    case "timeline-picker":
    case "rating":
    case "drop-down":
    case "link":
    case "text-cell":
      return false;
    default:
      return true;
  }
};
const getInitialQuickFilterOptionsButtonsForDateValues = (taskDateValue: Date | undefined) => {
  moment.updateLocale('en', {
    week: {
      dow: 0, // Sunday is the first day of the week
      doy: 6  // The week that contains Jan 6th is the first week of the year
    }
  });
  const now = moment().utc();
  const dateValue = moment.utc(taskDateValue);
  const startOfThisWeek = now.clone().startOf('week');
  const endOfThisWeek = now.clone().endOf('week');
  const startOfNextWeek = now.clone().startOf('week').add(1, 'weeks').startOf('day');
  const endOfNextWeek = startOfNextWeek.clone().add(6, 'days').startOf('day');
  const startOfLastWeek = now.clone().startOf('week').subtract(7, 'days');
  const endOfLastWeek = startOfLastWeek.clone().add(6, 'days');
  const matchesList = [];

  if (taskDateValue) {
    if (dateValue.isSame(now, 'day')) {
      matchesList.push("Today");
    }
    if (dateValue.isSame(now.clone().add(1, 'days'), 'day')) {
      matchesList.push("Tomorrow");
    }
    if (dateValue.isSame(now.clone().subtract(1, 'days'), 'day')) {
      matchesList.push("Yesterday");
    }

    if (dateValue.isBetween(startOfThisWeek, endOfThisWeek, null, '[]')) {
      matchesList.push("This week");
    }
    if (dateValue.isBetween(startOfNextWeek, endOfNextWeek, null, '[]')) {
      matchesList.push("Next week");
    }
    if (dateValue.isBetween(startOfLastWeek, endOfLastWeek, null, '[]')) {
      matchesList.push("Last week");
    }
    if (now.year() === dateValue.year() && now.month() === dateValue.month()) {
      matchesList.push("This month");
    }
    if (dateValue.isAfter(now, 'day')) {
      matchesList.push("Future Dates");
    }
    if (dateValue.isBefore(now, 'day')) {
      matchesList.push("Past Dates");
    }

    if (
      now.year() < dateValue.year() ||
      (now.year() === dateValue.year() && now.month() < dateValue.month())
    ) {
      const diffInMonths =
        dateValue.month() - now.month() + 12 * (dateValue.year() - now.year());
      if (diffInMonths === 1) {
        matchesList.push("Next month");
      }
    }
    if (
      now.year() > dateValue.year() ||
      (now.year() === dateValue.year() && now.month() > dateValue.month())
    ) {
      const diffInMonths =
        now.month() - dateValue.month() + 12 * (now.year() - dateValue.year());
      if (diffInMonths === 1) {
        matchesList.push("Last month");
      }
    }

    // If no matches were found, return 'Other'
    if (matchesList.length === 0) {
      matchesList.push("Other");
    }

    return matchesList;
  } else {
    return undefined;
  }
};
const getInitialQuickFilterOptionsButtonsForTimelineValues = (startDateValue: Date | undefined, endDateValue: Date | undefined) => {
  const today = moment().utc();
  const startDate = moment.utc(startDateValue).valueOf();
  const endDate = moment.utc(endDateValue).valueOf();
  const valueIsNotUndefined: boolean = startDateValue !== undefined && endDateValue !== undefined
  const matchesList = [];
  const isDueToday = moment(endDateValue).format('YYYY-MM-DD') === today.format('YYYY-MM-DD') && moment.utc(startDateValue) < today

  if (!valueIsNotUndefined) {
    matchesList.push('Blank');
    return matchesList;

  } else if (isDueToday) {
    matchesList.push("Due Today");
  } else if (startDate <= today.endOf('day').valueOf() && endDate >= today.valueOf() - 1) {
    matchesList.push("Current");
  } else if (endDate <= today.clone().subtract(1, 'day').startOf('day').valueOf()) {
    matchesList.push("Past Dates");
  } else if (startDate > today.endOf('day').valueOf()) {
    matchesList.push("Future Dates");
  }
  return matchesList;
};

const getInitialQuickFilterOptionsButtonsForRatingValues = (number) => {
  if (number === 0 || isNaN(number)) {
    return "0";
  } else {
    return number.toString();
  }
};


// Selectors
const getGroupsWithQuickFilterOptions = createSelector(
  [(state) => state, (_: CommonRootState) => []],
  (state): any => {
    const boardId = getSelectedBoardId(state);
    const currentBoardView = state.TasksReducer.currentBoardView;
    const viewId = currentBoardView?._id ?? boardId

    const viewFilters = viewFiltersSelectByQuery(
      state.DBViewFiltersReducer,
      {
        viewId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      null,
      {
        updatedAt: 1,
      }
    );

    const boardGroups = taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
      boardId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });

    const colmunFilterOptionsWithUsageList = boardGroups.map(group => {

      const matchedViewFilter = viewFilters.find(viewFilter =>
        Array.isArray(viewFilter?.filterValue) &&
        viewFilter.filterValue.some(filterValueItem => filterValueItem.id === group._id && viewFilter.filterOperator === 'is')
      );

      return {
        id: group?._id,
        name: group.name,
        bgColor: group.colorAccent,
        count: getGroupTaskListLength(state, group?._id),
        viewFilterId: matchedViewFilter?._id ?? false
      };
    })

    const groupsFilterObject = [{
      columnId: 'groupColumn',
      columnTitle: "Groups",
      colmunFilterOptionsWithUsageList,
    }];

    return groupsFilterObject;
  }
);
function addCountToQuickFilterObject(quickFilterObjectsList) {
  const quickFilterObject = quickFilterObjectsList.reduce((result, item) => {
    if (result[item.id]) {
      // If an entry with this ID already exists, increase the count
      result[item.id].count += 1;
    } else {
      // If no entry exists, create a new one with a count of 1
      result[item.id] = { ...item, count: 1 };
    }
    return result;
  }, {});

  // Convert the grouped object back to an array
  return Object.values(quickFilterObject);
}
const getColumnWithQuickFilterOptionsByColumn = createSelector(
  [state => state, (_: CommonRootState, column: TaskColumn) => column],
  (state, column) => {
    const initialQuickFilterObject = getInitialFilterObjectForQuickFilter(state, column?._id)
    const columnOptionsInUseList = addCountToQuickFilterObject(initialQuickFilterObject)
    return {
      columnId: column._id,
      columnTitle: column.title,
      colmunFilterOptionsWithUsageList: columnOptionsInUseList
    };
  }
);

export const getShownGroups = createSelector(
  [state => state],
  (state) => {
    const groups = taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId: getSelectedBoardId(state),
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      })
    return groups;
  }
);

export const getViewFiltersCount = createSelector(
  [state => state],
  (state) => {


    const boardId = getSelectedBoardId(state);
    const currentBoardView = state.TasksReducer.currentBoardView;
    const viewId = currentBoardView?._id ?? boardId
    const viewFilters = viewFiltersSelectByQuery(
      state.DBViewFiltersReducer,
      {
        viewId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      null,
      {
        updatedAt: 1,
      }
    );
    return viewFilters.length;
  }
);





const getInitialFilterObjectForQuickFilter = createSelector(
  [
    (state) => state,
    (_: CommonRootState, columnId: NullableId,) => columnId,
  ],
  (state, columnId: NullableId): any => {
    let valueObject: BaseFilterItemObjectType = {};
    const optionsInUseList = []
    const boardId = getSelectedBoardId(state);
    const columnList: TaskColumn[] = getColumnList(state);
    const members = getSelectedBoardCompleteUserList(state);
    const selectedColumn: TaskColumn = columnList.find((column) => column._id === columnId)
    const columnType: string = selectedColumn?.type;
    const rawTasks: Task[] = boardTaskSelector(state);
    const groups = getShownGroups(state);
    const tasks = rawTasks.filter(task => groups.some(group => group._id === task.groupId))
    const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
      boardId,
      columnId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
    const blankOption: TaskColumnOption = columnOptions.find(option => option.isDefaultLabel);
    switch (columnType) {
      case "person-picker":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            let cellValues = Array.isArray(taskColumnValue) ? taskColumnValue : [taskColumnValue];
            cellValues.forEach((cellValue) => {
              const selectedPerson = members.find((person) => person?._id === cellValue?.id)
              if (selectedPerson) {
                const personOption = {
                  id: selectedPerson._id,
                  name: selectedPerson.profile?.firstName ? `${selectedPerson.profile.firstName} ${selectedPerson.profile?.lastName || ''}` : selectedPerson.email,
                  isPerson: true,
                  viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, selectedPerson._id, columnId)
                }
                optionsInUseList.push(personOption);
              }
            });
          } else {
            valueObject = { id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true }
            optionsInUseList.push(valueObject);
          }
        });
        break;
      case "option-picker":
      case "status-option-picker":
      case "complexity-picker":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            valueObject = getInitialQuickFilterValueObjectForColumnWithOptions(state, taskColumnValue, columnId);
            valueObject.viewFilterId = getViewFilterIdForQuickFilterOptionButton(state, valueObject.id, columnId)
            optionsInUseList.push(valueObject);
          } else {
            valueObject = { id: blankOption?._id, bgColor: blankOption?.bgcolor, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, blankOption?._id, columnId), isBlank: true };
            optionsInUseList.push(valueObject);
          }
        });
        break;
      case "timeline-picker":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            const timelineResultsList = getInitialQuickFilterOptionsButtonsForTimelineValues(taskColumnValue?.startDate, taskColumnValue?.endDate);
            timelineResultsList.forEach((timelineResult) => {
              optionsInUseList.push({ id: timelineResult, name: timelineResult, dateColumnId: columnId, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, timelineResult, columnId) });
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "datepicker":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            const dateResultsList = getInitialQuickFilterOptionsButtonsForDateValues(taskColumnValue);
            dateResultsList.forEach((dateResult) => {
              optionsInUseList.push({ name: dateResult, id: dateResult, dateColumnId: columnId, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, dateResult, columnId) });
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "number":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            optionsInUseList.push({ name: taskColumnValue, id: taskColumnValue, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, taskColumnValue, columnId) });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "rating":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            const ratingResult = getInitialQuickFilterOptionsButtonsForRatingValues(taskColumnValue);
            optionsInUseList.push({ name: ratingResult, id: ratingResult, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, ratingResult, columnId) });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "phone-cell":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            optionsInUseList.push({ id: taskColumnValue, name: taskColumnValue, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, taskColumnValue, columnId) });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "checkbox":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            optionsInUseList.push({ id: "Checked", name: "Checked", viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, "Checked", columnId) });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "drop-down":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            taskColumnValue.forEach(dropdownQuickFilterObject => {
              optionsInUseList.push({
                id: dropdownQuickFilterObject?._id,
                name: dropdownQuickFilterObject?.label,
                viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, dropdownQuickFilterObject?.label, columnId)
              });
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "link":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            optionsInUseList.push({
              id: taskColumnValue?.link,
              name: taskColumnValue?.linkText ? taskColumnValue?.linkText : taskColumnValue?.link,
              viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, taskColumnValue?.linkText || taskColumnValue?.link, columnId)
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "created":
        tasks.forEach((task) => {
          const taskColumnValue = task?.createdAt;
          if (taskColumnValue) {
            const createdAtValuesList = getInitialQuickFilterOptionsButtonsForDateValues(taskColumnValue);
            createdAtValuesList.map((createdAt) => {
              optionsInUseList.push({
                name: createdAt,
                id: createdAt,
                dateColumnId: columnId,
                viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, createdAt, columnId)
              });
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "last-updated":
        tasks.forEach((task) => {
          const taskColumnValue = task?.updatedAt;
          if (taskColumnValue) {
            const lastUpdatedValuesList = getInitialQuickFilterOptionsButtonsForDateValues(taskColumnValue);
            lastUpdatedValuesList.map((lastUpdated) => {
              optionsInUseList.push({
                id: lastUpdated,
                name: lastUpdated,
                dateColumnId: columnId,
                viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, lastUpdated, columnId)
              });
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
      case "text-cell":
        tasks.forEach((task) => {
          const { customData } = task;
          const taskColumnValue = customData?.[columnId]?.value;
          if (taskColumnValue) {
            optionsInUseList.push({
              name: taskColumnValue,
              id: taskColumnValue,
              viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, taskColumnValue, columnId)
            });
          } else {
            optionsInUseList.push({ id: `${columnId}-blank`, viewFilterId: getViewFilterIdForQuickFilterOptionButton(state, `${columnId}-blank`, columnId), isBlank: true });
          }
        });
        break;
    }
    return optionsInUseList
  }
);



const getViewFilterIdForQuickFilterOptionButton = createSelector(
  [
    state => state,
    (_, columnOptionInUseIdOrName: string, columnId: NullableId) => columnOptionInUseIdOrName,
    (_, columnOptionInUseIdOrName: string, columnId: NullableId) => columnId
  ],
  (state, columnOptionInUseIdOrName: string, columnId: NullableId) => {


    const boardView: BoardView = state.TasksReducer.currentBoardView;
    const boardId: string = getSelectedBoardId(state);
    const [currentBoardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId, _id: boardView._id });
    const columnList: TaskColumn[] = getColumnList(state);
    const viewId: string = currentBoardView?._id ?? boardId;
    const viewFilters: ViewFilter[] = viewFiltersSelectByQuery(state.DBViewFiltersReducer, { viewId, $or: [{ deleted: { $exists: false } }, { deleted: false }] }, null, { updatedAt: 1 });

    const filter: ViewFilter = viewFilters.find((viewFilter) => {
      const selectedColumnFilter: TaskColumn = columnList.find(
        (column: TaskColumn) => viewFilter.filterColumnId === column?._id
      );
      const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId: selectedColumnFilter?._id,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      })
      const columnType: string = selectedColumnFilter?.type;
      if (viewFilter.filterColumnId === columnId) {
        switch (columnType) {
          case "person-picker":
            if (Array.isArray(viewFilter.filterValue)) {

              const personFilter = viewFilter.filterValue.find((filterVal) => filterVal.id === columnOptionInUseIdOrName)
              if (personFilter) {
                return personFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "option-picker":
          case "status-option-picker":
            if (Array.isArray(columnOptions)) {

              const selectedOptionPickerColumn = columnOptions.find((columnOption) => columnOption._id === columnOptionInUseIdOrName)
              const blankOptionPicker = columnOptions.find((columnOption) => columnOption.isDefaultLabel)
              const optionPickerFilter = viewFilter.filterValue.find((filterVal) => filterVal.id === selectedOptionPickerColumn?._id)
              if (optionPickerFilter) {
                return viewFilter
              }
              else if (viewFilter?.filterValue.includes('Blank') && blankOptionPicker?._id === columnOptionInUseIdOrName) {
                return viewFilter
              }
            }
            break;
          case "complexity-picker":
            if (Array.isArray(columnOptions)) {
              const selectedComplexityOption = columnOptions.find((columnOption) => columnOption._id === columnOptionInUseIdOrName)
              const complexityPickerFilter = viewFilter.filterValue.find((filterVal) => filterVal.id === selectedComplexityOption?._id)
              if (complexityPickerFilter) {
                return viewFilter
              }
              else if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "timeline-picker":
            if (Array.isArray(viewFilter.filterValue)) {
              const timelineFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (timelineFilter) {
                return timelineFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "datepicker":
            if (Array.isArray(viewFilter.filterValue)) {
              const dateFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (dateFilter) {
                return viewFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "number":
            const numberFilterValue = viewFilter.filterValue
            if (numberFilterValue === columnOptionInUseIdOrName) {
              return viewFilter
            } else if (typeof columnOptionInUseIdOrName === 'string') {
              if (viewFilter.filterOperator === 'is empty' && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "rating":
            if (Array.isArray(viewFilter.filterValue)) {

              const ratingFilter = viewFilter.filterValue.find((filterVal) => filterVal === parseInt(columnOptionInUseIdOrName))
              if (ratingFilter) {
                return viewFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "phone-cell":
            if (Array.isArray(viewFilter.filterValue)) {

              const phoneFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (phoneFilter) {
                return viewFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "checkbox":
            const checkkboxFilterValue = viewFilter.filterValue
            if (checkkboxFilterValue === columnOptionInUseIdOrName) {
              return viewFilter
            }
            if (viewFilter?.filterValue === '' && viewFilter?.filterOperator === 'is not checked' && columnOptionInUseIdOrName.includes('blank')) {
              return viewFilter
            }
            break;
          case "drop-down":
            if (Array.isArray(viewFilter.filterValue)) {
              const dropdownFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (dropdownFilter) {
                return viewFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break
          case "link":
            const linkFilterValue = viewFilter.filterValue

            if (linkFilterValue.includes(columnOptionInUseIdOrName)) {
              return viewFilter
            } else if (typeof columnOptionInUseIdOrName === 'string') {
              if (columnOptionInUseIdOrName.includes('blank') && viewFilter.filterValue.includes('Blank')) {
                return viewFilter
              }
            }
            break
          case "created":
            if (Array.isArray(viewFilter.filterValue)) {
              const createdFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (createdFilter) {
                return createdFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "last-updated":
            if (Array.isArray(viewFilter.filterValue)) {
              const lastUpdateFilter = viewFilter.filterValue.find((filterVal) => filterVal === columnOptionInUseIdOrName)
              if (lastUpdateFilter) {
                return lastUpdateFilter
              }
              if (viewFilter?.filterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
            }
            break;
          case "text-cell":
            if (Array.isArray(viewFilter.filterValue)) {
              const textFilterValue = viewFilter.filterValue
              if (textFilterValue.includes(columnOptionInUseIdOrName)) {
                return viewFilter
              }
              else if (textFilterValue.includes('Blank') && columnOptionInUseIdOrName.includes('blank')) {
                return viewFilter
              }
              break;
            }
        }
      }
    })
    return filter?._id;
  }
);

const getInitialQuickFilterValueObjectForColumnWithOptions = createSelector(
  [
    (state) => state,
    (_: CommonRootState, value: any, __: NullableId) => value,
    (_: CommonRootState, __: any, columnId: NullableId) => columnId,
  ],
  (state, value, columnId): any => {
    let optionObject = {};
    const boardId = getSelectedBoardId(state);
    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );
    const option = columnOptions.find((column) => value === column?._id);
    optionObject = {
      id: option?._id,
      name: option?.label,
      bgColor: option?.bgcolor,
    };
    return optionObject;
  }
);

const isFilterExistByColumnType = createSelector(
  [(state) => state,
  (_: CommonRootState, column: TaskColumn, viewId) => column,
  (_: CommonRootState, column: TaskColumn, viewId) => viewId
  ],
  (state, column, viewId): ViewFilter => {
    const allViewFilters: ViewFilter[] = viewFiltersSelectors.selectAll(state);
    return allViewFilters.find((filter) => filter.filterColumnId === column?._id && filter.viewId === viewId);
  }
);

const getValuesListForTextColumn = createSelector(
  [
    (state) => state,
    (_: CommonRootState, columnId: NullableId) => columnId
  ],
  (state, columnId) => {
    const textCellsValues = []
    const tasks = boardTaskSelector(state);
    tasks.map((task) => {
      if (task && task?.customData && task?.customData[columnId]?.value) {
        textCellsValues.push(task?.customData[columnId].value)
      }
    })
    return textCellsValues
  });

const getValuesListForLinkColumn = createSelector(
  [
    (state) => state,
    (_: CommonRootState, columnId: NullableId) => columnId
  ],
  (state, columnId) => {
    const textCellsValues = []
    const tasks = boardTaskSelector(state);
    tasks.map((task) => {
      if (task && task?.customData && task?.customData[columnId]?.value) {
        textCellsValues.push(task?.customData[columnId]?.value?.linkText || task?.customData[columnId]?.value?.link)
      }
    })
    return textCellsValues
  });
const getValuesListForDropdownColumn = createSelector(
  [
    (state) => state,
    (_: CommonRootState, columnId: NullableId) => columnId
  ],
  (state, columnId) => {
    const textCellsValues = []
    const tasks = boardTaskSelector(state);
    tasks.map((task) => {
      if (task && task?.customData && task?.customData[columnId]?.value) {
        textCellsValues.push(task?.customData[columnId]?.value[0]?.label)
      }
    })
    return textCellsValues
  });
export const getFilterConfigItemByColumnType = createSelector(
  [(state) => state, (_: CommonRootState, columnType: string) => columnType],
  (state, columnType): any => {
    if (columnType) {
      const selectedFilterConfigItem: FilterConfig = filtersConfig.find(
        (filter) => filter.filterType === columnType
      );
      return selectedFilterConfigItem;
    } else {
      return {};
    }
  }
);

export const getFilterOperatorListByColumnType = createSelector(
  [(state) => state, (_: CommonRootState, columnType: string) => columnType],
  (state, columnType): any => {
    if (columnType) {
      const filterItem = getFilterConfigItemByColumnType(state, columnType);
      return Object.keys(filterItem?.operatorsList);
    } else {
      return [];
    }
  }
);

export const getFilterConfigItemByColumnTypeAndSelectedOperator = createSelector(
  [
    (state) => state,
    (_: CommonRootState, columnType: string, selectedOperator: string) => columnType,
    (_: CommonRootState, columnType: string, selectedOperator: string) =>
      selectedOperator,
  ],
  (state, columnType, selectedOperator): any => {
    if (columnType && selectedOperator) {
      const filterItem = getFilterConfigItemByColumnType(state, columnType);
      const operatorData = filterItem.operatorsList[selectedOperator];
      return operatorData;
    } else {
      return {};
    }
  }
);

export const valuesListSelector = createSelector(
  [
    (state, columnType, columnId, operator) => state,
    (_, columnType: string, columnId: string | NullableId, operator: string) => columnType,
    (_, columnType: string, columnId: string | NullableId, operator: string) => columnId,
    (_, columnType: string, columnId: string | NullableId, operator: string) => operator,
  ],
  (state, columnType, columnId, operator) => {
    let valuesListByColumnType = [];
    const boardId = getSelectedBoardId(state);
    const groups: TasksGroup[] = taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
      boardId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    })
    const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    switch (columnType) {
      case "person-picker":
        const currentAssignedMemberList = getSelectedBoardCompleteUserList(state);
        valuesListByColumnType = currentAssignedMemberList.map((person) => {
          if (
            person?.profile

          ) {
            return {
              id: person?._id,
              value: (person?.profile?.firstName + " " + person?.profile?.lastName).trim(),
              profile: person.profile,
            };
          } else {
            return { id: person?._id, value: person?.email };
          }
        });
        break;


      case "option-picker":
      case "complexity-picker":
      case "status-option-picker":

        let hasDefaultLabel = false;

        valuesListByColumnType = columnOptions.map((option) => {
          if (option.isDefaultLabel) {
            hasDefaultLabel = true;
            return { [option._id]: "Blank", optionColor: option?.bgcolor ?? 'grey' };
          }
          return { [option._id]: option.label, optionColor: option?.bgcolor };
        });

        // If no default label was found, add an additional object to the array
        if (!hasDefaultLabel) {
          valuesListByColumnType.push({ undefined: 'Blank' });
        }
        break;
      case "created":
      case "last-updated":
        valuesListByColumnType = [
          "Today",
          "Yesterday",
          "This week",
          "Last week",
          "This month",
          "Last month",
          "Past",
          "Blank",
        ];
        break;
      case "datepicker":
        valuesListByColumnType = [
          "Today",
          "Tomorrow",
          "Yesterday",
          "This week",
          "Last week",
          "Next week",
          "This month",
          "Last month",
          "Next month",
          "Past Dates",
          "Future Dates",
          "Blank",
        ];
        break;
      case "timeline-picker":
        if (operator === 'is' || operator === 'is not') {
          valuesListByColumnType = [
            'Current',
            'Due Today',
            'Past Dates',
            "Future Dates",
            'Blank'

          ]
        } else {
          valuesListByColumnType = [
            "Today",
            "Tomorrow",
            "Yesterday",
            "This week",
            "Last week",
            "Next week",
            "This month",
            "Last month",
            "Next month",
            "Past Dates",
            "Future Dates",
            "Blank",
          ];
        }
        break;

      case "rating":
        valuesListByColumnType = [0, 1, 2, 3, 4, 5];
        break;

      case "link":
        valuesListByColumnType = getValuesListForLinkColumn(state, columnId)
        console.log("link cell scenario");
        break;
      case "drop-down":
        valuesListByColumnType = getValuesListForDropdownColumn(state, columnId)
        console.log("drop-down cell scenario");
        break;
      case "text-cell":
        valuesListByColumnType = getValuesListForTextColumn(state, columnId)
        console.log("Text cell scenario");
        break;
      case "group":
        valuesListByColumnType = groups.map(group => {
          return {
            isGroup: true,
            id: group?._id,
            value: group?.name,
          };
        });
        break;
      default:
        console.log("Unrecognized columnType");
    }
    return valuesListByColumnType;
  }
);

export const isTaskMatchingFiltersSelector = createSelector(
  [
    (boardId) => boardId,
    (boardId, task, filterList, columnList) => task,
    (boardId, task, filterList, columnList) => filterList,
    (boardId, task, filterList, columnList) => columnList,
    (boardId, task, filterList, columnList, DBTaskColumnOptionReducer) => DBTaskColumnOptionReducer,

  ],
  (boardId, task: Task, filterList: ViewFilter[], columnList: TaskColumn[], DBTaskColumnOptionReducer) => {
    let isTaskMatchingFilters: boolean = null;
    let filterDataFromFiltersConfigList: FilterConfig
    for (let i = 0; i < filterList.length; i++) {
      let filter = filterList[i];
      let taskCellValue = [];
      let taskGroupValue: any[];

      const selectedColumnFilter = columnList.find(
        (column: TaskColumn) => filter.filterColumnId === column?._id
      );
      const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(DBTaskColumnOptionReducer, {
        boardId,
        columnId: selectedColumnFilter?._id,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      })
      let blankColumnOption: TaskColumnOption = columnOptions.find((column) => column?.isDefaultLabel);

      if ('groupColumn' === filter.filterColumnId) {
        taskGroupValue = filter?.filterValue
        filterDataFromFiltersConfigList = filtersConfig.find(
          (filter) => filter.filterType === 'group'
        );
      } else {
        taskCellValue = taskCellValueByColumnIdAndColumnType(task, selectedColumnFilter?.type, selectedColumnFilter?._id)
        filterDataFromFiltersConfigList = filtersConfig.find(
          (filter) => filter.filterType === selectedColumnFilter?.type
        );
      }

      let isTaskMatchingFilter = false;

      const filterOperators = filterDataFromFiltersConfigList ? filterDataFromFiltersConfigList.operatorsList : [];
      const filterOperatorObject = filterOperators[filter.filterOperator];
      const filterOperatorValue = filterOperatorObject?.value;

      if (!filterOperatorObject) {
        return;
      }
      if (taskCellValue) {
        const getTaskValueForDatesValues = () => {
          if (filter?.filterColumnId !== 'groupColumn') {
            switch (selectedColumnFilter.type) {
              case "datepicker":
              case "timeline-picker":
                return task.customData[filter.filterColumnId]?.value;
              case "created":
                return task.createdAt
              case "last-updated":
                return task?.updatedAt
              default:
                return false;
            }
          }
        }
        const taskValueForDatesValues = getTaskValueForDatesValues()

        if (filterOperatorObject?.isSpecialDateFilter || filterOperatorObject?.isSpecialDateSelectFilter) {
          isTaskMatchingFilter = isTaskMatchingSpecialDateOperatorFilter(filterOperatorValue, filter.filterValue, taskValueForDatesValues);
        } else if (filterOperatorObject?.isSpecialTimelineFilter || selectedColumnFilter?.type === 'timeline-picker') {
          isTaskMatchingFilter = isTaskMatchingSpecialTimelineOperatorFilter(filterOperatorValue, filter.filterValue, taskValueForDatesValues);
        } else if (filterDataFromFiltersConfigList?.filterType === 'group') {
          isTaskMatchingFilter = isTaskMatchingGroupOperatorFilter(filterOperatorValue, task.groupId, taskGroupValue);
        } else {
          isTaskMatchingFilter = isTaskMatchingOperatorFilter(filterOperatorValue, taskCellValue, filter.filterValue, blankColumnOption, selectedColumnFilter?.type);
        }

        if (filterOperatorValue === "is not empty" && taskCellValue) {
          isTaskMatchingFilter = true;
        }

      }
      else {
        isTaskMatchingFilter = isTaskMatchingOperatorForUndefinedValuesFilter(
          filterOperatorValue,
          filter.filterValue, selectedColumnFilter?.type
        );
      }

      if (isTaskMatchingFilters === null) {
        isTaskMatchingFilters = isTaskMatchingFilter;
      } else {
        if (filter.filterAndOr === "and") {
          isTaskMatchingFilters = isTaskMatchingFilters && isTaskMatchingFilter;
        } else if (filter.filterAndOr === "or") {
          isTaskMatchingFilters = isTaskMatchingFilters || isTaskMatchingFilter;
        }
      }
    }

    return isTaskMatchingFilters;
  }





);


export const isFiltersExist = createSelector(
  [(state) => state],
  (state): any => {
    const boardId = getSelectedBoardId(state);
    const currentBoardView = state.TasksReducer.currentBoardView;
    const [boardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: boardId, _id: currentBoardView.id });
    const viewId = boardView?._id ?? boardId
    const filtersList = viewFiltersSelectByQuery(
      state.DBViewFiltersReducer,
      {
        viewId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      null,
      {
        updatedAt: 1,
      }
    );
    if (filtersList.length > 0) {
      return true;
    }
    return false;
  }
);

export const getFilterDataByFilterColumnIdForFilterRow = createSelector(
  [(state) => state, (_: CommonRootState, filterColumnId: string) => filterColumnId],
  (state, filterColumnId): any => {
    const columnList = getColumnList(state);
    let filterData = {}
    if ('groupColumn' === filterColumnId) {
      const columnType = 'group';
      const columnTitle = 'Group';
      filterData = {
        columnId: 'groupColumn',
        columnTitle,
        columnType,
      };
    } else {

      const selectedColumn = columnList.find(
        (col) => col._id === filterColumnId
      );
      const columnType = selectedColumn?.type;
      const columnTitle = selectedColumn?.title;
      const columnId = selectedColumn?._id;
      filterData = {
        columnId,
        columnTitle,
        columnType,
      };
    }
    return filterData;
  }
);

export const isRatingColumn = createSelector(
  [(state) => state, (_: CommonRootState, columnId: any) => columnId],
  (state, columnId): any => {
    const columnList = getColumnList(state);
    const ratingColumn = columnList.find(column => column?._id === columnId);
    return ratingColumn?.type === 'rating'
  });

export const getQuickFilterOptionsListSelector = createSelector(
  [(state) => state, (_: CommonRootState) => []],
  (state): any[] => {
    const columnList = getColumnList(state);
    const groupsWithQuickFilterOptions = getGroupsWithQuickFilterOptions(state)
    let columnsWithQuickFilterOptions = [];

    for (let i = 0; i < columnList.length; i++) {
      const column = columnList[i];
      const columnWithQuickFilterOptions = getColumnWithQuickFilterOptionsByColumn(state, column);
      columnsWithQuickFilterOptions.push(columnWithQuickFilterOptions);
    }
    return [...groupsWithQuickFilterOptions, ...columnsWithQuickFilterOptions];
  }
);

// Thunks
export const toggleFilterItemThunk = createAsyncThunk<
  void,
  {
    id: string;
    name: string;
    columnId: string;
    viewFilterId: string;
  },
  { state: CommonRootState }
>("tasks/toggleFilterItemThunk", async (params, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const valuesList = [];
  const userId = state.UserReducer.userId;
  const boardId = getSelectedBoardId(state);
  const currentBoardView = state.TasksReducer.currentBoardView;
  const [boardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId, _id: currentBoardView.id });
  const viewId = boardView?._id ?? boardId
  const columnList = getColumnList(state);
  const isGroup = params.columnId === "groupColumn"

  const selectedColumn = columnList.find(
    (column) => params.columnId === column._id
  );

  const valueIsObject = getFilterValueIsObjectForToggleThunk(selectedColumn?.type);
  const sameFilter: ViewFilter = isFilterExistByColumnType(
    state,
    selectedColumn,
    viewId
  );
  const selectedFilterItem = getFilterConfigItemByColumnType(
    state,
    selectedColumn?.type
  );

  let filterOperator = getSpecialCasesFilterOperator(
    selectedFilterItem.filterType
  );
  const isMultiselectForTextField = (selectedFilterItem.filterType === "link" || selectedFilterItem.filterType === "text-cell" || selectedFilterItem.filterType === "drop-down") && (filterOperator === 'is' || filterOperator === 'is not');

  const isRating = selectedFilterItem.filterType === "rating"
  const isSelectOrPerson = selectedFilterItem.valueInputType === "select" ||
    selectedFilterItem.valueInputType === "person";

  ((isSelectOrPerson && !isGroup && !isRating) || (isMultiselectForTextField)) &&

    valuesList.push(
      valueIsObject ? { id: params.id, label: params.name } : params.name
    );
  // Rating filter expect to get number

  isRating && !valueIsObject &&
    valuesList.push(Number(params.name))



  let filterValue =
    !isSelectOrPerson && !isGroup && !isMultiselectForTextField
      ? params.name ?? params.id
      : valuesList;

  if (params.name === undefined) {
    switch (selectedColumn?.type) {
      case 'number':
        filterOperator = 'is empty';
        filterValue = undefined;
        break;
      case 'checkbox':
        filterOperator = 'is not checked';
        filterValue = "";
        break;
      default:
        filterValue = ['Blank'];
    }
  }

  if (isGroup) {
    valuesList.push(
      { id: params.id, label: params.name }
    );
  }

  const filterCondition = {
    filterAndOr: "or",
    filterColumnId: params.columnId,
    filterOperator,
    viewId,
    filterValue,
    owner: userId,
  };
  if (params.viewFilterId) {
    if (!isTempId(params.viewFilterId)) {
      if (sameFilter && Array.isArray(sameFilter.filterValue) &&
        Array.isArray(filterCondition.filterValue) && isMultiselectForTextField) {
        let result;

        if (typeof filterCondition.filterValue[0] === "object") {
          const filterConditionIds = filterCondition.filterValue.map(
            (filter) => filter.id
          );

          result = sameFilter.filterValue.filter(
            (item) => !filterConditionIds.includes(item.id)
          );
        } else {
          result = sameFilter.filterValue.filter(
            (filter) => !filterCondition.filterValue.includes(filter)
          );
        }
        if (result.length > 0) {
          dispatch(
            DBViewFiltersThunks.patch({
              entity: {
                _id: params.viewFilterId,
                filterValue: result,
              },
            })
          );
        } else {
          dispatch(
            DBViewFiltersThunks.delete({ entity: { _id: params.viewFilterId } })
          );
        }
      } else {
        dispatch(
          DBViewFiltersThunks.delete({ entity: { _id: params.viewFilterId } })
        );
      }
    } else {
      console.log('isTemp')
    }
  } else {
    if (sameFilter && sameFilter?.filterValue?.length > 0 && sameFilter?.filterOperator === filterCondition?.filterOperator && (isMultiselectForTextField)) {
      dispatch(
        DBViewFiltersThunks.patch({
          entity: {
            ...sameFilter,
            filterValue: [
              ...sameFilter.filterValue,
              ...filterCondition.filterValue,
            ],
          },
        })
      );
    } else {
      dispatch(DBViewFiltersThunks.create(filterCondition));
    }
  }
});


export const addNewfilterConditionThunk = createAsyncThunk<
  any,
  ViewFilter,
  { state: CommonRootState }
>(
  "addNewfilterConditionThunk",
  async (
    ViewFilter,
    thunkApi
  ) => {
    const state: CommonRootState = thunkApi.getState();
    const { dispatch } = thunkApi;
    const userId = state.UserReducer.userId;
    const boardId = getSelectedBoardId(state);
    const currentBoardView = state.TasksReducer.currentBoardView
    const [boardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: boardId, _id: currentBoardView.id });
    const viewId = boardView?._id ?? boardId

    const filterCondition: ViewFilter = {
      filterColumnId: ViewFilter.filterColumnId,
      filterOperator: ViewFilter.filterOperator,
      filterValue: ViewFilter.filterValue,
      viewId,
      filterAndOr: ViewFilter.filterAndOr,
      owner: userId,
    };
    const newFilterCondtionResponse = await dispatch(
      DBViewFiltersThunks.create(filterCondition)
    );

    return newFilterCondtionResponse;
  }
);
export const clearAllFiltersThunks = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("clearAllFiltersThunks", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  const boardId = getSelectedBoardId(state);
  const currentBoardView = state.TasksReducer.currentBoardView;
  const [boardView]: BoardView[] = boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: boardId, _id: currentBoardView.id });
  const viewId = boardView?._id ?? boardId
  const filterList = viewFiltersSelectByQuery(
    state.DBViewFiltersReducer,
    {
      viewId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    null,
    {
      updatedAt: 1,
    }
  );
  filterList.map((filterItem) => {
    if (!isTempId(filterItem?._id)) {
      dispatch(
        DBViewFiltersThunks.delete({ entity: { _id: filterItem?._id } })
      );
    }
  });
});

