import { CircularProgress } from '@mui/material';
import { getLikedByUsersProfile, getSeenByUsersProfile } from '@common-reducers/BoardsSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React from 'react'
import PersonAvatar from '../person-avatar/person-avatar';

type Props = {
    likedByIdArray: string[];
}

export default function LikedByTooltipComponent({
    likedByIdArray,
}: Props) {
    const likedByUsersProfile = useAppSelector((state) => getLikedByUsersProfile(state, likedByIdArray))
    const isLoading = useAppSelector((state) => !state.DBBoardUserReducer.itemsLoaded)
    return (
        <>
            {isLoading &&
                <CircularProgress />
            }
            {!isLoading &&
                likedByUsersProfile.map((user, index) => {
                    return (
                        <PersonAvatar
                            key={index}
                            size={32}
                            email={user.email}
                            profileImg={user?.profileImg}
                            lastName={(user?.lastName === 'undefined' || user?.lastName === undefined) ? ' ' : user.lastName}
                            firstName={user?.firstName}
                            toolTip={true}
                        />
                    )
                })
            }
        </>
    )
}