import BTTimeline from "@common-components/bt-timeline/bt-timeline";
import { ClickAwayListener } from "@mui/base";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { updateCellValueThunk } from "@common-reducers/TaskThunks";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { useCallback } from "react";
import { PickerProps } from "../columns-cells/cell-interface";
import classes from "./datepicker.module.scss";
import { useCleanupRegister } from "./use-cleanup-register";


export default function TimelinePicker({
  cellValue: data,
  columnId,
  taskId,
}: PickerProps) {
  const dispatch = useAppDispatch()
  
  const toggleMenu = useCallback(() => {
    dispatch(pickerDialogActions.closePickerDialog());
  }, [dispatch])

  const onValueUpdate = (value: string) => {
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value: value
      }
    }));
  }

  const submit = useCallback(() => {
    toggleMenu();

  }, [toggleMenu])

  useCleanupRegister(() => {
    submit()
  });

  return (
    <ClickAwayListener onClickAway={(e) => {
      submit()
    }}>
      <div className={classes.datePicker} >
        <BTTimeline
          rangeDateInput={data}
          onValueUpdate={onValueUpdate} />
      </div>
    </ClickAwayListener >

  );
}
