import { modalTextStyle } from '@common-components/bt-chatbot/bot-components/bt-chatbot-modal'
import SelectVariable from '@common-components/bt-chatbot/bot-components/select-variable'
import BTInputField from '@common-components/bt-input-field/bt-input-field'
import RowStack from '@common-components/common/row-stack/RowStack'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import classes from "./webhook-node.module.scss";
import React, { useState } from 'react'
import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field'

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
}

export default function SetURLAndMethod({ localWebhookState, setLocalWebhookState }: Props) {

    const [methodAnchorEl, setMethodAnchorEl] = useState<null | HTMLElement>(null);
    const [isMethodMenuOpen, setIsMethodMenuOpen] = useState(false);


    const handleOpenMethodMenu = (event) => {
        setMethodAnchorEl(event.currentTarget);
        setIsMethodMenuOpen(true)
    }

    const handleCloseMethodMenu = (e) => {
        e.stopPropagation()
        setMethodAnchorEl(null);
        setIsMethodMenuOpen(false)
    }

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    return (
        <div className={classes.divider}>
            {title('URL & Method')}
            <RowStack sx={{ gap: 2, }}>
                <BotInputField
                    value={localWebhookState.url}
                    onChange={(newValue) => setLocalWebhookState({
                        ...localWebhookState,
                        url: newValue,
                    })}
                    startAdornment={
                        <div id='method-select-div' onClick={handleOpenMethodMenu}
                            className={classes.method_select}>
                            {localWebhookState.method}
                            <KeyboardArrowDown />
                            <Menu
                                anchorEl={methodAnchorEl}
                                open={isMethodMenuOpen}
                                onClose={handleCloseMethodMenu}
                                sx={{ border: '1px solid rgb(227, 227, 227)', }}
                                MenuListProps={{
                                    sx: {
                                        paddingTop: '0 !important',
                                        paddingBottom: '0 !important',
                                    },
                                }}>
                                {
                                    ['GET', 'POST', 'PATCH', 'PUT', 'DELETE'].map((method, index) => {
                                        return (
                                            <MenuItem sx={{
                                                borderBottom: index !== 4 && '1px solid rgb(227, 227, 227)',
                                                color: '#666666',
                                                fontSize: '14px',
                                            }} key={index} onClick={(e) => {
                                                setLocalWebhookState({
                                                    ...localWebhookState,
                                                    method: method,
                                                })
                                                handleCloseMethodMenu(e)
                                            }}>{method}</MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </div>
                    } />
                <SelectVariable buttonSize='medium' onSelect={(item) => {
                    setLocalWebhookState({
                        ...localWebhookState,
                        url: localWebhookState.url + item,
                    })
                }} />
            </RowStack >
        </div>
    )
}