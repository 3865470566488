import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import React, { useRef, useState } from 'react';
import classes from "./chatgpt-node.module.scss";
import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import { Stack } from '@mui/system';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, RemoveCircle } from '@mui/icons-material';
import { modalTextStyle } from '@common-components/bt-chatbot/bot-components/bt-chatbot-modal';
import SelectVariable from '@common-components/bt-chatbot/bot-components/select-variable';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function GPTPromptInput({ localChatGPTState, setLocalChatGPTState, }: Props) {
    const containerRef = useRef(null);

    const [isSelectMessageRoleMenuOpen, setIsSelectMessageRoleMenuOpen] = useState<number>(null);

    const rolesArray = ['System', 'User', 'Assistant']

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitleWithMaybeLink = (
        secondaryTitle: string,
        style?: React.CSSProperties,
        linkString?: string,
        linkURL?: string
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
                {linkString && linkURL && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {linkString}
                    </a>
                )}
            </div>
        );
    };

    const addMessageToPromptInput = () => {
        setLocalChatGPTState({
            ...localChatGPTState,
            messages: [...(localChatGPTState?.messages ?? []), { role: '', message: '' }]
        })
    }


    const toggleSelectMessageRoleMenu = (e, i) => {
        e.stopPropagation()
        setIsSelectMessageRoleMenuOpen(isSelectMessageRoleMenuOpen !== null ? null : i);
    }


    const handleCloseSelectMessageRoleMenu = (messageIndex, roleIndex) => {
        setLocalChatGPTState({
            ...localChatGPTState,
            messages: localChatGPTState?.messages?.map((m, i) => {
                if (i === messageIndex) {
                    return {
                        ...m,
                        role: rolesArray[roleIndex]
                    }
                }
                return m
            })
        });

        setIsSelectMessageRoleMenuOpen(null);
    }

    const handleClickRemoveMessageInput = (messageIndex) => {
        setLocalChatGPTState({
            ...localChatGPTState,
            messages: localChatGPTState?.messages?.filter((m, i) => i !== messageIndex)
        })
    }

    const userMessageInputUI = (message: any, messageIndex: number) => {
        return (
            <div key={messageIndex}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    {title(`Message ${messageIndex + 1}`, { marginTop: '20px', })}
                    <IconButton sx={{
                        zIndex: 2,
                        width: '30px',
                        height: '30px',
                        borderRadius: '8px',
                        backgroundColor: '#FEE7E7',
                        color: 'red',
                        ".MuiSvgIcon-root": {
                            width: '20px',
                            height: '20px',
                        }
                    }} onClick={() => handleClickRemoveMessageInput(messageIndex)}><RemoveCircle /></IconButton>
                </div>

                <Stack>
                    {title('Role', { marginTop: '20px', })}
                    <BotInputField
                        onClick={(e) => toggleSelectMessageRoleMenu(e, messageIndex)}
                        value={message.role}
                        sx={{
                            cursor: 'pointer',

                        }}
                        endAdornment={
                            <>
                                <IconButton disableRipple sx={{
                                    zIndex: 2,
                                    width: '30px',
                                    color: '#666666',
                                    ".MuiSvgIcon-root": {
                                        width: '20px',
                                        height: '20px',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}>
                                    {!isSelectMessageRoleMenuOpen && <KeyboardArrowDown />}
                                    {!!isSelectMessageRoleMenuOpen && <KeyboardArrowUp />}
                                </IconButton>
                            </>
                        }
                    />
                    {isSelectMessageRoleMenuOpen === messageIndex &&
                        <>
                            {rolesArray?.map((role, roleIndex) => (
                                <BotInputField
                                    key={roleIndex}
                                    value={role}
                                    onClick={() => handleCloseSelectMessageRoleMenu(messageIndex, roleIndex)}
                                    sx={{
                                        ".MuiOutlinedInput-input": {
                                            ...modalTextStyle,
                                            zIndex: 3,
                                            lineHeight: '19px',
                                            cursor: 'pointer',
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            border: "0px",
                                            backgroundColor: 'rgb(245, 246, 250)',
                                            cursor: 'pointer'
                                        },
                                        "&: hover": {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                backgroundColor: 'rgb(204, 208, 222)',
                                            },
                                        },
                                    }} />
                            ))}
                        </>
                    }




                    {title('Message content', { marginTop: '20px', })}
                    <BotInputField
                        value={message.message}
                        onChange={(newValue) =>
                            setLocalChatGPTState({
                                ...localChatGPTState,
                                messages: localChatGPTState?.messages?.map((m, i) => {
                                    if (i === messageIndex) {
                                        return {
                                            ...m,
                                            message: newValue
                                        }
                                    }
                                    return m
                                })
                            })
                        }
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}>
                        <SelectVariable sx={{ marginTop: '20px', width: '107px', }} buttonSize='small' onSelect={(item) => {
                            setLocalChatGPTState({
                                ...localChatGPTState,
                                messages: localChatGPTState?.messages?.map((m, i) => {
                                    if (i === messageIndex) {
                                        return {
                                            ...m,
                                            message: m.message + item
                                        }
                                    }
                                    return m
                                })
                            })
                        }} />
                    </div>
                </Stack>
            </div>
        )
    }


    const addMessageButton = () =>
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
        }}>
            <BTbotbutton styleType='dashed' onClick={addMessageToPromptInput}>Add message</BTbotbutton>
        </div>


    const handleBlur = (event) => {
        if (containerRef.current && containerRef.current.contains(event.relatedTarget)) {
            // Focus is shifting within the container, so don't close the menu
            return;
        }
        // Otherwise, close the menu
        setIsSelectMessageRoleMenuOpen(null);
    };


    return (
        <>
            {localChatGPTState?.method === 'chat completion' &&
                <>
                    <FormControlLabel sx={{ m: 0 }}
                        control={<Checkbox checked={localChatGPTState.includeConversationHistory ?? false} color="success" size="small" />}
                        label="Include conversation history."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setLocalChatGPTState({
                                ...localChatGPTState,
                                includeConversationHistory: e.target.checked
                            })
                        }} />


                    {title('Messages', { marginTop: '20px', })}
                    <div ref={containerRef} onBlur={handleBlur}>
                        {localChatGPTState?.messages?.map((message, index) => {
                            return userMessageInputUI(message, index)
                        })}

                        {addMessageButton()}

                        {secondaryTitleWithMaybeLink('For examples, see the ', {}, 'OpenAI Prompt examples', 'https://platform.openai.com/docs/examples')}
                    </div>
                </>
            }
            {localChatGPTState?.method === 'prompt completion' &&
                <>
                    {title('Prompt', { marginTop: '20px', })}
                    <BotInputField
                        value={localChatGPTState?.prompt ?? ''}
                        placeholder='Enter your prompt here...'
                        onChange={(newValue) => setLocalChatGPTState({
                            ...localChatGPTState,
                            prompt: newValue
                        })}
                    />
                </>
            }
        </>
    )
}