import dev from "./app.config.dev";
import devserver from "./app.config.dev.server";
import prod from "./app.config.prod";
import staging from "./app.config.staging";
import appConfigTudoStage from "./app.config.tudo.stage";
import appConfigTudoProd from "./app.config.tudo.prod";
import prodSupport from "./app.config.support.prod";

export type PlanProductType = "Scheduler" | "Manager" | "Campaigner";
export interface PlanDescriptor {
  id: string;
  key?: string;
  planName: string;
  intervalCount: number;
  intervalUnit: any;
  messageLimit: number;
  tasks?: boolean;
  bulk?: boolean;
  basePrice?: number;
  annualDiscount?: number;
  seatDiscount?: number;
  price?: number;
  order?: number;
  seatCount?: number;
  planType?: PlanProductType | string;
  bluesnapType: "plan" | "contract";
  quantity?: number;
}
export interface Features {
  labels?: boolean;
  snippets?: boolean;
}
export interface AppConfig {
  isProd?: boolean;
  features?: Features;
  asset_key: string;
  google_client_id: string;
  EXTENTION_ID: string;
  API_ENDPOINT: string;
  HOMEPAGE: string;
  LOGO: string;
  LOGO2: string;
  APP_ENDPOINT: string;
  WS_ENDPOINT: string;
  VZNLYTICS_ENDPOINT: string;
  GATEWAY_ENDPOINT: string;
  NOTIFIER_ENDPOINT: string;
  PRICING_PAGE: string;
  BLUESNAP_ENDPOINT: string;
  BLUESNAP_STORE_ID: string;
  BLUESNAP_REDIRECT_ENC: string;
  PAYPAL_CLIENT_ID: string;
  extensionStoreLink: string;
  ENV: string;
  GTM_ID: string;
  GA_ID: string;
  FB_PIXEL_ID: string;
  TIKTOK_ID?: string;
  CLARITY_ID?: string;
  AW_ID: string;
  WS_ID: string;
  HJ_ID: number;
  HJ_SV: number;
  DEFAULT_TITLE: string;
  pricingOptions?: "extension" | "only-tasks" | string;
}

export let appConfig: AppConfig;

let env: string = process.env.REACT_APP_ENV || process.env.NODE_ENV || "dev";
console.log("env", env);

switch (env) {
  case "production":
  case "prod": {
    appConfig = prod;
    break;
  }
  case "staging": {
    appConfig = staging;
    console.log("appConfig");
    console.log(appConfig);
    break;
  }
  case "devserver":
  case "matan":
  case "uriel": {
    devserver.API_ENDPOINT = "http://localhost:3310";
    // devserver.API_ENDPOINT = "https://matan.blueticks.co/";

    if (env === "matan") {
      devserver.API_ENDPOINT = "http://localhost:3310";
      // devserver.API_ENDPOINT = "https://matan.blueticks.co/";
    }
    // else if (env === "uriel") {
    //   devserver.API_ENDPOINT = "https://uriel.blueticks.co/";
    // }

    appConfig = devserver;
    break;
  }
  case "support": {
    appConfig = prodSupport;
    break;
  }
  case "tudostage": {
    appConfig = appConfigTudoStage;
    break;
  }
  case "tudoprod": {
    appConfig = appConfigTudoProd;
    break;
  }
  case "development":
  case "dev": {
    appConfig = dev;
    break;
  }
}