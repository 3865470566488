
import { undoRedoActions } from '@common-reducers/UndoRedoReducer';
import { pauseUndoToastTimer, processingMessageSelector, resumeUndoToastTimer, toastMessageSelector, undoActionThunk } from '@common-reducers/UndoRedoThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';

import BTButton from '@common-components/bt-button/bt-button';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import classes from './toast.module.scss';
import { AppThunkDispatch } from '@common/types/common-root-state-type';


const DeletedToast = () => {
    const dispatch = useDispatch<AppThunkDispatch>();
    const [undoProcessing, setUndoProcessing] = useState<boolean>(false);
    const toastMessage = useAppSelector(toastMessageSelector);
    const processingMessage = useAppSelector(processingMessageSelector);


    const pauseTimer = () => {
        dispatch(pauseUndoToastTimer());
    }

    const resumeTime = () => {
        if (!undoProcessing){
            dispatch(resumeUndoToastTimer());
        }
    }

    const undoAction = () => {
        setUndoProcessing(true);
        pauseTimer();
         dispatch(undoActionThunk());

    }

    return (

        <Box className={classes.fixed_wrapper}>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={3} onMouseOver={pauseTimer} onMouseLeave={resumeTime} className={classes.deleted_message}>
                {!undoProcessing ?
                    <Stack>{toastMessage}</Stack> : <Stack direction="row" spacing={2} alignItems={"center"}>
                        <Box>
                            <CircularProgress size="16px" />
                        </Box>
                        <Box>{processingMessage}</Box>
                    </Stack>
                }



                <>
                    <BTButton disabled={undoProcessing} onClick={undoAction} >Undo</BTButton>

                    <IconButton disabled={undoProcessing} onClick={() => {
                        dispatch(undoRedoActions.clearUndoToast())
                    }}>
                        <MdClose />
                    </IconButton>
                </>



            </Stack>
        </Box>

    )
}

export default DeletedToast;

