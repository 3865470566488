import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { Stack } from '@mui/system';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { DBFormThunks, formSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useRef } from 'react';
import BTCheckboxList from '../checkbox-list/bt-checkbox-list';
import { getDynamicColumnListForBTCheckbox } from '@common-reducers/TaskSelectors';

export default function FormsIncludedColumnsDialog() {
    const boardId = useAppSelector(getSelectedBoardId)
    const columnList = useAppSelector((state) => getDynamicColumnListForBTCheckbox(state, 'form'))
        .filter(column => {
            const cell = columnTypeList.find(columnType => columnType.type === column.type)
            return cell.isCellInForms
        });
    const formsList = useAppSelector((state) => formSelectByQuery(state.DBFormReducer, { boardId: boardId }));
    const form = formsList.length > 0 ? formsList[0] : undefined;
    const dispatch = useAppDispatch()
    const formColumnPickerRef = useRef<HTMLDivElement>()


    const isChecked = (column) => {
        if (form?.columnData[column._id] === undefined || (form?.columnData[column._id] && form?.columnData[column._id].visible)) {
            return true;
        }
        else {
            return false;
        }
    };

    function handleAllCheckBoxChange(value) {
        let newColumnData = {}
        for (let i = 0; i < columnList.length; i++) {
            newColumnData = {
                ...newColumnData,
                [columnList[i]._id]: {
                    ...form?.columnData[columnList[i]._id],
                    visible: value,
                }
            }
        }
        dispatch(DBFormThunks.patch({
            entity: {
                ...form,
                columnData: newColumnData
            }
        }))
    }

    const handleCheckBoxChange = (value, column) => {
        dispatch(DBFormThunks.patch({
            entity: {
                ...form,
                columnData: {
                    ...form?.columnData,
                    [column._id]: {
                        ...form?.columnData[column._id],
                        visible: value,
                    }
                }
            }
        }))
    }

    const isCheckedRecord: Record<string, boolean> = columnList.reduce((isCheckedRecord, column) => ({
        ...isCheckedRecord,
        [column._id]: isChecked(column)
    }), {});


    return (
        <Stack ref={formColumnPickerRef} direction="column" style={{
            position: 'relative',
        }}>
            <BTCheckboxList
                isCheckedRecord={isCheckedRecord}
                columnList={columnList}
                handleCheckBoxChange={handleCheckBoxChange}
                handleAllCheckBoxChange={handleAllCheckBoxChange}
            />
        </Stack>
    )
}
