import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box';
import { Template } from '@common-models/template';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { setActiveBulkView } from '@common-reducers/CampaignReducer';
import { DBTemplateThunks, isTempId } from "@common-reducers/DBServiceThunks";
import { getRuntimeUrl } from '@common-services/storage';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';

type Props = {
    templateLocalState: Partial<Template>;
    onChange: (value: Template) => void;
    setTemplateLocalState: React.Dispatch<React.SetStateAction<Partial<Template>>>;
}

export default function TemplateHeader({
    templateLocalState,
    onChange,
    setTemplateLocalState,
}: Props) {
    const dispatch = useAppDispatch()


    const srcTrashGreySVG = getRuntimeUrl("/img/trashGreySVG.svg")
    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        padding: "0 10px",
        fontSize: "36px",
        fontWeight: "700",
        borderRadius: "8px",
        border: "1px solid #53bdeb",
        fontFamily: "'Poppins', 'Assistant', sans-serif",
        "& .MuiInputBase-input": {
            padding: "0",
        },
    };
    const textTheme = {
        color: "var(--primary)",
        fontSize: "36px",
        padding: "0 10px",
        border: "1px solid transparent",
        borderRadius: "8px",
        fontWeight: "700",
        fontFamily: "'Poppins', 'Assistant', sans-serif",
        "&:hover": {
            border: templateLocalState._id && "1px dashed var(--text-color)",
        },
    };

    return (
        <Stack direction='column' sx={{
            justifyContent: 'center',
            alignItems: 'center',
            m: '50px 0 40px 0',
        }}>
            <Stack direction='row'>
                <BtEditableBox
                    preventClickEdit={!templateLocalState._id}
                    placeholder='Enter Template Name'
                    inputSx={inputTheme}
                    boxSx={textTheme}
                    text={templateLocalState?.name}
                    onValueUpdate={(newValue) => {
                        onChange({
                            ...templateLocalState,
                            name: newValue
                        });
                        setTemplateLocalState({
                            ...templateLocalState,
                            name: newValue
                        })
                    }}
                />
                {templateLocalState?._id && !isTempId(templateLocalState?._id) &&
                    <IconButton sx={{ alignSelf: 'center', }} onClick={() => {

                        dispatch(DBTemplateThunks.patch({
                            entity: {
                                _id: templateLocalState?._id,
                                deleted: true,
                            }
                        }))

                        dispatch(setActiveBulkView("campaignManagementView"))

                    }}>
                        <img src={srcTrashGreySVG} alt='srcTrashGreySVG' />
                    </IconButton>
                }
            </Stack>
            <span style={{
                textAlign: 'center',
                fontSize: '17px',
                fontWeight: '400',
                marginTop: '10px',
                marginBottom: '10px',
            }}>Add text, emojis, links, labels and files</span>
        </Stack>
    )
}