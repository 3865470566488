import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { CSSProperties } from 'react';
import { LogStack } from '../../ExtraDataPanel';


type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function OptionStatusComplexityColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );
    const cellWrapperStyle: CSSProperties = { pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    return (
        <LogStack>
            <span>{log.actionType}&nbsp;</span>
            {log.oldValue &&
                <div style={{ ...cellWrapperStyle, minWidth: '50px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
            }
            <span style={{ paddingInline: '1px' }}>to</span>
            <div style={{ ...cellWrapperStyle, minWidth: '50px' }}>
                <CellWrapper
                    columnId={""}
                    taskId={""}
                    groupId={""}
                    boardId={''}
                    mockValue={log.newValue}
                    readOnly={true}
                    cellComponent={cell.cellComponent}
                />
            </div>
            in&nbsp;
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>{` `}
        </LogStack>
    )
}