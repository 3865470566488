import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectPersonPickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentTrigger } from "../automations-helper";

const PersonAssignedTrigger = () => {
    const dispatch = useAppDispatch()
    const [columnName, setColumnName] = React.useState<string>('Person');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
    };
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const boardId = useAppSelector((state) => getSelectedBoardId(state));
    const filteredColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColumnChange = (id, name, type) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { columnId: id})));
        setColumnName(name);

        handleClose();
    };

    useEffect(() => {
        if (Object.keys(triggerObj).length > 0) {
            const selectedColumnId = triggerObj.columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnName(column?.name ?? "Person")
            dispatch(automation.fieldValidityChanged({ component: 'PersonAssignedTrigger', index: 0, field: 'columnId', isValid: !!column?.name }));
        }
    }, [triggerObj, filteredColumns, dispatch]);
    return (
        <Stack direction="row" justifyContent={"space-between"} sx={{ fontSize: "24px" }}>
            <Box>When
                <AutomationButton
                    componentName='PersonAssignedTrigger'
                    index={0}
                    option='columnId'
                    label={columnName}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                /> is assigned
            </Box>
            <AddAndRemove elements={conditions} index={0} type={"trigger"} />

            <PickerColumn
                anchorEl={anchorEl}
                handleClose={handleClose}
                filteredColumns={filteredColumns}
                handleColumnChange={handleColumnChange}
            />
        </Stack>

    )
}
export default PersonAssignedTrigger