import { useCleanupRegister } from '@common-components/tasks/picker-dialog/use-cleanup-register'
import { TaskColumnOption } from '@common-models/task-column-option'
import { DBTaskColumnOptionThunks, isTempId, taskColumnOptionSelectByQuery, taskColumnSelectByQuery } from "@common-reducers/DBServiceThunks"
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer'
import { deleteOptionFromTaskThunk, updateCellValueThunk } from '@common-reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { namespace } from '@common/config/constants'
import { MenuList } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PickerProps } from '../cell-interface'
import EditableOptions from './EditableOptions'
import MappedOptions from './MappedOptions'

export default function OptionPicker({
    taskId,
    boardId,
    columnId,
}: PickerProps) {
    const { t } = useTranslation(namespace);
    let saveString
    let newLabelString
    let editLabelsString
    let defaultLabelTooltipString
    let labelPlaceholderString
    let complexityPlaceholderString
    let defaultLabelRemoveTooltipString
    let removeInUseOptionTooltipString
    if (t) {
        saveString = t('editableOptions.save')
        newLabelString = t('editableOptions.newLabel')
        editLabelsString = t('mappedOptions.editLabel')
        defaultLabelTooltipString = t('editableOptions.defaultLabelTooltip')
        labelPlaceholderString = t('editableOptions.labelPlaceholder')
        complexityPlaceholderString = t('editableOptions.complexityPlaceholder')
        defaultLabelRemoveTooltipString = t('editableOptions.defaultLabelRemoveTooltip')
        removeInUseOptionTooltipString = t('editableOptions.removeInUsedOptionTooltip')
    }

    const dispatch = useAppDispatch()
    const [isEditing, setIsEditing] = useState(false);
    const [column] = useAppSelector((state) => taskColumnSelectByQuery(state.DBTaskColumnReducer, { _id: columnId }))
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }));
    console.log(columnId);
    console.log(columnOptions);
    const isComplexity = (column && column.type === 'complexity-picker') ? true : false

    const componentId = useCleanupRegister(() => {
        setIsEditing(false)
    });


    const toggleMenu = () => {
        dispatch(pickerDialogActions.closePickerDialog());
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Tab") {
            event.preventDefault();
            toggleMenu();
        }
    }

    const updateTask = (option: TaskColumnOption) => {

        if (option?.isDefaultLabel) {
            dispatch(deleteOptionFromTaskThunk(
                {
                    taskId,
                    columnId,
                }
            ))
        } else {
            dispatch(updateCellValueThunk({
                taskId,
                columnId,
                data: {
                    value: option._id
                }
            }));

        }




        toggleMenu();
    }

    const onEditCancel = (_pickerOptions) => {
        dispatch(pickerDialogActions.setEditModeOptions(false))
        setIsEditing(false);
    }

    const onEditChange = (options: TaskColumnOption[]) => {
        // Create new columns
        options
            .filter(option => !option._id)
            .forEach(option => {
                dispatch(DBTaskColumnOptionThunks.create({
                    ...option,
                    boardId,
                    columnId,
                }))
            })
        // Patch existing columns
        const updatedOptions = options
            .filter(option => option._id && !isTempId(option._id))
            .map(option => ({
                id: option._id,
                changes: option
            }))
        dispatch(DBTaskColumnOptionThunks.patchMany(updatedOptions));
        // Delete options that are not returned
        const newOptionIdList = options
            .filter(option => option._id && !isTempId(option._id))
            .map(option => option._id);
        const deletedOptions = columnOptions
            .filter(option => newOptionIdList.indexOf(option._id) === -1)
            .map(option => ({
                id: option._id,
                changes: {
                    deleted: true
                }
            }));
        dispatch(DBTaskColumnOptionThunks.patchMany(deletedOptions));
    }


    const onSave = () => {
        setIsEditing(false);
    }


    const canEdit = useMemo(() => column?._id && !isTempId(column?._id), [column?._id])
    useEffect(() => {
        setIsEditing(false)
    }, [columnId]);
    return (
        <MenuList
            autoFocusItem={true}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
            sx={{
                backgroundColor: 'var(--default-background)',
                border: '2px solid var(--default-background)',
                borderLeft: '20px solid var(--default-background)',
                borderRight: '20px solid var(--default-background)',
                borderRadius: '10px',
                margin: '-4px',
            }}
        >
            {isEditing ? (
                <EditableOptions
                    onChange={onEditChange}
                    onCancel={onEditCancel}
                    canEdit={canEdit}
                    options={columnOptions}
                    isComplexity={isComplexity}
                    onSave={onSave}
                    boardId={boardId}
                    columnId={columnId}
                    saveString={saveString}
                    newLabelString={newLabelString}
                    defaultLabelTooltipString={defaultLabelTooltipString}
                    labelPlaceholderString={labelPlaceholderString}
                    complexityPlaceholderString={complexityPlaceholderString}
                    defaultLabelRemoveTooltipString={defaultLabelRemoveTooltipString}
                    removeInUseOptionTooltipString={removeInUseOptionTooltipString}
                />
            ) : (
                <MappedOptions
                    options={columnOptions}
                    handleValueSelected={updateTask}
                    canEdit={canEdit}
                    onEdit={() => setIsEditing(true)}
                    isComplexity={isComplexity}
                    editLabelsString={editLabelsString}
                />
            )}
        </MenuList>
    )
}