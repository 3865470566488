import React from "react";
import classes from './footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from "@fortawesome/fontawesome-svg-core";


export interface FooterProps {

}

export interface FooterState {

}

class Footer extends React.Component<FooterProps, FooterState> {
    constructor(props: FooterProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div className={classes.footerContainer}>
                    <div className={classes.contentContainer}>
                        <div className={classes.linkContainer}>
                            <a target="_blank" href="https://blueticks.co/terms" rel="noreferrer"><span>Terms & Privacy Policy</span></a>
                            <FontAwesomeIcon size="xs" icon={faCircle as IconProp} />
                            <a target="_blank" href="faq.html"><span>FAQ</span></a>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className={classes.copyrightContainer}>
                        <FontAwesomeIcon icon={faCopyright as IconProp} />
                        <span>Copyrights 2020, All Rights Resereved</span>
                    </div>
                    {/* <div className={classes.socialContainer}>
                    <div className={classes.socialButton}>
                        <IconButton>
                            <FontAwesomeIcon icon={faFacebookF} />
                        </IconButton>
                    </div>
                    <div className={classes.socialButton}>
                        <IconButton>
                            <FontAwesomeIcon icon={faTwitter} />
                        </IconButton>
                    </div>
                    <div className={classes.socialButton}>
                        <IconButton>
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </IconButton>
                    </div>
                </div> */}
                </div>
                <div className={classes.disclaimer}>
                    <b>DISCLAIMER</b><br />
                    ‍This site is not a part of WhatsApp© or Facebook©. Mentioned names or logos are properties of their respective companies. The information on this website is for educational purpose only, we neither support nor be help responsible for any misuse of this info. This tool is not affiliated with any brand or website. The buyer must use the software responsibly and adhere to the website terms or usage policy (or whatever is applicable). its just a standalone tool can facilitate and extend some options in WhatsApp. its not a spam tool and not allowed to use it for spamming or violating WhatsApp policies. This tool automates a natural human's behavior to save his/her time in daily use, and we do not take responsibility on how the buyer uses this software. This is an automation tool intended to save time doing repetitive actions. All disputes are subject to Israeli jurisdiction.
                </div>
            </div>
        );
    }
}

export default Footer;