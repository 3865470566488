import { Stack } from '@mui/material';
import { FaUserCircle } from 'react-icons/fa';

export type PersonPickerIDType = 'user' | 'option';

export interface PersonPickerValue {
  id?: string;
  type?: PersonPickerIDType;
}

export default function PersonPickerScrollingCell({
  taskId,
  cellValue,
  columnId,
  mockValue,
  togglePicker,
  boardId
}: any) {
  const personSize = 22;

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <FaUserCircle color="#809ba8" size={personSize} />
    </Stack>
  )
}