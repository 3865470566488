import { MdDone } from 'react-icons/md';
import classes from "./checkbox.module.scss"


export interface checkBoxProps {
    checked: boolean
    color?:string,
    disabled?: boolean
}

const Checkbox = (props: checkBoxProps) => {
    return (
        <div className={classes.customCheckbox}
            style={{
                borderColor:  props.color ?? '#00c875',
                backgroundColor: props.checked ? props.color ?? '#00c875' : "",
                pointerEvents: props.disabled ? 'none' : "all"
            }}>

            {props.checked && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdDone size={14} color="#fff" />
                </div>
            )}
        </div>
    )
}

export default Checkbox