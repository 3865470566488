import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import SelectVariable from "@common-components/bt-chatbot/bot-components/select-variable";
import RowStack from "@common-components/common/row-stack/RowStack";
import classes from "./webhook-node.module.scss";
import React from 'react'

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
}

export default function CustomizeBody({ localWebhookState, setLocalWebhookState }: Props) {

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitle = (secondaryTitle: string, style?: any) => {
        return <div className={classes.secondary_title} style={style}>{secondaryTitle}</div>
    }


    return (
        <div className={classes.divider}>
            <RowStack sx={{ justifyContent: 'space-between' }}>
                {title('Customize Body')}
                <BotToggleSwitch
                    onChange={() => setLocalWebhookState({
                        ...localWebhookState,
                        customizeBody: {
                            ...localWebhookState.customizeBody,
                            isCustomizeBody: !localWebhookState.customizeBody.isCustomizeBody,
                        }
                    })}
                    checked={localWebhookState.customizeBody.isCustomizeBody}
                    isOptional={true}
                    sx={{ marginBottom: '20px', }} />
            </RowStack>
            {localWebhookState.customizeBody.isCustomizeBody &&
                <>
                    {secondaryTitle('Formatting Tips:')}
                    {secondaryTitle('– Strings and Variables must be wrapped in quotes →"@name"')}
                    {secondaryTitle('– A comma is needed between 2 key-value pairs →"Email":')}
                    {secondaryTitle('"@email", "Name": "@name"')}
                    {title('Request Body (JSON only)', { marginTop: '10px', })}
                    <textarea
                        style={{
                            fontSize: '12px',
                            lineHeight: '18px',
                            background: 'rgb(47, 48, 86)',
                            border: '1px solid rgb(221, 221, 221)',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                            color: 'rgb(69, 207, 114)',
                            width: '100%',
                            height: '100px',
                            padding: '5px 10px',
                            margin: '5px 0px',
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#1c1d34 #2A2B4D'
                        }}
                        value={localWebhookState.customizeBody.body}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setLocalWebhookState({
                            ...localWebhookState,
                            customizeBody: {
                                ...localWebhookState.customizeBody,
                                body: event.target.value,
                            }
                        })}
                    />
                    <SelectVariable buttonSize='small' onSelect={(item) => {
                        setLocalWebhookState({
                            ...localWebhookState,
                            customizeBody: {
                                ...localWebhookState.customizeBody,
                                body: localWebhookState.customizeBody.body + item,
                            }
                        })
                    }} />
                </>
            }
        </div>
    )
}