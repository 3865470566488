import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { useRouterWeb } from "./use-router-web";
import { useRouterExtension } from "./use-router-extension";

export function useRouter() {

  if (isExtensionContextOrWhatsappView()) {
    return useRouterExtension()
  }

  else {
    return useRouterWeb()
  }

}
