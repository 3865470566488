
import { Box, Stack } from '@mui/material';

const ContactWorkSpaceAdmin = () => {



    return (
        <Stack textAlign={"center"} sx={{borderRadius:"8px"}} position={"relative"} justifyContent={"center"} alignItems={"center"} p={4} spacing={4}>
           <Box> Oh no</Box>
          <Box>  Trial ended, contact the workspace owner.</Box>


        </Stack>
    );
};

export default ContactWorkSpaceAdmin;