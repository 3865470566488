import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactInfo } from "@common/models/contact-info";

export interface CellEditDescriptor {
  contactInfo?: ContactInfo | undefined;
  path?: string;
  _value?: string;
}

export interface ContactTableSliceState {
  filterValue: string;
  showSelected: boolean;
  activeCell?: CellEditDescriptor;
  importedContactSnackbarOpen: boolean;
  importedContactSnackbarMessage?: string;
}

const initialState: ContactTableSliceState = {
  filterValue: "",
  showSelected: false,
  importedContactSnackbarOpen: false,
};

export const ContactTableSlice = createSlice({
  name: "contactTableSlice",
  initialState,
  reducers: {
    updateSearchValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    updateActiveCell: (state, action: PayloadAction<CellEditDescriptor>) => {
      state.activeCell = action.payload;
    },
    clearActiveCell: (state) => {
      delete state.activeCell;
    },
    openImportedContactSnackbar: (state, action: PayloadAction<void>) => {
      state.importedContactSnackbarOpen = true;
    },
    closeImportedContactSnackbar: (state, action: PayloadAction<void>) => {
      state.importedContactSnackbarOpen = false;
    },
    setImportedContactSnackbarMessage: (
      state,
      action: PayloadAction<string>
    ) => {
      state.importedContactSnackbarMessage = action.payload;
    },
    setShowSelected: (state, action: PayloadAction<boolean>) => {
      state.showSelected = action.payload;
    },
  },
});

export const {
  updateSearchValue,
  clearActiveCell,
  updateActiveCell,
  openImportedContactSnackbar,
  closeImportedContactSnackbar,
  setImportedContactSnackbarMessage,
  setShowSelected,
} = ContactTableSlice.actions;

export const ContactTableReducer = ContactTableSlice.reducer;
export default ContactTableSlice.reducer;
