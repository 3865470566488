import LazyLoadingVirtuoso from '@extension/context/content/components/bt-bulk-dashboard/lazy-loading-virtuoso'
import { Stack } from '@mui/system'
import { getCurrentBoard } from '@common-reducers/BoardsSelectors'
import { DBLogsThunks, logsSelectByQuery } from "@common-reducers/DBServiceThunks"
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import LogRow from './log-row'
import { getSortedBoardLogs } from '@common-reducers/LogsSelectors'
import { useEffect } from 'react'



export default function ActivityLog() {
    const dispatch = useAppDispatch()
    
    const board = useAppSelector(getCurrentBoard);

    useEffect(() => {
        console.log("get all board logs");
        dispatch(
            DBLogsThunks.find({
                boardId: board?._id,
                deleted: false,
                $paginate: false,
                $sort: {
                    createdAt: -1
                },
            }))
    }, [])

    return (
        <>
            <h1> {board?.titleIcon ?? ""} {board?.name} <span style={{ fontWeight: 400, fontSize: '32px', }}>Log</span></h1>

            <LazyLoadingVirtuoso
                height={735}
                // rowHeightString='auto'
                filterObj={{}}
                DBThunks={DBLogsThunks}
                DBReducer={state => state.DBLogReducer}
                selectByQuery={logsSelectByQuery}
                Component={LogRow}
                isFullWidth={true}
                selector={getSortedBoardLogs}
            />
        </>
    )
}


