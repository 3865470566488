import AccessForm, { FormState } from "@common-components/access-form/access-form";
import classes from './login-view.module.scss';
export interface LoginViewProps {
    initialState?: FormState
}

export interface LoginViewState {
    loading: boolean;
}

export const LoginView = ({ initialState }: LoginViewProps) => {

    return (<div className={classes.loginViewContainer}>
        <AccessForm initialState={initialState}></AccessForm>
    </div>);
}

export default LoginView;
