import React from "react";


import { ColumnProps } from "../../../types/public-types";









import PersonPickerCell from "@common-components/tasks/columns-cells/person-picker-cell/person-picker-cell";
import { getCurrentBoard } from "@common-reducers/BoardsSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { columnListSelector, getGanttTaskItem } from "@common-reducers/TaskSelectors";

export const PersonColumn: React.FC<ColumnProps> = ({

  data: {
    dateSetup: {
      dateFormats,
      dateLocale,
    },

    task,


  },
}) => {


  const currentBoard = useAppSelector(getCurrentBoard);
  const boardId = currentBoard._id;

  const columnList = useAppSelector(state => columnListSelector(state, currentBoard._id));
  const [firstPersonColumn] = columnList.filter(column => column.type === 'person-picker');
  const columnId = firstPersonColumn._id;
  const taskId = task.id;
  const groupId = task.parent;
  // const ganttTasks: Gantt[] = useAppSelector((state) => getGanttTasks(state, columnId), isEqual);
  const ganttTaskItem: any = useAppSelector((state) => getGanttTaskItem(state, taskId, columnId));


  const onValueUpdate = (value: string) => {
    console.log('value')
    // console.log(value)
    // setData(value)
    // dispatch(updateCellValueThunk({
    //   taskId,
    //   columnId,
    //   data: {
    //     value: value
    //   }
    // }));


  }
  const clearValue = () => {
    onValueUpdate(undefined)
  }
  // const onChange = (item) => {
  //   let startDate = item.startDate;
  //   let endDate = item.endDate;
  //   const today = new Date();
  //   let progressInPercentage = ((today.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) * 100;
  //   progressInPercentage = Math.trunc(progressInPercentage)
  //   const output = {
  //       startDate,
  //       endDate,
  //       progressInPercentage:50,
  //   }
  //   // onValueUpdate(output)
  // }
  // console.log('ganttTaskItem')
  // console.log(ganttTaskItem)

  if (task.type === "empty") {
    return null
  }

  try {

    return (
      <div style={{ margin: '0 10px', display: 'flex', justifyContent: 'center ' }}>
        <PersonPickerCell cellValue={ganttTaskItem} taskId={taskId} onUpdate={clearValue} openPicker={() => console.log('close')} togglePicker={() => console.log('close')} closePicker={() => console.log('close')} boardId={boardId} columnId={columnId} groupId={groupId} isFormInEditMode={false} />
      </div>
    );
  } catch (e) {
    return (
      <>
        {task.start.toString()}
      </>
    );
  }
};
