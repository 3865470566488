import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import { modalTextStyle } from '@common-components/bt-chatbot/bot-components/bt-chatbot-modal';
import SelectVariable from '@common-components/bt-chatbot/bot-components/select-variable';
import BTInputField from '@common-components/bt-input-field/bt-input-field';
import RowStack from '@common-components/common/row-stack/RowStack';
import { Close, HelpRounded } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useStore } from '@common/reducers/ChatbotReducer';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { flowConditionTypeArray } from '../bt-nodes-def';
import classes from "./condition-node.module.scss";



const toolTipText = 'For "Chatbot input variables," they should start with @, such as @age. For "Contact custom variables," they should be formatted as {{ ... }}, like {{ phone }}. Users can also choose the variable by clicking the "VARIABLES" button.'


type Props = {
    onClose: () => void
    id?: string;
}


export default function AddConditionDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()

    const { setNodes } = useStore();

    const data = useAppSelector(state => getNodeDataByNodeId(state,id))

    const [localConditionState, setLocalConditionState] = useState<any>(data?.flowNodeConditions ?? {
        // condition data structure
        // flowNodeConditions: {
        firstCondition: {
            flowConditionType: "Equal to",
            //         variable: "@myage",
            //         value: "23"
        },
        //     secondCondition: {
        //         flowConditionType: "LessThan",
        //         variable: "@myage",
        //         value: "23"
        //     },
        //     conditionOperator: "AND",
        // }
    })



    const onChangeCondition = (conditionOrder: 'first' | 'second', conditionDataToPatch) => {
        const newCondition = () => {
            let _newCondition
            if (conditionOrder === 'first') {
                _newCondition = {
                    ...localConditionState,
                    firstCondition: {
                        ...localConditionState?.firstCondition,
                        ...conditionDataToPatch
                    }
                }
                return _newCondition
            } else {
                _newCondition = {
                    ...localConditionState,
                    secondCondition: {
                        ...localConditionState?.secondCondition,
                        ...conditionDataToPatch
                    }
                }
                return _newCondition
            }
        }

        // dispatch(changeNodeDataThunk({
        //     setNodes,
        //     id,
        //     dataToPatch: newCondition(),
        // }))
        setLocalConditionState(newCondition())
    }

    const onClickDeleteCondition = () => {
        setLocalConditionState({
            firstCondition: { ...localConditionState?.firstCondition },
        })
    }

    const conditionBox = (conditionOrder: 'first' | 'second') => {
        const condition = conditionOrder === 'first' ?
            localConditionState?.firstCondition
            :
            localConditionState?.secondCondition

        return (
            <Stack className={classes.condition_popover_box}>
                <BTInputField
                    value={condition?.variable ?? ''}
                    type="text"
                    onChange={({ target }: any) => {
                        onChangeCondition(conditionOrder, { variable: target.value })
                    }}
                    placeholder={'Type or select a variable'}
                    startAdornment={
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '50%',
                            left: '13px',
                            transform: 'translateY(-50%)',
                        }}>
                            <Tooltip title={toolTipText}><HelpRounded sx={{ color: '#333333', }} /></Tooltip>
                            <span style={{ color: '#333333', fontWeight: 600, marginLeft: '10px', }}>IF</span>
                        </div>
                    }
                    endAdornment={
                        < SelectVariable onSelect={(item) => onChangeCondition(conditionOrder, { variable: item })} />
                    }
                    sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "0px",
                        },
                        "&.Mui-focused": {
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                            },
                        },
                        "&: hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                            },
                        },
                        ".MuiOutlinedInput-input": {
                            ...modalTextStyle,
                            marginLeft: '55px',
                            lineHeight: '19px',
                        },
                        height: '40px',
                    }}
                />
                < Select
                    value={condition?.flowConditionType}
                    // options={flowConditionTypeArray}
                    onChange={(event) => {
                        setLocalConditionState({
                            ...localConditionState,
                            [conditionOrder === 'first' ? 'firstCondition' : 'secondCondition']: {
                                ...condition,
                                flowConditionType: event.target.value
                            }

                        })
                    }}
                    sx={{
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        height: '40px',
                        ".MuiOutlinedInput-notchedOutline": {
                            border: 0,
                        },
                        "&.Mui-focused": {
                            ".MuiOutlinedInput-notchedOutline": {
                                border: 0,
                            },
                        },
                        ".MuiOutlinedInput-input": {
                            marginTop: '-7px',
                            ...modalTextStyle,
                            lineHeight: '19px',
                            padding: '16.5px 14px 7.5px 21px',
                        },
                    }}>
                    {flowConditionTypeArray.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                    })}
                </Select>
                < RowStack >
                    <BTInputField
                        value={condition?.value ?? ''}
                        type="text"
                        onChange={({ target }: any) => {
                            onChangeCondition(conditionOrder, { value: target.value })
                        }}
                        placeholder={'Type a value'}
                        endAdornment={
                            <SelectVariable onSelect={(item) => onChangeCondition(conditionOrder, { value: item })} />
                        }
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0px",
                            },
                            "&.Mui-focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                },
                            },
                            "&: hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                },
                            },
                            ".MuiOutlinedInput-input": {
                                ...modalTextStyle,
                                lineHeight: '19px',
                            },
                            height: '40px',
                            width: '100%',
                        }}
                    />
                </RowStack >
                {conditionOrder === 'second' &&
                    <Button sx={{
                        width: '100%',
                        ...modalTextStyle,
                        color: 'red',
                        border: '1px solid red',
                        backgroundColor: 'rgba(255, 42, 42, 0.05)',
                    }} onClick={onClickDeleteCondition}>Delete condition</Button>
                }
            </Stack >
        )
    }

    const toggleConditionOperator = () => {
        const toggleButtonSX = (type: 'AND' | 'OR') => {
            return (
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '19px',
                    width: '60px',
                    height: '28px',
                    border: 0,
                    color: localConditionState?.conditionOperator === type ? 'rgb(245, 246, 250) !important' : 'rgb(35, 164, 85) !important',
                    backgroundColor: localConditionState?.conditionOperator === type ? 'rgb(35, 164, 85) !important' : 'transparent !important',
                }
            )
        }
        return (
            <RowStack sx={{ width: '100%', justifyContent: 'space-around', }}>
                <div style={{
                    margin: '30px 0px 40px 0px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    width: '138px',
                    height: '40px',
                    alignItems: 'center',
                    background: 'rgb(245, 246, 250)',
                    padding: '6px',
                    borderRadius: '5px',
                }}>
                    <Button onClick={() => setLocalConditionState({
                        ...localConditionState,
                        conditionOperator: 'AND'
                    })} sx={toggleButtonSX('AND')} value="AND">AND</Button>
                    <Button onClick={() => setLocalConditionState({
                        ...localConditionState,
                        conditionOperator: 'OR'
                    })} sx={toggleButtonSX('OR')} value="OR">OR</Button>
                </div>
            </RowStack >
        )
    }

    console.log(localConditionState?.conditionOperator)
    const handleClickAddCondition = () => {
        setLocalConditionState({
            ...localConditionState,
            secondCondition: {
                flowConditionType: "Equal to",
                variable: "",
                value: ""
            },
            conditionOperator: 'AND'
        })
    }

    const saveNewConditionData = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: { flowNodeConditions: localConditionState },
        }))
        onClose()
    }

    return (
        <>
            <RowStack className={classes.add_condition_popover_header}>
                <span>Set a condition</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </RowStack>
            <Stack className={classes.add_condition_popover_body}>
                <div className={classes.condition_popover_body_header}>Set the condition(s)</div>


                {conditionBox('first')}

                {!localConditionState.conditionOperator &&
                    <BTbotbutton
                        sx={{ width: '100px', }}
                        styleType="dashed"
                        onClick={handleClickAddCondition}>
                        +Add
                    </BTbotbutton>
                }

                {localConditionState.conditionOperator && toggleConditionOperator()}
                {localConditionState.conditionOperator && conditionBox('second')}

                <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                    <BTbotbutton
                        sx={{ marginLeft: '13px', }}
                        styleType="empty"
                        onClick={onClose}>
                        Cancel
                    </BTbotbutton>
                    <BTbotbutton
                        sx={{ marginLeft: '13px', }}
                        styleType="full"
                        onClick={saveNewConditionData}>
                        Save
                    </BTbotbutton>
                </RowStack>
            </Stack>
        </>
    )
}

