import classes from "./files_icons.module.scss";
import spreadSheetIcon from "./spreadsheets.png";
import docsIcon from "./docs.png";
import pdfIcon from "./pdf.png";
import compressionIcon from "./compression.png";
import persentaionIcon from "./presentations.png"
import general from "./file.png"
import ai from "./ai.png"

export interface Props {
  file: Record<string, any>;
  style?:{}
}
export default function FilesIcons({ file,style }: Props) {
  function renderIcon(item) {
    let icon = "";
    if (item?.includes("sheet")) {
      icon = spreadSheetIcon;
    }
    if (item?.includes("pdf")) {
      icon = pdfIcon;
    }
    if (item?.includes("word")) {
      icon = docsIcon;
    }
    if (item?.includes("text")) {
      icon = general;
    }
    if (item?.includes("zip") || item?.includes("rar")) {
      icon = compressionIcon;
    }
    if (item?.includes("presentation")) {
      icon = persentaionIcon;
    }
    if (item?.includes("postscript")) {
      icon = ai;
    }
    if (icon === "") {
      icon = general;
    }
    return icon;
  }

  return (
    <img
      className={
        file?.type?.includes("image") ? classes.imgIcon : classes.docIcons
      }
      style={style}
      src={file?.type?.includes("image") ? file?.url : renderIcon(file?.type)}
      alt={file?.filename}
    />
  );
}
