import classes from "./files_versions.module.scss";
import { mediaManager } from "@common-reducers/MediaManagerReducer";
import { useAppDispatch} from "@common-reducers/hooks/store.hook";
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useState } from "react";
import FilesIcons from "@common-components/files-icons/filesIcons";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { getFilesCurrentIndexAndVersionCurrentIndex } from "@common-reducers/MediaManagerSelectors";
import { deleteVersion, setCurrentVersionAsPrimary } from "@common-reducers/MediaManagerThunks";

export default function FilesVersions() {
    console.log('file versions render');
    const dispatch = useAppDispatch()
    const { fileList, filesCurrentIndex, currentFileVersion } = useAppSelector(getFilesCurrentIndexAndVersionCurrentIndex);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [versionMenu, setOpenVersionMenu] = useState(null);
    const currentFileShown = fileList[filesCurrentIndex];
    const fileVersions = currentFileShown.versions;
    let filesStateUpdate = {};
    const openVersionMenu = (file) => (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setOpenVersionMenu(file);
    };
    const closeVersionMenu = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenVersionMenu(null);
    };
    function setVersionPreview(e, i) {
        e.stopPropagation();
        filesStateUpdate["isVersionPreview"] = i === -1 ? false : true;
        filesStateUpdate["versionIndex"] = i;
        dispatch(
            mediaManager.setVersionPreview(filesStateUpdate)
        );
    }
    function setAsCurrentVersion(e, currentVersionFile, index) {
        e.stopPropagation();
        dispatch(setCurrentVersionAsPrimary({ currentVersionFile, index }));

    }
    function deleteSelectedVersion(index) {
        dispatch(deleteVersion(index));
    }
    return (

        <div className={classes.versionsWrapper}> Current Version

            <div onClick={(e) => setVersionPreview(e, -1)} className={`${classes.fileContainer} ${currentFileVersion === -1 ? classes.versionSelected : ''}`}>

                <FilesIcons file={currentFileShown} />
                <div className={classes.versionDetails}>
                    <div className={classes.fileNameContainer}>
                        <span className={classes.fileName}> {currentFileShown.filename}</span>
                    </div>
                    <span className={`${classes.versionSquare}  ${currentFileVersion === -1 ? classes.versionSelected : ''} `}> V{currentFileShown.version}</span>
                    {currentFileShown.updatedAt}
                </div>
            </div>
            <div className={classes.versionsContainer}>

                {fileVersions?.length > 1 && (
                    <div>
                        Previous Versions
                    </div>
                )}

                <div className={classes.versionsColumn}>
                    {fileVersions?.length > 0 && fileVersions?.slice(0,-1).map((fileVersion, i) => {
                        return (
                            <div onClick={(e) => setVersionPreview(e, i)} key={i} className={`${classes.fileContainer} ${currentFileVersion === i ? classes.versionSelected : ''} `}>

                                <FilesIcons file={fileVersion} />
                                <div className={classes.versionDetails}>
                                    <div className={classes.fileNameContainer}>
                                        <span className={classes.fileName}> {currentFileShown.filename}</span>
                                        <span className={classes.versionMenu}
                                        >     <IconButton style={{ padding: 0 }} color="primary" aria-label="version menu" component="label"

                                            onClick={openVersionMenu(fileVersion)} >
                                                <BiDotsHorizontalRounded /></IconButton></span>

                                        <Menu
                                            anchorEl={anchorEl}
                                            open={versionMenu === fileVersion}
                                            onClose={(e) => closeVersionMenu(e)}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <MenuItem onClick={(e) => setAsCurrentVersion(e, fileVersion, i)} sx={{ fontSize: 12 }}>Set as current version</MenuItem>
                                            <MenuItem onClick={() => deleteSelectedVersion(i)} sx={{ fontSize: 12 }}>Delete Version</MenuItem>
                                        </Menu>
                                    </div>
                                    <span className={`${classes.versionSquare} ${currentFileVersion === i ? classes.currentVersion : ''}`}> V{fileVersion.version}</span>
                                    {fileVersion.updatedAt}
                                </div>
                            </div>

                        );
                    })}
                </div>
            </div>
        </div>

    )
}
