import BoardLoaderLottie from '@common-assets/lottie/board-loader-animation-blue.json';
import { Stack } from "@mui/material";
import Lottie from 'lottie-react';
import { memo } from 'react';

function BoardsLoader({ loadingMessage = "" }) {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                height: '100vh',
                width: '100%',
                margin: 'auto',
            }}>
            <Lottie autoPlay={true} loop={true} animationData={BoardLoaderLottie} style={{ width: '300px', height: '300px' }} />
            <h3 style={{ marginTop: "-60px", color: "#787878" }}>{loadingMessage}</h3>
        </Stack>
    )
}

export default memo(BoardsLoader);
