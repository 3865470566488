import { AccountPage } from "@common-components/account-page/account-page";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { SideBarItem } from "@common-components/sidebar/sidebar";
import MessageCalendar from "@common-views/calendar/message-calendar";
import classes from "../account-view/account-view.module.scss";
import { t } from "i18next";
import SocketConnectionStatusButton from "@common-components/socket-connection-status-button/socket-connection-status-button";
import { toggleSocketModal } from "@common-reducers/MainReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { WASession } from "@common-models/wasession";
import { selectCurrentUserSession } from "@common-reducers/UserSelectors";


const CalendarView = () => {
  const dispatch = useAppDispatch();
  const session: WASession = useAppSelector(state => selectCurrentUserSession(state));
  const isSocketConnectedToWhatsApp = session?.active && session?.status === "connected";


  const CalendarViewContent = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>{t('sideBar.calendar')}</h4>
          {isSocketConnectedToWhatsApp &&
            <div style={{ marginLeft: "5px" }}
              onClick={() => {
                dispatch(toggleSocketModal(true))
              }}>
              <SocketConnectionStatusButton />
            </div>
          }
        </div>

        <MessageCalendar onClose={() => { }} />

      </div>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={CalendarViewContent()}
        active={SideBarItem.Calendar}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default CalendarView;
