import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectDatePickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { getWorkSpaceById } from "@common-reducers/WorkspaceSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentTrigger } from "../automations-helper";
import { getBoard } from "@common-reducers/TaskSelectors";


const EveryDayDateHasPassedTrigger = () => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnName, setColumnName] = React.useState<string>('column');

    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectDatePickerColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const { workspaceId } = useAppSelector(getBoard);
    const getWorkspaceById = getWorkSpaceById(workspaceId);
    const { timezone } = useAppSelector(getWorkspaceById);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const handleColumnChange = (id, name) => {
        const nextTrigger = getNextDayAtMidnightUTC();
        const nextTriggerUtc = nextTrigger.toISOString();
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { columnId: id, isCron: true, nextTrigger: nextTriggerUtc })));
        setColumnName(name);
        handleClose();
    };

    function getNextDayAtMidnightUTC() {
        const now = new Date();
        now.setUTCHours(0, 0, 0, 0);
        now.setUTCDate(now.getUTCDate());
        return now;
    }

    useEffect(() => {
        const selectedColumnId = triggerObj.columnId;
        const column = filteredColumns.find(item => item.id === selectedColumnId);
        setColumnName(column?.name ?? "Date");
        dispatch(automation.fieldValidityChanged({ component: 'EveryDayDateHasPassedTrigger', index: 0, field: 'columnId', isValid: !!column?.name }));
    }, [triggerObj, filteredColumns, dispatch]);
    return (
        <Stack>
            <Stack sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    Every day, if
                    <AutomationButton
                        componentName='EveryDayDateHasPassedTrigger'
                        index={0}
                        option='columnId'
                        label={columnName}
                        handleClick={handleClick}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                    has passed
                </Box>
                <AddAndRemove elements={conditions} componentName="EveryDayDateHasPassedTrigger" index={0} type={"trigger"} />
            </Stack>


            <PickerColumn
                anchorEl={anchorEl}
                handleClose={handleClose}
                filteredColumns={filteredColumns}
                handleColumnChange={handleColumnChange}
            />


        </Stack>


    )
}
export default EveryDayDateHasPassedTrigger