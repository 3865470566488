import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

 
export interface NotifierState {
  notifierSocket?: Socket;
}

const initialState: NotifierState = {
  notifierSocket: null,
};

const NotifierSlice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    setNotifierSocket: (state, action: PayloadAction<Socket>) => {
      state.notifierSocket = action.payload as any;
    },
  },
});

export const { setNotifierSocket } = NotifierSlice.actions;

export const NotifierActions = NotifierSlice.actions;

export default NotifierSlice.reducer;
