import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { handleClickWeedTimeSlotThunk } from '@common-reducers/CalendarThunks';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import moment from 'moment';
import React from 'react';
import './calendar-custom.css';
import classes from "./message-calendar.module.scss";





export const CustomDayBackground = ({ children, date }) => {
    const dispatch = useAppDispatch()


    const hasNestedChildrenWithoutExcludedClass = React.Children.toArray(children).some(child =>
        React.isValidElement(child) &&
        child.props &&
        child.props.children &&
        // !child.props.className?.includes('customEvent') && // Check for absence of excluded class
        child.props.children !== undefined
    );

    const isHalfHour = moment(date).minutes() === 30



    return (
        <div className={classes.week_view_time_slot_wrapper} style={{
            position: 'relative',
            width: '100%',
            height: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
            opacity: !hasNestedChildrenWithoutExcludedClass && 1,
            zIndex: 8,
        }} onClick={(event) => dispatch(handleClickWeedTimeSlotThunk({ event, date }))}>
            {children}
            {!hasNestedChildrenWithoutExcludedClass &&
                <IconButton className={classes.addDayButton} sx={{
                    // opacity: 0,
                    margin: isHalfHour ? '0px 4px 2px 0px !important' : '2px 4px 2px 0px !important',
                }}>
                    <AddIcon id={date} sx={{ width: "16px" }} />
                </IconButton>
            }
        </div>
    );
};