import { AppConfig } from "./app.config";

export const appConfigProdSupport: AppConfig = {
  DEFAULT_TITLE: 'Blueticks',
  asset_key: 'blueticks',
  pricingOptions: 'extension',
  google_client_id: '143723739146-oa75527ckplc3kb9nk8q41redtoq6t18.apps.googleusercontent.com',
  GTM_ID: 'GTM-NGKDFBM',
  GA_ID: 'G-RCEGPEQEZ5',
  CLARITY_ID:'',
  TIKTOK_ID:'',
  FB_PIXEL_ID: '',
  AW_ID: 'AW-591909474',
  WS_ID: 'G-8YP76PWS2J',
  HJ_ID: 2742843,
  HJ_SV: 6,
  ENV: "support",
  isProd: true,
  EXTENTION_ID: "adgnjhngogijkkppficiiepmjebijinl",
  GATEWAY_ENDPOINT: "https://gateway.blueticks.co",
  NOTIFIER_ENDPOINT: "https://notify.blueticks.co",
  VZNLYTICS_ENDPOINT: "https://analytics.blueticks.co",
  WS_ENDPOINT: "wss://api.blueticks.co",
  API_ENDPOINT: "https://blueticks-prod-support-47be297e238b.herokuapp.com/",
  PRICING_PAGE: "https://blueticks-support.netlify.app",
  APP_ENDPOINT: "https://blueticks-support.netlify.app",
  HOMEPAGE: "https://blueticks.co",
  LOGO:"/img/tudu-logo.svg",
  LOGO2:"/img/tudo-logo-icon.svg",
  PAYPAL_CLIENT_ID:
    "AafpAM2aA4Lqus7h_iAa2szoitZM7-hkyZaU5jjYOtv-KqcuZCpFFlpbluVp4_F-6fQhcIui7AWZs9Ah",
  BLUESNAP_ENDPOINT: "https://bluesnap.com",
  BLUESNAP_STORE_ID: "648495",
  BLUESNAP_REDIRECT_ENC:
    "y3BO7mSu04vXEh55QSFI51vnnO%2FrL2uZ10%2FPUF2Fh8urn%2BFdpIn4tyxpPqhcCnm%2BVOdueGkdnZpbpSjw%2Fj215nog250lMwUyqKry1OkfDap8daL1Nxv4dJeDeSXd9Ai6vUKYhCKeZaQ%2ByyfLkDPwJwlx1BGnPQYlRYkYiZBknRcVCl6kAgHv5ZTKEc3YMGPO4kc9iJJQnqXsotsbI3Z5jgJ5rXvslcQJXPihvCycO2%2FJfYH%2F2cr5s6ZLhSZZZaZY",
  
  extensionStoreLink: "https://chrome.google.com/webstore/detail/blueticks/adgnjhngogijkkppficiiepmjebijinl",
};
export default appConfigProdSupport;
