import { ThumbUpTwoTone } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import { notificationsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import RenderUpdate from '../editor/RenderUpdate';

type Props = { notificationId: string; }

export default function ExtraDataNotification({ notificationId, }: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))

    const actionString = () => {
        if (notification.actionType === 'mentioned') {
            return (
                <span>Mentioned you on</span>
            )
        }
        if (notification.notificationUpdateType === 'new extra data') {
            return (
                <span>Left an update on</span>
            )
        }
        if (notification.notificationUpdateType === 'updated extra data') {
            return (
                <span>Edited an update on</span>
            )
        }
        if (notification.notificationUpdateType === 'deleted extra data') {
            return (
                <span>Deleted the update on</span>
            )
        }
        if (notification.notificationUpdateType === 'extra data reaction') {
            return (
                <span>
                    Reacted <ThumbUpTwoTone sx={{
                        verticalAlign: 'bottom',
                        // Secondary color
                        '--mui-svg-icon-secondary-color': 'var(--background-default)',
                    }} /> to an update on
                </span>
            )
        }
    }
    return (
        <Stack sx={{ width: '100%', }}>
            <span>{actionString()}&nbsp;
                <b>{notification.taskName}:</b></span>
            <Box sx={{ paddingInlineEnd: '15px', }}>
                <RenderUpdate update={notification.actionOnEntityData} bgColor={'transparent'} />
            </Box>
        </Stack>
    )
}