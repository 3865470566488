import React from "react";
import classes from "./signup-form.module.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";

interface SignupStatusProps extends WithTranslation {
    isLoading: boolean
}

interface SignupStatusState {
    count: number
}

class SignupStatus extends React.Component<SignupStatusProps, SignupStatusState> {
    interval: any = null;

    constructor(props: SignupStatusProps) {
        super(props);
        this.state = { count: 0 };
    }

    tick() {
        if (this.props.isLoading) {
            this.setState({ count: this.state.count + 1 })
        }
    }

    componentDidMount(): void {
        this.interval = setInterval(() => this.tick(), 1000)
    }

    renderStatusText() {
        const { t } = this.props;
        if (this.state.count === 0 && !this.props.isLoading) return t('signupStatus.default');
        if (this.state.count <= 2) return t('signupStatus.statusOne');
        if (this.state.count > 2 && this.state.count <= 4) return t('signupStatus.statusTwo');
        if (this.state.count > 4) return t('signupStatus.statusThree');
        if (this.state.count === 6) {
            clearInterval(this.interval)
        }        
    }

    render() {
        return (
            <p className={classes.animatedStatus}>
                {this.renderStatusText() ?? <></>}
            </p>
        );
    }
}

export default withTranslation(namespace)(SignupStatus);