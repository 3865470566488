import { Button, ButtonProps, SvgIconProps, Tooltip } from '@mui/material';

interface ToolbarButtonProps extends ButtonProps {
  startIconSVG?: React.ElementType<SvgIconProps>;
  onClickAction: (e) => void;
  buttonText: string;
  toolTipTitle:string;
  additionalStyles?: Record<string, any>;
  id?: string; // Add this
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  startIconSVG,
  onClickAction,
  buttonText,
  toolTipTitle,
  additionalStyles,
  ...restProps
}) => {
  const commonStyles = {
    cursor: "pointer",
    opacity: '0.5',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    height: '36px',
    padding: '0 16px',
    fontSize: '14px',
    borderRadius: '8px',
    fontWeight: '600',
    border: '0',
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'var(--secondary-text-color)',
    display: 'flex',
    textTransform: 'none',
  };

  const StartIcon = startIconSVG;

  return (
    <Tooltip title={toolTipTitle} arrow placement="top">

    <Button
    {...restProps}
      startIcon={StartIcon ? <StartIcon style={{ width: 16, height: 16 }} /> : null}
      onClick={onClickAction}
      sx={{ ...commonStyles, ...additionalStyles }}
 
    >
      {buttonText}
    </Button>
    </Tooltip>
  );
};

export default ToolbarButton;
