import PromptDeleteAction from "@common-components/prompt-delete-action/prompt-delete-action";
import SelectUserRole from "@common-components/select-user-role";
import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import { userDisplayName } from "@common-models/user";
import { Workspace } from "@common-models/workspace";
import { BlueticksWorkspaceUser, ValidManualStatusList } from "@common-models/workspace-user";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { getSortedAndFilteredWorkspaceUserList } from "@common-reducers/BoardsSelectors";
import {
  DBWorkspaceUserThunks
} from "@common-reducers/DBServiceThunks";
import { taskActions } from "@common-reducers/TasksReducer";
import { selectCurrentWorkspace } from "@common-reducers/UserSelectors";
import {
  useAuthenticateMutation
} from "@common-reducers/backend-api/backend-api";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function WorkspaceUsersMembers({readOnly}) {
  const { t } = useTranslation(namespace);
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const sortedAndFilteredWorkspaceUserList = useAppSelector(state => getSortedAndFilteredWorkspaceUserList(state));


  const currentWorkspace = useAppSelector(selectCurrentWorkspace);


  const cellHeadStyle = {
    fontFamily: '"Segoe UI", Tahoma',
    padding: '8px 16px',
    color: '#809BA8',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '19px',
    borderBottom: 'none',
  }

  const buildTable = () => (
    <>
      <TableContainer sx={{
        background: 'unset',
        overflow: 'hidden',
        boxShadow: 'none',
        marginTop: "16px",
      }} component={Paper}>
        <Table sx={{
          width: '100%', overflowY: 'scroll',
          marginBottom: '20px',
        }}>
          <TableHead sx={{
            background: 'unset'
          }}>
            <TableRow  >
              <TableCell align={direction === "ltr" ? "left" : "right"} sx={{
                ...cellHeadStyle
              }}>{t("workspaceUsersMembers.headerName")}</TableCell>
              <TableCell align={direction === "ltr" ? "left" : "right"} sx={{
                ...cellHeadStyle
              }} >{t("workspaceUsersMembers.headerEmail")}</TableCell>
              <TableCell align={direction === "ltr" ? "left" : "right"} sx={{
                ...cellHeadStyle
              }} >{t("workspaceUsersMembers.headerType")}</TableCell>
              <TableCell align={direction === "ltr" ? "left" : "right"} sx={{
                ...cellHeadStyle
              }} >{t("workspaceUsersMembers.headerStatus")}</TableCell>
              <TableCell align={direction === "ltr" ? "left" : "right"} sx={{
                ...cellHeadStyle
              }} />
            </TableRow>
          </TableHead>
          <TableBody sx={{
            background: "var(--background-default)",
            borderRadius: "8px",
            "& .MuiTableRow-root:last-child td": {
              border: 0,
            },
            "& .MuiTableRow-root:first-of-type td:first-of-type": {
              borderTopLeftRadius: direction === "ltr" ? "12px" : undefined,
              borderTopRightRadius: direction === "rtl" ? "12px" : undefined,
            },
            "& .MuiTableRow-root:first-of-type td:last-child": {
              borderTopRightRadius: direction === "ltr" ? "12px" : undefined,
              borderTopLeftRadius: direction === "rtl" ? "12px" : undefined,

            },
            "& .MuiTableRow-root:last-child td:first-of-type": {
              borderBottomLeftRadius: direction === "ltr" ? "12px" : undefined,
              borderBottomRightRadius: direction === "rtl" ? "12px" : undefined,

            },
            "& .MuiTableRow-root:last-child td:last-child": {
              borderBottomRightRadius: direction === "ltr" ? "12px" : undefined,
              borderBottomLeftRadius: direction === "rtl" ? "12px" : undefined,
            },
          }}>
            {sortedAndFilteredWorkspaceUserList.map((workspaceUser: BlueticksWorkspaceUser, index) => (
              <Row
                user={workspaceUser}
                workspace={currentWorkspace}
                key={index}
                readOnly={readOnly}
              />
            ))}
          </TableBody>
        </Table>
        {/* <InviteUserToWorkspaceDialog
          open={inviteDialogOpen}
          workspace={currentWorkspace}
          onClose={() => {
            setInviteDialogOpen(false);
          }}
        /> */}
      </TableContainer>
    </>
  );

  return buildTable();
}

interface RowProps {
  user: BlueticksWorkspaceUser;
  workspace: Workspace;
  readOnly?: boolean;
}

const Row = ({ user, workspace,readOnly }: RowProps) => {
  const { t } = useTranslation(namespace);
  const direction = useAppSelector((state) => state.UserReducer.direction);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading: isAuthenticating }] = useAuthenticateMutation();
  const dispatch = useAppDispatch()
  const loading = useMemo(() => isAuthenticating, [isAuthenticating]);
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);

  const handleDeleteConfirmationClosed = useCallback((shouldDelete) => {
    if (shouldDelete) {
      dispatch(DBWorkspaceUserThunks.delete({
        entity: user
      }));
    }
    setDeletePromptOpen(false);
  }, [dispatch, user])

  const changeUserRole = useCallback(async (role: string, user: BlueticksWorkspaceUser) => {
    const res: any = await dispatch(DBWorkspaceUserThunks.patch({
      entity: {
        _id: user._id,
        role
      }
    }));
    if (res?.payload?.message) {
      dispatch(taskActions.setTaskSnackbarMessage(res?.payload?.message));
    }
  }, [dispatch]);

  const changeUserStatus = useCallback(async (event, user: BlueticksWorkspaceUser) => {
    const status = event.target.value;
    dispatch(DBWorkspaceUserThunks.patch({
      entity: {
        _id: user._id,
        status
      }
    }));
  }, [dispatch]);

  const buildLoader = () => (
    <TableRow>
      <TableCell colSpan={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ p: 1 }}
        >
          <CircularProgress></CircularProgress>
        </Stack>
      </TableCell>
    </TableRow>
  );

  const buildRow = () => (

    <>
      <PromptDeleteAction title={`${t("workspaceUsersMembers.removeUserPrompt")} ${user.email}?`} handleClose={handleDeleteConfirmationClosed} open={deletePromptOpen} />
      <TableRow sx={{
        '& .MuiTableCell-root': {
          color: 'var(--secondary-text-color)',
        }
      }} >
        {/* <TableCell>
        <Checkbox  />
        </TableCell> */}
        <TableCell align={direction === "ltr" ? "left" : "right"} sx={{ padding: ' 16px', maxWidth: '200px' }} component="td" scope="row">
          <div style={{ display: 'flex', alignItems: 'center', opacity: user?.status === 'inactive' ? '0.6' : '1' }}>
            <PersonAvatar textSize={16} size={35} email={user.email}
              profileImg={user?.profile?.profileImg}
              lastName={user?.profile?.lastName}
              firstName={user?.profile?.firstName} />

            <span style={{
              fontSize: '14px',
              fontWeight: '600',
              marginInlineStart: '10px',
              maxWidth: '220px',
              lineHeight: '19px',
              color: '#3BABEB',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontFamily: '"Inter", "Assistant", sans-serif',


            }} >{userDisplayName(user?.profile) ?? user.email}</span>

          </div>

        </TableCell>
        <TableCell align={direction === "ltr" ? "left" : "right"} >
          <span style={{
            fontFamily: '"Inter", "Assistant", sans-serif',
          }}>{user?.email}</span>
        </TableCell>

        <TableCell align={direction === "ltr" ? "left" : "right"} >
          <SelectUserRole
          disabled={readOnly}
            onChange={(role) => {
              changeUserRole(role, user)
            }}
            user={user} />
        </TableCell>
        <TableCell align={direction === "ltr" ? "left" : "right"} >
          {user.status === 'pending' ? (<span style={{
            fontSize: '14px', paddingLeft: '14px',
            color: 'var(--secondary-text-color)',
            fontFamily: '"Inter", "Assistant", sans-serif',
          }}>{t("workspaceUsersMembers.statusPending")}</span>) : (<FormControl fullWidth>
            <Select
              disabled={(user.status === 'pending' || user?.role === 'owner') ?? true}
              sx={{
                maxWidth: '120px',
                borderRadius: '8px',
                justify: 'space-between',
                color: 'var(--secondary-text-color)',
                maxHeight: '36px',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontFamily: '"Inter", "Assistant", sans-serif',
                "& .MuiSelect-icon": {
                  color: 'var(--secondary-text-color)',
                }
              }}
              id=""
              value={user.status}
              onChange={(event) => changeUserStatus(event, user)}>
              {ValidManualStatusList.map((status, index) => <MenuItem key={index} selected={user.status === status}
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '19px',
                  color: 'var(--primary)',

                }} value={status}>{status}
              </MenuItem>)}

            </Select>
          </FormControl>)}
        </TableCell>
        <TableCell>
          {user?.role !== 'owner' &&
            <IconButton  disabled={readOnly} onClick={() => setDeletePromptOpen(true)}>
              <DeleteIcon />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    </>
  );

  return loading ? buildLoader() : buildRow();
};
