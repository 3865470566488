import React, { useMemo } from 'react'
import { useMatch } from 'react-router-dom';
import FormPreview from './form-preview'

type Props = {}

export default function FormPreviwView({}: Props) {
    
  const match = useMatch('/form-preview/:shareId')
  const shareId = useMemo(() => match?.params?.shareId, [match]);
  return (
    <FormPreview shareId={shareId}/>
  )
}