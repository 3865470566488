import BTSlide from '@common-components/bt-slider/bt-slider';
import { ViewWeek } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DBWorkloadThunks, isTempId, workloadSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";

import { isWorkloadComplexityPickerExist, isWorkloadPersonPickerExist, selectColumnListByType } from '@common-reducers/TaskSelectors';
import { openPickerThunk } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSettings } from 'react-icons/md';
import GeneralScreenWithButtonAndText from '../general-screen-with-button-and-text';
import BTWorkload from './blueticks-workload';
import classes from './bt-workload.module.scss';
import WorkloadSettingsDrawer from './workload-settings-drawer';

type Props = {
  boardId: string;
  workloadId: string;
}

export default function WorkloadView({ boardId, workloadId }: Props) {
  const { t } = useTranslation(namespace);
  const isComplexityPickerExist = useAppSelector((state) => isWorkloadComplexityPickerExist(state, workloadId))
  const isPersonPickerExist = useAppSelector((state) => isWorkloadPersonPickerExist(state, workloadId))

  const [_complexityPickerColumn] = useAppSelector(state => selectColumnListByType(state, boardId, 'complexity-picker'))

  const [_personPickerColumn] = useAppSelector(state => selectColumnListByType(state, boardId, 'person-picker'))

  const complexityPickerId = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
    _id: workloadId
  }, 'complexityPickerId'))

  const personPickerId = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
    _id: workloadId
  }, 'personPickerId'))

  const dispatch = useAppDispatch()
  const [isSettings, setIsSettings] = useState(false)

  
  useEffect(() => {
    if (complexityPickerId === undefined) {
      if (_complexityPickerColumn?._id !== undefined && !isTempId(_complexityPickerColumn?._id)) {
        dispatch(
          DBWorkloadThunks.patch({
            entity: {
              _id: workloadId,
              complexityPickerId: _complexityPickerColumn?._id.toString(),
            }
          })
        )
      }
    }

  }, [_complexityPickerColumn?._id, complexityPickerId, dispatch, workloadId])

  useEffect(() => {
    if (personPickerId === undefined) {
      if (_personPickerColumn?._id !== undefined && !isTempId(_personPickerColumn?._id)) {
        dispatch(
          DBWorkloadThunks.patch({
            entity: {
              _id: workloadId,
              personPickerId: _personPickerColumn?._id.toString(),
            }
          })
        )
      }
    }

  }, [_personPickerColumn?._id, dispatch, personPickerId, workloadId])

  const handleSettingsClick = () => {
    setIsSettings(!isSettings);
  }

  const openColumnsPicker = ({
    anchorId,
    pickerType
  }) => {
    dispatch(openPickerThunk({
      anchorId,
      pickerType,
    }))

  }

  return (
    <Stack direction='column' sx={{
      position: 'relative',
      overflow: 'hidden',
    }}>
      <Stack direction='row' className={classes.workload_view_header}>
        <Button
          id={`workload${workloadId}`}
          key={`workload${workloadId}`}
          onClick={(e) => {
            e.stopPropagation()
            openColumnsPicker({
              anchorId: `workload${workloadId}`,
              pickerType: 'workload-included-columns-dialog',
            })
          }
          }
          startIcon={<ViewWeek style={{ width: 16, height: 16 }} />}
          className={classes.header_button}
        >
          {t('workloadView.columnsButton')}
        </Button>
        <Button
          onClick={handleSettingsClick}
          className={classes.header_button}
          startIcon={<MdSettings />}
          value="settings" >
          {t('workloadView.settingsButton')}</Button>
      </Stack>
      <BTSlide open={isSettings}>
        <div className={classes.settings_drawer_container}>
          <WorkloadSettingsDrawer workloadId={workloadId} boardId={boardId} />
        </div>
      </BTSlide>
      <Box sx={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 156px)',
        overflow: 'auto',
      }}>

        {(isComplexityPickerExist && isPersonPickerExist) &&
          <BTWorkload boardId={boardId} workloadId={workloadId} />
        }

        {(!isComplexityPickerExist && !isPersonPickerExist) &&
          <GeneralScreenWithButtonAndText
            height="calc(100vh - 260px)"
            target='personAndComplexity'
            textValue={t('workloadView.complexityAndPersonRequired')} />
        }

        {(!isComplexityPickerExist) &&
          <GeneralScreenWithButtonAndText
            height="calc(100vh - 260px)"
            target="complexity"
            textValue={t('workloadView.complexityRequired')} />
        }

        {(!isPersonPickerExist) &&
          <GeneralScreenWithButtonAndText
            height="calc(100vh - 260px)"
            target="person"
            textValue={t('workloadView.personRequired')} />
        }


      </Box>
    </Stack>
  )
}