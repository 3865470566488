import PropTypes from "prop-types";
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import ResizeObserver from "resize-observer-polyfill";

export interface UseObserverOptions {
  callback: ResizeObserverCallback;
  element: RefObject<HTMLDivElement>;
}

const useObserver = ({ callback, element }) => {
  const current = useMemo(() => element?.current, [element]);

  const observer = useRef(null);

  const observe = useCallback(() => {
    if (element && element.current && observer.current) {
      observer.current.observe(element.current);
    }
  }, [element]);

  useEffect(() => {
    // if we are already observing old element
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }
    const resizeObserverOrPolyfill = ResizeObserver;
    observer.current = new resizeObserverOrPolyfill(callback);
    observe();

    return () => {
      if (observer && observer.current && current) {
        observer.current.unobserve(current);
      }
    };
  }, [callback, current, element, observe]);
};

useObserver.propTypes = {
  element: PropTypes.object,
  callback: PropTypes.func,
};

export default useObserver;
