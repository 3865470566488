import { Menu, Radio } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectDatePickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect, useState } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import { setCurrentTrigger } from "../automations-helper";
import PickerColumn from "../dropdowns/column-dropdown";
import HourDropdown from "./hour-drop-down";
import SetTimePeriodForDateTrigger from "./set-time-period-for-date-trigger";

const WhenDateArrivesTrigger = () => {
    const dispatch = useAppDispatch()
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectDatePickerColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dateTrigger, setDateTrigger] = React.useState<string>('When');
    const [columnName, setColumnName] = React.useState<string>('Date');
    const [selectedValue, setSelectedValue] = React.useState("optionA");
    const [selectedHour1, setHourSelected1] = React.useState(triggerObj.nextTrigger);
    const [selectedHour2, setHourSelected2] = React.useState(triggerObj.nextTrigger);
    const [hourAnchorEl, setHourAnchorEl] = useState<null | HTMLElement>(null);

   


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColumnChange = (id, name) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, {columnId: id,isCron: true})));
        setColumnName(name);
        handleClose();
    };

    const handleHourSelected = (selectedHour) => {
        let triggerDetails;
        const nextTrigger = getNextDayAtMidnightUTC(selectedHour);
        const nextTriggerUTC = nextTrigger.toISOString();

        if (selectedValue === "optionA") {
            triggerDetails = setCurrentTrigger(triggerObj, {isCron: true, nextTrigger: nextTriggerUTC, cronDateArrivesOption: selectedValue})
            setHourSelected1(nextTriggerUTC)
        } else {
            triggerDetails = setCurrentTrigger(triggerObj,{isCron: true, nextTrigger: nextTriggerUTC, cronDateArrivesOption: selectedValue})
            setHourSelected2(nextTriggerUTC)
        }

        dispatch(
            automation.setTrigger(triggerDetails),
        );

        handleClose();
    };

    const handlecronNumberOfPeriodChange = (number) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, {cronNumberOfPeriod: number})));
        handleClose();
    };


    const handleTimePeriodChange = (timePeriod) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, {cronTimePeriod: timePeriod})));
        handleClose();
    };

    const handlecronBeforeOrAfterSelected = (cronBeforeOrAfter) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { cronBeforeOrAfter: cronBeforeOrAfter })));
        handleClose();
    };
    function getNextDayAtMidnightUTC(selectedHour) {
        // Parse the hour and period from the selectedHour string
        const hour = parseInt(selectedHour.slice(0, -2));
        const period = selectedHour.slice(-2);

        // Convert the hour to 24-hour format
        let hour24Format = period === "AM" ?
            hour :
            (hour % 12) + 12;

        // Create a Date object representing the current date and time
        const now = new Date();

        // Convert the local hour to UTC
        const localOffsetHours = now.getTimezoneOffset() / 60;
        hour24Format = (hour24Format + localOffsetHours) % 24;

        // Use Date.UTC to create a Date object representing the next day at the specified hour, in UTC
        const nextDayUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hour24Format, 0, 0, 0);

        return new Date(nextDayUTC);
    }

    const handleHourClick = (event) => {
        setHourAnchorEl(event.currentTarget);
        if (!triggerObj?.nextTrigger && !triggerObj.cronDateArrivesOption) {
            const triggerDetails = { ...triggerObj, nextTrigger: getNextDayAtMidnightUTC("10 AM"), cronDateArrivesOption: "optionA" }
            dispatch(
                automation.setTrigger(triggerDetails),
            );
        }
    };

    const handleHourClose = () => {
        setHourAnchorEl(null);
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        let triggerDetails = { ...triggerObj }
        triggerDetails = { ...triggerObj, cronDateArrivesOption: event.target.value }

        if (event.target.value === "optionB" && !triggerObj.cronNumberOfPeriod && !triggerObj.cronTimePeriod && !triggerObj.cronBeforeOrAfter) {
            triggerDetails = { ...triggerObj, cronDateArrivesOption: event.target.value, cronNumberOfPeriod: 1, cronTimePeriod: 'days', cronBeforeOrAfter: "before" }
        }
        dispatch(
            automation.setTrigger(triggerDetails),
        );
    };

    useEffect(() => {
        const selectedColumnId = triggerObj.columnId;
        const column = filteredColumns.find(item => item.id === selectedColumnId);
        setColumnName(column?.name ?? "Date");
        setSelectedValue(triggerObj.cronDateArrivesOption ?? "optionA");

        dispatch(automation.fieldValidityChanged({ component: 'WhenDateArrivesTrigger', index: 0, field: 'columnId', isValid: !!selectedColumnId }));


        dispatch(automation.fieldValidityChanged({ component: 'WhenDateArrivesTrigger', index: 0, field: 'nextTrigger', isValid: !!triggerObj.nextTrigger }));

        setDateTrigger(selectedValue === "optionA" ? "When" :
            `${triggerObj.cronNumberOfPeriod} ${triggerObj.cronNumberOfPeriod === 1 ? triggerObj.cronTimePeriod.slice(0, -1) : triggerObj.cronTimePeriod} ${triggerObj.cronBeforeOrAfter} `);
    }, [triggerObj, filteredColumns, dispatch, selectedValue]);

    return (
        <Stack>
            <Stack sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    <AutomationButton
                        componentName='WhenDateArrivesTrigger'
                        index={0}
                        option='nextTrigger'
                        label={dateTrigger}
                        handleClick={handleHourClick}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                    <Menu
                        id="hour-menu"
                        anchorEl={hourAnchorEl}
                        keepMounted
                        open={Boolean(hourAnchorEl)}
                        onClose={handleHourClose}
                        PaperProps={{
                            style: {
                                width: '600px',
                                padding: 10
                            }
                        }}
                    >
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems={"center"} sx={{ p: 2 }}>

                                <Radio sx={{ p: 0 }}
                                    checked={selectedValue === 'optionA'}
                                    onChange={handleChange}
                                    value="optionA"
                                    name="radio-buttons"
                                />
                                <Box px={2}> When date arrives at</Box>

                                <HourDropdown handleHourSelected={handleHourSelected} selectedHour={selectedHour1} disabled={selectedValue === 'optionB'} />

                            </Stack>

                            <Stack direction="row" alignItems={"center"} sx={{ p: 2 }}>
                                <Radio sx={{ p: 0 }}
                                    checked={selectedValue === 'optionB'}
                                    onChange={handleChange}
                                    value="optionB"
                                    name="radio-buttons"

                                />

                                <SetTimePeriodForDateTrigger handleNumberChange={handlecronNumberOfPeriodChange} handleTimePeriodChange={handleTimePeriodChange} handlecronBeforeOrAfter={handlecronBeforeOrAfterSelected} handleHourSelected={handleHourSelected} cronNumberOfPeriod={triggerObj.cronNumberOfPeriod} timePeriod={triggerObj.cronTimePeriod} cronBeforeOrAfter={triggerObj.cronBeforeOrAfter} selectedHour={selectedHour2} disabled={selectedValue === 'optionA'} />

                            </Stack>
                        </Stack>

                    </Menu>
                    <AutomationButton
                        componentName='WhenDateArrivesTrigger'
                        index={0}
                        option='columnId'
                        label={columnName}
                        handleClick={handleClick}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> arrives
                </Box>
                <AddAndRemove elements={conditions} componentName="WhenDateArrivesTrigger" index={0} type={"trigger"} />
            </Stack>

            <PickerColumn
                anchorEl={anchorEl}
                handleClose={handleClose}
                filteredColumns={filteredColumns}
                handleColumnChange={handleColumnChange}
            />
        </Stack>
    )
}


export default WhenDateArrivesTrigger;
