import NewTaskRowInput from "@common-components/tasks/new-task-row/new-task-row-input";
import { CustomColumnData, Task } from "@common-models/task";
import { TaskColumnOption } from "@common-models/task-column-option";
import { Box, Stack } from "@mui/system";
import { getCurrentBoardId } from "@common-reducers/BoardsSelectors";
import { kanbanSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { NullableId } from "@common/types/interface";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import KanbanTask from "./kanban-task";
import { getKanbanColumnTasksByOrder, getGroupIdForKanbanColumns, columnListSelectorForKanban } from "@common-reducers/TaskSelectors";
import classes from './kanban-column.module.scss';


type Props = {
  snapshot?: DraggableStateSnapshot,
  provided?: DraggableProvided;
  option: TaskColumnOption;
  kanbanId: NullableId;
  setKanbanCard: (event, taskId) => void;

};

export default function KanbanColumn({
  snapshot,
  provided,
  option,
  kanbanId,
  setKanbanCard,
}: Props) {
  const optionColumnId = useAppSelector((state) => kanbanSelectOneFieldByQuery(state.DBKanbanReducer, { _id: kanbanId }, 'optionColumnId'));
  const tasksListOnlyIds: Task[] = useAppSelector((state) => getKanbanColumnTasksByOrder(state, kanbanId, optionColumnId, option._id, option.isDefaultLabel));
  const groupId = useAppSelector((state) => getGroupIdForKanbanColumns(state))
  const columnsForDisplay = useAppSelector((state) => columnListSelectorForKanban(state, kanbanId));
  const boardId: string = useAppSelector(getCurrentBoardId);
  // const taskViewContainerOffset = useAppSelector(state => state.TasksReducer.taskViewContainerOffset)
  // const [, translateXValue, , translateYValue] = provided.draggableProps.style.transform?.match(/(-?\d+)|\w+/g) ?? [];
  // const translateX = parseInt(translateXValue, 10) - taskViewContainerOffset.left;
  // const translateY = parseInt(translateYValue, 10) - taskViewContainerOffset.top;
  // const styleWithOffset = {
  //   ...provided.draggableProps.style,
  //   transform: snapshot.isDragging ? `translate(${translateX}px, ${translateY}px)` : '',
  // };

  const customData: Record<string, CustomColumnData> = {
    [optionColumnId]: {
      value: option._id,
      order: 1000,
    },
  };

  return (
    <Stack
      {...provided?.draggableProps}
      // style={styleWithOffset}
      ref={provided?.innerRef}
      direction="column"
      flex="0 0 260px"
      justifyContent={"flex-start"}
      sx={{
        width: "260px",
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      {/* This box is for the title (label name) */}
      <Box
        sx={{
          backgroundColor: `${option.bgcolor}`,
          color: "white",
          paddingTop: "8px",
          paddingBottom: "8px",
          textAlign: "center",
          fontSize: "15px",
          cursor: "pointer",
          fontFamily: "'Poppins', 'Assistant', sans-serif",
          fontWeight: 600,
          height: 40,
          flexShrink: 0,
          boxSizing: "border-box",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
        {...provided?.dragHandleProps}

      >
        {option.label === "" ? `No Label` : option.label}
        {
          <span
            style={{
              alignItems: "center",
              backgroundColor: "var(--background-default)",
              border: 0,
              borderRadius: 5,
              display: "inline-flex",
              fontSize: "12px",
              height: "18px",
              justifyContent: "center",
              marginInlineStart: "8px",
              padding: "0 6px",
              color: "var(--secondary-text-color)",
              fontWeight: 400,
            }}
          >
            {tasksListOnlyIds.length}
          </span>
        }
      </Box>
      <Droppable droppableId={`option_list_${option._id}`} type="task">
        {(provided, snapshot) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              backgroundColor: option.bgcolor,
              // height: "100%",
              borderLeft: `4px solid ${option.bgcolor}`,
              borderBottomRightRadius: "14px",
              borderBottomLeftRadius: "14px",
            }}
          >
            <div
              className={classes.kanbanScroller}
              style={{
                background: "var(--background-default)",
                width: "100%",
                height: "calc(100vh - 350px)",
                overflowY: 'auto',
                //overflowX: 'hidden',
                padding: 6,
                boxSizing: "border-box",
                borderRadius: "12px 0 12px 12px",
                position: "relative",
              }}
            >
              {/* pale background */}
              <div
                {...provided.droppableProps}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? "var(--drag-over-bgcolor)"
                    : "var(--tasks-header-background)",
                  position: "absolute",
                  inset: 0,
                  // backgroundColor: "var(--tasks-header-background)",
                  borderRadius: "12px 0 12px 12px",
                }}
              ></div>
              {tasksListOnlyIds.map((task: Task, taskIndex) => {
                return (
                  <Draggable
                    key={`task_${task._id}`}
                    draggableId={`task_${task._id}`}
                    index={taskIndex}
                  >
                    {(provided, snapshot) => (
                      <KanbanTask
                        taskId={task._id}
                        snapshot={snapshot}
                        provided={provided}
                        setKanbanCard={setKanbanCard}
                        columnId={optionColumnId}
                        _columnsForDisplay={columnsForDisplay}
                      />
                    )
                    }
                  </Draggable>
                );
              })}
              {provided.placeholder}
              <div
                style={{
                  position: "relative",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <NewTaskRowInput
                  boardId={boardId}
                  groupId={groupId}
                  customData={customData}
                />
              </div>
            </div>
          </Box>
        )}
      </Droppable>
    </Stack >
  );
}
