import BTButton from '@common-components/bt-button/bt-button'
import { getCurrentBoard } from '@common-reducers/BoardsSelectors'
import { addNewColumnThunk } from '@common-reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { namespace } from '@common/config/constants'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'

export default function NewComplexityColumnButton(type) {
    const { t } = useTranslation(namespace);
    const currentBoard = useAppSelector(getCurrentBoard)
    const dispatch = useAppDispatch()

    function newComplexityColumn(type, title) {
        dispatch(addNewColumnThunk({
            type,
            title,
            boardId: currentBoard._id,
        }))
    }

    return (
        <>
                <BTButton sx={{
                    width: '100%',
                    maxWidth: "250px",
                    margin: '8px'
                    }} onClick={() => newComplexityColumn('complexity-picker', 'Complexity')} startIcon={<MdAdd />} >
                    {t('newComplexityColumnButton.addColumn')}
                </BTButton>
        </>

    );
}