import {
    parseOldUnits,
    toRuleSet
} from "@common-components/schedule-picker/recurrence-utils";
import { UserMessage } from "@common-models/user-message";
import { WASession } from "@common-models/wasession";
import { createSelector } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import { defaultEventcolor } from "@common-views/calendar/message-color";
import moment from "moment";
import { RRule, RRuleSet } from "rrule";
import { sessionSelectOneObjectByQuery, userMessagesSelectByQuery, waContactSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { selectCurrentUserSession, selectUserId } from "./UserSelectors";



export const selectWhatsappContact = createSelector(
    [
        (state) => state,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (state: CommonRootState, whatsappId: string) => {
        return state.WhatsAppReducer.contactList.find(contact => contact.id._serialized === whatsappId)
    }
);

export const hasActiveSocketSession = createSelector(
    [
        (state) => state,
        (state) => selectUserId(state),
    ],
    (state: CommonRootState, userId) => {
        const session = sessionSelectOneObjectByQuery(state.DBSessionReducer, {
            status: { $in: ["connected"] },
            owner: userId
        });
        return !!session;
    }
);


export const getIsContactChecked = createSelector(
    [
        (state, wid) => state,
        (state, wid) => wid,
    ],
    (state, wid) => {
        const importedContactsArray = state.WhatsAppReducer.importedContactsArray

        const isChecked = importedContactsArray?.filter(contact => contact?.whatsappId === wid).length > 0

        return isChecked
    }
);

export const extractPhoneNumber = (id: string) => {
    if (typeof id !== 'string') return null;

    const match = id?.match(/^(\d+)/);
    return match ? match[1] : null;
};

export const calculatedName = (contact: any): string => {
    const _calcName = [contact?.firstName, contact?.lastName].filter(x => x).join(' ') || contact?.mobile;
    return contact?.name || contact?.displayName || contact?.shortName || _calcName
}

export const contactLabel = (contact) => {
    const phoneNumber = () => {
        if (typeof contact?.id === 'string' && (contact?.id?.includes('s.whatsapp.net') || contact?.id?.includes('c.us'))) {
            return `+${contact?.id?.split('@')[0]}`
        }
        else if (typeof contact?.id?._serialized === 'string' && (contact?.id?._serialized?.includes('s.whatsapp.net') || contact?.id?._serialized?.includes('c.us'))) {
            return `+${contact?.id?._serialized?.split('@')[0]}`
        }
        else {
            return ''
        }
    }
    return contact?.formattedTitle ?? calculatedName(contact) ?? contact?.waName ?? contact?.displayName ?? contact?.notifyName ?? contact?.notify ?? contact?.verifiedName ?? contact?.pushname ?? phoneNumber()
}

export const selectContactLabelByWhatsappId = createSelector(
    [
        (state) => state,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (state: CommonRootState, whatsappId: string) => {
        const contact = selectWhatsappContact(state, whatsappId);
        return contactLabel(contact);
    }
);


// Selector to select a whatsapp contact by id
export const selectWhatsappContactById = createSelector(
    [
        (state) => state.WhatsAppReducer.contactMap,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (contactMap, whatsappId: string) => {
        return contactMap[whatsappId];
    }
);

// Selector to select a whatsapp contact name by id
export const selectWhatsappContactName = createSelector(
    [
        selectWhatsappContactById,
    ],
    (contact) => {
        return calculatedName(contact);
    }
);

export const selectIsGroup = createSelector(
    [
        (whatsappId: string) => whatsappId,
    ],
    (whatsappId: string) => {
        return whatsappId?.includes && whatsappId?.includes('@g.us');
    }
)

// Selector to select a profile picture by id
export const selectProfilePic = createSelector(
    [
        (state) => state,
        (state) => state.WhatsAppReducer.profilePicMap,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (state, profilePicMap, whatsappId: string) => {

        const profilePicUrlFromProfilePicMap = profilePicMap[whatsappId];

        if (!profilePicUrlFromProfilePicMap) {

            const profilePicUrlFromRedisContacts = waContactSelectOneObjectByQuery(state.DBWaContactReducer, {
                'id._serialized': whatsappId,
            });

            return profilePicUrlFromRedisContacts?.imgUrl !== 'profilePicUnavailable' ? profilePicUrlFromRedisContacts?.imgUrl : undefined
        }
        else {
            return profilePicUrlFromProfilePicMap;
        }

    }
);


export const getShowConnectionProgressBarFlag = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const session: WASession = selectCurrentUserSession(state)


        const isSocketConnectedToWhatsApp = session?.active && session?.status === "connected";

        const isContactsExist = (state.DBWaContactReducer?.total ?? 0) > 0

        const isFinishedSaving = session?.isFinishSavingContacts


        // if (!isSocketConnectedToWhatsApp) {
        //     return false
        // } else {
        //     if (isFinishedSaving) {
        //         return false
        //     } else {
        //         if (!isContactsExist) {
        //             return true
        //         }
        //         else {
        //             return false
        //         }
        //     }
        // }
        /// optimize the conditional statements
        return isSocketConnectedToWhatsApp && !isFinishedSaving && !isContactsExist
    }
)

export const getIsSocketConnectedToWhatsApp = createSelector(
    [
        (state) => selectCurrentUserSession(state),
    ],
    (session: WASession) => {
        return session?.active && session?.status === "connected";
    }
)


export const getCalendarEvents = createSelector(
    [
        (state: CommonRootState, viewMonthStartStr) => state,
        (state: CommonRootState, viewMonthStartStr) => viewMonthStartStr,
    ],
    (state, viewMonthStartStr) => {
        let messages: UserMessage[] = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        })

        const rangeStart = moment
            .utc(moment(viewMonthStartStr).subtract(1, "week"))
            .toDate();
        const rangeEnd = moment
            .utc(moment(viewMonthStartStr).endOf("month").add(1, "week"))
            .toDate();

        let nonRecurring = messages.filter(
            (message) =>
                !message.isRecurring &&
                moment(message.dueDate).isBetween(rangeStart, rangeEnd)
        );

        let recurring: UserMessage[] = [];

        messages
            .filter((message) => message.isRecurring)
            .forEach((m) => {
                let originalRuleset: RRuleSet = m.rruleset
                    ? toRuleSet(m.rruleset) // Make sure toRuleSet parses the string into an RRuleSet
                    : parseOldUnits(m.recurenceUnit, m.recurrenceQuantity); // Assume this returns an RRuleSet

                // Use moment.js to parse m.dueDate and convert it to a Date object, ensuring UTC
                let correctDtStart = moment.utc(m.dueDate).toDate();

                // Assuming you have a way to reconstruct or access the rules (and exdates if any) from the original RRuleSet:
                // Create a new RRuleSet
                let ruleset = new RRuleSet();

                // For each original rule, re-create it with the correct dtstart
                originalRuleset.rrules().forEach(rule => {
                    let newRuleOptions = rule.origOptions;
                    newRuleOptions.dtstart = correctDtStart;

                    // Create a new rule with corrected options and add to the new ruleset
                    ruleset.rrule(new RRule(newRuleOptions));
                });

                // If there are exclusion dates, add them to the new ruleset as well
                originalRuleset.exdates().forEach(exdate => {
                    ruleset.exdate(exdate);
                });

                // Get excluded dates from the ruleset
                const excludedDates = ruleset.exdates().map(ed => moment(ed).startOf('minute').valueOf());
                const _excludedDates = ruleset.exdates()
                // Get in-range dates that are not excluded
                const inRangeDates = originalRuleset.between(rangeStart, rangeEnd)
                    .filter((date) => {
                        // Compare dates down to the minute for exclusion
                        console.log(_excludedDates)
                        // const a = ruleset.between(rangeStart, rangeEnd)
                        const isDateBeforeRuleset = moment(date).isBefore(correctDtStart)
                        const dateValue = moment(date).startOf('minute').valueOf();
                        return !excludedDates.includes(dateValue) && !isDateBeforeRuleset
                    });

                // Create message objects for each in-range date that is not excluded
                const rec = inRangeDates.map((d) => {
                    const due = moment(d).startOf("minute").toISOString();
                    return { ...m, dueDate: due } as UserMessage;
                });

                // Add the non-excluded recurring messages to the array
                recurring = [...recurring, ...rec];
            });

        // colors
        nonRecurring = nonRecurring.map((v) => ({
            ...v,
            messageColor: v.messageColor || defaultEventcolor,
        }));

        recurring = recurring.map((v) => {
            if (v.calendarColors) {
                const { defaultColor, ranges, specific } = v.calendarColors;

                const specificColor = specific.find((s) => s.messageTime === v.dueDate);
                if (specificColor) return { ...v, messageColor: specificColor.color };

                const sorted = [...ranges]?.sort((a, b) =>
                    moment(a.fromTime).isBefore(moment(b.fromTime)) ? 1 : -1
                );
                const rangeColor = sorted?.find((s) =>
                    moment(s.fromTime).isSameOrBefore(moment(v.dueDate))
                );
                if (rangeColor) return { ...v, messageColor: rangeColor.color };

                if (defaultColor) return { ...v, messageColor: defaultColor };
            }
            return { ...v, messageColor: defaultEventcolor };
        });

        const events = [...nonRecurring, ...recurring];

        return events;

    }
)


export const shouldGetMoreContactsSelector = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const isSockectConnected = getIsSocketConnectedToWhatsApp(state)
        const totalContacts = state.DBWaContactReducer.total

        return !(!isSockectConnected && totalContacts === 0)
    }
)