import BTButton from '@common-components/bt-button/bt-button'
import { Stack } from '@mui/system'
import { getCurrentBoard } from '@common-reducers/BoardsSelectors'
import { addNewColumnThunk } from '@common-reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { MdAdd } from 'react-icons/md'

export default function NewPriorityColumnButton(type) {
    const currentBoard = useAppSelector(getCurrentBoard)
    const dispatch = useAppDispatch()

    function newPickerColumn(type, title) {
        dispatch(addNewColumnThunk({
            type: type,
            title: title,
            boardId: currentBoard._id,
        }))
    }

    return (
        <>
            <Stack direction='column'>
                <BTButton sx={{
                    width: '100%',
                    maxWidth: "250px",
                    margin: '8px'
                    }} onClick={() => newPickerColumn('option-picker', 'New Column')} startIcon={<MdAdd />} >
                    Add Priority Column
                </BTButton>
                <BTButton sx={{
                    width: '100%',
                    maxWidth: "250px",
                    margin: '8px'
                    }} onClick={() => newPickerColumn('status-option-picker', 'Status')} startIcon={<MdAdd />} >
                    Add Status Column
                </BTButton>
            </Stack>
        </>

    );
}