import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box'
import { CellProps } from '@common-components/tasks/columns-cells/cell-interface'
import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper'
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs'
import { Task } from '@common-models/task'
import { TaskColumn } from '@common-models/task-column'
import { NullableId } from '@common/types/interface';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors'
import { DBFormThunks, formSelectOneFieldByQuery, isTempId, taskColumnSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks"
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { namespace } from '@common/config/constants'
import { TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { createElement } from 'react'
import { useTranslation } from 'react-i18next'
import classes from "./form.module.scss"



type Props = {
    isFormInEditMode: boolean,
    columnId: NullableId | string;
    formId: string;
    submitFail?: boolean;
    updateTask?: (value: any, columnId: any) => void;
    newTask?: Task;
}

export default function FormColumnCell({
    isFormInEditMode,
    columnId,
    formId,
    submitFail,
    updateTask,
    newTask,
}: Props) {
    const { t } = useTranslation(namespace);
    const boardId = useAppSelector(state => getSelectedBoardId(state))
    let column = useAppSelector((state) => taskColumnSelectOneObjectByQuery(state.DBTaskColumnReducer, { _id: columnId }, ['_id', 'type', 'title']))
    column = column ??
        {
            _id: 'name',
            type: 'row-prefix',
            title: 'Task Name',
        } as TaskColumn


    const columnData = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'columnData')) ?? {}
    const dispatch = useAppDispatch()



    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        fontSize: "15px",
        borderRadius: "8px",
        border: "1px solid #53bdeb",
        fontFamily: "inherit",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "3px 6px",
    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "15px",
        border: "1px solid transparent",
        borderRadius: "8px",
        "&:hover": {
            border: "1px dashed var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "4px 6px",
        width: "100%",
        fontFamily: "inherit",
    };


    const columnTitleUpdate = (value, column) => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    columnData: {
                        ...columnData,
                        [column._id]: {
                            ...columnData[column._id],
                            title: value,
                        }
                    }
                }
            }))
        }

    }

    const columnDescriptionUpdate = (value, column) => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    columnData: {
                        ...columnData,
                        [column._id]: {
                            ...columnData[column._id],
                            description: value,
                        }
                    }
                }
            }))
        }

    }


    const ColumnTitleAndDescription = ((column: TaskColumn) => {
        if (column) {
            const cell = columnTypeList.find(columnType => columnType.type === column.type)

            let title = (column.title ?? cell?.title)
            if (columnData[column._id] && columnData[column._id].title !== undefined && columnData[column._id].title !== '') {
                title = columnData[column._id].title
            }

            let description = ''
            if (columnData[column._id]) {
                description = columnData[column._id].description ?? ''
            }
            if (isFormInEditMode && description === "") {
                description = t('formColumnCell.addColumnDescription');
            }

            const css = {
                padding: "0 6px",
                height: 32,
                boxSizing: 'border-box',
            }
            const titleCss = {
                fontSize: 15,
                fontWeight: 600,
            };
            const descriptionCss = {
                fontSize: 14,
                fontWeight: 400,
            };


            return (
                <>
                    {isFormInEditMode &&
                        <Stack direction='column' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            gap: '4px'
                        }}>
                            <BtEditableBox
                                inputSx={{ ...inputTheme, ...css, ...titleCss }}
                                boxSx={{ ...textTheme, ...css, ...titleCss }}
                                text={title}
                                onValueUpdate={(value) => {
                                    columnTitleUpdate(value, column)
                                }}
                            />
                            <BtEditableBox
                                inputSx={{ ...inputTheme, ...css, ...descriptionCss }}
                                boxSx={{ ...textTheme, ...css, ...descriptionCss }}
                                text={description}
                                onValueUpdate={(value) => {
                                    columnDescriptionUpdate(value, column)
                                }}
                            />
                        </Stack>
                    }
                    {
                        !isFormInEditMode && columnData[column._id] && columnData[column._id].required === true &&

                        <span style={{ display: "inline-block" }}>
                            {submitFail ?
                                <>
                                    <span style={{ color: "red" }}>{title} &nbsp;*{t('formColumnCell.required')}</span><br></br>

                                </> :
                                <>
                                    <span>{title}</span>
                                    <span style={{ color: "red" }}>&nbsp;*</span><br></br>
                                </>}
                            {description}
                        </span>
                    }
                    {
                        !isFormInEditMode && (
                            !columnData[column._id] ||
                            (columnData[column._id] && (columnData[column._id].required === undefined || columnData[column._id].required === false))) &&

                        <span style={{ display: "inline-block" }}>
                            <span style={{ marginInlineStart: '7px', }}>{title}</span><br></br>
                            <span style={{ marginInlineStart: '7px', }}>{description}</span>
                        </span>
                    }
                </>
            )
        }
    })


    const FormColumnCell = ((column) => {
        if (column) {
            if (column?._id === 'name' || column.title === 'Task Name') {
                return (<TextField
                    style={{ width: '260px', }}
                    disabled={isFormInEditMode}
                    id="outlined-helperText"
                    placeholder=""
                    onChange={(value) => updateTask(value, column._id)}
                />)
            }
            else {
                const cell = columnTypeList.find(columnType => columnType.type === column.type)
                return (
                    <>
                        {!isFormInEditMode &&
                            createElement(cell.formComponentFill, {
                                cellValue: newTask?.customData && newTask?.customData[column._id] && newTask?.customData[column._id].value,
                                groupId: newTask.groupId,
                                columnId: column?._id,
                                boardId,
                                onUpdate: (value) => updateTask(value, column._id),
                            } as CellProps)
                        }


                        {
                            isFormInEditMode &&
                            <CellWrapper
                                columnId={column?._id}
                                taskId='R3rcuceiLS5s4Mqe'
                                // groupId={task?.groupId}
                                boardId={boardId}
                                cellComponent={cell.formComponentEdit}
                                isFormInEditMode={true} />
                        }
                    </>
                )

            }
        }
    })





    return (
        <div>
            {ColumnTitleAndDescription(column)}
            <div className={classes.form_column_cell}>
                {FormColumnCell(column)}
            </div>
        </div>
    )
}