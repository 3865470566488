import { BlueticksWorkspaceUser } from '@common-models/workspace-user';
import { FormControl, MenuItem, Select } from '@mui/material';

const roleOptions = [{
    title: 'Guest',
    value: 'guest',
}, {
    title: 'Member',
    value: 'member',
}];

const ownerOptions = [{
    title: 'Owner',
    value: 'owner',
}];

type Props = {
    user: BlueticksWorkspaceUser;
    disabled?: boolean;
    onChange: (value: string) => void;
}

export default function SelectUserRole({
    user,
    disabled,
    onChange
}: Props) {
    return (
        <FormControl >
            {!disabled && <Select
                sx={{
                    pointerEvents: user?.role === 'owner' ? "none" : "all",
                    maxWidth: '120px',
                    minWidth: '102.83px',
                    borderRadius: '8px',
                    justifyContent: 'space-between',
                    height: '36px',
                    fontFamily: "'Inter', 'Poppins', 'Assistant', sans-serif",
                    fontWeight: '400',
                    color: 'var(--secondary-text-color)',
                    fontSize: 14,
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid rgba(0 0 0 / 10%)',
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid rgba(0 0 0 / 20%)',
                    },
                    "&.Mui-disabled": {
                        color: 'var(--secondary-text-color)',
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.1)",
                            webkitTextFillColor: "rgba(0, 0, 0, 0.1)",
                        }
                    },
                    "& .MuiSelect-icon": {
                        color: 'var(--secondary-text-color)',
                    }

                }}

                value={user?.role ?? ''}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
            >
                {(user?.role === 'owner' ? ownerOptions : roleOptions).map((option, index) =>
                    <MenuItem
                        selected={true}
                        key={index}
                        sx={{
                            color: 'var(--primary)',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '19px',

                        }} value={option.value}> {option.title}
                    </MenuItem>
                )}
            </Select>}
            {disabled && <span style={{ textTransform: "capitalize", color: '#013750!important', }}>{user?.role}</span>}
        </FormControl>
    )
}