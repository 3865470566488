import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function NumberInput({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Number', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.number ?? ''}
                type='number'
                onChange={(newValue) => {
                    if (/^\d*\.?\d*$/.test(newValue)) {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev.advanced,
                                number: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `Specifies the number of responses to generate. The generated responses can be found in the module's output under "Choices." The default value is set to 1`,
                {}
            )}
        </>
    );
}
