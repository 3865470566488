import { AccountPage } from "@common-components/account-page/account-page";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { Workspace } from "@common-models/workspace";
import { DBWorkspaceThunks, workspaceSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import { useRouter } from "@common-reducers/hooks/use-router";

import { Box, Button, CircularProgress, Stack, Tooltip } from "@mui/material";
import { switchWorkspace } from "@common-reducers/UserThunks";
import { selectCurrentWorkspace, selectIsWorkspaceChanging } from "@common-reducers/UserSelectors";
import newWorkspace from "@common-assets/icons/newWorkspace.png";
import { SideBarItem } from "@common-components/sidebar/sidebar";
import { User } from "@common/models/user";
import classes from "../account-view/account-view.module.scss";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";
import WorkspaceDropDown from "@common-components/workspace-dropdown/workspace-dropdown";
import DeletedWorkspacesModal from "./deleted-workspaces-modal";
export interface AccountWorkspacesViewProps {
  readOnly?: boolean;
 }
export interface AccountWorkspacesViewState {
  user?: User | null | undefined;
  loading: boolean;
}

const AccountWorkspacesView = ({readOnly = false}) => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const userWorkspaces: Workspace[] = useAppSelector((state) =>
    workspaceSelectByQuery(state.DBWorkspaceReducer, { deleted: { $ne: true }})
  );
  const [isDeletedWorkspacesModalOpen, setDeletedWorkspacesModalOpen] = useState(false);
  const openDeletedWorkspacesModal = () => {
    setDeletedWorkspacesModalOpen(true);
  };

  const closeDeletedWorkspacesModal = () => {
    setDeletedWorkspacesModalOpen(false);
  };


  const userId = useAppSelector((state) => state.UserReducer.userId);
  const userCurrentWorkspace = useAppSelector(selectCurrentWorkspace);
  const isWorkspaceChanging = useAppSelector(selectIsWorkspaceChanging);
  const triggerSwitchWorkspace = async (ws: Workspace) => {
    if (userCurrentWorkspace !== ws?._id) {
      console.log('Switching workspace');
     await  dispatch(switchWorkspace({ workspaceId: ws?._id.toString() }));
      getWorkspaces()
    }
  }

  const createNewWorkspace = useCallback(() => {
    router.navigate('/new-workspace');
  }, [router])

  const editWorkspace = useCallback((wsId: string) => {
    router.navigate(`/edit-workspace/?ws=${wsId}`);
  }, [router])

  const getWorkspaces = useCallback(async () => {
    const res = await dispatch(
      DBWorkspaceThunks.find({
        returnAlsoWorkspaceOwnerName: true,
        deleted: { $ne: true },
        $sort: {
          name: 1,
        },
        $limit: -1,
      })
    );
    console.log(res)
  }, [dispatch]);

  useEffect(() => {
    getWorkspaces()
  }, [getWorkspaces]);



  useEffect(() => {
    document.title = t('accountWorkspacesView.pageTitle');
  }, []);

  const accountWorkspacesView = () => {
    return (
      <Box className={classes.pageContainer} sx={{ position: 'relative',pointerEvents:readOnly ? 'none' : 'all' }}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>{t('accountWorkspacesView.pageTitle')}</h4>
          <div style={{ pointerEvents:"all" }}> <WorkspaceDropDown isSupportMode={readOnly}/></div>
        </div>
        <div className={classes.accountSettingBody} style={{position:"relative"}}>
        <Button variant="outlined" onClick={openDeletedWorkspacesModal} style={{ position: 'absolute', top: 10, right: 10,textTransform:"capitalize",fontSize:"14px" }}>
             
                View deleted Workspaces
              </Button>
          <div className={classes.newWorkspace}>
  
            <div className={classes.workspaceItemContainer} onClick={createNewWorkspace} style={{ cursor: 'pointer' }}>
              <div className={classes.workspaceImgContainer}>
                <img src={newWorkspace} width={30} height={30} alt="" />
              </div>
              <span className={classes.workspaceName}>{t('accountWorkspacesView.newWorkspace')}</span>
            </div>
          </div>
          <div className={classes.workspacesContainer}>
            {userWorkspaces?.map((ws, index) => {
              const canEdit = ws.owner.toString() === userId;
              return (
                <Stack direction='column' key={index}>

                  <Box sx={{ cursor: 'pointer', }} onClick={() => triggerSwitchWorkspace(ws)} key={`ws-${index}`} className={classes.workspaceItemContainer}>
                    {userCurrentWorkspace?._id === ws?._id ? <div className={classes.selectedWorkspace}>{t('accountWorkspacesView.currentWorkspace')}</div> : null}
                    {userCurrentWorkspace?._id !== ws?._id ? <div className={classes.unselectedWorkspace}>{t('accountWorkspacesView.switchWorkspace')}</div> : null}


                    <div style={{ opacity: userCurrentWorkspace?._id === ws?._id ? 0.375 : 1 }}
                      className={classes.workspaceImgContainer}>
                      {ws.imgUrl ? <img className={classes.workspaceImg} src={ws.imgUrl} alt="" /> : <span className={classes.workspaceImgPlaceholder}>{ws.name.charAt(0)}</span>}
                    </div>
                  </Box>

                  <Stack direction='column' alignItems="center" style={{ opacity: userCurrentWorkspace?._id === ws?._id ? 0.375 : 1 }}>
                    <Tooltip style={{ opacity: userCurrentWorkspace?._id === ws?._id ? 0.375 : 1 }}
                      title={canEdit ? t('accountWorkspacesView.editWorkspaceTooltip') : t('accountWorkspacesView.editWorkspaceTooltipDisabled')}>
                      <Box>
                        <IconButton onClick={() => editWorkspace(ws?._id as string)} disabled={!canEdit}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <span style={{ textTransform: "capitalize" }}>{ws.name}</span>
                    <span style={{ fontSize: "14px", textTransform: "capitalize" }} >{t('accountWorkspacesView.owner')}: {ws?.ownerName}</span>
                  </Stack>

                </Stack>
              )
            })}
          </div>
        </div>
        {isWorkspaceChanging &&
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.6)'
          }}>
            <CircularProgress />
          </Stack>}
          <DeletedWorkspacesModal
              open={isDeletedWorkspacesModalOpen}
              onClose={closeDeletedWorkspacesModal}
  
            />
      </Box>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={accountWorkspacesView()}
        active={SideBarItem.Workspace}
        readOnly={readOnly}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default AccountWorkspacesView;
