
import { Skeleton, Stack } from "@mui/material";
import { BsThreeDots } from "react-icons/bs";

export const ArchivedOrDeletedSkeletonLoader = () => {

    return (
        <div>
            {[...Array(8)].map((_, i) => (
                <Stack alignItems={"center"} key={i} direction="row" py={2} style={{ borderBottom: '1px solid #ccc', fontSize: "14px" }}>
                    <div style={{ flex: 1 }}>
                        <Skeleton variant="rectangular" width={20} height={20} />
                    </div>
                    <div style={{ flex: 5 }}>
                        <Skeleton animation="wave" variant="text" width={250} height={30} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Skeleton animation="wave" variant="text" width={60} height={30} />
                    </div>
                    <div style={{ flex: 2 }}>
                        <Skeleton animation="wave" variant="text" width={160} height={30} />
                    </div>
                    <div style={{ flex: 2 }}>
                        <Skeleton animation="wave" variant="text" width={160} height={30} />
                    </div>
                    <div style={{ flex: 2 }}>
                        <Skeleton variant="circular" width={30} height={30} />
                    </div>
                    <div style={{ flex: 0.85 }}><BsThreeDots /></div>
                </Stack>
            ))}
        </div>
    );
};



