import ToolbarButton from '@common-components/toolbar-button';
import { Badge, Box, Button, Menu, Stack } from "@mui/material";
import { workspaceActions } from "@common-reducers/WorkspaceReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from '@common/config/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
const hoverStyle = {
    '&:hover': {
        opacity: "1",
        background: "var(--button-hover)",
        boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)"
    }
};

export const UserManagementFilter = () => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const [filterMenuAnchor, setFilterMenuAnchor] = React.useState(null);

    const userTypeFilter = useAppSelector(state => state.WorkspaceReducer.userTypeFilter);
    const userStatusFilter = useAppSelector(state => state.WorkspaceReducer.userStatusFilter);

    const handleUserTypeChange = (type) => {
        dispatch(workspaceActions.setUserTypeFilter({ type, value: !userTypeFilter[type] }));
    };

    const handleUserStatusChange = (status) => {
        dispatch(workspaceActions.setUserStatusFilter({ status, value: !userStatusFilter[status] }));
    };

    const handleFilterMenuOpen = (event) => {
        setFilterMenuAnchor(event.currentTarget);
    };

    const handleFilterMenuClose = () => {
        setFilterMenuAnchor(null);
    };

    const activeFilterCount = [...Object.values(userTypeFilter), ...Object.values(userStatusFilter)].filter(Boolean).length;



    const buttonStyles = {
            opacity: "1",
            background: "var(--button-hover)",
            boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)",
            '&:hover': {
                opacity: "1",
                background: "var(--button-hover)",
                boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)"
            }
    };
    return (
        <>
            <Badge sx={{
                '& .MuiBadge-badge': {
                    color: '#fff',
                    backgroundColor: '#53bdeb',
                }
            }} badgeContent={activeFilterCount} >
            <ToolbarButton
                    startIconSVG={FaFilter}
                    onClickAction={(event) => handleFilterMenuOpen(event)}
                    buttonText={t('userManagementFilter.filterButton')}
                    toolTipTitle={t('userManagementFilter.filterTooltip')}
                    additionalStyles={activeFilterCount > 0 ? buttonStyles  : hoverStyle}
                >

                </ToolbarButton>
            </Badge>

            <Menu
                anchorEl={filterMenuAnchor}
                open={Boolean(filterMenuAnchor)}
                onClose={handleFilterMenuClose}
            >
                <Stack direction="row" spacing={2} p={2} justifyContent={"center"}>
                    <Box sx={{ textAlign: "center" }}>
                        <Box mb={1}>{t('userManagementFilter.userTypeHeader')}</Box>
                        {['owner', 'member', 'guest'].map((type, index) => (
                            <Box key={'user-type-' + index}>
                                <Button
                                    sx={{ width: "150px", textTransform: "capitalize" }}
                                    onClick={() => handleUserTypeChange(type)}
                                    variant={userTypeFilter[type] ? "contained" : "outlined"}
                                    color="primary"
                                    style={{ margin: 4 }}
                                >
                                    {type}
                                </Button>
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{ textAlign: "center" }}>
                        <Box mb={1}>{t('userManagementFilter.userStatusHeader')}</Box>
                        {['pending', 'active', 'disabled'].map((status, index) => (
                            <Box key={'user-status-' + index}>
                                <Button
                                    sx={{ width: "150px", textTransform: "capitalize" }}
                                    onClick={() => handleUserStatusChange(status)}
                                    variant={userStatusFilter[status] ? "contained" : "outlined"}
                                    color="primary"
                                    style={{ margin: 4 }}
                                >
                                    {status}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Stack>
            </Menu>
        </>
    );
};
