import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import SelectVariable from "@common-components/bt-chatbot/bot-components/select-variable";
import RowStack from "@common-components/common/row-stack/RowStack";
import { HelpRounded, RemoveCircle } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import classes from "./webhook-node.module.scss";

const toolTipText = 'For "Chatbot input variables," they should start with @, such as @age. For "Contact custom variables," they should be formatted as {{ ... }}, like {{ phone }}. Users can also choose the variable by clicking the "VARIABLES" button.';

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
    localCurrentBot: any;
    setLocalCurrentBot: (localCurrentBot: any) => void;
    id: string;
};

export default function SaveResponseAsVariable({
    localWebhookState,
    setLocalWebhookState,
    localCurrentBot,
    setLocalCurrentBot,
    id,
}: Props) {
    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>;
    };

    const secondaryTitle = (secondaryTitle: string, style?: any) => {
        return <div className={classes.secondary_title} style={style}>{secondaryTitle}</div>;
    };

    const setVariable = (type: 'variableName' | 'responseBody', value: any, index: number) => {
        setLocalWebhookState(prevState => ({
            ...prevState,
            saveResponseAsVariable: {
                ...prevState.saveResponseAsVariable,
                variables: prevState.saveResponseAsVariable.variables.map((variable, i) => {
                    if (i === index) {
                        return {
                            ...variable,
                            [type]: value,
                        };
                    } else {
                        return variable;
                    }
                }),
            },
        }));

        if (type === 'variableName') {
            setLocalCurrentBot(prevBot => {
                const updatedVariables = { ...prevBot.botSettings.variables };
                const nodeIdVariables = updatedVariables[id] ? [...updatedVariables[id]] : [];

                // Update the specific variable name in the node's variables array
                nodeIdVariables[index] = value;

                return {
                    ...prevBot,
                    botSettings: {
                        ...prevBot.botSettings,
                        variables: {
                            ...updatedVariables,
                            [id]: nodeIdVariables,
                        },
                    },
                };
            });
        }
    };

    const handleClickRemoveVariable = (index: number) => {
        const variables = [...localWebhookState.saveResponseAsVariable.variables];
        variables.splice(index, 1);

        setLocalWebhookState({
            ...localWebhookState,
            saveResponseAsVariable: {
                ...localWebhookState.saveResponseAsVariable,
                variables: variables,
            },
        });

        setLocalCurrentBot(prevBot => {
            const updatedVariables = { ...prevBot.botSettings.variables };
            const nodeIdVariables = updatedVariables[id] ? [...updatedVariables[id]] : [];
            nodeIdVariables.splice(index, 1);

            return {
                ...prevBot,
                botSettings: {
                    ...prevBot.botSettings,
                    variables: {
                        ...updatedVariables,
                        [id]: nodeIdVariables,
                    },
                },
            };
        });
    };

    const handleAddVariable = () => {
        setLocalWebhookState(prevState => ({
            ...prevState,
            saveResponseAsVariable: {
                ...prevState.saveResponseAsVariable,
                variables: [
                    ...prevState.saveResponseAsVariable.variables,
                    { variableName: '', responseBody: '' },
                ],
            },
        }));

        setLocalCurrentBot(prevBot => {
            const updatedVariables = { ...prevBot.botSettings.variables };
            const nodeIdVariables = updatedVariables[id] ? [...updatedVariables[id]] : [];
            nodeIdVariables.push('');

            return {
                ...prevBot,
                botSettings: {
                    ...prevBot.botSettings,
                    variables: {
                        ...updatedVariables,
                        [id]: nodeIdVariables,
                    },
                },
            };
        });
    };

    const handleToggleSwitch = () => {
        let variables = localWebhookState.saveResponseAsVariable.variables;
        if (variables.length === 0) {
            variables = [{ variableName: '', responseBody: '' }];
            setLocalCurrentBot(prevBot => ({
                ...prevBot,
                botSettings: {
                    ...prevBot.botSettings,
                    variables: {
                        ...prevBot.botSettings.variables,
                        [id]: [''],
                    },
                },
            }));
        }
        setLocalWebhookState(prevState => ({
            ...prevState,
            saveResponseAsVariable: {
                ...prevState.saveResponseAsVariable,
                isSaveResponseAsVariable: !prevState.saveResponseAsVariable.isSaveResponseAsVariable,
                variables,
            },
        }));
    };

    const variableRow = (variable, _index) => {
        return (
            <Stack key={_index} sx={{ marginBottom: '15px' }}>
                <RowStack sx={{ gap: 2, marginBottom: '5px' }}>
                    <Stack sx={{ height: '110px' }}>
                        <RowStack sx={{ alignItems: 'flex-end' }}>
                            {secondaryTitle('Variable Name')}
                            <Tooltip title={toolTipText}>
                                <HelpRounded sx={{ color: '#333333', width: '15px', margin: '0 0 -2px 3px' }} />
                            </Tooltip>
                        </RowStack>
                        <BotInputField
                            value={variable.variableName}
                            onChange={(newValue) => setVariable('variableName', newValue, _index)}
                            placeholder='Type value or select'
                        />
                        <div style={{ marginTop: '10px' }}>
                            <SelectVariable buttonSize='small' onSelect={(newValue) => setVariable('variableName', newValue, _index)} />
                        </div>
                    </Stack>
                    <Stack sx={{ height: '110px' }}>
                        {secondaryTitle('Entire Response Body')}
                        <BotInputField
                            value={variable.responseBody}
                            onChange={(newValue) => setVariable('responseBody', newValue, _index)}
                            placeholder='Type value or select'
                        />
                    </Stack>
                    <IconButton
                        disableRipple
                        sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            paddingBottom: '25px',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            ".MuiSvgIcon-root:hover": {
                                color: 'red',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() => handleClickRemoveVariable(_index)}
                    >
                        <RemoveCircle />
                    </IconButton>
                </RowStack>
            </Stack>
        );
    };

    return (
        <div className={classes.divider}>
            <RowStack sx={{ justifyContent: 'space-between' }}>
                {title('Save Responses as Variables')}
                <BotToggleSwitch
                    onChange={handleToggleSwitch}
                    checked={localWebhookState.saveResponseAsVariable.isSaveResponseAsVariable}
                    isOptional={true}
                    sx={{ marginBottom: '20px' }}
                />
            </RowStack>
            {localWebhookState.saveResponseAsVariable.isSaveResponseAsVariable && (
                <>
                    {secondaryTitle('Select from the dropdown below to save a specific part of the')}
                    {secondaryTitle('response as a variable (you must test the request first).')}
                    {secondaryTitle('Useful for displaying dynamic data from external sources as')}
                    {secondaryTitle('buttons or messages.')}

                    {title('Edit Response Variables', { marginTop: '10px' })}

                    {(localWebhookState?.saveResponseAsVariable?.variables ?? []).map((variable, index) =>
                        variableRow(variable, index)
                    )}
                    <BTbotbutton styleType="dashed" onClick={handleAddVariable}>
                        Add Response Variable
                    </BTbotbutton>
                </>
            )}
        </div>
    );
}
