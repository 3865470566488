import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import RowStack from "@common-components/common/row-stack/RowStack";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getNodeDataByNodeId } from "@common-reducers/ChatbotSelectors";
import { changeNodeDataThunk, removeHandleAndConnectedEdgesByHandleIdArrayThunk } from "@common-reducers/ChatbotThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useState } from 'react';
import { useReactFlow } from "reactflow";
import CustomizeBody from "./customize-body";
import CustomizeHeaders from "./customize-headers";
import ResponseRouting from "./response-routing";
import SaveResponseAsVariable from "./save-response-as-variable";
import SetURLAndMethod from "./set-url-and-method";
import TestRequest from "./test-request";
import classes from "./webhook-node.module.scss";
import { setCurrentBot, useStore } from "@common/reducers/ChatbotReducer";

type Props = {
    onClose: () => void
    id?: string;
}

export default function AddWebhookDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()

    const { setNodes } = useStore();
    const { setEdges } = useReactFlow()

    const data = useAppSelector(state => getNodeDataByNodeId(state, id))
    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)


    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)
    const [localEntitiesArrayToDelete, setLocalEntitiesArrayToDelete] = useState([])
    const [localNodeHandles, setLocalNodeHandles] = useState<any>(data?.handles)
    const [localWebhookState, setLocalWebhookState] = useState<any>(data?.webhook ?? {
        url: 'https://',
        method: 'GET',
        customizeHeaders: {
            isCustomizeHeaders: false,
            headers: [{ key: '', value: '' }],
        },
        customizeBody: {
            isCustomizeBody: false,
            body: '',
        },
        testRequest: {
            isTestWithVariables: false,
            testVariables: [{ variableName: '', testValue: '' }],
        },
        saveResponseAsVariable: {
            isSaveResponseAsVariable: false,
            variables: [],
        },
        responseRouting: {
            isResponseRouting: false,
            responseRoutes: [],
        }
    })


    const saveWebhookData = async () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: {
                webhook: localWebhookState,
                handles: localNodeHandles,
            }
        }))

        await dispatch(setCurrentBot(localCurrentBot))

        const resultAction = await dispatch(removeHandleAndConnectedEdgesByHandleIdArrayThunk(localEntitiesArrayToDelete))
        if (removeHandleAndConnectedEdgesByHandleIdArrayThunk.fulfilled.match(resultAction)) {
            for (let edgeId of resultAction.payload) {
                setEdges((edges) => edges.filter((e) => e.id !== edgeId));
            }
        }

        onClose()
    }


    return (
        <>
            <RowStack className={classes.add_webhook_dialog_header}>
                <span>Webhook</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </RowStack>

            <SetURLAndMethod
                localWebhookState={localWebhookState}
                setLocalWebhookState={setLocalWebhookState} />

            <CustomizeHeaders
                localWebhookState={localWebhookState}
                setLocalWebhookState={setLocalWebhookState} />

            {['POST', 'PATCH'].includes(localWebhookState.method) &&
                <CustomizeBody
                    localWebhookState={localWebhookState}
                    setLocalWebhookState={setLocalWebhookState} />
            }

            <TestRequest
                localWebhookState={localWebhookState}
                setLocalWebhookState={setLocalWebhookState} />

            <SaveResponseAsVariable
                localWebhookState={localWebhookState}
                setLocalWebhookState={setLocalWebhookState}
                localCurrentBot={localCurrentBot}
                setLocalCurrentBot={setLocalCurrentBot}
                id={id} />

            <ResponseRouting
                localWebhookState={localWebhookState}
                setLocalWebhookState={setLocalWebhookState}
                localEntitiesArrayToDelete={localEntitiesArrayToDelete}
                setLocalEntitiesArrayToDelete={setLocalEntitiesArrayToDelete}
                localNodeHandles={localNodeHandles}
                setLocalNodeHandles={setLocalNodeHandles} />


            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton onClick={saveWebhookData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
            </RowStack>
        </>
    )
}