import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { getColumnsIcon, getColumnsIconColor } from "../automations-helper";

const PickerColumn = ({ anchorEl, handleClose, filteredColumns, handleColumnChange }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuList>
                {filteredColumns.map(({ id, type, name }) => (
                    <MenuItem key={id} onClick={() => handleColumnChange(id, name, type)} >
                        <ListItemIcon sx={{ display: 'flex', alignItems: "center", justifyContent: "center", minWidth: '25px', borderRadius: "4px", height: "22px", marginRight: "5px", backgroundColor: getColumnsIconColor(type) }}>{getColumnsIcon(type)}</ListItemIcon>
                        <ListItemText>{name}</ListItemText>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default PickerColumn;