import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectUser } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect } from "react";
import AddAndRemove from "../add-and-remove";
import NotifyButton from "./create-notify-popover";
import { setCurrentAction } from "../automations-helper";



const CreateTaskUpdateAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const actions  = useAppSelector((state) => state.AutomationReducer.actions);
    const user = useAppSelector(selectUser);
    const prefix = index === 0 ? 'Then' : 'and then'

    const handleMessageSet = (value) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, dynamicMessage: value,userId:user._id })));
    }


  
    useEffect(() => {
        if (actions) {
            dispatch(automation.fieldValidityChanged({ component: 'CreateTaskUpdateAction', index, field: 'dynamicMessage', isValid: !!actions[index].dynamicMessage }));
        }
    }, [actions, index, dispatch]);

    
    return (
        <Stack key={index} sx={{ fontSize: "24px" }} direction="row" justifyContent={"space-between"}>
            <Box >
                {prefix} create an update with
                <NotifyButton index={index} submitMessage={handleMessageSet} message={actions[index].dynamicMessage}  componentName={"CreateTaskUpdateAction"}/>
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="CreateTaskUpdateAction" />
        </Stack>
    );
};

export default CreateTaskUpdateAction;
