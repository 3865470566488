import BTSlide from '@common-components/bt-slider/bt-slider';
import { PickerProps } from '@common-components/tasks/columns-cells/cell-interface';
import { PaletteOutlined, PreviewOutlined, ShareOutlined, ViewWeek } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { DBFormThunks, formSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { MdSettings } from 'react-icons/md';
import BlueticksForm from './blueticks-form';
import BTColorPicker from './bt-color-picker';
import FormSettingsDrawer from './form-settings-drawer';
import FormShare from './form-share';
import classes from "./form.module.scss";
import { updateFormColumnDataThunk } from '@common-reducers/formThunks';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';


type Props = {
    boardId: string;
    formId: string;
}

export default function FormView({ boardId, formId }: Props) {
    const { t } = useTranslation(namespace);
    const [isBlurred, setIsBlurred] = useState(false);
    const [isSettings, setIsSettings] = useState(false)
    const formBgColor = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'backgroundColor'))
    const shareId = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'shareId'))
    const dispatch = useAppDispatch();


    const formURL = window.location.origin + '/form-preview/' + shareId

    const handlePreviewClick = async () => {
        await dispatch(updateFormColumnDataThunk({ formId }))
        window.open(formURL, "_blank");
    }

    const handleShareFormClick = async () => {
        if (!isBlurred) {
            await dispatch(updateFormColumnDataThunk({ formId }))
        }
        setIsBlurred(!isBlurred);
    };

    const handleSettingsClick = () => {
        setIsSettings(!isSettings);
    }

    const togglePicker = ({
        anchorId,
        pickerType
    }) => {
        dispatch(pickerDialogActions.togglePicker({
            pickerAnchorId: anchorId,
            pickerProps: {
                boardId,
                type: pickerType
            } as PickerProps
        }))
    }

    function handleOnChange({ hex }) {
        console.log(hex)
        dispatch(DBFormThunks.patch({
            entity: {
                _id: formId,
                backgroundColor: hex,
            }
        }))
        dispatch(pickerDialogActions.setActiveGroupId(''))
    }

    const openColorPicker = () => {
        dispatch(pickerDialogActions.setActiveGroupId(formId))
    }

    return (
        <Stack direction='column' sx={{
            position: 'relative',
            overflow: 'hidden',
        }}>
            {/* header div- contains preview, share and settings button */}
            <Stack direction='row' className={classes.form_header}>
                {/* preview button  */}
                <Button
                    className={classes.header_button}
                    startIcon={<PreviewOutlined />}
                    style={{ textTransform: 'none', }}
                    value="preview"
                    onClick={handlePreviewClick}
                >
                    {t('formView.previewButton')}
                </Button>
                {/* share button */}
                <Button
                    className={classes.header_button}
                    startIcon={<ShareOutlined />}
                    sx={{
                        textTransform: 'none',
                        opacity: isBlurred && '1 !important',
                        background: isBlurred && 'var(--button-hover) !important',
                        boxShadow: isBlurred && '0 5px 12px rgba(0, 0, 0, 0.15)',
                    }}
                    value="Share"
                    onClick={handleShareFormClick}
                >
                    {isBlurred ? t('formView.editFormButton') : t('formView.shareButton')}
                </Button>
                {/* color button */}
                <Button
                    className={classes.header_button}
                    startIcon={<PaletteOutlined />}
                    style={{ textTransform: 'none', }}
                    value="share"
                    onClick={openColorPicker}
                >
                    {t('formView.colorButton')}
                </Button>
                <Button
                    id={`form_column_${formId}`}
                    key={`form_column_${formId}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        togglePicker({
                            anchorId: `form_column_${formId}`,
                            pickerType: 'forms-included-columns-dialog',
                        })
                    }
                    }
                    startIcon={<ViewWeek style={{ width: 16, height: 16 }} />}
                    className={classes.header_button}
                >{t('formView.columnsButton')}</Button>
                {/* settings button */}
                <Button
                    onClick={handleSettingsClick}
                    className={`${classes.header_button} ${isSettings ? classes.active : ''}`}
                    startIcon={<MdSettings />}
                    style={{
                        textTransform: 'none',
                    }}
                    value="settings" >
                    {t('formView.settingsButton')}</Button>
                <BTColorPicker handleOnChange={handleOnChange} />


            </Stack>
            <Stack direction='row' sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: formBgColor ? formBgColor : 'var(--secondary-background-color)',
            }}>
                {isBlurred && (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '100px',
                            transform: 'translateX(-50%)',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            zIndex: 10,
                        }}>
                        <FormShare formId={formId} shareId={shareId} />
                    </div>
                )}
                <BTSlide open={isSettings}>
                    {/* ================================================ */}
                    {/* =====HERE IS A NEW COMPONENT FOR THE DRAWER===== */}
                    {/* ================================================ */}
                    <FormSettingsDrawer formId={formId} />
                </BTSlide>
                <Stack direction='row'
                    style={{
                        filter: isBlurred ? 'blur(40px)' : 'none',
                        display: 'flex',
                        width: '100%',
                        height: 'calc(100vh - 156px)',
                        overflow: 'auto',
                        justifyContent: 'center',
                    }}>
                    <div style={{ margin: '30px 0' }}>
                        <BlueticksForm formId={formId} boardId={boardId} />
                    </div>
                </Stack>
            </Stack>
        </Stack >
    )
}
