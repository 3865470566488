import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem } from '@mui/material';

interface Props {
    handleChangeValueField: (value: string) => void;
    valueField?: string | null;
}

const FilterRowValueFieldSpecialDateComponent: React.FC<Props> = ({
    handleChangeValueField,
    valueField
}) => {
    const initialDateNumberValue = (valueField && !Array.isArray(valueField) && !valueField.includes('isbetween')) ? valueField.split('-')[0] : ''
    const initialDatePeriodValue = (valueField && !Array.isArray(valueField) && !valueField.includes('isbetween')) ? valueField.split('-')[1] : 'days'
    const [specialDateNumberValue, setSpecialDateNumberValue] = useState<string>(initialDateNumberValue);
    const [specialDatePeriodValue, setSpecialDatePeriodValue] = useState<string>(initialDatePeriodValue);

    // TIME PERIOD EDIT
    useEffect(() => {
        if (valueField && !Array.isArray(valueField) && !valueField.includes('isbetween')) {
            const specialDateNumber = valueField.split('-')[0]
            const specialDatePeriod = valueField.split('-')[1]
            setSpecialDateNumberValue(specialDateNumber)
            setSpecialDatePeriodValue(specialDatePeriod || 'days')

        }
    }, [valueField]);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 1.5fr', gap: '5px' }}>
            <TextField
                type='number'
                name="results"
                value={specialDateNumberValue ?? ''}
                placeholder=""
                onChange={(e) => {
                    setSpecialDateNumberValue(e.target.value);
                    handleChangeValueField(e.target.value + '-' + (specialDatePeriodValue || 'days'))
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        const target = e.target as HTMLInputElement;
                        handleChangeValueField(target.value + '-' + (specialDatePeriodValue || 'days'))
                    }
                }}
                sx={{
                    width: '70px',
                    height: '47px',
                    fontSize: "12px",
                    ".MuiInputLabel-root ": {
                        fontSize: '12px',
                        height: '49px',
                    },
                    ".MuiOutlinedInput-input": {
                        fontSize: '14px',
                        padding: '13px',
                        position: 'relative',
                    },
                    ".MuiTextField-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'rgba(0, 0, 0, 0.23) !important',
                    },
                    ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'rgba(0, 0, 0, 0.87) !important',
                    },

                }}
            />
            <Select
                sx={{
                    height: '47px',
                    ".MuiOutlinedInput-input": {
                        fontSize: '14px',
                        padding: '12px',
                        position: 'relative',
                    },
                }}
                value={specialDatePeriodValue}
                onChange={(e) => {
                    setSpecialDatePeriodValue(e.target.value);
                    handleChangeValueField(specialDateNumberValue + '-' + e.target.value);
                }}
            >
                <MenuItem sx={{ fontSize: '14px' }} value="days">Days</MenuItem>
                <MenuItem sx={{ fontSize: '14px' }} value="weeks">Weeks</MenuItem>
                <MenuItem sx={{ fontSize: '14px' }} value="months">Months</MenuItem>
            </Select>
        </div>
    );
}

export default FilterRowValueFieldSpecialDateComponent;
