import { AutomationTrigger } from '@common-models/automation';
import { Autocomplete, Box, Button, Grid, Stack, Tab, Tabs, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { RRule, rrulestr } from 'rrule';
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: 'none',
          color: '#53bdeb',
          '&:hover': {
            backgroundColor: "rgb(25 118 210 / 6%)",
            border: "1px dashed #53bdeb"
          },
        },
        contained: {
          backgroundColor: '#53bdeb',
          '&:hover': {
            backgroundColor: '#53bdeb',
          },
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SchedulerProps {
  onRRuleChange: (rrule: string, nextDueDate: Date) => void;
  trigger: AutomationTrigger,
  setRuleDescription: (translatedToTextString: string) => void;
}
export const parseRRule = (rruleStr: string) => {
  let rrule = rrulestr(rruleStr);
  let interval = rrule.options.interval;
  let byhour = rrule.options.byhour[0];
  let minute = rrule.options.byminute[0];

  return { freq: rrule.options.freq, interval, byhour, minute, byweekday: rrule.options.byweekday, bymonthday: rrule.options.bymonthday };
}

export const generateRuleDescription = (rrule) => {
  let desc = '';

  switch (rrule.freq) {
    case RRule.DAILY:
      desc = `Every ${rrule.interval === 1 ? 'day' : rrule.interval + " days"}`;
      break;
    case RRule.WEEKLY:
      const weekOrWeeks = rrule.interval > 1 ? 'weeks' : 'week';
      if (rrule.byweekday) {
        desc = `Every ${rrule.interval === 1 ? 'week' : rrule.interval + " weeks"} on ${rrule.byweekday.map(day => numberToWeekday(day)).join(', ')}`;
      } else {
        desc = `Every ${rrule.interval} ${weekOrWeeks}`;
      }
      break;
    case RRule.MONTHLY:
      if (rrule.bymonthday) {
        desc = `Every ${rrule.interval === 1 ? 'month' : ordinalSuffix(rrule.interval) + " month"} on the ${rrule.bymonthday.map(day => ordinalSuffix(day)).join(', ')}`;
      } else {
        desc = `Every ${rrule.interval === 1 ? '' : ordinalSuffix(rrule.interval)} month`;
      }
      break;
  }

  let hour = rrule.byhour;
  const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;
  hour = (hour - timezoneOffsetInHours + 24) % 24;
  const timeString = `${convertTo12Hour(hour)}`;
  desc += `, at ${timeString}`;

  return desc;
};

const numberToWeekday = (num) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return days[num];
};

const ordinalSuffix = (num) => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return num + 'st';
  }
  if (j === 2 && k !== 12) {
    return num + 'nd';
  }
  if (j === 3 && k !== 13) {
    return num + 'rd';
  }
  return num + 'th';
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

// Helper function to convert 24 hour format to 12 hour format
function convertTo12Hour(time: number) {
  let hours = time;
  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }
  else if (hours === 0) {
    hours = 12;
  }
  return `${hours} ${period}`;
}

// Helper function to convert 12 hour format to 24 hour format
function convertTo24Hour(time: string) {
  const [hoursStr, period] = time.split(" ");
  let hours = parseInt(hoursStr);
  if (period === "PM" && hours !== 12) {
    hours += 12;
  }
  if (period === "AM" && hours === 12) {
    hours = 0;
  }
  return hours;
}

export function Scheduler({ onRRuleChange, setRuleDescription, trigger }: SchedulerProps) {
  const [tabSelected, setTab] = useState(0);
  const [dailyFrequency, setDailyFrequency] = useState(1);
  const [dailyHour, setDailyHour] = useState("7 AM");
  const [weeklyFrequency, setWeeklyFrequency] = useState(1);
  const [weeklyHour, setWeeklyHour] = useState("7 AM");
  const [monthlyFrequency, setMonthlyFrequency] = useState(1);
  const [monthlyHour, setMonthlyHour] = useState("7 AM");
  const [selectedDays, setSelectedDays] = useState<Record<string, boolean>>({});
  const [selectedDates, setSelectedDates] = useState<Record<string, boolean>>({});

  const hours = [...Array(24).keys()].map(h => convertTo12Hour((h + 7) % 24)).filter((v, i, a) => a.indexOf(v) === i);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };


  useEffect(() => {
    generateRRule();
  }, [tabSelected, dailyFrequency, dailyHour, weeklyFrequency, weeklyHour, monthlyFrequency, monthlyHour, selectedDays, selectedDates]);



  useEffect(() => {
    if (trigger && trigger.rruleset) {
      let rrule = parseRRule(trigger.rruleset);
      const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;
      rrule.byhour = (rrule.byhour - timezoneOffsetInHours + 24) % 24;
      const hour = convertTo12Hour(rrule.byhour)
      switch (rrule.freq) {
        case RRule.DAILY:
          setTab(0);
          setDailyFrequency(rrule.interval);
          setDailyHour(hour);
          break;
        case RRule.WEEKLY:
          setTab(1);
          setWeeklyFrequency(rrule.interval);
          setWeeklyHour(hour);
          let selectedDaysFromRRule = daysOfWeek.reduce((acc, curr, idx) => {
            return { ...acc, [curr]: rrule.byweekday?.includes(idx) || false };
          }, {});
          setSelectedDays(selectedDaysFromRRule);
          break;
        case RRule.MONTHLY:
          setTab(2);
          setMonthlyFrequency(rrule.interval);
          setMonthlyHour(hour);
          let selectedDatesFromRRule = Array(31).fill(null).reduce((acc, _, idx) => {
            return { ...acc, [idx + 1]: rrule.bymonthday?.includes(idx + 1) || false };
          }, {});
          setSelectedDates(selectedDatesFromRRule);
          break;
        default:
          break;
      }
      setRuleDescription(generateRuleDescription(rrule));
    }

  }, []);

  const generateRRule = () => {
    let rrule;

    switch (tabSelected) {
      case 0:  // Daily
        rrule = new RRule({
          freq: RRule.DAILY,
          interval: dailyFrequency,
          byhour: moment().hour(convertTo24Hour(dailyHour)).utc().hour(),
          byminute: 0,
        });
        break;
      case 1:  // Weekly
        const selectedDaysForWeekly = Object.keys(selectedDays).filter(day => selectedDays[day]).map(day => daysOfWeek.indexOf(day));
        rrule = new RRule({
          freq: RRule.WEEKLY,
          interval: weeklyFrequency,
          byweekday: selectedDaysForWeekly,
          byhour: moment().hour(convertTo24Hour(weeklyHour)).utc().hour(),
          byminute: 0,
        });
        break;
      case 2:  // Monthly
        const selectedDatesForMonthly = Object.keys(selectedDates).filter(date => selectedDates[date]).map(date => parseInt(date));
        rrule = new RRule({
          freq: RRule.MONTHLY,
          interval: monthlyFrequency,
          bymonthday: selectedDatesForMonthly,
          byhour: moment().hour(convertTo24Hour(monthlyHour)).utc().hour(),
          byminute: 0,
        });
        break;
    }

    const nextDueDate = rrule.after(new Date());
    nextDueDate.setMinutes(0);
    nextDueDate.setSeconds(0);
    onRRuleChange(rrule.toString(), nextDueDate);
    setRuleDescription(generateRuleDescription(rrule.options));
  };




  return (
    <div>
      <Tabs variant="fullWidth" value={tabSelected} onChange={handleChange} sx={{ backgroundColor: '#1976d2' }}>
        <Tab label="Daily" sx={{ color: '#fff', '&.Mui-selected': { color: '#fff' } }} />
        <Tab label="Weekly" sx={{ color: '#fff', '&.Mui-selected': { color: '#fff' } }} />
        <Tab label="Monthly" sx={{ color: '#fff', '&.Mui-selected': { color: '#fff' } }} />
      </Tabs>
      <Stack direction="row" flexWrap={"wrap"}>
        <TabPanel value={tabSelected} index={0}>
          <Stack direction="row" alignItems={"center"} spacing={0.8} mt={2}>
            <Typography>Every</Typography>
            <TextField sx={{ maxWidth: "45px" }} variant='standard' type="number" value={dailyFrequency} onChange={e => setDailyFrequency(parseInt(e.target.value))} />
            <Typography>{dailyFrequency > 1 ? 'days' : "day"}, at</Typography>
            <Autocomplete
              value={dailyHour}
              onChange={(event, newValue) => {
                setDailyHour(newValue);
              }}
              options={hours}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              style={{ maxHeight: '200px', width: "105px" }}
            />
          </Stack>
        </TabPanel>

        <TabPanel value={tabSelected} index={1}>
          <Stack direction="row" justifyContent={"space-between"}>
            {daysOfWeek.map(day => (
              <ThemeProvider theme={theme}>
                <Button
                  sx={{ minWidth: "30px", width: "30px", height: "30px", padding: 0, borderRadius: "50%", }}
                  variant={selectedDays[day] ? "contained" : "outlined"}
                  onClick={() => setSelectedDays({ ...selectedDays, [day]: !selectedDays[day] })}
                  key={day}>
                  {day}
                </Button>
              </ThemeProvider>
            ))}
          </Stack>
          <Stack direction="row" alignItems={"center"} spacing={0.8} mt={2}>
            <Typography>Every</Typography>
            <TextField sx={{ maxWidth: "45px" }} variant='standard' type="number" value={weeklyFrequency} onChange={e => setWeeklyFrequency(parseInt(e.target.value))} />
            <Typography>{weeklyFrequency > 1 ? 'weeks' : "week"}, at</Typography>
            <Autocomplete
              value={weeklyHour}
              onChange={(event, newValue) => {
                setWeeklyHour(newValue);
              }}
              options={hours}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              style={{ maxHeight: '200px', width: "105px" }}
            />
          </Stack>
        </TabPanel>

        <TabPanel value={tabSelected} index={2}>
          <Grid container gap={"14px"}>
            {[...Array(31).keys()].map(date => (
              <Grid key={date + 1} alignItems={"center"} textAlign={"center"} sx={{ minWidth: "30px", height: "30px" }} item xs={1.3}>
                <ThemeProvider theme={theme}>
                  <Button
                    sx={{ minWidth: "30px", height: "30px", padding: 0, borderRadius: "50%", }}
                    variant={selectedDates[date + 1] ? "contained" : "outlined"}
                    onClick={() => setSelectedDates({ ...selectedDates, [date + 1]: !selectedDates[date + 1] })}>
                    {date + 1}
                  </Button>
                </ThemeProvider>
              </Grid>
            ))}
          </Grid>

          <Stack direction="row" alignItems={"center"} spacing={0.8} mt={2}>
            <Typography>Every</Typography>
            <TextField sx={{ maxWidth: "45px", }} variant='standard' type="number" value={monthlyFrequency} onChange={e => setMonthlyFrequency(parseInt(e.target.value))} />
            <Typography>{monthlyFrequency > 1 ? 'months' : "month"}, at</Typography>
            <Autocomplete
              value={monthlyHour}
              onChange={(event, newValue) => {
                setMonthlyHour(newValue);
              }}
              options={hours}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              style={{ maxHeight: '200px', width: "105px" }}
            />
          </Stack>
        </TabPanel>

      </Stack>
    </div>
  );
}
