import BTButton from '@common-components/bt-button/bt-button';
import BTSelectButton from '@common-components/bt-select-menu/bt-select-menu';
import { ViewMode } from '@common-components/reactgantt';
import { Slide } from '@mui/material';
import { Stack } from '@mui/system';
import { getCurrentBoard } from '@common-reducers/BoardsSelectors';
import { DBGanttThunks, ganttSelectByQuery, ganttSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useRef, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import dateIcon from '@common-assets/icons/dateSelectMenu.png';
import firstDateOptionColumn from '@common-assets/icons/firstDateOptionColumn.png';
import groupedIcon from '@common-assets/icons/groupedIcon.png';
import lastDateOptionColumn from '@common-assets/icons/lastDateOptionColumn.png';
import unGroupedIcon from '@common-assets/icons/ungroupedIcon.png';
import GeneralScreenWithButtonAndText from '../general-screen-with-button-and-text';
import classes from '../kanban/kanban.module.scss';
import BTGantt from './gantt';
import GanttSettingsDrawer from './gantt-settings-drawer';
import { columnListSelector } from '@common-reducers/TaskSelectors';

type Props = {
    boardId: string;
    ganttId: string
}

export default function GanttView({ boardId, ganttId }: Props) {
    const dispatch = useAppDispatch();

    const ganttsList = useAppSelector((state) => ganttSelectByQuery(state.DBGanttReducer, { boardId: boardId }));
    const currentBoard = useAppSelector(getCurrentBoard)
    const columnList = useAppSelector(state => columnListSelector(state, currentBoard._id));
    const [firstTimelineColumn] = columnList.filter(column => column.type === 'timeline-picker');
    const columnId = ganttsList[0]?.columnId;
    const ganttSettingsMode = useAppSelector((state) => state.GanttReducer.settingsMode);
    const [orderBy, setOrderBy] = useState()
    const [orderByOpen, setOrderByOpen] = useState<boolean>(false)
    const [viewModeOpen, setViewModeOpen] = useState<boolean>(false)
    const [fixDependencyView, setFixDependencyView] = useState<boolean>(false)
    const [renderAfterViewModeChange,setRenderAfterViewModeChange]=useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const gantt = ganttsList[0]
    const viewModeFromDb = useAppSelector(state => ganttSelectOneFieldByQuery(state.DBGanttReducer, { _id: gantt?._id },
        'viewMode'));
        const [viewMode, setViewMode] = useState(viewModeFromDb ?? ViewMode.Day)

    const groupView = useAppSelector(state => ganttSelectOneFieldByQuery(state.DBGanttReducer, { _id: gantt?._id },
        'showTasksByGroup'));
    const fixDependency = useAppSelector(state => ganttSelectOneFieldByQuery(state.DBGanttReducer, { _id: gantt?._id },
        'fixDependency'));
    const orderByFromDB = useAppSelector(state => ganttSelectOneFieldByQuery(state.DBGanttReducer, { _id: gantt?._id },
        'orderTasksBy'));
    const [groupedView, setGroupedView] = useState(groupView)
    const childRef = useRef();
    const [todayIndicator, setTodayIndicator] = useState<any>(undefined);
    const viewModeMenuOptions =
        [
            { icon: '', funcIndicator: ViewMode.Day, text: ViewMode.Day },
            { icon: '', funcIndicator: ViewMode.Week, text: ViewMode.Week },
            { icon: '', funcIndicator: ViewMode.Month, text: ViewMode.Month },
            { icon: '', funcIndicator: ViewMode.Year, text: ViewMode.Year },
        ]
    const orderMenuOptions = [
        { icon: '', funcIndicator: 'customOrder', text: 'Custom Order' },
        { icon: firstDateOptionColumn, funcIndicator: 'startDate', text: 'Start Date' },
        { icon: lastDateOptionColumn, funcIndicator: 'endDate', text: 'End Date' },
    ]
    const groupedMenu = [
        { icon: groupedIcon, funcIndicator: true, text: 'Grouped' },
        { icon: unGroupedIcon, funcIndicator: false, text: 'Ungrouped' },
    ]
    const fixDependencyOptions = [
        { icon: '', funcIndicator: true, text: 'Fix Dependency' },
        { icon: '', funcIndicator: false, text: 'Free mode' }
    ]


    const handleClickMenuItem = (handleIndicator, menuItemValue) => {
        switch (handleIndicator) {
            case 'orderBy':
                setOrderByOpen(false)
                setOrderBy(menuItemValue)
                dispatch(
                    DBGanttThunks.patch({
                        entity: {
                            ...gantt,
                            orderTasksBy: menuItemValue
                        }
                    })
                )

                break;
            case 'grouped':
                dispatch(
                    DBGanttThunks.patch({
                        entity: {
                            ...gantt,
                            showTasksByGroup: !groupedView
                        }
                    })
                )
                setGroupedView(!groupedView)
                break;
            case 'fixDependency':
                dispatch(
                    DBGanttThunks.patch({
                        entity: {
                            ...gantt,
                            fixDependency: !fixDependencyView
                        }
                    })
                )
                setFixDependencyView(!fixDependencyView)
                break;
            case 'viewMode':
                setViewModeOpen(false)


                setViewMode(null)

                setTimeout(() => {
                    setViewMode(menuItemValue)

                }, 300);

                dispatch(
                    DBGanttThunks.patch({
                        entity: {
                            ...gantt,
                            viewMode: menuItemValue
                        }
                    })
                )
                setRenderAfterViewModeChange(true)

                break;
        }

    }
    const handleTodayClick = () => {
        const beforeTodayVisual = 86400000 * 14
        const todayDate = new Date((Date.now() - beforeTodayVisual));


        setTodayIndicator(todayDate.getTime())
    }


    const [anchorGanttTasklist, setAnchorGanttTasklist] = useState<any>()
    const openPopover = Boolean(anchorEl);
    const anchorReference = 'anchorPosition';
    const [taskHasClicked, setTaskHasClicked] = useState<any>()
    const handleClose = () => {
        setAnchorGanttTasklist(null);
    };
    const openPopoverFunc = (e: any) => {
        setAnchorGanttTasklist(e.currentTarget)
    }
    return (
        <Stack direction='column'>
            <Stack direction='row' className={classes.kanban_header}>

                <BTSelectButton selected={viewModeFromDb ?? ViewMode.Day} icon={dateIcon} options={viewModeMenuOptions} onClick={(index) => handleClickMenuItem('viewMode', viewModeMenuOptions[index].funcIndicator)} />
                <BTSelectButton selected={orderByFromDB ?? orderMenuOptions[0].funcIndicator} options={orderMenuOptions} onClick={(index) => handleClickMenuItem('orderBy', orderMenuOptions[index].funcIndicator)} />
                <BTSelectButton selected={groupedView ?? groupedMenu[0].funcIndicator} options={groupedMenu} onClick={(index) => handleClickMenuItem('grouped', groupedMenu[index].funcIndicator)} />

                <BTSelectButton selected={fixDependency ?? fixDependencyOptions[0].funcIndicator} options={fixDependencyOptions} onClick={(index) => handleClickMenuItem('fixDependency', fixDependencyOptions[index].funcIndicator)} />

                <BTButton
                    onClick={handleTodayClick}
                    className={classes.header_button}
                    sx={{ marginLeft: 'auto' }}
                    endIcon={<BsArrowRight style={{ width: 24, height: 24 }} />}
                >
                    today
                </BTButton>
                {/* <Button

                    className={classes.header_button}
                    startIcon={<SettingsOutlined style={{ width: 24, height: 24 }} />}
                >
                    Settings
                </Button> */}
            </Stack>
            {!firstTimelineColumn &&
                <div>
                    <GeneralScreenWithButtonAndText
                        height="calc(100vh - 260px)"
                        target="ganttStatus"
                        textValue="This board has no timeline column <b>yet</b>!" />
                </div>
            }
            {firstTimelineColumn &&

                <Stack direction='column' sx={{
                    display: 'flex',
                }}>
                    {/* ask noam about overflow here!
                        neet to make calendar.tsx header sticky
                    
                    */}


                    {viewMode != null &&
                        <div style={{
                            justifySelf: 'center', maxWidth: '100%', height: '100%'
                            , overflow: 'scroll'
                        }}>
                            <BTGantt
                                today={todayIndicator}
                                gantt={ganttsList[0]}
                                columnId={columnId ?? firstTimelineColumn?._id}
                                openPopover={(e) => openPopoverFunc}

                            />
                        </div>
                    }
                    <Slide direction="left" in={ganttSettingsMode} mountOnEnter unmountOnExit>
                        <div style={{

                            maxWidth: '400px',
                            minWidth: '200px',
                            height: '100%',
                            border: '1px solid var(--border-color)',
                            zIndex: '10',
                            position: 'absolute',
                            right: '0',
                            backgroundColor: 'var(--background-default)',
                            padding: '10px',
                        }}>
                            <GanttSettingsDrawer ganttId={ganttId} />
                        </div>
                    </Slide>
                </Stack>

            }

            {/* 
<Popover 
        sx={{overflow:'visible',
        width:'300px',
        '&.MuiPopover-paper': {
          overflow:'visible',
      }
      
      }}
      PaperProps={{
        sx: {
          '&.MuiPopover-paper':{
            overflow:'visible',
            width:'350px',
            minWidth:'350px'
          }
        },
        }}
          open={openPopover}
          anchorEl={anchorGanttTasklist}
          anchorReference={anchorReference}
          anchorPosition={{
            top: window.innerHeight / 3.5,
            left: 300,
          }}
          onClose={handleClose}
        >
          <GanttPopover task={taskHasClicked}  />
        </Popover> */}
        </Stack>
    )
}