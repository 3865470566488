
import { TaskColumn } from "@common-models/task-column";
import { Stack } from "@mui/system";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { ReactElement, memo } from 'react';
import { TaskColumnType } from "../columns-cells/cell-interface";
import CellWrapper from "../columns-cells/cell-wrapper";
import { columnTypeList } from "../columns-cells/column-cell-defs";
import classes from "./group_header.module.scss";

interface Props {
  boardId: string;
  tasksCount: number;
}

interface CustomColumnCellOptions {
  column: TaskColumn;
}

function GroupHeaderBoardView({ boardId,tasksCount}: Props): ReactElement {


  const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);

   let columnList = [
    {
      "_id": "row-prefix",
      "type": "row-prefix",
      "title": "row-prefix",
      "static": true,
      "width":400,
      "order": -20
    },
    // {
    //   "_id": "chat",
    //   "type": "chat",
    //   "title": "Chat",
    //   "static": true,
    //   "order": -10,
    //   "width": 80
    // },
    {
      "_id": "board-cell",
      "type": "board-cell",
      "title": "Board",
      "static": true,
      "outerCell": true,
      "width": 250,

      "order": 1000
    },
    {
      "_id": "group-cell",
      "type": "group-cell",
      "title": "Group",
      "static": true,
      "outerCell": true,
      "width": 250,

      "order": 1000
    },
    {
      "_id": "person-picker",
      "type": "person-picker",
      "title": "Owner",
      "width": 180,
      "static": true,


    },
    {
      "_id": "datepicker",
      "type": "datepicker",
      "title": "Date",
      "width": 180,
      "static": true,

    },
    {
      "_id": "status-option-picker",
      "type": "status-option-picker",
      "title": "Status",
      "width": 180,
      "static": true,

    }
  ]
  
  columnList =  myWorkPreferences?.collapsedBoards?.includes(boardId) || tasksCount === 0  ?  [columnList[0]] : columnList;

  const customColumnCell = ({ column, }: CustomColumnCellOptions) => {

    let cell;
    const columnType: TaskColumnType = columnTypeList.find(columnType => columnType.type === column.type);
    if (columnType) {
      cell =
          <CellWrapper
            columnId={column._id}
            groupId={""}
            boardId={boardId}
            mockValue={{ tasksCount, column }}
            cellComponent={columnType.headerComponentBoardView } />
    }

    const cellId = `column_${boardId}_${column._id}`;

    const columnCell = <Stack
      direction="row"
      alignItems="center"
      className={`${classes.date}`}
      id={cellId}

      style={{
        borderRight: '1px solid transparent',
        boxSizing: 'border-box',
      }}

    >
      {cell}
    </Stack>

    return columnCell;
  }


  return <Stack
    data-testid='column-header-row'
    direction="row"
    className={classes.todoRow}
  >

    {columnList.map((column) => {
      return (
        <div key={`${column._id}-${column.type}`}
          className={`${classes.todoCell}`}
          style={{
            width: column.width,
            position: 'relative',
            backgroundColor: 'var(--secondary-background-color)'
          }}
          data-testid={`column-header-type-${column.type}`}
        >
          {customColumnCell({ column })}



        </div>)

    })}

  </Stack>

}

export default memo(GroupHeaderBoardView);
