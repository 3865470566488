import { appConfig } from "@common-config/app.config";


/// injectPixels function
export const injectPixels = () => {


  console.log('----')
  console.log('loading google analytics')
  console.log(appConfig.GA_ID)



  if (appConfig.GA_ID) {
    // const gaScript = document.createElement('script');
    // gaScript.async = true;
    // gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
    // gaScript.onload = () => {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(...args: any[]) { window.dataLayer.push(args); }
    //   window.gtag = gtag;
    //   gtag('js', new Date());
    //   gtag('config', appConfig.GA_ID);
    //   gtag('config', appConfig.AW_ID, { groups: 'all' });
    //   gtag('config', appConfig.WS_ID);
    // };
    // document.head.appendChild(gaScript);


    // Create a script element for Google Tag Manager
    var gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
    document.head.appendChild(gtmScript);

    // Inline script content
    var inlineScript = document.createElement('script');
    inlineScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', '${appConfig.GA_ID}');
    gtag('config', '${appConfig.AW_ID}', { groups: 'all' });
    gtag('config', '${appConfig.WS_ID}');
    `;
    document.head.appendChild(inlineScript);



  }



  // IT CUSING GOOGLE ANALYTICS NOT TO SEND EVENTS
  /*
  if (appConfig.GTM_ID) {
    // Create a script tag for the Google Tag Manager (GTM)
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${appConfig.GTM_ID}');`;
    document.head.appendChild(gtmScript);

    // Existing Google Analytics initialization code
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${appConfig.GTM_ID}');
      `;
    document.body.appendChild(gaScript);
  }
  */

  // Google Analytics
  // if (appConfig.GA_ID) {
  //   const gaScript = document.createElement('script');
  //   gaScript.async = true;
  //   gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ID}`;
  //   gaScript.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(...args: any[]) { window.dataLayer.push(args); }
  //     window.gtag = gtag;
  //     gtag('js', new Date());
  //     gtag('config', appConfig.GA_ID);
  //     gtag('config', appConfig.AW_ID, { groups: 'all' });
  //     gtag('config', appConfig.WS_ID);
  //   };
  //   document.head.appendChild(gaScript);
  // }





  if (appConfig.FB_PIXEL_ID) {
    const fbScript = document.createElement('script');
    fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${appConfig.FB_PIXEL_ID}');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(fbScript);
  }

  // Hotjar
  if (appConfig.HJ_ID) {
    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: ${appConfig.HJ_ID}, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    `;
    document.head.appendChild(hotjarScript);
  }

  // TikTok
  if (appConfig.TIKTOK_ID) {
    const tiktokScript = document.createElement('script');
    tiktokScript.innerHTML = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
  )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
  
    ttq.load('${appConfig.TIKTOK_ID}');
    ttq.page();
  }(window, document, 'ttq');
  `
    document.body.appendChild(tiktokScript);
  }

  // Clarity
  if (appConfig.CLARITY_ID) {
    const clarityScript = document.createElement('script');
    clarityScript.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${appConfig.CLARITY_ID}");
    `
    document.body.appendChild(clarityScript);
  }


};