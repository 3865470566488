import React, { useEffect, useState } from 'react';
import { Task } from '@common-models/task';
import { TasksGroup } from '@common-models/task-group';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { DBTaskColumnThunks, DBTasksThunks, boardsSelectByQuery, boardsSelectOneObjectByQuery, taskColumnSelectOneFieldByQuery, taskColumnSelectOneObjectByQuery, taskGroupsSelectByQuery } from "@common-reducers/DBServiceThunks";
import classes from './move-to-menu.module.scss'
import { bulkActions } from '@common-reducers/BulkActionsReducer';
import { taskActions } from '@common-reducers/TasksReducer';
import { BsSearch } from 'react-icons/bs';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';
import { getCurrentBoardId } from '@common-reducers/BoardsSelectors';

interface MoveTaskToGroupProps {
    bulkTasksList: Task[];
    activeTaskid: string;
    boardId: string;
    onMoveComplete?: (status: boolean) => void;
}

const MoveTaskToGroup: React.FC<MoveTaskToGroupProps> = ({ bulkTasksList, activeTaskid, boardId,onMoveComplete }) => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const [filterName, setFilterName] = useState<string>('')
    const currentBoardId = useAppSelector(getCurrentBoardId);
    const boardSelected = useAppSelector((state) => boardsSelectOneObjectByQuery(state.DBBoardsReducer,
        {
            _id: boardId,
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    ))
    const boardContactColumnId = useAppSelector((state) => taskColumnSelectOneFieldByQuery(state.DBTaskColumnReducer,
        {
            boardId: boardId,
            type:'whatsapp-id-cell',
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    
    "_id",
    { order: -1 }
  ));

    const currentBoardContactColumnId = useAppSelector((state) => taskColumnSelectOneFieldByQuery(state.DBTaskColumnReducer,
        {
            boardId: currentBoardId,
            type:"whatsapp-id-cell"
        },
    
        "_id",
        { order: -1 }
      ));

    useEffect(() => {
     
            dispatch(DBTaskColumnThunks.find({
                boardId,
                deleted: false,
                $paginate: false,
            }))

    }, [])

    

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer,
        {
            boardId: boardId,
            name: new RegExp(filterName, "i"),
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    ))

    async function moveTaskToGroup(group: TasksGroup) {
        dispatch(bulkActions.setIsBulkActionsDialogOpen(false));
        let tasksToPatch = bulkTasksList;
        const tasksAreOnlySubTasks = tasksToPatch.length > 0 ? tasksToPatch.every(task => task?.parentTaskId !== undefined) : false;


        if (tasksToPatch.length === 0) {
            tasksToPatch = [{ _id: activeTaskid }];
        }
  
  



        const changes = tasksToPatch.map((task) => {
            let change: {
                id: string,
                changes: {
                    groupId: string,
                    boardId?: string,
                    taskType?: string,
                    parentTaskId?: string,
                    customData?: any
                },
            } = {
                id: task._id,
                changes: {
                    groupId: group._id,
                    boardId: boardId,
                },
            };
            if (boardSelected?.type === 'audience' && boardId !== currentBoardId) {
                change.changes.customData = {
                    ...task.customData,
                    [boardContactColumnId]: task.customData?.[currentBoardContactColumnId]
                };
            }
            if (tasksAreOnlySubTasks) {
                change.changes.taskType = 'task';
                change.changes.parentTaskId = '';
            }
            if (onMoveComplete) {
                onMoveComplete(false);
            }

            return change;
        });

        dispatch(DBTasksThunks.patchMany(changes));

        dispatch(bulkActions.toggleMoveTo(false));
        dispatch(bulkActions.setMovingTo(''));
        dispatch(taskActions.toggleTaskMenu(''));
        dispatch(bulkActions.setBulkTasksMap({}))

    }

    const listItemStyles: any = {
        width: 198,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    function handleSearch({ target }: any) {
        setFilterName(target.value)
    }

    const styles = {
        filterContainer: {
            display: 'flex',
            marginTop: '8px',
            marginBottom:"5px",
            borderRadius: '3px',
            border: '1px solid var(--secondary-background-color)',
            borderBottomWidth: '2px',
        },
        filterInput: {
            flex: 1,
            border: '0',
            outline: 'none',
            borderRadius: '3px',
        },
        filterButton: {
            border: '0',
            outline: 'none',
            backgroundColor: 'transparent',
        },
        groupItemWrap: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '3px',
            padding: '0 5px',
        },
        groupItem: {
            flex: 1,
            backgroundColor: "transparent",
            border: 0,
            borderRadius: "3px",
            color: "var(--primary)",
            display: "block",
            outline: "none",
            padding: "8px",
            textAlign: "left"
        },
        accentIcon: {
            width: '16px',
            height: '16px',
            marginRight: '8px',
            backgroundColor: 'var(--secondary-background-color)',
            borderRadius: '50%',
        },
    };
    const groupItemWrapStyle = {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        padding: '0 5px',
        ':hover': {
            backgroundColor: '#ddd'
        }
    };
    return (
        <>
            <Box style={styles.filterContainer} onClick={(e) => e.stopPropagation()}>
                <input style={styles.filterInput} type="text" onChange={handleSearch} placeholder={t('moveTaskToGroup.searchGroup')} />
                <button style={styles.filterButton}>
                    <BsSearch />
                </button>
            </Box>
            {groups.sort((a, b) => a.order - b.order).map((group, index) => (
                <Box key={index} sx={groupItemWrapStyle}
                    onClick={() => moveTaskToGroup(group)}>
                    <Box style={{ ...styles.accentIcon, backgroundColor: group.colorAccent ?? '#00c875' }}></Box>
                    <button style={{ ...styles.groupItem, ...listItemStyles, ...styles.filterButton }}>{group.name}</button>
                </Box>
            ))}
        </>
    );
}



export default MoveTaskToGroup;
