import { AccountPage } from '@common-components/account-page/account-page';
import BTThemeProvider from '@common-components/bt-theme-provider';
import { SideBarItem } from '@common-components/sidebar/sidebar';
import SubscriptionTable from '@common-components/subscription-table/subscription-table';
import { Box, Divider, Typography } from '@mui/material';
import React from 'react'
import { theme } from '@common/theme';


export default function AccountInvoiceView() {
    return (<BTThemeProvider>
        <AccountPage page={<BTThemeProvider>
            <Box sx={{ p: '60px' }}>
                <Typography variant="h4">Invoices</Typography>
                <Divider style={{
                    margin: theme.spacing(1),
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2)
                }}></Divider>
                <SubscriptionTable />
            </Box>
        </BTThemeProvider>} active={SideBarItem.Invoices}></AccountPage>
    </BTThemeProvider>);
}