export interface MeasurementProtocolItem {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string
    location_id?: string;
    price?: number;
    quantity?: number;
}

export interface MeasurementProtocolEvent {
    name: string;
    params: {
        currency?: string;
        value?: number;
        coupon?: string;
        payment_type?: string;
        page_title?: string;
        page_location?: string;
        items?: MeasurementProtocolItem[];
        session_id: string;
        engagement_time_msec: number;
    }
}

export interface MeasurementProtocolBody {
    client_id: string;
    user_id?: string;
    timestamp_micros?: number;
    user_properties?: any;
    events: MeasurementProtocolEvent[];
}

const apiSecret = 'Xx9XgPP8STu_6L4jOa1gNw';
const apiSecret2 = 'otJ2Me7DQ8ymS1m9nyNqqw';

const trackId = "G-5HQG6JS4Y5"; // Blueticks Extension (GA4)
const trackId2 = "G-RCEGPEQEZ5"; // Blueticks Extension - Website - App - REAL


export const sendEvent = async (body: MeasurementProtocolBody) => {
    if (typeof window !== 'undefined' && window.location.host.indexOf('localhost') === 0) {
        return;
    }

    const eventURL = `https://www.google-analytics.com/mp/collect?v=1&measurement_id=${trackId}&api_secret=${apiSecret}`;
    fetch(eventURL, {
        method: "POST",
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(body)
    }).then(res3 => {
        console.log(`${trackId}measure protocol event sent!`)
        console.log(res3)
    }).catch(err => {
        console.error('measure protocol event error!')
        console.error(err);
    });


    const eventURL2 = `https://www.google-analytics.com/mp/collect?v=1&measurement_id=${trackId2}&api_secret=${apiSecret2}`;

    fetch(eventURL2, {
        method: "POST",
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(body)
    }).then(res2 => {
        console.log(`${trackId2}measure protocol event sent!`)
        console.log(res2)
    }).catch(err => {
        console.error('measure protocol event error!')
        console.error(err);
    });

}