import CircularProgressWithText from '@extension/context/content/components/bt-bulk-dashboard/audiences/circular-progress-with-text'
import { ContactTable } from '@extension/context/content/components/bulk-dashboard/contact-table/contact-table'
import { Board } from '@common-models/board'
import { TaskColumn } from '@common-models/task-column'
import { Stack } from '@mui/material'
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors'
import { initialAutoMappingExcelToBoardColumnsThunks } from '@common-reducers/BoardsThunks'
import { boardsSelectors } from "@common-reducers/DBServiceThunks"
import { importItemsActions } from '@common-reducers/ImportItemsReducer'
import { dynamicColumnList } from '@common-reducers/TaskSelectors'
import { getRuntimeUrl } from '@common-services/storage'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import React, { ForwardedRef, useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { RiFileExcel2Line } from 'react-icons/ri'
import { TiArrowRightOutline } from 'react-icons/ti'
import FecthDataToImportDialog from './fetch-data-to-import-dialog'
import classes from './import-excel.module.scss'
import ImportNavButtons from './import-nav-buttons'
import MapImportedDataDialog from './map-imported-data-dialog'
import SelectImportTypeDialog from './select-import-type-dialog'
import { modalActions } from '@common-reducers/ModalReducer'

type Props = {
    handleFinishImporting?: () => void;
}


export const supportedColumnTypes = ['text-cell', 'number', 'phone-cell', 'task name',
    'datepicker', 'whatsapp-id-cell', 'option-picker', 'status-option-picker'
]

const ImportExcelToBlueticks = React.forwardRef<HTMLDivElement, Props>(({
    handleFinishImporting,
}: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch()
    const selectedBoardId = useAppSelector((state) => getSelectedBoardId(state))
    const board: Board = useAppSelector((state) => boardsSelectors.selectById(state, selectedBoardId))
    const _columnsList = useAppSelector((state) => dynamicColumnList(state, selectedBoardId))
    const _importJsonData = useAppSelector((state) => state.ImportItemsReducer.importJsonData);
    const importFileName = useAppSelector((state) => state.ImportItemsReducer.importFileName);
    const selectedColumn = useAppSelector((state) => state.ImportItemsReducer.selectedColumn);
    const importInProgress = useAppSelector((state) => state.ImportItemsReducer.importInProgress);
    const openedby = useAppSelector((state) => state.ModalReducer.openedby)
    const importType = useAppSelector((state) => state.ModalReducer.importType)
    const step = useAppSelector((state) => state.ImportItemsReducer.step);



    const [importJsonData, setImportJsonData] = useState([])

    const srcExcelImg = openedby === 'campaign' ? getRuntimeUrl("/img/excel.png") : "/img/excel.png"
    const srcBTLogoImg = openedby === 'campaign' ? getRuntimeUrl("/img/blueticks_logo_icon.png") : "/img/blueticks_logo_icon.png"
    let columnsList
    if (!selectedBoardId) {
        columnsList = [
            {
                _id: 'taskName',
                title: 'Display Name',
                type: 'task name',
            } as TaskColumn,
            {
                _id: 'fakeFirstNameColumnId012',
                title: 'first name',
                type: 'text-cell',
            } as TaskColumn,
            {
                _id: 'fakeLastNameColumnId0123',
                title: 'last name',
                type: 'text-cell',
            } as TaskColumn,
            {
                _id: 'fakeContactColumnId01234',
                title: 'phone',
                type: 'whatsapp-id-cell',
            } as TaskColumn,
        ]
    }
    else {
        columnsList = [..._columnsList, { _id: 'taskName', title: 'Task Name', type: 'task name' }]
    }






    const goNext = (isFromSheets?: boolean) => {
        if (importType === 'whatsapp') {
            dispatch(modalActions.setImportType('whatsappWithMapping'));
        }
        if (importFileName && (!selectedColumn || openedby === 'campaign') && step === 0) {
            dispatch(importItemsActions.setStep(2))
        } else if (importFileName && selectedColumn && step !== 0) {
            dispatch(importItemsActions.setStep(2))
        }
        else if (isFromSheets && step !== 2) {
            dispatch(importItemsActions.setStep(2))
        }
    }



    const buildStepContainer = () => <Stack sx={{
        justifyContent: 'space-between',
        alignContent: importType === 'whatsapp' && 'center',
        height: '100%',
        direction: 'ltr',
    }}>
        {board?.name && board?.name !== '' &&
            <div className={classes.columnHeader}>
                <RiFileExcel2Line color='#437445' size={21} /> <FaAngleRight size={15} />  <img src="/img/blueticks_logo_icon.png" style={{ height: 20, marginRight: 8 }} alt="" />
                {board?.titleIcon} <span style={{ fontWeight: 400, marginLeft: 4 }}>{board?.name}</span>
            </div>
        }

        {!importType &&
            <SelectImportTypeDialog />
        }

        {step === 0 && importType && !['whatsapp', 'whatsappWithMapping'].includes(importType) &&
            <FecthDataToImportDialog goNext={goNext} />
        }

        {step === 2 &&
            // STEP 1 HAS MERGED INTO STEP 2
            <MapImportedDataDialog
                importJsonData={importJsonData}
                columnsList={columnsList ?? []} />
        }

        {/* {!skipstep3 && step === 3 &&
                <RenderImportLastStep startImport={startImport} />
            } */}


        <ImportNavButtons
            selectedBoardId={selectedBoardId}
            importJsonData={importJsonData}
            handleFinishImporting={handleFinishImporting}
            goNext={goNext} />

    </Stack >


    useEffect(() => {
        setImportJsonData(_importJsonData)

        dispatch(initialAutoMappingExcelToBoardColumnsThunks({
            columnsList,
        }))
    }, [_importJsonData, _columnsList, dispatch])


    useEffect(() => {
        dispatch(importItemsActions.setImportDataMapping({}))
        dispatch(importItemsActions.setNewColumnList([]))
    }, [dispatch])


    return (
        <div ref={ref} className={classes.container}
            style={{
                gridTemplateColumns: step === 3 && '1fr',
                display: ['whatsapp', 'whatsappWithMapping'].includes(importType) && 'block',
            }}>
            <div className={classes.dropzoneWrapper} style={{
                borderRadius: step === 3 && 10,
                position: 'relative',
                backgroundColor: 'var(--import-bg)',
                borderTopRightRadius: ['whatsapp', 'whatsappWithMapping'].includes(importType) && '10px',
                borderBottomRightRadius: ['whatsapp', 'whatsappWithMapping'].includes(importType) && '10px',
                height: 'inherit',
                boxSizing: 'border-box',
            }}>
                {importType !== 'whatsapp' && buildStepContainer()}

                {importType === 'whatsapp' &&
                    <>
                        <ContactTable />
                        <ImportNavButtons
                            selectedBoardId={selectedBoardId}
                            importJsonData={importJsonData}
                            handleFinishImporting={handleFinishImporting}
                            goNext={goNext} />
                    </>
                }

                {importType === 'whatsappWithMapping' &&
                    <MapImportedDataDialog
                        columnsList={columnsList?.filter(column => column._id !== 'taskName') ?? []} />
                }
                {importInProgress &&
                    <Stack sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        backgroundColor: 'var(--import-bg)',
                    }} direction="row" alignItems='center' justifyContent='center'>

                        <CircularProgressWithText text={openedby === 'campaign' ? "Importing contacts…" : "Importing data…"} />
                    </Stack>}
            </div>

            {/* right column */}
            {step !== 3 && importType !== 'whatsapp' && (
                <div className={classes.tudoImportIllustration}
                    style={{
                        backgroundColor: openedby === 'campaign' ?
                            'var(--campaign-import-ilustration)' : 'var(--tudo-import-ilustration)'
                    }}>
                    <div className={classes.illustrationBox}>
                        <div className={classes.excelIcon}  >
                            <img src={srcExcelImg} height="43px" alt="" />
                        </div>
                        <div style={{ transform: 'rotate(-45deg)' }}>
                            <TiArrowRightOutline size={30} color="#53bdeb" />
                        </div>
                        <div className={classes.tudoLogo}>
                            <img src={srcBTLogoImg} style={{ height: 40 }} alt="" />
                        </div>


                    </div>
                </div>
            )}
        </div>
    )
})

export default ImportExcelToBlueticks