import { AccountPage } from "@common-components/account-page/account-page";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { SideBarItem } from "@common-components/sidebar/sidebar";
import { activeSubscriptionTypeSelector } from "@common-reducers/UserSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import AdminBillingView from "@common-views/admin-billing/admin-billing";
import ChargebeeAdminPortal from "@common-views/chargebee-admin-portal/chargebee-admin-portal";

export const UnifiedAdminBilling = () => {

    const activeSubscriptionType = useAppSelector(activeSubscriptionTypeSelector) ?? null;    

    return (
        <BTThemeProvider>
            <AccountPage
                page={activeSubscriptionType === 'chargebee' ? <ChargebeeAdminPortal /> : <AdminBillingView />}
                active={SideBarItem.AdminBilling}
            ></AccountPage>
        </BTThemeProvider>
    );
}