import BTInputField from "@common-components/bt-input-field/bt-input-field";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge, IconButton, Menu, Stack } from "@mui/material";
import { recycleActions } from "@common-reducers/RecycleBinReducer";
import { fetchArchivedOrDeletedData } from "@common-reducers/TaskThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useRef, useState } from "react";
import { ArchivedOrDeletedFilterMenu } from "./archived-or-deleted-filter-menu";
import { setTimeout } from "timers/promises";
import { debounce } from "lodash";

export const ArchivedOrDeletedFilterRow = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.RecycleBinReducer.filters);
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);
    const archivedOrDeletedData = useAppSelector((state) => state.RecycleBinReducer.archivedOrDeletedData);

    const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);

    const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
        setFilterAnchor(event.currentTarget);
    };


    const closeFilterMenu = () => {
        setFilterAnchor(null);
    };

    const executeFilter = (text) => {
        dispatch(recycleActions.setArchivedOrDeletedData([]));
        dispatch(fetchArchivedOrDeletedData({
            mode: currentTab,
            itemId: "",
            itemUpdatedAt: "",
            searchQuery: text,
            ...filters,
            showLoader: true

        }));
    };

    const filterByText = debounce((text: string) => {
        dispatch(recycleActions.setSearchText(text));
        executeFilter(text);
    },1000)
    
    return (
        <Stack direction="row" alignItems={"center"} spacing={2} marginTop={3}>
            <BTInputField
                sx={{ width: "300px" }}
                type="text"
                onChange={({ target }: any) => {
                    filterByText(target.value);
                   
                }}
                placeholder={`Search ${currentTab === "Trash" ? "deleted" : "archived"} items...`} />
            <IconButton onClick={openFilterMenu}>
                <Badge
                    color="secondary"

                    badgeContent={filters.type.length + filters.board.length + (filters.date ? 1 : 0)}
                    invisible={filters.type.length === 0 && !filters.date && filters.board.length === 0}
                >

                    <FilterListIcon />
                </Badge>
            </IconButton>

            <Menu
                anchorEl={filterAnchor}
                open={Boolean(filterAnchor)}
                onClose={closeFilterMenu}
            >

                <ArchivedOrDeletedFilterMenu />

            </Menu>
            {archivedOrDeletedData.length} items

        </Stack>
    );
};

