import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { accessFormPopupActions } from "@common-reducers/AccessFormPopupReducer";
import { useGetPlanMapMutation } from "@common-reducers/backend-api/backend-api";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { activeSubscriptionSelector, selectUserObject } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { User } from "@common/models/user";
import { theme } from '@common/theme';
import PlanDescription from "../plan-description/plan-description";
import classes from './subscription-selection.module.scss';

export interface WSRouter {
    goTo: (generator: any) => void;
    routes: any;
}
export interface SubscriptionSelectionProps {
    router?: WSRouter;
    selectedPlan?: string;
}

export type BillingPeriod = 'year' | 'month';

export interface SubscriptionSelectionState {
    billingPeriod: BillingPeriod;
    loading: boolean;
    success: boolean;
    user?: User | null | undefined;
    loginOpen: boolean;
}

const SubscriptionSelection = ({ selectedPlan }: SubscriptionSelectionProps) => {
    const dispatch = useAppDispatch()
    const [billingPeriod, setBillingPeriod] = useState('annual');
    const analyticsService = useAnalyticsService();
    const user = useSelector(selectUserObject);
    const activeSubscription = useAppSelector(activeSubscriptionSelector);

    const [getPlanMap, { isLoading, data: planMap }] = useGetPlanMapMutation();

    useEffect(() => {
        getPlanMap();
    }, [])

    const billingPeriodChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: BillingPeriod) => {
        if (value != null) {
            setBillingPeriod(value);
        }
    }

    const price = useMemo(() => {
        let price: number | undefined;
        switch (selectedPlan) {
            case 'personal': {
                price = billingPeriod === 'annual' ? 3 : 5;
                break;
            }
            case 'professional': {
                price = billingPeriod === 'annual' ? 29 : 49;
                break;
            }
        }
        return price;
    }, [billingPeriod, selectedPlan]);

    const billedPrice = useMemo(() => {
        let price: number | undefined;
        switch (selectedPlan) {
            case 'personal': {
                price = billingPeriod === 'annual' ? 36 : 5;
                break;
            }
            case 'professional': {
                price = billingPeriod === 'annual' ? 348 : 49;
                break;
            }
        }
        return price;
    }, [billingPeriod, selectedPlan]);

    const openLoginDialog = () => {
        dispatch(accessFormPopupActions.setStatus('Login'));
    }

    const activeSubscriptionView = () => {
        return (
            <div className={classes.activeSubscriptionContainer}>
                <PlanDescription subscription={activeSubscription} />
            </div>
        )
    }

    const newSubscriptionView = () => {
        const planMapKey = `${selectedPlan}-${billingPeriod}`;
        analyticsService.event('view_item', {
            category: 'ecommerce',
            action: 'view_item',
            currency: 'USD',
            value: billedPrice,
            items: [{
                item_id: planMap[planMapKey].id,
                item_name: planMapKey,
                price: billedPrice,
            }]
        });
        return (<div className={classes.root}>
            <div className={[classes.row, classes.title].join(' ')}>Go Premium to unlock unlimited messages</div>
            <div className={[classes.row, classes.price].join(' ')} style={{ backgroundColor: theme.palette.primary.main }}>
                <span className={classes.priceTag}>${price}</span>
                <span>month</span>
            </div>
            <div className={classes.row}>
                <ToggleButtonGroup
                    value={billingPeriod}
                    exclusive
                    onChange={billingPeriodChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value="annual" aria-label="Annual">
                        Annual (Save 40%)
                    </ToggleButton>
                    <ToggleButton value="monthly" aria-label="Monthly">
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {user ?
                <div className={classes.paypalSubscribe}>
                    {/* { this.hotmartButton() } */}
                </div> :
                <Button
                    className="loginButton"
                    variant="contained"
                    onClick={openLoginDialog}
                    color="secondary">Login to buy
                </Button>
            }
            <div className={classes.support}>
                <img alt='' src="img/support-icon.png" />
                <span>Includes technical support!</span>
            </div>
        </div>);
    }


    return activeSubscription?.hasBulk ?
        activeSubscriptionView() :
        newSubscriptionView();
}

export default SubscriptionSelection;