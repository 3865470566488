import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Divider, IconButton, Popover, Stack, Tooltip } from "@mui/material";
import { DBTaskColumnThunks, taskColumnSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { memo, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

interface Props {
    values: any;
    columnId: string;
}
function NumberSumCell({ values, columnId }: Props) {
    const dispatch = useAppDispatch()
    const [customSymbolVal, setCustomSymbol] = useState<string | undefined>();

    const [column] = useAppSelector((state) => taskColumnSelectByQuery(state.DBTaskColumnReducer, { _id: columnId }))
    const customSymbol = column.customData?.customSymbol ? column.customData.symbol : undefined;
    const symbol = column.customData?.symbol ? column.customData.symbol : "";
    const func = column.customData?.func ? column.customData?.func : ""
    const direction = column.customData?.direction ? column.customData?.direction : undefined
    useEffect(() => {
        setCustomSymbol(customSymbol ?? undefined);
    }, [customSymbol]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const spanRef = React.useRef();
    function openMenu() {
        setAnchorEl(spanRef.current);
    }
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUnitSelected = (unit) => {

        dispatch(DBTaskColumnThunks.patch({
            entity: {
                _id: columnId,
                customData: { ...column.customData, symbol: unit, customSymbol: false }
            }
        }))

    }

    const onCustomUnit = (event) => {
        dispatch(DBTaskColumnThunks.patch({
            entity: {
                _id: columnId,
                customData: { ...column.customData, symbol: event.value ?? undefined, customSymbol: true }
            }
        }))
        setCustomSymbol(!!event.value ? event.value : undefined)
    }

    const setDirection = (direction) => {
        dispatch(DBTaskColumnThunks.patch({
            entity: {
                _id: columnId,
                customData: { ...column.customData, direction: direction }
            }
        }))
    }

    const onFuncSelected = (func) => {

        dispatch(DBTaskColumnThunks.patch({
            entity: {
                _id: columnId,
                customData: { ...column.customData, func: func }
            }
        }))

    }

    const handleCopyToClipboard = (event) => {
        event.stopPropagation(); 
        const textToCopy = total.toString();
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log("Copied to clipboard successfully!");
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    }

    let total = values.filter(v => !isNaN(v)).reduce((a, b) => a + b, 0);
    switch (column.customData?.func) {
        case 'none': {
            total = '-'
            break;
        }
        case 'sum': {
            total = values.filter(v => !isNaN(v)).reduce((a, b) => a + b, 0);
            break;
        }
        case 'average': {
            const sum = values.filter(v => !isNaN(v)).reduce((a, b) => a + b, 0);
            total = (sum / values.length) || 0;
            break;
        }
        case 'min': {
            const arr = values.filter(v => !isNaN(v))
            total = arr.length > 0 ? Math.min(...arr) : "-";
            break;
        }
        case 'max': {
            const arr = values.filter(v => !isNaN(v))
            total = arr.length > 0 ? Math.max(...arr) : "-";
            break;
        }
        case 'count': {
            const arr = values.filter(v => !isNaN(v))
            total = arr.length
            break;
        }

    }

    return (
        <Stack sx={{position:"relative"}} alignItems={"center"} flexGrow={1} textAlign={"center"}>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{
                    "& .MuiPaper-elevation": {
                        boxShadow: "0 5px 12px var(--shadow-default-color)",
                        borderRadius: "10px !important",
                        width: "350px"
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Stack p={2}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Box sx={{ fontSize: "12px" }} pb={1}>Unit</Box>
                        <Box sx={{ fontSize: "12px" }} pb={1}>Direction</Box>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack pb={2} sx={{ "& .MuiBox-root": { cursor: "pointer", background: "#fff", fontSize: "12px", color: "#000", height: "29px", display: "flex", justifyContent: "center", alignItems: "center", width: "30px" }, "& .MuiBox-root:first-of-type": { width: "40px" }, }} alignItems={"flex-start"} direction="row">
                            <Box sx={{ border: symbol === "" ? "1px solid #3f94ff" : "1px solid #ebdce2", width: "50px" }} onClick={() => onUnitSelected("")} >None
                            </Box>
                            <Box sx={{ border: symbol === "$" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: column.customData?.symbol === "$" ? "#3f94ff" : "#ebdce2" }} onClick={() => onUnitSelected("$")}>$</Box>
                            <Box sx={{
                                border: symbol === "€" ? "1px solid #3f94ff" : "1px solid #ebdce2",
                                color: column.customData?.symbol === "€" ? "#3f94ff" : "#ebdce2"
                            }} onClick={() => onUnitSelected("€")}>€</Box>
                            <Box sx={{
                                border: symbol === "£" ? "1px solid #3f94ff" : "1px solid #ebdce2",
                                color: column.customData?.symbol === "£" ? "#3f94ff" : "#ebdce2"
                            }} onClick={() => onUnitSelected("£")}>£</Box>


                            <Box sx={{ border: symbol === "%" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: column.customData?.symbol === "%" ? "#3f94ff" : "#ebdce2" }} onClick={() => onUnitSelected("%")}>%</Box>
                            <input style={{ border: column.customData?.customSymbol ? "1px solid #3f94ff" : "1px solid #ebdce2", height: "29px", maxWidth: "70px", borderRadius: "0" }} value={customSymbolVal ?? ""} placeholder="Custom" onChange={(e) => onCustomUnit(e.target)} />
                        </Stack>

                        <Stack direction={"row"} sx={{ "& .MuiBox-root": { cursor: "pointer", background: "#fff", fontSize: "12px", height: "29px", display: "flex", justifyContent: "center", alignItems: "center", width: "20px" } }}>
                            <Box sx={{ border: direction === "left" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: direction === "left" ? "#3f94ff" : "#000" }} onClick={() => setDirection('left')}>L</Box>
                            <Box sx={{ border: direction === "right" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: direction === "right" ? "#3f94ff" : "#000" }} onClick={() => setDirection('right')}>R</Box>
                        </Stack>
                    </Stack>
                    <Box sx={{ fontSize: "12px" }} pb={1}>Function</Box>
                    <Stack pb={1} sx={{ "& .MuiBox-root": { cursor: "pointer", background: "#fff", fontSize: "12px", height: "29px", display: "flex", justifyContent: "center", alignItems: "center", width: "60px" }, "& .MuiBox-root:first-of-type": { width: "50px" } }} alignItems={"flex-start"} direction="row">
                        <Box sx={{ border: func === "none" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "none" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('none')}>None</Box>
                        <Box sx={{ border: func === "sum" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "sum" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('sum')}>Sum</Box>
                        <Box sx={{ border: func === "average" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "average" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('average')}>Average</Box>
                        <Box sx={{ border: func === "min" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "min" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('min')}>Min</Box>
                        <Box sx={{ border: func === "nax" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "max" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('max')}>Max</Box>
                        <Box sx={{ border: func === "count" ? "1px solid #3f94ff" : "1px solid #ebdce2", color: func === "count" ? "#3f94ff" : "#000" }} onClick={() => onFuncSelected('count')}>Count</Box>
                    </Stack>
                    <Divider />
                    <Stack direction={"row"} pt={1}>   Overall <Box sx={{ px: 0.4 }}> {func !== 'none' ? func : "summary"} </Box> of column: <Box pl={0.4} sx={{ direction: direction === 'left' ? 'ltr' : 'rtl' }}>  <NumericFormat onClick={openMenu} displayType="text"
                        allowNegative
                        decimalSeparator={"."}
                        allowLeadingZeros
                        thousandSeparator=","
                        value={total}
                        decimalScale={3}
                        prefix={(func !== "count" && (direction === 'left' || direction === undefined)) ? symbol ?? "" : ""}
                        suffix={func !== "count" && direction === 'right' ? symbol ?? "" : ""}
                        style={{ width: "100%" }} /></Box>
                    </Stack>
                </Stack>

            </Popover>
            <Stack style={{ cursor: "pointer" }} ref={spanRef} onClick={openMenu}>
                <Tooltip title={`Copy total ${func}`} placement="right" arrow>
                    <IconButton
                        onClick={handleCopyToClipboard}
                        sx={{ position: 'absolute', top: "-5px", right: 0, fontSize: "12px" }}>
                        <ContentCopyIcon sx={{ fontSize: "12px" }} />
                    </IconButton>
                </Tooltip>
                <NumericFormat displayType="text"
                    allowNegative
                    decimalSeparator={"."}
                    allowLeadingZeros
                    thousandSeparator=","
                    value={total}
                    decimalScale={3}
                    prefix={(func !== "count" && (direction === 'left' || direction === undefined)) ? symbol ?? "" : ""}
                    suffix={func !== "count" && direction === 'right' ? symbol ?? "" : ""}
                    style={{ width: "100%" }} />
                <Box sx={{ fontSize: "11px" }}>{func ?? "sum"}</Box>
            </Stack>

        </Stack>
    );
}

export default memo(NumberSumCell)