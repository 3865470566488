
import { List, ListItem, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
interface BTSelectButtonProps {
  // children: React.ReactNode;
  sx?: any;
  onClick?: (index) => void;
  btvariant?: 'primary' | 'secondary' | 'text';
  options?: any[];
  icon?: string;
  selected?:string;
}

function BTSelectButton(props: BTSelectButtonProps) {
  const options = props.options;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    props.onClick(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // switch (props.btvariant) {
  //   case 'secondary': {
  //     buttonTheme = {
  //       cursor: "pointer",
  //       margin: "0",
  //       textDecoration: "none",
  //       borderRadius: "8px",
  //       backgroundColor: "var(--billing-btn-bg)",
  //       color: "var(--link-text-color)",
  //       fontSize: "12px",
  //       fontWeight: "600",
  //       lineHeight: "16px",
  //       padding: "6px",
  //       textAlign: "center",
  //       textTransform: "capitalize",
  //       maxWidth: "80%",
  //       fontFamily: "'Poppins', 'Inter', sans-serif",
  //     };
  //     break;
  //   }
  //   case 'text': {
  //     buttonTheme = {
  //       cursor: "pointer",
  //       margin: "0",
  //       textDecoration: "none",
  //       borderRadius: "8px",
  //       color: "var(--link-text-color)",
  //       fontSize: "12px",
  //       fontWeight: "600",
  //       lineHeight: "16px",
  //       padding: "6px",
  //       textAlign: "center",
  //       textTransform: "capitalize",
  //       maxWidth: "80%",
  //       background: 'transparent',
  //       fontFamily: "'Poppins', 'Inter', sans-serif",
  //     };
  //     break;
  //   }
  //   case 'primary':
  //   default: {
  //     buttonTheme = {
  //       "&:disabled": {
  //         opacity: '0.8',
  //         color: "#fff"
  //       },
  //       "& .MuiButton-startIcon": {
  //         display: props.loading && "none",
  //       },
  //       "& .MuiLoadingButton-loadingIndicator": {
  //         // left: props.startIcon ? '30%' : "8%",
  //         position: "inherit",
  //         left: "unset",
  //         marginRight: "8px",
  //         color: "#fff"
  //       },
  //       transition: '0.2s',
  //       color: "#ffffff",
  //       fontWeight: "600",
  //       lineHeight: "21px",
  //       borderRadius: "8px",
  //       padding: "7.5px 28px",
  //       background: "linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)",
  //       whiteSpace: 'nowrap',
  //       textTransform: 'capitalize',
  //       fontFamily: "'Poppins', 'Inter', sans-serif",
  //       ...props.sx,
  //     };
  //     break;
  //   }
  // }
  const selectedItem = options.find(opt=> props.selected === opt.funcIndicator)
  const indexSelected = props.options.findIndex((value)=> selectedItem?.text === value )


  return (
    <div >
      <List

        component="nav"
        aria-label="Device settings"
        sx={{cursor:'pointer', bgcolor: 'background.paper', padding: '12px 8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px', maxHeight: '45px', boxSizing: 'border-box', margin: '0 10px' }}
      >
        <img style={{ margin:'0 5px 0 0'}} src={props?.icon ?? props.options[selectedIndex]?.icon} />
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          // aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={selectedItem?.text}
    
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (


          <MenuItem
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '17px',


            }}
            key={option[index]}
            // disabled={index === 0}
            selected={index === indexSelected }
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <img style={{ margin:'0 5px 0 0'}} src={option?.icon} />

            {option?.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
export default BTSelectButton;
