import BTButton from '@common-components/bt-button/bt-button';
import { CircularProgress } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { importItemsActions } from '@common-reducers/ImportItemsReducer';
import { getRuntimeUrl } from '@common-services/storage';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import FileSaver from 'file-saver';
import classes from "./create-google-sheets-audience.module.scss";


type Props = {
    goNext(isFromSheets?: boolean): void;
}

export default function CreateGoogleSheetsAudience({
    goNext,
}: Props) {
    const dispatch = useAppDispatch()

    const openedby = useAppSelector((state) => state.ModalReducer.openedby)

    const [link, setLink] = useState('');
    const [sheets, setSheets] = useState([]);
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [isSheetLoading, setIsSheetLoading] = useState(false)
    const [isUnableToLoadSheet, setIsUnableToLoadSheet] = useState(false)

    const API_KEY = 'AIzaSyCCO4W0WCNM3srGW1c6guCGuj7jzBemi3I';


    function trimEmptyRowsAndColumns(spreadsheet) {
        if (!spreadsheet.length) return [];

        const trimmedSheet = spreadsheet.filter(row => row?.length && row?.length !== 0)
        let count = 0;

        for (let cell of trimmedSheet?.[0]) {
            if (cell === '' || cell === null) {
                count++;
            } else {
                break;
            }
        }

        return trimmedSheet.map(row => row.slice(count))
    }

    const fetchSheetNames = async (sheetId) => {
        try {
            setIsSheetLoading(true)
            const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?key=${API_KEY}`);
            const data = await response.json();
            setSheets(data.sheets.map(sheet => sheet.properties.title));
            setName(data?.properties?.title)
            if (data.sheets.length === 1) {
                fetchSheetData(link.match(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/)[1], data.sheets[0].properties.title)
            }
            setIsSheetLoading(false)
        } catch (error) {
            console.error('Error fetching sheet names:', error);
            setIsSheetLoading(false)
            setIsUnableToLoadSheet(true)
        }
    };


    const fetchSheetData = async (sheetId, sheetName) => {
        try {
            setIsSheetLoading(true)
            const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${API_KEY}`);
            const data = await response.json();
            setData(data.values || []);

            const _data = trimEmptyRowsAndColumns(data.values)
            const __data = _data.slice(1).map(row => {
                let rowData = {}
                for (let i = 0; i < row.length; i++) {
                    rowData = {
                        ...rowData,
                        [_data[0][i]]: row[i],
                    }
                }
                return rowData
            })
            await dispatch(importItemsActions.setGoogleSheetsImportProps({
                importFileName: name === '' ? sheetName : name,
                workSheetName: sheetName,
                importJsonData: __data,
            }))
            setIsSheetLoading(false)
            goNext(true)
        } catch (error) {
            console.error('Error fetching sheet data:', error);
            setIsSheetLoading(false)
            setIsUnableToLoadSheet(true)
        }
    };

    const handleSubmit = () => {
        const matches = link.match(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/);
        if (matches && matches[1]) {
            fetchSheetNames(matches[1]);
        }
    };


    const downloadFile = () => {
        if (openedby === 'campaign') {
            const src = getRuntimeUrl('img/sample.csv');
            FileSaver.saveAs(src, 'sample.csv');
        } else {
            const link = document.createElement('a');
            link.href = '/img/sample.csv';
            link.download = 'sample.csv';
            link.click();
        }
    };


    return (
        <Stack direction='column' sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
        }}>
            {!isSheetLoading && isUnableToLoadSheet &&
                <>
                    <span style={{
                        marginTop: '70px',
                        fontWeight: 'bold',
                        fontSize: '22px',
                        marginBottom: '25px',
                        display: 'block',
                        textAlign: 'center',
                    }}>
                        Unable to fetch from Google Sheets.<br /> Please check the following:
                    </span>
                    <ul>
                        <li style={{ marginBottom: '25px', }}>Your file's share settings are set to public- 'Anyone with the link'</li>
                        {/* <li>Your file format is correct- you can <span style={{ color: '#1f76c2' }} onClick={(e) => {

                            downloadFile()
                            e.stopPropagation()
                        }
                        }>Download</span> a sample excel file</li> */}
                    </ul>
                    <BTButton
                        sx={{
                            marginTop: '20px',
                            padding: "8px",
                            width: '250px',
                        }}
                        onClick={() => setIsUnableToLoadSheet(false)}>
                        I understand
                    </BTButton>
                </>
            }
            {!isSheetLoading && !isUnableToLoadSheet && sheets.length === 0 &&
                <Stack direction='column' sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '10px',
                }}>
                    <Box>
                        <input
                            style={{ color: 'var(--primary)', width: '450px', textAlign: 'center', backgroundColor: 'var(--search-input-background)' }}
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            placeholder="Enter Google Sheet share link"
                        />
                    </Box>
                    <BTButton
                        sx={{
                            marginTop: '20px',
                            padding: "8px",
                            width: '250px',
                        }}
                        onClick={handleSubmit}>
                        Fetch Sheets
                    </BTButton>
                </Stack>
            }
            {!isSheetLoading && !isUnableToLoadSheet && sheets.length > 1 && data.length === 0 &&
                <Stack direction='column' sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}>
                    <span style={{ marginTop: '345x' }}>Select From Which Sheet To Extract Your Audience</span>
                    <Stack className={classes.scrollContainer}>
                        {sheets.map(sheetName => {
                            return (
                                <BTButton
                                    sx={{
                                        marginTop: '20px',
                                        mx: '15px',
                                        padding: "8px",
                                        width: "250px",
                                        display: "flex",      // Ensure the button content is flex to align items
                                        alignItems: "center", // Center align items vertically
                                        justifyContent: "center" // Center content horizontally
                                    }}
                                    key={sheetName}
                                    onClick={() => {
                                        fetchSheetData(link.match(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/)[1], sheetName)
                                    }}>
                                    <span style={{
                                        maxWidth: "100%",   // Set a max-width to span to ensure it respects button's width
                                        overflow: "hidden", // Hide overflow
                                        textOverflow: "ellipsis", // Show an ellipsis for overflowed text
                                        whiteSpace: "nowrap", // Keep text in a single line
                                        display: "inline-block" // Make the span inline-block to apply width and overflow styles
                                    }}>
                                        {sheetName}
                                    </span>
                                </BTButton>
                            );
                        })}
                    </Stack>
                </Stack>
            }
            {isSheetLoading &&
                <Box sx={{ position: 'relative', top: '50%', left: '50%,' }}>
                    <CircularProgress />
                </Box>
            }
        </Stack>


    )
}