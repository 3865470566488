import { Box, Popover } from "@mui/material";
import { Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import { Scheduler } from "../dropdowns/every-time-period";

const EveryTimePeriod = () => {
    const dispatch = useAppDispatch()
    const [buttonName, setButtonName] = useState<string>('Every time period');
    const [anchorEl, setAnchorEl] = useState(null);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);

    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);

    const handleRRuleChange = (rrule: string, nextDueDate: Date) => {
        if (anchorEl === null) return;
        console.log("RRule string: ", rrule);
        console.log("Next due date: ", nextDueDate);
        let triggerDetails = {
            ...triggerObj,
            triggerType: "every-time-period",
            isCron: true,
            rruleset: rrule,
            nextTrigger: nextDueDate.toISOString()
        }
        dispatch(automation.setTrigger(triggerDetails));
    };

    const handleDescChange = (translatedToTextString: string) => {
        setButtonName(translatedToTextString)
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = 'period-popover';
    useEffect(() => {
        dispatch(automation.fieldValidityChanged({ component: 'EveryTimePeriod', index: 0, field: 'rruleset', isValid: !!triggerObj?.rruleset }));
    }, [triggerObj, dispatch]);

    return (
        <Stack direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
            <Box>
                <AutomationButton
                    componentName='EveryTimePeriod'
                    index={0}
                    option='rruleset'
                    label={buttonName}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <Popover
                sx={{ display: open ? "block" : "none", "& .MuiPaper-root": { maxWidth: "400px" } }}
                id={id}
                open={true}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <Scheduler setRuleDescription={handleDescChange} onRRuleChange={handleRRuleChange} trigger={triggerObj} />
            </Popover>

            <AddAndRemove elements={conditions} componentName="EveryTimePeriod" index={0} type={"trigger"} />
        </Stack>
    )
}


export default EveryTimePeriod;
