import BTThemeProvider from "@common-components/bt-theme-provider";
import ConditionalWrapper from "@common-components/conditional-wrapper/ConditionalWrapper";
import ImagePreviewer from "@common-components/image-previewer/image-previewer";
import ReusableModal from "@common-components/modals/reusable-modal";
import TaskUpdateModal from "@common-components/tasks-panel/kanban/kanban-card";
import MyWorkView from "@common-components/tasks-panel/mywork/my-work-view";
import OpenWaBoard from "@common-components/tasks-panel/openWaBoard";
import BoardsLoader from "@common-components/tasks-panel/recents-boards/boards-loader";
import RecentsBoards from "@common-components/tasks-panel/recents-boards/recents-boards";
import TasksPanel from "@common-components/tasks-panel/tasks-panel";
import PickerDialog from "@common-components/tasks/picker-dialog/picker-dialog-box";
import DeletedToast from "@common-components/toasts/deleted-toast";
import { appConfig } from "@common-config/app.config";
import { namespace } from "@common-config/constants";
import { boardsActions } from "@common-reducers/BoardsReducer";
import { currentBoardExistsSelector, getBlueticksThemeSelector, getIsLoadingBoards, getSelectedBoardId, isBoardPanelDrawerOpen } from "@common-reducers/BoardsSelectors";
import { DBUserThunks, isTempId } from "@common-reducers/DBServiceThunks";
import { MODAL, modalActions } from "@common-reducers/ModalReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { showUndoToastSelector } from "@common-reducers/UndoRedoThunks";
import { activeSubscriptionSelector, didUserHaveRealSubscription, didUserHaveTrialSubscription, isUserWorkSpaceOwner, selectUser } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { selectUserId } from "@common/reducers/UserSelectors";
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRect } from 'react-use-rect';
import BoardsPanel from "../boards-panel/BoardsPanel";
import TasksHeader from "./TasksHeader";
import DeletedBoardView from "./deleted-board-view";
import classes from "./task-view.module.scss";


type Props = {
  isDisplayTasksHeader?: boolean;
  isDisplayTabs?: boolean;
  isDisplayTasksPanelHeader?: boolean;
  isDisplayNewGroupButton?: boolean;
  isDisplaySearchField?: boolean;
  isDisplayPersonButton?: boolean;
  isDisplayColumnsButton?: boolean;
  isDisplayImportButton?: boolean;
  isDisplayExportButton?: boolean;
  isDisplayBoardNameAndIcon?: boolean;
  isDisplaySharedUsersAvatar?: boolean;
  isDisplayShareButton?: boolean;
  isDisplayMenuButton?: boolean;
  isDisplayDarkBrightModeButton?: boolean;
  isDisplayBackToCampaignButton?: boolean;
  isDisplayActivityButton?: boolean;
  isDisplayNotificationsButton?: boolean
  setIsBoardView?: React.Dispatch<React.SetStateAction<boolean>>;
  isDisplayBoardPanel?: boolean;
  isDisplayUpgradeButton?: boolean;
  isFromCampaign?: boolean;
  height?: Number;
  taskViewTheme?: string;
}

export default function TaskView({
  isDisplayTasksHeader = true,
  isDisplayTabs = true,
  isDisplayTasksPanelHeader = true,
  isDisplayNewGroupButton = true,
  isDisplaySearchField = true,
  isDisplayPersonButton = true,
  isDisplayColumnsButton = true,
  isDisplayImportButton = false,
  isDisplayExportButton = true,
  isDisplayBoardNameAndIcon = true,
  isDisplaySharedUsersAvatar = true,
  isDisplayShareButton = true,
  isDisplayMenuButton = true,
  isDisplayDarkBrightModeButton = true,
  isDisplayBackToCampaignButton = false,
  isDisplayActivityButton = true,
  isDisplayNotificationsButton = true,
  setIsBoardView,
  isDisplayBoardPanel = true,
  isDisplayUpgradeButton = true,
  isFromCampaign = false,
  height,
  taskViewTheme,
}: Props): ReactElement {
  console.log("TaskView rendering");
  const { t } = useTranslation(namespace);
  const isWorkSpaceOwner = useAppSelector(isUserWorkSpaceOwner);
  const showUndoToast = useAppSelector(showUndoToastSelector);
  const isSubscriptionLoaded = useAppSelector((state) => state.DBSubscriptionReducer.itemsLoaded)

  const dispatch = useAppDispatch()
  const [taskViewContainerRef] = useRect((rect) => {
    if (rect) {
      dispatch(taskActions.setTaskViewContainerOffset({
        left: rect.left,
        top: rect.top
      }))
    }
  });
  const [isIntroEnabled, setIntro] = useState<boolean>(true)

  const steps = [
    {
      element: '#new-group-button',
      intro: 'Welcome, we created the first board for you, Here you can create your group',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '#new-board',
      intro: 'Here you can create your boards',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '#step-2',
      intro: 'Invite a team member so you can plan your tasks together.',
      position: 'top',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
  ];
  const onIntroExit = () => {
    setIntro(false)
  }

  const selectedBoardId = useAppSelector(getSelectedBoardId);
  const isToggleableNavOpen = useAppSelector((state) => state.MainReducer.isToggleableNavOpen);
  const isBoardsLoading = useAppSelector(getIsLoadingBoards);
  const currentBoardExists = useAppSelector(currentBoardExistsSelector);
  const deletedBoardName = useAppSelector(state => state.TasksReducer.deletedBoardName);
  const viewMode = useAppSelector(state => state.TasksReducer.viewMode);
  const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
  const theme = useAppSelector((state) => getBlueticksThemeSelector(state))
  const activeSubscription = useAppSelector((state) => activeSubscriptionSelector(state))
  const userHadTrial = useAppSelector((state) => didUserHaveTrialSubscription(state))
  const userHaveRealSubscription = useAppSelector((state) => didUserHaveRealSubscription(state))


  const isThisExtensionContext = isExtensionContextOrWhatsappView();
  const isTaskModalOpen = useAppSelector((state) => state.TasksReducer.isTaskModalOpen);
  const creatingFirstBoardStatus = useAppSelector((state) => state.TasksReducer.creatingFirstBoardStatus);
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const userId = useAppSelector(selectUserId)
  const user = useAppSelector(selectUser)
  function checkAndShowEmailConfirmationModal() {
    if (user && !user.isEmailConfirmed) {
      const lastModalCloseDateStr = localStorage.getItem('modal_email_confirm');
      const today = new Date();
      let shouldShowModal = true;
      if (lastModalCloseDateStr) {
        const lastModalCloseDate = new Date(lastModalCloseDateStr);
        const differenceInDays = (today.getTime() - lastModalCloseDate.getTime()) / (1000 * 60 * 60 * 24);
        shouldShowModal = differenceInDays >= 10;
      }

      if (user.createdAt) {
        const userCreationDate = new Date(user.createdAt.toString());
        const differenceInDaysSinceCreation = (today.getTime() - userCreationDate.getTime()) / (1000 * 60 * 60 * 24);
        shouldShowModal = shouldShowModal && differenceInDaysSinceCreation >= 5;
      }
      if (shouldShowModal) {
        dispatch(modalActions.setComponentToRender(MODAL.EMAIL_CONFIRMATION));
        localStorage.setItem('modal_email_confirm', today.toISOString());
      }
    }
  }

  const shouldShowTrialModal = () => {
    console.log("------------------------------------")
    console.log("isSubscriptionLoaded:", isSubscriptionLoaded)
    console.log("activeSubscription:", activeSubscription)
    console.log("isWorkSpaceOwner:", isWorkSpaceOwner)
    console.log("userHadTrial:", userHadTrial)
    console.log("userHaveRealSubscription:", userHaveRealSubscription)
    console.log("------------------------------------")




    if (isSubscriptionLoaded && !activeSubscription && isWorkSpaceOwner && !userHadTrial && !userHaveRealSubscription) {
      dispatch(modalActions.setComponentToRender(MODAL.TRIAL_START));
    }
  }


  // const shouldContactWorkSpaceAdminShow = () => {
  //   if (!isWorkSpaceOwner && userHadTrial){
  //     dispatch(modalActions.setComponentToRender(MODAL.CONTACT_WORKSPACE_ADMIN));
  //   }
  // }

  // temporary disabled
  /*
  const location =  useLocation(); 

  useEffect(() => {
    if (location.pathname === '/mywork' && viewMode === 'tasks') {
      dispatch(taskActions.setViewMode('mywork'))
    }
  }, [dispatch, location.pathname,viewMode]);
  */


  useEffect(() => {
    if (taskViewTheme && taskViewTheme === "lean") {
      dispatch(boardsActions.setBoardTheme('lean'))
    }
    else {
      dispatch(boardsActions.setBoardTheme(''))
    }

    if (appConfig.asset_key === 'tudoboard') {
      checkAndShowEmailConfirmationModal();
    }

  }, [])

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (appConfig.asset_key === 'tudoboard') {
        console.log("checking for trial modal")
        shouldShowTrialModal();
        checkAndShowEmailConfirmationModal();
        //shouldContactWorkSpaceAdminShow();
      }

    }, 3000);

    return () => clearTimeout(timerId); //  Clear timeout on effect cleanup to prevent memory leaks -- creating DEBOUNCE
  }, [activeSubscription, isWorkSpaceOwner, isSubscriptionLoaded])




  // useEffect(() => {
  //   shouldShowTrialModal();
  // }, [isWorkSpaceOwner, activeSubscription])

  return (
    <BTThemeProvider>

      <Box id="task-view" className={classes.wrapper} sx={{
        direction: direction === 'ltr' ? 'ltr' : "rtl", borderRadius: height ? "15px" : ""
      }}>
        <ConditionalWrapper
          shouldLoadSelector={(state) => state.ExtraDataReducer.imagePreview?.isActive}>
          <ImagePreviewer />
        </ConditionalWrapper>


        {!isExtensionContextOrWhatsappView() &&
          <>
            <div
              id="boardsPanel"
              className={
                isToggleableNavOpen
                  ? classes.boardsPanelOpen
                  : classes.boardsPanelClosed
              }>
              {isDisplayBoardPanel && <BoardsPanel />}
              <IconButton
                edge="start"
                onClick={() => dispatch(DBUserThunks.patch({
                  entity: {
                    _id: userId,
                    isBoardsPanelOpen: !drawerIsOpen,
                  }
                }))}
                sx={{ m: 0, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </div>

            {!selectedBoardId && !deletedBoardName && isBoardsLoading && creatingFirstBoardStatus === "" && viewMode === "tasks" && (

              <BoardsLoader />

            )}
            {creatingFirstBoardStatus === "creating" &&
              <BoardsLoader loadingMessage={t('taskView.creatingBoard')} />
            }

            {!selectedBoardId && !deletedBoardName && !isBoardsLoading && viewMode === "tasks" && !creatingFirstBoardStatus && (
              <>
                <RecentsBoards />
                <PickerDialog boardId={""} />

              </>
            )}

          </>
        }


        {/* <PendingInvitesSnackbar/> */}
        {(
          (isExtensionContextOrWhatsappView() && !isBoardsLoading && creatingFirstBoardStatus === "" && viewMode === "tasks") ||
          (!isExtensionContextOrWhatsappView() && !isBoardsLoading && creatingFirstBoardStatus === "" && selectedBoardId && viewMode === "tasks" && !deletedBoardName)
        ) &&
          <div id="task-view-container" ref={taskViewContainerRef} className={classes.tasksContainer}
            style={{
              width: isToggleableNavOpen ? 'calc(100% - 300px)' : 'calc(100% - 80px)',
              position: 'relative',
              backgroundColor: theme?.boards?.tasksPanel?.background,
              borderRadius: height ? "15px" : ""
            }}
          >
            {showUndoToast && <DeletedToast />}
            {selectedBoardId && !isTempId(selectedBoardId) && currentBoardExists && <>
              {
                <TasksHeader
                  isDisplayTasksHeader={isDisplayTasksHeader}
                  isDisplayBoardNameAndIcon={isDisplayBoardNameAndIcon}
                  isDisplaySharedUsersAvatar={isDisplaySharedUsersAvatar}
                  isDisplayShareButton={isDisplayShareButton}
                  isDisplayMenuButton={isDisplayMenuButton}
                  isDisplayDarkBrightModeButton={isDisplayDarkBrightModeButton}
                  isDisplayUpgradeButton={isDisplayUpgradeButton}
                  isDisplayActivityButton={isDisplayActivityButton}
                  isDisplayNotificationsButton={isDisplayNotificationsButton}
                />
              }
              <TasksPanel
                isDisplayTabs={isDisplayTabs}
                isDisplayTasksPanelHeader={isDisplayTasksPanelHeader}
                isDisplayNewGroupButton={isDisplayNewGroupButton}
                isDisplaySearchField={isDisplaySearchField}
                isDisplayPersonButton={isDisplayPersonButton}
                isDisplayColumnsButton={isDisplayColumnsButton}
                isDisplayImportButton={isDisplayImportButton}
                isDisplayExportButton={isDisplayExportButton}
                isFromCampaign={isFromCampaign}
                height={height}
              />


            </>}


            {(!selectedBoardId || isTempId(selectedBoardId)) && isThisExtensionContext && <OpenWaBoard />}
          </div>
        }

        {deletedBoardName && !isThisExtensionContext &&
          (
            <>
              {showUndoToast && <DeletedToast />}

              <DeletedBoardView />
            </>
          )}

        {viewMode === 'mywork' &&
          <>
            <MyWorkView />
            <PickerDialog boardId={""} />
          </>

        }
        {isTaskModalOpen &&
          <TaskUpdateModal />

        }
        <ReusableModal />

      </Box>
    </BTThemeProvider>
  );
}
