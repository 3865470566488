import { CellProps } from '@common-components/tasks/columns-cells/cell-interface'
import { TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { namespace } from '@common/config/constants'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RiCloseCircleFill } from 'react-icons/ri'

export default function DateFormCell({
    cellValue,
    taskId,
    columnId,
    groupId,
    onUpdate,
    isFormInEditMode
}: CellProps) {
    const { t } = useTranslation(namespace);
    const [value, setValue] = React.useState<Dayjs | null>(null);

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
        onUpdate(newValue)
    };

    function handleClearValue(e) {
        e.stopPropagation()
        setValue(null)
        onUpdate(undefined);
    }

    return (
        <Stack direction='row' sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={3}>
                    <DesktopDatePicker
                        disabled={isFormInEditMode ? true : false}
                        label={t('dateFormCell.selectDate')}
                        format="MM/DD/YYYY"
                        value={value}
                        onChange={handleChange}
                        slots={(params) => <TextField {...params} />}
                        />
                </Stack>
            </LocalizationProvider>
            {cellValue && (
                <RiCloseCircleFill
                    onClick={(e) => handleClearValue(e)}
                // className={classes.clearIcon}
                />
            )}
        </Stack>
    )
}