import { CommonRootState, AppThunkDispatch } from "@common/types/common-root-state-type";

import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";



export const useAppThunkDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<CommonRootState> = (selector) => useSelector(selector, isEqual);

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
