import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import moment from "moment";
// import { AnalyticsService } from "@common/services/analytics-service";
import { Poll } from "@common-components/message-editor/message-editor";
import { User } from "@common-models/user";
import { UserMessage } from "@common-models/user-message";
import { MessageState } from "@common/types/interface";
import { Chat } from "@wa-communication-layer/whatsapp";

export interface AddScheduledMessageButtonState {
  showErrorDialog: boolean;
  open: boolean;
  message: UserMessage;
  isEmojiPicker: boolean;
  user?: User | null | undefined;
  currentChat?: Chat;
  showConfirmationDialog?: boolean;
  originalMessage?: UserMessage;
  isWaSocketsLimitReached: boolean;
  pollFromWAModal?: Poll;
}

const initialState: AddScheduledMessageButtonState = {
  showErrorDialog: false,
  open: false,
  message: {
    dueDate: undefined,
    message: "",
    isRecurring: false,
    state: MessageState.pending,
    cancelIfReceived: false,
  },
  isEmojiPicker: false,
  showConfirmationDialog: false,
  originalMessage: undefined,
  isWaSocketsLimitReached: null,
  pollFromWAModal: null
};


const AddScheduledMessageButtonSlice = createSlice({
  name: "AddScheduledMessageButtonSlice",
  initialState,
  reducers: {
    editMessage: (state, action: PayloadAction<UserMessage>) => {
      const message = { ...action.payload };
      if (!state.open) {
        message.dueDate = moment(message.dueDate).toDate().toISOString();
        message.state = MessageState.editing;
        state.open = true;
        state.message = message;
      }
    },
    updateMessage: (state, action: PayloadAction<UserMessage>) => {
      state.message = action.payload;
    },
    closeDialog: (state, action: PayloadAction<void>) => {
      return initialState;
    },
    setIsEmojiPicker: (state, action: PayloadAction<boolean>) => {
      state.isEmojiPicker = action.payload;
    },
    editCalendarMessage: (state, action: PayloadAction<UserMessage>) => {
      const message = { ...action.payload };
      message.state = MessageState.editing;
      state.open = true;
      state.message = message;
      state.originalMessage = { ...message }
    },
    setConfirmationDialog: (state, action: PayloadAction<boolean>) => {
      state.showConfirmationDialog = action.payload;
    },
    setIsSocketsLimitReached: (state, action: PayloadAction<boolean>) => {
      state.isWaSocketsLimitReached = action.payload;
    },
    setPollFromWAModal: (state, action: PayloadAction<Poll>) => {
      state.pollFromWAModal = action.payload;
    },
  },
});

const selectState = (state) => state.AddScheduledMessageButtonReducer
export const selectIsOpen = createSelector(selectState, (s: AddScheduledMessageButtonState) => s.open);


export const addScheduledMessageButtonActions =
  AddScheduledMessageButtonSlice.actions;
export default AddScheduledMessageButtonSlice.reducer;
