import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import { GetProfilePicResponse, Picture, ProfilePicState, ProfilePicThumbEvent } from "@wa-communication-layer/whatsapp";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import axios from "axios";
import { backendApi } from "./backend-api/backend-api";

const initialState: ProfilePicState = {
  profilePicMap: {},
};

export const getProfilePicThunk = createAsyncThunk<
  GetProfilePicResponse,
  string,
  { state: CommonRootState }
>("getProfilePicThunk", async (whatsappId: string, thunkApi) => {
  const state = thunkApi.getState();

  let publicUrl;
  let uploadUrl;
  let profilePicThumb: Picture;
  if (!state.ProfilePicReducer.profilePicMap[whatsappId]) {
    thunkApi.dispatch(
      updateProfilePic({
        id: { _serialized: whatsappId },
        profilePicThumb: {},
      })
    );
    console.log(`getProfilePicThunk ${whatsappId} - started`);
    profilePicThumb = await ContentMessageServiceActions.getProfilePicThumb(whatsappId);
    console.log(`getProfilePicThunk ${whatsappId} - finished`);

    thunkApi.dispatch(
      updateProfilePic({
        id: { _serialized: whatsappId },
        profilePicThumb,
      })
    );

    if (profilePicThumb?.img) {
      const uploadUrlRes = await thunkApi.dispatch(backendApi.endpoints.getSignedUploadUrl.initiate(
        'image/jpeg'
      ));
      console.log(uploadUrlRes);
      if (!('error' in uploadUrlRes)) {
        const data = uploadUrlRes.data;
        uploadUrl = data.url;
        publicUrl = data.publicUrl;
      }
      if (uploadUrl) {
        const res = await fetch(profilePicThumb?.img);
        const blob = await res.blob();

        await axios({
          method: "PUT",
          url: uploadUrl,
          data: blob,
          headers: {
            "Content-Type": 'image/jpeg',
          },
        });
      }
    }
  } else {
    profilePicThumb = state.ProfilePicReducer.profilePicMap[whatsappId];
  }
  const response = {
    profilePic: publicUrl ?? profilePicThumb?.profilePicUrl ?? profilePicThumb?.img,
    waId: whatsappId,
  };
  console.log(`getProfilePicThunk finisehd for user: ${whatsappId}`);
  return response;
});

export const sliceName = createSlice({
  name: "ProfilePicReducer",
  initialState,
  reducers: {
    updateProfilePic: (state, action: PayloadAction<ProfilePicThumbEvent>) => {
      if (action.payload.id?._serialized && action.payload.profilePicThumb) {
        state.profilePicMap[action.payload.id?._serialized] =
          action.payload.profilePicThumb;
      }
    },
  },
});
export interface ProfilePicThumbResponse {
  profilePicThumb: Picture;
}

export const { updateProfilePic } = sliceName.actions;

export default sliceName.reducer;
