import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Menu, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";

import { getAssignedUserListToTasks, getPersonById, getSelectedPersons } from "@common-reducers/TaskSelectors";
import { taskActions } from "@common-reducers/TasksReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { MemberListFilter } from "../memberlist-filter/memberlist-filter";
import classes from "./person_button_filter.module.scss";


export default function PersonButtonFilter() {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();
  const usersMenuRef = React.useRef();
  const currentAssignedMemberList = useAppSelector(getAssignedUserListToTasks);
  const personUserId = useAppSelector((state) => getSelectedPersons(state));
  const person = useAppSelector(getPersonById);
  const [isUserListShown, togglePersonList] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(usersMenuRef.current);
    togglePersonList(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    togglePersonList(false);
  };

  function clearPersonFilter(e) {
    e.stopPropagation();
    dispatch(taskActions.setPersonFilter(""));
    handleClose();
  }

  const endIconTheme = {
    color: "var(--secondary-text-color)",
    "&.MuiSvgIcon-fontSizeSmall": {
      fontSize: "14px",
    },
    marginInlineStart: "8px",
  };
  return (
    <div className={classes.personButtonFilterContainer}>
  
        <Stack direction={"row"} ref={usersMenuRef}>
        <Tooltip title={t('personButtonFilter.tooltipTitle')} arrow placement="top">
          <Button
            sx={{
              background: personUserId
                ? "var(--default-background)"
                : "transparent",
              ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' }
            }}
            startIcon={
              personUserId ? (
                <PersonAvatar
                  size={16}
                  email={person?.email}
                  profileImg={person?.profile?.profileImg}
                  lastName={person?.profile?.lastName}
                  firstName={person?.profile?.firstName}
                />
              ) : (
                <FaUserCircle style={{ fontSize: "14px", marginInlineEnd: "4px" }} />
              )
             
            }
            onClick={handleClick}
            className={classes.button_col}
            endIcon={
              personUserId ? (
                <CancelIcon
                  onClick={(e) => clearPersonFilter(e)}
                  fontSize="small"
                  sx={endIconTheme}
                  color="action"
                />
              ) : (
                ""
              )
            }
          >
            {t('personButtonFilter.personButton')}
          </Button>
          </Tooltip>
        </Stack>

        {isUserListShown && (
          <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={
                currentAssignedMemberList.length > 0 ? "start" : "center"
              }
              sx={{ fontSize: 12, padding: "0 5px", width: "250px" }}
            >
              {currentAssignedMemberList.length > 0 ? (
                currentAssignedMemberList?.map((person, i) => {
                  return <MemberListFilter key={i} person={person} />;
                })
              ) : (
                <Box>{t('personButtonFilter.noAssignedMembers')}</Box>
              )}
            </Stack>
          </Menu>
        )}
  
    </div>
  );
}
