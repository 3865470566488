import { Autocomplete, Stack, Chip, TextField, Accordion, AccordionDetails, AccordionSummary, Typography, Tooltip } from '@mui/material';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DBMyWorksThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { getActiveWorkSpaceUsers } from '@common-reducers/BoardsSelectors';
import { fetchMyWorkThunk } from '@common-reducers/MyWorkThunks';
import { BsPeople } from 'react-icons/bs';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { userDisplayName } from '@common-models/user';

export const MyWorkUsersSelect = () => {
    const dispatch = useAppDispatch();
    const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);
    const activeUsers = useAppSelector(getActiveWorkSpaceUsers);
    const initialSelectedUsers = activeUsers.filter(user =>
        myWorkPreferences?.people?.includes(user.userId)
    );
    const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers);
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    const filteredOptions = activeUsers.filter(
        optionUser => !selectedUsers.some(selectedUser => selectedUser._id === optionUser._id)
    );

    const addUserFilter = async (userToAdd) => {
        if (userToAdd && !selectedUsers.some(u => u._id === userToAdd._id)) {
            const updatedUsers = [...selectedUsers, userToAdd];
            setSelectedUsers(updatedUsers);

            await dispatch(DBMyWorksThunks.patch({
                entity: {
                    _id: myWorkPreferences._id,
                    people: updatedUsers.map(user => user.userId),
                }
            }));

            dispatch(fetchMyWorkThunk())
        }
    };

    const removeUser = async (userId) => {
        const updatedUsers = selectedUsers.filter(user => user._id !== userId);
        setSelectedUsers(updatedUsers);

        await dispatch(DBMyWorksThunks.patch({
            entity: {
                _id: myWorkPreferences._id,
                people: updatedUsers.map(user => user.userId),
            }
        }));

        dispatch(fetchMyWorkThunk())
    };


    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="people-content"
                id="people-header"
            >
           
                <Stack direction="row" alignItems="center" spacing={1}>
                    <BsPeople />
                    <Typography>People</Typography>
                    <Tooltip title="Select owners tasks you wanna see" placement='top' arrow>
                        <div>
                            <AiOutlineInfoCircle />
                        </div>
                    </Tooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Autocomplete
                    options={filteredOptions}
                    value={autocompleteValue}
                    getOptionLabel={(user) => userDisplayName(user.profile)}
                    onChange={(_, newValue) => {
                        addUserFilter(newValue);
                        setAutocompleteValue(null);
                    }}
                    renderInput={(params) => <TextField  {...params} label="Choose team member" variant="standard" />}
                    sx={{ mb: 2 }}
                    renderOption={(props, option) => (
                        <li   {...props} style={{ display: 'flex', alignItems: 'center', padding: "8px 10px" }}>
                            <PersonAvatar
                                email={option.email}
                                profileImg={option.profile?.profileImg}
                                firstName={option.profile?.firstName}
                                lastName={option.profile?.lastName}
                                style={{ marginRight: 4 }}
                                isName={true}
                            />
                            
                        </li>
                    )}
                />
                <Stack direction={"row"} flexWrap={"wrap"}>
                    {selectedUsers.map(user => (
                        <Chip
                            key={user._id}
                            icon={<PersonAvatar
                                email={user.email}
                                profileImg={user.profile?.profileImg}
                                firstName={user.profile?.firstName}
                                lastName={user.profile?.lastName}
                                //isName={true}
                            />}
                            label={userDisplayName(user.profile)}
                            onDelete={() => removeUser(user._id)}
                            style={{ margin: '5px' }}
                        />
                    ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
