import { Button, SxProps } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react'

type Props = {
    icon?: ReactElement;
    sx?: SxProps;
    onClick?: () => void;
}

export default function MenuButton({
    icon,
    children,
    sx,
    onClick
}: PropsWithChildren<Props>) {
  return (
    <Button onClick={onClick} sx={{
        cursor: 'pointer',
        fontSize: 14,
        display: 'flex',
        width: '100%',
        padding: '6px 10px',
        border: 0,
        outline: 'none',
        backgroundColor: 'transparent',
        borderRadius: '5px',
        color: 'var(--primary)',
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        margin: '0 !important',
        
        '&:hover': {
            backgroundColor: 'var(--button-hovered-color)'
        },
        ...sx,
    }}>
        {icon}
        <span style={{ marginLeft: 5 }}>
            {children}
        </span>
    </Button>
  )
}