
export const DEFAULT_ACCENT_COLOR = '#00C875';
export const colors = {
    light: {
       primary: '#013750',
       primaryLight: '#869ca8',
       boardHeader: '#FAFEFF',
       lightBlue: '#e8f9fd',
       itemHover: '#f3fcfd',
       blueGradient: {
        start: '#6dd1f1',
        end: '#259ae9',
       },
       blue: '#53bdeb',
       sidebar: '#fffbf8',
       sidebarOrange: '#FDAB3D',
       emptyCheckboxBorder: '#c5c5c5',
       emptyCheckboxBorderHover: '#a5a5a5',
       boardActionButton: 'transparent',
       boardActionButtonHover: 'white',
       paleLine: '#DEEEF2',
       pink: '#FF46A0',
       pinkGradient: {
        start: '#F656A1',
        end: '#FFA3CE',
       },
       pinkText: '#1B000D',
       technical: '#f3f3f3',
    },
    dark: {
        primary: 'white	',
        primaryLight: '#869ca8',
        boardHeader: '#00283c',
        lightBlue: '#013750',
        itemHover: '#003e5a',
        blueGradient: {
         start: '#6dd1f1',
         end: '#259ae9',
        },
        blue: '#53bdeb',
        sidebar: '#003e5a',
        sidebarOrange: '#FDAB3D',
        emptyCheckboxBorder: '#005177',
        emptyCheckboxBorderHover: '#0b5e85',
        boardActionButton: 'transparent',
        boardActionButtonHover: '#003e5a',
        paleLine: '#DEEEF2',
        pink: '#FF46A0',
        pinkGradient: {
         start: '#F656A1',
         end: '#FFA3CE',
        },
        pinkText: '#1B000D',
        technical: '#f3f3f3',
     }
}