import { UserRoleList } from "@common-models/workspace-user";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import { tasksExtraDataSelectors, workspaceUserSelectByQuery } from "@common-reducers/DBServiceThunks";

type ImagePreview = {
  isActive: boolean;
  imageURL: string;
};

interface ExtraDataState {
  displayExtraDataOfTaskById: string;
  editModeByExtraDataId: string | null;
  showExtraData: boolean;
  activeMenuId: string;
  imagePreview: ImagePreview;
  openMenuByExtraDataId: string;
  isEditorUploading: boolean;
  isMentionsDialogOpen?: boolean;
  isNewSubItemDialog?: string;
}

const initialState: ExtraDataState = {
  displayExtraDataOfTaskById: "",
  editModeByExtraDataId: null,
  showExtraData: false,
  activeMenuId: "",
  imagePreview: {
    isActive: false,
    imageURL: "",
  },
  openMenuByExtraDataId: "",
  isEditorUploading: false,
  isMentionsDialogOpen: false,
  isNewSubItemDialog: '',
};

const ExtraDataSlice = createSlice({
  name: "extradata",
  initialState,
  reducers: {
    setShowExtraData(state, { payload }: PayloadAction<boolean>) {
      state.showExtraData = payload;
    },
    setActiveMenuId(state, { payload }: PayloadAction<string>) {
      state.activeMenuId = payload;
    },
    setExtraDataOfTaskId(state, { payload }: PayloadAction<string>) {
      state.displayExtraDataOfTaskById = payload;
    },

    previewPhoto(state, { payload }: PayloadAction<ImagePreview>) {
      state.imagePreview = payload;
    },

    setOpenMenuByExtraDataId(state, action: PayloadAction<string>) {
      state.openMenuByExtraDataId = action.payload;
    },

    setEditModeByExtraDataId(state, action: PayloadAction<string>) {
      state.editModeByExtraDataId = action.payload;
    },
    setEditorUploading(state, action: PayloadAction<boolean>) {
      state.isEditorUploading = action.payload;
    },
    setIsMentionsDialogOpen(state, action: PayloadAction<boolean>) {
      state.isMentionsDialogOpen = action.payload;
    },
    setIsNewSubItemDialog(state, action: PayloadAction<string>) {
      state.isNewSubItemDialog = action.payload;
    },
  },
});

export const {
  setShowExtraData,
  setOpenMenuByExtraDataId,
  setEditModeByExtraDataId,
  previewPhoto,
  setExtraDataOfTaskId,
  setIsMentionsDialogOpen,
  setIsNewSubItemDialog,
} = ExtraDataSlice.actions;


export const extraDataActions = ExtraDataSlice.actions;
export default ExtraDataSlice.reducer;


export const selectExtraDataItem = createSelector(
  (state: CommonRootState, extraDataId) => ({ state, extraDataId }),
  (params: any) => {
    let extraDataRelatedToOpenMenu = null
    extraDataRelatedToOpenMenu = tasksExtraDataSelectors.selectById(params.state, params.extraDataId)
    return extraDataRelatedToOpenMenu;
  }
);

export const canDeleteExtraDataItemSelector = createSelector(
  [
    (state: CommonRootState, extraDataId) => state,
    (state: CommonRootState, extraDataId) => extraDataId,
  ],
  (state, extraDataId) => {
    const currentUserId = state.UserReducer.userId;
    const extraDataItem = tasksExtraDataSelectors.selectById(state, extraDataId)
    const [ownerWorkspaceUser] = workspaceUserSelectByQuery(state.DBWorkspaceUserReducer, {
      userId: extraDataItem.owner
    })
    const [currentUserWorkspaceUser] = workspaceUserSelectByQuery(state.DBWorkspaceUserReducer, {
      userId: currentUserId
    })
    const userRoleIndex = UserRoleList.indexOf(currentUserWorkspaceUser?.role);
    const ownerRoleIndex = UserRoleList.indexOf(ownerWorkspaceUser?.role);

    return userRoleIndex >= ownerRoleIndex;
  }
);
