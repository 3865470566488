import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { useEffect } from "react";
import QrModal from "./qr-modal";
import { toggleHowToScanQrModal } from "@common-reducers/MainReducer";
import QrHowToScan from "./qr-how-to-scan";

export interface AccountWorkspacesViewProps { }

const QrView = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHowToScanQrModal(true));
  }, [dispatch]);

  return (
    <div style={{
      position: 'absolute',
      inset: 0,
    }}>
      <img src="https://i.imgur.com/XHGBxC4.png" alt="" style={{
        position: 'absolute',
        inset: 0,
        height: '100vh',
        maxWidth: '100vw',
        filter: 'blur(10px)',
      }} />
      <QrModal />
      <QrHowToScan />
    </div>
  );

};

export default QrView;



