import { pickerDialogActions } from '@common-reducers/PickerDialogReducer';
import { cellValueSelector } from '@common-reducers/TaskSelectors';
import { updateCellValueThunk } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { createElement, memo } from 'react';
import { CellProps, CellWrapperProps, PickerProps } from './cell-interface';
import { isTempId } from "@common-reducers/DBServiceThunks";
import { Skeleton } from '@mui/material';

function CellWrapper({
    columnId,
    columnType,
    taskId,
    groupId,
    boardId,
    cellComponent,
    isFormInEditMode,
    isOnKanban = false,
    sx,
    mockValue,
    readOnly = false
}: CellWrapperProps) {

    const dispatch = useAppDispatch()
    const cellValue = useAppSelector((state) => cellValueSelector(state, taskId, columnId)) ?? mockValue

    const updateTask = (value: any) => {
        dispatch(updateCellValueThunk({
            taskId,
            columnId,
            data: {
                value
            }
        }));
    }

    const closePicker = () => {
        pickerDialogActions.closePickerDialog();
    }

    const togglePicker = ({
        anchorId,
        pickerType
    }) => {
        if (readOnly) return;
        dispatch(pickerDialogActions.togglePicker({
            pickerAnchorId: anchorId,
            pickerProps: {
                cellValue,
                columnId,
                taskId,
                groupId,
                boardId,
                isFormInEditMode,
                type: pickerType
            } as PickerProps
        }))
    }

    const openPicker = ({
        anchorId,
        pickerType
    }) => {
        if (readOnly) return;
        dispatch(
            pickerDialogActions.setPickerTypeAndId({
                pickerAnchorId: anchorId,
                pickerProps: {
                    cellValue,
                    columnId,
                    taskId,
                    groupId,
                    boardId,
                    isFormInEditMode,
                    type: pickerType
                } as PickerProps
            })
        )
    }

    return createElement(cellComponent, {
        cellValue,
        taskId,
        groupId,
        columnId,
        boardId,
        isFormInEditMode,
        isOnKanban,
        sx,
        readOnly,
        mockValue,
        onUpdate: updateTask,
        openPicker,
        togglePicker,
        closePicker
    } as CellProps);
}

export default memo(CellWrapper);