import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectPersonPickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardCompleteUserListEqual, getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect, useMemo, useState } from "react";
import CustomDropdown from "../automation-dropdown";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import { extractName } from "../conditions/person-is-someone-condition";
import { setCurrentAction } from "../automations-helper";

const AssignPersonAction = ({ index }) => {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionData, setActionData] = useState({ actionType: 'replace', assignee: 'someone', columnName: 'assignee' });
    const [dropdown, setDropdown] = useState<string>('someone');

    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual);
    const boardId = useAppSelector((state) => getSelectedBoardId(state));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    
    let filteredColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));
    filteredColumns = filteredColumns.map(({ name, ...rest }) => ({ ...rest, name, label: name }));

    const mappedUserList = extractName(userList);
    const actionsOptions = [{ label: "Replace" }, { label: "Add Assignee" }];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type) => {
        setAnchorEl(event.currentTarget);
        setDropdown(type);
    };

    const handleSelect = (handler) => (option) => {
        handler(option);
        setAnchorEl(null);
    };

    const handleActionOptionSelect = handleSelect(option => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { ...actions[index], actionOption: option.label })));
        setActionData(prev => ({ ...prev, actionType: option.label }));
    });

    const handlePersonSelect = handleSelect(option => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { ...actions[index], userId: option._id, userType: option.type })));
        setActionData(prev => ({ ...prev, assignee: option.label }));
    });

    const handleColumnSelect = handleSelect(option => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { ...actions[index], columnId: option.id })));
        setActionData(prev => ({ ...prev, columnName: option.label }));
    });

    const assignedName = useMemo(() => mappedUserList.find(item => item._id === actions[index].userId), [mappedUserList, actions, index]);
    const personColumnName = useMemo(() => filteredColumns.find(item => item.id === actions[index]?.columnId)?.name, [filteredColumns, actions, index]);

    useEffect(() => {
        if (actions) {
            setActionData({
                actionType: actions[index].actionOption ?? "Assign (replace)",
                assignee: assignedName?.label ?? "someone",
                columnName: personColumnName ?? "assignee"
            });

            dispatch(automation.fieldValidityChanged({ component: 'AssignPersonAction', index, field: 'actionOption', isValid: !!actions[index].actionOption }));
            dispatch(automation.fieldValidityChanged({ component: 'AssignPersonAction', index, field: 'userId', isValid: !!actions[index].userId }));
            dispatch(automation.fieldValidityChanged({ component: 'AssignPersonAction', index, field: 'columnId', isValid: !!actions[index].columnId }));
        }
    }, [actions, index,  dispatch, assignedName?.label, personColumnName]);

    const prefix = index === 0 ? 'Then' : 'and then';

    return (
        <Stack sx={{ fontSize: "24px" }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix}
                <AutomationButton
                    componentName='AssignPersonAction'
                    index={index}
                    option='actionOption'
                    label={actionData.actionType}
                    handleClick={(e) => handleClick(e, 'replace')}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
                <AutomationButton
                    componentName='AssignPersonAction'
                    index={index}
                    option='userId'
                    label={actionData.assignee}
                    handleClick={(e) => handleClick(e, 'someone')}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                /> as
                <AutomationButton
                    componentName='AssignPersonAction'
                    index={index}
                    option='columnId'
                    label={actionData.columnName}
                    handleClick={(e) => handleClick(e, 'assignee')}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>

            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="AssignPersonAction" />

            {dropdown === 'replace' && (
                <CustomDropdown
                    options={actionsOptions}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onOptionSelect={handleActionOptionSelect}
                    onHoverOption={null}
                />
            )}

            {dropdown === 'someone' && (
                <CustomDropdown
                    options={mappedUserList}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onOptionSelect={handlePersonSelect}
                    onHoverOption={null}
                />
            )}

            {dropdown === 'assignee' && (
                <CustomDropdown
                    options={filteredColumns}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onOptionSelect={handleColumnSelect}
                    onHoverOption={null}
                />
            )}
        </Stack>
    );
};

export default AssignPersonAction;
