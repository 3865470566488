import {
  Box,
  Drawer,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useMemo, useState } from "react";
import SideBar, { SideBarItem } from "../sidebar/sidebar";
import { useAppSelector } from "@common-reducers/hooks/store.hook";

export interface AccountPageProps {
  page: JSX.Element;
  active?: SideBarItem;
  classes?: any;
  readOnly?: boolean;
}

export interface AccountPageState {
  open: boolean;
}
export const AccountPage = ({ page, active,readOnly }: AccountPageProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const variant = useMemo(() => (mobile ? "permanent" : "temporary"), [mobile]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box dir={direction ==="ltr" ? "ltr" : "rtl"}>
      {/* <AppBar
        sx={{
          display: {
            md: "none",
          },
        }}
        position="relative"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes?.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Blueticks
          </Typography>
        </Toolbar>
      </AppBar> */}
     
      <Stack direction="row" sx={{ height: '100vh' }}>
        
        <Drawer
          variant={variant}
          anchor={direction === "rtl" ? "right" : "left"}
          open={open}
          onClose={handleDrawerToggle}
          sx={{
            width: "300px",
            ".MuiPaper-root": {
              md: {
              },
            },
            [`& .MuiDrawer-paper`]: {
              background: "var(--boards-panel-background)",
              borderRight: "1px solid var(--separator-line)",
              overflow: 'visible',
              borderRadius: "0 !important",
            },
          }}
        >
      
          <SideBar 
          active={active} readOnly={readOnly}></SideBar>
        </Drawer>
        <Box sx={{ flex: "auto" }} component="main">
          {page}
        </Box>
      </Stack>
    </Box>
  );
};
