import { Stack } from '@mui/system';
import { isTempId, kanbanSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { isKanbanOptionPickerExists, optionColumnList } from '@common-reducers/TaskSelectors';
import { openPickerThunk, updateKanbanOptionColumn } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralScreenWithButtonAndText from '../general-screen-with-button-and-text';
import Kanban from './kanban';
import classes from './kanban.module.scss';


type Props = {
    kanbanId: string;

}

export default function KanbanView({ kanbanId }: Props) {
    const { t } = useTranslation(namespace);
    const [isKanbanSettingsOpen, setIsKanbanSettingsOpen] = useState(false);
    const optionColumnId = useAppSelector((state) => kanbanSelectOneFieldByQuery(state.DBKanbanReducer, { _id: kanbanId }, 'optionColumnId'));
    const kanbanCurrentColumn = useAppSelector((state) => isKanbanOptionPickerExists(state, kanbanId))
    const columnList = useAppSelector(state => optionColumnList(state))
    const isToggleableNavOpen = useAppSelector((state) => state.MainReducer.isToggleableNavOpen);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (optionColumnId === undefined) {
            if (columnList?.length !== 0 && !isTempId(columnList[0]?._id)) {

                dispatch(updateKanbanOptionColumn({
                    kanbanId: kanbanId,
                    columnId: columnList[0]?._id,
                }))

            }
        }

    }, [columnList, dispatch, kanbanId, optionColumnId])


    const openColumnsPicker = ({
        anchorId,
        pickerType
    }) => {
        dispatch(openPickerThunk({
            anchorId,
            pickerType,
        }))

    }

    return (
        <Stack direction='column' sx={{
            height: '100%',
        }}>
            {/* <Stack direction='row' className={classes.kanban_header}>

           
                <Button
                    id={`kanban_${kanbanId}`}
                    key={`kanban_${kanbanId}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        openColumnsPicker({
                            anchorId: `kanban_${kanbanId}`,
                            pickerType: 'kanban-column-picker',
                        })
                    }
                    }
                    startIcon={<ViewWeek style={{ width: 16, height: 16 }} />}
                    className={classes.header_button}
                >
                    {t('kanbanView.columnsButton')}
                </Button>
                <Button
                    onClick={() => setIsKanbanSettingsOpen(!isKanbanSettingsOpen)}
                    className={classes.header_button}
                    startIcon={<SettingsOutlined style={{ width: 24, height: 24 }} />}
                >
                    {t('kanbanView.settingsButton')}
                </Button>
           
            </Stack> */}
            <Stack direction='row' sx={{
                display: 'flex',
                width: '100%',
                height: 'calc(100vh - 213px)',
                overflow: 'auto',
            }}
                className={classes.kanban_view_wrapper}>
                {
                    kanbanCurrentColumn &&
                    <div style={{ justifySelf: 'center' }}>
                        <Kanban kanbanId={kanbanId} />
                    </div>
                }

                {
                    !kanbanCurrentColumn &&
                    <GeneralScreenWithButtonAndText
                        height="calc(100vh - 260px)"
                        target="status"
                        textValue={t('kanbanView.kanbanInfoText')} />
                }


                {/* <BTSlide open={isKanbanSettingsOpen}>
                    <KanbanSettingsDrawer kanbanId={kanbanId} />
                </BTSlide> */}
            </Stack>
        </Stack>
    )
}