import { CircularProgress } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { selectUserId } from '@common-reducers/UserSelectors';
import { shouldGetMoreContactsSelector } from '@common-reducers/WhatsAppSelectors';
import { isExtensionContext } from '@common-services/detect-context';
import { useAppDispatch, useAppSelector, useDebounce } from '@common-reducers/hooks/store.hook';
import React, { DetailedHTMLProps, JSXElementConstructor, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { requestMissingContactsImagesThunk } from '@extension/context/content/thunks/WhatsappThunks';

type Props = {
    height: number;
    rowHeightString?: string;
    DBThunks: any;
    DBReducer: (state: any) => any;
    selectByQuery?: (state: any, filterObj: any, projectionArray: string[], sortObj: any) => any;
    selector?: (state: any) => any;
    Component: JSXElementConstructor<any>;
    minWidth?: string;
    isWaContact?: boolean;
    searchFilter?: string;
    rowWidth?: string;
    onClick?: (contact: any) => any;
};

const WaContactLazyLoadingVirtuoso = ({
    height,
    rowHeightString = 'auto',
    DBThunks,
    DBReducer,
    selectByQuery,
    Component,
    selector,
    minWidth = '400px',
    isWaContact = false,
    searchFilter = '',
    rowWidth,
    onClick,
}: Props) => {
    const dispatch = useAppDispatch();

    const [range, setRange] = useState({ startIndex: 0, endIndex: 0 });

    const isItemsLoaded = useAppSelector(state => DBReducer(state).itemsLoaded);
    const ownerId = useAppSelector(selectUserId);
    const shouldGetMoreContacts = useAppSelector(state => shouldGetMoreContactsSelector(state))
    const filterdList = useAppSelector((state) => {
        if (selector) {
            return selector(state);
        } else {
            const rawList = selectByQuery(DBReducer(state), {
                $and: [
                    {
                        $or: [
                            { name: { $regex: new RegExp(searchFilter ?? '', 'iu') } },
                            { number: { $regex: new RegExp(searchFilter ?? '', 'iu') } },
                            { pushname: { $regex: new RegExp(searchFilter ?? '', 'iu') } },
                            { verifiedName: { $regex: new RegExp(searchFilter ?? '', 'iu') } }
                        ]
                    },
                    {
                        $or: [
                            { isMyContact: true },
                            { isGroup: true }
                        ]
                    },
                    { 'id.server': { $in: ['c.us', 'g.us'] } },
                    { owner: ownerId.toString() }
                ],
            },
                [],
                {
                    name: 1
                }
            );
            return rawList;
        }
    });

    const isExtension = isExtensionContext()
    const debounceObject = range;
    const debouncedInternalValue = useDebounce(debounceObject, 500);

    const HeightPreservingItem = useCallback(
        ({
            children,
            ...props
        }: PropsWithChildren<
            DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
        >) => {
            return (
                <div
                    {...props}
                    className="height-preserving-container"
                    style={{ height: rowHeightString, width: rowWidth }}
                >
                    {children}
                </div>
            );
        },
        []
    );


    const handleRangeChanged = useCallback(({ startIndex, endIndex }) => {
        setRange({ startIndex, endIndex });
    }, []);

    const loadMore = async () => {
        try {
            const skipCount = memoizedData.length;

            if (shouldGetMoreContacts) {
                await dispatch(DBThunks.find({
                    $and: [
                        {
                            $or: [
                                { name: { $regex: searchFilter, $options: 'iu' } },
                                { number: { $regex: searchFilter, $options: 'iu' } },
                                { pushname: { $regex: searchFilter, $options: 'iu' } },
                                { verifiedName: { $regex: searchFilter, $options: 'iu' } }
                            ]
                        },
                        {
                            $or: [
                                { isMyContact: true },
                                { isGroup: true }
                            ]
                        },
                        { 'id.server': { $in: ['c.us', 'g.us'] } },
                        { owner: ownerId.toString() },
                    ],
                    $sort: {
                        name: 1
                    },
                    $skip: skipCount,
                }));
            }
        } catch (error) {
            console.error('Error loading more data:', error);
            // Handle error appropriately
        }
    };

    const memoizedData = useMemo(() => filterdList, [filterdList, searchFilter]);


    useEffect(() => {
        if (isWaContact && debouncedInternalValue) {
            const [start, end] = [range.startIndex, range.endIndex];
            const idsInView = memoizedData
                .slice(start, end + 1)
                .filter(contact => !contact.imgUrl)
                .map(contact => ({ _id: contact._id, lid: contact.id?._serialized }));

            if (idsInView.length > 0) {
                dispatch(requestMissingContactsImagesThunk(idsInView));
            }
        }
    }, [debouncedInternalValue, isWaContact]);

    useEffect(() => {
        async function getInitialObjects() {
            const res = await dispatch(DBThunks.find({
                $and: [
                    {
                        $or: [
                            { name: { $regex: searchFilter, $options: 'iu' } },
                            { number: { $regex: searchFilter, $options: 'iu' } },
                            { pushname: { $regex: searchFilter, $options: 'iu' } },
                            { verifiedName: { $regex: searchFilter, $options: 'iu' } }
                        ]
                    },
                    {
                        $or: [
                            { isMyContact: true },
                            { isGroup: true }
                        ]
                    },
                    { 'id.server': { $in: ['c.us', 'g.us'] } },
                    { owner: ownerId.toString() },
                ],
                // Apply sorting in the database query if supported
                sort: {
                    name: 1
                }
            }));
            console.log('Initial fetch result:', res);
        }

        if (shouldGetMoreContacts) {
            getInitialObjects();
        }
    }, [searchFilter]);

    return (
        <Stack>
            {!isItemsLoaded && shouldGetMoreContacts &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <CircularProgress />
                </Box>
            }
            {isItemsLoaded && filterdList.length > 0 &&
                <Virtuoso
                    rangeChanged={(r) => { if (isWaContact) { handleRangeChanged(r) } }}
                    components={{
                        Item: HeightPreservingItem,
                    }}
                    endReached={loadMore}
                    increaseViewportBy={350}
                    data={memoizedData}
                    style={{
                        height: height + 'px',
                        minWidth,
                    }}
                    totalCount={memoizedData.length}
                    itemContent={(index, item) => {
                        return (
                            <Component itemId={isWaContact ? item.id._serialized : item._id} index={index} onClick={onClick} contactFromExtension={isExtension && item} />
                        );
                    }}
                />
            }
        </Stack>
    )
}

export default WaContactLazyLoadingVirtuoso;
