
const ColumnTypeList = ['text', 'list', 'date']
export type ColumnType = typeof ColumnTypeList[number];
export const DEFAULT_COLUMN_WIDTH = 140;

export interface TaskColumn {
  _id?: string;
  owner?: string;
  boardId?: string;
  type: ColumnType;
  title?: string;
  width?: number;
  order?: number;
  version?: number;
  static?: boolean;
  selector?: string;
  outerCell?: boolean;
  defaultOptionId?: string;
  isVisible?: boolean;
  customData?:any;
  deleted?:boolean
}
