import moment, { Moment } from 'moment';
import { PlanDescriptor } from '../config/app.config';
import { Workspace } from './workspace';

export interface Subscription {
    _id: string;
    id: any;
    recurrency_period: number;
    recurrency: number;
    origStatus: string;
    type: undefined | 'paypal' | 'hotmart' | 'bluesnap' | 'trial' | 'chargebee';
    quantity: string;
    subscriber: {
        code: string;
        name: {
            given_name: string;
            surname: string;
        };
        email_address: string;
        payer_id: string;
        shipping_address: {};
    };
    create_time: string;
    plan_overridden: boolean;
    shipping_amount: {};
    start_time: string;
    update_time: string;
    billing_info: {
        last_payment: {
            time: string;
        };
    };
    subscription_id: string;
    referenceNumber: string;
    plan_id: string;
    status: string;
    status_update_time: string;
    workspace?: Partial<Workspace>;
    expiredDate?: string;
    next_billing_at?: number;
}

export class SubscriptionObject {
    id?: string;
    _id?: string;
    status?: string;
    origStatus?: string;
    referenceNumber?: string;
    plan_id?: string;
    plan?: PlanDescriptor | SubscriptionItem;
    start_time?: Date;
    type?: 'paypal' | 'hotmart' | 'bluesnap' | 'chargebee' | 'trial' | undefined;
    recurrency_period?: number;
    workspace?: Workspace;
    subscription_id?: string;
    createdAt?: string;
    updatedAt?: string;
    expiredDate?: string;
    next_billing_at?: number;
    entitlementList?: Entitlement[];

    constructor(data: any) {
        Object.assign(this, data);
        this.start_time = moment(this.start_time).toDate();
    }

    get renewalTime() {
        let renewalTime: Moment | undefined;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                const planDuration: PlanDescriptor = this.plan as PlanDescriptor;
                if (planDuration) {
                    renewalTime = moment.utc(this.start_time).add(planDuration?.intervalCount, planDuration?.intervalUnit);
                }
                break;
            }
            case 'chargebee': {
                renewalTime = moment.utc(this.next_billing_at * 1000)
                break;
            }
        }

        return renewalTime;
    }

    get isActive() {
        let isActive: boolean = false;
        switch (this.type) {
            case undefined:
            case 'paypal': {
                isActive = (this.status === 'ACTIVE' && this.renewalTime?.isAfter(moment())) ?? false;
                break;
            }
            case 'bluesnap':
            case 'hotmart': {
                isActive = this.status === 'ACTIVE' && this.renewalTime?.isAfter(moment());
                break;
            }
            case 'chargebee':
                isActive = ['active', 'non_renewing'].indexOf(this.status) > -1;
                break;
            case 'trial': {
                isActive = moment(this.expiredDate).isAfter(moment());
                break;
            }
        }
        return isActive;
    }

    get isLive() {
        let isActive: boolean = false;
        switch (this.type) {
            case undefined:
            case 'paypal': {
                isActive = this.origStatus?.toLowerCase() === 'active';
                break;
            }
            case 'bluesnap':
            case 'hotmart': {
                isActive = this.origStatus?.toLowerCase() === 'active';
                break;
            }
            case 'chargebee': {
                isActive = this.status === 'active';
                break;
            }
        }
        return isActive;
    }

    get planId() {
        let planId: string = '';
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                planId = this.plan_id ?? '';
                break;
            }
        }
        return planId;
    }

    get planName() {
        let planName: string = '';
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                planName = (this.plan as PlanDescriptor)?.planName ?? '';
                break;
            }
            case 'chargebee': {
                planName = this.plan_id ?? '';
                break;
            }
        }
        return planName;
    }

    get planType() {
        let planType: string = '';
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                const bluesnapPlanType = (this.plan as PlanDescriptor)?.planType ?? '';
                switch (bluesnapPlanType) {
                    case 'Scheduler': {
                        planType = 'Basic';
                        break;
                    }
                    case 'Manager': {
                        planType = 'Standard';
                        break;
                    }
                    case 'Campaigner': {
                        planType = 'Pro';
                        break;
                    }
                }
                break;
            }
            case 'chargebee': {
                [planType] = (this.plan_id ?? '').split('-');
                break;
            }
        }
        return planType;
    }

    get order(): number {
        let order = 0;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                order = (this.plan as PlanDescriptor)?.order ?? 0;
                break;
            }
            case 'chargebee': {
                const [plan_id] = (this.plan_id as string).split('-');
                switch (plan_id) {
                    case 'Basic': {
                        order = 1;
                        break;
                    }
                    case 'Standard': {
                        order = 2;
                        break;
                    }
                    case 'Pro': {
                        order = 3;
                        break;
                    }
                }
                break;
            }
        }

        return order;
    }

    get seatCount() {
        let seatCount: number = 0;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                seatCount = (this.plan as PlanDescriptor)?.seatCount ?? 1;
                break;
            }
            case 'chargebee': {
                seatCount = this.plan?.quantity ?? 1;
                break;
            }
        }
        return seatCount;
    }

    get messageLimit() {
        let messageLimit: number = 0;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                messageLimit = (this.plan as PlanDescriptor)?.messageLimit ?? 1;
                break;
            }
            case 'chargebee': {
                messageLimit = this.entitlementList.some(entitlement => entitlement.feature_id === 'unlimited-messages') ? -1 : 1;
                break;
            }
        }
        return messageLimit === -1 ? Infinity : messageLimit;
    }

    get hasBulk() {
        let hasBulk: boolean = false;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                hasBulk = (this.plan as PlanDescriptor)?.bulk ?? false;
                break;
            }
            case 'chargebee': {
                hasBulk = this.entitlementList.some(entitlement => entitlement.feature_id === 'remove-branding');
                break;
            }
        }
        return hasBulk;
    }

    hasTasks(): boolean {
        let hasTasks: boolean = false;
        // return this.plan?.tasks;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                hasTasks = (this.plan as PlanDescriptor)?.tasks ?? false;
                break;
            }
            case 'chargebee': {
                hasTasks = this.entitlementList.some(entitlement => entitlement.feature_id === 'unlimited-tasks');
                break;
            }
        }

        return hasTasks;
    }

    hasSocket() {
        let hasSocket: boolean = false;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                hasSocket = (this.plan as PlanDescriptor)?.bulk ?? false;
                break;
            }
            case 'chargebee': {
                hasSocket = this.entitlementList.some(entitlement => entitlement.feature_id === 'offline-socket');
                break;
            }
        }
        return hasSocket;
    }

    get price() {
        return (this.plan as PlanDescriptor)?.price ?? ((this.plan as SubscriptionItem)?.amount / 100) ?? 0;
    }

    get isWorkspaceSubscription() {
        // return this.plan.tasks;
        return false;
    }

    get billingPeriod(): 'month' | 'year' {
        let billingPeriod: 'month' | 'year' = 'month';
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                billingPeriod = (this.plan as PlanDescriptor)?.intervalUnit ?? 'month';
                break;
            }
            case 'chargebee': {
                const [plan_id, currency, interval] = (this.plan_id as string).split('-');
                billingPeriod = interval === 'Monthly' ? 'month' : 'year';
                break;
            }
        }
        return billingPeriod;
    }

    get amount() {
        let amount;
        switch (this.type) {
            case undefined:
            case 'paypal':
            case 'hotmart':
            case 'bluesnap': {
                amount = (this.plan as PlanDescriptor)?.price ?? 0;
                break
            }
            case 'chargebee': {
                amount = ((this.plan as SubscriptionItem)?.amount ?? 0) / 100;
                break;
            }
            case 'trial': {
                amount = 0;
                break;
            }
        }

        return amount;
    }
}
