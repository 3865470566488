import { appConfig } from "@common-config/app.config";
import { getBoardNameById } from '@common-reducers/BoardsSelectors';
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { Stack, Typography } from "@mui/material";
import { CellProps } from "../cell-interface";

export default function BoardCell({ cellValue, readOnly, onUpdate, groupId, boardId }: CellProps) {
  const boardName = useAppSelector(state => getBoardNameById(state, boardId));

  function openBoardInNewTab() {
    const boardURL = `${appConfig.APP_ENDPOINT}/tasks/?boardID=${boardId}`;
    if (isExtensionContextOrWhatsappView()) {
      window.open(boardURL, "_blank");
      //ContentMessageServiceActions.openTab(boardURL);
    } else {
        window.open(boardURL, '_blank')
    }
}

  return (
    <Stack sx={{cursor:'pointer'}} onClick={openBoardInNewTab} direction={"row"} alignItems={"center"} >
      <Typography sx={{ fontSize: "14px" }}>{boardName}</Typography>
    </Stack>
  );
}
