import ThemeAsset from '@common-components/theme/theme-asset';
import { Box } from '@mui/system';
import { isInPopup } from '@common-services/detect-context';
import { theme } from "@common/theme";


type Props = {
    screenTitle?: string
    text?: string
}

export default function FormHeader({ screenTitle, text }: Props) {
    return (<>
        {!isInPopup() &&
            <Box>
                <ThemeAsset
                    assetName={'logo.svg'}
                    style={{
                        marginBottom: "20px",
                        maxWidth: "165px",
                    }} />
            </Box>
        }
        <Box>
            {screenTitle && <h2 style={{ fontWeight: "bold", margin: "0 0 10px 0" }}>
                {screenTitle}
            </h2>
            }
            {
                !isInPopup() && text &&
                <small style={{ fontWeight: "bold", marginBottom: theme.spacing(3) }}>
                    {text}
                </small>
            }
        </Box>

    </>
    )
}