import { Box } from '@mui/system'
import React from 'react'
import classes from './access-form.module.scss'

type Props = {
    label: string
}

export default function ContinueWithDevider({ label }: Props) {
    return (
        <Box className={classes.ContinueWithDevider}>
            <Box className={classes.CenteredLabelText}>
                {label}
            </Box>
        </Box>
    )
}