import { ViewMode } from '@common-components/reactgantt'
import { ViewSwitcher } from '@common-components/reactgantt/components/gantt/view-switcher'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getCurrentBoardId } from '@common-reducers/BoardsSelectors'
import { DBGanttThunks, ganttSelectByQuery, isTempId } from "@common-reducers/DBServiceThunks"
import { timelinePickerColumnList } from '@common-reducers/TaskSelectors'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import React, { useEffect, useState } from 'react'

type Props = {
    ganttId: string,
}

export default function GanttSettingsDrawer({ ganttId }: Props) {
    const dispatch = useAppDispatch()
    const boardId = useAppSelector(getCurrentBoardId)
    const [gantt] = useAppSelector((state) => ganttSelectByQuery(state.DBGanttReducer, { boardId, _id: ganttId }));
    console.log(gantt)
    const columnList = useAppSelector(state => timelinePickerColumnList(state, boardId))
    const handleDropDownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isTempId(event.target.value)) {
            dispatch(
                DBGanttThunks.patch({
                    entity: {
                        ...gantt,
                        columnId: event.target.value,

                    }
                })
            )
        }
    }
    const BuildColumnSelect = () => {
        const pickerOptions = columnList.filter(column => column.type === "timeline-picker")
        return (
            <Box sx={{
                p: 2,
            }}>

                <FormControl fullWidth>
                    <InputLabel id="label-ID" >Pick Your Timeline picker</InputLabel>
                    <Select
                        MenuProps={{
                            disablePortal: true,
                        }}
                        labelId="label-ID"
                        id="id-of-???"
                        value={gantt?.columnId ?? pickerOptions[0]?._id.toString()}
                        onChange={handleDropDownChange}
                    >
                        {pickerOptions
                            .map((option, pickerIndex) => {
                                return (
                                    <MenuItem value={option._id} key={pickerIndex}>{option.title ?? "UnNamed"}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        )
    }
    const BuildGanttDateView = () => {
        const [view, setView] = useState<ViewMode>(ViewMode.Day);
        const [isChecked, setIsChecked] = useState(true);

        useEffect(() => {

            dispatch(
                DBGanttThunks.patch({
                    entity: {
                        ...gantt,
                        viewMode: view
                    }
                })
            )
        }, [view])


        return (
            <div>
                <ViewSwitcher
                    onViewModeChange={(viewMode: ViewMode) => setView(viewMode)}
                    onViewListChange={setIsChecked}
                    isChecked={isChecked}
                />
            </div>
        )
    }
    return (
        <Stack direction='column' sx={{
            maxWidth: '400px',
            minWidth: '200px',
            height: '100%',
            border: '1px solid var(--border-color)',
            zIndex: '10',
            position: 'absolute',
            right: '0',
            backgroundColor: 'var(--background-default)',
            padding: '10px',
        }}>
            <p>
                Customize View
            </p>
            {BuildColumnSelect()}
            {BuildGanttDateView()}
        </Stack >
    )
}