import { greenStopColors, redStopColors } from "@common-components/top-menu/extension-top-button-bar";
import MenuIconButton from "@common-components/top-menu/menu-icon-button";
import { Sync, SyncDisabled } from "@mui/icons-material";
import { selectIsCurrentUserSessionConnected } from "@common-reducers/UserSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import PropTypes from 'prop-types';

const SocketConnectionStatusButton = ({ onClick }) => {
  const isSocketConnectedToWhatsApp = useAppSelector(state => selectIsCurrentUserSessionConnected(state));

  return (
    <MenuIconButton
      onClick={onClick}
      stopColors={isSocketConnectedToWhatsApp ? greenStopColors : redStopColors}
      hoverStopColors={isSocketConnectedToWhatsApp ? greenStopColors : redStopColors}
      Icon={isSocketConnectedToWhatsApp ? <Sync /> : <SyncDisabled />}
      alt={isSocketConnectedToWhatsApp ? "Connected" : "Connect to send messages offline"}
    />
  );
};

SocketConnectionStatusButton.propTypes = {
  onClick: PropTypes.func
};

export default SocketConnectionStatusButton;

