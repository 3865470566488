import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import { modalTextStyle } from "@common-components/bt-chatbot/bot-components/bt-chatbot-modal";
import SelectVariable from "@common-components/bt-chatbot/bot-components/select-variable";
import BTInputField from "@common-components/bt-input-field/bt-input-field";
import RowStack from "@common-components/common/row-stack/RowStack";
import { RemoveCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import classes from "./webhook-node.module.scss";
import React from 'react'
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
}

export default function CustomizeHeaders({ localWebhookState, setLocalWebhookState }: Props) {



    const setHeader = (type: 'key' | 'value', newValue: string, index: number) => {
        const headers = [...localWebhookState.customizeHeaders.headers]
        headers[index] = {
            ...headers[index],
            [type]: newValue,
        }
        setLocalWebhookState({
            ...localWebhookState,
            customizeHeaders: {
                ...localWebhookState.customizeHeaders,
                headers: headers,
            }
        })
    }

    const handleClickRemoveHeader = (index: number) => {
        const headers = [...localWebhookState.customizeHeaders.headers]
        headers.splice(index, 1)
        setLocalWebhookState({
            ...localWebhookState,
            customizeHeaders: {
                ...localWebhookState.customizeHeaders,
                headers: headers,
            }
        })
    }

    const headerRow = (header, index) => {
        return (
            <RowStack key={index} sx={{ gap: 2, marginBottom: '5px', }}>
                <Stack sx={{ height: '110px', }}>
                    {secondaryTitle('Key')}
                    <BotInputField
                        value={header.key}
                        onChange={(newValue) => setHeader('key', newValue, index)}
                        placeholder='Content-Type'
                    />
                </Stack>
                <Stack sx={{ height: '110px', }}>
                    {secondaryTitle('Value')}
                    <BotInputField
                        value={header.value}
                        onChange={(newValue) => setHeader('value', newValue, index)}
                        placeholder='application/json'
                    />
                    <div style={{ marginTop: '10px', }}>
                        <SelectVariable buttonSize='small' onSelect={(item) => {
                            setLocalWebhookState({
                                ...localWebhookState,
                                customizeHeaders: {
                                    ...localWebhookState.customizeHeaders,
                                    headers: localWebhookState.customizeHeaders.headers.map((header, i) => {
                                        if (i === index) {
                                            return {
                                                ...header,
                                                value: header.value + item,
                                            }
                                        } else {
                                            return header
                                        }
                                    })
                                }
                            })
                        }} />
                    </div>
                </Stack>
                <IconButton disableRipple sx={{
                    zIndex: 2,
                    width: '30px',
                    color: '#666666',
                    paddingBottom: '25px',
                    ".MuiSvgIcon-root": {
                        width: '20px',
                        height: '20px',
                    },
                    ".MuiSvgIcon-root:hover": {
                        color: 'red',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    }
                }} onClick={() => handleClickRemoveHeader(index)}><RemoveCircle /></IconButton>
            </RowStack>
        )
    }


    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitle = (secondaryTitle: string, style?: any) => {
        return <div className={classes.secondary_title} style={style}>{secondaryTitle}</div>
    }


    return (
        <div className={classes.divider}>
            <RowStack sx={{ justifyContent: 'space-between' }}>
                {title('Customize Headers')}
                <BotToggleSwitch
                    onChange={() => setLocalWebhookState({
                        ...localWebhookState,
                        customizeHeaders: {
                            ...localWebhookState.customizeHeaders,
                            isCustomizeHeaders: !localWebhookState.customizeHeaders.isCustomizeHeaders,
                        }
                    })}
                    checked={localWebhookState.customizeHeaders.isCustomizeHeaders}
                    isOptional={true}
                    sx={{ marginBottom: '20px', }} />
            </RowStack>
            {secondaryTitle('Add headers to your request (example: Content-Type: application/json)')}
            {localWebhookState.customizeHeaders.isCustomizeHeaders &&
                <>
                    {title('Edit Headers', { marginTop: '10px', })}
                    {(localWebhookState?.customizeHeaders?.headers ?? []).map((header, index) => (
                        headerRow(header, index)
                    ))}
                    <BTbotbutton
                        sx={{ width: '100px', }}
                        styleType="dashed"
                        onClick={() => setLocalWebhookState({
                            ...localWebhookState,
                            customizeHeaders: {
                                ...localWebhookState.customizeHeaders,
                                headers: [
                                    ...localWebhookState.customizeHeaders.headers,
                                    { key: '', value: '' }
                                ],
                            }
                        })}>
                        Add Header
                    </BTbotbutton>
                </>
            }
        </div>
    )
}