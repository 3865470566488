import { BTPaperProps } from "@common-components/atoms/bt-paper/bt-paper";
import BTThemeProvider from "@common-components/bt-theme-provider";
import RowStack from "@common-components/common/row-stack/RowStack";
import MessageEditor from "@common-components/message-editor/message-editor";
import { fetchRule, patchRuleSet, toRuleSet } from "@common-components/schedule-picker/recurrence-utils";
import SocketConnectionStatusButton from "@common-components/socket-connection-status-button/socket-connection-status-button";
import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import WSButton from "@common-components/ws-button/ws-button";
import { appConfig } from "@common-config/app.config";
import { toggleSocketModal } from "@common-reducers/MainReducer";
import { isOverLimitOfScheduledMessages } from "@common-reducers/UserMessageReducer";
import { selectFilteredContactList, setAddMsgInCalendarContact, setIsReply, setMentions } from "@common-reducers/WhatsAppReducer";
import { contactLabel, getIsSocketConnectedToWhatsApp } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { isExtensionContext, isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { UserMessage } from "@common/models/user-message";
import { DBWaContactThunks, userMessagesSelectByQuery, waContactSelectByQuery } from "@common-reducers/DBServiceThunks";
import { theme } from "@common/theme";
import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, Link,
  Slide
} from '@mui/material';
import { TransitionProps } from "@mui/material/transitions";
import { Box, Stack } from '@mui/system';
import moment from "moment";
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from "react";
import { RiCloseCircleFill } from "react-icons/ri";

import { updateSearchValue } from "@common-reducers/ContactTableReducer";
import { confirmationDialogOption, confirmationDialogOptions } from "@common-views/calendar/message-calendar";
import { scheduleMessage } from "@common/thunks/UserMessagesThunks";
import { Popup } from "@extension/context/popup/components/popup/popup";
import SchedulePicker from "@common/components/schedule-picker/schedule-picker";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { useAppSelector } from "@common/reducers/hooks/store.hook";
import { Schedule } from "@common/types/interface";
import { ContentMessageServiceActions } from "../../services/contentMessageServiceActions";
import styles from "./add-scheduled-message-button.module.scss";
import ContactRow from "./contact-row";
import { MessageConfirmationDialog } from "./message-confirmation-dialog";
import WaContactLazyLoadingVirtuoso from "./wa-contact-lazy-loading-virtuoso";

type AddScheduledMessageButtonProps = {
  isDisabled?: boolean;
  classes?: any;
};

export interface MessageContact {
  id?: string;
  name?: string;
  displayName?: string;
  mobile?: string;
  imgUrl?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddScheduledMessage = (props: AddScheduledMessageButtonProps) => {

  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppDispatch();

  const anchorRef = useRef(null);


  const [searchFilter, setSearchFilter] = useState('');

  const messageContact = useAppSelector(state => state.WhatsAppReducer.addMsgInCalendarContact)

  const currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat);
  const errorState = useAppSelector((state) => state.WhatsAppReducer.errorState);
  const message = useAppSelector((state) => state.AddScheduledMessageButtonReducer.message);
  const open = useAppSelector((state) => state.AddScheduledMessageButtonReducer.open);
  const showConfirmationDialog = useAppSelector((state) => state.AddScheduledMessageButtonReducer.showConfirmationDialog);
  const devices = useAppSelector((state) => state.MainReducer.devices)
  const isWaSocketsLimitReached = useAppSelector((state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached)
  const isSocketConnectedToWhatsApp
    = useAppSelector(state => getIsSocketConnectedToWhatsApp(state));

  const whatsappSockets = devices.filter(obj => obj.connectedFrom === "extension" && obj.domain === "https://web.whatsapp.com/");

  const messageList: UserMessage[] = useAppSelector((state) =>
    userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    })
  );

  const isDisabled = useMemo(
    () => (isExtensionContextOrWhatsappView() && localStorage.getItem("schedulerReady") !== "true") || errorState,
    [errorState]
  );



  useEffect(() => {
    const contactList = message.contactList;

    if (contactList?.length && contactList[0]?.id) {
      dispatch(setAddMsgInCalendarContact({
        name: contactList[0]?.name,
        id: contactList[0].id,
        displayName: contactList[0]?.name,
      }));

      // setDisplayContactList(false);
    } else if (currentChat) {
      dispatch(setAddMsgInCalendarContact({
        name: currentChat.name ?? currentChat.displayName ?? currentChat.contact?.name,
        id: currentChat?.id?._serialized,
        displayName: currentChat.contact?.name ?? currentChat.displayName,
      }));
      // setDisplayContactList(false);
    } else {
      dispatch(setAddMsgInCalendarContact(null));
      // setDisplayContactList(true);
    }
  }, [currentChat, message.contactList]);


  const handleClose = () => {
    dispatch(addScheduledMessageButtonActions.closeDialog());
    dispatch(addScheduledMessageButtonActions.setPollFromWAModal(null))
    dispatch(setAddMsgInCalendarContact(null))
    dispatch(setIsReply(null))
    dispatch(setMentions(null))
    setSearchFilter('')
  };


  const handleCloseLimitReached = () => {
    analyticsService.event("close_popup", {
      category: "purchasePopup",
      action: "close popup",
    });
    dispatch(addScheduledMessageButtonActions.closeDialog());
  };

  const scheduleSend = () => {
    const scheduleMessageFrom: string = "whatsapp" // or calendar
    if (scheduleMessageFrom === "calendar") {
      dispatch(addScheduledMessageButtonActions.setConfirmationDialog(true));
    } else {
      let _message = { ...message }
      _message.contactList = [{ id: messageContact.id.replace('s.whatsapp.net', 'c.us'), name: messageContact.name }]
      if (!message.isRecurring) {
        dispatch(scheduleMessage({
          ..._message,
          rruleset: undefined,
          recurenceUnit: undefined,
          recurrenceQuantity: undefined,
        }));
      } else {
        dispatch(
          scheduleMessage(_message)
        );
      }
      handleClose();
    }
  };

  const onEditRecurringDialogClose = (selection?: confirmationDialogOption) => {
    if (selection) {
      let current = { ...message };
      let original = messageList.find((m) => m._id === current._id);
      const originalRuleset = toRuleSet(original.rruleset);
      let triggerOriginal = true;

      //
      if (selection === "This message") {
        originalRuleset.exdate(
          moment.utc(current.dueDate).startOf("minute").toDate()
        );
        original = { ...original, rruleset: originalRuleset.toString() };

        delete current._id; // -> create
      } else if (selection === "This and following messages") {
        const patched = patchRuleSet(originalRuleset, {
          until: moment.utc(current.dueDate).subtract(1, "minute").toDate(),
        });
        original = { ...original, rruleset: patched.toString() };

        delete current._id; // -> create
      } else {
        triggerOriginal = false;
      }

      if (triggerOriginal) {
        dispatch(scheduleMessage(original));
      }
      dispatch(scheduleMessage(current));
    }
    handleClose();
  };

  const handleScheduleChange = (schedule: Schedule) => {
    if (schedule.rruleset) {
      const rrule = fetchRule(schedule.rruleset);
      const rruleset = rrule.toString()

      console.log(rruleset)
      console.log(rrule.toText())
    }

    dispatch(
      addScheduledMessageButtonActions.updateMessage({
        ...message,
        ...schedule,
        image: undefined,
      })
    );
  };

  const isOverLimit = useAppSelector(isOverLimitOfScheduledMessages);

  console.log(`[add-scheduled-message] open ${open}, isOverLimit: ${isOverLimit}`)

  const limitReachedDialog = () => {
    return (
      <BTThemeProvider>
        <Dialog
          dir="ltr"
          TransitionComponent={Transition}
          open={open && isOverLimit}
          onClose={handleCloseLimitReached}
        >
          <Popup
            headerTitle={"Only 1 message at a time"}
            headerSubtitle={"Go premium to unlock unlimited messages"}
            onClose={handleCloseLimitReached}
          />
        </Dialog>
      </BTThemeProvider>
    );
  };

  const errorStateDialog = () => {
    return (
      <Dialog
        dir="ltr"
        TransitionComponent={Transition}
        open={isDisabled}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{`Sync Error`}</DialogTitle>
        <DialogContent>
          <h1>Please refresh this page</h1>
        </DialogContent>
      </Dialog>
    );
  };



  const isPollValid = useCallback(() => {

    function haveSimilarOptions() {
      const options = (message?.poll?.options ?? []).filter((option) => option.text !== '');
      const uniqueOptions = [...new Set(options.map((option) => option.text))];
      return options.length !== uniqueOptions.length;
    }


    return message?.isPoll &&
      message?.poll?.question?.length > 0 &&
      message?.poll?.options?.length > 1 &&
      message?.poll?.options?.filter(o => o.text.length > 0).length > 1 &&
      !haveSimilarOptions()
  }, [message?.isPoll, message?.poll?.question, message?.poll?.options]);


  const canSchedule = useMemo(() => {


    const isCanSchedule =
      message?.dueDate &&
      moment(message?.dueDate).isValid() &&
      messageContact?.id &&
      (
        (!message.isPoll && (message.message?.length > 0 ||
          message?.asset?.url ||
          message?.imageId))
        ||
        (message.isPoll && (isPollValid()))
      )
    return isCanSchedule;
  }, [message?.dueDate, message.isPoll, message.message?.length, message?.asset?.url, message?.imageId, messageContact?.id, isPollValid]);

  const { classes } = props;
  const buttonClasses = [styles?.alarmButton, classes?.alarmButton];
  if (isDisabled) {
    buttonClasses.push(classes?.disabled);
  }


  const contactElement: () => JSX.Element = () => {
    const allowToAdd = /^[0-9@+]+$/.test(searchFilter);

    if (!messageContact) {

      const onSave = (c: any) => {
        dispatch(setAddMsgInCalendarContact({
          name: c?.name ?? c?.waName ?? c?.notify,
          id: c?.id?._serialized ?? c?.waId ?? c?.id ?? c?.lid,
          displayName: c?.displayName ?? c?.waName ?? c?.notify,
          imgUrl: c?.imgUrl,
        }));
        setSearchFilter('');
      };

      const saveFreeInput = () => {
        let whatsappId = searchFilter.replace(/\+/g, '');
        if (!whatsappId.includes('@')) {
          whatsappId = `${whatsappId}@c.us`;
        }
        onSave({ waId: whatsappId })
      }

      const selectContact = (contact) => {
        dispatch(setAddMsgInCalendarContact({
          name: contact?.name,
          id: contact?.id?._serialized,
          displayName: contact?.pushname,
          imgUrl: contact?.imgUrl,
        }))
      }



      return (
        <>
          <Stack direction="row" alignItems={'center'} justifyContent={'start'} ref={anchorRef}>
            <SearchBox
              variant="outlined"
              // sx={{
              //     width: 225
              // }}
              autoComplete='off'
              value={searchFilter}
              onChange={(e) => {
                if (!e.target.value?.includes('+')) {
                  if (isExtensionContext()) {
                    dispatch(updateSearchValue(e.target.value));
                  }
                  setSearchFilter(e.target.value)
                }
              }}
              placeholder="Enter name or number"
              InputProps={{
                endAdornment: allowToAdd ? (
                  <IconButton onClick={() => {
                    saveFreeInput();
                  }} >
                    <AddIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                ) : <SearchIcon sx={{ fontSize: 16 }} />,
              }}
              fullWidth
            />
          </Stack>
          {(searchFilter && searchFilter !== '') && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1000,
                backgroundColor: 'var(--default-background)',
              }}
            >
              <WaContactLazyLoadingVirtuoso
                height={350}
                rowHeightString='44px'
                DBThunks={DBWaContactThunks}
                DBReducer={state => state.DBWaContactReducer}
                selectByQuery={waContactSelectByQuery}
                Component={ContactRow}
                minWidth={'330px'}
                rowWidth={'310px'}
                isWaContact={true}
                searchFilter={searchFilter}
                onClick={selectContact}
                selector={isExtensionContext() && selectFilteredContactList}
              />
            </div>
          )}
        </>

      )
    }

    if (currentChat ?? messageContact) {
      return <RowStack>
        <ContactAvatar
          size={24}
          textSize={12}
          contactId={currentChat?.id?._serialized ?? currentChat?.id ?? messageContact?.id}
          sx={{ m: '0px 8px 0px 10px' }} />
        <span style={{
          maxWidth: '190px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>{contactLabel(currentChat ?? messageContact)}</span>
        {/* <IconButton onClick={() => dispatch(setAddMsgInCalendarContact(null))}>
          <RiCloseCircleFill />
        </IconButton> */}
      </RowStack>;
    }
    // }
  };

  const updateMessage = (_message: UserMessage) => {
    const isPoll = _message.isPoll;
    if (!isPoll) {
      dispatch(addScheduledMessageButtonActions.updateMessage({
        ...message,
        ..._message,
        isPoll: false,
        image: undefined
      }));
    } else {
      dispatch(addScheduledMessageButtonActions.updateMessage({
        ...message,
        ..._message,
      }));
    }
  };

  /*
  const checkAndUpdateIfReplyingToAMessage = async () => {
    const replyData: any = await ContentMessageServiceActions.checkIfReplyingToMessage()

    if (replyData) {
      dispatch(setIsReply(replyData));
    }
  }

  useEffect(() => {
    if (open) {
      try {

        checkAndUpdateIfReplyingToAMessage()

      } catch (error) {
        console.error("Error accessing the React component:", error);
      }
    }
  }, [open]);
  */


  return (
    <BTThemeProvider>
      {/* {errorStateDialog()} */}
      {limitReachedDialog()}
      <Dialog dir="ltr"
        fullWidth={true}
        PaperProps={{
          sx: {
            maxWidth: 700,
            ...BTPaperProps,
          },
        }}
        open={open && !isOverLimit}
        TransitionComponent={Transition}
        onClose={handleClose}
      >


        <Stack sx={{ padding: "0" }} >
          {(false && whatsappSockets.length > 1) && (
            <Alert severity="error" sx={{
              color: "#df0000", ".MuiAlert-message": {
                width: "100%", textAlign: "center",
              }
            }}>
              <div> {whatsappSockets.length} Devices in use, Please close uncessary,    <Link sx={{
                textDecoration: "underline"

              }}
                component="a"
                target="_blank"
                href={`${appConfig.PRICING_PAGE}/devices`}
              > Click here to see all devices </Link></div>

            </Alert>
          )}

          {moment.utc(message?.dueDate).isBefore(
            moment.utc().subtract(1, "minute")
          ) && (
              <Alert sx={{
                alignItems: "center", boxShadow: "none", ".MuiAlert-message": {
                  width: "100%", textAlign: "center", padding: "0"
                }
              }} severity="warning">
                <div>Pay attention, The scheduled time has already passed.</div>
                <div>Please select a future date and time</div></Alert>
            )}

          {!isSocketConnectedToWhatsApp &&

            <Alert severity="info"
              sx={{
                alignItems: "center",
                boxShadow: "none",
                ".MuiAlert-message": {
                  width: "100%",
                  textAlign: "center",
                  padding: "0",
                }
              }}>
              <Box sx={{ textAlign: "center" }}>
                Pay attention, the message will be sent only if the browser is open.
              </Box>
              {!isWaSocketsLimitReached &&
                <RowStack
                  justifyContent={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(toggleSocketModal(true))
                  }}>
                  <SocketConnectionStatusButton />
                  <div style={{ marginLeft: "5px" }}>
                    Connect your device to send messages when the browser is closed
                  </div>
                </RowStack>
              }
            </Alert>

          }
        </Stack>

        <Box sx={{
          m: "10px 0px 0px 140px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontWeight: 600,
        }}>
          <RowStack sx={{ height: '45px' }}>
            {`Schedule a message to`} <Box sx={{
              ml: 1,
            }}>{contactElement()}</Box>
          </RowStack>
        </Box>

        <DialogContent>
          <form className={classes?.container} noValidate>
            <SchedulePicker
              showRecurrence={true}
              showCancelIfReceived={true}
              schedule={message ?? {}}
              handleScheduleChange={handleScheduleChange}
            ></SchedulePicker>
            <Stack>
              <MessageEditor
                showTemplateButtons={false}
                value={message}
                onChange={updateMessage}
                editorContext='scheduleMessage' />
            </Stack>
            <DialogActions>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={theme.spacing(1)}
                  sx={{
                    width: "100%",
                    px: 2,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <WSButton sx={{ flex: "0 0 150px" }} onClick={handleClose}>
                    Cancel
                  </WSButton>
                  <WSButton
                    sx={{ flex: "0 0 250px" }}
                    onClick={() => {
                      analyticsService.event("first_action", {
                        category: "firstAction",
                        action: "first action",
                      });
                      scheduleSend()
                    }}
                    disabled={!canSchedule}
                    variant="contained"
                    color="primary"
                  >
                    Schedule Send
                  </WSButton>
                </Stack>
              </Stack>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog >
      {
        showConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message"
            onClose={onEditRecurringDialogClose}
            options={confirmationDialogOptions}
          />
        )
      }
    </BTThemeProvider >
  );
};

export default AddScheduledMessage;
