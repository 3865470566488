import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export interface CalendarDashboardState {
    contactPicMap?: Record<string, string>, /// [waId]:profilePicURL
}

const initialState: CalendarDashboardState = {
    contactPicMap: {},
};



const CalendarSlice = createSlice({
    name: "Calendars",
    initialState,
    reducers: {
        setContactPicMap: (state, { payload }: PayloadAction<[string, string]>) => {
            state.contactPicMap = { ...state.contactPicMap, [payload[0]]: payload[1] }
        },
    },
});


export const {
    setContactPicMap,
} = CalendarSlice.actions;
export default CalendarSlice.reducer;


