import { IconButton, SvgIconProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import GradientIcon from "./gradient-icon";

export interface MenuIconButtonProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    Icon: React.ReactElement<SvgIconProps>;
    stopColors?: string[];
    hoverStopColors?: string[];
    alt: string;
}

export const MenuIconButton = ({
    onClick,
    Icon,
    stopColors,
    hoverStopColors,
    alt
}: MenuIconButtonProps) => {
    // Generate IDs for the gradients
    const defaultId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
    const hoverId = `gradient-hover-${Math.random().toString(36).substr(2, 9)}`;

    return (
        <Tooltip title={alt} arrow>
            <IconButton onClick={onClick} sx={{
                width: "40px",
                height: "40px",
                '&:hover': {
                    // Change the fill of the SVG icon on hover of the IconButton
                    '.gradient-icon': { fill: `url(#${hoverId})` }
                },
            }}>
                {/* Pass the defaultId and hoverId to the GradientIcon */}
                <GradientIcon aria-label={alt} Icon={Icon} hoverStopColors={hoverStopColors} stopColors={stopColors} defaultId={defaultId} hoverId={hoverId} />
            </IconButton>
        </Tooltip>
    );
};

export default MenuIconButton;