import { NodeType } from '@common-models/bot-node';
import { Stack } from '@mui/system';
import { getIsStartNode } from '@common-reducers/ChatbotSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import StartNodeChip from '../bot-components/start-node-chip';
import classes from "../bt-chatbot.module.scss";
import { nodeTypes } from './bt-nodes-def';
import NodeHeader from './node-header';

type Props = {
    id: string;
    type: NodeType;
}

export default function NodeContainer({ id, type }: Props) {
    const isStartNode = useAppSelector(state => getIsStartNode(state, id))

    const NodeContainerByType = nodeTypes?.[type]?.container
    const isMainNode = nodeTypes?.[type]?.isMainNode || type === 'messageContact' || type === 'status'
    const nodeColor = nodeTypes?.[type]?.color

    return (
        <Stack className={isMainNode ? classes.main_node_wrapper : classes.secondary_node_wrapper}
            sx={{
                border: isMainNode ? 0 : `2px solid ${nodeColor}`
            }}>
            {isStartNode && <StartNodeChip type={'sendMessage'} />}
            <NodeHeader type={type} id={id} />
            <Stack style={{ padding: isMainNode && '10px 10px 15px', }}>
                {NodeContainerByType && <NodeContainerByType id={id} />}
            </Stack>
        </Stack >
    )
}