import classes from './import-button.module.scss';

type Props = {
    startImport: () => Promise<void>;
    isDisabled?: boolean;
}

function ImportButton({ startImport, isDisabled = false }: Props) {
    return (
        <div>
            <button
                disabled={isDisabled}
                onClick={startImport}
                className={classes.importBtn}>
                Import
            </button>
        </div>
    )
}

export default ImportButton