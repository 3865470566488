import { NullableId } from "@common/types/interface";
import DeepDiff from "deep-diff";
import moment, { Moment } from "moment";

export interface MenuState {
  openChatByPhoneNumber: boolean;
  scheduledMessages: boolean;
  broadcastBulkCampaign: boolean;
}

export interface BetaFeatures {
  backendSocket?: boolean;
}

export interface UserInterface {
  _id?: string;
  email?: string;
  fullName?: string;
  profileImg?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  address?: string;
  countryCode?: string;
  vatId?: string;
  phone?: string;
  password?: string;
  peerMap?: {
    [key: string]: Date;
  };
  createdAt?: Moment;
  updatedAt?: Moment;
  unlimited?: boolean;
  apiKey?: string;
  menuState?: MenuState;
  WASession?: string;
  qr?: string;
  qrStatus?: string;
  defaultChatCountryCode?: string;
  beta?: BetaFeatures;
  lastSessionCheck?: string;
  sessionIsActive?: boolean;
  notifications?: {
    badSocket?: {
      count: number;
      lastNotification: string;
    };
  };
  accessToken?: string;
  currentWorkspace?: NullableId;
  isDarkMode?: boolean;
  direction?: string;
  isBoardsPanelOpen?: boolean;
  impersonating?: boolean;
  googleId?: string;
  invoiceDetails?: {
    firstName?: string;
    lastName?: string;
    address?: string;
    address2?: string;
    companyName?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    vatId?: string;
    zip?: string;
  },
  isEmailConfirmed?: boolean;
  emailConfirmationToken?: string;
  role?: string;
  bulkVersion?: "migrating" | "migrated";
  waVersion?: string;

}
export class User {
  _id?: string;
  email?: string;
  fullName?: string;
  profileImg?: string;
  firstName?: string;
  lastName?: string;

  phone?: string;
  password?: string;
  peerMap?: {
    [key: string]: Date;
  };
  createdAt: Moment;
  updatedAt: Moment;
  unlimited?: boolean;
  apiKey?: string;
  menuState?: MenuState;
  WASession?: string;
  qr?: string;
  qrStatus?: string;
  defaultChatCountryCode?: string;
  beta?: BetaFeatures;
  lastSessionCheck?: string;
  sessionIsActive?: boolean;
  notifications?: {
    badSocket?: {
      count: number;
      lastNotification: string;
    };
  };
  accessToken?: string;
  currentWorkspace: NullableId;
  isDarkMode: boolean;
  isBoardsPanelOpen?: boolean;
  role?: string;
  chargeVAT:boolean;
  VATPercent: string;
  invoiceDetails?: {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    address?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    vatId?: string;
  }
  waVersion?: string;

  constructor(data: UserInterface) {
    Object.assign(this, data);

    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }

  get master() {
    let masterId = null;
    const peerList = Object.keys(this.peerMap ?? {})
      .map((clientId) => ({
        clientId,
        updateTime: (this.peerMap ?? {})[clientId],
      }))
      .sort((a, b) => moment(b.updateTime).diff(a.updateTime));
    if (peerList.length > 0) {
      masterId = peerList[0].clientId;
    }
    return masterId;
  }

  get isGuest(): boolean {
    return !/^[a-zA-Z0-9.!#$%&â€™*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      this.email ?? ""
    );
  }

  get displayEmail(): string {
    return this.isGuest ? "Guest" : this.email!;
  }

  compareTo(anotherUser: User | undefined | null): boolean {
    let areEqual = false;
    if (anotherUser !== undefined && anotherUser !== null) {
      const diff = DeepDiff.diff(
        this.toJSON(true),
        anotherUser?.toJSON(true) ?? {}
      );
      areEqual = !diff || diff?.length === 0;
    }
    return areEqual;
  }

  toJSON(forCompare?: boolean): UserInterface {
    const json: any = Object.assign({}, this);
    json.taskMap = Object.entries(json.taskMap ?? {});
    delete json.stateService;
    if (!forCompare) {
      delete json._id;
    }
    return json;
  }
}


export const userDisplayName = (user: Partial<User>) => {
  const names = [];
  if (user?.firstName && user?.firstName?.trim() !== '') {
    names.push(user?.firstName?.trim());
  }
  if (user?.lastName && user?.lastName?.trim() !== '') {
    names.push(user.lastName.trim());
  }
  return names.length > 0 ? names.join(' ') : (user?.email || '');
};
