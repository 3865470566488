import { Stack } from '@mui/system'
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors'
import { DBTaskColumnThunks } from "@common-reducers/DBServiceThunks"
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import BTCheckboxList from '../checkbox-list/bt-checkbox-list'
import { dynamicColumnList, getDynamicColumnListForBTCheckbox } from '@common-reducers/TaskSelectors'

/// before it changed to "Columns" this button was called "Hide"///
export default function BoardHideColumnsDialog() {
    const boardId = useAppSelector(getSelectedBoardId)
    const radioButtonsColumnListForAllColumnsCheckBox = useAppSelector((state) => dynamicColumnList(state, boardId))
    const columnList = useAppSelector((state) => getDynamicColumnListForBTCheckbox(state, 'board'));
    const dispatch = useAppDispatch()

    const isChecked = (column) => (column.isVisible ?? true)

    function handleAllCheckBoxChange(value) {
        dispatch(DBTaskColumnThunks.patchMany(radioButtonsColumnListForAllColumnsCheckBox.map((column, index) => ({
            id: column._id,
            changes: {
                isVisible: value
            }
        }))))
    }

    const handleCheckBoxChange = (value, column) => {
        dispatch(DBTaskColumnThunks.patch({
            entity: {
                ...column,
                isVisible: value
            }
        }))
    }


    const isCheckedRecord: Record<string, boolean> = columnList.reduce((isCheckedRecord, column) => ({
        ...isCheckedRecord,
        [column._id]: isChecked(column)
    }), {});

    return (
        <Stack direction="column" >
            <BTCheckboxList
                isCheckedRecord={isCheckedRecord}
                columnList={columnList}
                handleCheckBoxChange={handleCheckBoxChange}
                handleAllCheckBoxChange={handleAllCheckBoxChange}
            />
        </Stack>
    )
}


