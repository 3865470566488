import BTInputField from '@common-components/bt-input-field/bt-input-field';
import { Box, MenuItem, Popover, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import React from "react";

const CustomDropdown = ({ options, anchorEl, onClose, onOptionSelect, onHoverOption }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const resetSearchValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleOptionSelect = useCallback(
    (option) => {
      onOptionSelect(option);
      resetSearchValue();
    },
    [onOptionSelect, resetSearchValue]
  );

  const onHover = (option, reset) => {
    if (typeof onHoverOption === 'function') {
    onHoverOption(option, reset)
    }
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: '50%',
          width: '250px',
          padding: "10px"
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <BTInputField
        placeholder="Filter options"
        onChange={handleInputChange}
        autoFocus
        fullWidth
        autoComplete='off'
        margin="dense"
        sx={{ marginBottom: "10px" }}
      />
      {filteredOptions.map((option, index) => (
        <MenuItem onMouseEnter={() => onHover(option, true)}
          onMouseLeave={() => onHover(option, false)} key={index} onClick={() => handleOptionSelect(option)}>
          <Stack direction={"row"} alignItems={"center"}>
            {option.role &&
              <Box mr={1}>
                <PersonAvatar
                  size={30}
                  email={option.email}
                  profileImg={option?.profileImg}
                  lastName={option?.lastName}
                  firstName={option?.firstName}
                />
              </Box>
            }
            {(option.icon || option.colorAccent) &&
              <Stack mr={1}>
                {option.icon ? option.icon : <Stack sx={{ color: option.colorAccent }}>
                  <CircleIcon /></Stack>}
              </Stack>

            }
            {option.label}
          </Stack>



        </MenuItem>
      ))}
    </Popover>
  );
};

export default React.memo(CustomDropdown);
