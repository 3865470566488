import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectAllColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentTrigger } from "../automations-helper";


const ColumnChangeTrigger = () => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnName, setColumnName] = React.useState<string>('column');

    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectAllColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);

    const handleColumnChange = (id, name) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { columnId: id })));
        setColumnName(name);
        handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const selectedColumnId = triggerObj.columnId;
        const column = filteredColumns.find(item => item.id === selectedColumnId);
        setColumnName(column?.name ?? "status");
        dispatch(automation.fieldValidityChanged({ component: 'ColumnChangeTrigger', index: 0, field: 'columnId', isValid: !!column?.name }));
    }, [triggerObj, filteredColumns, dispatch]);


    return (
        <Stack>
            <Stack sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    When
                    <AutomationButton
                        componentName='ColumnChangeTrigger'
                        index={0}
                        option='columnId'
                        label={columnName}
                        handleClick={handleClick}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> changes
                </Box>
                <AddAndRemove elements={conditions} componentName="ColumnChangeTrigger" index={0} type={"trigger"} />
            </Stack>


            <PickerColumn
                anchorEl={anchorEl}
                handleClose={handleClose}
                filteredColumns={filteredColumns}
                handleColumnChange={handleColumnChange}
            />


        </Stack>


    )
}
export default ColumnChangeTrigger