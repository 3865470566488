import { getCreditCardLogo } from '@common-components/payment-form/payment-form';
import { BluesnapInvoice } from '@common-models/order';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { selectUser } from '@common-reducers/UserSelectors';
import { modifyPlanName } from '@common-services/utils';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface GroupedInvoice {
    invoice: BluesnapInvoice | null;
    refunds: BluesnapInvoice[];
}

interface Props {
    group: GroupedInvoice;
    index: number;
    handleOpenModal: (event: any, invoiceId: string) => void
}

const GroupedBlueSnapInvoice: React.FC<Props> = ({ group, index, handleOpenModal }) => {
    const hasRefunds = group.refunds.length > 0;
    const panelId = `panel${index}`;
    const paymentMethod = group.invoice['financial-transactions']['financial-transaction']['payment-method'];
    const planName = modifyPlanName(group.invoice?.planName);

    const location = useLocation();
    const user = useAppSelector(selectUser);

    const buildCreditCard = (invoice) => {
        return <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: 140
            }}
        >
            <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                <img style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                }} src={getCreditCardLogo(invoice['financial-transactions']['financial-transaction']['credit-card']?.['card-type'])} alt="" />
            </Stack>
            {<span>****</span>}
            <span>{invoice['financial-transactions']['financial-transaction']['credit-card']?.['card-last-four-digits'].toString().padStart(4, '0')}</span>
        </div>
    }

    const buildPaypal = (invoice) => {
        return <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: 140
            }}
        >
            <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                <img style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                }} src={getCreditCardLogo('Paypal')} alt="" />
            </Stack>
        </div>
    }

    const buildBalance = () => {
        return <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: 140
            }}
        >
            <span>Balance</span>
        </div>
    }

    const [expandedAccordion, setExpandedAccordion] = useState<boolean>(hasRefunds);

    const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(!isExpanded);
    };

    let paymentMethodElement = null;
    switch (paymentMethod) {
        case 'Credit Card':
            paymentMethodElement = buildCreditCard(group.invoice);
            break;
        case 'PayPal':
            paymentMethodElement = buildPaypal(group.invoice);
            break;
        case 'Balance':
            paymentMethodElement = buildBalance();
            break;
    }

    return (
        <Accordion
            key={index}
            expanded={expandedAccordion}
            onChange={handleAccordionChange}
            sx={{
                "& .MuiAccordionSummary-content": {
                    cursor: 'default',
                },
            }}
        >
            <AccordionSummary
                aria-controls={`${panelId}bh-content`}
                id={`${panelId}bh-header`}
            >
                <Stack>
                    {planName && (
                        <Box>
                            {planName}
                        </Box>
                    )}
                    {planName && <Divider sx={{ my: 1 }} />}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <div style={{ userSelect: 'text' }}>{group.invoice['invoice-id']}</div>
                        <Box sx={{ width: 90 }}>{group.invoice['financial-transactions']['financial-transaction']['date-created']}</Box>
                        {paymentMethodElement}
                        <div style={{ textAlign: 'center' }}>{group.invoice['financial-transactions']['financial-transaction'].amount}</div>
                        <div style={{ textAlign: 'center' }}>{group.invoice['financial-transactions']['financial-transaction'].currency}</div>
                        <Button
                            sx={{
                                cursor: 'pointer',
                                margin: '0',
                                textDecoration: 'none',
                                borderRadius: '12px',
                                backgroundColor: 'var(--billing-btn-bg)',
                                color: 'var(--link-text-color)',
                                fontSize: '12px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                padding: '6px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                            }}
                            onClick={() => window.open(`/invoices/${group.invoice['invoice-id']}${location.search}`, '_blank')}
                        >
                            View
                        </Button>
                        {(user?.role === 'support' || user?.role === 'admin') && (
                            <Button
                                sx={{
                                    cursor: 'pointer',
                                    margin: '0',
                                    textDecoration: 'none',
                                    borderRadius: '12px',
                                    backgroundColor: 'var(--billing-btn-bg)',
                                    color: 'var(--link-text-color)',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    padding: '6px',
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                }}
                                onClick={(e) => handleOpenModal(e, group.invoice['invoice-id'].toString())}
                            >
                                Refund
                            </Button>
                        )}
                    </Box>
                </Stack>
            </AccordionSummary>
            {hasRefunds && (
                <AccordionDetails>
                    <Typography component="div">
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {group.refunds.length > 0 && (
                                <Box>
                                    <b>Refunds:</b>
                                    {group.refunds.map((refund, refundIndex) => (
                                        <Box
                                            key={refundIndex}
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(7, 1fr)',
                                                alignItems: 'center',
                                                py: 2,
                                                px: 0,
                                                gap: 2,
                                                borderBottom: '1px solid lightgrey',
                                            }}
                                        >
                                            <div>{refund['invoice-id']}</div>
                                            <div>{refund['financial-transactions']['financial-transaction']['date-created']}</div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Stack direction="row" alignItems="center" sx={{ width: 40, border: '1px solid #0000001a' }}>
                                                    <img
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                        src={getCreditCardLogo(refund['financial-transactions']['financial-transaction']['credit-card']?.['card-type'])}
                                                        alt=""
                                                    />
                                                </Stack>
                                                <span>{refund['financial-transactions']['financial-transaction']['credit-card']?.['card-last-four-digits'].toString().padStart(4, '0')}</span>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>{refund['financial-transactions']['financial-transaction'].amount}</div>
                                            <div style={{ textAlign: 'center' }}>{refund['financial-transactions']['financial-transaction'].currency}</div>
                                            <Button
                                                sx={{
                                                    cursor: 'pointer',
                                                    margin: '0',
                                                    textDecoration: 'none',
                                                    borderRadius: '12px',
                                                    backgroundColor: 'var(--billing-btn-bg)',
                                                    color: 'var(--link-text-color)',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    lineHeight: '16px',
                                                    padding: '6px',
                                                    textAlign: 'center',
                                                    textTransform: 'capitalize',
                                                }}
                                                onClick={() => window.open(`/invoices/${refund['invoice-id']}${location.search}`, '_blank')}
                                            >
                                                View
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Typography>
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default GroupedBlueSnapInvoice;