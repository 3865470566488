import { FC, useState } from "react";

import BTButton from "@common-components/bt-button/bt-button";
import { BoardView } from "@common-models/board-views";
import { Box, Stack } from "@mui/system";
import { getCurrentBoardId } from "@common-reducers/BoardsSelectors";
import { boardViewSelectByQuery, viewFiltersSelectByQuery } from "@common-reducers/DBServiceThunks";
import { boardTaskSelector, currentTasks } from "@common-reducers/TaskSelectors";
import { saveFilterAsBoardViewThunk } from "@common-reducers/TaskThunks";
import { clearAllFiltersThunks, getShownGroups } from "@common-reducers/TasksFilterPanelThunks";
import { selectUserId } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";
import AdvancedFilters, { ViewFilter } from "./advanced_filters";
import classes from './filter_styles.module.scss';
import { QuickFilterPanel } from "./quick_filter_panel";

interface FilterProps {
    isQuickFilter?: boolean
}
const FilterSection: FC<FilterProps> = ({ isQuickFilter }) => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const rawTasks = useAppSelector(boardTaskSelector);
    const groups = useAppSelector((state) => getShownGroups(state));
    const tasks = rawTasks.filter(task => groups.some(group => group._id === task.groupId))
    const currentTasksList = useAppSelector((state) => currentTasks(state))

    const boardId = useAppSelector(getCurrentBoardId)

    const userId = useAppSelector(selectUserId)
    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
    const [boardView]: BoardView[] = useAppSelector((state) => boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: boardId, _id: currentBoardView.id }));

    const viewId = boardView?._id ?? boardId
    const viewFilters: ViewFilter[] = useAppSelector((state) =>
        viewFiltersSelectByQuery(
            state.DBViewFiltersReducer,
            {
                viewId,
                $or: [{ deleted: { $exists: false } }, { deleted: false }],
            },
            null,
            {
                updatedAt: 1
            }
        )
    );

    const saveAsNewBoardView = () => {
        dispatch(saveFilterAsBoardViewThunk({ userId, boardId }));

    }

    const [quickFilter, setQuickFilter] = useState<boolean>(isQuickFilter)
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                maxWidth: '800px',
                minWidth: '600px',
                maxHeight: '440px',
                width: '800px',
                fontSize: '14px',
            }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '24px', marginBottom: '24px' }}>
                <Stack gap="8px" direction={'row'} alignItems="flex-end" >
                    <b style={{ fontSize: '16px' }}>{quickFilter ? t('filterSection.quickFilters') : t('filterSection.advancedFilters')}</b>
                    <span style={{ opacity: 0.75 }}>
                        {t('filterSection.showingItems')}{` `}
                        {currentTasksList?.length !== tasks?.length ? `${currentTasksList?.length} of` : 'all'}{` `}
                        {tasks?.length}{` `}
                        {t('filterSection.items')}
                    </span>
                </Stack>
                <Stack gap="4px" direction={'row'} >
                    <BTButton disabled={!viewFilters.length} onClick={() => dispatch(clearAllFiltersThunks())} btvariant={'text'} >{t('filterSection.clearAll')}</BTButton>
                    <BTButton onClick={() => saveAsNewBoardView()} btvariant={'secondary'}  >{t('filterSection.saveAsNewView')}</BTButton>
                </Stack>
            </div>
            <Box sx={{ overflowY: !quickFilter ? 'scroll' : 'hidden' }}>
                <div className={classes.mainScrollContainer} style={{
                    display: 'flex',
                    gap: '48px',
                    overflowX: 'auto',
                    width: '100%',
                    paddingBottom: '16px'
                }}>
                    {quickFilter ? (<QuickFilterPanel />) : (<AdvancedFilters />)}
                </div>
            </Box>
            <div style={{ borderTop: '1px solid #e0e0e0', paddingTop: '20px' }}>
                <BTButton btvariant={"secondary"}
                    sx={{
                        alignSelf: 'flex-start',
                    }} onClick={() => setQuickFilter(!quickFilter)}>
                    {t('filterSection.switchTo')} {!quickFilter ? t('filterSection.quickFilters') : t('filterSection.advancedFilters')}
                </BTButton>
            </div>
        </div >
    );
};

export default FilterSection;