
import { createAsyncThunk } from "@reduxjs/toolkit";

import { BLUETICKS_TOKEN_KEY } from "@common/reusable/common";
import { MessageServiceActions } from "@common/services/messageServiceActions";


//import { googleLogout } from '@react-oauth/google';
import {
  authenticate
} from "@common-reducers/backend-api/backend-api";
import {
  getContextName,
  isWebPage
} from "@common-services/detect-context";



export const initAuth = createAsyncThunk(
  "initAuth",
  async (_, thunkApi) => {

    console.log(`initAuth called in context: ${getContextName()}`);

    let accessToken: string | null = localStorage.getItem(BLUETICKS_TOKEN_KEY);

    if (isWebPage()) {
      const params = new URLSearchParams(window.location.search);
      accessToken = accessToken ?? params.get('accessToken');
    }

    if (!accessToken) {
      console.log("Trying to get token from background");
      try {
        const res = await MessageServiceActions.postMessageToBackgroundWithResponse<{ accessToken: string }>("get-token", {});
        accessToken = res?.accessToken ?? null;
        console.log(`Received access token: ${res?.accessToken}`);
      } catch {
        console.log("No token in backgorund found!");
      }
    }

    if (accessToken) {
      await thunkApi.dispatch(authenticate.initiate({ strategy: "jwt", accessToken }));
    }
  }
);


export const externalAccessToken = createAsyncThunk<
  void,
  string,
  { state }
>("externalAccessToken", (accessToken, thunkApi) => {
  if (!accessToken) {
    return;
  }
  const state = thunkApi.getState();
  if (state.UserReducer.accessToken !== accessToken) {
    thunkApi.dispatch(
      authenticate.initiate({
        strategy: "jwt",
        accessToken,
      })
    );
  }
});
