import { appConfig } from "@common/config/app.config";

// checks if image's url is rela emage
import { fileTypeFromStream } from 'file-type';

// checks if image's url is a real image
export const isImageUrl = async (url: string) => {
  if (!url) return false;

  try {
    const response = await fetch(url);
    const type = await fileTypeFromStream(response.body);
    return type?.mime?.startsWith('image/');
  } catch {
    return false;
  }
};

// function that starts the process of uploading the image to google cloud bucket
export async function handleUpload(base64file: string, mimeType: string) {
  // set the statu of the upload to the window
  //objrct

  //get signe url
  const signedURL = await getSignedURL(base64file, mimeType);

  const res2 = await fetch(base64file);
  const blob = await res2.blob();
  // check if we have a signed URL
  if (signedURL.url) {
    const uploadResponse = await uploadImage(signedURL.url, blob, mimeType);
    return { image_url: signedURL.publicUrl, uploadResponse };
  }
}

// function that gets the signed url
export const getSignedURL = async (base64: string, mimeType: string) => {
  const res = await fetch(`${appConfig.API_ENDPOINT}/actions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: `{\n    "cmd": "signed-upload-url",\n\t\t"contentType": "${mimeType}"\n}`,
  });
  const signedURL = await res.json();

  return signedURL;
};

// function that uploads image to the google bucket
export const uploadImage = async (
  signedURL: string,
  blob: any,
  mimeType: string
) => {
  return await fetch(signedURL, {
    method: "PUT",
    headers: {
      "Content-Type": mimeType,
    },
    body: blob,
  });
};
