import randomstring from "randomstring";
import { getStorageKey, setStorageKey } from "./storage";
import { UserInterface } from "@common/models/user";
import { VznLytics } from "./vznlytics";
import { MeasurementProtocolItem, sendEvent } from "./measurement-protocol";
import { Moment } from "moment";
import moment from "moment";

let _instance: AnalyticsService;

export interface EventOptions {
  category?: string;
  action?: string;
  label?: string;
  value?: any;
  [key: string]: any;
}

export interface TransactionData {
  transaction_id?: string;
  affiliation?: string;
  value?: number;
  coupon?: string;
  currency?: string;
  items: MeasurementProtocolItem[]
}

export class AnalyticsService {
  cid?: string;
  fbqLoaded: any;
  bizsdk: any;
  bizsdkUserData: any;
  vznLytics: VznLytics = new VznLytics();
  user?: UserInterface;
  initPromise?: Promise<void>;
  sessionID: string;
  startTime: Moment;

  constructor() {
    if (!_instance) {
      _instance = this;
      _instance.sessionID = randomstring.generate(16);
      _instance.startTime = moment();
      _instance.initPromise = _instance.setUid();
    }

    return _instance;
  }

  get hasGTAG() {
    return typeof window === "object" && window.gtag;
  }

  get hasFBQ() {
    return typeof window === "object" && window.fbq;
  }

  defaultParams() {
    return {
      engagement_time_msec: moment().diff(moment(this.startTime), 'milliseconds'),
      session_id: this.sessionID,
    }
  }

  async setUid() {
    let cid: string = (await getStorageKey("cid")) as string;
    if (!cid) {
      console.log("Generating new cid");
      cid = randomstring.generate(12);
      setStorageKey("cid", cid);
    } else {
      console.log(`Found cid ${cid}`);
    }

    this.cid = cid;
  }

  identify(user?: UserInterface) {
    this.user = user;
  }

  clearUserIdentity() {
    delete this.user;
  }

  async event(eventName: string, options: EventOptions) {
    const eventNameClean = eventName?.replace(/-/g, '_') ?? '';
    if (this.hasGTAG) {
      const sendOptions = JSON.parse(JSON.stringify(options))
      console.log(sendOptions);

      window.gtag(
        "event",
        eventNameClean,
        sendOptions
      );
    } else {
      console.log('waiting for init...');
      await this.initPromise;
      console.log(`init complete ${this.cid}`);
      sendEvent({
        client_id: this.cid,
        events: [{
          name: eventNameClean,
          params: {
            ...this.defaultParams(),
            ...options,
          }
        }]
      })
    }

    if (this.hasFBQ) {
      window.fbq('track', eventName, options);
    }

    const { label, value, category, action, email, ...eventOptions } = options;

    this.vznLytics.event({
      event_name: eventName,
      label: label,
      category: category,
      sub_category: action,
      value: value,
      email: email,
      data: eventOptions,
      entity_id: this.user?._id,
      entity_type: "user",
      entity_display_name: [this.user?.firstName, this.user?.lastName]
        .filter((x) => x)
        .join(" "),
    });
  }

  async transaction(transaction: TransactionData, sendTo?: string[]) {
    if (this.hasGTAG) {
      window.gtag(
        "event",
        "purchase",
        Object.assign(transaction, { send_to: "all" })
      );
    } else {
      await this.initPromise;
      sendEvent({
        client_id: this.cid,
        events: [{
          name: 'purchase',
          params: {
            ...this.defaultParams(),
            ...transaction
          }
        }]
      })
    }

    if (this.hasFBQ) {
      window.fbq('track', 'Purchase', {
        value: transaction.value,
        currency: transaction.currency || 'USD',
        content_ids: transaction.items.map(item => item.item_id),
        content_type: 'product'
      });
    }

    this.vznLytics.event({
      event_name: "transaction",
      category: "ecommerce",
      value: transaction.value,
      entity_id: this.user?._id,
      entity_type: "user",
      entity_display_name: [this.user?.firstName, this.user?.lastName]
        .filter((x) => x)
        .join(" "),
    });
  }

  async pageView(path: string, title?: string) {
    if (this.hasGTAG) {
      window.gtag('event', 'page_view', {
        page_title: title,
        page_location: path
      });
    } else {
      await this.initPromise;
      sendEvent({
        client_id: this.cid,
        events: [{
          name: 'page_view',
          params: {
            ...this.defaultParams(),
            page_title: title,
            page_location: path
          }
        }]
      })
    }

    if (this.hasFBQ) {
      window.fbq('track', 'PageView');
    }

    this.vznLytics.event({
      event_name: "pageView",
      category: "general",
      value: path,
      entity_id: this.user?._id,
      entity_type: "user",
      entity_display_name: [this.user?.firstName, this.user?.lastName]
        .filter((x) => x)
        .join(" "),
    });
  }
}
