import { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { CellProps } from "../cell-interface";
import classes from "./rating_cell.module.scss";
import Rating from '@mui/material/Rating';


export default function RatingCell({ cellValue, onUpdate,readOnly }: CellProps) {
  const [value, setValue] = useState<number | undefined>();
  function updateRating(newValue) {
    setValue(Number(newValue.currentTarget.value));
    onUpdate(Number(newValue.currentTarget.value));
  }

  function handleClearValue() {
    setValue(undefined);
    onUpdate(undefined);
  }
  return (
    <div className={classes.ratingCell}>
      <Rating size="small"
        value={(value || cellValue) ?? 0}
        readOnly={readOnly}
        onChange={(newValue) => {
          updateRating(newValue);
        }}
      />
       {(cellValue && !readOnly )  && (
          <RiCloseCircleFill
            onClick={handleClearValue}
            className={classes.clearIcon}
          />
        )}
    </div>
  );
}
