import ThemeAsset from "@common-components/theme/theme-asset";
import { userDisplayName } from "@common-models/user";
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableFooter, TableRow } from '@mui/material';
import { selectUserObject } from "@common-reducers/UserSelectors";
import { useGetInvoiceMutation, useGetShopperInfoMutation, useGetUserPaymentParamsMutation } from '@common-reducers/backend-api/backend-api';
import { modifyPlanName } from "@common-services/utils";
import { decode } from 'html-entities';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FiPrinter } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMatch } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import classes from './invoice-view.module.scss';
import { countryList } from "@common-components/edit-contact-details-form/edit-contact-details-form";

export default function InvoiceView() {
    const match = useMatch('/invoices/:invoiceId')
    const invoiceId = useMemo(() => match.params.invoiceId, [match]);
    const [getInvoice, { data: invoice }] = useGetInvoiceMutation();
    const [getSKU, { data: skuData }] = useGetUserPaymentParamsMutation();
    const [getShopperInfo, { data: shopper, isLoading }] = useGetShopperInfoMutation();

    const invoiceRef = useRef<HTMLDivElement>();
    const user = useSelector(selectUserObject);

    const shopperContactInfo = useMemo(() => {
        return shopper?.["shopper-info"]?.["shopper-contact-info"];
    }, [shopper]);

    const firstName = shopperContactInfo?.["first-name"];
    const lastName = shopperContactInfo?.["last-name"];
    const fullName = [firstName, lastName].filter(x => x).join(' ') || userDisplayName(user);
    const address1 = shopperContactInfo?.["address1"];
    const address2 = shopperContactInfo?.["address2"];
    const zip = shopperContactInfo?.["zip"];
    const shopperCountry = shopperContactInfo?.["country"];
    const companyName = shopperContactInfo?.["company-name"];
    const chargeVat = user?.chargeVAT
    const vatPercent = Number(user?.VATPercent)

    const countryObject = countryList.find(country => country.isoCode.toLowerCase() === shopperCountry);

    const vatId = user?.invoiceDetails?.vatId;
    const secondaryAddress = [
        address2,
        zip,
        countryObject?.name
    ].filter(x => x).join(', ');


    const printComponentRef = useRef(null);

    useEffect(() => {
        getInvoice({ invoiceId });
        getShopperInfo();
    }, [getInvoice, getShopperInfo, invoiceId])

    const createdAt = invoice ? moment(invoice['financial-transactions']['financial-transaction']['date-created'], 'DD-MMM-YY') : undefined;



    // const subscription = shopper?.
    const currency = invoice ? invoice['financial-transactions']['financial-transaction'].currency : '';
    const total = invoice ? `${invoice['financial-transactions']['financial-transaction'].amount} ${currency}` : '';
    const sku = invoice ? invoice['financial-transactions']['financial-transaction'].skus.sku['sku-id'] : undefined;
    const paymentMethod = invoice ? invoice["financial-transactions"]["financial-transaction"]["payment-method"] : '';

    const totalPrice = invoice ? invoice['financial-transactions']['financial-transaction'].amount : 0;

    const priceWithoutVAT = Math.round(totalPrice/(1+vatPercent) *100)/100; 
    const vatPrice = (totalPrice - priceWithoutVAT).toFixed(2);
    const totalPriceWithoutVATwithCurrency = priceWithoutVAT + ' ' + currency;
    console.log(`Payment method: ${paymentMethod}`)

    useEffect(() => {
        const productId = invoice ? invoice['financial-transactions']['financial-transaction'].skus.sku['sku-id'].toString() : '';
        if (sku && productId) {
            getSKU({
                productId
            });
        }
    }, [getSKU, invoice, sku])

    const contactItemList = (invoice && skuData) ? [
        fullName,
        `${companyName ? companyName : ""} ${vatId ? `(VAT ID: ${vatId})` : ''}`,
        address1,
        secondaryAddress,
        user?.email
    ] : [];



    const downloadInvoice = useCallback(async () => {
        const element = invoiceRef.current;

        const canvas = await html2canvas(element, {
            scale: 3,
        });
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('invoice.pdf');
    }, [])

    const buildInvoice = () => <Stack direction="row" ref={invoiceRef}
        alignItems="center"
        justifyContent="center"
        sx={{
            boxSizing: 'border-box',
            height: 1,
            '@media print': { background: "#fff" }
        }}>
        <Table className={classes.invoiceTable} sx={{
            p: 2,
            height: '95%',
            width: '90%',
            margin: 'auto',
            boxSizing: 'border-box'
        }}>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Box >
                            <ThemeAsset
                                assetName='logo.svg'
                                style={{
                                    marginBottom: "20px",
                                    maxWidth: "165px",
                                }} />
                        </Box>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Stack spacing={0.5} sx={{
                            textAlign: 'right',
                            fontSize: 12
                        }}>
                            <Box>Invoice #{invoice['invoice-id']}</Box>
                            <Box>Subscription #{invoice.subscriptionId}</Box>
                            <Box>Created: {createdAt.format('DD MMM YYYY')}</Box>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}>
                        <Stack alignItems="center" spacing={1}>
                            <Box sx={{ fontWeight: 600 }}>Blueticks</Box>
                            <Box>Blueticks, Bilu 38, Herzelia, Israel</Box>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.headerRow}>
                    <TableCell colSpan={2}>
                        <Box>Bill To</Box>
                    </TableCell>
                    <TableCell colSpan={2} sx={{ textAlign: 'right' }}>
                        <Box>Ship To</Box>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.invoiceDetailsSection}>
                    <TableCell colSpan={2}>
                        <Stack spacing={1}>
                            {contactItemList.filter(x => x).map((item, index) => <Box key={index} >{item}</Box>)}
                        </Stack>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Box sx={{ fontWeight: 600, textAlign: 'right' }}>
                            Electronic Delivery
                        </Box>
                    </TableCell>
                </TableRow>
                {paymentMethod && <TableRow className={classes.headerRow}>
                    <TableCell colSpan={4}>
                        <Box>Payment Method</Box>
                    </TableCell>
                </TableRow>}
                {paymentMethod === 'Credit Card' && <TableRow>
                    <TableCell colSpan={4}>
                        <Box>{`${invoice['financial-transactions']['financial-transaction']['credit-card']?.['card-type']} (ending ${invoice['financial-transactions']['financial-transaction']['credit-card']?.['card-last-four-digits'].toString().padStart(4, '0')})`}</Box>
                    </TableCell>
                </TableRow>}
                {paymentMethod === 'PayPal' && <TableRow>
                    <TableCell colSpan={4}>
                        <Box>PayPal Transaction ID: {invoice["financial-transactions"]["financial-transaction"]["paypal-transaction-data"]["pp-transaction-id"]}</Box>
                    </TableCell>
                </TableRow>}
                <TableRow className={classes.headerRow}>
                    <TableCell colSpan={2}>Item</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>Quantity</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>Price</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{modifyPlanName(skuData.plan.planName)}</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>1</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>
                        
                        {!user?.chargeVAT ? total :  totalPriceWithoutVATwithCurrency }
                       
                        </TableCell>
               
                </TableRow>
                      
                {(chargeVat ) && <TableRow>
                    <TableCell colSpan={2} >VAT ({vatPercent*100}%)</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>0</TableCell>
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>{vatPrice} {currency}</TableCell>
                </TableRow>}
                <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={1} sx={{ textAlign: 'right' }}>
                        {`Total: ${total}`}
                    </TableCell>
                </TableRow>
            </TableBody>
            <TableFooter sx={{ fontSize: 12, mt: 'auto' }}>
                <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                        <Box sx={{ fontWeight: 600 }}>{`This transaction will appear on your bank statement as ${decode(invoice['financial-transactions']['financial-transaction']['soft-descriptor'])}`}</Box>
                        <Box sx={{ mt: 3 }}>BlueSnap Inc. 800 South Street, Waltham, MA 02453</Box>
                        <Box sx={{ mt: 'auto' }}>
                            Web: <a target="_blank" href="https://home.bluesnap.com/contact-us" rel="noreferrer">https://home.bluesnap.com/contact-us</a> | Tax ID: 45-0479415 | VAT ID: EU826002156
                        </Box>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    </Stack>

    const buildLoader = () => <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <CircularProgress />
    </Stack>

    return (
        <Stack ref={printComponentRef} direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ height: '100vh', '@media print': { background: "#fff" } }}>
            <Paper sx={{
                width: 595, height: 842,
                '@media print': {
                    width: '210mm',
                    height: '250mm',
                    boxShadow: "none"
                }
            }}>
                {(invoice && skuData) ? buildInvoice() : buildLoader()}
            </Paper>
            {(invoice && skuData) && <Box sx={{
                position: 'fixed',
                top: 16,
                right: 16,
                '@media print': {
                    display: 'none'
                }
            }}>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={downloadInvoice}>
                        <Box mr={1}>Download</Box>
                        <DownloadIcon />
                    </Button>

                    <ReactToPrint
                        copyStyles={true}
                        trigger={() =>
                            <Button variant="contained">
                                <Box mr={1}> Print </Box>
                                <FiPrinter />
                            </Button>
                        }
                        content={() => printComponentRef.current} />
                </Stack>
            </Box>}
        </Stack >
    )
}