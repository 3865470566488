import { CellProps } from '@common-components/tasks/columns-cells/cell-interface'
import { TextField } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useState } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'

type Props = {}

export default function NumberFormCell({
  cellValue,
  taskId,
  columnId,
  groupId,
  onUpdate,
  isFormInEditMode
}: CellProps) {
  const [value, setValue] = useState<number | ''>('')
  function handleNumberChange(event) {
    setValue(event.target.value)
    onUpdate(event.target.value);
  }
  function handleClearValue() {
    setValue('')
    onUpdate(undefined);
  }
  return (
    <Stack direction='row' sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    }}>
      <TextField
        style={{ width: '260px', }}
        disabled={isFormInEditMode ? true : false}
        id="outlined-number"
        type="number"
        value={value}
        onChange={(event) => handleNumberChange(event)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {cellValue && (
        <RiCloseCircleFill
          onClick={handleClearValue}
        // className={classes.clearIcon}
        />
      )}
    </Stack>
  )
}