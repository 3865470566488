import React from 'react'
import { modalTextStyle } from './bt-chatbot-modal'
import BTInputField from '@common-components/bt-input-field/bt-input-field'

type Props = {
    value?: string;
    onChange?: (newValue: string) => void;
    onClick?: (e: any) => void;
    startAdornment?: any;
    endAdornment?: any;
    sx?: any;
    placeholder?: string;
    onBlur?: (e: any) => void;
    type?: 'text' | 'number';
}

export default function BotInputField({
    value,
    onChange,
    onClick,
    startAdornment,
    endAdornment,
    sx,
    placeholder,
    onBlur,
    type = 'text',
}: Props) {
    return (
        <BTInputField
            value={value}
            type={type}
            onChange={({ target }: any) => onChange(target.value)}
            onClick={onClick}
            placeholder={placeholder}
            onBlur={onBlur}
            sx={{
                ".MuiOutlinedInput-notchedOutline": {
                    border: "0px",
                    backgroundColor: 'rgb(245, 246, 250)',
                },
                "&.Mui-focused": {
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                    },
                },
                "&: hover": {
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                    },
                },
                ".MuiOutlinedInput-input": {
                    ...modalTextStyle,
                    zIndex: 3,
                    lineHeight: '19px',
                },
                height: '40px',
                width: '100%',
                ...sx,
            }}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
        />
    )
}