import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { getSelectedBoardCompleteUserListEqual } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import CustomDropdown from "../automation-dropdown";
import NotifyButton from "./create-notify-popover";
import { setCurrentAction } from "../automations-helper";


const NotifyPersonAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [text, setText] = useState('someone');

    const { attemptedSubmission, validationStates, actions } = useAppSelector((state) => state.AutomationReducer);
    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual);
    const prefix = index === 0 ? 'Then' : 'and then'

    const userListMapped = userList.map(({ profile: { firstName, lastName, ...restProfile }, ...restObj }) => ({
        ...restObj,
        ...restProfile,
        label: `${firstName} ${lastName}`
    }));


    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleOptionSelect = (option) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, userId: option._id })));
        setText(option.label);
        handleClose();
    };
    const handleMessageSet = (value) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, dynamicMessage: value })));
    }

    const onHover = (option, show) => setText(show ? option.label : 'someone');


    useEffect(() => {
        if (actions) {
            const selectedUserId = actions[index].userId;
            const user = userListMapped.find(item => item._id === selectedUserId);
            setText(user?.label ?? "someone");
            dispatch(automation.fieldValidityChanged({ component: 'NotifyPersonAction', index, field: 'userId', isValid: !!selectedUserId }));
            dispatch(automation.fieldValidityChanged({ component: 'NotifyPersonAction', index, field: 'dynamicMessage', isValid: !!actions[index].dynamicMessage }));
        }
    }, [userListMapped, actions, index, dispatch]);


    return (
        <Stack sx={{ fontSize: "24px" }} direction="row" justifyContent={"space-between"}>
            <Box >
                {prefix}
                <NotifyButton index={index} submitMessage={handleMessageSet} message={actions[index].dynamicMessage} componentName={"NotifyPersonAction"} />
             

                <AutomationButton
                    componentName='NotifyPersonAction'
                    index={index}
                    option='userId'
                    label={text}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="NotifyPersonAction" />
            <CustomDropdown
                options={userListMapped}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={onHover}
            />
        </Stack>
    );
};

export default NotifyPersonAction;
