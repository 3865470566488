export const appConfigStaging = {
  DEFAULT_TITLE: 'Blueticks',
  asset_key: 'blueticks',
  pricingOptions: 'extension',
  google_client_id: '143723739146-oa75527ckplc3kb9nk8q41redtoq6t18.apps.googleusercontent.com',
  GTM_ID: '',
  GA_ID: '',
  CLARITY_ID:'',
  TIKTOK_ID:'',
  FB_PIXEL_ID: '',
  AW_ID: '',
  WS_ID: '',
  HJ_ID: 0,
  HJ_SV: 0,
  EXTENTION_ID: "elmlpmbhicpdnbpnicbmgjhbolmlfmnf",
  GATEWAY_ENDPOINT: "https://stg-gateway.blueticks.co",
  NOTIFIER_ENDPOINT: "https://stg-api.blueticks.co",
  VZNLYTICS_ENDPOINT: "https://analytics.blueticks.co",
  WS_ENDPOINT: "wss://stg-api.blueticks.co",
  API_ENDPOINT: "https://stg-api.blueticks.co",
  PRICING_PAGE: "https://staging--blueticks-app.netlify.app",
  APP_ENDPOINT: "https://staging--blueticks-app.netlify.app",
  HOMEPAGE: "https://blueticks.co",
  LOGO:"/img/tudu-logo.svg",
  LOGO2:"/img/tudo-logo-icon.svg",
  PAYPAL_CLIENT_ID:
    "ARQG3WFWCo5c_cl0idcg5fPastyJNcC3Gpzgh-7y7wHqliqw8heanLqZZLDQK-JNc5Bt2tHNJUkVx5pC",
  ENV: "staging",
  BLUESNAP_ENDPOINT: "https://sandbox.bluesnap.com",
  BLUESNAP_STORE_ID: "296929",
  BLUESNAP_REDIRECT_ENC:
    "y3BO7mSu04vXEh55QSFI51vnnO%2FrL2uZ10%2FPUF2Fh8tM%2BuK1SbYHdaA64niusbZmSEzNN%2B8UxIi5s2XCYmwZBC5nSCktB%2Fecf9WpUAVm7dThQyrBSGNEc8rMNTv8OPFzs55%2B5SK6yOAzB1mmeWYME1RPVUsco5idX4uxIUpmzFXkrAfzgUdezK%2BtTWmKvtGXG%2FudtOXj2tdcR6XJcZDQBUGHsDmn0cVumqSNZ98gQSS3l5R3EcBxJRNX7RVdp3EH",
  
  extensionStoreLink: "https://chrome.google.com/webstore/detail/blueticks/adgnjhngogijkkppficiiepmjebijinl",
  PRODUCTS: {
    personal: "PROD-72S99618JL130302",
    professional: "PROD-47W322527S599700R",
  },
};
export default appConfigStaging;
