import React from "react";
import { useDropzone } from "react-dropzone";
import classes from "./dropzone.module.scss";

export default function Dropzone({ onDrop }) {
  const { getRootProps,  isDragActive } = useDropzone({
    onDrop, noClick: true
  });
  const rootProps = getRootProps();
//   const inputProps = getInputProps();
  return (
    <div  {...rootProps}>

      {isDragActive ? (
        <span className={classes.dropzoneContent}></span>
      ) : (
        <span className={classes.dropzoneContentNone}></span>
      )}
    </div>
  );
}
