
import { ModeEditOutlineOutlined, MoreVert } from '@mui/icons-material';
import { CircularProgress, IconButton, Menu } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { setActiveBulkView, setCurrentTemplate, setIsLoadingDuplicateTemplate, setIsTemplateDropdownModalOpen, setNewCampaign } from '@common-reducers/CampaignReducer';
import { duplicateTemplateForCampaignsThunk } from '@common-reducers/CampaignThunks';
import { DBTemplateThunks, templateSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { MdOutlineContentCopy } from 'react-icons/md';
import classes from './bulk_item_row_menu.module.scss';
import TemplateRowDetails from './template-row-details';

type Props = {
    itemId: string;
    index: number;
}

export default function BulkTemplateDialogRow({
    itemId,
    index,
}: Props) {
    const dispatch = useAppDispatch()
    const template = useAppSelector((state) => templateSelectOneObjectByQuery(state.DBTemplateReducer, {
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ],
        _id: itemId,
    }))
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)
    const [isLoadingDuplicateTemplate, setIsLoadingDuplicateTemplate] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false)




    const handleOpenMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleEditClick = (template, index) => {
        handleCloseMenu()
        dispatch(setCurrentTemplate(template))
        if (activeBulkView === "campaignManagementView") {
            dispatch(setActiveBulkView("campaignNewTemplateViewFromManagement"))
        }
        else {
            dispatch(setActiveBulkView("campaignNewTemplateViewFromNewCampaignView"))
        }
    }
    const handleDuplicateClick = async (template, index) => {
        setIsLoadingDuplicateTemplate(true)
        handleCloseMenu()
        const newTemplateId = await dispatch(duplicateTemplateForCampaignsThunk({ oldTemplateId: template?._id, }))

        console.log('newTemplateId', newTemplateId)

        await dispatch(DBTemplateThunks.find({
            deleted: false,
            $sort: { createdAt: -1 }
        }))

        setIsLoadingDuplicateTemplate(false)
    }
    const handleDeleteClick = (template, index) => {
        handleCloseMenu()
        dispatch(DBTemplateThunks.patch({
            entity: {
                ...template,
                deleted: true,
            }
        }))
    }

    const handleTemplateRowClick = () => {
        const templateWithoutId = {
            ...template,
            _id: null,
        }
        if (!(activeBulkView === "campaignManagementView")) {
            dispatch(setNewCampaign({
                ...newCampaign,
                template: templateWithoutId,
            }))
        }
        dispatch(setIsTemplateDropdownModalOpen(false))
    }

    return (
        <>

            <Stack direction='row' key={index} sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '84px',
                borderBottom: '1px solid var(--campaign-border)',
                p: '0px 0px 0px 20px',
                cursor: activeBulkView === "newCampaignView" && 'pointer',
                /// add hover event
                '&:hover': {
                    backgroundColor: activeBulkView === "newCampaignView" && 'var(--button-hovered-color)',
                },
            }}
                onMouseEnter={() => setIsMouseOver(true)}
                onMouseLeave={() => setIsMouseOver(false)}
                onClick={handleTemplateRowClick}
            >
                <div style={{ width: '90%' }}>
                    <TemplateRowDetails
                        template={template}
                    />
                </div>
                {isMouseOver && !(activeBulkView === "campaignManagementView") &&
                    <IconButton onClick={() => {
                        handleEditClick(template, index)
                    }}
                        style={{ color: '#7f9887', width: '10%', }}>
                        <ModeEditOutlineOutlined />
                    </IconButton>
                }
                {activeBulkView === "campaignManagementView" && !isLoadingDuplicateTemplate &&
                    <Box onClick={(e) => e.stopPropagation()}>
                        <IconButton onClick={(e) => handleOpenMenu(e)} style={{ color: '#7f9887', width: 'fit-content', }}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    borderRadius: '6px',
                                },
                            }}
                            MenuListProps={{
                                sx: {
                                    padding: 0, // Attempting to target the padding directly
                                    // If the above doesn't work, you might need a more specific target using global selectors
                                    '& .MuiList-root': {
                                        padding: 0, // This is a more targeted approach
                                    },
                                },
                            }}
                        >
                            <Box className={classes.menu_wrapper}>
                                <button onClick={() =>
                                    handleEditClick(template, index)
                                } className={classes.menu_list_item}>
                                    <BsPencil />
                                    <span style={{ marginLeft: 8 }}>Edit template</span>
                                </button>
                                <button onClick={() => handleDuplicateClick(template, index)} className={classes.menu_list_item}>
                                    <MdOutlineContentCopy />
                                    <span style={{ marginInlineStart: 5 }}>Duplicate template</span>
                                </button>
                                <button onClick={() => handleDeleteClick(template, index)} className={classes.menu_list_item}>
                                    <BsTrash style={{ color: 'red' }} />
                                    <span style={{ marginInlineStart: 5, color: 'red' }}> Delete template</span>
                                </button>
                            </Box>
                        </Menu>
                    </Box>
                }

                {activeBulkView === "campaignManagementView" && isLoadingDuplicateTemplate &&
                    <Stack direction='row' sx={{
                        overflow: 'hidden',
                        px: '12px',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        height: '40px',
                    }}>
                        <CircularProgress size='20px' style={{ color: '#52da79' }} />
                    </Stack>
                }
            </Stack>
        </>
    )
}