
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface BulkActionState {
    component: string,
    openedby: string,
    importType: string,
    animationComponent: string,
    previousComponentToRender: string,

}
const initialState: BulkActionState = {
    component: '',
    openedby: '',
    importType: '',
    animationComponent: '',
    previousComponentToRender: '',

}
const ModalSlice = createSlice({
    name: "ModalActions",
    initialState,
    reducers: {
        setComponentToRender(state, action: PayloadAction<string>) {
            state.previousComponentToRender = state.component
            state.component = action.payload
        },
        setAnimationComponentToRender(state, action: PayloadAction<string>) {
            state.animationComponent = action.payload
        },
        setOpenedby(state, action: PayloadAction<string>) {
            state.openedby = action.payload
        },
        setImportType(state, action: PayloadAction<string>) {
            state.importType = action.payload
        },
        cleanComponentToRender(state) {
            state.component = '';
            state.previousComponentToRender = '';
        }


    }
})
export const modalActions = ModalSlice.actions;
export default ModalSlice.reducer;


export const MODAL = {
    EXPORT_TO_EXCEL: "EXPORT_TO_EXCEL",
    IMPORT_EXCEL_TO_BLUETICKS: "IMPORT_EXCEL_TO_BLUETICKS",
    NEW_BOARD: "NEW_BOARD",
    FEATURE_NOT_AVAILABLE: "FEATURE_NOT_AVAILABLE",
    COMPLETE_PROFILE: "COMPLETE_PROFILE",
    INVITE_TO_WORKSPACE: "INVITE_TO_WORKSPACE",
    EMAIL_CONFIRMATION: "EMAIL_CONFIRMATION",
    EMAIL_CONFIRMATION_THANKS: "EMAIL_CONFIRMATION_THANKS",
    TRIAL_START: "TRIAL_START",
    TRIAL_EXTEND: "TRIAL_EXTEND",
    CONTACT_WORKSPACE_ADMIN: "CONTACT_WORKSPACE_ADMIN",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    UPDATE_SUBSCRIPTION_WORKSPACE: "UPDATE_SUBSCRIPTION_WORKSPACE",

}