import { Dialog, Stack } from '@mui/material';
import classes from './prompt-delete-action.module.scss';

export interface PromptDeleteActionProps {
  handleClose: (shouldDelete?: boolean) => void;
  open?: boolean;
  title: string;
}

function PromptDeleteAction({
  handleClose,
  open,
  title
}: PromptDeleteActionProps) {
  function cancelDelete() {
    handleClose(false);
  }

  function handleDelete() {
    handleClose(true);
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth
      PaperProps={{
        style: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 300, // Adjust the width as needed
        },
      }}>
      <Stack className={classes.container}>
        <h4 style={{ textAlign: 'start' }}>{title}</h4>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" className={classes.buttons_wrapper}>
          <button onClick={cancelDelete} className={classes.cancel_button}>Cancel</button>
          <button data-testid="confirm-delete-board-button" onClick={handleDelete} className={classes.delete_button}>Delete</button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default PromptDeleteAction