import { CellProps } from "../cell-interface";

export default function TextScrollingCell({ cellValue }: CellProps) {
    return (
        <div
            style={{
                fontFamily: 'Inter, Assistant, sans-serif',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "block",
                textAlign: "center",
                fontSize: "14px",
                color: "#819ba8",
                padding: "0 10px",
            }}>
            {cellValue}
        </div>
    );
}
