import { Box, Button, Divider, Popover, Stack, TextField, Typography } from '@mui/material';
import { selectAllColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { NullableId } from '@common/types/interface';;
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import AutomationButton from '../automation-button';

type Props = {
    index: number;
    submitMessage: (string) => void;
    message: string;
    componentName: string;
};
type TextPart = {
    columnName: string;
    id: NullableId;
};

const NotifyButton: FC<Props> = ({ index, submitMessage, message, componentName }) => {

    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const dynamicColumns = useAppSelector((state) => selectAllColumns(state, boardId));
    const staticColumns = [
        { "id": "itemName1", "type": "text", "name": "Name" },
        { "id": "groupName2", "type": "text", "name": "Group" },
        { "id": "boardName3", "type": "board", "name": "Board" },
        { "id": "created4", "type": "board", "name": "Created at" },
        { "id": "updatedAt5", "type": "board", "name": "Updated at" }
    ]
    const columns = dynamicColumns.concat(staticColumns);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setMessage = () => {
        submitMessage(columnIdMessage);
        handleClose();
    }


    const initialTextParts: TextPart[] = message
        ? message.split(/(\{Item [^\}]+\})/g).map(part => {
            const match = part.match(/\{Item ([^\}]+)\}/);
            if (match) {
                const column = columns.find(c => c.name === match[1]);
                return { columnName: match[1], id: column?.id ?? null };
            } else {
                return { columnName: part, id: null };
            }
        })
        : [{ columnName: '', id: null }];

    const [textParts, setTextParts] = useState<TextPart[]>(initialTextParts);

    const displayMessage = textParts.map(part => part.id ? `{Item ${part.columnName}}` : part.columnName).join('');
    const columnIdMessage = textParts.map(part => part.id ? `{Item ${part.columnName}_${part.id}}` : part.columnName).join('');

    const handleTextChange = (value: string) => {
        const newParts = value.split(/(\{Item [^\}]+\})/g).map(part => {
            const existingPart = textParts.find(p => `{Item ${p.columnName}}` === part || `{Item ${p.columnName}_${p.id}}` === part);
            if (existingPart) {
                return existingPart;
            } else {
                const match = part.match(/\{Item ([^\}]+)\}/);
                if (match) {
                    const column = columns.find(c => c.name === match[1]);
                    return { columnName: match[1], id: column?.id ?? null };
                } else {
                    return { columnName: part, id: null };
                }
            }
        });
        setTextParts(newParts);
    };

    const insertIntoTextArea = (columnName: string, id: NullableId) => {
        if (textAreaRef.current) {
            const start = textAreaRef.current.selectionStart;
            const end = textAreaRef.current.selectionEnd;
            const before = displayMessage.substring(0, start);
            const after = displayMessage.substring(end, displayMessage.length);
            const insertion = `{Item ${columnName}}`;

            handleTextChange(`${before}${insertion}${after}`);

            setTimeout(() => {
                textAreaRef.current!.selectionStart = start + insertion.length;
                textAreaRef.current!.selectionEnd = start + insertion.length;
            }, 0);
        }
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        let initialTextParts: TextPart[] = [];

        if (message) {
            initialTextParts = message.split(/(\{Item [^\}_]+\_[^\}_]+\})/g).map(part => {
                const match = part.match(/\{Item ([^\}_]+)\_([^\}_]+)\}/);

                if (match) {
                    return { columnName: match[1], id: match[2] };
                } else {
                    return { columnName: part, id: null };
                }
            });
        } else {
            initialTextParts.push({ columnName: '', id: null });
        }

        setTextParts(initialTextParts);
    }, [message]);

    return (
        <>
        

            <AutomationButton
                componentName={componentName}
                index={index}
                option='dynamicMessage'
                label={'notify'}
                handleClick={handleClick}
                validationStates={validationStates}
                attemptedSubmission={attemptedSubmission}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        maxWidth: "600px"
                    }
                }}
            >
                <Box p={2}>
                    <Typography fontSize={"14px"}>Type your message to personalize it with fields from your board</Typography>
                    <Divider sx={{ my: 2 }} />
                    <TextField
                        multiline
                        rows={4}
                        label="Write your message"
                        value={displayMessage}
                        variant="outlined"
                        fullWidth
                        inputRef={textAreaRef}
                        onChange={(e) => handleTextChange(e.target.value)} />
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography mx={2} fontSize={"14px"}>Populate from board items</Typography>
                <Stack flexWrap="wrap" direction="row" sx={{ maxWidth: "100%", maxHeight: "150px", overflowY: "scroll" }}>
                    {columns.map((column) => <Box
                        key={column.id}
                        onClick={() => insertIntoTextArea(column.name, column.id)}
                        sx={{ border: "1px solid lightgray", borderRadius: "5px", p: 1, m: 2 }}
                    >
                        Item {column.name}
                    </Box>
                    )}
                </Stack>

                <Divider sx={{ my: 2 }} />
                <Stack direction="row" justifyContent={"flex-end"} m={2} spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={setMessage}>Submit</Button>
                </Stack>

            </Popover>
        </>

    );
};

export default NotifyButton;
