import { logsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
}

export default function RenameLog({
    logId,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))








    return (
        <LogStack direction='row'>
            {/* <div> */}
                {log.actionType}&nbsp;
                <b>{log.oldValue}</b>{` `}
                &nbsp;to&nbsp;
                <b>{log.newValue}</b>{` `}
            {/* </div> */}
        </LogStack>
    )
}