import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";

type BetweenPickersFilterRowProps = {
    handleChangeValueField: (startDate: any, endDate: any) => void;
    valueField?: any;
}
const StyledDesktopDatePicker = styled(DesktopDatePicker)`

    .MuiOutlinedInput-input {
        padding: 14px 12px ;
        font-size:12px;
    }
`;

export const BetweenPickersFilterRow = ({ handleChangeValueField, valueField }: BetweenPickersFilterRowProps) => {
    const [specialDateBetweenStartValue, setSpecialDateBetweenStartValue] = useState(valueField.length ?? valueField.split('isbetween')[0]);
    const [specialDateBetweenEndValue, setSpecialDateBetweenEndValue] = useState(valueField.length ?? valueField.split('isbetween')[1]);



    useEffect(() => {
        setSpecialDateBetweenStartValue(new Date(parseInt(valueField.start)));
        setSpecialDateBetweenEndValue(new Date(parseInt(valueField.end)));

    }, [valueField]);
    useEffect(() => {
        if (valueField && !Array.isArray(valueField)) {
            const startDateString = valueField.split('isbetween')[0]
            const endtDateString = valueField.split('isbetween')[1]
            setSpecialDateBetweenStartValue(new Date(parseInt(startDateString)))
            setSpecialDateBetweenEndValue(new Date(parseInt(endtDateString)))
        }
    }, [valueField]);

    const handleStartDateChange = (newValue) => {
        setSpecialDateBetweenStartValue(new Date(newValue).getTime());
        handleChangeValueField(new Date(newValue).getTime(), specialDateBetweenEndValue);
    };

    const handleEndDateChange = (newValue) => {
        setSpecialDateBetweenEndValue(new Date(newValue).getTime());
        handleChangeValueField(new Date(specialDateBetweenStartValue).getTime(), new Date(newValue).getTime());
    };

    return (
        <div style={{ display: 'flex', gap: 5 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledDesktopDatePicker

                    label="Start Date"
                    format="DD/MM/YYYY"
                    value={new Date(specialDateBetweenStartValue)}
                    onChange={handleStartDateChange}
                    slots={(params) => <TextField {...params} />}
                />

                <StyledDesktopDatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    minDate={specialDateBetweenStartValue}
                    value={new Date(specialDateBetweenEndValue)}
                    onChange={handleEndDateChange}
                    slots={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
};