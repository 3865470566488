import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { memo, useEffect, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Poll } from './message-editor';

type Props = {
    localPollState: Partial<Poll>;
    setLocalPollState?: React.Dispatch<React.SetStateAction<Partial<Poll>>>;
    disabled?: boolean;
}

function CreatePoll({ localPollState, setLocalPollState, disabled = false }: Props) {
    const pollFromWAModal = useAppSelector(state => state.AddScheduledMessageButtonReducer.pollFromWAModal)


    const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) { return; }

        setLocalPollState({ ...localPollState, question: e.target.value });
    };

    const handleOptionChange = (index: number, value: string) => {
        if (disabled) { return; }

        // Create a copy of the options array
        let newOptions = [...localPollState.options];

        // Create a new object with the updated text
        newOptions[index] = { ...newOptions[index], text: value };

        // Remove empty options if there's more than 2 options
        if (value === '' && newOptions.length > 2) {
            newOptions.splice(index, 1);
        }

        // Add a new empty option if the last option is not empty
        if (index === newOptions.length - 1 && value !== '') {
            newOptions.push({ id: Math.floor(Math.random() * 1000000000000000000000000), text: '', order: newOptions.length });
        }

        // Update the state with the new options array
        setLocalPollState({ ...localPollState, options: newOptions });
    };

    const handleDragEnd = (result) => {
        if (disabled) { return; }

        if (!result.destination) return;



        let newOptions = localPollState.options
            .map(option => ({ ...option }))
        const [movedOption] = newOptions.splice(result.source.index, 1);
        if (movedOption.order === localPollState.options.length - 1) { return; }
        newOptions.splice(result.destination.index, 0, movedOption);

        // Update the order
        newOptions.forEach((option, index) => {
            option.order = index;
        });

        setLocalPollState({ ...localPollState, options: newOptions });
    };

    const handleAllowMultipleChange = () => {
        if (disabled) { return; }

        setLocalPollState({ ...localPollState, allowMultiple: !localPollState.allowMultiple });
    };


    const isOtherOptionsSimilar = (index) => {
        const currentOption = localPollState.options[index].text;
        const otherOptions = localPollState.options.filter((option, i) => i !== index && option.text !== '');
        return otherOptions.some((option) => option.text === currentOption);
    }


    const haveSimilarOptions = useMemo(() => {
        const options = localPollState.options.filter((option) => option.text !== '');
        const uniqueOptions = [...new Set(options.map((option) => option.text))];
        return options.length !== uniqueOptions.length;
    }, [localPollState.options])


    useEffect(() => {
        if (pollFromWAModal) {
            setLocalPollState(pollFromWAModal)
        }
    }, [pollFromWAModal])



    return (
        <div style={styles.container}>
            <div style={styles.section}>
                <h2 style={styles.title}>Question</h2>
                <input
                    type="text"
                    value={localPollState.question}
                    onChange={handleQuestionChange}
                    placeholder="Enter your question"
                    style={styles.input}
                />
            </div>
            <div style={styles.section}>
                <h2 style={styles.title}>Options</h2>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="options">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {localPollState.options
                                    .filter((option) => !disabled || (option.text && option.text !== ''))
                                    .map((option, index) => (
                                        <Draggable key={option.id} draggableId={option.id.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{ ...styles.optionContainer, ...provided.draggableProps.style }}
                                                >
                                                    <input
                                                        type="text"
                                                        value={option.text}
                                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                                        placeholder={`Option ${index + 1}`}
                                                        style={{
                                                            ...styles.input,
                                                            color: isOtherOptionsSimilar(index) && 'red',
                                                            border: isOtherOptionsSimilar(index) && '1px solid red',
                                                        }}
                                                    />
                                                    <span {...provided.dragHandleProps} style={{ ...styles.dragHandle, visibility: disabled ? 'hidden' : 'visible' }}>☰</span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div style={styles.section}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={localPollState.allowMultiple || false}
                            onChange={handleAllowMultipleChange}
                        />
                    }
                    label={
                        <Typography style={{ color: '#666666' }}>
                            Allow multiple answers
                        </Typography>
                    }
                />
            </div>
            {haveSimilarOptions && <p style={{ color: 'red', fontSize: '12px', }}>* Poll options must be unique</p>}
        </div >
    );
}

export default memo(CreatePoll)

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#f7f7f7',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        marginBottom: '24px',
    },
    title: {
        marginBottom: '12px',
        fontSize: '18px',
        fontWeight: '600',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '12px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '16px',
        outline: 'none',
        boxSizing: 'border-box' as const, // Corrected type for boxSizing
        transition: 'border-color 0.2s ease-in-out',
    },
    inputFocus: {
        borderColor: '#007bff',
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    dragHandle: {
        cursor: 'grab',
        padding: '8px',
        fontSize: '20px',
        color: '#888',
    }
};

