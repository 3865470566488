import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

// create a "selector creator" that uses lodash.isequal instead of ===


export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
    // (a, b) => {
    //     const res = isEqual(a, b);
    //     const _diff = diff(a, b);
    //     return res;
    // }
)