import { createSelector, createSlice } from "@reduxjs/toolkit";

import { userMessagesSelectByQuery, userMessagesSelectByQueryOnlyLength } from "@common-reducers/DBServiceThunks";
import { messageLimitSelector } from "./UserSelectors";
import { UserMessage } from "@common-models/user-message";
import { selectWhatsappContact } from "./WhatsAppSelectors";
import _ from 'lodash';
import { CommonRootState } from "@common/types/common-root-state-type";

export interface UserInfo {
  email: string;
  id: string;
}
export interface UserMessageState {
  contactWithLastMessage: UserInfo[];
}

const initialState: UserMessageState = {
  contactWithLastMessage: [],

};

const UserMessageSlice = createSlice({
  name: "UserMessage",
  initialState,
  reducers: {
  },
});



export const getContactWithLastMessage = createSelector(
  [
    (state) => state,
    (state, searchToken) => searchToken
  ],
  (state: CommonRootState, searchToken: string | undefined) => {
    const lowerCaseToken = searchToken?.toLowerCase();
    const messageList = userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [
        { deleted: { $exists: false } },
        { deleted: false }
      ],
      status: "pending"
    }, null, { dueDate: 1 })
      .filter(message => !message.campaignId)

    const groupedByWaId = _.groupBy(messageList, (message) => message?.contactList[0]?.id ?? '');

    let allMessages: UserMessage[] = []

    for (const [key, userMessages] of Object.entries(groupedByWaId)) {
      // console.log('user WA id', key);
      const apiCount = userMessages.filter(msg => msg.api).length;
      const campaignCount = userMessages.filter(msg => msg.campaignId).length;
      const scheduledCount = userMessages.length - apiCount - campaignCount;

      const message = userMessages[0];

      allMessages.push({
        ...message,
        contactWAId: message?.contactList[0]?.id ?? "",
        contactName: message?.contactList[0]?.name ?? message?.contactList[0]?.id ?? "",
        contactImageUrl: message?.contactList[0]?.imageUrl,
        messageCount: userMessages.length,

        messageScheduledCount: scheduledCount,
        messageApiCount: apiCount,
        messageCampaignCount: campaignCount,
      })
    }

    // let messageListGroupByPerson: UserMessage[] = []

    // messageList.forEach(message => {

    //   let isContactExist = false;

    //   message = {
    //     ...message,
    //     contactWAId: message?.contactList[0]?.id ?? "",
    //     contactName: message?.contactList[0]?.name ?? message?.contactList[0]?.id ?? "",
    //     contactImageUrl: message?.contactList[0]?.imageUrl,
    //     messageCount: 1,
    //   }

    //   messageListGroupByPerson.forEach(message2 => {

    //     if (message.contactWAId === message2.contactWAId) {
    //       message2.messageCount++
    //       isContactExist = true;
    //       return;
    //     }

    //   })

    //   if (!isContactExist) {
    //     messageListGroupByPerson.push(message)
    //   }

    // });


    return allMessages.filter(message => { // messageListGroupByPerson
      const whatsappContact = selectWhatsappContact(state, message.contactWAId)

      return lowerCaseToken === undefined ||
        whatsappContact?.name?.toLowerCase()?.indexOf(lowerCaseToken) > -1 ||
        whatsappContact?.displayName?.toLowerCase()?.indexOf(lowerCaseToken) > -1 ||
        message.message?.toLowerCase()?.indexOf(lowerCaseToken) > -1;
    });

  }
);

export const isOverLimitOfScheduledMessages = createSelector(
  (state: CommonRootState) => state,
  (state) => {
    const scheduledMessagesCount = userMessagesSelectByQueryOnlyLength(state.DBUserMessageReducer, { $or: [{ deleted: { $exists: false } }, { deleted: false }] });
    const currentMessageId = state.AddScheduledMessageButtonReducer.message._id
    const messageLimit = messageLimitSelector(state);
    return scheduledMessagesCount >= messageLimit && (!currentMessageId);

  }
);

export default UserMessageSlice.reducer;
