export const taskColumnsWidth = {
    menuCell: 52,
    shareCell: 52,
    deleteCell: 60,
    chatCell:100,
    ownerCell: 131,
    priorityCell: 131,
    statusCell: 131,
    dateCell:100,
    addColumnCell: 110,
    colorCell: 8,
    filesCell:111,
    linkCell:111,
}