import unavailable from "@common-assets/unavailable.png"
import BTButton from '@common-components/bt-button/bt-button'
import { Stack, Typography } from '@mui/material'
import { modalActions } from '@common-reducers/ModalReducer'
import { switchWorkspace } from '@common-reducers/UserThunks'
import { selectCurrentUserWorkspace } from '@common-reducers/UserSelectors'
import { useRouter } from '@common-reducers/hooks/use-router'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'

function FeatureUnavailableDialog() {
    const dispatch = useAppDispatch()
    const workspaces = useAppSelector(selectCurrentUserWorkspace)
    const router = useRouter();

    const switchToCurrentUserWorkspace = async () => {

        await dispatch(switchWorkspace({ workspaceId:workspaces._id }))

        router.navigate({
            pathname: 'tasks',
            search: ''
        })

        dispatch(modalActions.setComponentToRender(null))
        
    }


    return (
        <Stack px={6} py={4} spacing={2} alignItems={"center"}>
            <Typography variant="h6" fontWeight={"700"} textAlign={"center"}>This Feature is available only to workspace users.</Typography>
            <Typography variant="body1">You are a guest on this workspace.</Typography>

            <img style={{marginLeft:"45px"}} src={unavailable} width="350" height="300" alt="unavailable" />
             
               <BTButton sx={{width:"70%",boxShadow:"none"}} onClick={switchToCurrentUserWorkspace} variant="contained" color="primary"  >
                Switch to your workspace
            </BTButton>
        </Stack>
    )
}

export default FeatureUnavailableDialog