import BTThemeProvider from '@common-components/bt-theme-provider';
import { selectUser } from '@common-reducers/UserSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { CircularProgress } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { goTo } from 'react-chrome-extension-router';

import StatusBox from '@common-components/status-box/status-box';
import AccessForm from '@common/components/access-form/access-form';
import SubscriptionSelection from '@common/components/subscription-selection/subscription-selection';
import classes from './popup.module.scss';

export interface PopupProps {
    headerTitle?: string;
    headerSubtitle?: string;
    onClose?: () => void;
}

export const Popup = ({
    headerTitle,
    headerSubtitle,
    onClose
}: PopupProps) => {
    const user = useAppSelector(selectUser);
    const loading = useAppSelector((state) => state.UserReducer.authenticating);

    const routes: any = useMemo(() => ({
        accessForm: () => (<AccessForm headerTitle={headerTitle} headerSubtitle={headerSubtitle} />),
        home: () => (<StatusBox onClose={onClose} />),
        subscriptionSelection: () => (<SubscriptionSelection />)
    }), [headerSubtitle, headerTitle, onClose]);

    const navigateTo = (component: any) => {
        goTo(component);
    };

    useEffect(() => {
        if (user) {
            console.log('popup found user');
            navigateTo(routes.home);
        } else {
            console.log('popup no user');
            navigateTo(routes.accessForm);
        }
    }, [routes.accessForm, routes.home, user]);

    return (
        <BTThemeProvider>
            <div className={classes.popupContainer}>
                {loading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && user && (
                    <StatusBox onClose={onClose} />
                )}
                {!loading && !user && (
                    <AccessForm headerTitle={headerTitle} headerSubtitle={headerSubtitle} />
                )}
            </div>
        </BTThemeProvider>
    );
};
