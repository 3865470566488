import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import React, { ReactElement } from "react";

export interface ConfirmationDialogProps {
    title: string;
    content: string;
    onCancel: () => void;
    onConfirm: () => void;
    open?: boolean;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps): ReactElement {
    return <Dialog
        open={props.open ?? false}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel} color="primary">
                Cancel
            </Button>
            <Button color="primary" autoFocus onClick={props.onConfirm}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}
