import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function Temperature({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Temperature', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.temperature ?? ''}
                type='number'
                onChange={(newValue) => {
                    if (/^\d*\.?\d*$/.test(newValue) && +newValue <= 2) {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev.advanced,
                                temperature: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `Higher temperatures lead to more diverse and creative responses, such as a setting of 0.8. In contrast, lower temperatures produce more focused and well-defined responses, like a setting of 0.2. The default value is set to 1`,
                {}
            )}
            {secondaryTitleWithMaybeLink(`Must be lower than or equal to 2`, {})}
        </>
    );
}
