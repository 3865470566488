import { FormControl } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useState, useEffect } from "react";

function HourDropdown({ handleHourSelected, selectedHour, disabled }) {
    const [hour, setHour] = useState('');
    let hours = ["7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM",
        "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM", "12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM"];


    const convert24to12 = (hour24: number) => {
        let meridiem = 'AM';
        let hour12 = hour24;

        if (hour24 >= 12) {
            meridiem = 'PM';
            if (hour24 > 12) hour12 = hour24 - 12;
        } else if (hour24 === 0) {
            hour12 = 12;
        }

        return `${hour12}${meridiem}`;
    }
    const handleHourChange = (event, newValue) => {
        setHour(newValue);
        handleHourSelected(newValue);
    };
    useEffect(() => {
        const date = new Date(selectedHour);
        const hour24 = date.getHours();
        let hour12 = convert24to12(hour24);
        if (isNaN(hour24)) {
            hour12 = "8AM"
        }
        setHour(hour12)

    }, [selectedHour]);
    return (
        <FormControl sx={{  display: "flex", flexDirection: "row", alignItems: "center" }}>

            <Autocomplete sx={{ width: "100px" }}
                disabled={disabled}
                value={hour}
                onChange={handleHourChange}
                options={hours}
                renderInput={(params) => (
                    <TextField
                        {...params}

                        variant="standard"
                    />
                )}
            />
        </FormControl>
    );
}

export default HourDropdown