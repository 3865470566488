import { Box, Stack, Tooltip, Typography } from "@mui/material";

interface TimeAgoProps {
    updatedAt: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ updatedAt }) => {
    let timeAgo = "";

    const date: Date = new Date(updatedAt);
    const now: Date = new Date();

    const diffInMinutes: number = (now.getTime() - date.getTime()) / 1000 / 60;
    const diffInHours: number = diffInMinutes / 60;
    const diffInDays: number = diffInHours / 24;
    const diffInMonths: number = diffInDays / 30;
    const diffInYears: number = diffInMonths / 12;
    if (diffInMinutes < 5) {
        timeAgo = "just now";
    } else if (diffInMinutes < 60) {
        timeAgo = Math.round(diffInMinutes) + (Math.round(diffInMinutes) === 1 ? " minute ago" : " minutes ago");
    } else if (diffInHours < 24) {
        timeAgo = Math.round(diffInHours) + (Math.round(diffInHours) === 1 ? " hour ago" : " hours ago");
    } else if (diffInDays < 30) {
        timeAgo = Math.round(diffInDays) + (Math.round(diffInDays) === 1 ? " day ago" : " days ago");
    } else if (diffInMonths < 12) {
        timeAgo = Math.round(diffInMonths) + (Math.round(diffInMonths) === 1 ? " month ago" : " months ago");
    } else {
        timeAgo = Math.round(diffInYears) + (Math.round(diffInYears) === 1 ? " year ago" : " years ago");
    }
    
    const timeLeft = Math.max(30 * 24 * 60 - diffInMinutes, 0); 
    const daysLeft = Math.floor(timeLeft / (24 * 60));
   

    let timeLeftString = `${daysLeft}`;

    return (
        <Box >
        
                <Stack direction="row" alignItems={"center"} textAlign={"center"}>
                <Tooltip   title={`Will be deleted in ${timeLeftString} Days`} arrow disableInteractive>
                    <Box fontSize={"14px"}>  {timeAgo}</Box>
                    </Tooltip>
                </Stack>
       
        </Box>
    );
};

export default TimeAgo;