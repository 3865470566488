import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper'
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs'
import EditTaskInput from '@common-components/tasks/edit-task-input/edit-task-input'
import { ExtraDataPanel } from '@common-components/tasks/extraData/ExtraDataPanel'
import { Circle } from '@mui/icons-material'
import { ClickAwayListener, Modal, Paper } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors'
import { bulkActions } from '@common-reducers/BulkActionsReducer'
import { boardsSelectOneFieldByQuery, taskGroupsSelectOneFieldByQuery, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks"
import { dynamicColumnsListOfIdsTypesAndTitles } from '@common-reducers/TaskSelectors'
import { updateKanbanCardTaskMapThunk } from '@common-reducers/TaskThunks'
import { taskActions } from '@common-reducers/TasksReducer'
import { getIconByType } from '@common/components/tasks/columns-cells/column-cell-defs';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { namespace } from '@common/config/constants'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MoveToMenu from '../bulk-actions-dialog/move-to/move-to-menu'
import classes from './kanban-column.module.scss'

interface TaskUpdateModalProps {
    taskBoardId?: string; // This makes the prop optional
}


const isFalsyOrEmpty = (value) => {
    return value === null || value === undefined || value === '';
};

export default function TaskUpdateModal({ taskBoardId }: TaskUpdateModalProps) {
    const { t } = useTranslation(namespace);
    const taskId = useAppSelector((state) => state.ExtraDataReducer.displayExtraDataOfTaskById);

    const boardIdValue = useAppSelector((state) => getSelectedBoardId(state));
    const boardId = isFalsyOrEmpty(boardIdValue) ? taskBoardId : boardIdValue;
    //const boardId = useAppSelector((state) => getSelectedBoardId(state)) ?? taskBoardId
    const boardName = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, { _id: boardId }, "name"))
    const groupId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "groupId"))
    const groupName = useAppSelector((state) => taskGroupsSelectOneFieldByQuery(state.DBTaskgroupsReducer, { _id: groupId }, "name"))
    const groupColor = useAppSelector((state) => taskGroupsSelectOneFieldByQuery(state.DBTaskgroupsReducer, { _id: groupId }, "colorAccent"))
    const columnsListOfIds = useAppSelector((state) => dynamicColumnsListOfIdsTypesAndTitles(state, boardId)) // [_id, type, title]
    const isMoveTo = useAppSelector((state) => state.BulkActionsReducer.isMoveTo)
    const dispatch = useAppDispatch()

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    function handleMoveTo() {
        dispatch(bulkActions.setBulActionsMoveToId('id'))
        dispatch(bulkActions.setMovingTo('group'))
        dispatch(bulkActions.toggleMoveTo(!isMoveTo))
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsMenuOpen(!isMenuOpen);
        dispatch(updateKanbanCardTaskMapThunk({ taskId: taskId }));
        console.log(isMenuOpen)
        event.stopPropagation();
    };

    const GroupLine = () => (
        <Stack direction="row" sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
            position: 'relative',
            direction: 'ltr',
            paddingRight:"5%"

          //  maxWidth: '440px',
        }}>
        
            <Stack direction="row" alignItems={"center"} style={{ width: '30%', textAlign: 'left', fontSize: '13px' }}>
            <span style={{ width: '18px', marginInlineEnd: 10, height: '23px', color: groupColor ?? 'black' }}><Circle /></span>
                {t('taskUpdateModal.group')}</Stack>

            <Box
                id={`kanban_card_${taskId}`}
                key={`kanban_card_${taskId}`}
                sx={{

                    height: '40px',
                    width: "60%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--default-background)',
                    borderRadius: '4px',
                    boxShadow:"rgba(0, 0, 0, 0.15) 0px 2px 7px -2px;",
                    background:"#e9e9e9",
                    cursor: "pointer"
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    handleMoveTo()
                    handleClick(e)
                }}
            >
                <div>{groupName}</div>
                {isMenuOpen &&
                    <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>

                        <Paper sx={{
                            position: 'absolute',
                            bottom: 0,
                            backgroundColor: 'white',
                            transform: 'translateY(100%)',
                            zIndex: 9,
                        }}>
                            <MoveToMenu />
                        </Paper>
                    </ClickAwayListener>
                }
            </Box>
        </Stack>
    )

    const ColumnRow = (column, index) => {
        const [cell] = columnTypeList.filter(
            (columnType) =>
                columnType.type === column[1]
        );
        return (
            <Stack spacing={2}  direction="row" key={`${index}_${column[0]}`} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px',
                paddingRight:"5%"
              //  maxWidth: '440px',

            }}>
             
                <Stack alignItems={"center"} direction="row" style={{ width: '30%' }}>

                <span style={{ width: '18px', marginInlineEnd: 10, height: '15px',fontSize: '13px' }}>
                    {getIconByType(column[1])}
                </span>
                <span style={{fontSize: '13px'}}>
                {column[2] ?? column[1]}
                </span>
                   
                    
                    </Stack>
                <Box sx={{
                    height: '40px',
                    width: '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--default-background)',
                    borderRadius: '4px',
                    boxShadow:"rgba(0, 0, 0, 0.15) 0px 2px 7px -2px;",
                    background:"#e9e9e9"

                }}>
                    <CellWrapper
                        columnId={column[0]}
                        taskId={taskId}
                        groupId={groupId}
                        boardId={boardId}
                        cellComponent={cell.cellComponent}
                        sx={{
                            height: 40,
                            borderRadius: '5px',
                            flex: 'auto',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    />
                </Box>
            </Stack>
        )
    }



    return (
        <Modal
        id="media-manager"
        open={true}
        onClose={() => {
            dispatch(taskActions.toggleTaskModal(false));
            dispatch(bulkActions.setIsBulkActionsDialogOpen(false));
            dispatch(bulkActions.toggleMoveTo(false));
            dispatch(bulkActions.setMovingTo(""));
            dispatch(bulkActions.setBulActionsMoveToId(''));
            taskActions.toggleTaskMenu('');
        }}
        sx={{
            maxWidth: "1000px",
            minWidth: "800px",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <Stack direction="row" sx={{
            width: '100%',
            boxSizing: 'border-box',
            height: '80vh',
            p: '30px',
            backgroundColor: 'var(--default-background)',
            direction: 'ltr',
            overflowY: 'hidden',
           
        }}>
            <Stack direction="column" sx={{
                width: '50%',
       
                flex: 1,
                borderRight: '2px solid var(--border-color)',
                m: 0,
                alignItems: 'center',
            }}>{/* Left part */}
                <div 
                 className={classes.kanbanScroller}
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width:"98%",
        
                    overflowY:"scroll"

                }}>
                    <Box sx={{
                        marginBottom: '25px',
                    }}>
                        <EditTaskInput taskId={taskId}
                            isMultiline={true}
                            rowsMax={4}
                            boxSx={{
                                fontSize: '24px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0px',
                                maxWidth: "290px"
                            }} inputSx={{
                                fontSize: '24px',
                                whiteSpace: 'pre-wrap',
                                maxHeight: '400px',
                                overflow: 'auto',
                                maxWidth: "290px"
                            }} />
                        <div>{t('taskUpdateModal.inBoard')} &#x2192; {boardName}</div>
                    </Box>
                    <Box sx={{width:"100%"}}>
                      
                            {GroupLine()}
                            {columnsListOfIds
                                .map((column, index) => (
                                    ColumnRow(column, index)
                                ))
                            }
                        
                    </Box>
                </div>
            </Stack>
            <Stack direction="column" sx={{
                width: '50%',
                backgroundColor: 'var(--secondary-background-color)',
      
                flex: 1,
            
       
          
            }}>{/* Right part */}

         
                    <ExtraDataPanel />
     


            </Stack>
        </Stack>
        </Modal>
    )
}

