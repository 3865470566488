import BTInputField from '@common-components/bt-input-field/bt-input-field';
import ToolbarButton from '@common-components/toolbar-button';
import { Stack } from '@mui/material';
import { DBMyWorksThunks } from "@common-reducers/DBServiceThunks";
import { myWorkActions } from '@common-reducers/MyWorkReducer';
import { taskActions } from '@common-reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { BiCustomize, BiLayout } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";

export default function MyWorkHeader() {
    const dispatch = useAppDispatch();
    const myWorkViewMode = useAppSelector(state => state.MyWorkReducer.myWorkViewMode);
    const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);


    const buttonStyles = {
        '&:hover': {
            opacity: "1",
            background: "var(--button-hover)",
            boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)"
        }
    };


    const getStyleForViewMode = (mode: string) => {
        if (myWorkViewMode === mode) {
            return {
                opacity: "1",
                background: "var(--button-hover)",
                boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)"
            };
        }
        return {};
    }

    const setViewMode = async (currentView: string) => {

        dispatch(myWorkActions.setMyWorkViewMode(currentView));
        
        await dispatch(DBMyWorksThunks.patch({
            entity: {
                _id: myWorkPreferences._id,
                currentView: currentView,
            }
        }));
    };

    function filterTasks(query: string) {
        dispatch(myWorkActions.setTasksFilterName(query))
    }


    return (
        <Stack direction="row" spacing={2} my={2}>

            <ToolbarButton
                startIconSVG={BiCustomize}
                onClickAction={() => dispatch(taskActions.setTaskViewSideDrawerOpenWith("my-work-filter"))}
                buttonText="Customize"
                toolTipTitle="Customize my work"
                additionalStyles={buttonStyles}
            />


            <ToolbarButton
                startIconSVG={BiLayout}
                onClickAction={() => setViewMode('board')}
                buttonText="Board View"
                toolTipTitle="Switch toBoard View"
                additionalStyles={{ ...buttonStyles, ...getStyleForViewMode('board') }}
            />


            <ToolbarButton
                startIconSVG={BsCalendar3}
                onClickAction={() => setViewMode('date')}
                buttonText="Date View"
                toolTipTitle="Switch to Date View"
                additionalStyles={{ ...buttonStyles, ...getStyleForViewMode('date') }}
            />
            <BTInputField
                sx={{
                    minWidth: '250px',
                    marginBottom: 2,
                    padding: '0px 4px',
                    background: 'var(--search-input-background)',
                    ':focus': {
                        boxShadow: '0 5px 12px rgb(0 0 0 / 15%)',
                    }
                }} type="text" onChange={({ target }: any) => filterTasks(target.value)} placeholder="Search Tasks..." />

        </Stack>
    );
}
