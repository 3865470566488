import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import { contactLabel } from '@common-reducers/WhatsAppSelectors';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Paper } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';

type Props = {
    filteredContacts
    searchFilter
    handleSearchFilterChange
    saveFreeInput
    save
    shouldGetProfilePic?
}

export default function WhatsappContactSearchBox({
    filteredContacts,
    searchFilter,
    handleSearchFilterChange,
    saveFreeInput,
    save,
    shouldGetProfilePic = true,
}: Props) {
    const anchorRef = useRef(null);

    const allowToAdd = /^[0-9@+]+$/.test(searchFilter);


    const showSuggestions = searchFilter !== '' && filteredContacts?.length > 0;



    return (
        <>
            <Stack direction="row" alignItems={'center'} justifyContent={'start'} ref={anchorRef}>
                <SearchBox
                    variant="outlined"
                    // sx={{
                    //     width: 225
                    // }}
                    autoComplete='off'
                    value={searchFilter}
                    onChange={(e) => handleSearchFilterChange(e.target.value)}
                    placeholder="Enter name or number"
                    InputProps={{
                        endAdornment: allowToAdd ? (
                            <IconButton onClick={() => {
                                saveFreeInput();
                            }} >
                                <AddIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                        ) : <SearchIcon sx={{ fontSize: 16 }} />,
                    }}
                    fullWidth
                />
            </Stack>
            {showSuggestions && (
                <div
                    style={{
                        position: 'absolute',
                        width: '300px',
                        height: '300px',
                        overflow: 'auto',
                        zIndex: 1000,
                    }}
                >
                    <Paper sx={{
                        width: 1,
                        height: 1
                    }}>
                        <Virtuoso
                            style={{ height: '100%' }}
                            totalCount={filteredContacts?.length}
                            itemContent={(index) => {
                                const contact = filteredContacts[index];
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'var(--button-hovered-color)',
                                            },
                                        }}
                                        onClick={() => save(contact)}
                                    >
                                        <ContactAvatar
                                            size={24}
                                            textSize={12}
                                            contactId={contact?.whatsappId ?? contact?.waId ?? contact?.id?._serialized}
                                            shouldGetProfilePic={shouldGetProfilePic}
                                        />
                                        <Box ml={2}>{contactLabel(contact)}</Box>
                                    </Box>
                                );
                            }}
                        />
                    </Paper>
                </div>
            )}
        </>
    )
}