import React, {
  memo,
  useMemo,
} from "react";
import { getDatesDiff } from "../../helpers/get-dates-diff";

import type {
  Distances,
  ViewMode,
} from "../../types/public-types";

export type GridBodyProps = {
  additionalLeftSpace: number;
  distances: Distances;
  ganttFullHeight: number;
  isUnknownDates: boolean;
  startDate: Date;
  todayColor: string;
  rtl: boolean;
  viewMode: ViewMode;
};

const GridBodyInner: React.FC<GridBodyProps> = ({
  additionalLeftSpace,
  distances: {
    columnWidth,
  },

  ganttFullHeight,

  isUnknownDates,
  todayColor,
  rtl,
  startDate,
  viewMode,
}) => {
  const today = useMemo(() => {
    if (isUnknownDates) {
      return null;
    }

    const todayIndex = getDatesDiff(new Date(), startDate, viewMode);

    const tickX = todayIndex * columnWidth;

    const x = rtl
      ? tickX + columnWidth
      : tickX;

    return (
      <svg>
        {/* <text fontSize={'16px'} x={additionalLeftSpace + x + 10} y="15" fill="#259ae9">Today</text> */}

        <rect
          x={additionalLeftSpace + x}
          y={0}
          width={'3px'}
          height={ganttFullHeight}
          fill={todayColor}

        />
      </svg>


    );
  }, [
    additionalLeftSpace,
    columnWidth,
    ganttFullHeight,
    isUnknownDates,
    rtl,
    startDate,
    todayColor,
    viewMode,
  ]);

  return (
    <g

      className="gridBody">
      <g className="today">{today}</g>
    </g>
  );
};

export const GridBody = memo(GridBodyInner);
