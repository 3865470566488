import * as React from "react";
import Radio from "@mui/material/Radio";
import BTThemeProvider from "@common-components/bt-theme-provider";
import {
  Box,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { EventPL } from "./message-calendar";

export const eventDialogColor = "#5f6368";

const claendarMessageColorPallete = [
  { name: "Tomato", color: "#D50000", isSecondRow: false },
  { name: "Flamingo", color: "#E67C73", isSecondRow: false },
  { name: "Tangerine", color: "#F4511E", isSecondRow: false },
  { name: "Banana", color: "#F6BF26", isSecondRow: false },
  { name: "Sage", color: "#33B679", isSecondRow: false },
  { name: "Basil", color: "#0B8043", isSecondRow: false },
  { name: "Peacock", color: "#039BE5", isSecondRow: true },
  { name: "Blueberry", color: "#3F51B5", isSecondRow: true },
  { name: "Lavender", color: "#7986CB", isSecondRow: true },
  { name: "Grape", color: "#8E24AA", isSecondRow: true },
  { name: "Graphite", color: "#616161", isSecondRow: true },
  { name: "Calendar colour", color: "#B39DDB", isSecondRow: true },
] as const;

export const getRandomMessageColor = () => {
  return claendarMessageColorPallete[
    Math.floor(Math.random() * claendarMessageColorPallete.length)
  ].color;
}

export type Color = typeof claendarMessageColorPallete[number]["color"];
export const defaultEventcolor = "#B39DDB" as Color;

interface P {
  event: EventPL;
  onClose: () => void;
  onDelete: () => void;
  onChange: (c: Color) => void;
}

export default function MessageColor(props: P) {
  const boxRef = React.createRef<HTMLDivElement>();
  const [paperLeft, setPaperLeft] = useState(0);
  const [paperTop, setPaperTop] = useState(0);
  const [disablePortal, setDisablePortal] = useState(true);

  /////////////////// postion dialog next to calendar message ///////////////////////////////
  useEffect(() => {
    if (boxRef.current) {
      const {
        left: dialogLeft,
        right: dialogRight,
        top: dialogTop,
        bottom: dialogBottom,
      } = boxRef.current
        .querySelector(".MuiPaper-root")
        .getBoundingClientRect();
      const {
        left: eventLeft,
        top: eventTop,
        bottom: eventBottom,
      } = props.event;

      const dialogWidth = dialogRight - dialogLeft;
      const hasPlaceAlignedLeft = window.innerWidth > eventLeft + dialogWidth;

      const left = hasPlaceAlignedLeft
        ? eventLeft
        : window.innerWidth - dialogWidth;

      setPaperLeft(left);

      const dialogHeight = dialogBottom - dialogTop;
      const eventHeight = eventBottom - eventTop;
      const hasPlaceBelow = window.innerHeight - eventTop - dialogHeight > 0;
      const top = hasPlaceBelow
        ? eventTop + eventHeight
        : eventTop - dialogHeight;

      setPaperTop(top);
    }
    if (disablePortal) {
      setDisablePortal(false);
    }
  }, [boxRef, disablePortal, props.event]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const [selectedValue, setSelectedValue] = useState<Color>(
    props.event.messageColor as Color
  );

  const handleChange = (selectedColor: Color) => {
    props.onChange(selectedColor);
    setSelectedValue(selectedColor);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    value: item,
  });

  const colorsSlice = (start: number, end: number) =>
    claendarMessageColorPallete.slice(start, end).map((c, index) => {
      return (
        <Tooltip
          title={c.name}
          key={c.color}
          placement={c.isSecondRow ? 'bottom' : 'top'} // Tooltip placement based on row
        >
          <Radio
            key={c.color}
            {...controlProps(c.color)}
            onChange={() => handleChange(c.color)}
            sx={{
              color: c.color,
              padding: '4px',
              '&.Mui-checked': {
                color: c.color,
              },
            }}
          />
        </Tooltip>
      );
    });

  return (
    <BTThemeProvider>
      <Dialog
        ref={boxRef}
        open={true}
        disablePortal={disablePortal}
        sx={{
          ".MuiBackdrop-root": {
            backgroundColor: "unset",
          },
        }}
        PaperProps={{
          sx: {
            position: "absolute",
            margin: 0,
            left: paperLeft,
            top: paperTop,
            color: eventDialogColor,
            padding: "10px",
          },
        }}
      >
        <ClickAwayListener onClickAway={props.onClose}>
          <Box>
            <DialogTitle
              onClick={props.onDelete}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: eventDialogColor,
                "& button": {
                  paddingLeft: "4px",
                },
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#f1f3f4",
                },
              }}
            >
              <IconButton sx={{ color: eventDialogColor }}>
                <Delete></Delete>
              </IconButton>
              Delete
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "20px 0 0 0" }}>
              <Box>{colorsSlice(0, 6)}</Box>
              <Box>{colorsSlice(6, 12)}</Box>
            </DialogContent>
          </Box>
        </ClickAwayListener>
      </Dialog>
    </BTThemeProvider>
  );
}
