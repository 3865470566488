import { AddOutlined } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { setCurrentTemplate } from '@common-reducers/CampaignReducer'
import { handleActiveBulkViewNavigationThunk } from '@common-reducers/CampaignThunks'
import { DBTemplateThunks, templateSelectByQuery } from "@common-reducers/DBServiceThunks"
import { useAppDispatch } from '@common-reducers/hooks/store.hook'
import { useState } from 'react'
import BulkButton from './bulk-button'
import BulkSearchField from './bulk-search-field'
import BulkTemplateDialogRow from './bulk-template-dialog-row'
import LazyLoadingVirtuoso from './lazy-loading-virtuoso'

type Props = {
    handleCloseBulkTemplateDialog?: () => void;
}

export default function BulkTemplateDialog({
    handleCloseBulkTemplateDialog,
}: Props) {
    /// selectors/state hooks


    const dispatch = useAppDispatch()


    const [searchTerm, setSearchTerm] = useState("");




    /// variables


    /// functions
    const handleNewTemplateButtonClick = () => {
        dispatch(setCurrentTemplate({
            name: `New Template`,
            message: '',
        }))
        dispatch(handleActiveBulkViewNavigationThunk({ navigate: "toNewTemplate" }))
        if (handleCloseBulkTemplateDialog) {
            handleCloseBulkTemplateDialog()
        }
    }


    return (
        <Stack direction='column' sx={{ width: '100%', }}>
            <Stack direction='row' sx={{
                width: '100%',
            }}>
                <BulkSearchField
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeHolderString="Search templates..."
                />
                <BulkButton
                    onClick={handleNewTemplateButtonClick}
                    buttonString='New Template'
                    Icon={AddOutlined}
                    buttonWidth='230px'
                />
            </Stack>
            <Stack direction='column' sx={{ border: '1px solid var(--campaign-border)', borderRadius: '12px', marginTop: '20px', }}>
                <LazyLoadingVirtuoso
                        isFullWidth={true}
                        height={500}
                    rowHeightString='84px'
                    filterObj={{
                        $or: [
                            { 'name': { $regex: searchTerm, $options: 'i' } },
                            { 'message': { $regex: searchTerm, $options: 'i' } }
                          ],
                        deleted: false,
                    }}
                    DBThunks={DBTemplateThunks}
                    DBReducer={state => state.DBTemplateReducer}
                    selectByQuery={templateSelectByQuery}
                    Component={BulkTemplateDialogRow}
                />
            </Stack>

        </Stack>
    )
}