import { useState } from "react";
import GoogleSheetAuthButton from "./google-sheets-auth-button";
import classes from "./google-sheets-node.module.scss";
import RowStack from "@common-components/common/row-stack/RowStack";
import { IconButton, Menu, MenuItem, MenuList, Popper, Stack } from "@mui/material";
import { Close, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle } from "@mui/icons-material";
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { getNodeDataByNodeId } from "@common-reducers/ChatbotSelectors";
import SelectVariable from "@common-components/bt-chatbot/bot-components/select-variable";
import { changeNodeDataThunk } from "@common-reducers/ChatbotThunks";
import { useStore } from "@common/reducers/ChatbotReducer";



type Props = {
    onClose: () => void
    id?: string;
}


export default function AddGoogleSheetDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => getNodeDataByNodeId(state,id))

    const { setNodes } = useStore();

    const [googleAccount, setGoogleAccount] = useState<string | null>(null);
    const [tokens, setTokens] = useState<{ credential: string, email: string }[] | null>(null);
    const [sheets, setSheets] = useState<any[]>([]);
    const [currentToken, setCurrentToken] = useState<{ credential: string, email: string } | null>(null);
    const [isSelectAccountMenuOpen, setIsSelectAccountMenuOpen] = useState<boolean>(false);
    const [selectAccountMenuAnchorEl, setSlectAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [localNodeRowData, setLocalNodeRowData] = useState<any>(data?.rowData ?? {
        token: '',
        email: '',
        type: 'new',
        rowData: [{ name: '', value: '' }]
    });

    const handleAuthSuccess = (credential: { credential: string, email: string }) => {
        console.log('Received Credential:', credential);
        setGoogleAccount('User Email or ID'); // Replace with actual user data from Google response
        setTokens([...(tokens ?? []), credential]);
        if (!tokens || tokens?.length === 0) {
            setCurrentToken(credential);
        }
    };

    const listSheets = async () => {
        if (tokens[0].credential) {
            try {
                const response = await fetch('https://www.googleapis.com/drive/v3/files?q=mimeType="application/vnd.google-apps.spreadsheet"', {
                    headers: {
                        'Authorization': `Bearer${tokens[0]}`
                    }
                });

                // Log the entire response for debugging
                console.log('Response Status:', response.status);
                console.log('Response OK:', response.ok);
                console.log('Response Headers:', response.headers);

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Error Response:', errorText);
                    throw new Error(`Error: ${response.statusText}`);
                }

                const data = await response.json();
                console.log('Fetched Sheets:', data);
                setSheets(data.files);
            } catch (error) {
                console.error('Error fetching sheets from Google Drive:', error);
            }
        }
    };


    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const handleOpenSelectAccountMenu = (e) => {
        setIsSelectAccountMenuOpen(true);
        setSlectAccountMenuAnchorEl(e.currentTarget);
    }

    const handleCloseSelectAccountMenu = () => {
        setIsSelectAccountMenuOpen(false);
        setSlectAccountMenuAnchorEl(null);
    }


    const connectAccount = () => <div className={classes.divider}>
        {tokens?.length > 0 &&
            <BotInputField
                value={currentToken?.email}
                endAdornment={
                    <RowStack>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            ".MuiSvgIcon-root:hover": {
                                color: 'red',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }} onClick={() =>
                            setTokens(prev => prev.filter(token => token?.credential !== currentToken?.credential))}>
                            <RemoveCircle />
                        </IconButton>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }} onClick={handleOpenSelectAccountMenu}>
                            {!isSelectAccountMenuOpen && <KeyboardArrowDown />}
                            {isSelectAccountMenuOpen && <KeyboardArrowUp />}
                        </IconButton>
                        <Popper
                            open={isSelectAccountMenuOpen}
                            anchorEl={selectAccountMenuAnchorEl}
                            placement="bottom-start"
                            modifiers={[
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 8],
                                    },
                                },
                            ]}
                            style={{ zIndex: 1 }}
                        >
                            <MenuList
                                sx={{
                                    border: '1px solid rgb(227, 227, 227)',
                                    width: selectAccountMenuAnchorEl ? selectAccountMenuAnchorEl.clientWidth : 'auto',
                                    paddingTop: '0 !important',
                                    paddingBottom: '0 !important',
                                }}
                            >
                                {
                                    tokens.map((token, index) => {
                                        return (
                                            <MenuItem sx={{
                                                borderBottom: index !== 4 && '1px solid rgb(227, 227, 227)',
                                                color: '#666666',
                                                fontSize: '14px',
                                            }} key={index} onClick={(e) => {
                                                setCurrentToken(tokens[index]);
                                                handleCloseSelectAccountMenu();
                                            }}>{token.email}</MenuItem>
                                        )
                                    })
                                }
                            </MenuList>
                        </Popper>
                    </RowStack>
                }
            />
        }
        {title('Google Account')}
        <GoogleSheetAuthButton onAuthSuccess={handleAuthSuccess} />
    </div>


    const selectSheet = () => <div className={classes.divider}>

        {title('Spreadsheet')}
        <BotInputField
            value={'untitled spreadsheet'}
            endAdornment={
                <>
                    <IconButton disableRipple sx={{
                        zIndex: 2,
                        width: '30px',
                        color: '#666666',
                        ".MuiSvgIcon-root": {
                            width: '20px',
                            height: '20px',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        }
                    }} onClick={handleOpenSelectAccountMenu}>
                        {!isSelectAccountMenuOpen && <KeyboardArrowDown />}
                        {isSelectAccountMenuOpen && <KeyboardArrowUp />}
                    </IconButton>
                </>
            }
        />

        {title('Sheet')}
        <BotInputField
            value={'sheet 1'}
            endAdornment={
                <>
                    <IconButton disableRipple sx={{
                        zIndex: 2,
                        width: '30px',
                        color: '#666666',
                        ".MuiSvgIcon-root": {
                            width: '20px',
                            height: '20px',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        }
                    }} onClick={handleOpenSelectAccountMenu}>
                        {!isSelectAccountMenuOpen && <KeyboardArrowDown />}
                        {isSelectAccountMenuOpen && <KeyboardArrowUp />}
                    </IconButton>
                </>
            }
        />
    </div>


    const setCell = (type: 'name' | 'value', newValue, index) => {
        setLocalNodeRowData({
            ...localNodeRowData,
            rowData: localNodeRowData.rowData.map((cell, i) => {
                if (i === index) {
                    return { ...cell, [type]: newValue }
                }
                return cell
            })
        })
    }

    const handleClickRemoveCell = (index) => {
        setLocalNodeRowData({
            ...localNodeRowData,
            rowData: localNodeRowData.rowData.filter((cell, i) => i !== index)
        })
    }

    const cellDataRow = (cell, index) => {
        return (
            <RowStack key={index} sx={{ gap: 2, marginBottom: '5px', }}>
                <Stack sx={{ height: '110px', }}>
                    <BotInputField
                        value={cell.Name}
                        onChange={(newValue) => setCell('name', newValue, index)}
                        placeholder='Name'
                    />
                </Stack>
                <Stack sx={{ height: '110px', }}>
                    <BotInputField
                        value={cell.value}
                        onChange={(newValue) => setCell('value', newValue, index)}
                        placeholder='Value'
                    />
                    <div style={{ marginTop: '10px', }}>
                        <SelectVariable buttonSize='small' onSelect={(item) => {
                            setLocalNodeRowData({
                                ...localNodeRowData,
                                rowData: localNodeRowData.rowData.map((cell, i) => {
                                    if (i === index) {
                                        return { ...cell, value: item }
                                    }
                                    return cell
                                })
                            })
                        }} />
                    </div>
                </Stack>
                <IconButton disableRipple sx={{
                    zIndex: 2,
                    width: '30px',
                    height: '110px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    color: '#666666',
                    paddingBottom: '25px',
                    ".MuiSvgIcon-root": {
                        width: '20px',
                        height: '20px',
                    },
                    ".MuiSvgIcon-root:hover": {
                        color: 'red',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    }
                }} onClick={() => handleClickRemoveCell(index)}><RemoveCircle /></IconButton>
            </RowStack>
        )
    }


    const setDataInsertOrUpdate = () => <>
        {title('New row values', { marginTop: '10px', })}
        {(localNodeRowData?.rowData ?? []).map((cell, index) => (
            cellDataRow(cell, index)
        ))}
        <BTbotbutton
            sx={{ width: '100px', }}
            styleType="dashed"
            onClick={() => {
                setLocalNodeRowData({
                    ...localNodeRowData,
                    rowData: [...localNodeRowData.rowData, { name: '', value: '' }]
                })
            }}>
            Add cell
        </BTbotbutton>
    </>

    const saveGoogleSheetsNodeData = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: {
                rowData: localNodeRowData,
            }
        }))

        onClose()
    }

    return (
        <>
            {googleAccount &&
                <div>
                    <span>{googleAccount}</span>
                    <button onClick={() => setGoogleAccount(null)}>Logout</button>
                </div>
            }
            {!googleAccount &&
                <GoogleSheetAuthButton onAuthSuccess={handleAuthSuccess} />
            }
            <button onClick={listSheets}>List Sheets</button>
            <ul>
                {sheets.map(sheet => (
                    <li key={sheet.id}>{sheet.name}</li>
                ))}
            </ul>



            <RowStack className={classes.add_google_sheets_dialog_header}>
                <span>Webhook</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </RowStack>

            {connectAccount()}

            {(currentToken || true) && selectSheet()}

            {(currentToken || true) && setDataInsertOrUpdate()}

            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton onClick={saveGoogleSheetsNodeData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
            </RowStack>
        </>
    )
}


