import React from 'react';
import emailConfirm from "@common-assets/email-confirmation.png"
import { Box, Stack } from '@mui/material';
import BTButton from '@common-components/bt-button/bt-button';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { useConfirmEmailMutation } from '@common-reducers/backend-api/backend-api';
import { modalActions } from '@common-reducers/ModalReducer';

const ConfirmEmailThankYou: React.FC = () => {

    const dispatch = useAppDispatch()
    const [confirmEmail] = useConfirmEmailMutation();

    const handleSendEmail = async () => {
        await confirmEmail({}).unwrap();
    };

    return (
        <Stack justifyContent={"center"} alignItems={"center"} p={4}>
            <Box> <img width="200" height="200" src={emailConfirm} alt="email confirmation" /></Box>
            <Stack spacing={2} sx={{ maxWidth: "450px", lineHeight: 1.6 }} mt={2} mb={4}>
                <Box>🎉 Thank you for confirming your email</Box>
                

                <Box>
                we’re super excited to have you on board. Keep an eye on your inbox for some cool stuff coming your way soon. 😊
                </Box>

                <Box>     If you have any questions or just want to say hi, don't hesitate to get in touch. We're here for you!</Box>
            </Stack>
            <BTButton onClick={() => dispatch(modalActions.setComponentToRender(''))} btvariant='text' sx={{ textTransform: "capitalize" }}>Close</BTButton>
        </Stack>
    );
};

export default ConfirmEmailThankYou;