// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/addons/dragAndDrop/styles.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!./calendar-custom.css";
import AddScheduledMessage from "@extension/context/content/components/add-scheduled-message-button/add-scheduled-message";
import { MessageConfirmationDialog } from "@extension/context/content/components/add-scheduled-message-button/message-confirmation-dialog";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { UserMessage } from "@common/models/user-message";
import { Close } from "@mui/icons-material";
import { AppBar, CircularProgress, DialogContent, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { DBUserMessageThunks, DBWaContactThunks } from "@common-reducers/DBServiceThunks";
import { useAppSelector, useAppThunkDispatch } from "@common-reducers/hooks/store.hook";
import moment from "moment";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Calendar,
  EventProps,
  momentLocalizer,
  NavigateAction,
  stringOrDate,
  ToolbarProps,
  View
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { CustomDayBackground } from "./custom-day-background";
import { CustomMonthEvent } from "./custom-message-event";
import CustomToolbar from "./custom-toolbar";
import MessageColor, { Color } from "./message-color";
import SelectedEvent from "./selected-event";
// import { isTimeToUpdateContacts } from "@common-reducers/WhatsAppSelectors";
import { handleNewMessageDialogThunk, onColorsRecurringDialogCloseThunk, onRecurringDialogCloseThunk } from "@common-reducers/CalendarThunks";
import { getCalendarEvents, getIsSocketConnectedToWhatsApp, getShowConnectionProgressBarFlag } from "@common-reducers/WhatsAppSelectors";
import { isExtensionContext } from "@common-services/detect-context";
import { CustomDayCell } from "./custom-day-cell";
import CustomMonthDayBackground from "./custom-month-day-background";
import SocketConnectBar from "./socket-connect-bar";
import { scheduleMessage } from "@common/thunks/UserMessagesThunks";


const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop<UserMessage>(Calendar);

export interface CalendarEvent extends UserMessage {
  dropDate?: moment.Moment;
}
export type EventPL = UserMessage & Partial<DOMRect>;

export type confirmationDialogOption =
  | "All messages"
  | "This message"
  | "This and following messages";

export const confirmationDialogOptions: confirmationDialogOption[] = [
  "This message",
  "This and following messages",
  "All messages",
];

interface P {
  onClose: () => void;
}

function MessageCalendar(props: P) {
  const [eventPL, setEventPL] = useState<EventPL>(null);
  const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
  const [dndEvent, setDndEvent] = useState<CalendarEvent>(null);
  const [view, setView] = useState<View>("month");
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [showDndConfirmationDialog, setShowDndConfirmationDialog] =
    useState<boolean>(false);

  const [showColorsDialog, setShowColorsDialog] = useState<boolean>(false);
  const [showColorsConfirmationDialog, setShowColorsConfirmationDialog] =
    useState<boolean>(false);
  const [eventColor, setEventColor] = useState<string>();

  const [viewMonthStart, setViewMonthStart] = useState(
    moment().startOf("month")
  );

  const viewMonthStartStr = useMemo(
    () => viewMonthStart.toISOString(),
    [viewMonthStart]
  );

  const isSocketConnectedToWhatsApp = useAppSelector(state => getIsSocketConnectedToWhatsApp(state));
  const events = useAppSelector((state) => getCalendarEvents(state, viewMonthStartStr));
  const isAddScheduledMessageModalOpen = useAppSelector((state) => state.AddScheduledMessageButtonReducer.open);
  const showConnectionProgressBar = useAppSelector((state) => getShowConnectionProgressBarFlag(state));


  const onNavigate = useCallback(
    (newDate: Date, view: View, action?: NavigateAction): void => {
      const start = moment(newDate).startOf("month");
      if (start.month() !== viewMonthStart.month()) {
        setViewMonthStart(start);
      }
    },
    [viewMonthStart]
  );



  const onSelectEvent = (
    event: UserMessage,
    e: SyntheticEvent<HTMLElement, Event>
  ): void => {
    const { left, top, bottom, right } =
      e.currentTarget.getBoundingClientRect();
    setEventPL({ ...event, calendarMessage: true, left, top, bottom, right });
    setShowEventDialog(true);
  };

  const dispatch = useAppThunkDispatch();

  // existing event
  const editEvent = () => {
    dispatch(addScheduledMessageButtonActions.editCalendarMessage(eventPL));
    setShowEventDialog(false);
  };

  const deleteEvent = () => {
    eventPL.isRecurring
      ? setShowDeleteConfirmationDialog(true)
      : dispatch(DBUserMessageThunks.delete({ entity: eventPL }));

    setShowEventDialog(false);
    setShowColorsDialog(false);
  };

  const closeEvent = () => {
    setShowEventDialog(false);
  };


  const onEventDrop = ({
    event,
    start,
  }: {
    event: UserMessage;
    start: stringOrDate;
  }) => {
    const dropDate = moment.utc(start).startOf("minute");
    if (dropDate.isSame(event.dueDate)) return;

    setDndEvent({ ...event, dropDate });

    event.isRecurring
      ? setShowDndConfirmationDialog(true)
      : dispatch(
        scheduleMessage({ ...event, dueDate: dropDate.toISOString() })
      );
  };

  const onDndRecurringDialogClose = async (value?: confirmationDialogOption) => {
    await dispatch(onRecurringDialogCloseThunk({ value, event: dndEvent }));
    setShowDndConfirmationDialog(false);
  };

  const onDeleteRecurringDialogClose = async (value?: confirmationDialogOption) => {
    await dispatch(onRecurringDialogCloseThunk({ value, event: eventPL }));

    setShowDeleteConfirmationDialog(false);
  };

  const onColorsRecurringDialogClose = async (value?: confirmationDialogOption) => {
    await dispatch(onColorsRecurringDialogCloseThunk({ value, eventColor, event: eventPL }));
    setShowColorsConfirmationDialog(false);
  };


  const onSelectSlot = (slotInfo) => {
    if (view === 'month') dispatch(handleNewMessageDialogThunk(slotInfo))
  };

  const onView = (view: View) => {
    setView(view);
  };

  const onRightClick = (pl: EventPL) => {
    setEventPL(pl);
    setShowColorsDialog(true);
  };

  const onCloseColorDialog = () => {
    setShowColorsDialog(false);
  };

  const components = useMemo(
    () => ({
      toolbar: (v: ToolbarProps<UserMessage, any>) => (
        <CustomToolbar toolbarProps={v} />
      ),
      month: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEvent eventProps={v} onRightClick={onRightClick} />
        ),
        dateHeader: ({ date, label }) => (
          <CustomDayCell date={date} label={label} />
        ),
        dateCellWrapper: ({ value }) => (
          <CustomMonthDayBackground date={value} />
        ),
      },
      week: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEvent eventProps={v} onRightClick={onRightClick} />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}  >{children}</CustomDayBackground>
        ),
      },
      day: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEvent eventProps={v} onRightClick={onRightClick} />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}  >{children}</CustomDayBackground>
        ),
      },
    }),
    []
  );

  const onColorChange = (c: Color) => {
    setEventColor(c);
    if (eventPL.isRecurring) {
      setShowColorsConfirmationDialog(true);
    } else {
      dispatch(scheduleMessage({ ...eventPL, messageColor: c }));
    }

    setShowColorsDialog(false);
  };

  useEffect(() => {
    dispatch(DBWaContactThunks.getTotalCount({}))
  }, [])



  return (
    <>

      <BTThemeProvider>
        {/* <Dialog open={true} fullScreen sx={{ direction: 'ltr' }}> */}
        <AppBar position="static">
          {isExtensionContext() &&
            <Toolbar sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                sx={{ margin: "auto", color: "var(--calendar-title-color)" }}
              >
                Messages Calendar
              </Typography>
              <Tooltip title={"Close"}>
                <IconButton
                  size="large"
                  sx={{
                    mr: 2,
                    position: "absolute",
                    right: 0,
                    color: "var(--calendar-title-color)",
                  }}
                  onClick={props.onClose}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Toolbar>
          }

          {!isSocketConnectedToWhatsApp &&
            <SocketConnectBar />
          }
        </AppBar>



        <DialogContent sx={{ height: `calc(100% - ${!isSocketConnectedToWhatsApp ? '132px' : '80px'})`, boxSizing: 'border-box' }}>
          {showConnectionProgressBar && isSocketConnectedToWhatsApp &&
            <Stack sx={{
              width: "90%",
              alignItems: "center",
              position: "absolute",
            }}>
              <CircularProgress sx={{ height: '17px !important', width: '17px !important', my: '5px', }} />
              <span style={{ fontSize: '15px', }}>Loading scheduled messages...</span>
            </Stack>
          }

          <DnDCalendar
            key={view}
            localizer={localizer}
            events={events}
            titleAccessor="message"
            startAccessor={(m) => moment(m.dueDate).toDate()}
            endAccessor={(m) => moment(m.dueDate).add(30, "minute").toDate()}
            onNavigate={onNavigate}
            onSelectEvent={onSelectEvent}
            view={view}
            onView={onView}
            resizable={false}
            selectable={true}
            onSelectSlot={onSelectSlot}
            onEventDrop={onEventDrop}
            components={components}
            dayLayoutAlgorithm="no-overlap"
          />
          {/* <Button onClick={() => onSelectSlot(moment())}>click here</Button> */}
          {showEventDialog && (
            <SelectedEvent
              event={eventPL}
              onClose={closeEvent}
              onEdit={editEvent}
              onDelete={deleteEvent}
            />
          )}
        </DialogContent>
        {/* </Dialog> */}
        {showDeleteConfirmationDialog && (
          <MessageConfirmationDialog
            title="Delete recurring message"
            options={confirmationDialogOptions}
            onClose={onDeleteRecurringDialogClose}
          />
        )}
        {showDndConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message"
            options={confirmationDialogOptions.slice(0, 2)}
            onClose={onDndRecurringDialogClose}
          />
        )}
        {showColorsDialog && (
          <MessageColor
            event={eventPL}
            onClose={onCloseColorDialog}
            onDelete={deleteEvent}
            onChange={onColorChange}
          />
        )}
        {showColorsConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message color"
            options={confirmationDialogOptions}
            onClose={onColorsRecurringDialogClose}
          />
        )}


      </BTThemeProvider>
      {isAddScheduledMessageModalOpen && <AddScheduledMessage />}
    </>
  );
}

export default MessageCalendar;
