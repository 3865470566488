import Button from '@mui/material/Button';
import { SxProps } from '@mui/system';

// Define an interface for the BTbotbutton props
interface BTbotbuttonProps {
    disabled?: boolean;
    styleType?: 'full' | 'empty' | 'dashed'; // Add more types as needed
    size?: 'small' | 'medium' | 'large'; // Add more sizes as needed
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    sx?: SxProps; // Allow additional styles to be passed
}

// Define the styles for different button types
const buttonStyles: Record<NonNullable<BTbotbuttonProps['styleType']>, SxProps> = {
    full: {
        backgroundColor: '#23a455',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(35, 164, 85, 0.85)',
        },
        '&.Mui-disabled': {
            color: '#666',
            backgroundColor: 'rgb(231, 231, 231)'
        }
    },
    empty: {
        backgroundColor: 'rgba(35, 164, 85, 0.05)',
        border: '1px solid #23a455',
        color: '#23a455',
        '&:hover': {
            backgroundColor: 'rgba(35, 164, 85, 0.1)',
        },
    },
    dashed: {
        backgroundColor: 'rgba(35, 164, 85, 0.05)',
        border: '1px dashed #23a455',
        color: '#23a455',
        '&:hover': {
            backgroundColor: 'rgba(35, 164, 85, 0.1)',
        },
    },
};

const sizeStyles: Record<NonNullable<BTbotbuttonProps['size']>, SxProps> = {
    small: {
        height: '32px'
    },
    medium: {
        height: '40px',
        minWidth: '100px'
    },
    large: {
        height: '50px'
    }

};

const BTbotbutton: React.FC<BTbotbuttonProps> = ({
    disabled = false,
    styleType = 'full',
    size = 'medium',
    onClick,
    children,
    sx,
    ...props }) => {

    const combinedSx: SxProps = {
        fontSize: '13px',
        textTransform: 'none',
        ...buttonStyles[styleType],
        ...sizeStyles[size],
        ...sx, // Allow additional styles to override default ones
    };

    return (
        <Button
            disabled={disabled}
            sx={combinedSx} // Merge styles
            onClick={onClick}
            {...props}
            disableRipple
        >
            {children}
        </Button>
    );
};


export default BTbotbutton;
