import { ReactComponent as AddIcon } from "@common-assets/add.svg";
import { Stack } from "@mui/material";
import { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { SlScreenSmartphone } from "react-icons/sl";
import PhoneInput from 'react-phone-input-2';
import { CellProps } from "../cell-interface";
import 'react-phone-input-2/lib/style.css'
import classes from "./phone_cell.module.scss";
import { isExtensionContext } from "@common-services/detect-context";

export default function PhoneNumberCell({ cellValue, readOnly, onUpdate,sx }: CellProps) {
  const [value, setValue] = useState<string | undefined>(cellValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  
  console.log(cellValue);
  
  function updateNumber(e) {
    if (value === "") {
      setValue(cellValue);
    }

    if (value !== cellValue) {
      onUpdate(value ?? "")
    }

    setIsEditing(false)
    
  }

  function handleClearValue(e) {
    e.stopPropagation();
    setIsEditing(false);
    setValue(undefined);
    onUpdate(undefined);
  }
  function setEditMode(e, value) {
    if (readOnly) return;
    e.stopPropagation();
    setIsEditing(value)
  }
  return (
    <Stack  sx={{ cursor: 'text',height: "35px", ...sx }} direction="row" alignItems={"center"} onClick={(e) => setEditMode(e, true)} 
    className={classes.phoneCell} >

      {(cellValue && !isEditing) && (
        <Stack direction={"row"}>
          <PhoneInput
           containerStyle={{width:"0",marginRight:"25px"}}
            value={cellValue.toString()}
            onChange={setValue}
            inputStyle={{ border: "none", paddingLeft: '0px', width: '100%', fontSize: '12px', height: '18px', cursor: "pointer", color: "#52bbe8", textDecoration: "underline", background: "transparent", textAlign: 'center', display: "none" }}
            disableDropdown
            disabled

            buttonStyle={{ border: "none", background: "transparent", left: "-5px",top: isExtensionContext() ? "-5px" : "-2px" }}
          />
          <a style={{ border: "none", width: 'fit-content', fontSize: '12px', height: '18px', cursor: "pointer", color: "#52bbe8", textDecoration: "underline", background: "transparent", textAlign: 'center', }} onClick={(e) => setEditMode(e, false)} href={'tel:' + cellValue}>
            +{cellValue}
          </a>
        </Stack>
      )

      }

      {isEditing &&
        <PhoneInput
          value={value ?? ""}
          onBlur={updateNumber}
          onKeyDown={(e: any) => e.key === "Enter" && e?.target?.blur()}
          onChange={setValue}
         // defaultMask={"......................."}
          //alwaysDefaultMask
          disableDropdown
          placeholder={""}
          prefix={"+"}
          autoFormat={true}
          enableAreaCodeStretch={false}
          inputProps={{
            autoFocus: true,
            readOnly:readOnly
          }}
          inputStyle={{ padding:"0",border: "none",  width: '100%', background: "transparent", fontSize: '12px', height: '18px', color: "#52bbe8",paddingLeft: '0px',textAlign:"center" }}
          buttonStyle={{ border: "none", background: "transparent", left: "-5px",visibility:"hidden" }}

        />
      }


      {!cellValue && !isEditing && (
        <>
          <div>
            <AddIcon className={classes.addIcon} />
            <SlScreenSmartphone className={classes.smPhoneIcon} />
          </div>
        </>
      )}
      {(cellValue && !isEditing && !readOnly) && (
        <div>
          <RiCloseCircleFill
            onClick={handleClearValue}
            className={classes.clearIcon}
          />
        </div>
      )}

    </Stack>
  );
}
