import { getBoardLogByIndex } from '@common-reducers/LogsSelectors'
import { useAppSelector } from '@common-reducers/hooks/store.hook'
import { Stack } from '@mui/system'
import AvatarGenerator from '../avatar-generator'
import CheckboxColumnLog from './task-logs-components/checkbox-column-log'
import DateColumnLog from './task-logs-components/date-column-log'
import DropdownColumnLog from './task-logs-components/dropdown-column-log'
import FilesColumnLog from './task-logs-components/files-column-column-log'
import InviteAcceptRemoveRestoreBoardUserLog from './task-logs-components/inviteAcceptRemoveRestoreBoardUserLog'
import LogCreation from './task-logs-components/log-creation'
import NewDeleteRestoreTaskLog from './task-logs-components/new-delete-task-log'
import NumberLinkTextPhoneColumnLog from './task-logs-components/number-link-text-phone-column-log'
import OptionStatusComplexityColumnLog from './task-logs-components/option-status-complexity-column-log'
import PersonColumnLog from './task-logs-components/person-column-log'
import RatingColumnLog from './task-logs-components/rating-column-log'
import RenameLog from './task-logs-components/rename-log'
import TaskDragToNewGroupLog from './task-logs-components/task-drag-to-new-group-log'
import TimelineColumnLog from './task-logs-components/timeline-column-log'

type Props = {
    itemId: string;
    index: number;
}

export default function LogRow({
    itemId,
    index,
}: Props) {
    const log = useAppSelector((state) => getBoardLogByIndex(state, index))

    const TaskLogData = (log) => {
        if (
            [
                'task group change'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<TaskDragToNewGroupLog logId={log._id} />)
        }
        if (
            [
                'new task', 'delete task', 'restore task',
                'new board', 'restore board', 'delete board',
                'new group', 'restore group', 'delete group',
                'new column', 'restore column', 'delete column'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<NewDeleteRestoreTaskLog logId={log._id} />)
        }
        if (
            [
                'board invite', 'accept board user invite', 'remove board user', 'restore board user'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<InviteAcceptRemoveRestoreBoardUserLog logId={log._id} />)
        }
        if (
            ['task name', 'board name', 'group name', 'column name']
                .includes(log?.logUpdateType) === true) {
            return (<RenameLog logId={log._id} />)
        }
        if (
            ['option-picker', 'status-option-picker', 'complexity-picker']
                .includes(log?.logUpdateType) === true) {
            return (<OptionStatusComplexityColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['person-picker']
                .includes(log?.logUpdateType) === true) {
            return (<PersonColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['datepicker']
                .includes(log?.logUpdateType) === true) {
            return (<DateColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['number', 'link', 'text-cell', 'phone-cell']
                .includes(log?.logUpdateType) === true) {
            return (<NumberLinkTextPhoneColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['files']
                .includes(log?.logUpdateType) === true) {
            return (<FilesColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['checkbox']
                .includes(log?.logUpdateType) === true) {
            return (<CheckboxColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['rating']
                .includes(log?.logUpdateType) === true) {
            return (<RatingColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['drop-down']
                .includes(log?.logUpdateType) === true) {
            return (<DropdownColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['timeline-picker']
                .includes(log?.logUpdateType) === true) {
            return (<TimelineColumnLog logId={log._id} isBoardLog={true} />)
        }
    }


    return (
        <Stack direction='row' key={`${index}_logs_${log?._id}`} gap={1} sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: "white",
            padding: "5px 24px 5px 16px",
            minHeight: "80px",
            marginBottom: '7px',
            borderRadius: "5px",
            boxSizing: 'border-box',
        }}>
            <AvatarGenerator itemId={log?._id} size={32} isUser={true} />
            {TaskLogData(log)}
            <div style={{ marginLeft: 'auto' }}><LogCreation logId={log?._id} /></div>
        </Stack>
    )
}