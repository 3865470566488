import { appConfig } from "@common/config/app.config";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import queryString from "qs";
import { useMemo, useCallback } from "react";

export function useRouterExtension() {
  // Helper function to create a URL from a pathname and query parameters.
  const createUrl = (pathname, query = {}) => {
    const queryStringified = queryString.stringify(query);
    return `${pathname}?${queryStringified}`;
  };

  // Using useCallback to memoize the navigate function so it doesn't change on every render.
  const navigate = useCallback((pathname, query = {}) => {
    const url = createUrl(pathname, query);

    if (chrome.tabs) {
      // We are on the extension popup
      chrome.tabs.create({
        url
      });
    } else if (chrome.extension) {
      // We are on the content script
      const link = `${appConfig.APP_ENDPOINT}${url}`;
      window.open(link, "_blank");
      //ContentMessageServiceActions.openTab(link);
    }
  }, []); // No dependencies, so the function will remain stable across renders.

  const router = useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      navigate,
      pathname: window.location.pathname,
      // Merge params and parsed query string into single "query" object
      query: {
        ...queryString.parse(window.location.search.substring(1)), // Convert string to object without the "?"
      },
      // Include match, location, history objects
      location: window.location,
      history: navigate,
    };
  }, [navigate]); // useMemo will only recompute when navigate changes.

  return router;
}
