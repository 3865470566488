import FeatureUnavailableDialog from '@common-components/tasks-panel/feature-unavailable-dialog/feature-unavailable-dialog';
import ImportExcelToBlueticks from '@common-components/tasks-panel/import-tasks-from-excel/import-excel';
import NewBoardDialog from '@common-components/tasks-panel/new-board-dialog/new-board-dialog';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { bulkActions } from '@common-reducers/BulkActionsReducer';
import { importItemsActions } from '@common-reducers/ImportItemsReducer';
import { MODAL, modalActions } from '@common-reducers/ModalReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import UpdateDetailsModal from '@common-views/account-view/update-details-modal';
import { InviteToWorkSpace } from '@common-views/user-management/invite-to-work-space';
import { useEffect } from 'react';
import ExportExcel from '../tasks-panel/export-tasks-and-groups/export-to-excel';
import ContactWorkSpaceAdmin from './contact-workspace-admin';
import ConfirmEmail from './email-confirmation-modal';
import ConfirmEmailThankYou from './email-confirmation-thankyou-modal';
import ExtendTrialModal from './extend-trial-modal';
import TrialModal from './trial-modal';
import TrialModalActivateAnimation from './trial-modal-activate-animation';
import { appConfig } from '@common/config/app.config';
import { DeleteAccountModal } from './delete-account-modal';
import { UpdateSubscriptionWorkSpace } from './update-subscription-workspace';


export default function ReusableModal() {
    const dispatch = useAppDispatch()

    const openedby = useAppSelector((state) => state.ModalReducer.openedby)
    const componentToRender = useAppSelector((state) => state.ModalReducer.component)
    const animationComponent = useAppSelector((state) => state.ModalReducer.animationComponent)
    const previousComponentToRender = useAppSelector((state) => state.ModalReducer.previousComponentToRender)


    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: openedby === 'campaign' ? 'translate(-85%, -50%)' : 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "600px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        padding: 0,
        direction: 'ltr',
    };

    useEffect(() => {
        if (componentToRender === "") {
            dispatch(importItemsActions.resetImport())
            //handleClose()
        }
    }, [componentToRender, dispatch])


    function handleClose() {
        dispatch(modalActions.setImportType());
        dispatch(bulkActions.setBulkTasksMap({}))
        dispatch(modalActions.setOpenedby(''))
        dispatch(importItemsActions.setNewAudienceName(null));
        if (componentToRender === MODAL.EMAIL_CONFIRMATION) {
            localStorage.setItem('modal_email_confirm', new Date().toISOString())
        }
        if (componentToRender !== MODAL.TRIAL_START) {
            dispatch(modalActions.setComponentToRender(''))
        }
    }

    function modalExitAnimationTimeOut() {
        console.log("previousComponentToRender", previousComponentToRender)
        return previousComponentToRender === MODAL.TRIAL_START ? 1500 : 0
    }
    return (
        <>
            <Modal
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: modalExitAnimationTimeOut(),
                    },
                }}
                open={componentToRender !== ""}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Fade in={componentToRender !== ""} timeout={{ enter: 500, exit: modalExitAnimationTimeOut() }} >
                    <Box sx={style} p={componentToRender === MODAL.IMPORT_EXCEL_TO_BLUETICKS ? 0 : 4}>
                        {componentToRender === MODAL.EXPORT_TO_EXCEL && <ExportExcel />}
                        {componentToRender === MODAL.IMPORT_EXCEL_TO_BLUETICKS && <ImportExcelToBlueticks />}
                        {componentToRender === MODAL.NEW_BOARD && <NewBoardDialog />}
                        {componentToRender === MODAL.FEATURE_NOT_AVAILABLE && <FeatureUnavailableDialog />}
                        {componentToRender === MODAL.COMPLETE_PROFILE && <UpdateDetailsModal />}
                        {componentToRender === MODAL.INVITE_TO_WORKSPACE && <InviteToWorkSpace />}
                        {componentToRender === MODAL.DELETE_ACCOUNT && <DeleteAccountModal />}
                        {componentToRender === MODAL.UPDATE_SUBSCRIPTION_WORKSPACE && <UpdateSubscriptionWorkSpace />}
                        
                        {(appConfig.asset_key === 'tudoboard') && <>
                            {componentToRender === MODAL.EMAIL_CONFIRMATION && <ConfirmEmail />}
                            {componentToRender === MODAL.EMAIL_CONFIRMATION_THANKS && <ConfirmEmailThankYou />}
                            {(componentToRender === MODAL.TRIAL_START || previousComponentToRender === MODAL.TRIAL_START) && <TrialModal />}
                            {componentToRender === MODAL.TRIAL_EXTEND && <ExtendTrialModal />}
                        </>}
                        {componentToRender === MODAL.CONTACT_WORKSPACE_ADMIN && <ContactWorkSpaceAdmin />}


                    </Box>
                </Fade>
            </Modal>

            {animationComponent === 'TrialModalActivateAnimation' && <TrialModalActivateAnimation />}
        </>
    );
}