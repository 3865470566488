import { AccessTime } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { notificationsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = {
    notificationId: string;
}

export default function NotificationCreation
    ({
        notificationId,
    }: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, { _id: notificationId }))
    const dateJS = moment(notification?.createdAt);


    const [timeSince, setTimeSince] = useState('');

    useEffect(() => {
        const updateTimeSince = () => {
            const secondsPassed = moment().diff(dateJS, 'seconds');

            if (secondsPassed < 60) {
                setTimeSince('Now');
            } else if (secondsPassed < 3600) {
                setTimeSince(`${Math.floor(secondsPassed / 60)}m`);
            } else if (secondsPassed < 86400) {
                setTimeSince(`${Math.floor(secondsPassed / 3600)}h`);
            } else if (secondsPassed < 604800) {
                setTimeSince(`${Math.floor(secondsPassed / 86400)}d`);
            } else if (secondsPassed < 2628000) {
                setTimeSince(`${Math.floor(secondsPassed / 604800)}w`);
            } else if (secondsPassed < 31536000) {
                setTimeSince(`${Math.floor(secondsPassed / 2628000)}M`);
            } else {
                setTimeSince(`${Math.floor(secondsPassed / 31536000)}y`);
            }
        };

        updateTimeSince();
        const interval = setInterval(updateTimeSince, 1000);

        return () => clearInterval(interval);
    }, [dateJS]);

    return (
        <Stack direction='row'
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#879daa',
                gap: '4px',
            }}
        >
            <AccessTime fontSize={'small'} />
            {timeSince}
        </Stack>
    );
}