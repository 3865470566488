import { toggleDarkMode } from "@common-reducers/UserThunks";
import { selectIsDarkMode } from "@common-reducers/UserSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { IconButton, Tooltip } from "@mui/material";
import {
    ReactElement,
    useEffect
} from "react";

import { BsMoonStarsFill } from "react-icons/bs";

import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { ImSun } from "react-icons/im";


import { namespace } from "@common-config/constants";
import classes from "@common-views/task-view/tasks-header.module.scss";
import { useTranslation } from "react-i18next";

export default function ToggleDarkMode(): ReactElement {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const isDarkMode = useAppSelector(selectIsDarkMode);
    //create chat task group if the board is empty

    //persist dark mode

    useEffect(() => {
        console.log("TasksHeader: only once");
        // dispatch(taskActions.setIsTasksPanelOpen(true)); setting isTasksPanelOpen to true here will mess up the bulk campaign feature in the extension, as it renders <TaskView/>
    }, [dispatch]);

    return (
        <Tooltip title={`${t('toggleDarkMode.switchTo')} ${isDarkMode ? t('toggleDarkMode.lightMode') : t('toggleDarkMode.darkMode')}`} placement="bottom" arrow >

            <IconButton
                className={classes.with_shadow_on_hover}
                sx={{
                    backgroundColor: isDarkMode ? "#013750 !important" : "#e8f9fd !important",
                    width: "36px",
                    height: "36px",
                }}
                onClick={() => dispatch(toggleDarkMode())}
            >
                {isDarkMode ? <ImSun size={16} color="white" /> : <BsMoonStarsFill size={16} color="var(--primary)" />}
            </IconButton>
        </Tooltip>
    );
}
