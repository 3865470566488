import { Box } from "@mui/material";
import { BsChatSquareTextFill } from "react-icons/bs";
import classes from "../../extra-data-button/extraDataButton.module.scss";
import { CellProps } from "../cell-interface";

export const ChatScrollingCell = ({
    taskId
}: CellProps) => {
    // const taskExtradataLength = useAppSelector((state) => getTaskExtradataLength(state, taskId));

    return <Box className={classes.convsBtn}>
        <BsChatSquareTextFill color={"#809ba8"} size={18} />

        {/* {taskExtradataLength > 0 && (
            <div className={classes.convsBtnBadge}>{taskExtradataLength}</div>
        )} */}
    </Box>
}