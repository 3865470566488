import { CellData, TaskColumnType } from '@common-components/tasks/columns-cells/cell-interface'
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs'
import { TasksGroup } from '@common-models/task-group'
import { Box } from '@mui/system'
import { _taskgroupsSelectors } from "@common-reducers/DBServiceThunks"
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer'
import { addNewColumnThunk } from '@common-reducers/TaskThunks'
import { useAppSelector } from '@common-reducers/hooks/store.hook'
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import classes from './add-column-dialog.module.scss'
import { AnalyticsService } from '@common-services/analytics-service'
import { supportedColumnTypes } from '@common-components/tasks-panel/import-tasks-from-excel/import-excel'

interface columnDataProps extends CellData {
    addNewColumnToImportModule?: (columnDescriptor: TaskColumnType<any>) => void
}


export default function AddColumnDialog({ groupId, addNewColumnToImportModule }: columnDataProps) {
    const dispatch = useAppDispatch()
    const analyticsService: AnalyticsService = new AnalyticsService();

    const tasksGroup: TasksGroup = useAppSelector((state) => {
        return _taskgroupsSelectors.selectById(state, groupId)
    });

    const addColumn = (columnDescriptor: TaskColumnType<any>) => {
        dispatch(addNewColumnThunk({
            type: columnDescriptor.type,
            title: columnDescriptor.title,
            boardId: tasksGroup?.boardId ?? 'No Board',
            width: columnDescriptor.width
        }))
        analyticsService.event("add_new_column", {
            category: "userEngagement",
            action: `add new column ${columnDescriptor.title}`,
        });

        dispatch(pickerDialogActions.closePickerDialog())
    }

    const handleClickColumn = (columnDescriptor: TaskColumnType<any>) => {
        if (addNewColumnToImportModule) {
            addNewColumnToImportModule(columnDescriptor)
        } else {
            addColumn(columnDescriptor)
        }
    }
    return (
        <Box>
            <Box py={1} className={classes.container}>
                {columnTypeList.filter(columnDescriptor => {
                    let shouldFilter = !columnDescriptor.private
                    if (addNewColumnToImportModule) {
                        shouldFilter = shouldFilter && supportedColumnTypes?.includes(columnDescriptor.type)
                    }
                    return shouldFilter
                }).map((columnDescriptor, index) =>
                    <Box key={index}>
                        <button
                            type="button"
                            className={classes.addColumnBtn}
                            onClick={() => handleClickColumn(columnDescriptor)} data-testid={`create-column-button-type-${columnDescriptor.type}`}>
                            <div className={classes.iconWrap} style={{ backgroundColor: columnDescriptor.iconAccent }}>
                                <columnDescriptor.icon color="#fff" />
                            </div>
                            <span style={{
                                marginLeft: 6,
                                fontFamily: "'Inter', 'Assistant',sans-serif",
                            }}>{columnDescriptor.title}</span>
                        </button>
                    </Box>)}
            </Box>
            {/* <Box p={1} className={classes.menuFooter} sx={{
                backgroundColor: 'var(--background-default)',
            }}>
                <button className={classes.moreColumnsBtn}>
                    More columns
                </button>
            </Box> */}
        </Box>
    )
}
