import { EventEmitter } from "events";

export enum StorageWrapperType {
  local = "local",
  sync = "sync",
}

export class StorageWrapper extends EventEmitter {
  get(type: StorageWrapperType, keyList: string[] | null, callback: any) {
    try {
      chrome.storage[type].get(keyList, (items) => {
        if (chrome.runtime.lastError) {
          this.emit("storage-error", chrome.runtime.lastError);
        }
        callback(items);
      });
    } catch (e) {
      this.emit("storage-error", e);
    }
  }

  set(type: StorageWrapperType, objectToStore: any, callback: any) {
    try {
      chrome.storage[type].set(objectToStore, () => {
        if (chrome.runtime.lastError) {
          this.emit("storage-error", chrome.runtime.lastError);
        }
        callback();
      });
    } catch (e) {
      this.emit("storage-error", e);
    }
  }

  remove(type: StorageWrapperType, keys: string[]) {
    // key must be defined!
    if (!keys) {
      return;
    }

    try {
      chrome.storage[type].remove(keys, () => {
        // console.log('keys removed!');
        this.get(type, keys, (res: any) => {
          // console.log(res);
        });
      });
      if (chrome.runtime.lastError) {
        this.emit("storage-error", chrome.runtime.lastError);
      }
    } catch (e) {
      this.emit("storage-error", e);
    }
  }

  clear(type: StorageWrapperType) {
    try {
      chrome.storage[type].clear();
      if (chrome.runtime.lastError) {
        this.emit("storage-error", chrome.runtime.lastError);
      }
    } catch (e) {
      this.emit("storage-error", e);
    }
  }
}

export function isValidChromeRuntime() {
  // It turns out that chrome.runtime.getManifest() returns undefined when the
  // runtime has been reloaded.
  // Note: If this detection method ever fails, try to send a message using
  // chrome.runtime.sendMessage. It will throw an error upon failure.
  let validRuntime = false;
  try {
    validRuntime = chrome.runtime && !!chrome.runtime.getManifest();
  } catch (e) {}
  return validRuntime;
}

export function getRuntimeUrl(path: string) {
  let runtimeUrl = path;

  if (
    isValidChromeRuntime() &&
    chrome.runtime.getURL
  ) {
    runtimeUrl = chrome.runtime.getURL(path);
  } else {
    runtimeUrl = path;
  }
  return runtimeUrl;
}

export async function setStorageKey(key: string, value: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (chrome.storage) {
      if (isValidChromeRuntime()) {
        chrome.storage.local.set({ [key]: value });
      }
    } else {
      localStorage.setItem(key, value);
    }
    resolve();
  });
}

export async function getStorageKey(key: string): Promise<string | null> {
  return new Promise((resolve, reject) => {
    if (chrome.storage) {
      if (isValidChromeRuntime()) {
        chrome.storage.local.get([key], async (result: any) => {
          resolve(result?.[key]);
        });
      } else {
        reject();
      }
    } else {
      resolve(localStorage.getItem(key));
    }
  });
}

export async function clearStorageKey(key: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (chrome.storage) {
      if (isValidChromeRuntime()) {
        chrome.storage.local.remove(key, () => {
          resolve();
        });
      }
    } else {
      localStorage.removeItem(key);
      resolve();
    }
  });
}
