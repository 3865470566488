import { Alert } from '@mui/material';





// import { isTimeToUpdateContacts } from "@common-reducers/WhatsAppSelectors";
import RowStack from "@common-components/common/row-stack/RowStack";
import SocketConnectionStatusButton from "@common-components/socket-connection-status-button/socket-connection-status-button";
import { toggleSocketModal } from "@common-reducers/MainReducer";
import QrModal from "@common-views/qr-view/qr-modal";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';

type Props = {}

export default function SocketConnectBar({ }: Props) {
    const dispatch = useAppDispatch()
    const isWaSocketsLimitReached = useAppSelector((state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached)
    return (
        <>
            <Alert severity="warning"
                icon={false}
                sx={{
                    alignItems: "center",
                    boxShadow: "none",
                    ".MuiAlert-message": {
                        width: "100%",
                        textAlign: "center",
                        padding: "0",
                    }
                }}>
                {!isWaSocketsLimitReached &&
                    <RowStack
                        justifyContent={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            dispatch(toggleSocketModal(true))
                        }}>
                        <SocketConnectionStatusButton />
                        In order to schedule messages in the calendar, please connect your device first.
                    </RowStack>
                }
            </Alert>
            <QrModal />
        </>
    )
}