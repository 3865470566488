import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box';
import { Edit } from '@mui/icons-material';
import { ClickAwayListener, Icon } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DBWorkloadThunks, workloadSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { getUserCapacityList, getTaskComplexityList, getProfileCapacity, getDefaultCapacity } from '@common-reducers/TaskSelectors';

import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


type Props = {
    boardId: string;
    workloadId: string;
    complexityPickerId: string;
    userId: string;
}

export default function UserCapacity({
    boardId,
    workloadId,
    complexityPickerId,
    userId,
}: Props) {
    const { t } = useTranslation(namespace);
    const userCapacityList = useAppSelector((state) => getUserCapacityList(state, userId, workloadId, complexityPickerId))
    const complexityList = useAppSelector((state) => getTaskComplexityList(state, userId, workloadId, complexityPickerId))
    const profileCapacity = useAppSelector((state) => getProfileCapacity(state, workloadId, userId))
    const defaultCapacity = useAppSelector((state) => getDefaultCapacity(state, workloadId))
    const workloadPeople = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'people'));

    const dispatch = useAppDispatch()

    const [isEditMode, setIsEditMode] = useState(false)

    const [userCapacity, setUserCapacity] = useState<number | undefined>(undefined)


    const sumOfCapacity = complexityList.reduce((sum, item: number) => sum = sum + item, 0);


    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        height: "100%",
        fontSize: "12px",
        borderRadius: "9px",
        border: "1px solid #53bdeb",
        fontFamily: "inherit",
        display: 'flex',
        alignItems: 'center',
        padding: "3px 6px",
    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "12px",
        border: "1px solid transparent",
        borderRadius: "9px",
        "&:hover": {
            border: "1px dashed var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "4px 6px",
        width: "100%",
        height: "100%",
        fontFamily: "inherit",
    };

    const handleStopEditMode = (newCapacity) => {
        if (newCapacity !== undefined) {
            if (!isNaN(newCapacity)) {
                dispatch(
                    DBWorkloadThunks.patch({
                        entity: {
                            _id: workloadId,
                            people: {
                                ...workloadPeople,
                                [userId]: {
                                    ...(workloadPeople === undefined ? {} : workloadPeople[userId]),
                                    capacity: newCapacity as number,
                                }
                            }
                        }
                    })
                )
            }
        }
        setIsEditMode(false)
    }


    return (
        <ClickAwayListener onClickAway={() => handleStopEditMode(userCapacity)}>
            <Box sx={{
                width: '100%',
                position: 'relative',
                height: "44px",
            }}
            >
                <Stack direction='column'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        opacity: '1',
                        fontSize: "12px",
                        cursor: 'pointer',
                        "&:hover .edit-capacity": {
                            display: 'block',
                        },
                        "&:hover .preview-capacity": {
                            filter: 'blur(2px)',
                            opacity: '0.35',
                            display: 'block',
                        },
                    }}>
                    <div className="preview-capacity"
                        style={{
                            color: sumOfCapacity > profileCapacity ? 'tomato' : sumOfCapacity === 0 ? '#809BA9' : 'var(--primary)',
                            fontWeight: sumOfCapacity > profileCapacity ? '800' : 'normal',
                        }}>{sumOfCapacity}/{profileCapacity}</div>
                    <Box sx={{
                        marginTop: '4px',
                        width: '100%',
                        height: '10px',
                        borderRadius: '10px',
                        border: '1px solid var(--border-color)',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                        fontSize: "12px",
                    }}
                        className="preview-capacity"
                    >
                        {userCapacityList
                            .map((task, index) => (
                                <Box sx={{
                                    width: `${task[0]}%`,
                                    height: '100%',
                                    backgroundColor: task[1],
                                }}
                                    key={index}></Box>
                            ))
                        }
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        inset: 0,
                        border: '1px dashed black',
                        borderRadius: '9px',
                        width: '100%',
                        height: '100%',
                        display: 'none',
                    }}
                        className="edit-capacity"
                        onClick={() => { setIsEditMode(true) }}>
                        <Box sx={{
                            display: isEditMode ? 'none' : 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '12px',
                            fontWeight: '800',
                            height: '100%',
                        }}>
                            <Icon sx={{
                                marginInlineEnd: "6px",
                                fontSize: 'unset'
                            }}>
                                <Edit sx={{
                                    fontSize: "14px",
                                }} />
                            </Icon>
                            {t('userCapacity.editCapacity')}
                        </Box>
                    </Box>
                    <Box className="edit-capacity-input"
                        sx={{
                            border: '1px solid #53bdeb',
                            borderRadius: '9px',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            display: isEditMode ? 'block' : 'none',
                            backgroundColor: "var(--tasks-header-background)",
                            position: "absolute",
                            inset: 0,
                            "& *": {
                                width: "100%",
                                height: "100%",
                            },
                            "& input": {
                                boxSizing: "border-box",
                                paddingX: "6px",
                                fontSize: "18px",
                                fontWeight: "200",
                                textAlign: "center",
                            },
                        }}
                        onClick={() => { console.log(userCapacity) }}>
                        <BtEditableBox
                            inputSx={{ inputTheme }}
                            boxSx={{ textTheme }}
                            placeholder={t('userCapacity.editCapacityPlaceholder')}
                            type='number'
                            text={userCapacity !== undefined ? userCapacity.toString() : !!profileCapacity ? profileCapacity : defaultCapacity}
                            forceEditMode={true}
                            onValueUpdate={(value) => { setUserCapacity(value) }}
                            onEnterPress={(value) => { handleStopEditMode(value) }}
                        />
                    </Box>
                </Stack>
            </Box >
        </ClickAwayListener>
    )
}