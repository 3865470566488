
enum ValueInputType {
    SELECT = 'select',
    TEXT = 'text',
    PERSON = 'person'
}
interface Operator {
    [key: string]: {
        value: string;
        isHideValueField?: boolean;
        isSpecialTimelineFilter?: boolean;
        isSpecialDateFilter?: boolean;
        isSpecialDateSelectFilter?: boolean;
        isSpecialBetweenDatesFilter?: boolean;
        isMultiselectForTextField?: boolean;
    };
}

interface FilterValue {
    [key: string]: string;
}

export interface FilterConfig {
    filterType: string;
    displayName: string;
    valueInputType?: ValueInputType;
    operatorsList: Operator;
    valuesList?: FilterValue[];
    isHideValueField?: boolean;
}

export const filtersListConfig: FilterConfig[] = [
    {
        filterType: "status-option-picker",
        displayName: "Status",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true }
        }
    },
    {
        filterType: "group",
        displayName: "Group",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
        }
    },
    {
        filterType: "number",
        displayName: "Number",
        valueInputType: ValueInputType.TEXT,
        operatorsList: {
            "=": { "value": "===" },
            "≠": { "value": "!==" },
            ">=": { "value": ">=" },
            ">": { "value": ">" },
            "<=": { "value": "<=" },
            "<": { "value": "<" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true }
        }
    },
    {
        filterType: "person-picker",
        displayName: "Person",
        valueInputType: ValueInputType.PERSON,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
            "is empty": { "value": "is empty", isHideValueField: true },

        }
    },
    {
        filterType: "phone-cell",
        displayName: "Phone",
        valueInputType: ValueInputType.TEXT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
            "contains": { "value": "includes" },
            "does not contain": { "value": "!includes" }
        }
    },
    {
        filterType: "option-picker",
        displayName: "Option Picker",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true }
        }
    },
    {
        filterType: "complexity-picker",
        displayName: "Complexity Picker",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true }
        }
    },
    {
        filterType: "timeline-picker",
        displayName: "Timeline Picker",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "is not" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true },
            "ends between": { "value": "ends between", isSpecialBetweenDatesFilter: true, },
            "ends on": { "value": "ends on", isSpecialTimelineFilter: true },
            "ends after": { "value": "ends after", isSpecialTimelineFilter: true },
            "ends before": { "value": "ends before", isSpecialTimelineFilter: true },
            "end on or before": { "value": "end on or before", isSpecialTimelineFilter: true },
            "ends on or after": { "value": "ends on or after", isSpecialTimelineFilter: true },
            "start between": { "value": "start between", isSpecialBetweenDatesFilter: true, },
            "start on": { "value": "start on", isSpecialTimelineFilter: true },
            "start after": { "value": "start after", isSpecialTimelineFilter: true },
            "start before": { "value": "start before", isSpecialTimelineFilter: true },
            "start on or before": { "value": "start on or before", isSpecialTimelineFilter: true },
            "start on or after": { "value": "start on or after", isSpecialTimelineFilter: true },
        },
        valuesList: [
            { 'Today': "Today" },
            { 'Tomorrow': "Tomorrow" },
            { 'Yesterday': "Yesterday" },
            { 'This week': "This week" },
            { 'Last week': "Last week" },
            { 'Next week': "Next week" },
            { 'This month': "This month" },
            { 'Last month': "Last month" },
            { 'Next month': "Next month" },
            { 'Past': "Past" },
            { 'Future': "Future" },
            { 'Blank': "Blank" },

        ]
    },
    {
        filterType: "datepicker",
        displayName: "Date Picker",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "is" },
            "is not": { "value": "is not" },
            "in the next": { "value": "in the next", isSpecialDateFilter: true },
            "in the last": { "value": "in the last", isSpecialDateFilter: true },
            "is between": { "value": "is between", isSpecialBetweenDatesFilter: true, isSpecialDateFilter: true },
            "is on or after": { "value": "is on or after", isSpecialDateSelectFilter: true },
            "is after": { "value": "is after", isSpecialDateSelectFilter: true },
            "is on or before": { "value": "is on or before", isSpecialDateSelectFilter: true },
            "is before": { "value": "is before", isSpecialDateSelectFilter: true },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true },
            "is only": { "value": "is only", isSpecialDateSelectFilter: true },
            "is anything but": { "value": "is anything but", isSpecialDateSelectFilter: true },

        },
        valuesList: [
            { 'Today': "Today" },
            { 'Tomorrow': "Tomorrow" },
            { 'Yesterday': "Yesterday" },
            { 'This week': "This week" },
            { 'Last week': "Last week" },
            { 'Next week': "Next week" },
            { 'This month': "This month" },
            { 'Last month': "Last month" },
            { 'Next month': "Next month" },
            { 'Past': "Past" },
            { 'Future': "Future" },
            { 'Blank': "Blank" },
            // { 'exact date': "exact date" },

        ]
    },
    {
        filterType: "last-updated",
        displayName: "Last Updated",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "is" },
            "is not": { "value": "is not" },
            "in the next": { "value": "in the next", isSpecialDateFilter: true },
            "in the last": { "value": "in the last", isSpecialDateFilter: true },
            "is between": { "value": "is between", isSpecialBetweenDatesFilter: true },
            "is on or after": { "value": "is on or after", isSpecialDateSelectFilter: true },
            "is after": { "value": "is after", isSpecialDateSelectFilter: true },
            "is on or before": { "value": "is on or before", isSpecialDateSelectFilter: true },
            "is before": { "value": "is before", isSpecialDateSelectFilter: true },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true },
            "is only": { "value": "is only", isSpecialDateSelectFilter: true },
            "is anything but": { "value": "is anything but", isSpecialDateSelectFilter: true },

        },
        valuesList: [
            { 'Today': "Today" },
            { 'Yesterday': "Yesterday" },
            { 'This week': "This week" },
            { 'Last week': "Last week" },
            { 'This month': "This month" },
            { 'Last month': "Last month" },
            { 'Past': "Past" },
            { 'Blank': "Blank" },
            // { 'exact date': "exact date" },



        ]
    }, {
        filterType: "created",
        displayName: "Created",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "is" },
            "is not": { "value": "is not" },
            "in the next": { "value": "in the next", isSpecialDateFilter: true },
            "in the last": { "value": "in the last", isSpecialDateFilter: true },
            "is between": { "value": "is between", isSpecialBetweenDatesFilter: true },
            "is on or after": { "value": "is on or after", isSpecialDateSelectFilter: true },
            "is after": { "value": "is after", isSpecialDateSelectFilter: true },
            "is on or before": { "value": "is on or before", isSpecialDateSelectFilter: true },
            "is before": { "value": "is before", isSpecialDateSelectFilter: true },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true },
            "is only": { "value": "is only", isSpecialDateSelectFilter: true },
            "is anything but": { "value": "is anything but", isSpecialDateSelectFilter: true },

        },
        valuesList: [
            { 'Today': "Today" },
            { 'Yesterday': "Yesterday" },
            { 'This week': "This week" },
            { 'Last week': "Last week" },
            { 'This month': "This month" },
            { 'Last month': "Last month" },
            { 'Past': "Past" },
            { 'Blank': "Blank" },
            // { 'Exact date': "Exact date" },

        ]
    },
    {
        filterType: "rating",
        displayName: "Rating",
        valueInputType: ValueInputType.SELECT,
        operatorsList: {
            "is": { "value": "===" },
            "is not": { "value": "!==" },
        }
    },
    {
        filterType: "checkbox",
        displayName: "Checkbox",
        isHideValueField: true,
        operatorsList: {
            "is checked": { "value": "is checked", isHideValueField: true },
            "is not checked": { "value": "is not checked", isHideValueField: true },
        }
    },
    {
        filterType: "drop-down",
        displayName: "Drop-down",
        valueInputType: ValueInputType.TEXT,
        operatorsList: {
            "is": { "value": "===", isMultiselectForTextField: true },
            "is not": { "value": "!==", isMultiselectForTextField: true },
            "contains": { "value": "includes" },
            "does not contain": { "value": "!includes" }
        }
    },
    {
        filterType: "link",
        displayName: "Link",
        valueInputType: ValueInputType.TEXT,
        operatorsList: {
            "is": { "value": "===", isMultiselectForTextField: true },
            "is not": { "value": "!==", isMultiselectForTextField: true },
            "contains": { "value": "includes" },
            "does not contain": { "value": "!includes" }
        }
    },
    {
        filterType: "text-cell",
        displayName: "Text Cell",
        valueInputType: ValueInputType.TEXT,
        operatorsList: {
            "is": { "value": "===", isMultiselectForTextField: true },
            "is not": { "value": "!==", isMultiselectForTextField: true },
            "contains": { "value": "includes" },
            "does not contain": { "value": "!includes" },
            "is empty": { "value": "is empty", isHideValueField: true },
            "is not empty": { "value": "is not empty", isHideValueField: true },
        }
    }
]
