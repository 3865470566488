
import axios, { AxiosResponse } from "axios";
import { Poll } from 'whatsapp-web.js/src/structures';
import MessageMedia from 'whatsapp-web.js/src/structures/MessageMedia';
import { addHiddenDecryptedMessageIdToMessageText, getWaMessageType, initWaMessageOptions, replaceMentionsAndExtractIds as prepareMessageMentions } from "./process-message-utils";
import { UserMessage, WaMessage } from "./wa-interfaces";

export async function prepareMessageForWa(message: UserMessage): Promise<WaMessage> {

    let waMessage: WaMessage = {};

    waMessage.type = getWaMessageType(message);
    waMessage.chatId = message.contactList[0].id;


    switch (waMessage.type) {
        case 'text':
        case 'newsletterText':
        case 'image':
        case "attachmentWithoutImage":
        case 'serverImage':
            let messageText = message.message;
            let messageOptions = {};

            const { messageTextWithMentions, messageOptionsWithMentionedIds } = await prepareMessageMentions(messageText, messageOptions)

            waMessage.content = addHiddenDecryptedMessageIdToMessageText(messageTextWithMentions, message._id.toString())
            waMessage.options = prepareTextMessageWithReply(message?.replyData, messageOptionsWithMentionedIds)

            if ((message.asset && waMessage.type === 'serverImage') ||
                (message.asset && waMessage.type === 'attachmentWithoutImage')) {
                const attachment = await MessageMedia.fromUrl(message.asset.url, { unsafeMime: true });
                waMessage.options.attachment = attachment;
                // Support new API in newsletter branch
                waMessage.options.media = attachment;
                waMessage.options.caption = waMessage.content as string;
                waMessage.content = '';
            }
            else if (message.asset && waMessage.type === 'image') {
                const attachment = await prepareMessageAttachment(message.asset);
                waMessage.options.attachment = attachment;
                // Support new API in newsletter branch
                waMessage.options.media = attachment;
                waMessage.options.caption = waMessage.content as string;
                waMessage.content = '';
            }
            break;

        case 'poll':

            waMessage.content = '';
            let pollMessageOptions = await preparePollMessageOptions(message);
            pollMessageOptions.poll.pollName = addHiddenDecryptedMessageIdToMessageText(message?.poll?.question, message._id.toString())
            waMessage.options = pollMessageOptions;
            break;

        case 'newsletterPoll':
            // Handle newsletter poll message
            break;
        case 'newsletterImage':
            const media = await prepareMediaOptions(message.asset);
            waMessage.content = '';
            waMessage.options = {
                caption: message.message,
                media
            };
            break;
        default:
            // Optional: Handle unsupported message types
            console.warn(`Unsupported message type: ${waMessage.type}`);
            break;
    }

    return waMessage; // Return the original message
}

const prepareMediaOptions = async (asset: any) => {
    const base64FromUrl = await axios.get(asset.url, { responseType: 'arraybuffer' })
        .then(response => Buffer.from(response.data, 'binary').toString('base64'));
    
    return {
        data: base64FromUrl,
        mimeType: asset.type,
        filename: asset.name
    }
}

const prepareMessageAttachment = async (asset: any) => {

    const response: AxiosResponse<Blob> = await axios.get(
        asset.url,
        { responseType: "blob" }
    );

    const assetFile = new File([response.data], asset.name, {
        type: asset.type,
    });

    const attachment = {
        file: assetFile,
        filename: assetFile.name,
        mimetype: assetFile.type,
    };
    return attachment;
}

const preparePollMessageOptions = async (message: UserMessage) => {

    const pollOptions = message.poll.options
        .filter(o => o.text && o.text !== '')
        .sort((a, b) => a.order - b.order)
        .map((option) => {
            return option.text
        })


    let poll = new Poll(
        message?.poll?.question,
        pollOptions,
        { allowMultipleAnswers: message?.poll?.allowMultiple },
    )

    let options: any = {};
    let internalOptions: any = initWaMessageOptions(options)
    internalOptions.poll = poll;

    return internalOptions
}

const prepareTextMessageWithReply = (replyData: any, options: any) => {

    if (replyData) {
        options = { ...options, quotedMessageId: replyData?._serialized, }
    }

    return options
}