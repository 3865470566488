// useCleanupRegister.js
import { useMemo, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@common-reducers/hooks/store.hook';
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer';

import randomstring from 'randomstring'

export const useCleanupRegister = (cleanupCallback) => {
  const dispatch = useAppDispatch();
  //const componentIdsForCleanup = useAppSelector((state) => state.PickerDialogReducer.componentIdsForCleanup);
  const pickerChangeIndicator = useAppSelector((state) => state.PickerDialogReducer.pickerChangeIndicator);

  const componentId = useMemo(() => randomstring.generate(16), 
  [pickerChangeIndicator]); // Update the componentId whenever pickerChangeIndicator changes

  useEffect(() => {
    dispatch(pickerDialogActions.registerComponentForCleanup(componentId));

    return () => {
      dispatch(pickerDialogActions.unregisterComponentForCleanup(componentId));
    };
  }, [componentId, dispatch]);

  // useEffect(() => {
  //   if (componentIdsForCleanup.includes(componentId)) {
  //     cleanupCallback();
  //     dispatch(pickerDialogActions.unregisterComponentForCleanup(componentId));
  //   }
  // }, [cleanupCallback, componentId, componentIdsForCleanup, pickerChangeIndicator, dispatch]);

  return componentId;
}
