import BTInputField from '@common-components/bt-input-field/bt-input-field'
import { SearchOutlined } from '@mui/icons-material'
import { InputAdornment, SxProps } from '@mui/material'
import React from 'react'

type Props = {
    searchTerm: string;
    setSearchTerm: any;
    placeHolderString: string;
    sx?: SxProps;
}

export default function BulkSearchField({
    searchTerm,
    setSearchTerm,
    placeHolderString,
    sx={}
}: Props) {
    return (
        <BTInputField sx={{
            backgroundColor: 'var(--search-input-background)',
            width: '100%',
            borderRadius: '10px',
            padding: '0px 8px 0px 0px',
            ".MuiOutlinedInput-notchedOutline": {
                border: "0px",
            },
            ...sx,
        }}
            value={searchTerm}
            type="text"
            onChange={({ target }: any) => {
                setSearchTerm(target.value)
            }}
            placeholder={placeHolderString}
            startAdornment={
                <InputAdornment position="start">
                    <SearchOutlined sx={{ color: 'var(--primary)', mx: '10px', }} />
                </InputAdornment>
            }
        />
    )
}