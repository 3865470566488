import BTButton from '@common-components/bt-button/bt-button';
import EditableOptions from '@common-components/tasks/columns-cells/option-picker/EditableOptions';
import MappedOptions from '@common-components/tasks/columns-cells/option-picker/MappedOptions';
import { TaskColumnOption } from '@common-models/task-column-option';
import { Box, FormControl, IconButton, MenuItem, MenuList, Select, Stack, TextField, Tooltip } from '@mui/material';
import { getMockPersonList, getSelectedBoardUserList } from '@common-reducers/BoardsSelectors';
import { DBTaskColumnOptionThunks, DBWorkloadThunks, isTempId, taskColumnOptionSelectByQuery, workloadSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { selectColumnListByType } from '@common-reducers/TaskSelectors';
import { createMockPersonThunk } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';

import { namespace } from '@common/config/constants';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import classes from './bt-workload.module.scss';
import WorkloadMockUserSettingsRow from './workload-mock-user-settings-row';


type Props = {
    workloadId: string;
    boardId: string;

}

export default function WorkloadSettingsDrawer({
    workloadId,
    boardId,
}: Props) {
    const { t } = useTranslation(namespace);
    const complexityPickerList = useAppSelector(state => selectColumnListByType(state, boardId, 'complexity-picker'))
    const complexityPickerId = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'complexityPickerId'))
    const columnOptions = useAppSelector((state) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId: complexityPickerId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))

    const personPickerList = useAppSelector(state => selectColumnListByType(state, boardId, 'person-picker'))
    const personPickerId = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'personPickerId'))

    const defaultCapacity = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'defaultCapacity'))
    const realPersonList = useAppSelector((state) => getSelectedBoardUserList(state));
    const mockPersonList = useAppSelector((state) => getMockPersonList(state));



    const dispatch = useAppDispatch();
    const [isCompEditing, setIsCompEditing] = useState(false);



    const handleDropDownChange = (event: React.ChangeEvent<HTMLInputElement>, type) => {
        if (!isTempId(event.target.value)) {
            dispatch(
                DBWorkloadThunks.patch({
                    entity: {
                        _id: workloadId,
                        [type]: event.target.value
                    }
                })
            )
        }
    }

    const selectFieldCss = {
        backgroundColor: 'var(--default-background)',
        height: '40px',
        borderRadius: '8px',
        fontSize: '14px',
        fontFamily: "'Inter'",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #DEEEF2 !important",
        },
    }
    const textFieldCss = {
        ...selectFieldCss,
        "& .MuiInputBase-root": {
            fontSize: '14px',
            fontFamily: "'Inter'",
            borderRadius: '8px',
        },
        "& .MuiInputBase-input": {
            height: '40px',
            padding: '0 16px !important',
        },
    }

    const complexityPickerSelect = () => (
        <Stack direction='column' >
            <Stack direction='row' className={classes.settings_drawer_title}>
                <div className={classes.field_title}>{t('workloadSettingsDrawer.complexityColumnTitle')}</div>
                <Tooltip title={t('workloadSettingsDrawer.complexityColumnTooltip')} placement="top">
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <FormControl fullWidth>
                <Select
                    sx={selectFieldCss}
                    labelId="label-ID"
                    id="id-of-???"
                    value={complexityPickerId}

                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDropDownChange(event, "complexityPickerId")}
                >
                    {complexityPickerList
                        .map((picker, pickerIndex) => {
                            return (
                                <MenuItem
                                    value={picker._id}
                                    key={pickerIndex}
                                >
                                    {picker.title ?? t('workloadSettingsDrawer.unnamedGroup')}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Stack>
    )

    const personPickerSelect = () => (
        <Stack direction='column' sx={{ marginTop: '32px', }}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <div className={classes.field_title}>{t('workloadSettingsDrawer.personColumnTitle')}</div>
                <Tooltip title={t('workloadSettingsDrawer.personColumnTooltip')} placement="top">
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <FormControl fullWidth>
                <Select
                    sx={selectFieldCss}


                    labelId="label-ID"
                    id="id-of-???"
                    value={personPickerId}

                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDropDownChange(event, "personPickerId")}
                >
                    {personPickerList
                        .map((picker, pickerIndex) => {
                            return (
                                <MenuItem
                                    value={picker._id}
                                    key={pickerIndex}
                                >
                                    {picker.title ?? t('workloadSettingsDrawer.unnamedGroup')}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Stack>
    )

    const handleChange = (value) => {
        const valueToDispatch = value === '' || 0 ? 100 : value
        dispatch(
            DBWorkloadThunks.patch({
                entity: {
                    _id: workloadId,
                    defaultCapacity: valueToDispatch
                }
            })
        )
    }

    const defaultCapacityBox = () => (
        <Stack direction='column' sx={{ marginTop: '32px', }}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <div className={classes.field_title}>{t('workloadSettingsDrawer.defaultCapacityTitle')}</div>
                <Tooltip title={t('workloadSettingsDrawer.defaultCapacityTooltip')} placement="top">
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <TextField
                type="number"
                sx={textFieldCss}
                value={defaultCapacity ?? 100}
                onChange={(event) => handleChange(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Stack>
    )


    const canEdit = useMemo(() => complexityPickerId && !isTempId(complexityPickerId), [complexityPickerId])

    const editabbleComplexityOptions = () => {

        const handleListKeyDown = (event: React.KeyboardEvent) => {
            if (event.key === "Tab") {
                event.preventDefault();
            }
        }

        const updateTask = (option: TaskColumnOption) => {

        }

        const onEditCancel = (_pickerOptions) => {
            setIsCompEditing(false);
        }

        const onEditChange = (options: TaskColumnOption[]) => {
            // Create new columns
            options
                .filter(option => !option._id)
                .forEach(option => {
                    dispatch(DBTaskColumnOptionThunks.create({
                        ...option,
                        boardId,
                        columnId: complexityPickerId,
                    }))
                })
            // Patch existing columns
            const updatedOptions = options
                .filter(option => option._id && !isTempId(option._id))
                .map(option => ({
                    id: option._id,
                    changes: option
                }))
            dispatch(DBTaskColumnOptionThunks.patchMany(updatedOptions));
            // Delete options that are not returned
            const newOptionIdList = options
                .filter(option => option._id && !isTempId(option._id))
                .map(option => option._id);
            const deletedOptions = columnOptions
                .filter(option => newOptionIdList.indexOf(option._id) === -1)
                .map(option => ({
                    id: option._id,
                    changes: {
                        deleted: true
                    }
                }));
            dispatch(DBTaskColumnOptionThunks.patchMany(deletedOptions));
        }


        const onSave = () => {
            setIsCompEditing(false);
        }

        return (
            <Box sx={{ marginTop: '32px', }}>
                <Stack direction='row' className={classes.settings_drawer_title}>
                    <div className={classes.field_title}>{t('workloadSettingsDrawer.optionsTitle')}</div>
                    <Tooltip title={t('workloadSettingsDrawer.optionsTooltip')} placement="top">
                        <IconButton>
                            <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <MenuList
                    autoFocusItem={true}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    sx={{
                        backgroundColor: 'var(--default-background)',
                        border: '2px solid var(--default-background)',
                        borderLeft: '20px solid var(--default-background)',
                        borderRight: '20px solid var(--default-background)',
                        borderRadius: '10px',
                        margin: '-4px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {isCompEditing ? (
                        <EditableOptions
                            onChange={onEditChange}
                            onCancel={onEditCancel}
                            canEdit={canEdit}
                            options={columnOptions}
                            isComplexity={true}
                            onSave={onSave}
                        />
                    ) : (
                        <MappedOptions
                            options={columnOptions}
                            handleValueSelected={updateTask}
                            canEdit={canEdit}
                            onEdit={() => setIsCompEditing(true)}
                            isComplexity={true}
                        />
                    )}
                </MenuList>
            </Box>
        )
    }




    const editablePeopleOptions = () => {


        const superheroes = [
            "Peter Parker",         // Spider-Man
            "Tony Stark",           // Iron Man
            "Steve Rogers",         // Captain America
            "Thor Odinson",         // Thor
            "Bruce Banner",         // Hulk
            "Logan Howlett",        // Wolverine
            "Stephen Strange",      // Doctor Strange
            "Matt Murdock",         // Daredevil
            "Wade Wilson",          // Deadpool
            "Clark Kent",           // Superman
            "Bruce Wayne",          // Batman
            "Barry Allen",          // The Flash
            "Arthur Curry",         // Aquaman
            "Oliver Queen",         // Green Arrow
            "Hank Pym",             // Ant-Man
            "Scott Lang",           // Ant-Man (Scott Lang)
            "Wanda Maximoff",       // Scarlet Witch
            "Carol Danvers",        // Captain Marvel
            "Diana Prince",         // Wonder Woman
            "Natasha Romanoff",     // Black Widow
            "Kara Zor-El",          // Supergirl
            "Selina Kyle",          // Catwoman
            "Ororo Munroe",         // Storm
            "Jean Grey",            // Jean Grey
            "Harleen Quinzel",      // Harley Quinn
            "Barbara Gordon",       // Batgirl
            "Raven Darkhölme"       // Mystique
        ];

        const handleSave = () => {
            const randomIndex = Math.floor(Math.random() * superheroes.length);
            const randomName = superheroes[randomIndex];
            dispatch(createMockPersonThunk({
                columnId: undefined,
                newPersonName: randomName,
                imgUrl: '',
                imgColor: '',
            }));
        }





        return (
            <Box sx={{ marginTop: '32px', marginBottom: '50px' }}>
                <Stack direction='row' className={classes.settings_drawer_title}>
                    <div className={classes.field_title}>{t('workloadSettingsDrawer.membersTitle')}</div>
                    <Tooltip title={t('workloadSettingsDrawer.membersTooltip')} placement="top">
                        <IconButton>
                            <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Box sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    backgroundColor: "var(--background-default)",
                    border: '1px solid #DEEEF2',
                }}>
                    {realPersonList && realPersonList.map((person, index) => {
                        return (
                            <Box key={index} className={classes.person_row}>
                                <WorkloadMockUserSettingsRow
                                    workloadId={workloadId}
                                    person={person}
                                    index={index}
                                />
                            </Box>
                        );
                    })}
                    {mockPersonList && mockPersonList.map((person, index) => {
                        return (
                            <Box key={index} className={classes.person_row}>
                                <WorkloadMockUserSettingsRow
                                    workloadId={workloadId}
                                    person={person}
                                    index={index}
                                />
                            </Box>
                        );
                    })}

                    {/* <PersonPickerEditableOptions
                        newPersonName={newPersonName}
                        setNewPersonName={setNewPersonName}
                        columnId={personPickerId}
                        imgUrl={imgUrl}
                        setImgUrl={setImgUrl}
                        imgColor={imgColor}
                        setImgColor={setImgColor}
                        handleSave={handleSave}
                    /> */}

                    {/* <div className={classes.edit_labels_button_wrap}
                        style={{
                            borderTop: boardMemberList.length ? '1px solid #ddd' : 'none',
                            marginTop: boardMemberList.length ? '5px' : '0',
                            paddingTop: boardMemberList.length ? '6px' : '0',
                        }}

                    > */}
                    <Box
                        className={classes.edit_labels_button_wrap}
                        sx={{
                            my: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderTop: '1px solid #ddd',
                            marginTop: '5px',
                            paddingTop: '6px',
                        }}>
                        <BTButton
                            data-testid='tasks-header-new-group-button'
                            sx={{ minWidth: "150px", span: { margin: "0" } }}
                            onClick={handleSave}
                        >
                            <FaPlus style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
                            <p style={{
                                margin: '0px 5px',
                                fontFamily: "'Inter', sans-serif",
                                fontWeight: "600",
                                fontSize: 14,
                            }}>
                                {t('workloadSettingsDrawer.newMockUserButton')}
                            </p>
                        </BTButton>
                    </Box>
                    {/* </div> */}
                </Box >
            </Box >

        )
    }


    return (
        <Stack direction='column' className={classes.settings_drawer}>
            <div className={classes.drawer_title}>
                {t('workloadSettingsDrawer.drawerTitle')}
            </div>
            {complexityPickerSelect()}
            {personPickerSelect()}
            {defaultCapacityBox()}
            {editabbleComplexityOptions()}
            {editablePeopleOptions()}
        </Stack >
    )
}