import BTTextField from "@common-components/atoms/text-field/bt-text-field";
import BTButton from "@common-components/bt-button/bt-button";
import SchedulePicker from "@common-components/schedule-picker/schedule-picker";
import { Delete } from "@mui/icons-material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  Tab, Tabs,
  Typography,
} from "@mui/material";
import { DBWebhooksThunks, webhooksSelectByQuery } from "@common-reducers/DBServiceThunks";
import { selectIsDarkMode } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdOutlineWebhook } from "react-icons/md";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark, docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import info from "@common/assets/icons/info.png";
import { appConfig } from "@common/config/app.config";
import { Schedule } from "@common/types/interface";
interface AdminApiPlaygroundProps {
  apiKey: string;
}
const AdminApiPlayground = ({ apiKey }: AdminApiPlaygroundProps) => {
  const dispatch = useAppDispatch()
  const webhookList = useAppSelector((state) => webhooksSelectByQuery(state.DBWebhooksReducer, {
    deleted: false,
  }))
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const [message, setMessage] = useState("");
  const [targetNumber, setTargetNumber] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [attachmentMimeType, setAttachmentMimeType] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [schedule, setSchedule] = useState<Schedule>({});
  const formattedRequest = useMemo(() => {
    let optionalFormatted = "";
    if (attachmentUrl) {
      optionalFormatted += `
            asset: {
                url: "${attachmentUrl}",
                type: "${attachmentMimeType}",
            },`;
    }
    if (dueDate) {
      optionalFormatted += `
            dueDate: "${dueDate}",`;
    }
    if (callbackUrl) {
      optionalFormatted += `
            callbackUrl: "${callbackUrl}",`;
    }
    const formatted = `fetch("${appConfig.API_ENDPOINT}/messages", 
    {
        method: "POST"
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            apiKey: "${apiKey}", 
            to: "${targetNumber}", 
            message: "${message}", ${optionalFormatted}
        }),
    }
)`;
    return formatted;
  }, [
    apiKey,
    attachmentMimeType,
    attachmentUrl,
    callbackUrl,
    dueDate,
    message,
    targetNumber,
  ]);
  const sendMessage = async () => {
    console.log("sending message");
    setIsLoading(true);
    const sendRes = await axios.post(`${appConfig.API_ENDPOINT}/messages`, {
      apiKey,
      to: targetNumber,
      message,
      asset: attachmentUrl
        ? {
          url: attachmentUrl,
          type: attachmentMimeType,
        }
        : undefined,
      callbackUrl: callbackUrl ? callbackUrl : undefined,
      dueDate: dueDate ? dueDate : undefined,
    });
    if (sendRes.data?.status === true) {
      setSnackbarSuccessMessage(
        sendRes.data.message || "Successfully scheduled"
      );
    }
    setIsLoading(false);
    console.log("sending message result", sendRes.data);
  };
  const deleteHook = (hook) => {
    dispatch(DBWebhooksThunks.patch({
      entity: {
        ...hook,
        deleted: true,
      }
    }))
  }
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const getMimeType = async (url: string) => {
    if (!url) {
      return null;
    }
    try {
      // Fetch the URL using axios
      const response = await axios.head(url);
      // Get the "Content-Type" header from the response
      const contentTypeHeader = response.headers["content-type"];
      // Use the mimeTypes library to get the MIME type from the header
      const mimeType = getMimeType(contentTypeHeader);
      console.log("mimeType", mimeType);
      return mimeType || null;
    } catch (error) {
      console.error("Error fetching URL:", error);
      return null;
    }
  };
  const setAttachmentMimeOnBlur = async (url: string) => {
    const mimeType = await getMimeType(url);
    setAttachmentMimeType(mimeType || "");
  };
  const getDueTime = useCallback((scheduleDueDate?: string): moment.Moment => {
    const dueTime: moment.Moment = moment(scheduleDueDate);
    const { hours, minutes } = moment(scheduleDueDate)
      .startOf("minute")
      .toObject();
    dueTime.hours(hours);
    dueTime.minutes(minutes);
    return dueTime;
  }, []);
  const isScheduleValid = (scheduleDueDate?: string) => {
    return (
      moment(scheduleDueDate).isValid() &&
      getDueTime(scheduleDueDate).isAfter(moment().subtract(1, "minute"))
    );
  };
  const handleScheduleChange = (schedule: Schedule) => {
    if (!isScheduleValid(schedule?.dueDate)) {
      setSchedule({
        ...schedule,
        dueDate: getDueTime().toISOString(),
      });
      return;
    }
    setSchedule(schedule);
  };
  const showScheduleDialog = () => {
    setScheduleDialogOpen(true);
  };
  const closeScheduleDialog = () => {
    if (schedule) {
      const dueDate = getDueTime(schedule?.dueDate).toDate().toISOString();
      setSchedule({
        ...schedule,
        dueDate,
      });
      setDueDate(dueDate);
    }
    setScheduleDialogOpen(false);
  };
  const copyHandle = async () => {
    await navigator?.clipboard?.writeText(formattedRequest);
    setSnackbarSuccessMessage("Successfully copied to clipboard");
  };
  useEffect(() => {
    dispatch(DBWebhooksThunks.find({ deleted: false }))
  }, []);
  const addWebhook = async () => {
    dispatch(DBWebhooksThunks.create(
      {
        url: webhookUrl,
      }
    ))
    setWebhookUrl("")
  }
  return (
    <>
      <Paper sx={{ px: 3, pb: 3 }}>
        <Stack spacing={2}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="admin tabs">
            <Tab label="Api" />
            <Tab label="Webhooks" />
          </Tabs>
          {activeTab === 0 && (
            <Stack spacing={2}>
              <Stack spacing={1} my={1}>
                <Typography variant="h5">
                  Test the api, and start using it to schedule messages.
                </Typography>
              </Stack>
              <BTTextField
                disabled
                label="Your API token - REQUIRED"
                value={apiKey ?? ""}
                variant="outlined"
              />
              <BTTextField
                label="Insert full phone number (with country prefix) - REQUIRED"
                value={targetNumber}
                onChange={(event) => setTargetNumber(event.target.value)}
                variant="outlined"
                inputProps={{
                  maxLength: 30,
                }}
              />
              <Divider textAlign="left" sx={{ my: 3 }}>
               Type a message, add an attachment, or do both
              </Divider>
              <BTTextField
                id="outlined-multiline-static"
                label="Type a message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                multiline
                rows={4}
                variant="outlined"
                inputProps={{
                  dir: "auto",
                }}
              />
              <BTTextField
                label="Insert file URL (optional)"
                value={attachmentUrl}
                onChange={(event) => {
                  setAttachmentUrl(event.target.value);
                }}
                onBlur={(event) => {
                  setAttachmentMimeOnBlur(event.target.value);
                }}
                variant="outlined"
              />
              <BTTextField
                label="Insert file MIME type (optional)"
                value={attachmentMimeType}
                onChange={(event) => setAttachmentMimeType(event.target.value)}
                variant="outlined"
                helperText={
                  <Link
                    component="a"
                    target="_blank"
                    href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types"
                  >
                    List of mime types
                  </Link>
                }
              />
              <Divider textAlign="left" sx={{ my: 3 }}>
                Optional
              </Divider>
              <BTTextField
                label="Due date - ISO string (optional)"
                value={dueDate}
                onChange={(event) => setDueDate(event.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ color: "var(--text-color)" }}
                      aria-label="schedule"
                      onClick={showScheduleDialog}
                    >
                      <ScheduleIcon />
                    </IconButton>
                  ),
                }}
              />
              <BTTextField
                label="Callback URL (optional)"
                value={callbackUrl}
                onChange={(event) => setCallbackUrl(event.target.value)}
                variant="outlined"
              />
              <BTButton
                sx={{ p: 2, width: "250px" }}
                loading={isLoading}
                disabled={
                  isLoading || !(targetNumber && (message || attachmentUrl))
                }
                onClick={sendMessage}
              >
                Send Message
              </BTButton>
              <SyntaxHighlighter
                language="javascript"
                style={isDarkMode ? dark : docco}
              >
                {formattedRequest}
              </SyntaxHighlighter>
              <Button
                sx={{ p: 1, width: "250px" }}
                variant="outlined"
                onClick={copyHandle}
              >
                Copy to clipboard
              </Button>
            </Stack>
          )}
          {activeTab === 1 && (
            <Stack spacing={2}>
              <Typography variant="h5">
               Automate Your Chats with webhooks.
              </Typography>
              <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                padding: "8px 16px",
                borderRadius: "8px",
                background: "var(--link-preview-background)",
                marginTop: "30px",
              }}
            >
              <img width={16} height={16} src={info} alt="" />
              <Stack direction="row" spacing={0.5}>
                <Typography
                  sx={{
                    color: "var(--primary)",
                    fontSize: "12px",
                    lineHeight: "18px",
                  }}
                >
                  Make Tutorial can be found here
                </Typography>
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
               
                  href={`${appConfig.PRICING_PAGE}/webhook-make-guide`}
                >
                  <Typography
                    sx={{
                      color: "var(--link-text-color)",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    here
                  </Typography>
                </a>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                padding: "8px 16px",
                borderRadius: "8px",
                background: "var(--link-preview-background)",
                marginTop: "30px",
              }}
            >
              <img width={16} height={16} src={info} alt="" />
              <Stack direction="row" spacing={0.5}>
                <Typography
                  sx={{
                    color: "var(--primary)",
                    fontSize: "12px",
                    lineHeight: "18px",
                  }}
                >
                  Zapier Tutorial can be found here
                </Typography>
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                  href={`${appConfig.PRICING_PAGE}/webhook-zapier-guide`}
                >
                  <Typography
                    sx={{
                      color: "var(--link-text-color)",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    here
                  </Typography>
                </a>
              </Stack>
            </Stack>
              {/* <Typography variant="h5">Manage Webhooks</Typography> */}
              <Stack direction="row" alignItems={"center"} spacing={4} >
              <BTTextField
                label="Insert webhook address"
                value={webhookUrl}
                onChange={(event) => setWebhookUrl(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    addWebhook();
                  }
                }}
                variant="outlined"
              
                style={{
                  width: "100%",
                }}
              />
              <BTButton
                onClick={addWebhook}
                style={{marginLeft:"10px"}}
      
              >
                Add Webhook
              </BTButton>
              </Stack>
              <List>
                {webhookList.map((webhook: any) => (
                  <ListItem button key={webhook.url}>
                    <ListItemAvatar>
                      <Avatar>
                        <MdOutlineWebhook />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={webhook.url} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteHook(webhook)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Stack>
          )}
        </Stack>
      </Paper>
      <Snackbar
        open={!!snackbarSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarSuccessMessage("")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setSnackbarSuccessMessage("")}
          severity="success"
          variant="filled"
          sx={{
            "& .MuiButtonBase-root": {
              color: "#fff;",
            },
          }}
        >
          {snackbarSuccessMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={scheduleDialogOpen}
        style={{ direction: "ltr" }}
        onClose={closeScheduleDialog}
      >
        <DialogTitle>Due date</DialogTitle>
        <DialogContent>
          <SchedulePicker
            showRecurrence={false}
            showCancelIfReceived={false}
            schedule={schedule ?? {}}
            handleScheduleChange={handleScheduleChange}
          ></SchedulePicker>
        </DialogContent>
        <DialogActions>
          <div>
            <div style={{ flex: "auto" }}></div>
            <Button
              disabled={
                !schedule?.dueDate || !isScheduleValid(schedule?.dueDate)
              }
              variant="contained"
              color="primary"
              onClick={closeScheduleDialog}
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AdminApiPlayground;

