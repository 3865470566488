import classes from "./drop_down_cell.module.scss";
import { CellProps } from "../cell-interface";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { AvatarGroup, Box, Skeleton, Stack } from "@mui/material";
import OverflowTooltip from "../text-cell/overflow-tooltip";
import { isTempId } from "@common-reducers/DBServiceThunks";
import { selectDropdownOptionsAndPickedValues } from "@common-reducers/TaskSelectors";


export default function DropDownCell({
  taskId, columnId, boardId, mockValue,openPicker
}: CellProps) {
  const pickerAnchorId = `dropdown_${taskId}_${columnId}`;
  function handleClick() {
    openPicker({
      anchorId: pickerAnchorId,
      pickerType: 'drop-down',
    })
  }

  const { pickedOptions } = useAppSelector((state) => selectDropdownOptionsAndPickedValues(state, { taskId: taskId, columnId: columnId, boardId: boardId }))
  const dropDownValues = pickedOptions.length > 0 ? pickedOptions : mockValue;

  const extraFilesTheme = {
    alignItems: "center",
    width:"100%",
    ".MuiAvatar-circular": {
      height: '20px',
      width: '20px',
      background: 'var(--primary)',
      color: 'var(--background-default)',
      fontSize: '10px',
      marginLeft: "8px",
      marginBottom: '-2px',
      border:"none",
    }
  }
  return (

    isTempId(columnId) ? 
     ( <Skeleton  variant="rectangular" />)
     :
    <Stack className={classes.labelsContainer} onClick={handleClick} id={pickerAnchorId}>
      <AvatarGroup sx={{...extraFilesTheme,width: dropDownValues?.length < 3 ? '100%' : 'auto' }} max={2}>
      {dropDownValues && dropDownValues.map((option, i) => {
          return (
            <OverflowTooltip key={i} value={option.label}>
              <Box className={classes.label} >
                {option?.label ?? ''}
              </Box>
            </OverflowTooltip>
          );
        })}
      </AvatarGroup>
    </Stack >
  );
}
