import BTButton from "@common-components/bt-button/bt-button";
import { UserInterface } from "@common-models/user";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DBUserThunks } from "@common-reducers/DBServiceThunks";
import { modalActions } from "@common-reducers/ModalReducer";
import { selectUserObject } from "@common-reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";





export const DeleteAccountModal = () => {
    const dispatch = useAppDispatch();
    // get current user id
    const user: UserInterface = useAppSelector(selectUserObject);
    const handleCloseDeleteDialog = () => {
        dispatch(modalActions.setComponentToRender(''));
    }


    const deleteUser = () => {
        dispatch(DBUserThunks.delete({
            entity: {
                _id: user._id,
            }
        }))

    }



    return (
        <>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Box>Are you certain you want to <b>delete</b> your account?</Box>
                    <Box sx={{ color: "red" }}>This action is irreversible.</Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions >

                <Button color="secondary" sx={{ textTransform: "capitalize", mr: 2 }} onClick={deleteUser}>Delete</Button>
                <BTButton onClick={handleCloseDeleteDialog} variant="contained">Cancel</BTButton>
            </DialogActions>
        </>

    )

}




