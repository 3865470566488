import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import RowStack from '@common-components/common/row-stack/RowStack';
import { RemoveCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { setCurrentBot, useStore } from '@common/reducers/ChatbotReducer';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk, removeHandleAndConnectedEdgesByHandleIdArrayThunk } from '@common-reducers/ChatbotThunks';
import { getChatbotTempId } from '@common-services/utils';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import classes from "./question-node.module.scss";

type Props = {
    onClose: () => void
    id?: string;
}

export default function AddQuestionDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()

    const { setNodes } = useStore();

    const data = useAppSelector(state => getNodeDataByNodeId(state,id))
    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)

    const [newAnswer, setNewAnswer] = useState('')
    const [localNodeData, setLocalNodeData] = useState(data)
    const [localEntitiesArrayToDelete, setLocalEntitiesArrayToDelete] = useState([])
    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)



    const messageEditorDialog = () => {
        return (
            <>
                <div className={classes.add_question_title}>Question message text</div>
                <BotMessageEditor
                    _message={{
                        message: localNodeData?.question,
                        asset: localNodeData?.questionAsset
                    }}
                    onChange={(questionMessage) => onChange(questionMessage, 'question')} />
            </>
        )
    }

    const onChange = (value, type: string) => { // type = 'question' | handleId
        const dataToPatch = () => {
            if (type === 'question') {
                return { question: value.message, questionAsset: value.asset }
            } else {
                return {
                    answers: {
                        ...localNodeData.answers,
                        [type]: value
                    }
                }
            }
        }
        setLocalNodeData({
            ...localNodeData,
            ...dataToPatch()
        })
    }

    const handleClickRemoveAnswer = (handleId) => {
        /// remove output handle from the data object
        const newOutputs = localNodeData.handles.outputs.filter(handle => handle.id !== handleId)
        const { [handleId]: _, ...newAnswers } = localNodeData.answers
        setLocalEntitiesArrayToDelete([...localEntitiesArrayToDelete, handleId])
        setLocalNodeData({
            ...localNodeData,
            handles: {
                ...localNodeData.handles,
                outputs: newOutputs
            },
            answers: newAnswers

        })
    }

    const answers = () => {
        const handleIds = Object.keys(localNodeData?.answers ?? {})
        if (handleIds?.length > 0) {
            return (
                <>
                    <div className={classes.add_question_title}>Answer options</div>
                    {handleIds.map((handleId, i) => {
                        const answer = localNodeData?.answers[handleId]
                        return (
                            <RowStack key={i}>
                                <BotInputField
                                    value={answer}
                                    onChange={(newValue) => {
                                        onChange(newValue, handleId)
                                    }}
                                    placeholder={'Type a new answer'}
                                    sx={{ marginBottom: '8px', }}
                                    endAdornment={
                                        <IconButton sx={{
                                            zIndex: 2,
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '8px',
                                            backgroundColor: '#FEE7E7',
                                            color: 'red',
                                            ".MuiSvgIcon-root": {
                                                width: '20px',
                                                height: '20px',
                                            }
                                        }} onClick={() => handleClickRemoveAnswer(handleId)}><RemoveCircle /></IconButton>
                                    }
                                />
                            </RowStack >
                        )
                    })}
                </>
            )
        }
    }

    const handleClickAddNewAnswer = () => {
        const newHandleId = getChatbotTempId()
        setLocalNodeData({
            ...localNodeData,
            handles: {
                ...localNodeData.handles,
                outputs: [...localNodeData.handles.outputs, { id: newHandleId, type: 'source' }]
            },
            answers: {
                ...localNodeData.answers,
                [newHandleId]: newAnswer
            }
        })
        setNewAnswer('')
    }

    const addAnswer = () => {
        return (
            <>
                <div className={classes.add_question_title}>Add an answer</div>
                <RowStack>
                    <BotInputField
                        value={newAnswer}
                        onChange={(newValue) => setNewAnswer(newValue)}
                        placeholder={'Type a new answer'}
                        endAdornment={
                            <BTbotbutton
                                sx={{ zIndex: 2, }}
                                size='small'
                                disabled={newAnswer === ''}
                                onClick={() => handleClickAddNewAnswer()}>Create</BTbotbutton>
                        }
                    />
                </RowStack>
            </>
        )
    }

    const onChangeVariableName = (value) => {
        setLocalCurrentBot({
            ...localCurrentBot,
            botSettings: {
                ...localCurrentBot?.botSettings,
                variables: {
                    ...localCurrentBot?.botSettings?.variables,
                    [id]: value
                }
            }

        })
    }

    const addVariable = () => {
        return (
            <Stack>
                <div className={classes.add_question_title}>Save answers in a variable</div>
                <BotInputField
                    value={localCurrentBot?.botSettings?.variables?.[id] ?? ''}
                    onChange={(newValue) => onChangeVariableName(newValue)}
                    placeholder={'Type a new variable'} />
            </Stack>
        )
    }

    const saveNewQuestionData = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: localNodeData
        }))

        dispatch(removeHandleAndConnectedEdgesByHandleIdArrayThunk(localEntitiesArrayToDelete))

        dispatch(setCurrentBot(localCurrentBot))

        onClose()
    }

    return (
        <div className={classes.add_question_message_editor_wrapper}>
            {/*message editor*/}
            {messageEditorDialog()}
            <div className={classes.divider} />
            {/* answers */}
            {answers()}
            {/*add answers*/}
            {addAnswer()}
            {/*add variable*/}
            <div className={classes.divider} />
            {addVariable()}
            {/*save and cancel button*/}
            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton onClick={saveNewQuestionData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
            </RowStack>
        </div>
    )
}