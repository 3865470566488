import ContactRow from '@extension/context/content/components/add-scheduled-message-button/contact-row';
import WaContactLazyLoadingVirtuoso from '@extension/context/content/components/add-scheduled-message-button/wa-contact-lazy-loading-virtuoso';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import RowStack from '@common-components/common/row-stack/RowStack';
import { WhatsappIdCell } from '@common-components/tasks/columns-cells/whatsapp-id-cell/whatsapp-id-cell';
import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import { Cancel } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { DBWaContactThunks, waContactSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { useStore } from '@common/reducers/ChatbotReducer';


type Props = {
    id: string;
}

export default function SendMessageNodeContainer({ id }: Props) {
    const dispatch = useAppDispatch()

    const [searchTerm, setSearchTerm] = useState('');
    const [isContactPickerOpen, setIsContactPickerOpen] = useState(false);

    const data = useAppSelector(state => getNodeDataByNodeId(state,id))

    const { setNodes } = useStore();


    const allowToAdd = /^[0-9@+]+$/.test(searchTerm);



    const onChange = async (value) => {
        await dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: { text: value.message, asset: value.asset }
        }))
    }

    const setPhoneNumberAsContact = (phoneNumber) => {
        if (parseInt(phoneNumber)) {
            const contact = { id: { _serialized: `${phoneNumber}@c.us` } }
            setNewContactValue(contact)
        }
        return
    }

    const setNewContactValue = async (contact) => {
        setIsContactPickerOpen(false)
        await dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: { contactId: contact?.id?._serialized }
        }))
        setSearchTerm('')
    }

    return (
        <>
            {data?.type === 'messageContact' &&
                <Stack sx={{
                    width: '100%',
                    marginBottom: '10px',
                    justifyContent: 'center',
                }}>
                    <RowStack sx={{ height: '40px', }}>
                        <WhatsappIdCell
                            cellValue={data?.contactId}
                            taskId={id.substring(0, 24)}
                            columnId={id.substring(0, 24)}
                            openPicker={(a: any) => setIsContactPickerOpen(true)}
                            onUpdate={(value) => { }}
                            togglePicker={(options) => { }}
                            closePicker={() => { }}
                        />
                        {data?.contactId && data?.contactId !== '' &&
                            < IconButton onClick={() => setNewContactValue('')}><Cancel /></IconButton>
                        }
                    </RowStack>
                    {isContactPickerOpen &&
                        <>
                            <Stack direction="row" justifyContent={'center'}>
                                <SearchBox
                                    variant="outlined"
                                    // sx={{
                                    //     width: 225
                                    // }}
                                    autoComplete='off'
                                    value={searchTerm}
                                    onChange={(e) => {
                                        if (!e.target.value?.includes('+')) {
                                            setSearchTerm(e.target.value)
                                        }
                                    }}
                                    placeholder="Enter name or number"
                                    InputProps={{
                                        endAdornment: allowToAdd ? (
                                            <IconButton onClick={() => {
                                                setPhoneNumberAsContact(searchTerm);
                                            }} >
                                                <AddIcon sx={{ fontSize: 16 }} />
                                            </IconButton>
                                        ) : <SearchIcon sx={{ fontSize: 16 }} />,
                                    }}
                                    fullWidth
                                />
                            </Stack>
                            {(searchTerm && searchTerm !== '') && (
                                <div className="nowheel"
                                    style={{
                                        zIndex: 1000,
                                        backgroundColor: 'var(--default-background)',
                                    }}
                                >
                                    <WaContactLazyLoadingVirtuoso
                                        onClick={setNewContactValue}
                                        height={175}
                                        rowHeightString='44px'
                                        DBThunks={DBWaContactThunks}
                                        DBReducer={state => state.DBWaContactReducer}
                                        selectByQuery={waContactSelectByQuery}
                                        Component={ContactRow}
                                        minWidth={'330px'}
                                        rowWidth={'310px'}
                                        isWaContact={true}
                                        searchFilter={searchTerm}
                                    />
                                </div>
                            )}
                        </>
                    }

                </Stack >
            }
            <BotMessageEditor
                _message={{ message: data?.text, asset: data?.asset }}
                onChange={onChange} />
        </>
    )
}