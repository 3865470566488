export const orangeStyle = {
  color: "var(--snippet-orange-btn)",
  borderColor: "var(--snippet-orange-btn)",
};
export const textFieldSX = {
  "&.MuiTextField-root": {
    ".MuiInputLabel-root": {
      opacity: 1,
      color: "var(--snippet-label-color)",
      "&.Mui-focused": {
        color: "var(--snippet-label-color)",
      },
    },
    "& .MuiInputBase-input": {
      color: "var(--snippet-input-color)",
    },
  },
};