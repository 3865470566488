import { ContactInfo, contactMatches } from "@common-models/contact-info";
import { isExtensionContext } from "@common-services/detect-context";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { Chat, WhatsappContact } from "@wa-communication-layer/whatsapp";
import { CommonRootState } from "@common/types/common-root-state-type";
import { contactSelectByQuery, waContactSelectByQuery } from "@common-reducers/DBServiceThunks";

export type ThemeType = "light" | "dark";


export interface UserInfo {
  email: string;
  id: string;
}
export interface WhatsAppState {
  currentChat: Chat | null;
  stashedChat: Chat | null;
  contactList: WhatsappContact[];
  contactMap: { [key: string]: WhatsappContact };
  profilePicMap: Record<string, string>;
  chatList: Chat[];
  chatMap: { [key: string]: Chat };
  theme: ThemeType;
  errorState: boolean;
  completeDetailsClosed: boolean;
  userInfo?: UserInfo;
  invalidWANumber?: string;
  me?: WhatsappContact;
  wapiInitialized: boolean;
  assetMap: Record<string, File>,
  currentExtensionVersion: string,
  importedContactsArray: ContactInfo[];
  showWizard?: boolean;
  hoveredDate?: any;
  isReply?: any;
  mentions?: { id: string, display: string }[]
  // waContactsMap?: any;
  // waContactsArray?: any;
  redisContactsUpdateTimestamp?: string;
  addMsgInCalendarContact?: any;
}

const initialState: WhatsAppState = {
  theme: "light",
  currentChat: null,
  stashedChat: null,
  contactList: [],
  contactMap: {},
  profilePicMap: {},
  chatMap: {},
  chatList: [],
  errorState: false,
  completeDetailsClosed: false,
  wapiInitialized: false,
  assetMap: {},
  currentExtensionVersion: "",
  importedContactsArray: [],
  showWizard: false,
  hoveredDate: undefined,
  isReply: null,
  mentions: null,
  // waContactsArray: [],
  // waContactsMap: {},
  redisContactsUpdateTimestamp: undefined,
  addMsgInCalendarContact: null,
};

const WhatsAppSlice = createSlice({
  name: "whatsApp",
  initialState,
  reducers: {
    setAsset: (state, action: PayloadAction<{ url: string, file: File }>) => {
      state.assetMap[action.payload.url] = action.payload.file;
    },

    privateSetCurrentChat(state, action: PayloadAction<Chat>) {
      console.log("privateSetCurrentChat");
      state.currentChat = action.payload;
    },
    setWAcontacts(state, action: PayloadAction<WhatsappContact[]>) {
      console.log("setWAcontacts");
      state.contactMap = action.payload.reduce((contactMap, contact) => {
        const contactId = contact?.id?._serialized ?? contact?._id;
        if (contactId) {
          contactMap[contactId] = contact;
        }
        return contactMap;
      }, {} as Record<string, WhatsappContact>);
      state.contactList = Object.keys(state.contactMap).map(
        (contactId) => state.contactMap[contactId]) ?? [];
    },
    setProfilePic(state, action: PayloadAction<{ contactId: string, profilePic: string }>) {
      state.profilePicMap[action.payload.contactId] = action.payload.profilePic;
    },
    setWAchats(state, action: PayloadAction<Chat[]>) {
      console.log("setWAchats");
      state.chatMap = action.payload.reduce((chatMap, chat) => {
        const chatId = chat?.id?._serialized;
        if (chatId) {
          chatMap[chatId] = chat;
        }
        return chatMap;
      }, {} as Record<string, Chat>);
      state.chatList = Object.keys(state.chatMap).map(
        (chatId) => state.chatMap[chatId]
      ) ?? [];
    },
    setTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload;
    },
    setErrorState(state, action: PayloadAction<boolean>) {
      state.errorState = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setInvalidWANumber: (state, action: PayloadAction<string>) => {
      state.invalidWANumber = action.payload;
    },
    setMe: (state, action: PayloadAction<WhatsappContact>) => {
      state.me = action.payload;
    },
    setWapiInitialized: (state, action: PayloadAction<boolean>) => {
      state.wapiInitialized = action.payload;
    },
    setCurrentExtensionVersion(state, action: PayloadAction<string>) {
      state.currentExtensionVersion = action.payload
    },
    setImportedContactsArray(state, action: PayloadAction<any[]>) {
      state.importedContactsArray = action.payload
    },
    setShowWizard(state, action: PayloadAction<boolean>) {
      state.showWizard = action.payload
    },
    setHoveredDate: (state, action: PayloadAction<any>) => {
      state.hoveredDate = action.payload;
    },
    setAddMsgInCalendarContact: (state, action: PayloadAction<any>) => {
      state.addMsgInCalendarContact = action.payload;
    },
    setIsReply: (state, action: PayloadAction<any>) => {
      state.isReply = action.payload;
    },
    setMentions: (state, action: PayloadAction<any>) => {
      state.mentions = action.payload;
    },
    setStashedChat: (state, action: PayloadAction<any>) => {
      state.stashedChat = action.payload;
    },
    // setRedisContactsMapAndArray: (state, action: PayloadAction<any>) => {
    //   state.waContactsArray = action.payload;
    //   const newContactsMap = {};
    //   action.payload.forEach(obj => {
    //     const { lid, ...rest } = obj;
    //     if (lid) {
    //       newContactsMap[lid] = rest;
    //     }
    //   });

    //   state.waContactsMap = newContactsMap;
    // },
  },
});

export const selectMergedContactMap = createSelector([
  (state) => state.WhatsAppReducer.contactList,
  (state) => state.WhatsAppReducer.chatList,
  (state): ContactInfo[] => contactSelectByQuery(state.DBContactReducer, {
    $or: [
      { deleted: { $exists: false } },
      { deleted: false }
    ],
  })
],
  (contactList: WhatsappContact[], chatList, importedContactList: ContactInfo[]): Record<string, ContactInfo> => {
    let _mergedContactMap: Record<string, ContactInfo> = {};
    for (const waContact of contactList) {
      if (waContact.id?._serialized
        && !waContact.id?._serialized.includes('@lid')
      ) {
        _mergedContactMap[waContact.id._serialized] = {
          ...waContact,
          whatsappId: waContact.id._serialized
        };
      }
    }
    for (const contact of importedContactList) {
      if (contact.whatsappId) {
        _mergedContactMap[contact.whatsappId] = {
          ...(_mergedContactMap[contact.whatsappId] ?? {}),
          ...contact
        };
      }
    }
    return _mergedContactMap
  }
);

export const selectMergedContactMapWithNamesOnly = createSelector([
  (state) => state
], (state) => {
  const mergedContactMap = selectMergedContactMap(state);
  // create from mergedContactMap a new map of all the contacts that has the 'name' field not undefined
  // and the value is the contact itself
  const mergedContactMapWithNamesOnly = Object
    .keys(mergedContactMap)
    .filter(whatsappId => mergedContactMap[whatsappId].name && mergedContactMap[whatsappId].name !== '')
    .reduce((mergedContactMapWithNamesOnly, whatsappId) => {
      mergedContactMapWithNamesOnly[whatsappId] = mergedContactMap[whatsappId];
      return mergedContactMapWithNamesOnly;
    }, {} as Record<string, ContactInfo>);


  return mergedContactMapWithNamesOnly
});

export const selectMergedContactList = createSelector([
  (state) => selectMergedContactMap(state)
], (mergedContactMap: Record<string, ContactInfo>) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
});


export const getContactListForMentions = createSelector([
  (state) => selectMergedContactList(state),
  (state) => state
], (mergedContactList, state) => {
  const mentions = state.WhatsAppReducer.mentions;
  if (mentions) {
    return mentions
  }

  // const contactListForMentions = mergedContactList
  //   .filter(contact => ['c.us', 'g.us'].includes(contact.id._serialized.split('@')[1]))
  //   .map(contact => {
  //     return ({
  //       id: contact.id._serialized,
  //       display: contact.name ?? contact.id._serialized.split('@')[0]
  //     })
  //   })

  // return contactListForMentions
});

export const selectFilteredMergedContactList = createSelector([
  (state) => selectMergedContactMap(state),
  (state: CommonRootState, filterValue: string) => filterValue,
], (mergedContactMap: Record<string, ContactInfo>, filterValue: string) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
    .filter(contact => contactMatches(filterValue, contact));
});


export const getContactsForRedisUpdate = createSelector([
  (state) => selectMergedContactMap(state),
], (mergedContactMap: Record<string, ContactInfo>) => {
  if (isExtensionContext()) {
    return Object
      .keys(mergedContactMap)
      .map(whatsappId => mergedContactMap[whatsappId])
  }
});


export const selectedSelectedContactCount = createSelector([
  (state) => state.WhatsAppReducer.importedContactsArray,
], (importedContactsArray) => {
  return importedContactsArray.length;
});

export const selectFilteredContactList = createSelector([
  (state) => state,
  (state) => selectMergedContactList(state),
  (state) => state.ContactTableReducer.filterValue,
  (state) => state.ContactTableReducer.showSelected,
  (state) => state.CampaignReducer.contactListPanelState.selectedListId,
], (state: CommonRootState, mergedContactList: ContactInfo[], filterValue: string, showSelected: boolean, selectedListId) => {
  const importedContactsArray = state.WhatsAppReducer.importedContactsArray;
  const selectedIdList = importedContactsArray.map(contact => contact.whatsappId);
  const filteredContactList = mergedContactList
    .filter((contact: ContactInfo) => {
      return (filterValue ? contactMatches(filterValue, contact) : true) &&
        (showSelected ? selectedIdList.indexOf(contact.id?._serialized ?? contact.whatsappId ?? '') > -1 : true)
    });

  return filteredContactList
});




export const getFilterredContactsForAddScheduledMessage = createSelector([
  (state) => state,
  (state) => selectMergedContactMap(state),
  (state: CommonRootState, filterValue: string) => filterValue,
], (state, mergedContactMap: Record<string, ContactInfo>, filterValue: string) => {
  if (isExtensionContext()) {
    return Object
      .keys(mergedContactMap)
      .map(whatsappId => mergedContactMap[whatsappId])
      .filter(contact => contactMatches(filterValue, contact));
  }
  else {
    const _filteredContacts = waContactSelectByQuery(state.DBWaContactReducer, {})

    const filteredContacts = _filteredContacts
      ?.filter(contact => {
        const idSerialized = contact.id?._serialized ?? '';
        const idDomainPart = idSerialized.split('@')[1];
        return (idDomainPart && !idDomainPart.includes('lid'))
      })
      .filter(contact => {
        const idSerialized = contact.id?._serialized ?? '';
        const name = contact.name?.toLowerCase() ?? '';
        const verifiedName = (contact.verifiedName ?? '').toLowerCase();
        const pushname = contact.pushname?.toLowerCase() ?? '';
        const shortName = contact.shortName?.toLowerCase() ?? '';

        const lowerCaseFilterValue = filterValue?.toLowerCase();

        // Check if idSerialized exists and perform split
        const idLocalPart = idSerialized.split('@')[0];

        const matchesFilter = (
          idLocalPart.includes(lowerCaseFilterValue) ||
          name.includes(lowerCaseFilterValue) ||
          verifiedName.includes(lowerCaseFilterValue) ||
          pushname.includes(lowerCaseFilterValue) ||
          shortName.includes(lowerCaseFilterValue)
        );

        return matchesFilter;
      }).map(a => { return { _id: a.id._serialized } });

    return filteredContacts;
  }



});

export const getFilterredContactsLengthForAddScheduledMessage = createSelector([
  (state: CommonRootState, filterValue: string) => getFilterredContactsForAddScheduledMessage(state, filterValue),
], (filteredContacts) => {

  return filteredContacts?.length


});




export const {
  privateSetCurrentChat,
  setWAcontacts,
  setWAchats,
  setTheme,
  setErrorState,
  setUserInfo,
  setInvalidWANumber,
  setMe,
  setWapiInitialized,
  setCurrentExtensionVersion,
  setImportedContactsArray,
  setShowWizard,
  setProfilePic,
  setHoveredDate,
  setAddMsgInCalendarContact,
  setIsReply,
  setMentions,
  setStashedChat,
  // setRedisContactsMapAndArray,
} = WhatsAppSlice.actions;
export const WhatsappActions = WhatsAppSlice.actions;

export default WhatsAppSlice.reducer;
