import { Task } from "@common-models/task";
import { Box, Popover } from "@mui/material";
import { Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { taskGroupsSelectByQuery, tasksSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { dynamicColumnsListOfIdsTypesAndTitles } from "@common-reducers/TaskSelectors";
import { addNewTaskThunk } from "@common-reducers/TaskThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import { setCurrentAction } from "../automations-helper";
import ColumnRow from "../dropdowns/column-row";
import GroupDropdown from "../dropdowns/groups-dropdown";
import TaskNewRowCellWrapper from "../dropdowns/task-row-cell-wrapper";

const CreateTaskAction = ({ index, taskType }) => {
    const dispatch = useAppDispatch();
    const boardId = useAppSelector((state) => getSelectedBoardId(state));
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const { attemptedSubmission, validationStates } = useAppSelector((state) => state.AutomationReducer);
    const taskId = actions[index].taskId;

    const columnsListOfIds = useAppSelector((state) => dynamicColumnsListOfIdsTypesAndTitles(state, boardId));
    const task = useAppSelector((state) => tasksSelectOneObjectByQuery(state.DBTasksReducer, {
        _id: taskId,
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ]
    }));

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
        boardId: boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }]
    }));

    const [anchorEl, setAnchorEl] = useState(null);
    //const [taskText, setTaskText] = useState<string | undefined>();
    const [taskDuplicated, setTaskDuplicated] = useState(false);

    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const createNewTask = useCallback(async () => {
        let newTask: Task = {
            text: "",
            boardId,
            groupId: groups[0]._id,
            isTemplate: true
        };

        if (taskType) {
            newTask.taskType = "subTask"
        }
        const { payload } = await dispatch(addNewTaskThunk(newTask)) as any;
        dispatch(automation.setActions(setCurrentAction(actions, index, { taskId: payload?._id })));
    }, [actions, boardId, dispatch, groups, index, taskType]);

    const duplicateTaskTemplate = useCallback(async () => {
        const { _id, ...taskWithoutId } = task;
        const { payload } = await dispatch(addNewTaskThunk(taskWithoutId)) as any;
        dispatch(automation.setActions(setCurrentAction(actions, index, { taskId: payload?._id })));
    }, [actions, dispatch, index, task]);

    useEffect(() => {
        if (!taskId) {
            createNewTask();
        }

        if (taskId && task && !taskDuplicated) {
           // setTaskText(task.text);
            duplicateTaskTemplate();
            setTaskDuplicated(true);
        }
        dispatch(automation.fieldValidityChanged({ component: 'CreateTaskAction', index, field: 'taskText', isValid: !!task?.text }));
    }, [taskId, task, createNewTask, duplicateTaskTemplate, taskDuplicated, dispatch, index]);

    const prefix = index === 0 ? 'Then' : 'and then';

    return (
        <Stack key={index} sx={{ fontSize: "24px" }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} create an
                <AutomationButton
                    componentName='CreateTaskAction'
                    index={index}
                    option='taskText'
                    label={taskType === 'task' ? "task" : "subtask"}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                        sx: { width: "400px", backgroundColor: "#E8F9FD" }
                    }}>
                    <TaskNewRowCellWrapper taskId={taskId} text={task?.text} />
                    {taskType !== 'subtask' &&
                        <GroupDropdown boardId={boardId} taskId={taskId} groups={groups} />
                    }
                    {columnsListOfIds
                        .filter(column => column[1] !== 'created' && column[1] !== 'last-updated')
                        .map((column, index) => (
                            <ColumnRow key={index} column={column} index={index} taskId={taskId} boardId={boardId} />
                        ))
                    }
                </Popover>
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="CreateTaskAction" />
        </Stack>
    );
};

export default CreateTaskAction;
