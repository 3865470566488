import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AccessFormPopupStatus = "Closed" | "Signup" | "Login";

export interface AccessFormPopupState {
  status: AccessFormPopupStatus;
}

const initialState: AccessFormPopupState = {
  status: "Closed",
};

const AccessFormPopupSlice = createSlice({
  name: "AccessFormPopupSlice",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<AccessFormPopupStatus>) => {
      state.status = action.payload;
    },
  },
});

export const accessFormPopupActions = AccessFormPopupSlice.actions;
export default AccessFormPopupSlice.reducer;
