import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { CSSProperties } from 'react';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function TimelineColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    const cellWrapperStyle: CSSProperties = {
        pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center',
        paddingBlockStart: '3px',
        paddingInline: '8px'
    }

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );

    let status
    if (!log.oldValue && log.newValue) { status = 'add data' }
    if (log.oldValue && !log.newValue) { status = 'remove data' }
    if (log.oldValue && log.newValue) { status = 'replace data' }
    return (
        <LogStack direction='row'>
            {status === 'add data' &&
                <>
                    <span>{log.actionType}&nbsp;</span>
                    <span>to</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span>removed&nbsp;</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                </>
            }
            {status === 'replace data' &&
                <>
                    <span>changed&nbsp;</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                    <span>&nbsp;to</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                </>
            }
            <span>in&nbsp;</span>
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span style={{ fontWeight: 'bold' }}> {log.actionOnEntityData} </span>
        </LogStack>
    )
}