import MenuButton from '@common-components/menu/menu-button';
import { PickerProps } from '@common-components/tasks/columns-cells/cell-interface';
import { TasksGroup } from '@common-models/task-group';
import { getCurrentBoard } from '@common-reducers/BoardsSelectors';
import { DBKanbanThunks, DBTaskColumnThunks, DBWorkloadThunks, kanbanSelectByQuery, workloadSelectByQuery, _taskgroupsSelectors, formSelectByQuery, DBFormThunks } from "@common-reducers/DBServiceThunks";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { columnListSelector } from '@common-reducers/TaskSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';


export default function ColumnContextMenuDialog({
  columnId,
  groupId
}: PickerProps) {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()

  const tasksGroup: TasksGroup = useAppSelector((state) => {
    return _taskgroupsSelectors.selectById(state, groupId)
  });
  const columnList = useAppSelector(state => columnListSelector(state, tasksGroup.boardId));
  const column = useMemo(() => columnList.find(_col => _col._id === columnId), [columnId, columnList]);
  const currentBoard = useAppSelector(getCurrentBoard)
  const kanbansList = useAppSelector((state) => kanbanSelectByQuery(state.DBKanbanReducer, { boardId: currentBoard?._id }, ['_id', 'optionColumnId']));
  const workloadList = useAppSelector((state) => workloadSelectByQuery(state.DBWorkloadReducer, { boardId: currentBoard?._id }, ['_id', 'complexityPickerId', 'personPickerId']));
  const formsList = useAppSelector((state) => formSelectByQuery(state.DBFormReducer, { boardId: currentBoard?._id }, ['_id', 'columnData']));

  const closeDialog = () => {
    dispatch(pickerDialogActions.closePickerDialog())
  }

  const deleteColumn = () => {
    const kanbans = kanbansList.filter((kanban) => kanban?.optionColumnId === columnId)
    const workloadsWithDeletedComplexityColumn = workloadList.filter((workload) => workload?.complexityPickerId === columnId)
    const workloadsWithDeletedPersonColumn = workloadList.filter((workload) => workload?.personPickerId === columnId)
    const forms = formsList.filter((form) => (form?.columnData ?? {}).hasOwnProperty(columnId))
    if (forms.length > 0) {
      for (let i = 0; i < forms.length; i++) {
        const newColumnData = Object.keys(forms[i].columnData).reduce((object, key) => {
          if (key !== columnId) {
            object[key] = forms[i].columnData[key]
          }
          return object
        }, {})

        dispatch(
          DBFormThunks.patch({
            entity: {
              _id: forms[i]._id,
              columnData: {
                ...newColumnData,
              }
            }
          })
        )
      }
    }
    if (kanbans.length > 0) {
      for (let i = 0; i < kanbans.length; i++) {
        dispatch(
          DBKanbanThunks.patch({
            entity: {
              _id: kanbans[i]._id,
              optionColumnId: undefined
            }
          })
        )
      }
    }

    if (workloadsWithDeletedComplexityColumn.length > 0) {
      for (let i = 0; i < workloadsWithDeletedComplexityColumn.length; i++) {
        dispatch(
          DBWorkloadThunks.patch({
            entity: {
              _id: workloadsWithDeletedComplexityColumn[i]._id,
              complexityPickerId: undefined,
            }
          })
        )
      }
    }

    if (workloadsWithDeletedPersonColumn.length > 0) {
      for (let i = 0; i < workloadsWithDeletedPersonColumn.length; i++) {
        dispatch(
          DBWorkloadThunks.patch({
            entity: {
              _id: workloadsWithDeletedPersonColumn[i]._id,
              personPickerId: undefined,
            }
          })
        )
      }
    }

    dispatch(DBTaskColumnThunks.patch({
      entity:{
        _id:columnId,
        deleted:true
      }
    }))

    closeDialog();
  }

  return (
    <div style={{ padding: '10px 12px' }} data-testid={`column-three-dots-menu-delete-button-${column.type}`}>
      <MenuButton
        sx={{
          fontFamily: '"Inter", "Assistant", sans-serif',
        }}
        onClick={deleteColumn}
        icon={<BsTrash />}
      >
        <span style={{ marginLeft: 2 }}>{t('columnContextMenuDialog.delete')}</span>
      </MenuButton>
    </div>
  )
}