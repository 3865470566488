import { ExtraDataItem } from "@common-models/ExtraDataItem";
import { Box } from "@mui/system";
import { DBTasksExtraDataThunks } from "@common-reducers/DBServiceThunks";
import { canDeleteExtraDataItemSelector, extraDataActions, selectExtraDataItem, setEditModeByExtraDataId } from "@common-reducers/ExtraDataReducer";

import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";

import { Stack } from '@mui/material';
import { taskActions } from "@common-reducers/TasksReducer";
import { ReactElement } from "react";
import classes from "./extraDataMenu.module.scss";

import { AiOutlinePushpin } from "react-icons/ai";

import { BsPencil, BsTrash } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";

export interface Props {
  extraDataId: string
}

function ExtraDataMenu({ extraDataId }: Props): ReactElement {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();

  const extraDataItem: ExtraDataItem = useAppSelector((state) => selectExtraDataItem(state, extraDataId));
  const canDeleteExtraDataItem = useAppSelector((state) => canDeleteExtraDataItemSelector(state, extraDataId));
  const currentUserId = useAppSelector((state) => state.UserReducer.userId);

  function pinExtraDataToTop() {
    dispatch(DBTasksExtraDataThunks.patch({
      entity: {
        ...extraDataItem,
        isPinedToTop: !extraDataItem?.isPinedToTop,
      }
    }));
    dispatch(extraDataActions.setActiveMenuId(""))
  }

  function editExtraData() {
    dispatch(setEditModeByExtraDataId(extraDataItem._id))
    dispatch(extraDataActions.setActiveMenuId(""))
  }

  function deleteExtraData() {
    const _extraDataItem: ExtraDataItem = {
      _id: extraDataItem._id,
      deleted: true,
    };
    dispatch(DBTasksExtraDataThunks.patch({
      entity: _extraDataItem
    }));
    dispatch(extraDataActions.setActiveMenuId(""))
  }

  const copyUpdateLink = () => {
    const url = `${window.location.origin}/tasks/?boardID=${extraDataItem.boardId}&taskId=${extraDataItem.taskId}&updateId=${extraDataItem._id}`
    navigator.clipboard.writeText(url)
    dispatch(extraDataActions.setActiveMenuId(""))
    dispatch(taskActions.setTaskSnackbarMessage(t('extraDataMenu.copiedUpdateLinkToClipboard')));
  }

  return (
    //   <Stack className={classes.drop_down_comp_wrap}>

    //     <MenuItem  onClick={pinExtraDataToTop}>
    //       {extraDataItem?.isPinedToTop ? 
    //         <>
    //           <RiPaintFill />
    //           Unpin
    //         </>
    //         : 
    //         <>
    //           <AiTwotonePushpin />
    //           Pin to top
    //         </>
    //       }
    //     </MenuItem>

    //     {extraDataItem.owner === currentUserId && (
    //       <MenuItem onClick={editExtraData}>
    //         <EditIcon />
    //         Edit update
    //       </MenuItem>
    //     )}

    //     <MenuItem onClick={copyUpdateLink}>
    //     <IoIosLink />
    //       Copy update link
    //     </MenuItem>

    //     {canDeleteExtraDataItem && (
    //       <MenuItem onClick={deleteExtraData}>
    //         <DeleteIcon />
    //         Delete update
    //       </MenuItem>
    //     )}
    // </Stack>

    <Box className={classes.drop_down_comp_wrap}>
      <Stack spacing={1} className={classes.drop_down}>
        <button onClick={pinExtraDataToTop}>
        {extraDataItem.isPinedToTop ? <AiOutlinePushpin style={{ marginRight: "5px" }} /> : <AiOutlinePushpin style={{ marginRight: "5px" }} />}

          {extraDataItem?.isPinedToTop ? t('extraDataMenu.unpin') : t('extraDataMenu.pinToTop')}
        </button>
        {extraDataItem.owner === currentUserId && <button onClick={editExtraData}>
        <BsPencil style={{ marginRight: "5px" }} />

          {t('extraDataMenu.editUpdate')}
        </button>}
        <button onClick={copyUpdateLink}>
        <IoIosLink style={{ marginRight: "5px" }} />

          {t('extraDataMenu.copyLink')}
        </button>
        {canDeleteExtraDataItem && <button onClick={deleteExtraData}>

          <BsTrash style={{ marginRight: "5px" }} />
          {t('extraDataMenu.deleteUpdate')}
        </button>}
      </Stack>
    </Box>
  );
}

export default ExtraDataMenu;
