import NoResults from '@common-assets/lottie/no-results.json';
import BTButton from "@common-components/bt-button/bt-button";
import BTInputField from "@common-components/bt-input-field/bt-input-field";
import ThemeAsset from "@common-components/theme/theme-asset";
import UserControlPanel from "@common-components/user-control-panel/user-control-panel";
import WorkspaceDropDown from "@common-components/workspace-dropdown/workspace-dropdown";
import { Board } from "@common-models/board";
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Divider, Drawer, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { ClearIcon } from '@mui/x-date-pickers/icons';
import {
  boardsActions
} from "@common-reducers/BoardsReducer";
import { getCurrentBoards, getIsLoadingBoards, isBoardPanelDrawerOpen, isCurrentUserMemberOrOwner, isCurrentWorkspaceOwnerSelector } from "@common-reducers/BoardsSelectors";
import { addNewBoardThunk, setSelectedBoardId } from "@common-reducers/BoardsThunks";
import {
  DBBoardUserThunks,
  DBBoardsThunks,
  DBUserThunks,
  DBWorkspaceThunks,
  boardsSelectors
} from "@common-reducers/DBServiceThunks";
import { MODAL, modalActions } from "@common-reducers/ModalReducer";
import { taskActions } from '@common-reducers/TasksReducer';
import {  selectUserId } from "@common-reducers/UserSelectors";
import { useRouter } from "@common-reducers/hooks/use-router";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { appConfig } from "@common-config/app.config";
import { namespace } from "@common-config/constants";
import getRandomEmoji from 'get-random-emoji';
import { isEqual } from "lodash";
import Lottie from "lottie-react";
import {
  MutableRefObject,
  ReactElement,
  memo,
  useCallback, useEffect, useRef,
  useState
} from "react";
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { BiChevronsLeft, BiSearch } from "react-icons/bi";
import { FaPlus, FaRegFolderOpen } from "react-icons/fa";
import { useSelector } from "react-redux";
import BoardListItem from "./board-list-item";
import classes from "./boards-panel.module.scss";
import { Link } from 'react-router-dom';
import { selectCurrentUser } from '@common/reducers/UserSelectors';


function BoardsPanel(): ReactElement {
  const { t } = useTranslation(namespace);
  console.log("boards panel rendering")
  const dispatch = useAppDispatch()
  const router = useRouter();
  const searchInputRef: MutableRefObject<HTMLInputElement> = useRef();
  const userId = useAppSelector(selectUserId)
  const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);
  // const pendingInvitesToBoard = useAppSelector(selectPendingBoardUsers);
  const isCurrentWorkspaceOwner = useAppSelector(isCurrentWorkspaceOwnerSelector);

  const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
  const [isNewBoardLoading, setIsNewBoardLoading] = useState(false);
  const [draggedBoard, setDraggedBoard] = useState<any>({});
  const viewMode = useAppSelector(state => state.TasksReducer.viewMode);
  const isBoardsLoading = useAppSelector(getIsLoadingBoards);
  const deletedBoardName = useAppSelector(state => state.TasksReducer.deletedBoardName);

  const boards: Board[] = useSelector(getCurrentBoards, isEqual);
  const boardsFilter = useAppSelector((state) => state.BoardsReducer.boardsFilterName);
  
  const boardsList: Board[] = useSelector((state) => boardsSelectors.selectAll(state));
 



  async function addNewBoard() {
    if (isMemberOrOwner) {
      dispatch(
        modalActions.setComponentToRender(MODAL.NEW_BOARD)
      );
    } else {
      dispatch(
        modalActions.setComponentToRender(MODAL.FEATURE_NOT_AVAILABLE)
      );

    }


  }


  function filterBoards({ target }: any) {
    dispatch(boardsActions.setBoardsFilterName(target.value));
  }

  function clearSearch() {
    dispatch(boardsActions.setBoardsFilterName(''));
  }
  const user = useAppSelector(state => selectCurrentUser(state));

  const fetchPendingBoardUserInvites = async () => {
    await dispatch(DBBoardUserThunks.find({
      deleted: false,
      $paginate: false, userId: user._id
    })).unwrap();

  }
  const createFirstBoard = useCallback(async () => {
    dispatch(taskActions.setCreatingFirstBoardStatus("creating"));
    const res: any = await dispatch(addNewBoardThunk({
      boardName: "new board", emoji: getRandomEmoji(),
      template: ''
    }));
    const boardId = res.payload;
    dispatch(taskActions.setCreatingFirstBoardStatus(""));
    router.navigate(`/tasks/?boardID=${boardId}`);
  }, [dispatch, router]);


  useEffect(() => {
    if (!isBoardsLoading && boardsList.length === 0 && !deletedBoardName && isCurrentWorkspaceOwner) {
      createFirstBoard();
    }
  }, [createFirstBoard, isBoardsLoading, boards.length]);


  useEffect(() => {
    console.log("BoardsPanel: run only once");
    console.log("get boards");
    dispatch(DBBoardsThunks.find({}));
    dispatch(DBWorkspaceThunks.find({}));
    //fetchPendingBoardUserInvites()
  }, []); // Must remain empty!

  useEffect(() => {
    const selectedBoardIdByUrl = new URL((window as any).location.href).searchParams.get("boardID");

    if (selectedBoardIdByUrl) {
      dispatch(setSelectedBoardId({ boardId: selectedBoardIdByUrl }));
    }

    // else if (boards.length === 1 && viewMode === "tasks") {
    //   const [board] = boards;
    //   router.navigate(`/tasks/?boardID=${board?._id}`)
    // }
  }, [boards, dispatch, router])


  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(786))
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const toggleDrawer = () => {
    //setDrawerIsOpen(!drawerIsOpen);
    // dispatch(mainSliceActions.setDrawerToggle(!drawerIsOpen));
    dispatch(DBUserThunks.patch({
      entity: {
        _id: userId,
        isBoardsPanelOpen: !drawerIsOpen,
      }
    }));
    console.log(drawerIsOpen);
  };

  function onBeforeDragStart(initial: DragStart) {
    setDraggedBoard(boards[initial.source.index])
  }

  const navigateToMyWork = () => {
    dispatch(taskActions.setViewMode('mywork'))
    router.navigate(`/mywork`);
  }

  const navigateToHome = () => {
    dispatch(boardsActions.privateSetSelectedBoardId(''))
    dispatch(taskActions.setDeletedBoardName(''));
    dispatch(taskActions.setViewMode('tasks'))
    router.navigate(`/tasks`);
  }

  function updateBoardsOnDragEnd({ destination, source }: DropResult) {
    setDraggedBoard({})
    if (source.index === destination?.index) {
      return
    }

    const clonedBoards = [...boards];
    const [removed] = clonedBoards.splice(source.index, 1)
    clonedBoards.splice(destination?.index, 0, removed)

    const changes = clonedBoards.reverse().map((board, index) => {
      return {
        id: board._id,
        changes: {
          order: index
        }
      }
    })

    dispatch(DBBoardsThunks.patchMany(changes))
  }

  return (
    <Drawer
      sx={{
        width: isDesktop ? drawerIsOpen ? "300px" : '80px' : "80px",
        transition: '0.2s',
        [`& .MuiDrawer-paper`]: {
          background: "var(--boards-panel-background)",
          transition: '0.2s',
          borderRight: "1px solid var(--separator-line)",
          width: isDesktop ? drawerIsOpen ? "300px" : '80px' : '100%',
          // overflowX: 'hidden'
          overflow: 'visible',
        },
      }}
      variant={isDesktop ? "permanent" : 'temporary'}
      open={drawerIsOpen}
      anchor={direction === "ltr" ? 'left' : 'right'}
    >
      <div className={classes.panel_top}>
        <div className={classes.panel_header}>
          <ThemeAsset
            assetName='logo.svg'
            onClick={navigateToHome}
            style={{ width: drawerIsOpen ? 'initial' : 32 }}
            className={classes.logo}
            alt={`${appConfig.DEFAULT_TITLE}-logo`}
          />
          <span
            className={classes.toggle_drawer_icon}
            style={{
              insetInlineEnd: drawerIsOpen ? 0 : -12,
              border: drawerIsOpen ? 0 : '1px solid var(--separator-line)',
              transform: direction === "ltr" ? drawerIsOpen ? 'rotate(0deg)' : 'rotate(180deg)' : drawerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}>
            <BiChevronsLeft
              onClick={toggleDrawer}
              color="#53bdeb" size={23} />
          </span>
          {/* {drawerIsOpen ? (
            <>
              <img
              src="/img/blueticks_logo_icon_full.png" alt="Blueticks-logo" height="32px" />
              <span
                className={classes.toggle_drawer_icon}
                onClick={toggleDrawer}
              >
                <BiChevronsLeft color="#53bdeb" size={23} />
              </span>
            </>
          ) : (
            <span className={classes.toggle_drawer_icon}>
              <BiChevronsRight
                color="#53bdeb"
                size={23}
                onClick={toggleDrawer}
              />
            </span>
          )} */}
        </div>
        <Stack sx={{ width: "100%" }} mt={2} spacing={2} >


          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  display: drawerIsOpen ? "none" : "flex",
                  bgcolor: "#fff",
                  padding: "4px 18px",
                  maxWidth: "150px",
                  height: "35px",
                  justifyContent: "center",
                  border: '1px solid transparent',
                  alignItems: "center",
                  color: "rgb(0, 55, 80)",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                  boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                  "& .MuiTooltip-arrow": {
                    color: "#fff",
                  },
                },
              },
            }}
            arrow
            title={t('boardsPanel.homeTooltip')}
            placement="right-start">
            <Stack direction="row" justifyContent={drawerIsOpen ? "" : "center"} sx={{ paddingInline: "16px", }} >
              <Button onClick={navigateToHome} sx={{
                width: "100%",
                display: "flex",
                justifyContent: drawerIsOpen ? "space-between" : "center",
                alignItems: "center",
                color: "var(--primary)",
                textTransform: "capitalize",
              }}   >
                <Stack direction="row" alignItems={"center"} >

                  <HomeIcon sx={{ marginInlineEnd: drawerIsOpen ? "5px" : "" }} />
                  {drawerIsOpen ? t('boardsPanel.homeTooltip') : ""}

                </Stack>

              </Button>

            </Stack>
          </Tooltip>

          <WorkspaceDropDown />
          {(user?.role === "support" || user?.role === "admin" )  &&
            <Link style={{ paddingInline: "16px" }} to="/support" >
              Support
            </Link>
          }
 
          {/* <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  display: drawerIsOpen ? "none" : "flex",
                  bgcolor: "#fff",
                  padding: "4px 18px",
                  maxWidth: "150px",
                  height: "35px",
                  justifyContent: "center",
                  border: '1px solid transparent',
                  alignItems: "center",
                  color: "rgb(0, 55, 80)",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                  boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                  "& .MuiTooltip-arrow": {
                    color: "#fff",
                  },
                },
              },
            }}
            arrow
            title={t('boardsPanel.myWork')}
            placement="right-start">
            <Stack direction="row" justifyContent={drawerIsOpen ? "" : "center"} sx={{ paddingInline: "16px", }} >
              <Button onClick={navigateToMyWork} sx={{
                width: "100%",
                display: "flex",
                justifyContent: drawerIsOpen ? "space-between" : "center",
                alignItems: "center",
                color: "var(--primary)",
                textTransform: "capitalize",
              }}   >
                <Stack direction="row" alignItems={"center"} >
                  <WorkIcon sx={{ marginInlineEnd: drawerIsOpen ? "5px" : "" }} />
                  {drawerIsOpen ? t('boardsPanel.myWork') : ""}
                </Stack>

              </Button>

            </Stack>
          </Tooltip> */}
          <Divider sx={{ marginTop: 2 }} />


        </Stack>
        <div className={classes.new_board_button_container} data-testid="new-board-button">
          <BTButton
            id="new-board"
            loading={isNewBoardLoading}
            onClick={addNewBoard}
            sx={{
              startIcon: { margin: 0 },
              span: { margin: "0" },
              img: { margin: drawerIsOpen ? "4px" : "0" },
              padding: drawerIsOpen ? "7.5px 16px" : "8px",
              minWidth: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <FaPlus style={{ marginInlineEnd: drawerIsOpen ? '5px' : 0, height: '14px', width: '14px' }} /> {drawerIsOpen ? t('boardsPanel.newBoardButton') : ""}
          </BTButton>
          <button style={{ display: "none" }} className={classes.directoryBtn}>
            <FaRegFolderOpen />
          </button>
        </div>
        <div className={classes.search_input_container} >
          <BTInputField onClick={drawerIsOpen ? () => { } : toggleDrawer}
            className={classes.search_input}
            type="text"
            ref={searchInputRef}
            value={boardsFilter ?? ""}
            onChange={filterBoards}
            placeholder={drawerIsOpen ? t('boardsPanel.searchPlaceholder') : ""}
            startAdornment={drawerIsOpen ? <SearchIcon /> : ""}
            endAdornment={
              boardsFilter && (
                <IconButton size='small' style={{ fontSize: '12px' }} onClick={clearSearch}>
                  <ClearIcon style={{ fontSize: '14px' }} />
                </IconButton>
              )
            }

          />
          <div style={{ opacity: drawerIsOpen ? 0 : 1 }}
            className={classes.customSearchIcon}>
            <BiSearch />
          </div>

        </div>
      </div>
      {(boardsFilter === "" || boards.length > 0) ? (
        <div style={{ marginTop: 16, overflow: 'auto', flexGrow: '1' }} className={classes.scrollContainer}>

          <DragDropContext
            onDragEnd={updateBoardsOnDragEnd}
            onBeforeDragStart={onBeforeDragStart}
          >

            <Droppable
              droppableId="list"
              isCombineEnabled
              renderClone={(provided, snapshot) => (
                <BoardListItem
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  boardId={draggedBoard._id}
                  key={`boardItem${draggedBoard._id}`}
                />
              )}
            >


              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {boards.map((board, index) => (
                    <Draggable
                      index={index}
                      draggableId={board._id}
                      key={`${board._id}-${index}`}

                    >
                      {(provided, snapshot) => (
                        <Box data-testid='board-panel-item'>
                          <BoardListItem
                            provided={provided}
                            isDragging={false}
                            boardId={board._id}
                            key={`boardItem${board._id}`}
                          />
                        </Box>
                      )}

                    </Draggable>
                  )
                  )}
                </div>
              )}

            </Droppable>
          </DragDropContext>
        </div>

      )

        : <Stack px={5} sx={{ height: "100vh" }} justifyContent={"center"} alignItems={"center"}>
          <Lottie autoPlay={true} loop={true} animationData={NoResults} style={{ width: '200px', height: '200px' }} />

          <Box fontSize="16px" textAlign={"center"}>
            We've looked everywhere but couldn't find matching boards. Maybe it's hiding?
            <br /> Try a different search term or add new board!✨
          </Box>
        </Stack>

      }


      <UserControlPanel isSideMenuOpen={drawerIsOpen} />
    </Drawer>
  );
}


export default memo(BoardsPanel);