import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import RowStack from "@common-components/common/row-stack/RowStack";
import { Bot } from "@common-models/bot";
import { Alert, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { setCurrentBot } from "@common/reducers/ChatbotReducer";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { WritableDraft } from "immer/dist/internal";
import React, { useState } from 'react';
import classes from "./chatgpt-node.module.scss";

type Props = {
    onClose: () => void;
    setModels: React.Dispatch<React.SetStateAction<any[]>>;
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
    localCurrentBot: WritableDraft<Bot>;
    setLocalCurrentBot: React.Dispatch<React.SetStateAction<WritableDraft<Bot>>>;
}

export default function ConnectToOpenAI({
    onClose,
    setModels,
    localChatGPTState,
    setLocalChatGPTState,
    localCurrentBot,
    setLocalCurrentBot,
}: Props) {
    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)

    const [localConnectionState, setLocalConnectionState] = useState({
        connectionName: 'My OpenAI connection',
        apiKey: '',
        organizationId: '',
    })
    const [connectionNameError, setConnectionNameError] = useState(false)
    const [apiKeyError, setApiKeyError] = useState(false)
    const [connectionError, setConnectionError] = useState(null);
    const [fetchingData, setFetchingData] = useState(false);

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitleWithMaybeLink = (
        secondaryTitle: string,
        style?: React.CSSProperties,
        linkString?: string,
        linkURL?: string
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
                {linkString && linkURL && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {linkString}
                    </a>
                )}
            </div>
        );
    };


    const fetchModels = async () => {
        try {
            const headers: HeadersInit = {
                'Authorization': `Bearer ${localConnectionState.apiKey}`,
                'Content-Type': 'application/json'
            };

            // Conditionally add the OpenAI-Organization header if organizationId is not empty
            if (localConnectionState.organizationId) {
                headers['OpenAI-Organization'] = localConnectionState.organizationId;
            }

            const response = await fetch('https://api.openai.com/v1/models', {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                const errorData = await response.json();
                setConnectionError({
                    message: errorData.error.message,
                    code: response.status
                });
            }

            const data = await response.json();
            setModels(data.data);

            return true
        } catch (err) {

        }
    }


    const saveConnectionData = async () => {
        setFetchingData(true)
        const isConnected = await fetchModels()
        setFetchingData(false)

        if (isConnected) {
            setLocalCurrentBot({
                ...localCurrentBot,
                botSettings: {
                    ...localCurrentBot?.botSettings,
                    api: {
                        ...localCurrentBot?.botSettings?.api,
                        chatGPT: [...(localCurrentBot?.botSettings?.api?.chatGPT ?? []), localConnectionState]
                    }
                }
            })

            setLocalChatGPTState({
                ...localChatGPTState,
                connection: localConnectionState
            })

            if (currentBot?.botSettings?.api?.chatGPT?.length > 0) {
                onClose()
            }
        }

    }


    const isSaveConnectionDisabled = () => {
        return localConnectionState.connectionName.length === 0 || localConnectionState.apiKey.length === 0
    }

    return (
        <Stack className={classes.divider}>
            {connectionError &&
                <Alert sx={{
                    marginBottom: '20px',
                }}
                    severity="error">{`${connectionError?.code}: ${connectionError?.message}`}</Alert>
            }


            {title('Connection Name', { color: connectionNameError && 'red' })}
            <BotInputField
                value={localConnectionState.connectionName}
                onChange={(newValue) => setLocalConnectionState({ ...localConnectionState, connectionName: newValue })}
                onBlur={() => {
                    if (localConnectionState.connectionName.length === 0) {
                        setConnectionNameError(true)
                    } else {
                        setConnectionNameError(false)
                    }
                }}
            />
            {connectionNameError &&
                secondaryTitleWithMaybeLink('Connection name is required', { color: 'red' })
            }

            {title('API key', { color: apiKeyError && 'red', marginTop: '20px', })}
            <BotInputField
                value={localConnectionState.apiKey}
                onChange={(newValue) => setLocalConnectionState({ ...localConnectionState, apiKey: newValue })}
                onBlur={() => {
                    if (localConnectionState.apiKey.length === 0) {
                        setApiKeyError(true)
                    } else {
                        setApiKeyError(false)
                    }
                }}
            />
            {apiKeyError &&
                secondaryTitleWithMaybeLink('API key is required', { color: 'red' })
            }

            {secondaryTitleWithMaybeLink('Create an API key at ', {}, 'OpenAI Account API Keys', 'https://platform.openai.com/api-keys')}

            {title('Organization ID', { marginTop: '20px', })}
            <BotInputField
                value={localConnectionState.organizationId}
                onChange={(newValue) => setLocalConnectionState({ ...localConnectionState, organizationId: newValue })}
            />

            {secondaryTitleWithMaybeLink('Get your organization ID at ', {}, 'OpenAI Organization Settings', 'https://platform.openai.com/settings/organization/general')}


            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton disabled={isSaveConnectionDisabled() || fetchingData} onClick={saveConnectionData} sx={{ marginLeft: "13px" }}>
                    {!fetchingData && 'Save connection'}
                    {fetchingData && <CircularProgress sx={{ width: '20px !important', height: '20px !important', }} />}
                </BTbotbutton>
            </RowStack>
        </Stack>
    )
}