import BTButton from "@common-components/bt-button/bt-button";
import ExtensionIcon from '@mui/icons-material/Extension';
import { Box, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  updateUninstallState
} from "@common-reducers/UninstallReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import axios from "axios";
import { appConfig } from "@common-config/app.config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./uninstall-view.module.scss";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";

const UninstallView = () => {
  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false);
  const uninstallForm = useAppSelector(
    (state) => state.UninstallReducer.uninstallForm
  );


  function sendFeedback() {
    setSubmitted(true);

    const feedbackItems = [
      uninstallForm.whatDidYouExpect && `What did you expect from Blueticks?: ${uninstallForm.whatDidYouExpect} \n`,
      uninstallForm.scedualMessage && `Scheduled message did not work as expected:  ${uninstallForm.scedualMessageTA} \n`,
      uninstallForm.bulkCampaign && `bulk campaign did not work as expected:   ${uninstallForm.bulkCampaignTA} \n`,
      uninstallForm.taskManagement && `Task management did not work as expected: ${uninstallForm.taskManagementTA} \n`,
      uninstallForm.apiIntegration && `API integration did not work as expected: ${uninstallForm.apiIntegrationTA} \n`,
      uninstallForm.missingFeatures && `Can you share with us what has been missing?:   ${uninstallForm.missingFeaturesTA} \n`,
      uninstallForm.ididntExpect && "I didn't expects that WhatsApp web must be active for scheduled messages to be sent \n",
      uninstallForm.tooExpensive && "The premium plan is promoted too aggressively, Too expensive \n",
      uninstallForm.notEnoughFeatures && "The premium plan is promoted too aggressively, Not enough features in the free product \n",
      uninstallForm.dontWantCreateAccount && "I don't want to create an account \n",
      uninstallForm.emailAddress && "email: " + uninstallForm.emailAddressTA
    ].filter(x => x)

    axios.post(`${appConfig.API_ENDPOINT}/actions`, {
      cmd: "uninstall-feedback",
      feedbackItems,
    });
  }

  const buildFeedbackForm = () => <>
    <h6>Why did you uninstall Blueticks?</h6>
    <span className={classes.checkAll}>Check all that apply:</span>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "ididntExpect",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="I didn't expects that WhatsApp web must be active for scheduled messages to be sent"
      />
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "blueticksIsnt",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="Blueticks isn't what I expected"
      />
      {uninstallForm.blueticksIsnt && (
        <TextField
          id="filled-multiline-static"
          label="What did you expect from Blueticks?"
          name="t1"
          onChange={(event) => {
            dispatch(
              updateUninstallState({
                path: "whatDidYouExpect",
                value: event.target.value,
              })
            );
          }}
          multiline
          rows={4}
          defaultValue=""
          variant="filled"
        />
      )}
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "workingCorrectly",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="Blueticks isn't working correctly"
      />
    </FormGroup>

    {
      uninstallForm.workingCorrectly && (
        <div className={classes.itemsGroup}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "scedualMessage",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="Scheduled message did not work as expected"
            />
            {uninstallForm.scedualMessage && (
              <TextField
                name="t2"
                onChange={(event) => {
                  dispatch(
                    updateUninstallState({
                      path: "scedualMessageTA",
                      value: event.target.value,
                    })
                  );
                }}
                id="filled-multiline-static"
                label="Please give us more details so we fix it and improve the"
                multiline
                rows={4}
                defaultValue=""
                variant="filled"
              />
            )}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "bulkCampaign",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="bulk campaign did not work as expected"
            />
            {uninstallForm.bulkCampaign && (
              <TextField
                name="t3"
                onChange={(event) => {
                  dispatch(
                    updateUninstallState({
                      path: "bulkCampaignTA",
                      value: event.target.value,
                    })
                  );
                }}
                id="filled-multiline-static"
                label="Please give us more details so we fix it and improve the product"
                multiline
                rows={4}
                defaultValue=""
                variant="filled"
              />
            )}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "taskManagement",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="Task management did not work as expected"
            />
            {uninstallForm.taskManagement && (
              <TextField
                name="t4"
                onChange={(event) => {
                  dispatch(
                    updateUninstallState({
                      path: "taskManagementTA",
                      value: event.target.value,
                    })
                  );
                }}
                id="filled-multiline-static"
                label="Please give us more details so we fix it and improve the product"
                multiline
                rows={4}
                defaultValue=""
                variant="filled"
              />
            )}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "apiIntegration",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="API integration did not work as expected"
            />

            {uninstallForm.apiIntegration && (
              <TextField
                name="t5"
                onChange={(event) => {
                  dispatch(
                    updateUninstallState({
                      path: "apiIntegration",
                      value: event.target.value,
                    })
                  );
                }}
                id="filled-multiline-static"
                label="Please give us more details so we fix it and improve the product"
                multiline
                rows={4}
                defaultValue=""
                variant="filled"
              />
            )}
          </FormGroup>
        </div>
      )
    }
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "premiumPlan",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="The premium plan is promoted too aggressively"
      />
    </FormGroup>
    {
      uninstallForm.premiumPlan && (
        <div className={classes.itemsGroup}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "tooExpensive",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="Too expensive"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    dispatch(
                      updateUninstallState({
                        path: "notEnoughFeatures",
                        value: event.target.checked,
                      })
                    );
                  }}
                  sx={{
                    color: "#53bdeb",
                    "&.Mui-checked": {
                      color: "#53bdeb",
                    },
                  }}
                />
              }
              label="Not enough features in the free product"
            />
            <TextField
              name="t5"
              onChange={(event) => {
                dispatch(
                  updateUninstallState({
                    path: "premiumOther",
                    value: event.target.value,
                  })
                );
              }}
              id="filled-multiline-static"
              label="Somthing else?"
              multiline
              rows={4}
              defaultValue=""
              variant="filled"
            />
          </FormGroup>
        </div>
      )
    }

    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "dontWantCreateAccount",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="I don't want to create an account"
      />
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "missingFeatures",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="It's missing important features that I need."
      />
      {uninstallForm.missingFeatures && (
        <TextField
          name="t6"
          onChange={(event) => {
            dispatch(
              updateUninstallState({
                path: "missingFeaturesTA",
                value: event.target.value,
              })
            );
          }}
          id="filled-multiline-static"
          label="Can you share with us what has been missing?"
          multiline
          rows={4}
          defaultValue=""
          variant="filled"
        />
      )}
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => {
              dispatch(
                updateUninstallState({
                  path: "emailAddress",
                  value: event.target.checked,
                })
              );
            }}
            sx={{
              color: "#53bdeb",
              "&.Mui-checked": {
                color: "#53bdeb",
              },
            }}
          />
        }
        label="This is my email address, please update me if the issue has been fixed"
      />
    </FormGroup>
    {
      uninstallForm.emailAddress && (
        <TextField
          name="email"
          onChange={(event) => {
            dispatch(
              updateUninstallState({
                path: "emailAddressTA",
                value: event.target.value,
              })
            );
          }}
          type="email"
          id="standard-basic"
          label="My email address"
          variant="standard"
        />
      )
    }
    <div>

      <BTButton
        onClick={sendFeedback}
        sx={{
          width: "150px",
          marginTop: "40px",
          height: '40px',
        }}>Send</BTButton>
    </div>
  </>

  const buildThankyouMessage = () => {
    return <Stack direction="row" sx={{
      height: '100%'
    }} justifyContent="center" alignItems="flex-start">
      <h6>Thank you for you feedback!</h6>
    </Stack>;
  }
  
  useEffect(() => {
    analyticsService.event("uninstall_extension", {
      category: "uninstall",
      action: "uninstall extension",
    });

  }, []);

  return (
    <div>
      <div className={classes.headerContainer}>
        <div>
          <img className={classes.headerLogoImage} src="/img/logo.png" alt="" />
        </div>
        <div>


          <Link to={appConfig.extensionStoreLink}
            style={{
              textDecoration: "none"
            }} >
            <BTButton
              sx={{
                height: '40px',
              }}
              startIcon={<ExtensionIcon />}  > Reinstall Extension</BTButton>
          </Link>



        </div>
      </div>
      <div className={classes.titleContainer}>
        <h3 className={classes.mainTitle}>Help Us Improve</h3>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.blueticksMessage}>
          <div className={classes.imgContainer}>
            <img
              className={classes.robertImg}
              src="/img/robertimg.png"
              alt=""
            />
          </div>
          <p>Hi,</p>
          <p>
            I'm Robert, one of Blueticks' engineers. My team works hard to build features that can help anyone, anywhere, communicate clearly and effectively.
          </p>
          <p>
            We know our extension isn't perfect, but we're committed to making it the best possible for you. Can you help us by letting us know why you uninstalled the Blueticks browser extension?
          </p>
          <p>
            We use your feedback to understand how we can make our product better and more valuable. We're committed to improving it and hope to see you again.
          </p>
          <div className={classes.signature}>
            <img src="/img/Robert-Fiance-Signature.png" alt="" />
            <div>Senior Engineer</div>
          </div>
        </div>



        <Box flex={1} className={classes.whyUninstall}>
          {submitted ? buildThankyouMessage() : buildFeedbackForm()}
        </Box>
      </div>
    </div>
  );
};

export default UninstallView;
