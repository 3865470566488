import React from 'react';
import classes from "../chatgpt-node.module.scss";
import Temperature from './temperature';
import TopP from './top-p';
import NumberInput from './number-input';
import FrequencyPenalty from './frequency-penalty';
import PresencePenalty from './presence-penalty';
import OtherInputParameters from './other-input-parameters';
import ResponseFormat from './response-format';
import Seed from './seed';
import StopSequence from './stop-sequence';
import TokenProbability from './token-probability';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function AdvancedSettings({ localChatGPTState, setLocalChatGPTState }: Props) {

    return (
        <>
            <div className={classes.divider} />
            <Temperature
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <TopP
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <NumberInput
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <FrequencyPenalty
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <PresencePenalty
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <TokenProbability
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <ResponseFormat
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <Seed
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <StopSequence
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
            <OtherInputParameters
                localChatGPTState={localChatGPTState}
                setLocalChatGPTState={setLocalChatGPTState}
            />
        </>
    )
}
