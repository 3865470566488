import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function FrequencyPenalty({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Frequency Penalty', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.frequencyPenalty ?? ''}
                type='number'
                onChange={(newValue) => {
                    if (/^-?\d*\.?\d*$/.test(newValue) && +newValue >= -2 && +newValue <= 2) {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev.advanced,
                                frequencyPenalty: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `Positive values penalize new tokens based on their existing frequency, reducing repetition and encouraging more varied output. Conversely, negative values prioritize tokens that have already appeared, increasing repetition and reinforcing existing patterns`,
                {}
            )}
            {secondaryTitleWithMaybeLink(`Must be a number between -2 and 2`, {})}
        </>
    );
}
