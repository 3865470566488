import { AttachFileRounded, InsertLinkRounded } from '@mui/icons-material'
import { Stack } from '@mui/system'



export default function TemplateRowDetails({ template }) {
    // Assuming InsertLinkRounded and AttachFileRounded are imported components/icons
    const isLinkSwitch = !!template?.sendLinkPreview;
    const hasLink = !!template?.linkPreview?.url;
    const hasFiles = !!template?.asset;
    const isEmptyMessage = !template?.message;
    const isTemplateName = !!template?.name;


    if (!isLinkSwitch && !hasLink && !hasFiles && !isTemplateName && isEmptyMessage && !template.isPoll)
        return null;

    return (
        <>
            <Stack direction='row' sx={{ justifyContent: 'flex-start' }}>
                {template?.name && (
                    <span style={{
                        marginRight: '15px',
                        fontSize: '17px',
                        fontWeight: 500,
                    }}>
                        {template.name}
                    </span>
                )}
                {(hasLink && isLinkSwitch) && (
                    <div style={{
                        marginRight: '15px',
                        color: 'var(--template-label-text)',
                        backgroundColor: 'var(--template-label-bg)',
                        borderRadius: '6px',
                        width: '60px',
                        padding: '0 5px',
                        fontSize: '13px',
                        fontWeight: 600,
                        paddingRight: '2px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <InsertLinkRounded style={{ fontSize: '18px', paddingTop: "2px" }} />
                        <span>LINK</span>
                    </div>
                )}
                {hasFiles && (
                    <div style={{
                        marginRight: '15px',
                        color: 'var(--template-label-text)',
                        backgroundColor: 'var(--template-label-bg)',
                        borderRadius: '6px',
                        width: '60px',
                        padding: '0 5px',
                        fontSize: '13px',
                        fontWeight: 600,
                        paddingRight: '2px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <AttachFileRounded style={{ fontSize: '18px', paddingTop: "2px" }} />
                        <span>FILES</span>
                    </div>
                )}
            </Stack>
            {!isEmptyMessage && !template.isPoll &&
                <div style={{
                    fontSize: '14px',
                    fontWeight: 350,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 40px)',
                }}>
                    {template.message}
                </div>
            }
            {isEmptyMessage && !template.isPoll && !template?.asset?.type?.includes('audio') &&
                <div style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 40px)',
                }}>
                    This Template has no message
                </div>
            }
            {template?.asset?.type?.includes('audio') &&
                <div style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 40px)',
                }}>
                    {template?.asset?.name}
                </div>
            }
            {template.isPoll &&
                < div style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 40px)',
                }}>
                    Poll: {template.poll.question}<br />
                    {template.poll.options
                        .filter(option => option.text !== '')
                        .map((option, index) => (
                            <span key={index}>
                                {`${index}) ${option.text}`} <br />
                            </span>
                        ))}
                </div >
            }
        </>
    );
}
