import { getMyWorkPreferencesCurrentView, selectMyWorkElementList } from "@common-reducers/MyWorkThunks";
import React, { DetailedHTMLProps, memo, PropsWithChildren, useCallback } from "react";
import { GroupedVirtuoso } from "react-virtuoso";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { BoardRow } from "../tasks/board-row/BoardRow";
import classes from "./chat-task-list.module.scss";
import { Box, Stack } from "@mui/material";
import Notasks from '@common-assets/notasks.png';




function MyWorkTaskList() {
  const elementList = useAppSelector(selectMyWorkElementList) ?? [];
  const currentView = useAppSelector(getMyWorkPreferencesCurrentView);

  console.log(`MyWorkTaskList rendering`);
  const headers = elementList
    .filter(item => item.type === "boardHeader")
    .sort((a, b) => a - b);

    const groupStartingIndexes = headers.map(header => {
      return elementList.findIndex(item => item.id === header.id);
  })

  const groupCounts = groupStartingIndexes.map((startIndex, index) => {
    if (index === groupStartingIndexes?.length - 1) {
      return elementList?.length - startIndex;
    }
    return groupStartingIndexes[index + 1] - startIndex;
  });
  
  const HeightPreservingItem = useCallback(
    ({
      children,
      ...props
    }: PropsWithChildren<
      DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    >) => {
      return (
        <div
          {...props}
          className="height-preserving-container"
          style={{ height: "40px", width: 'fit-content' }}
        >
          {children}
        </div>
      );
    },
    []
  );
  if ((elementList.length === 1 && currentView === "board") || (elementList.length === 7 && currentView === "date")) {
    return <Stack  spacing={4} sx={{height:"50vh",width:"1000px",margin:"5% auto",borderRadius:8}} justifyContent={"center"} alignItems={"center"} >
     <Box sx={{fontWeight:'bold',fontSize:"24px"}}>
     No tasks assigned to you yet, You can customize my work.
     </Box>
      <img src={Notasks} alt="no-tasks" width="300" height="300" />
      </Stack>;
  }


  return (
    <GroupedVirtuoso
      components={{
        Item: HeightPreservingItem,
      }}
      style={{ height: "calc(100vh - 215px)" }}
      groupCounts={groupCounts}
      className={classes.virtuoso_wrapper}

      groupContent={index => {
        const header = headers[index];
        return (
          <BoardRow item={header} isDragging={false} provided={undefined} />
        );
      }}
      itemContent={(index, groupIndex) => {
        const item = elementList[index + 2];
        if (item === undefined) return null;;
        if (item.type === "task" || item.type === "spacer") {
          return <BoardRow item={item} isDragging={false} provided={undefined} />
        }


      }}


    />
  );
}

export default memo(MyWorkTaskList);
