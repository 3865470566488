import RestoreIcon from "@mui/icons-material/Restore";
import {
    Box,
    Button,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {
    DBWorkspaceThunks,
    workspaceSelectByQuery,
} from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect } from "react";
import classes from "@common-views/account-view/account-view.module.scss";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeletedWorkspacesModal = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const deletedWorkspaces = useAppSelector((state) =>
    workspaceSelectByQuery(state.DBWorkspaceReducer, { deleted: true })
  );

  useEffect(() => {
    if (open) {
      dispatch(DBWorkspaceThunks.find({ deleted: true }));
    }
  }, [open, dispatch]);

  const onRestore = async (workspace) => {
    await dispatch(
        DBWorkspaceThunks.patch({
            entity: {
                _id: workspace._id,
                deleted:false
            }
        }))
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <h2>Deleted Workspaces</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Workspace Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deletedWorkspaces.length > 0 ? (
                deletedWorkspaces.map((ws, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {ws.imgUrl ? (
                        <img
                          className={classes.workspaceImg}
                          src={ws.imgUrl}
                          alt=""
                          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                        />
                      ) : (
                        <Stack
                          justifyContent={"center"}
                          alignItems={"center"}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.1)",
                          }}
                          className={classes.workspaceImgPlaceholder}
                        >
                          {ws.name.charAt(0)}
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell>{ws.name}</TableCell>
                    <TableCell>
                      <Button variant="outlined" sx={{textTransform:"capitalize"}} onClick={() => onRestore(ws)}>
                        <RestoreIcon /> &nbsp; Restore
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No deleted workspaces
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default DeletedWorkspacesModal;
