import { Stack } from "@mui/material";
import { NullableId } from "@common/types/interface";
import { memo } from "react";
interface Props {
    values: any;
    columnId: NullableId;
}
function CheckBoxSumCell({ values, columnId }: Props) {

    const calculateSum = () => {

        const trueCount = values?.data?.filter(value => value).length;
        return trueCount
    };

    const sum = calculateSum();

    return (
        <Stack sx={{position:"relative"}} alignItems={"center"} flexGrow={1} textAlign={"center"}>

            {sum} / {values?.data?.length}

        </Stack>
    );
}

export default memo(CheckBoxSumCell)