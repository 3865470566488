import { getContextName, isWebPage } from "./detect-context";
import { createBrowserHistory, createMemoryHistory } from "history";

export let globalHistory;
const context = getContextName();
console.log(`Running in context: ${context}`);

if (isWebPage()) {
  globalHistory = createBrowserHistory();
} else {
  globalHistory = createMemoryHistory();
}
