import { taskColumnsWidth } from '@common-components/tasks/tasksConfig';
import { TasksGroup } from '@common-models/task-group';
import { Tooltip } from '@mui/material';
import { _taskgroupsSelectors } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { MdAdd } from 'react-icons/md';
import classes from "../../task-row/task_row.module.scss";
import { CellProps } from '../cell-interface';
import { getBlueticksThemeSelector } from '@common-reducers/BoardsSelectors';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';

export default function RowSuffixHeader({
    groupId,
    openPicker
}: CellProps) {
    const { t } = useTranslation(namespace);
    const tasksGroup: TasksGroup = useAppSelector((state) => {
        return _taskgroupsSelectors.selectById(state, groupId)
    });
    const theme = useAppSelector((state) => getBlueticksThemeSelector(state))

    const anchorId = `addColumn_${tasksGroup?._id}`;

    const openAddColumnDialog = () => {
        openPicker({
            anchorId,
            pickerType: 'add-column-picker'
        })
    }

    return (
        <div
            id={anchorId}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: taskColumnsWidth.addColumnCell,
                backgroundColor: theme?.boards?.tasksPanel?.background,
                height: '39px'
            }} >
            <Tooltip arrow placement={"top"} title={t('rowSuffixHeader.addColumn')} data-testid='add-new-column-row-suffix-button'>
                <button className={classes.add_button} onClick={openAddColumnDialog}>
                    <MdAdd />
                </button>
            </Tooltip>
        </div>
    )
}