import RowStack from '@common-components/common/row-stack/RowStack';
import { UserMessage } from '@common/models/user-message';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { useEffect, useState } from 'react'

type Props = {
    height: string;
    scheduledMessage?: UserMessage;
}


export default function ReplyToMessageChip({ height, scheduledMessage }: Props) {
    const message = useAppSelector((state) => state.AddScheduledMessageButtonReducer.message);
    const localReplyData = useAppSelector(state => state.WhatsAppReducer.isReply)

    const [replyData, setReplyData] = useState(scheduledMessage?.replyData ?? message?.replyData ?? localReplyData)


    const bblColor = replyData?.fromMe ? '#06cf9c' : '#53bdeb'
    const sender = replyData?.fromMe ? 'You' : replyData?.sender

    const base64Format = `data:${replyData?.mimetype};base64,${replyData?.data}`

    useEffect(() => {
        if (!scheduledMessage) {
            if (localReplyData) {
                setReplyData(localReplyData)
            }
            if (message?.replyData) {
                setReplyData(message?.replyData)
            }
        }
    }, [message?.replyData, localReplyData])


    return (
        <>
            {replyData &&
                <RowStack sx={{
                    width: '100%',
                    backgroundColor: '#0b141a0d',
                    borderRadius: '7px',
                }}>
                    <span style={{
                        backgroundColor: bblColor,
                        flex: 'none',
                        width: '4px',
                        height,
                        borderTopLeftRadius: '7px',
                        borderBottomLeftRadius: '7px',
                    }}></span>


                    <div style={{
                        height,
                        padding: '7px 12px 10px 11px',
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                    }}><div style={{
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}>

                            <span style={{
                                display: 'inline-flex',
                                maxWidth: '100%',
                                fontSize: '12.8px',
                                lineHeight: '22px',
                                fontWeight: 500,
                                color: bblColor,
                            }}>{sender}</span>

                            <span style={{
                                display: '-webkit-box',
                                maxHeight: '40px',
                                overflow: 'hidden',
                                fontSize: '13px',
                                lineHeight: '22px',
                                color: '#667781',
                                textOverflow: 'ellipsis',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                            }}>{replyData?.text}</span>

                        </div></div>


                    {replyData?.mimetype?.includes('image') &&
                        < img src={base64Format} alt={replyData?.filename ?? 'data'} style={{
                            height,
                        }} />
                    }

                </RowStack>
            }
        </>
    )
}