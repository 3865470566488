import PromptDeleteAction from '@common-components/prompt-delete-action/prompt-delete-action';
import MoveToMenu from '@common-components/tasks-panel/bulk-actions-dialog/move-to/move-to-menu';
import { Task } from '@common-models/task';
import { TasksGroup } from '@common-models/task-group';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { bulkActions } from '@common-reducers/BulkActionsReducer';
import { DBTaskgroupsThunks, taskGroupsSelectOneFieldByQuery, taskgroupsSelectors, tasksSelectors } from "@common-reducers/DBServiceThunks";
import { mainSliceActions } from '@common-reducers/MainReducer';
import { MODAL, modalActions } from '@common-reducers/ModalReducer';
import { pickerDialogActions } from '@common-reducers/PickerDialogReducer';
import { areAllGroupsCollapsedSelector } from '@common-reducers/TaskSelectors';
import { moveGroupToTopOfTheGroupAndReOrderRestOfGroup, setSubtaskStateForTaskList, updateAllGroupsCollapsedState } from '@common-reducers/TaskThunks';
import { taskActions } from '@common-reducers/TasksReducer';
import { UndoRedoAction } from '@common-reducers/UndoRedoReducer';
import { doActionThunk } from '@common-reducers/UndoRedoThunks';
import { selectUser } from '@common-reducers/UserSelectors';
import { useDuplicateGroupMutation } from '@common-reducers/backend-api/backend-api';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';
import { BsChevronRight, BsTrash } from 'react-icons/bs';
import { CgArrowsScrollV } from 'react-icons/cg';
import { FiArchive, FiArrowRight } from 'react-icons/fi';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { MdAdd, MdOutlineContentCopy } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TbSubtask } from 'react-icons/tb';
import classes from './group-menu.module.scss';

export default function GroupMenu() {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const [duplicateCurrentGroup] = useDuplicateGroupMutation();
    const menuActiveGroupId = useAppSelector((state) => state.MainReducer.menuActiveGroupId)
    const menuActiveGroupName = useAppSelector(state => taskGroupsSelectOneFieldByQuery(state.DBTaskgroupsReducer, {
        _id: menuActiveGroupId,
    }, 'name'))
    const isBoardItemMenuOpen = useAppSelector((state) => state.BoardsReducer.isBoardItemMenuOpen)
    const move_to = useAppSelector((state) => state.BulkActionsReducer.move_to)
    const user = useAppSelector(selectUser);
    const selectedBoardId = useAppSelector(getSelectedBoardId);
    const areAllGroupsCollapsed = useAppSelector(areAllGroupsCollapsedSelector)
    const [open, setOpen] = useState(false);

    const tasksGroup: TasksGroup = useAppSelector((state) =>
        taskgroupsSelectors.selectById(state, menuActiveGroupId)
    );
    const tasksGroups: TasksGroup[] = useAppSelector((state) =>
        taskgroupsSelectors.selectAll(state)
    );

    const tasks: Task[] = useAppSelector((state) => {
        return tasksSelectors.selectAll(state)
    });
    const [duplicatedGroupName, setDuplicatedGroupName] = React.useState<string>("")

    const id = isBoardItemMenuOpen ? 'board-item-popper' : undefined;

    let menuAnchorEl
    if (menuActiveGroupId) {
        menuAnchorEl = document.getElementById(`group-button-${menuActiveGroupId}`);
    }


    //menu delete board
    function deleteGroup() {
        setOpen(true);
    }

    const archiveGroup = () =>{
        const action: UndoRedoAction<string[], string> = {
            type: 'delete-group-list',
            data: [menuActiveGroupId],
            deletedOrArchived:"archived"
        }
        dispatch(doActionThunk({ action }),)
        dispatch(mainSliceActions.toggleGroupMenu(''))

    }

    const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            const action: UndoRedoAction<string[], string> = {
                type: 'delete-group-list',
                data: [menuActiveGroupId],
                deletedOrArchived:"deleted"
            }
            dispatch(doActionThunk({ action }),)
        }
        setOpen(false);
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

    function openColorPicker() {
        dispatch(pickerDialogActions.setActiveGroupId(tasksGroup._id))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

    function handleColapse() {
        const clonedTasksGroup = { ...tasksGroup }
        clonedTasksGroup.isCollapsed = !clonedTasksGroup.isCollapsed
        dispatch(DBTaskgroupsThunks.patch({
            entity: clonedTasksGroup
        }))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

    function handleColapseAll(bool: boolean) {
        dispatch(updateAllGroupsCollapsedState(bool))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

    const groupItems: any = tasks.filter((task) => task?.groupId === menuActiveGroupId);

    function selectAllItems() {
        console.log(groupItems)
        dispatch(bulkActions.setBulkTasksMap(groupItems.reduce((bulkTaskMap, task) => ({
            ...bulkTaskMap,
            [task._id]: task
        }), {})))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }


    //expand all subtask
    function expandAllSubItems(expand: boolean) {
        dispatch(setSubtaskStateForTaskList({
            taskIdList: tasks.map((task) => task._id),
            expand
        }));
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }


    const forceGroupEditMode = () => {
        dispatch(taskActions.setForceEditModeToGroupNameByGroupId(tasksGroup._id))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }


    function exportGroup() {
        dispatch(modalActions.setComponentToRender(MODAL.EXPORT_TO_EXCEL))
        dispatch(bulkActions.setBulkTasksMap(groupItems.reduce((bulkTaskMap, task) => ({
            ...bulkTaskMap,
            [task._id]: task
        }), {})))
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }


    function addGroupBelow() {
        const clonedTaskGroup = { ...tasksGroup }
        if (user?._id && selectedBoardId) {
            const taskGroup: TasksGroup = {
                name: t('groupMenu.newGroupName'),
                owner: user._id,
                createdBy: user._id,
                boardId: selectedBoardId,
            };
            dispatch(DBTaskgroupsThunks.patch({ entity: { ...tasksGroup, order: tasksGroup.order + 1 } }));
            dispatch(DBTaskgroupsThunks.create({ ...taskGroup, order: clonedTaskGroup.order }));
            dispatch(mainSliceActions.toggleGroupMenu(''))
        }
    }


    async function duplicateGroup() {
        const res = await duplicateCurrentGroup({ groupId:menuActiveGroupId })
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }

   
    //move group to top
    function moveGroupToTop() {
        dispatch(moveGroupToTopOfTheGroupAndReOrderRestOfGroup());
        dispatch(mainSliceActions.toggleGroupMenu(''))
    }


    function setMovingTo(to) {
        dispatch(bulkActions.setMovingTo(to))
        dispatch(bulkActions.setGroupIsMoving(true))
        dispatch(bulkActions.setBulActionsMoveToId(menuActiveGroupId))
    }


    return (<>
        <PromptDeleteAction title={`${t('groupMenu.deleteGroupPromptTitle')} ${menuActiveGroupName}?`} handleClose={handleDeleteConfirmationClosed} open={open} />
        {menuActiveGroupId && (
            <ClickAwayListener onClickAway={() => dispatch(mainSliceActions.toggleGroupMenu(''))}>
                <div style={{ position: 'relative', zIndex: 1300 }}>
                    <Popper
                        id={id}
                        open={true}
                        anchorEl={menuAnchorEl}
                        style={{ zIndex: 1300 }}
                    >
                        <div className={classes.menu_wrapper}>
                            <div className={classes.menu_head}>
                                <button onClick={handleColapse} className={classes.menu_list_item}>
                                    <CgArrowsScrollV size={16} />
                                    <span style={{ marginLeft: 5 }}>{!tasksGroup.isCollapsed ? t('groupMenu.collapseThisGroup') : t('groupMenu.uncollapseThisGroup')}</span>
                                </button>

                                {!areAllGroupsCollapsed && <button onClick={() => handleColapseAll(true)} className={classes.menu_list_item}>
                                    <CgArrowsScrollV size={16} />
                                    <span style={{ marginLeft: 5 }}>
                                        {t('groupMenu.collapseAllGroups')}
                                    </span>
                                </button>}

                                {areAllGroupsCollapsed && (
                                    <button onClick={() => handleColapseAll(false)} className={classes.menu_list_item}>
                                        <CgArrowsScrollV size={16} />
                                        <span style={{ marginLeft: 5 }}>
                                            {t('groupMenu.uncollapseAllGroups')}
                                        </span>
                                    </button>
                                )}


                                <button className={classes.menu_list_item} onClick={selectAllItems}>
                                    <IoMdCheckboxOutline />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.selectAllItems')}</span>
                                </button>
                                <button className={classes.menu_list_item} onClick={() => expandAllSubItems(true)}>
                                    <TbSubtask />
                                    <span style={{ marginLeft: 5 }}>
                                        {t('groupMenu.expandAllSubitems')}
                                    </span>
                                </button>

                                <button className={classes.menu_list_item} onClick={() => expandAllSubItems(false)}>
                                    <TbSubtask />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.collapseAllSubitems')}</span>
                                </button>
                            </div>
                            <button onClick={addGroupBelow} className={classes.menu_list_item}>
                                <MdAdd />
                                <span style={{ marginLeft: 5 }}>  {t('groupMenu.addGroup')}</span>
                            </button>
                            <button onClick={duplicateGroup} className={classes.menu_list_item}>
                                <MdOutlineContentCopy />
                                <span style={{ marginLeft: 5 }}>{t('groupMenu.duplicateGroup')}</span>
                            </button>
                            <div className={classes.move_to_wrapper}>
                                <button style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }} className={classes.menu_list_item} id={`bulk-actions-menu-${menuActiveGroupId}`}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <FiArrowRight />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.moveTo')}</span>
                                    </Stack>
                                    <BsChevronRight />
                                </button>
                                {move_to ?

                                    <div className={classes.menu_component_wrap}>
                                        <MoveToMenu />
                                    </div>
                                    : (
                                        <div className={classes.move_to_menu}>
                                            <button onClick={() => setMovingTo("board")} className={classes.move_to_btn}>{t('groupMenu.moveToBoard')}</button>
                                            <button onClick={moveGroupToTop} className={classes.move_to_btn}>{t('groupMenu.moveToTop')}</button>
                                        </div>
                                    )}


                            </div>
                            <div className={classes.menu_footer}>
                                <button onClick={forceGroupEditMode} className={classes.menu_list_item}>
                                    <BiPencil />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.renameGroup')}</span>
                                </button>
                                <button onClick={openColorPicker} className={classes.menu_list_item}>
                                    <div style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: '50%',
                                        backgroundColor: tasksGroup?.colorAccent ?? '#00c875'
                                    }}></div>
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.changeGroupColor')}</span>
                                </button>
                            </div>
                            <div className={classes.menu_footer}>
                                <button onClick={exportGroup} className={classes.menu_list_item}>
                                    <RiFileExcel2Line />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.exportToExcel')}</span>
                                </button>

                            </div>
                            <div className={classes.menu_footer}>
                            {/* <button onClick={archiveGroup} className={classes.menu_list_item}>
                                    <FiArchive />
                                    <span style={{ marginLeft: 5 }}>Archive</span>
                                </button> */}
                                <button onClick={deleteGroup} className={classes.menu_list_item}>
                                    <BsTrash />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.delete')}</span>
                                </button>
                                {/* <button className={classes.menu_list_item}>
                                    <FiArchive />
                                    <span style={{ marginLeft: 5 }}>{t('groupMenu.archive')}</span>
                                </button> */}

                            </div>
                        </div>

                    </Popper>
                </div>
            </ClickAwayListener>
        )}

    </>

    );
}



























