import { Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import { TiWarningOutline } from "react-icons/ti";
import BTThemeProvider from "./bt-theme-provider";
import { isExtensionContext } from "@common-services/detect-context";
export interface MessageProblemProps {
  isSolid?: boolean;
}
export interface MessageProblemState { }

class MessageProblem extends React.Component<
  MessageProblemProps,
  MessageProblemState
> {
  constructor(props: MessageProblemProps) {
    super(props);
    this.state = {};
  }
  render() {
    const isExtension = isExtensionContext()
    return (
      <BTThemeProvider>
        <Tooltip
          PopperProps={{ style: { textAlign: "left" } }}
          style={{ direction: "ltr" }}
          title="Your message couldn't be sent at the scheduled time"
        >
          <div style={{display: !isExtension && 'flex',}}>
          {this.props.isSolid ? (
            <Warning
              style={{
                height: 18,
                color: "yellow",
                fill: "black",
                stroke: "white",
                position: "relative",
                right: 4,
              }}
            />
          ) : (
            <TiWarningOutline />
          )}
        </div>
      </Tooltip>
      </BTThemeProvider >
    );
  }
}

export default MessageProblem;
