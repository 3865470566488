import { IconButton, Menu, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import classes from "./filter_button.module.scss";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { isFiltersExist } from '@common-reducers/TasksFilterPanelThunks'
import ToolbarButton from '@common-components/toolbar-button';
import { Google, KeyboardArrowDown, ListAlt } from '@mui/icons-material';
import { MODAL, modalActions } from '@common-reducers/ModalReducer';
import { MdFileDownload } from 'react-icons/md';


type Props = {
    isFromCampaign: boolean;
}


export default function TaskPanelHeaderImportButton({
    isFromCampaign,
}: Props) {
    const dispatch = useAppDispatch()


    function openImportModal() {
        if (isFromCampaign) {
            dispatch(
                modalActions.setOpenedby('campaign')
            );
        }
        dispatch(
            modalActions.setComponentToRender(MODAL.IMPORT_EXCEL_TO_BLUETICKS)
        );

        dispatch(
            modalActions.setImportType()
        );
    }


    return (
        <div className={classes.filterButtonContainer}>
            <Stack sx={{ gap: '2px' }} direction={"row"}
            >
                <ToolbarButton
                    startIconSVG={MdFileDownload}
                    onClickAction={() => openImportModal()}
                    buttonText="Import"
                    className={classes.button_col}
                    toolTipTitle="Import from excel"
                />
            </Stack>
        </div>
    );
}