import { AugmentedBlueticksBoardUser } from "@common-reducers/BoardsSelectors";
import { User } from "./user";

export const UserRoleOrderMap: Record<UserRole, number> = {
    'owner': 1,
    'member': 2,
    'guest': 3
  }

// Order matters! the higher the index the more "powerfull" the role is
export const UserRoleList = ['guest', 'member', 'owner']
export type UserRole = typeof UserRoleList[number];

export const ValidManualStatusList = ['active', 'disabled']
export const WorkspaceUserStatusList = ['pending', ...ValidManualStatusList]
export type WorkspaceUserStatus = typeof WorkspaceUserStatusList[number];

export interface BlueticksWorkspaceUser {
    _id?: string;
    userId?: string;
    role: UserRole;
    status?: WorkspaceUserStatus;
    profile?: Partial<User>;
    email?: string;
    workspaceId?: string;
}

export const workspaceUserCompareValue = (user: BlueticksWorkspaceUser | AugmentedBlueticksBoardUser): string => [user?.profile?.firstName, user?.profile?.lastName].filter(x => x).join(' ') || user?.email || '';