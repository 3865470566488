import { Stack } from '@mui/system'
import classes from "../bt-chatbot.module.scss";
import React, { forwardRef } from 'react'
import AddConditionDialog from '../bt-nodes/condition/add-condition-dialog';
import AddQuestionDialog from '../bt-nodes/question/add-question-dialog';
import BotSettings from './bot-settings';
import AddWebhookDialog from '../bt-nodes/webhook/add-webhook-dialog';
import AddGoogleSheetDialog from '../bt-nodes/google-sheets/add-google-sheet-dialog';
import AddStatusDialog from '../bt-nodes/status/add-status-dialog';
import AddChatGPTDialog from '../bt-nodes/chatGPT/add-chatgpt-dialog';

type Props = {
    type: 'setCondition' | 'setQuestion' | 'setSettings' | 'setWebhook' | 'setGoogleSheet' | 'setStatus' | 'setChatGPT';
    onClose: () => void;
    id?: string;
}

const BTChatbotModal = forwardRef<HTMLDivElement, Props>(({
    type,
    onClose,
    id,
}, ref) => {
    return (
        <Stack
            className={`${type === 'setSettings' ? classes.bt_popover_wrapper_for_settings : classes.bt_popover_wrapper} 
        ${classes.scrollContainer}`}
        >
            {type === 'setCondition' && <AddConditionDialog onClose={onClose} id={id} />}
            {type === 'setQuestion' && <AddQuestionDialog onClose={onClose} id={id} />}
            {type === 'setSettings' && <BotSettings onClose={onClose} />}
            {type === 'setWebhook' && <AddWebhookDialog onClose={onClose} id={id} />}
            {type === 'setGoogleSheet' && <AddGoogleSheetDialog onClose={onClose} id={id} />}
            {type === 'setStatus' && <AddStatusDialog onClose={onClose} id={id} />}
            {type === 'setChatGPT' && <AddChatGPTDialog onClose={onClose} id={id} />}
        </Stack>
    )
})

export default BTChatbotModal;

export const modalTextStyle = {
    fontSize: '13px',
    color: '#0A3750',
}