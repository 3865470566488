import { logsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
}

export default function InviteAcceptRemoveRestoreBoardUserLog({
    logId,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const logType = log.actionType

    return (
        <LogStack direction='row'>
            {['invited', 'restored'].includes(logType) === true &&
                <>
                    <p>{log.triggerByData}</p>
                    <p>{log.actionType}</p>
                    <p>{log.actionOnEntityData}</p>
                    <p>to</p>
                    <p style={{ fontWeight: 'bold' }}>{log.customData}</p>
                </>
            }
            {logType === 'accepted' &&
                <>
                    <p>{log.triggerByData}</p>
                    <p>{log.actionType}</p>
                    <p>the invitation to</p>
                    <p style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</p>
                </>
            }
            {logType === 'removed' &&
                <>
                    <p>{log.triggerByData}</p>
                    <p>{log.actionType}</p>
                    <p>{log.actionOnEntityData}</p>
                    <p>from</p>
                    <p style={{ fontWeight: 'bold' }}>{log.customData}</p>
                </>
            }
        </LogStack>
    )
}