import React from 'react';
import classes from "../chatgpt-node.module.scss";

export const title = (title: string, style?: any) => {
    return <div className={classes.title} style={style}>{title}</div>
}

export const secondaryTitleWithMaybeLink = (
    secondaryTitle: string,
    style?: React.CSSProperties,
    linkString?: string,
    linkURL?: string,
    additionalTextAfterLink?: string
) => {
    return (
        <div className={classes.secondary_title} style={style}>
            {secondaryTitle}
            {linkString && linkURL && (
                <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                    {linkString}
                </a>
            )}
        </div>
    );
};
