import { WhatsappId } from "@wa-communication-layer/whatsapp";

export interface ContactInfoData {
  _id?: string;
  whatsappId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  origName?: string;
  contactName: string;
}

export interface ContactInfo {
  _id?: string;
  id?: WhatsappId;
  whatsappId?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  origName?: string;
  imported?: boolean;
  mobile?: string;
  updatedAt?: string;
  contactName?: string;
  displayName?: string;
  version?: number;
  placeholder?: string;
  shortName?: string;
  verifiedName?: string;
}

export function contactMatches(
  filter: string,
  contactInfo?: ContactInfo
): boolean {
  const lowerCaseFilter = filter.toLowerCase();
  return (
    (contactInfo?.name || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.firstName || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.lastName || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.displayName || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.origName || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.whatsappId || "").toLowerCase().indexOf(lowerCaseFilter) > -1 ||
    (contactInfo?.verifiedName || "").toLowerCase().indexOf(lowerCaseFilter) > -1
  );
}
