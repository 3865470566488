import BTButton from "@common-components/bt-button/bt-button";
import { CircularProgress, Stack } from "@mui/material";
import { switchWorkspace } from "@common-reducers/UserThunks";
import { useAcceptInvention } from "@common-reducers/hooks/use-accept-invitation";
import { useRouter } from "@common-reducers/hooks/use-router";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import classes from "./join-board.module.scss";

export interface JoinBoardState {
  loading: boolean;
  message?: string;
}

export const JoinBoard = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const location = useLocation();
  const match = useMatch("/join-board/:boardId");
  const boardId = useMemo(() => match.params.boardId, [match]);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const inviteId = useMemo(() => searchParams.get("inviteId"), [searchParams]);
  const [loadingBoard, setLoadingBoard] = useState(false);

  const {
    loading,
    message,
    authorized,
    handleLoginWithDifferentUser,
    workspaceId,
  } = useAcceptInvention(boardId, inviteId, false);
  const gotoBoard = useCallback(async () => {
    setLoadingBoard(true)
    await dispatch(switchWorkspace({ workspaceId }));
    router.navigate(`/tasks?boardID=${boardId}`)
  }, [boardId, dispatch, router, workspaceId])

  return (
    <div className={classes.container}>
      <div className={classes.card} style={{ minWidth: 500, minHeight: 500 }}>
        {loading ? <Stack sx={{ height: 500 }} direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack> :
          <>
            <div className={classes.header}>
              <img alt="" src="/img/logo.png" style={{ height: 30 }} />
            </div>

            <Stack className={classes.body} direction="column" spacing={2}>
              {message && <>
                <h2>{message}</h2>
              </>}
              {!authorized &&
                <Stack direction="row" justifyContent="center">
                  <BTButton onClick={handleLoginWithDifferentUser}>Switch User</BTButton>
                </Stack>}
              {boardId && authorized &&
                <Stack justifyContent="center" alignItems="center">
                  {/* <h2>
                  Well done!
                  <br />
                  We are excited to have you onboard😊
                </h2> */}
                  <div>
                    <BTButton loading={loadingBoard} onClick={gotoBoard}>Go to Board</BTButton>
                  </div>
                </Stack>}
              <img src="/img/illustrator_img.png" alt="illustration" />
              <p>"It takes two flints to make a fire."</p>
            </Stack>
          </>}
      </div>
    </div>
  )
}

export default JoinBoard;
