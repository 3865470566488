import { Stack } from '@mui/system';
import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { DBBoardViewThunks, boardViewSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useMemo } from 'react';
import BTCheckboxList from '../checkbox-list/bt-checkbox-list';
import { getDynamicColumnListForBTCheckbox } from '@common-reducers/TaskSelectors';

export default function BoardViewIncludedColumnsDialog() {
    const dispatch = useAppDispatch()
    const currentBoardId = useAppSelector(getSelectedBoardId)
    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
    const [boardView] = useAppSelector((state) => boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: currentBoardId, _id: currentBoardView.id }));
    const columnList = useAppSelector((state) => getDynamicColumnListForBTCheckbox(state, 'board'))
    const includedColumns = useMemo(() => (boardView && boardView?.includedColumns ? boardView?.includedColumns : {}), [boardView])
    const handleCheckBoxChange = (value, column) => {
        dispatch(
            DBBoardViewThunks.patch({
                entity: {
                    _id: boardView._id,
                    includedColumns: {
                        ...boardView.includedColumns,
                        [column._id]: value,
                    }
                }
            })
        )

    }

    function handleAllCheckBoxChange(value) {
        const newIncludedColumnsList = columnList.map((column, radioListIndex) => ({
            NullableId: column._id, boolean: value
        }))
        let newIncludedColumns = {}
        for (let i in newIncludedColumnsList) {
            newIncludedColumns = {
                ...newIncludedColumns,
                [newIncludedColumnsList[i].NullableId]: newIncludedColumnsList[i].boolean
            }
        }

        dispatch(
            DBBoardViewThunks.patch({
                entity: {
                    _id: boardView._id,
                    includedColumns: newIncludedColumns,
                }
            })
        )
    }

    const isChecked = (column) => (includedColumns[column._id] ?? false)

    const isCheckedRecord: Record<string, boolean> = columnList.reduce((isCheckedRecord, column) => ({
        ...isCheckedRecord,
        [column._id]: isChecked(column)
    }), {});

    return (
        <Stack direction="column" >
            <BTCheckboxList
                isCheckedRecord={isCheckedRecord}
                columnList={columnList}
                handleCheckBoxChange={handleCheckBoxChange}
                handleAllCheckBoxChange={handleAllCheckBoxChange}
            />
        </Stack>
    )
}
