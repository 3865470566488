export enum BoardRowType {
  boardHeader = "boardHeader",
  groupHeader = "groupHeader",
  task = "task",
  newTask = "newTask",
  subTask = "subTask",
  newSubTask = "newSubTask",
  spacer = "spacer",
  footer = "footer",
  newGroup = "newGroup"
}
export interface CustomColumnData {
  value?: any;
  order?: number;
}

export interface LocalTaskData {
  checked?: boolean;
}

export interface Task {
  _id?: string;
  text?: string;
  number?: number;
  status?: string;
  statusId?: string;
  person?: string;
  personId?: string;
  priority?: string;
  priorityId?: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  groupIndex?: number;
  taskListIndex?: number;
  chatId?: string;
  groupId?: string;
  boardId?: string;
  owner?: string;
  ownerWAId?: string;
  order?: number;
  version?: number;
  customData?: Record<string, CustomColumnData>;
  overideOptimisticUpdate?: boolean,
  link?: string,
  linkText?: string,
  date?: string;
  showSubTasks?: boolean,
  showAllSubTasks?: boolean,
  parentTaskId?: string,
  taskType?: string,
  deleted?: boolean,
  archived?: boolean,
  files?: Record<string, any>;
  dirty?: boolean,
  isTaskInEditMode?: boolean,
  groupColor?: string,
  isQuickTask?:boolean
  isTemplate?: boolean
  phoneColumnId?: string; //for campaigns only
  isTaskConverted?: boolean; // for audience boards that have tasks with whatsappId in their name
}


export interface BTdragElement {
  _id: string,
  index: number,
  parent?: string,
  grandparent?: string,
  type?: string,
  IgnoreElementTypesOnBottomTop?: string[]
  IgnoreElementTypesOnTopBottom?: string[]
}


