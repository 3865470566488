import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field'
import { modalTextStyle } from '@common-components/bt-chatbot/bot-components/bt-chatbot-modal'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useRef, useState } from 'react'
import classes from "./chatgpt-node.module.scss";

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function MethodDropdown({ localChatGPTState, setLocalChatGPTState, }: Props) {
    const containerRef = useRef(null);

    const [isSelectMethodMenuOpen, setIsSelectMethodMenuOpen] = useState<boolean>(false);


    const methodsArray = [
        'Create a Chat Completion (GPT Models)',
        'Create a Prompt Completion'
    ]

    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }


    const toggleSelectMethodMenu = (e) => {
        setIsSelectMethodMenuOpen(prev => !prev);
    }


    const handleCloseSelectMethodMenu = (e, index) => {
        e.stopPropagation()


        const method = () => {
            if (index === 0) {
                return 'chat completion';
            } else if (index === 1) {
                return 'prompt completion';
            }
        }

        setLocalChatGPTState({
            ...localChatGPTState,
            method: method(),
            model: undefined,
            prompt: undefined,
            messages: method() === 'chat completion' ? [{ message: '', role: '' }] : undefined,
        });

        setIsSelectMethodMenuOpen(false);
    }


    const getMethod = () => {
        if (localChatGPTState.method === 'chat completion') {
            return 'Create a Chat Completion (GPT Models)';
        } else if (localChatGPTState.method === 'prompt completion') {
            return 'Create a Prompt Completion';
        }
    }

    const handleBlur = (event) => {
        if (containerRef.current && containerRef.current.contains(event.relatedTarget)) {
            // Focus is shifting within the container, so don't close the menu
            return;
        }
        // Otherwise, close the menu
        setIsSelectMethodMenuOpen(false);
    };

    return (
        <div ref={containerRef} onBlur={handleBlur}>
            {title('Select method', { marginTop: '20px', })}
            <BotInputField
                onClick={toggleSelectMethodMenu}
                value={getMethod() ?? ''}
                endAdornment={
                    <>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }} onClick={toggleSelectMethodMenu}>
                            {!isSelectMethodMenuOpen && <KeyboardArrowDown />}
                            {isSelectMethodMenuOpen && <KeyboardArrowUp />}
                        </IconButton>
                    </>
                }
            />
            {isSelectMethodMenuOpen &&
                <>
                    {methodsArray.map((method, index) => (
                        <BotInputField
                            key={index}
                            value={method}
                            onClick={(e) => handleCloseSelectMethodMenu(e, index)}
                            sx={{
                                ".MuiOutlinedInput-input": {
                                    ...modalTextStyle,
                                    zIndex: 3,
                                    lineHeight: '19px',
                                    cursor: 'pointer',
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                    backgroundColor: 'rgb(245, 246, 250)',
                                    cursor: 'pointer'
                                },
                                "&: hover": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        backgroundColor: 'rgb(204, 208, 222)',
                                    },
                                },
                            }} />
                    ))}
                </>
            }
        </div>
    )
}