import CheckboxFormCell from "@common-components/tasks-panel/form/form-cell/checkbox-form-cell";
import DateFormCell from "@common-components/tasks-panel/form/form-cell/date-form-cell";
import NumberFormCell from "@common-components/tasks-panel/form/form-cell/number-form-cell";
import PriorityStatusFormCell from "@common-components/tasks-panel/form/form-cell/priority-or-status-form-cell";
import RatingFormCell from "@common-components/tasks-panel/form/form-cell/rating-form-cell";
import TextFormCell from "@common-components/tasks-panel/form/form-cell/text-form-cell";
import TimelineFormCell from "@common-components/tasks-panel/form/form-cell/timeline-form-cell";
import { Task } from "@common-models/task";
import { userDisplayName } from "@common-models/user";
import { _taskColumnOptionSelectOneObjectByQuery, userSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import moment from "moment";
import { AiOutlineLink } from "react-icons/ai";
import { BsCalendar4Event, BsFileEarmarkPlus } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FiAlertCircle, FiAlertTriangle, FiTool } from "react-icons/fi";
import { GiBackwardTime } from "react-icons/gi";
import { IoIosCheckboxOutline } from "react-icons/io";
import { MdOutlineArrowDropDownCircle, MdOutlineMoreTime } from "react-icons/md";
import { SlScreenSmartphone } from "react-icons/sl";
import { TbNumbers, TbStars } from "react-icons/tb";
import { TfiSmallcap } from "react-icons/tfi";
import LinkCell from "../link-cell/link-cell";
import { CompareFunctionOptions, TaskColumnType } from "./cell-interface";
import ChatCell from "./chat-cell/chat-cell";
import CheckboxCell from "./checkbox-cell/checkbox-cell";
import ColumnFooterCell from "./column-footer-cell/column-footer-cell";
import ColumnHeaderBoardView from "./column-header-board-view/column-header-board-view";
import CreatedCell from "./created-cell/created-cell";
import DateCell from "./date-cell/date-cell";
import DropDownCell from "./drop-down-cell/drop-down-cell";
import EditableColumnHeaderCell from "./editable-column-header-cell/editable-column-header-cell";
import FilesCell from "./files-cell/files-cell";
import GroupMenuHeader from "./group-menu-header/group-menu-header";
import LastUpdatedCell from "./last-updated/last-updated-cell";
import NumberCell from "./number-cell/number-cell";
import OptionPickerCell from "./option-picker/option-picker-cell";
import PersonPickerCell, { PersonPickerValue } from "./person-picker-cell/person-picker-cell";
import PhoneNumberCell from "./phone-number-cell/phone-number-cell";
import RatingCell from "./rating-cell/rating-cell";
import RowMenuCell from "./row-menu-cell/row-menu-cell";
import RowPrefixCell from "./row-prefix-cell/row-prefix-cell";
import RowPrefixHeaderBoardView from "./row-prefix-header-board-view/row-prefix-header-board-view";
import RowPrefixHeader from "./row-prefix-header/row-prefix-header";
import RowSuffixCell from "./row-suffix-cell/row-suffix-cell";
import RowSuffixHeader from "./row-suffix-header/row-suffix-header";
import TextCell from "./text-cell/text-cell";
import TimelineCell from "./timeline-cell/timeline-cell";

import BoardCell from "./board-cell/board-cell";
import { ChatScrollingCell } from "./chat-cell/chat-scrolling-cell";
import EmptyCell from "./empty-cell/empty-cell";
import GroupCell from "./group-cell/group-cell";
import PersonPickerScrollingCell from "./person-picker-cell/person-picker-scrolling-cell";
import TextScrollingCell from "./text-cell/text-scrolling-cell";
import { WhatsappIdCell } from "./whatsapp-id-cell/whatsapp-id-cell";
import { WhatsappIdScrollingCell } from "./whatsapp-id-cell/whatsapp-id-scrolling-cell";

export const columnTypeList: TaskColumnType[] = [
    {
        type: 'context-menu',
        title: 'context-menu',
        private: true,
        cellComponent: RowMenuCell,
        scrollingCellComponent: RowMenuCell, // RowMenuScrollingCell.
        headerComponent: GroupMenuHeader,
        headerComponentBoardView: GroupMenuHeader,
    },
    {
        type: 'row-prefix',
        title: 'row-prefix',
        private: true,
        cellComponent: RowPrefixCell,
        headerComponent: RowPrefixHeader,
        headerComponentBoardView: RowPrefixHeaderBoardView,
        cellValueFunction: (task: Task) => task?.text,
        scrollingCellComponent: RowPrefixCell, //RowPrefixScrollingCell,
    },
    {
        type: 'chat',
        title: 'Chat',
        private: true,
        width: 40,
        cellComponent: ChatCell,
        scrollingCellComponent: ChatScrollingCell,
        headerComponentBoardView: ColumnHeaderBoardView,


    },
    {
        type: 'row-suffix',
        title: 'row-suffix',
        private: true,
        cellComponent: RowSuffixCell,
        headerComponent: RowSuffixHeader,
    },
    {
        type: 'person-picker',
        title: 'Person',
        icon: CgProfile,
        iconAccent: '#53bdeb',
        cellComponent: PersonPickerCell,
        scrollingCellComponent: PersonPickerScrollingCell,
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        headerComponentBoardView: ColumnHeaderBoardView,


        compareFunction: (a: PersonPickerValue, b: PersonPickerValue, options: CompareFunctionOptions) => {
            let aValue;
            if (a && a[0]?.type === 'user') {
                const user = userSelectOneObjectByQuery(options.state.DBBoardUserReducer, { _id: a[0].id })
                aValue = userDisplayName(user);
            } else if (a && a[0]?.type === 'option') {
                const option = _taskColumnOptionSelectOneObjectByQuery(options.state.DBTaskColumnOptionReducer, { _id: a[0].id })
                aValue = option.label;
            }
            let bValue;
            if (b && b[0]?.type === 'user') {
                const user = userSelectOneObjectByQuery(options.state.DBBoardUserReducer, { _id: b[0].id })
                bValue = userDisplayName(user);
            } else if (b && b[0]?.type === 'option') {
                const option = _taskColumnOptionSelectOneObjectByQuery(options.state.DBTaskColumnOptionReducer, { _id: b[0].id })
                bValue = option.label;
            }

            if (aValue === undefined) return options.sortOrder === 'asc' ? -1 : 1;
            if (bValue === undefined) return options.sortOrder === 'asc' ? 1 : -1;

            return options.sortOrder === 'asc' ?
                aValue.localeCompare(bValue) :
                bValue.localeCompare(aValue);
        },
        width: 100,
        footerComponent: ColumnFooterCell

    },
    {
        type: 'option-picker',
        title: 'Priority',
        icon: FiAlertCircle,
        iconAccent: '#ff642e',
        cellComponent: OptionPickerCell,
        formComponentEdit: PriorityStatusFormCell,
        formComponentFill: PriorityStatusFormCell,
        // isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        headerComponentBoardView: ColumnHeaderBoardView,


        compareFunction: (optionIdA, optionIdB, options) => {
            const optionA = (options?.columnOptions ?? []).find(option => option._id === optionIdA);
            const optionB = (options?.columnOptions ?? []).find(option => option._id === optionIdB);

            const orderA = optionA?.order ?? Infinity;
            const orderB = optionB?.order ?? Infinity;

            return options.sortOrder === 'asc' ? orderA - orderB : orderB - orderA;
        },
        defaultOptions: [
            {
                label: 'High',
                bgcolor: '#e2445c',
                order: 1,
            },
            {
                label: 'Medium',
                bgcolor: '#fdab3d',
                order: 2,
            },
            {
                label: 'Low',
                bgcolor: '#579bfc',
                order: 3,
            },
            {
                label: '',
                bgcolor: 'var(--empty-picker-cell)',
                order: 4,
                isDefaultLabel: true,
            },
        ],
        footerComponent: ColumnFooterCell

    },
    {
        type: 'complexity-picker',
        title: 'Complexity',
        icon: FiTool,
        iconAccent: '#d32626',
        cellComponent: OptionPickerCell,
        // formComponentEdit: PriorityStatusFormCell,
        // formComponentFill: PriorityStatusFormCell,
        // isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (optionIdA, optionIdB, options) => {
            const optionA = (options?.columnOptions ?? []).find(option => option._id === optionIdA);
            const optionB = (options?.columnOptions ?? []).find(option => option._id === optionIdB);

            const orderA = optionA?.order ?? Infinity;
            const orderB = optionB?.order ?? Infinity;

            return options.sortOrder === 'asc' ? orderA - orderB : orderB - orderA;
        },
        defaultOptions: [
            {
                label: 'Easy',
                bgcolor: '#cddd39',
                complexity: 1,
                order: 1,
            },
            {
                label: 'Medium',
                bgcolor: '#ff9800',
                complexity: 25,
                order: 2,
            },
            {
                label: 'Hard',
                bgcolor: '#f44336',
                complexity: 50,
                order: 3,
            },
            {
                label: 'Difficult',
                bgcolor: '#464646',
                complexity: 100,
                order: 4,
            },
        ],
        footerComponent: ColumnFooterCell

    },
    {
        type: 'status-option-picker',
        title: 'Status',
        icon: FiAlertTriangle,
        iconAccent: '#00c875',
        cellComponent: OptionPickerCell,
        formComponentEdit: PriorityStatusFormCell,
        formComponentFill: PriorityStatusFormCell,
        isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        headerComponentBoardView: ColumnHeaderBoardView,


        compareFunction: (optionIdA, optionIdB, options) => {
            const optionA = (options?.columnOptions ?? []).find(option => option._id === optionIdA);
            const optionB = (options?.columnOptions ?? []).find(option => option._id === optionIdB);

            const orderA = optionA?.order ?? Infinity;
            const orderB = optionB?.order ?? Infinity;

            return options.sortOrder === 'asc' ? orderA - orderB : orderB - orderA;
        },
        defaultOptions: [
            {
                label: 'Done',
                bgcolor: '#00c875',
                order: 1,
            },
            {
                label: 'Working On It',
                bgcolor: '#fdab3d',
                order: 2,
            },
            {
                label: 'Stuck',
                bgcolor: '#e2445c',
                order: 3,
            },
            {
                label: '',
                bgcolor: 'var(--empty-picker-cell)',
                order: 4,
                isDefaultLabel: true,
            },
        ],
        footerComponent: ColumnFooterCell

    },
    {
        type: 'number',
        title: 'Number',
        icon: TbNumbers,
        iconAccent: '#784bd1',
        cellComponent: NumberCell,
        headerComponent: EditableColumnHeaderCell,
        formComponentEdit: NumberFormCell,
        formComponentFill: NumberFormCell,
        isCellInForms: true,
        footerComponent: ColumnFooterCell
    },
    {
        type: 'datepicker',
        title: 'Date',
        icon: BsCalendar4Event,
        iconAccent: '#9cd326',
        cellComponent: DateCell,
        formComponentEdit: DateFormCell,
        formComponentFill: DateFormCell,
        isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        headerComponentBoardView: ColumnHeaderBoardView,


        compareFunction: (a: Date, b: Date, options: CompareFunctionOptions) => {
            return options.sortOrder === 'asc' ?
                moment(a).diff(b) :
                moment(b).diff(a);
        },
        width: 120,
    },
    {
        type: 'files',
        title: 'Files',
        icon: BsFileEarmarkPlus,
        iconAccent: '#579bfc',
        cellComponent: FilesCell,
        // formComponentEdit: FileFormCell,
        // formComponentFill: FileFormCell,
        // isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (a, b, options: CompareFunctionOptions) => {
            a = a?.length || 0
            b = b?.length || 0
            return options.sortOrder === 'asc' ?
                a - b :
                b - a;
        }
    },
    {
        type: 'checkbox',
        title: 'Checkbox',
        icon: IoIosCheckboxOutline,
        iconAccent: '#fdab3d',
        cellComponent: CheckboxCell,
        formComponentEdit: CheckboxFormCell,
        formComponentFill: CheckboxFormCell,
        isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
    },
    {
        type: 'rating',
        title: 'Rating',
        icon: TbStars,
        iconAccent: '#ffcb00',
        cellComponent: RatingCell,
        formComponentEdit: RatingFormCell,
        formComponentFill: RatingFormCell,
        isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (a: number, b: number, options: CompareFunctionOptions) => {
            a = a || 0
            b = b || 0
            return options.sortOrder === 'asc' ?
                a - b :
                b - a;
        }
    },
    {
        type: 'created',
        title: 'Created',
        icon: MdOutlineMoreTime,
        iconAccent: '#401694',
        cellComponent: CreatedCell,
        // formComponentEdit: Irrelevant
        // formComponentFill: Irrelevant
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        cellValueFunction: (task: Task) => task?.createdAt,
        compareFunction: (a: Date, b: Date, options: CompareFunctionOptions) => {
            return options.sortOrder === 'asc' ?
                moment(a).diff(b) :
                moment(b).diff(a);
        }
    },
    {
        type: 'last-updated',
        title: 'Last Updated',
        icon: GiBackwardTime,
        iconAccent: '#7e3b8a',
        cellComponent: LastUpdatedCell,
        // formComponentEdit: Irrelevant
        // formComponentFill: Irrelevant
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        cellValueFunction: (task: Task) => task?.updatedAt,
        compareFunction: (a: Date, b: Date, options: CompareFunctionOptions) => {
            return options.sortOrder === 'asc' ?
                moment(a).diff(b) :
                moment(b).diff(a);
        }
    },
    {
        type: 'drop-down',
        title: 'Dropdown',
        icon: MdOutlineArrowDropDownCircle,
        iconAccent: '#ff158a',
        cellComponent: DropDownCell,
        // formComponentEdit: Irrelevant
        // formComponentFill: Irrelevant
        isCellInForms: false, ///Waiting for the feature to be complete
        headerComponent: EditableColumnHeaderCell,
    },
    {
        type: 'link',
        title: 'Link',
        icon: AiOutlineLink,
        iconAccent: '#0086c0',
        cellComponent: LinkCell,
        // formComponentEdit: LinkCell,
        // formComponentFill: LinkCell,
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (a, b, options: CompareFunctionOptions) => {
            a = ((a?.linkText || a?.link) ?? "");
            b = ((b?.linkText || b?.link) ?? "");
            return options.sortOrder === 'asc' ?
                a?.localeCompare(b) :
                b?.localeCompare(a);
        }
    },
    {
        type: 'text-cell',
        title: 'Text',
        icon: TfiSmallcap,
        iconAccent: '#264573',
        cellComponent: TextCell,
        scrollingCellComponent: TextScrollingCell,
        headerComponent: EditableColumnHeaderCell,
        formComponentEdit: TextFormCell,
        formComponentFill: TextFormCell,
        isCellInForms: true,
        compareFunction: (a, b, options: CompareFunctionOptions) => {
            a = a || null
            b = b || null
            return options.sortOrder === 'asc' ?
                a?.localeCompare(b) :
                b?.localeCompare(a);
        }
    },
    {
        type: 'phone-cell',
        title: 'Phone',
        icon: SlScreenSmartphone,
        iconAccent: '#037f4c',
        cellComponent: PhoneNumberCell,
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (a, b, options: CompareFunctionOptions) => {
            a = a || null
            b = b || null
            return options.sortOrder === 'asc' ?
                a?.localeCompare(b) :
                b?.localeCompare(a);
        }
    },
    {
        type: 'whatsapp-id-cell',
        title: 'Contact',
        icon: SlScreenSmartphone,
        iconAccent: '#037f4c',
        cellComponent: WhatsappIdCell,
        scrollingCellComponent: WhatsappIdScrollingCell,
        // private: true,
        // static: true,
        isCellInForms: false,
        headerComponent: EditableColumnHeaderCell,
        headerComponentBoardView: ColumnHeaderBoardView,
        compareFunction: (a, b, options: CompareFunctionOptions) => {
            a = a || null
            b = b || null
            return options.sortOrder === 'asc' ?
                a?.localeCompare(b) :
                b?.localeCompare(a);
        }
    },

    {
        type: 'timeline-picker',
        title: 'Timeline',
        icon: BsCalendar4Event,
        iconAccent: '#9cd326',
        cellComponent: TimelineCell,
        formComponentEdit: TimelineFormCell,
        formComponentFill: TimelineFormCell,
        isCellInForms: true,
        headerComponent: EditableColumnHeaderCell,
        compareFunction: (a: Date, b: Date, options: CompareFunctionOptions) => {
            return options.sortOrder === 'asc' ?
                moment(a).diff(b) :
                moment(b).diff(a);
        }
    },

    {
        type: 'group-cell',
        title: 'Group',
        private: true,
        icon: TfiSmallcap,
        iconAccent: '#264573',
        cellComponent: GroupCell,
        headerComponentBoardView: ColumnHeaderBoardView,

    },
    {
        type: 'board-cell',
        title: 'board',
        private: true,
        icon: TfiSmallcap,
        iconAccent: '#264573',
        cellComponent: BoardCell,
        headerComponentBoardView: ColumnHeaderBoardView,

    },
    {
        type: 'empty-cell',
        title: '',
        private: true,
        icon: TfiSmallcap,
        iconAccent: '#264573',
        cellComponent: EmptyCell,
        headerComponentBoardView: ColumnHeaderBoardView,

    },
    // {
    //     type: 'time-tracker',
    //     title: 'Time Tracker',
    //     icon: FiWatch,
    //     iconAccent: '#9dcec7',
    //     cellComponent: TimeTrackerCell,
    //     headerComponent: EditableColumnHeaderCell,
    //     // compareFunction: (a: Date, b: Date, options: CompareFunctionOptions) => {
    //     //     return options.sortOrder === 'asc' ?
    //     //         moment(a).diff(b) :
    //     //         moment(b).diff(a);\
    // }

];


// create map of column types bt type
export const columnTypeMap = new Map<string, TaskColumnType>();
columnTypeList.forEach((columnType) => {
    columnTypeMap.set(columnType.type, columnType);
});



export const getIconByType = (type) => {
    // let iconType;
    // if (column && column.length >= 2) {
    //   iconType = column[1]
    // }
    console.log(type)
    if (!type) return;
  
    let icon = columnTypeList.find(ct => ct.type === type)?.icon;
    return icon ? icon({ color: 'var(--primary)!important', size: 20 }) : undefined;
  }