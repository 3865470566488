import { CircularProgress, Stack, Typography } from '@mui/material';

type Props = {
    text?: string;
    color?: "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning";
}

export default function CircularProgressWithText({ text, color }: Props) {


    return (
        <Stack style={{ width: '400px', }} justifyContent={"center"} alignItems={"center"}>
            <Typography
                marginBottom={"10px"}
                component="span"
                fontWeight="bold"
                style={{
                    color: 'var(--primary)',
                    marginBottom: '10px',
                    textAlign: 'center'
                }} // Added margin for spacing
            >
                {text}
            </Typography>
            <CircularProgress color={color ?? "success"} />
        </Stack>
    )
}