import { memo, useState } from "react";
import classes from "./checkbox-form-cell.module.scss";
import { Box } from "@mui/material";
import Checkbox from "@common-components/tasks/custom-checkbox/checkbox";
import { TasksGroup } from "@common-models/task-group";
import { taskgroupsSelectors } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { CellProps } from "@common-components/tasks/columns-cells/cell-interface";

function CheckboxFormCell({ cellValue, groupId, onUpdate, isFormInEditMode }: CellProps) {
  const [isChecked, setCheckbox] = useState<boolean>(false);
  const tasksGroup: TasksGroup = useAppSelector((state) => {
    return taskgroupsSelectors.selectById(state, groupId)
  }
  );
  function updateCheckbox(e) {
    setCheckbox(!isChecked);
    onUpdate(!isChecked);
  }

  return (
    < div className={classes.checkboxCell} >
      <Box onClick={updateCheckbox}>
        <Checkbox checked={isFormInEditMode ? false : (isChecked || cellValue)} color={tasksGroup?.colorAccent} />
      </Box>
    </div>

  );
}


export default memo(CheckboxFormCell);