import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { logsSelectByQuery, tasksSelectByQuery } from "@common-reducers/DBServiceThunks";

import { getCurrentBoard } from "./BoardsSelectors";
import { CommonRootState } from "@common/types/common-root-state-type";



export const getSortedBoardLogs = createDeepEqualSelector(
    [
        (state) => state
    ],
    (state) => {
        const board = getCurrentBoard(state)

        const boardLogs = logsSelectByQuery(state.DBLogReducer, { boardId: board?._id, })

        const createBoard = boardLogs.find(log => (log?.logUpdateType as any) === 'new board')
        const _boardLogs = [createBoard, ...boardLogs.filter(log => log?._id !== createBoard?._id)]
            .sort((a, b) => {
                const aDate = new Date(a.createdAt)
                const bDate = new Date(b.createdAt)

                const aSeconds = Math.floor(aDate.getTime())
                const bSeconds = Math.floor(bDate.getTime())
                return bSeconds - aSeconds
            })

        return _boardLogs
    }
);

export const getBoardLogByIndex = createDeepEqualSelector(
    [
        (state: CommonRootState, index) => getSortedBoardLogs(state),
        (state: CommonRootState, index) => index,
    ],
    (boardLogs, index) => {
        return boardLogs[index]
    }
);