import { InputAdornment, OutlinedInput, OutlinedInputProps, } from "@mui/material";
import React from "react";
export interface BTInputFieldProps extends OutlinedInputProps {
    sx?: any;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
}
export const BTInputField = React.forwardRef((props: BTInputFieldProps, ref: any) => {
    const { startAdornment, endAdornment, ...inputProps } = props;

    return (
        <OutlinedInput
            startAdornment={startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null}
            endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null}
            {...inputProps}

            sx={{
                ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid var(--search-input-border)",
                },
                "& input::placeholder": {
                    color: "var(--input-placeholder-color)",
                    opacity: '1',
                    fontWeight: '400',
                },
                "&.Mui-focused": {
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid var(--search-input-border)",
                        boxShadow: ' 0 5px 12px rgb(0 0 0 / 15%)',
                    },
                },
                "&: hover": {
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid var(--search-input-border-hover)",
                        boxShadow: ' 0 5px 12px rgb(0 0 0 / 15%)',
                    },
                },
                fontWeight: '500',
                borderRadius: '8px',
                background: '#fff',
                fontSize: '14px',
                height: '36px',
                padding: '0 8px',
                margin: '0',
                color: '#706b6b',
                outline: 'none',
                ...props.sx,
            }}
            ref={ref}
        />

    );
});
export default BTInputField;
