import { ViewFilter } from "@common-components/tasks-panel/filter-button/advanced_filters";
import { ExtraDataFile, ExtraDataItem } from "@common-models/ExtraDataItem";
import { Automation, AutomationActions, AutomationConditions, AutomationTrigger } from "@common-models/automation";
import { Board } from "@common-models/board";
import { BlueticksBoardUser } from "@common-models/board-user";
import { BoardView } from "@common-models/board-views";
import { Bot } from "@common-models/bot";
import { BotNode } from "@common-models/bot-node";
import { ContactLabel } from "@common-models/contact-label";
import { BotEdge } from "@common-models/edge";
import { EntitySubscriber } from "@common-models/entity-subscriber";
import { Feature } from "@common-models/feature";
import { BlueticksForm } from "@common-models/form";
import { Gantt } from "@common-models/gantt";
import { Handle } from "@common-models/handle";
import { Invoice } from "@common-models/invoice";
import { Kanban } from "@common-models/kanban";
import { _Label } from "@common-models/label";
import { Log } from "@common-models/logs";
import { MessageLog } from "@common-models/message-log";
import { MyWork } from "@common-models/mywork";
import { NewTabFocus } from "@common-models/new-tab-focus";
import { Notification } from "@common-models/notification";
import { QuickLaunch } from "@common-models/quick-launch";
import { RecycledItem } from "@common-models/recycle-items";
import { Subscription } from "@common-models/subscription";
import { Task } from "@common-models/task";
import { TaskColumn } from "@common-models/task-column";
import { TaskColumnOption } from "@common-models/task-column-option";
import { TasksGroup } from "@common-models/task-group";
import { TimeTrackingSession } from "@common-models/time-tracking-session";
import { Tracing } from "@common-models/tracing";
import { UserInterface } from "@common-models/user";
import { UserMessage } from "@common-models/user-message";
import { WaChat } from "@common-models/waChat";
import { WaContact } from "@common-models/waContact";
import { WASession } from "@common-models/wasession";
import { Webhook } from "@common-models/webhook";
import { Workload } from "@common-models/workload";
import { Workspace } from "@common-models/workspace";
import { BlueticksWorkspaceUser } from "@common-models/workspace-user";
import { Chat } from "@wa-communication-layer/whatsapp";

import { BulkList } from "@common/models/bulk-list";
import { Campaign } from "@common/models/campaign-model";
import { ContactInfo } from "@common/models/contact-info";
import { Template } from "@common/models/template";
import { CommonRootState } from "@common/types/common-root-state-type";
import { NullableId } from "@common/types/interface";
import {
  ActionReducerMapBuilder,
  EntityId,
  Selector,
  createAction,
  createSelector
} from "@reduxjs/toolkit";
import sift from "sift";
import { _DBReducerMap, createDBServiceSlice } from "@common-reducers/DBServiceReducers";
import { SnippetHooks } from "./hooks/snippet.hook";


// Lists Reducer
export const {
  slice: BulkListSlice,
  dbThunks: _bulkListThunks,
  selectByQuery: _bulkListSelectByQuery,
  selectOneObjectByQuery: _bulkListSelectOneObjectByQuery,
  selectPaginationStatus: _bulkListSelectPaginationStatus,
} = createDBServiceSlice<BulkList>({
  service: "lists",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBulkListReducer,
});
export const DBBulkListReducer = BulkListSlice.reducer;
export const bulkListReducerActions = BulkListSlice.actions;
export type BulkListReducerActions = keyof typeof bulkListReducerActions;
export const DBBulkListThunks = _bulkListThunks;
export const bulkListSelectByQuery = _bulkListSelectByQuery;
export const BulkListSelectPaginationStatus = _bulkListSelectPaginationStatus;

// Contact Info Reducer
export const {
  slice: ContactSlice,
  dbThunks: _contactThunks,
  selectByQuery: _contactSelectByQuery,
  selectOneObjectByQuery: _contactSelectOneObjectByQuery,
} = createDBServiceSlice<ContactInfo>({
  service: "contacts",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBContactReducer,
});
export const DBContactReducer = ContactSlice.reducer;
export const contactReducerActions = ContactSlice.actions;
export type ContactReducerActions = keyof typeof contactReducerActions;
export const DBContactThunks = _contactThunks;
export const contactSelectByQuery = _contactSelectByQuery;

// Template Reducer
export const {
  slice: TemplateSlice,
  dbThunks: _templateThunks,
  selectByQuery: _templateSelectByQuery,
  selectOneObjectByQuery: _templateSelectOneObjectByQuery,
  selectors: _templatesSelectors,
} = createDBServiceSlice<Template>({
  service: "templates",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTemplateReducer,
  hooks: SnippetHooks
});
export const DBTemplateReducer = TemplateSlice.reducer;
export const templateReducerActions = TemplateSlice.actions;
export type TemplateReducerActions = keyof typeof templateReducerActions;
export const DBTemplateThunks = _templateThunks;
export const templateSelectByQuery = _templateSelectByQuery;
export const templateSelectors = _templatesSelectors;
export const templateSelectOneObjectByQuery = _templateSelectOneObjectByQuery;


// Campaigns Reducer
export const {
  slice: CampaignSlice,
  dbThunks: _campaignThunks,
  selectByQuery: _campaignsSelectByQuery,
  selectOneObjectByQuery: _campaignsSelectOneObjectByQuery,
  selectors: _campaignSelectors,
} = createDBServiceSlice<Campaign>({
  service: "campaigns",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBCampaignReducer,
  // customReducers: (builder) => {
  //   builder.addMatcher(
  //     backendApi.endpoints.startCampaign.matchFulfilled,
  //     (state, { payload }) => {
  //       if (payload.campaignMessages?.length > 0) {
  //       }
  //     }
  //   );
  // },
});
export const DBCampaignReducer = CampaignSlice.reducer;
export const campaignReducerActions = CampaignSlice.actions;
export type CampaignReducerActions = keyof typeof campaignReducerActions;
export const DBCampaignThunks = _campaignThunks;
export const campaignSelectors = _campaignSelectors;
export const campaignsSelectByQuery = _campaignsSelectByQuery;
export const campaignsSelectOneObjectByQuery = _campaignsSelectOneObjectByQuery;


// Message Log Reducer
export const {
  slice: MessageLogSlice,
  dbThunks: _messageLogThunks,
  selectByQuery: _messageLogSelectByQuery,
  selectOneObjectByQuery: _messageLogSelectOneObjectByQuery,
} =
  createDBServiceSlice<MessageLog>({
    service: "message-logs",
    initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
    getReducerState: (state) => state.DBMessageLogReducer,
    //hooks: UserMessageHooks,
  });
export const DBMessageLogReducer = MessageLogSlice.reducer;
export const messageLogReducerActions = MessageLogSlice.actions;
export type MessageLogReducerActions = keyof typeof messageLogReducerActions;
export const DBMessageLogThunks = _messageLogThunks;
export const messageLogSelectByQuery = _messageLogSelectByQuery;
export const messageLogSelectOneObjectByQuery = _messageLogSelectOneObjectByQuery;


// Taskgroups Reducer
export const {
  slice: TaskgroupsSlice,
  dbThunks: _taskgroupsThunks,
  selectByQuery: _taskGroupsSelectByQuery,
  selectOneObjectByQuery: _taskGroupsSelectOneObjectByQuery,
  selectOneFieldByQuery: _taskGroupsSelectOneFieldByQuery,
  selectByQueryOnlyLength: _taskGroupsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskGroupsSelectByQueryOnlyMaxMin,
  selectOneObjectById: _taskGroupsSelectOneObjectById,
  selectOneFieldById: _taskGroupsSelectOneFieldById,
  selectors: _taskgroupsSelectors,
} = createDBServiceSlice<TasksGroup>({
  service: "task-groups",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskgroupsReducer,
});
export const DBTaskgroupsReducer = TaskgroupsSlice.reducer;

export const taskgroupsReducerActions = TaskgroupsSlice.actions;
export type TaskgroupsReducerActions = keyof typeof taskgroupsReducerActions;
export const DBTaskgroupsThunks = _taskgroupsThunks;
export const taskgroupsSelectors = _taskgroupsSelectors;
export const taskGroupsSelectByQuery = _taskGroupsSelectByQuery;
export const taskGroupsSelectOneFieldByQuery = _taskGroupsSelectOneFieldByQuery;
export const taskGroupsSelectOneObjectByQuery = _taskGroupsSelectOneObjectByQuery;
export const taskGroupsSelectByQueryOnlyLength = _taskGroupsSelectByQueryOnlyLength;
export const taskGroupsSelectByQueryOnlyMaxMin = _taskGroupsSelectByQueryOnlyMaxMin;
export const taskGroupsSelectOneObjectById = _taskGroupsSelectOneObjectById;
export const taskGroupsSelectOneFieldById = _taskGroupsSelectOneFieldById;


// Boards Reducer
export const {
  slice: BoardsSlice,
  dbThunks: _BoardsThunks,
  selectByQuery: _boardsSelectByQuery,
  selectOneObjectByQuery: _boardsSelectOneObjectByQuery,
  selectOneFieldByQuery: _boardsSelectOneFieldByQuery,
  selectByQueryOnlyLength: _boardsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardsSelectByQueryOnlyMaxMin,
  selectPaginationStatus: _boardsSelectPaginationStatus,
  selectOneObjectById: _boardsSelectOneObjectById,
  selectOneFieldById: _boardsSelectOneFieldById,
  selectors: _boardSelectors,
} = createDBServiceSlice<Board>({
  service: "boards",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardsReducer,
});
export const DBBoardsReducer = BoardsSlice.reducer;
export const boardsReducerActions = BoardsSlice.actions;
export type BoardsReducerActions = keyof typeof boardsReducerActions;
export const DBBoardsThunks = _BoardsThunks;
export const boardsSelectors = _boardSelectors;
export const boardsSelectByQuery = _boardsSelectByQuery;
export const boardsSelectOneObjectByQuery = _boardsSelectOneObjectByQuery;
export const boardsSelectOneFieldByQuery = _boardsSelectOneFieldByQuery;
export const boardsSelectByQueryOnlyLength = _boardsSelectByQueryOnlyLength;
export const boardsSelectByQueryOnlyMaxMin = _boardsSelectByQueryOnlyMaxMin;
export const BoardsSelectPaginationStatus = _boardsSelectPaginationStatus;
export const boardsSelectOneObjectById = _boardsSelectOneObjectById;
export const boardsSelectOneFieldById = _boardsSelectOneFieldById;


// Tasks Reducer
export const {
  slice: TasksSlice,
  dbThunks: _TasksThunks,
  selectByQuery: _tasksSelectByQuery,
  selectOneObjectByQuery: _tasksSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksSelectOneFieldByQuery,
  selectByQueryOnlyLength: _tasksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _tasksSelectByQueryOnlyMaxMin,
  selectOneObjectById: _tasksSelectOneObjectById,
  selectOneFieldById: _tasksSelectOneFieldById,
  selectors: _tasksSelectors,
} = createDBServiceSlice<Task>({
  service: "tasks",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksReducer,
});
export const DBTasksReducer = TasksSlice.reducer;
export const tasksReducerActions = TasksSlice.actions;
export type TasksReducerActions = keyof typeof tasksReducerActions;
export const DBTasksThunks = _TasksThunks;
export const tasksSelectors = _tasksSelectors;
export const tasksSelectByQuery = _tasksSelectByQuery;
export const tasksSelectOneFieldByQuery = _tasksSelectOneFieldByQuery;
export const tasksSelectOneObjectByQuery = _tasksSelectOneObjectByQuery;
export const tasksSelectByQueryOnlyLength = _tasksSelectByQueryOnlyLength;
export const tasksSelectByQueryOnlyMaxMin = _tasksSelectByQueryOnlyMaxMin;
export const tasksSelectOneObjectById = _tasksSelectOneObjectById;
export const tasksSelectOneFieldById = _tasksSelectOneFieldById;


// ExtraData Reducer
export const {
  slice: TasksExtraDataSlice,
  dbThunks: _TasksExtraDataThunks,
  selectByQuery: _tasksExtraDataSelectByQuery,
  selectOneObjectByQuery: _tasksExtraDataSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksExtraDataSelectOneFieldByQuery,
  selectors: _tasksExtraDataSelectors,
} = createDBServiceSlice<ExtraDataItem>({
  service: "tasks-extra-data",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksExtraDataReducer,
});
export const DBTasksExtraDataReducer = TasksExtraDataSlice.reducer;
export const TasksExtraDataReducerActions = TasksExtraDataSlice.actions;
export type tasksExtraDataReducerActions = keyof typeof TasksExtraDataReducerActions;
export const DBTasksExtraDataThunks = _TasksExtraDataThunks;
export const tasksExtraDataSelectors = _tasksExtraDataSelectors;
export const tasksExtraDataSelectByQuery = _tasksExtraDataSelectByQuery;


// TasksExtraDataFiles Reducer
export const {
  slice: TasksExtraDataFilesSlice,
  dbThunks: _TasksExtraDataFilesThunks,
  selectByQuery: _tasksExtraDataFilesSelectByQuery,
  selectOneObjectByQuery: _tasksExtraDataFilesSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksExtraDataFilesSelectOneFieldByQuery,
  selectors: _tasksExtraDataFilesSelectors,
} = createDBServiceSlice<ExtraDataFile>({
  service: "tasks-extra-data-files",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksExtraDataFilesReducer,
});

export const DBTasksExtraDataFilesReducer = TasksExtraDataFilesSlice.reducer;
export const TasksExtraDataFilesReducerActions = TasksExtraDataFilesSlice.actions;
export type tasksExtraDataFilesReducerActions = keyof typeof TasksExtraDataFilesReducerActions;
export const DBTasksExtraDataFilesThunks = _TasksExtraDataFilesThunks;
export const tasksExtraDataFilesSelectors = _tasksExtraDataFilesSelectors;
export const tasksExtraDataFilesSelectByQuery = _tasksExtraDataFilesSelectByQuery;

// Label Reducer
export const {
  slice: LabelSlice,
  dbThunks: _LabelThunks,
  selectByQuery: _labelSelectByQuery,
  selectOneObjectByQuery: _labelSelectOneObjectByQuery,
  selectOneFieldByQuery: _labelSelectSelectOneFieldByQuery,
  selectors: _labelSelectors,
  selectByQueryOnlyMaxMin: _selectByQueryOnlyMaxMin
} = createDBServiceSlice<_Label>({
  service: "labels",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBLabelReducer,
});

export const DBLabelReducer = LabelSlice.reducer;
export const LabelReducerActions = LabelSlice.actions;
export type labelReducerActions = keyof typeof LabelReducerActions;
export const DBLabelThunks = _LabelThunks;
export const labelSelectors = _labelSelectors;
export const labelSelectByQuery = _labelSelectByQuery;
export const labelSelectByQueryOnlyMaxMin = _selectByQueryOnlyMaxMin;
export const labelSelectOneObjectByQuery = _labelSelectOneObjectByQuery;


// conatct Label Reducer
// ContactsLabels Reducer
export const {
  slice: ContactsLabelsSlice,
  dbThunks: _ContactsLabelsThunks,
  selectByQuery: _contactsLabelsSelectByQuery,
  selectOneObjectByQuery: _contactsLabelsSelectOneObjectByQuery,
  selectOneFieldByQuery: _contactsLabelsSelectOneFieldByQuery,
  selectors: _contactsLabelsSelectors,
  selectByQueryOnlyLength: _contactsLabelssSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _contactsLabelssSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<ContactLabel>({
  service: "contacts-labels",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBContactsLabelsReducer,
});
export const DBContactsLabelsReducer = ContactsLabelsSlice.reducer;
export const ContactsLabelsReducerActions = ContactsLabelsSlice.actions;
export type contactsLabelsReducerActions = keyof typeof ContactsLabelsReducerActions;
export const DBContactsLabelsThunks = _ContactsLabelsThunks;
export const contactsLabelsSelectors = _contactsLabelsSelectors;
export const contactsLabelsSelectByQuery = _contactsLabelsSelectByQuery;
export const contactsLabelsSelectOneObjectByQuery = _contactsLabelsSelectOneObjectByQuery;
export const contactsLabelsSelectOneFieldByQuery = _contactsLabelsSelectOneFieldByQuery;
export const contactsLabelssSelectByQueryOnlyLength = _contactsLabelssSelectByQueryOnlyLength;
export const contactsLabelssSelectByQueryOnlyMaxMin = _contactsLabelssSelectByQueryOnlyMaxMin;


// Invoice Reducer
export const {
  slice: InvoiceSlice,
  dbThunks: _InvoiceThunks,
  selectByQuery: _invoiceSelectByQuery,
  selectOneObjectByQuery: _invoiceSelectOneObjectByQuery,
  selectOneFieldByQuery: _invoiceSelectOneFieldByQuery,
  selectors: _invoiceSelectors,
} = createDBServiceSlice<Invoice>({
  service: "invoices",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBInvoiceReducer,
});
export const DBInvoiceReducer = InvoiceSlice.reducer;
export const InvoiceReducerActions = InvoiceSlice.actions;
export type invoiceReducerActions = keyof typeof InvoiceReducerActions;
export const DBInvoiceThunks = _InvoiceThunks;
export const invoiceSelectors = _invoiceSelectors;
export const invoiceSelectByQuery = _invoiceSelectByQuery;

// TaskColumn Reducer
export const {
  slice: TaskColumnSlice,
  dbThunks: _TaskColumnThunks,
  selectByQuery: _taskColumnSelectByQuery,
  selectOneObjectByQuery: _taskColumnSelectOneObjectByQuery,
  selectOneFieldByQuery: _taskColumnSelectOneFieldByQuery,
  selectors: _taskColumnSelectors,
  selectByQueryOnlyLength: _taskColumnsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskColumnsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<TaskColumn>({
  service: "task-columns",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskColumnReducer,
});
export const DBTaskColumnReducer = TaskColumnSlice.reducer;
export const TaskColumnReducerActions = TaskColumnSlice.actions;
export type taskColumnReducerActions = keyof typeof TaskColumnReducerActions;
export const DBTaskColumnThunks = _TaskColumnThunks;
export const taskColumnSelectors = _taskColumnSelectors;
export const taskColumnSelectByQuery = _taskColumnSelectByQuery;
export const taskColumnSelectOneObjectByQuery = _taskColumnSelectOneObjectByQuery;
export const taskColumnSelectOneFieldByQuery = _taskColumnSelectOneFieldByQuery;
export const taskColumnsSelectByQueryOnlyLength = _taskColumnsSelectByQueryOnlyLength;
export const taskColumnsSelectByQueryOnlyMaxMin = _taskColumnsSelectByQueryOnlyMaxMin;

// Workspace Reducer
export const {
  slice: WorkspaceSlice,
  dbThunks: _WorkspaceThunks,
  selectByQuery: _workspaceSelectByQuery,
  selectOneObjectByQuery: _workspaceSelectOneObjectByQuery,
  selectOneFieldByQuery: _workspaceSelectOneFieldByQuery,
  selectors: _workspaceSelectors,
  selectByQueryOnlyLength: _workspacesSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workspacesSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Workspace>({
  service: "workspaces",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkspaceReducer,
});
export const DBWorkspaceReducer = WorkspaceSlice.reducer;
export const WorkspaceReducerActions = WorkspaceSlice.actions;
export type workspaceReducerActions = keyof typeof WorkspaceReducerActions;
export const DBWorkspaceThunks = _WorkspaceThunks;
export const workspaceSelectors = _workspaceSelectors;
export const workspaceSelectByQuery = _workspaceSelectByQuery;
export const workspaceSelectOneObjectByQuery = _workspaceSelectOneObjectByQuery
export const workspacesSelectByQueryOnlyLength = _workspacesSelectByQueryOnlyLength;
export const workspacesSelectByQueryOnlyMaxMin = _workspacesSelectByQueryOnlyMaxMin;

export function isTempId(id: NullableId) {
  if (id?.toString().length === 16) return true;
}

// TaskColumnOption Reducer
export const {
  slice: TaskColumnOptionSlice,
  dbThunks: _TaskColumnOptionThunks,
  selectByQuery: _taskColumnOptionSelectByQuery,
  selectOneObjectByQuery: _taskColumnOptionSelectOneObjectByQuery,
  selectors: _taskColumnOptionSelectors,
  selectByQueryOnlyLength: _taskColumnOptionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskColumnOptionSelectByQueryOnlyMaxMin,
  selectOneObjectById: _taskColumnOptionSelectOneObjectById,
  selectOneFieldById: _taskColumnOptionSelectOneFieldById,
} = createDBServiceSlice<TaskColumnOption>({
  service: "task-column-options",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskColumnOptionReducer,
});
export const DBTaskColumnOptionReducer = TaskColumnOptionSlice.reducer;
export const TaskColumnOptionReducerActions = TaskColumnOptionSlice.actions;
export type taskColumnOptionReducerActions = keyof typeof TaskColumnOptionReducerActions;
export const DBTaskColumnOptionThunks = _TaskColumnOptionThunks;
export const taskColumnOptionSelectors = _taskColumnOptionSelectors;
export const taskColumnOptionSelectByQuery = _taskColumnOptionSelectByQuery;
export const taskColumnOptionSelectByQueryOnlyLength = _taskColumnOptionSelectByQueryOnlyLength;
export const taskColumnOptionSelectByQueryOnlyMaxMin = _taskColumnOptionSelectByQueryOnlyMaxMin;
export const taskColumnOptionSelectOneObjectById = _taskColumnOptionSelectOneObjectById;
export const taskColumnOptionSelectOneFieldById = _taskColumnOptionSelectOneFieldById;

// Users Reducer
export const {
  slice: UserSlice,
  dbThunks: _UserThunks,
  selectByQuery: _userSelectByQuery,
  selectOneObjectByQuery: _userSelectOneObjectByQuery,
  selectors: _userSelectors,
  selectByQueryOnlyLength: _userSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _userSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<UserInterface>({
  service: "users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBUserReducer,
});
export const DBUserReducer = UserSlice.reducer;
export const UserReducerActions = UserSlice.actions;
export type userReducerActions = keyof typeof UserReducerActions;
export const DBUserThunks = _UserThunks;
export const userSelectors = _userSelectors;
export const userSelectByQuery = _userSelectByQuery;
export const userSelectByQueryOnlyLength = _userSelectByQueryOnlyLength;
export const userSelectByQueryOnlyMaxMin = _userSelectByQueryOnlyMaxMin;
export const userSelectOneObjectByQuery = _userSelectOneObjectByQuery;

// Kanbans Reducer
export const {
  slice: KanbanSlice,
  dbThunks: _KanbanThunks,
  selectByQuery: _kanbanSelectByQuery,
  selectOneObjectByQuery: _kanbanSelectOneObjectByQuery,
  selectOneFieldByQuery: _kanbanSelectOneFieldByQuery,
  selectors: _kanbanSelectors,
  selectByQueryOnlyLength: _kanbanSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _kanbanSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Kanban>({
  service: "kanbans",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBKanbanReducer,
});
export const DBKanbanReducer = KanbanSlice.reducer;
export const KanbanReducerActions = KanbanSlice.actions;
export type kanbanReducerActions = keyof typeof KanbanReducerActions;
export const DBKanbanThunks = _KanbanThunks;
export const kanbanSelectors = _kanbanSelectors;
export const kanbanSelectByQuery = _kanbanSelectByQuery;
export const kanbanSelectByQueryOnlyLength = _kanbanSelectByQueryOnlyLength;
export const kanbanSelectByQueryOnlyMaxMin = _kanbanSelectByQueryOnlyMaxMin;
export const kanbanSelectOneObjectByQuery = _kanbanSelectOneObjectByQuery;
export const kanbanSelectOneFieldByQuery = _kanbanSelectOneFieldByQuery;


// Workloads Reducer
export const {
  slice: WorkloadSlice,
  dbThunks: _WorkloadThunks,
  selectByQuery: _workloadSelectByQuery,
  selectOneObjectByQuery: _workloadSelectOneObjectByQuery,
  selectOneFieldByQuery: _workloadSelectOneFieldByQuery,
  selectors: _workloadSelectors,
  selectByQueryOnlyLength: _workloadSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workloadSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Workload>({
  service: "workloads",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkloadReducer,
});
export const DBWorkloadReducer = WorkloadSlice.reducer;
export const WorkloadReducerActions = WorkloadSlice.actions;
export type workloadReducerActions = keyof typeof WorkloadReducerActions;
export const DBWorkloadThunks = _WorkloadThunks;
export const workloadSelectors = _workloadSelectors;
export const workloadSelectByQuery = _workloadSelectByQuery;
export const workloadSelectByQueryOnlyLength = _workloadSelectByQueryOnlyLength;
export const workloadSelectByQueryOnlyMaxMin = _workloadSelectByQueryOnlyMaxMin;
export const workloadSelectOneObjectByQuery = _workloadSelectOneObjectByQuery;
export const workloadSelectOneFieldByQuery = _workloadSelectOneFieldByQuery;



// Forms Reducer
export const {
  slice: FormSlice,
  dbThunks: _FormThunks,
  selectByQuery: _formSelectByQuery,
  selectOneObjectByQuery: _formSelectOneObjectByQuery,
  selectors: _formSelectors,
  selectByQueryOnlyLength: _formSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _formSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _formSelectOneFieldByQuery,
} = createDBServiceSlice<BlueticksForm>({
  service: "forms",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBFormReducer,
});
export const DBFormReducer = FormSlice.reducer;
export const FormReducerActions = FormSlice.actions;
export type formReducerActions = keyof typeof FormReducerActions;
export const DBFormThunks = _FormThunks;
export const formSelectors = _formSelectors;
export const formSelectByQuery = _formSelectByQuery;
export const formSelectByQueryOnlyLength = _formSelectByQueryOnlyLength;
export const formSelectByQueryOnlyMaxMin = _formSelectByQueryOnlyMaxMin;
export const formSelectOneObjectByQuery = _formSelectOneObjectByQuery;
export const formSelectOneFieldByQuery = _formSelectOneFieldByQuery;

// Gantts Reducer
export const {
  slice: GanttSlice,
  dbThunks: _GanttThunks,
  selectByQuery: _ganttSelectByQuery,
  selectOneObjectByQuery: _ganttSelectOneObjectByQuery,
  selectOneFieldByQuery: _ganttSelectOneFieldByQuery,
  selectors: _ganttSelectors,
  selectByQueryOnlyLength: _ganttSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _ganttSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Gantt>({
  service: "gantts",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBGanttReducer,
});
export const DBGanttReducer = GanttSlice.reducer;
export const GanttReducerActions = GanttSlice.actions;
export type ganttReducerActions = keyof typeof GanttReducerActions;
export const DBGanttThunks = _GanttThunks;
export const ganttSelectors = _ganttSelectors;
export const ganttSelectByQuery = _ganttSelectByQuery;
export const ganttSelectByQueryOnlyLength = _ganttSelectByQueryOnlyLength;
export const ganttSelectByQueryOnlyMaxMin = _ganttSelectByQueryOnlyMaxMin;
export const ganttSelectOneObjectByQuery = _ganttSelectOneObjectByQuery;
export const ganttSelectOneFieldByQuery = _ganttSelectOneFieldByQuery;


// WorkspaceUsers Reducer
export const {
  slice: WorkspaceUserSlice,
  dbThunks: _WorkspaceUserThunks,
  selectByQuery: _workspaceUserSelectByQuery,
  selectOneObjectByQuery: _workspaceUserSelectOneObjectByQuery,
  selectors: _workspaceUserSelectors,
  selectByQueryOnlyLength: _workspaceUserSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workspaceUserSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BlueticksWorkspaceUser>({
  service: "workspace-users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkspaceUserReducer,
});
export const DBWorkspaceUserReducer = WorkspaceUserSlice.reducer;
export const WorkspaceUserReducerActions = WorkspaceUserSlice.actions;
export type workspaceUserReducerActions = keyof typeof WorkspaceUserReducerActions;
export const DBWorkspaceUserThunks = _WorkspaceUserThunks;
export const workspaceUserSelectors = _workspaceUserSelectors;
export const workspaceUserSelectByQuery = _workspaceUserSelectByQuery;
export const workspaceUserSelectByQueryOnlyLength = _workspaceUserSelectByQueryOnlyLength;
export const workspaceUserSelectByQueryOnlyMaxMin = _workspaceUserSelectByQueryOnlyMaxMin;
export const workspaceUserSelectOneObjectByQuery = _workspaceUserSelectOneObjectByQuery;

// BoardUsers Reducer
export const {
  slice: BoardUserSlice,
  dbThunks: _BoardUserThunks,
  selectByQuery: _boardUserSelectByQuery,
  selectOneObjectByQuery: _boardUserSelectOneObjectByQuery,
  selectors: _boardUserSelectors,
  selectByQueryOnlyLength: _boardUserSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardUserSelectByQueryOnlyMaxMin,
  selectOneObjectById: _boardUserSelectOneObjectById,
  selectOneFieldById: _boardUserSelectOneFieldById,
} = createDBServiceSlice<BlueticksBoardUser>({
  service: "board-users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardUserReducer,
});
export const DBBoardUserReducer = BoardUserSlice.reducer;
export const BoardUserReducerActions = BoardUserSlice.actions;
export type boardUserReducerActions = keyof typeof BoardUserReducerActions;
export const DBBoardUserThunks = _BoardUserThunks;
export const boardUserSelectors = _boardUserSelectors;
export const boardUserSelectByQuery = _boardUserSelectByQuery;
export const boardUserSelectByQueryOnlyLength = _boardUserSelectByQueryOnlyLength;
export const boardUserSelectByQueryOnlyMaxMin = _boardUserSelectByQueryOnlyMaxMin;
export const boardUserSelectOneObjectByQuery = _boardUserSelectOneObjectByQuery;
export const boardUserSelectOneObjectById = _boardUserSelectOneObjectById;
export const boardUserSelectOneFieldById = _boardUserSelectOneFieldById;

// Subscriptions Reducer
export const {
  slice: SubscriptionSlice,
  dbThunks: _SubscriptionThunks,
  selectByQuery: _subscriptionSelectByQuery,
  selectOneObjectByQuery: _subscriptionSelectOneObjectByQuery,
  selectors: _subscriptionSelectors,
  selectByQueryOnlyLength: _subscriptionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _subscriptionSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Subscription>({
  service: "subscriptions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBSubscriptionReducer,
});
export const DBSubscriptionReducer = SubscriptionSlice.reducer;
export const SubscriptionReducerActions = SubscriptionSlice.actions;
export type subscriptionReducerActions = keyof typeof SubscriptionReducerActions;
export const DBSubscriptionThunks = _SubscriptionThunks;
export const subscriptionSelectors = _subscriptionSelectors;
export const subscriptionSelectByQuery = _subscriptionSelectByQuery;
export const subscriptionSelectByQueryOnlyLength = _subscriptionSelectByQueryOnlyLength;
export const subscriptionSelectByQueryOnlyMaxMin = _subscriptionSelectByQueryOnlyMaxMin;
export const subscriptionSelectOneObjectByQuery = _subscriptionSelectOneObjectByQuery;

// RecycledItemss Reducer
export const {
  slice: RecycledItemsSlice,
  dbThunks: _RecycledItemsThunks,
  selectByQuery: _recycledItemsSelectByQuery,
  selectOneObjectByQuery: _recycledItemsSelectOneObjectByQuery,
  selectors: _recycledItemsSelectors,
  selectByQueryOnlyLength: _recycledItemsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _recycledItemsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<RecycledItem>({
  service: "recycled-items",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBRecycledItemsReducer,
});
export const DBRecycledItemsReducer = RecycledItemsSlice.reducer;
export const RecycledItemsReducerActions = RecycledItemsSlice.actions;
export type recycledItemsReducerActions = keyof typeof RecycledItemsReducerActions;
export const DBRecycledItemsThunks = _RecycledItemsThunks;
export const recycledItemsSelectors = _recycledItemsSelectors;
export const recycledItemsSelectByQuery = _recycledItemsSelectByQuery;
export const recycledItemsSelectByQueryOnlyLength = _recycledItemsSelectByQueryOnlyLength;
export const recycledItemsSelectByQueryOnlyMaxMin = _recycledItemsSelectByQueryOnlyMaxMin;
export const recycledItemsSelectOneObjectByQuery = _recycledItemsSelectOneObjectByQuery;

// Webhooks Reducer
export const {
  slice: WebhooksSlice,
  dbThunks: _WebhooksThunks,
  selectByQuery: _webhooksSelectByQuery,
  selectOneObjectByQuery: _webhooksSelectOneObjectByQuery,
  selectors: _webhooksSelectors,
  selectByQueryOnlyLength: _webhooksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _webhooksSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Webhook>({
  service: "webhooks",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWebhooksReducer,
});
export const DBWebhooksReducer = WebhooksSlice.reducer;
export const WebhooksReducerActions = WebhooksSlice.actions;
export type webhooksReducerActions = keyof typeof WebhooksReducerActions;
export const DBWebhooksThunks = _WebhooksThunks;
export const webhooksSelectors = _webhooksSelectors;
export const webhooksSelectByQuery = _webhooksSelectByQuery;
export const webhooksSelectByQueryOnlyLength = _webhooksSelectByQueryOnlyLength;
export const webhooksSelectByQueryOnlyMaxMin = _webhooksSelectByQueryOnlyMaxMin;
export const webhooksSelectOneObjectByQuery = _webhooksSelectOneObjectByQuery;

// User Message Reducer
export const {
  slice: UserMessageSlice,
  dbThunks: _userMessageThunks,
  selectByQuery: _userMessagesSelectByQuery,
  selectByQueryOnlyLength: _userMessagesSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _userMessagesSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _userMessagesSelectOneFieldByQuery,
  selectOneObjectByQuery: _userMessagesSelectOneObjectByQuery,
  selectors: _userMessagesSelectors,
} = createDBServiceSlice<UserMessage>({
  service: "user-messages",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBUserMessageReducer,
});
export const DBUserMessageReducer = UserMessageSlice.reducer;
export const userMessageReducerActions = UserMessageSlice.actions;
export type UserMessageReducerActions = keyof typeof userMessageReducerActions;
export const DBUserMessageThunks = _userMessageThunks;
export const userMessagesSelectByQuery = _userMessagesSelectByQuery;
export const userMessagesSelectByQueryOnlyLength = _userMessagesSelectByQueryOnlyLength;
export const userMessagesSelectByQueryOnlyMaxMin = _userMessagesSelectByQueryOnlyMaxMin;
export const userMessagesSelectOneObjectByQuery = _userMessagesSelectOneObjectByQuery;

export const currentChatMessageListSelector = createSelector<
  [Selector<CommonRootState, CommonRootState>, Selector<CommonRootState, Chat>],
  UserMessage[]
>(
  [(state) => state, (state) => state.WhatsAppReducer.currentChat],
  (state, currentChat) =>
    state.DBUserMessageReducer.ids
      .map((id: EntityId) => {
        return state.DBUserMessageReducer.entities[id];
      })
      .filter(
        sift({
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          contactList: { $elemMatch: { id: currentChat?.id?._serialized } },
        })
      )
);

//quick launch reducer 
export const {
  slice: quickLaunchSlice,
  dbThunks: DBQuickLaunchThunks,
  selectByQuery: quickLaunchSelectByQuery,
  selectOneObjectByQuery: quickLaunchSelectOneObjectByQuery,
  selectOneFieldByQuery: quickLaunchSelectOneFieldByQuery,
  selectors: quickLaunchSelectors,
  selectByQueryOnlyLength: quickLaunchSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: quickLaunchSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<QuickLaunch>({
  service: 'quick-launch',
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBQuickLaunchReducer
})

export const DBQuickLaunchReducer = quickLaunchSlice.reducer
export const QuickLaunchReducerActions = quickLaunchSlice.actions


// Time tracking cell Reducer
export const {
  slice: TimeTrackingSessionsSlice,
  dbThunks: _TimeTrackingSessionsThunks,
  selectByQuery: _TimeTrackingSessionsSelectByQuery,
  selectOneObjectByQuery: _TimeTrackingSessionsSelectOneObjectByQuery,
  selectors: _TimeTrackingSessionsSelectors,
  selectByQueryOnlyLength: _TimeTrackingSessionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _TimeTrackingSessionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<TimeTrackingSession>({
  service: "time-tracking-sessions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTimeTrackingSessionReducer,
});



export const DBTimeTrackingSessionReducer = TimeTrackingSessionsSlice.reducer;
export const timeTrackingSessionsActions = TimeTrackingSessionsSlice.actions;
export type timeTrackingSessionsReducerActions = keyof typeof timeTrackingSessionsActions;
export const DBTimeTrackingSessionsThunks = _TimeTrackingSessionsThunks;
export const timeTrackingSessionsSelectors = _TimeTrackingSessionsSelectors;
export const timeTrackingSessionsSelectByQuery = _TimeTrackingSessionsSelectByQuery;
export const timeTrackingSessionsSelectByQueryOnlyLength = _TimeTrackingSessionsSelectByQueryOnlyLength;
export const timeTrackingSessionsSelectByQueryOnlyMaxMin = _TimeTrackingSessionsSelectByQueryOnlyMaxMin;
export const timeTrackingSessionsSelectOneObjectByQuery = _TimeTrackingSessionsSelectOneObjectByQuery;

// Log Reducer
export const {
  slice: LogsSlice,
  dbThunks: _LogsThunks,
  selectByQuery: _LogsSelectByQuery,
  selectOneObjectByQuery: _LogsSelectOneObjectByQuery,
  selectors: _LogsSelectors,
  selectByQueryOnlyLength: _LogsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _LogsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Log>({
  service: "logs",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBLogReducer,
});
export const DBLogReducer = LogsSlice.reducer;
export const logsActions = LogsSlice.actions;
export type logsReducerActions = keyof typeof logsActions;
export const DBLogsThunks = _LogsThunks;
export const logsSelectors = _LogsSelectors;
export const logsSelectByQuery = _LogsSelectByQuery;
export const logsSelectByQueryOnlyLength = _LogsSelectByQueryOnlyLength;
export const logsSelectByQueryOnlyMaxMin = _LogsSelectByQueryOnlyMaxMin;
export const logsSelectOneObjectByQuery = _LogsSelectOneObjectByQuery;

// Automation Reducer
export const {
  slice: AutomationsSlice,
  dbThunks: _AutomationsThunks,
  selectByQuery: _AutomationsSelectByQuery,
  selectOneObjectByQuery: _AutomationsSelectOneObjectByQuery,
  selectors: _AutomationsSelectors,
  selectByQueryOnlyLength: _AutomationsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Automation>({
  service: "automations",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationReducer,
});
export const DBAutomationReducer = AutomationsSlice.reducer;
export const automationsActions = AutomationsSlice.actions;
export type automationsReducerActions = keyof typeof automationsActions;
export const DBAutomationsThunks = _AutomationsThunks;
export const automationsSelectors = _AutomationsSelectors;
export const automationsSelectByQuery = _AutomationsSelectByQuery;
export const automationsSelectByQueryOnlyLength = _AutomationsSelectByQueryOnlyLength;
export const automationsSelectByQueryOnlyMaxMin = _AutomationsSelectByQueryOnlyMaxMin;
export const automationsSelectOneObjectByQuery = _AutomationsSelectOneObjectByQuery;


// AutomationTrigger Reducer
export const {
  slice: AutomationTriggerSlice,
  dbThunks: _AutomationTriggerThunks,
  selectByQuery: _AutomationTriggerSelectByQuery,
  selectOneObjectByQuery: _AutomationTriggerSelectOneObjectByQuery,
  selectors: _AutomationTriggerSelectors,
  selectByQueryOnlyLength: _AutomationTriggerSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationTriggerSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationTrigger>({
  service: "automations-triggers",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationTriggerReducer,
});
export const DBAutomationTriggerReducer = AutomationTriggerSlice.reducer;
export const automationTriggerActions = AutomationTriggerSlice.actions;
export type automationTriggerReducerActions = keyof typeof automationTriggerActions;
export const DBAutomationTriggerThunks = _AutomationTriggerThunks;
export const automationTriggerSelectors = _AutomationTriggerSelectors;
export const automationTriggerSelectByQuery = _AutomationTriggerSelectByQuery;
export const automationTriggerSelectByQueryOnlyLength = _AutomationTriggerSelectByQueryOnlyLength;
export const automationTriggerSelectByQueryOnlyMaxMin = _AutomationTriggerSelectByQueryOnlyMaxMin;
export const automationTriggerSelectOneObjectByQuery = _AutomationTriggerSelectOneObjectByQuery;

// AutomationConditions Reducer
export const {
  slice: AutomationConditionsSlice,
  dbThunks: _AutomationConditionsThunks,
  selectByQuery: _AutomationConditionsSelectByQuery,
  selectOneObjectByQuery: _AutomationConditionsSelectOneObjectByQuery,
  selectors: _AutomationConditionsSelectors,
  selectByQueryOnlyLength: _AutomationConditionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationConditionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationConditions>({
  service: "automations-conditions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationConditionsReducer,
});
export const DBAutomationConditionsReducer = AutomationConditionsSlice.reducer;
export const automationConditionsActions = AutomationConditionsSlice.actions;
export type automationConditionsReducerActions = keyof typeof automationConditionsActions;
export const DBAutomationConditionsThunks = _AutomationConditionsThunks;
export const automationConditionsSelectors = _AutomationConditionsSelectors;
export const automationConditionsSelectByQuery = _AutomationConditionsSelectByQuery;
export const automationConditionsSelectByQueryOnlyLength = _AutomationConditionsSelectByQueryOnlyLength;
export const automationConditionsSelectByQueryOnlyMaxMin = _AutomationConditionsSelectByQueryOnlyMaxMin;
export const automationConditionsSelectOneObjectByQuery = _AutomationConditionsSelectOneObjectByQuery;


// AutomationActions Reducer
export const {
  slice: AutomationActionsSlice,
  dbThunks: _AutomationActionsThunks,
  selectByQuery: _AutomationActionsSelectByQuery,
  selectOneObjectByQuery: _AutomationActionsSelectOneObjectByQuery,
  selectors: _AutomationActionsSelectors,
  selectByQueryOnlyLength: _AutomationActionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationActionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationActions>({
  service: "automations-actions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationActionsReducer,
});
export const DBAutomationActionsReducer = AutomationActionsSlice.reducer;
export const automationActionsActions = AutomationActionsSlice.actions;
export type automationActionsReducerActions = keyof typeof automationActionsActions;
export const DBAutomationActionsThunks = _AutomationActionsThunks;
export const automationActionsSelectors = _AutomationActionsSelectors;
export const automationActionsSelectByQuery = _AutomationActionsSelectByQuery;
export const automationActionsSelectByQueryOnlyLength = _AutomationActionsSelectByQueryOnlyLength;
export const automationActionsSelectByQueryOnlyMaxMin = _AutomationActionsSelectByQueryOnlyMaxMin;
export const automationActionsSelectOneObjectByQuery = _AutomationActionsSelectOneObjectByQuery;



// View filters Reducer
export const {
  slice: ViewFiltersSlice,
  dbThunks: _ViewFiltersThunks,
  selectByQuery: _ViewFiltersSelectByQuery,
  selectOneObjectByQuery: _ViewFiltersSelectOneObjectByQuery,
  selectors: _ViewFiltersSelectors,
  selectByQueryOnlyLength: _ViewFiltersSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _ViewFiltersSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<ViewFilter>({
  service: "view-filters",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBViewFiltersReducer,
});
export const DBViewFiltersReducer = ViewFiltersSlice.reducer;
export const viewFiltersActions = ViewFiltersSlice.actions;
export type viewFiltersReducerActions = keyof typeof viewFiltersActions;
export const DBViewFiltersThunks = _ViewFiltersThunks;
export const viewFiltersSelectors = _ViewFiltersSelectors;
export const viewFiltersSelectByQuery = _ViewFiltersSelectByQuery;
export const viewFiltersSelectByQueryOnlyLength = _ViewFiltersSelectByQueryOnlyLength;
export const viewFiltersSelectByQueryOnlyMaxMin = _ViewFiltersSelectByQueryOnlyMaxMin;
export const viewFiltersSelectOneObjectByQuery = _ViewFiltersSelectOneObjectByQuery;





// boardView Reducer
export const {
  slice: BoardViewSlice,
  dbThunks: _BoardViewThunks,
  selectByQuery: _boardViewSelectByQuery,
  selectOneObjectByQuery: _boardViewSelectOneObjectByQuery,
  selectOneFieldByQuery: _boardViewSelectOneFieldByQuery,
  selectors: _boardViewSelectors,
  selectByQueryOnlyLength: _boardViewSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardViewSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BoardView>({
  service: "board-views",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardViewReducer,
});
export const DBBoardViewReducer = BoardViewSlice.reducer;
export const BoardViewReducerActions = BoardViewSlice.actions;
export type boardViewReducerActions = keyof typeof BoardViewReducerActions;
export const DBBoardViewThunks = _BoardViewThunks;
export const boardViewSelectors = _boardViewSelectors;
export const boardViewSelectByQuery = _boardViewSelectByQuery;
export const boardViewSelectByQueryOnlyLength = _boardViewSelectByQueryOnlyLength;
export const boardViewSelectByQueryOnlyMaxMin = _boardViewSelectByQueryOnlyMaxMin;
export const boardViewSelectOneObjectByQuery = _boardViewSelectOneObjectByQuery;
export const boardViewSelectOneFieldByQuery = _boardViewSelectOneFieldByQuery;


// Feature Reducer
export const {
  slice: FeatureSlice,
  dbThunks: _FeatureThunks,
  selectByQuery: _featureSelectByQuery,
  selectOneObjectByQuery: _featureSelectOneObjectByQuery,
  selectors: _featureSelectors,
  selectByQueryOnlyLength: _featureSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _featureSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _featureSelectOneFieldByQuery,
} = createDBServiceSlice<Feature>({
  service: "features",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBFeatureReducer,
});
export const DBFeatureReducer = FeatureSlice.reducer;
export const FeatureReducerActions = FeatureSlice.actions;
export type featureReducerActions = keyof typeof FeatureReducerActions;
export const DBFeatureThunks = _FeatureThunks;
export const featureSelectors = _featureSelectors;
export const featureSelectByQuery = _featureSelectByQuery;
export const featureSelectByQueryOnlyLength = _featureSelectByQueryOnlyLength;
export const featureSelectByQueryOnlyMaxMin = _featureSelectByQueryOnlyMaxMin;
export const featureSelectOneObjectByQuery = _featureSelectOneObjectByQuery;
export const featureSelectOneFieldByQuery = _featureSelectOneFieldByQuery;

// MyWorkss Reducer
export const {
  slice: MyWorksSlice,
  dbThunks: _MyWorksThunks,
  selectByQuery: _myWorksSelectByQuery,
  selectOneObjectByQuery: _myWorksSelectOneObjectByQuery,
  selectOneFieldByQuery: _myWorksSelectOneFieldByQuery,
  selectors: _myWorksSelectors,
  selectByQueryOnlyLength: _myWorksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _myWorksSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<MyWork>({
  service: "my-works",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBMyWorksReducer,
});
export const DBMyWorksReducer = MyWorksSlice.reducer;
export const MyWorksReducerActions = MyWorksSlice.actions;
export type myWorksReducerActions = keyof typeof MyWorksReducerActions;
export const DBMyWorksThunks = _MyWorksThunks;
export const myWorksSelectors = _myWorksSelectors;
export const myWorksSelectByQuery = _myWorksSelectByQuery;
export const myWorksSelectByQueryOnlyLength = _myWorksSelectByQueryOnlyLength;
export const myWorksSelectByQueryOnlyMaxMin = _myWorksSelectByQueryOnlyMaxMin;
export const myWorksSelectOneObjectByQuery = _myWorksSelectOneObjectByQuery;
export const myWorksSelectOneFieldByQuery = _myWorksSelectOneFieldByQuery;



// Notification Reducer
export const {
  slice: NotificationsSlice,
  dbThunks: _NotificationsThunks,
  selectByQuery: _notificationsSelectByQuery,
  selectOneObjectByQuery: _notificationsSelectOneObjectByQuery,
  selectors: _notificationsSelectors,
  selectByQueryOnlyLength: _notificationsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _notificationsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Notification>({
  service: "notifications",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBNotificationsReducer,
});
export const DBNotificationsReducer = NotificationsSlice.reducer;
export const NotificationsReducerActions = NotificationsSlice.actions;
export type notificationsReducerActions = keyof typeof NotificationsReducerActions;
export const DBNotificationsThunks = _NotificationsThunks;
export const notificationsSelectors = _notificationsSelectors;
export const notificationsSelectByQuery = _notificationsSelectByQuery;
export const notificationsSelectByQueryOnlyLength = _notificationsSelectByQueryOnlyLength;
export const notificationsSelectByQueryOnlyMaxMin = _notificationsSelectByQueryOnlyMaxMin;
export const notificationsSelectOneObjectByQuery = _notificationsSelectOneObjectByQuery;


// EntitySubscribers Reducer
export const {
  slice: EntitySubscribersSlice,
  dbThunks: _EntitySubscribersThunks,
  selectByQuery: _entitySubscribersSelectByQuery,
  selectOneObjectByQuery: _entitySubscribersSelectOneObjectByQuery,
  selectors: _entitySubscribersSelectors,
  selectByQueryOnlyLength: _entitySubscribersSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _entitySubscribersSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<EntitySubscriber>({
  service: "entity-subscribers",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBEntitySubscribersReducer,
});
export const DBEntitySubscribersReducer = EntitySubscribersSlice.reducer;
export const EntitySubscribersReducerActions = EntitySubscribersSlice.actions;
export type entitySubscribersReducerActions = keyof typeof EntitySubscribersReducerActions;
export const DBEntitySubscribersThunks = _EntitySubscribersThunks;
export const entitySubscribersSelectors = _entitySubscribersSelectors;
export const entitySubscribersSelectByQuery = _entitySubscribersSelectByQuery;
export const entitySubscribersSelectByQueryOnlyLength = _entitySubscribersSelectByQueryOnlyLength;
export const entitySubscribersSelectByQueryOnlyMaxMin = _entitySubscribersSelectByQueryOnlyMaxMin;
export const entitySubscribersSelectOneObjectByQuery = _entitySubscribersSelectOneObjectByQuery;


// Sessions Reducer
export const {
  slice: SessionSlice,
  dbThunks: _SessionThunks,
  selectByQuery: _sessionSelectByQuery,
  selectOneObjectByQuery: _sessionSelectOneObjectByQuery,
  selectors: _sessionSelectors,
  selectByQueryOnlyLength: _sessionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _sessionSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<WASession>({
  service: "sessions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBSessionReducer,
});
export const DBSessionReducer = SessionSlice.reducer;
export const SessionReducerActions = SessionSlice.actions;
export type sessionReducerActions = keyof typeof SessionReducerActions;
export const DBSessionThunks = _SessionThunks;
export const sessionSelectors = _sessionSelectors;
export const sessionSelectByQuery = _sessionSelectByQuery;
export const sessionSelectByQueryOnlyLength = _sessionSelectByQueryOnlyLength;
export const sessionSelectByQueryOnlyMaxMin = _sessionSelectByQueryOnlyMaxMin;
export const sessionSelectOneObjectByQuery = _sessionSelectOneObjectByQuery;


//create reducer for NewTabFocus
export const {
  slice: NewTabFocusSlice,
  dbThunks: _NewTabFocusThunks,
  selectByQuery: _newTabFocusSelectByQuery,
  selectOneObjectByQuery: _newTabFocusSelectOneObjectByQuery,
  selectors: _newTabFocusSelectors,
  selectByQueryOnlyLength: _newTabFocusSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _newTabFocusSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<NewTabFocus>({
  service: "new-tab-focus",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBNewTabFocusReducer,
});
export const DBNewTabFocusReducer = NewTabFocusSlice.reducer;
export const NewTabFocusReducerActions = NewTabFocusSlice.actions;
export type newTabFocusReducerActions = keyof typeof NewTabFocusReducerActions;
export const DBNewTabFocusThunks = _NewTabFocusThunks;
export const newTabFocusSelectors = _newTabFocusSelectors;
export const newTabFocusSelectByQuery = _newTabFocusSelectByQuery;
export const newTabFocusSelectByQueryOnlyLength =
  _newTabFocusSelectByQueryOnlyLength;
export const newTabFocusSelectByQueryOnlyMaxMin =
  _newTabFocusSelectByQueryOnlyMaxMin;
export const newTabFocusSelectOneObjectByQuery =
  _newTabFocusSelectOneObjectByQuery;





// Create the reset action for Bots
export const resetBotState = createAction('bot/reset');

// Create the slice for Bots
export const {
  slice: BotSlice,
  dbThunks: _BotThunks,
  selectByQuery: _botSelectByQuery,
  selectOneObjectByQuery: _botSelectOneObjectByQuery,
  selectors: _botSelectors,
  selectByQueryOnlyLength: _botSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _botSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _botSelectOneFieldByQuery,
} = createDBServiceSlice<Bot>({
  service: "bot",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBotReducer,
  customReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(resetBotState, () => ({
      ids: [],
      entities: {},
      itemsLoaded: false,
      itemsLoading: false,
      total: 0,
      skip: 0,
      limit: 0,
    }));
  }
});

// Export the reducer
export const DBBotReducer = BotSlice.reducer;

// Export the actions
export const BotReducerActions = BotSlice.actions;
export type botReducerActions = keyof typeof BotReducerActions;

// Export other thunks and selectors
export const DBBotThunks = _BotThunks;
export const botSelectors = _botSelectors;
export const botSelectByQuery = _botSelectByQuery;
export const botSelectByQueryOnlyLength = _botSelectByQueryOnlyLength;
export const botSelectByQueryOnlyMaxMin = _botSelectByQueryOnlyMaxMin;
export const botSelectOneObjectByQuery = _botSelectOneObjectByQuery;
export const botSelectOneFieldByQuery = _botSelectOneFieldByQuery;



// Create the reset action
export const resetNodeState = createAction('node/reset');

export const {
  slice: NodeSlice,
  dbThunks: _NodeThunks,
  selectByQuery: _nodeSelectByQuery,
  selectOneObjectByQuery: _nodeSelectOneObjectByQuery,
  selectors: _nodeSelectors,
  selectByQueryOnlyLength: _nodeSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _nodeSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BotNode>({
  service: "node",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBNodeReducer,
  customReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(resetNodeState, () => ({
      ids: [],
      entities: {},
      itemsLoaded: false,
      itemsLoading: false,
      total: 0,
      skip: 0,
      limit: 0,
    }));
  }
});

// Export the reducer
export const DBNodeReducer = NodeSlice.reducer;

// Other exports
export const DBNodeThunks = _NodeThunks;
export const nodeSelectors = _nodeSelectors;
export const nodeSelectByQuery = _nodeSelectByQuery;
export const nodeSelectByQueryOnlyLength = _nodeSelectByQueryOnlyLength;
export const nodeSelectByQueryOnlyMaxMin = _nodeSelectByQueryOnlyMaxMin;
export const nodeSelectOneObjectByQuery = _nodeSelectOneObjectByQuery;



//create reducer for WaContact
export const {
  slice: WaContactSlice,
  dbThunks: _WaContactThunks,
  selectByQuery: _waContactSelectByQuery,
  selectOneObjectByQuery: _waContactSelectOneObjectByQuery,
  selectors: _waContactSelectors,
  selectByQueryOnlyLength: _waContactSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _waContactSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<WaContact>({
  service: "wacontacts",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWaContactReducer,
});
export const DBWaContactReducer = WaContactSlice.reducer;
export const WaContactReducerActions = WaContactSlice.actions;
export type waContactReducerActions = keyof typeof WaContactReducerActions;
export const DBWaContactThunks = _WaContactThunks;
export const waContactSelectors = _waContactSelectors;
export const waContactSelectByQuery = _waContactSelectByQuery;
export const waContactSelectByQueryOnlyLength =
  _waContactSelectByQueryOnlyLength;
export const waContactSelectByQueryOnlyMaxMin =
  _waContactSelectByQueryOnlyMaxMin;
export const waContactSelectOneObjectByQuery =
  _waContactSelectOneObjectByQuery;



//create reducer for WaChat
export const {
  slice: WaChatSlice,
  dbThunks: _WaChatThunks,
  selectByQuery: _WaChatSelectByQuery,
  selectOneObjectByQuery: _WaChatSelectOneObjectByQuery,
  selectors: _WaChatSelectors,
  selectByQueryOnlyLength: _WaChatSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _WaChatSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<WaChat>({
  service: "wachats",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWaChatReducer,
});
export const DBWaChatReducer = WaChatSlice.reducer;
export const WaChatReducerActions = WaChatSlice.actions;
export type waChatReducerActions = keyof typeof WaChatReducerActions;
export const DBWaChatThunks = _WaChatThunks;
export const WaChatSelectors = _WaChatSelectors;
export const WaChatSelectByQuery = _WaChatSelectByQuery;
export const WaChatSelectByQueryOnlyLength =
  _WaChatSelectByQueryOnlyLength;
export const WaChatSelectByQueryOnlyMaxMin =
  _WaChatSelectByQueryOnlyMaxMin;
export const WaChatSelectOneObjectByQuery =
  _WaChatSelectOneObjectByQuery;




//create reducer for Edge
// Create the reset action
export const resetEdgeState = createAction('edge/reset');

export const {
  slice: EdgeSlice,
  dbThunks: _EdgeThunks,
  selectByQuery: _edgeSelectByQuery,
  selectOneObjectByQuery: _edgeSelectOneObjectByQuery,
  selectors: _edgeSelectors,
  selectByQueryOnlyLength: _edgeSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _edgeSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BotEdge>({
  service: "edge",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBEdgeReducer,
  customReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(resetEdgeState, () => ({
      ids: [],
      entities: {},
      itemsLoaded: false,
      itemsLoading: false,
      total: 0,
      skip: 0,
      limit: 0,
    }));
  }
});

// Export the reducer
export const DBEdgeReducer = EdgeSlice.reducer;

// Other exports
export const DBEdgeThunks = _EdgeThunks;
export const edgeSelectors = _edgeSelectors;
export const edgeSelectByQuery = _edgeSelectByQuery;
export const edgeSelectByQueryOnlyLength = _edgeSelectByQueryOnlyLength;
export const edgeSelectByQueryOnlyMaxMin = _edgeSelectByQueryOnlyMaxMin;
export const edgeSelectOneObjectByQuery = _edgeSelectOneObjectByQuery;





//create reducer for Handle
export const resetHandleState = createAction('handle/reset');

export const {
  slice: HandleSlice,
  dbThunks: _HandleThunks,
  selectByQuery: _handleSelectByQuery,
  selectOneObjectByQuery: _handleSelectOneObjectByQuery,
  selectors: _handleSelectors,
  selectByQueryOnlyLength: _handleSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _handleSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Handle>({
  service: "handle",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBHandleReducer,
  customReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(resetHandleState, () => ({
      ids: [],
      entities: {},
      itemsLoaded: false,
      itemsLoading: false,
      total: 0,
      skip: 0,
      limit: 0,
    }));
  }
});

// Export the reducer
export const DBHandleReducer = HandleSlice.reducer;

// Other exports
export const DBHandleThunks = _HandleThunks;
export const handleSelectors = _handleSelectors;
export const handleSelectByQuery = _handleSelectByQuery;
export const handleSelectByQueryOnlyLength = _handleSelectByQueryOnlyLength;
export const handleSelectByQueryOnlyMaxMin = _handleSelectByQueryOnlyMaxMin;
export const handleSelectOneObjectByQuery = _handleSelectOneObjectByQuery;





// Tracings Reducer
export const {
  slice: TracingsSlice,
  dbThunks: _TracingsThunks,
  selectByQuery: _tracingsSelectByQuery,
  selectOneObjectByQuery: _tracingsSelectOneObjectByQuery,
  selectors: _tracingsSelectors,
  selectByQueryOnlyLength: _tracingsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _tracingsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Tracing>({
  service: "tracing",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTracingsReducer,
});
export const DBTracingsReducer = TracingsSlice.reducer;
export const TracingsReducerActions = TracingsSlice.actions;
export type tracingsReducerActions = keyof typeof TracingsReducerActions;
export const DBTracingsThunks = _TracingsThunks;
export const tracingsSelectors = _tracingsSelectors;
export const tracingsSelectByQuery = _tracingsSelectByQuery;
export const tracingsSelectByQueryOnlyLength = _tracingsSelectByQueryOnlyLength;
export const tracingsSelectByQueryOnlyMaxMin = _tracingsSelectByQueryOnlyMaxMin;
export const tracingsSelectOneObjectByQuery = _tracingsSelectOneObjectByQuery;


// Must be declared after all the reducers!
export type DBSlice = ReturnType<typeof createDBServiceSlice>;
export const DBReducerMap: Map<string, DBSlice> = new Map(_DBReducerMap);