
import { DBBoardUserThunks, boardUserSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import pluralize from "pluralize";
import { ActionMap, ActionTypeDisplayName, UndoRedoAction, UndoToastTimout, undoRedoActions } from "./UndoRedoReducer";
import { CommonRootState } from "@common/types/common-root-state-type";


export const toastMessageSelector = createSelector(
    (state:CommonRootState) => state,
    (state) => {
        const [topAction] = state.UndoRedoReducer.undoStack.slice(-1);

        return topAction && `${pluralize(ActionTypeDisplayName[topAction.type], topAction.data?.length, true)} ${topAction.deletedOrArchived} successfully`
    }
);

export const processingMessageSelector = createSelector(
    (state:CommonRootState) => state,
    (state) => {
        const [topAction] = state.UndoRedoReducer.undoStack.slice(-1);

        return topAction && `Restoring ${pluralize(ActionTypeDisplayName[topAction.type], topAction.data?.length, true)}`
    }
);

export const showUndoToastSelector = createSelector(
    (state:CommonRootState) => state,
    (state) => {
        const showUndoToast = !!state.UndoRedoReducer.timerDueTime;
        return showUndoToast;
    }
);


export const doActionThunk = createAsyncThunk<
    void,
    { action: UndoRedoAction },
    { state: CommonRootState }
>("doActionThunk", async ({ action }, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;
    const state: CommonRootState = getState();

    if (action.type === 'delete-board-user-list') {
        const removedUserId = state.DBBoardUserReducer.entities[action.data[0]]._id
        await dispatch(DBBoardUserThunks.find({ _id: removedUserId }))

        const removedUser = boardUserSelectOneObjectByQuery(state.DBBoardUserReducer, { _id: removedUserId })?.profile

        const board = state.DBBoardsReducer.entities[state.BoardsReducer.selectedBoardId]


        // remove user from board - log:
        // await dispatch(DBLogsThunks.create({
        //     boardId: board?._id,

        //     triggerById: state.DBUserReducer.ids[0] as any,
        //     triggerByData: `${state.DBUserReducer.entities[state.DBUserReducer.ids[0]].firstName} ${state.DBUserReducer.entities[state.DBUserReducer.ids[0]].lastName}`,
        //     triggerByEntity: 'user',

        //     actionType: 'removed',
        //     actionOnEntityId: removedUserId,
        //     actionOnEntityData: `${removedUser?.firstName} ${removedUser?.lastName}`,

        //     logUpdateType: 'remove board user',

        //     customData: board?.name,
        // }))
    }



    const actionDescriptor = ActionMap[action.type];
    if (actionDescriptor) {
        const doActionResponse = await actionDescriptor.doAction({
            data: action.data,
            deletedOrArchived:action.deletedOrArchived,
            thunkApi: {
                state,
                dispatch
            }
        });
        dispatch(undoRedoActions.pushUndoAction({
            ...action,
            doActionResponse
        }))

        dispatch(startUndoToastTimer());


    } else {
        throw new Error(`Action ${action.type} not suppored!`)
    }
});




export const undoActionThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("undoActionThunk", async (_, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;
    const state: CommonRootState = getState();

   // dispatch(undoRedoActions.clearUndoToast())
    const [action] = state.UndoRedoReducer.undoStack.slice(-1);

    const actionDescriptor = ActionMap[action.type];
    if (actionDescriptor) {
        actionDescriptor.undoAction({
            doActionResponse: action.doActionResponse,
            data: action.data,
            thunkApi: {
                state,
                dispatch
            }
        });
        dispatch(undoRedoActions.pushRedoAction(action));
    } else {
        throw new Error(`Action ${action.type} not suppored!`)
    }

});

export const startUndoToastTimer = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("startUndoToastTimer", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    dispatch(undoRedoActions.showUndoToast())
    const timerHandle = setTimeout(() => {
        dispatch(undoRedoActions.clearUndoToast());
    }, UndoToastTimout)

    dispatch(undoRedoActions.updateToastTimerHandler(timerHandle));
});

export const pauseUndoToastTimer = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("pauseUndoToastTimer", async (_, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    if (state.UndoRedoReducer.timerHandle) {
        clearTimeout(state.UndoRedoReducer.timerHandle);
    }
});

export const resumeUndoToastTimer = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("resumeUndoToastTimer", async (_, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;
    const state = getState();

    const remainingTime = moment(state.UndoRedoReducer.timerDueTime).diff(moment(), 'milliseconds');
    const timerHandle = setTimeout(() => {
        dispatch(undoRedoActions.clearUndoToast());
    }, remainingTime)

    dispatch(undoRedoActions.updateToastTimerHandler(timerHandle));
});

export const stopUndoToastTimer = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("stopUndoToastTimer", async (_, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;

    const state = getState();

    if (state.UndoRedoReducer.timerHandle) {
        clearTimeout(state.UndoRedoReducer.timerHandle);
        dispatch(undoRedoActions.clearUndoToast());
    }
});