export interface WaCommunicationLayerConfig {
  API_ENDPOINT: string;
  WCL_ENV: "client" | "server";
  cronToken?: string;
}

class ConfigurationManager {
  private static instance: ConfigurationManager;
  private config: WaCommunicationLayerConfig | null = null;

  private constructor() {}

  static getInstance(): ConfigurationManager {
    if (!ConfigurationManager.instance) {
      ConfigurationManager.instance = new ConfigurationManager();
    }
    return ConfigurationManager.instance;
  }

  init(config: WaCommunicationLayerConfig): void {
    this.config = config;
  }

  getConfig(): WaCommunicationLayerConfig {
    if (!this.config) {
      throw new Error('Configuration not initialized. Call init() first.');
    }
    return this.config;
  }
}

// Export a singleton instance
export const configManager = ConfigurationManager.getInstance();