import React from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

export default function PresencePenalty({ localChatGPTState, setLocalChatGPTState }: Props) {
    return (
        <>
            {title('Presence Penalty', { marginTop: '20px', })}
            <BotInputField
                value={localChatGPTState?.advanced?.presencePenalty ?? ''}
                type='number'
                onChange={(newValue) => {
                    if (/^-?\d*\.?\d*$/.test(newValue) && +newValue >= -2 && +newValue <= 2) {
                        setLocalChatGPTState((prev: any) => ({
                            ...prev,
                            advanced: {
                                ...prev.advanced,
                                presencePenalty: newValue
                            }
                        }))
                    }
                }}
            />
            {secondaryTitleWithMaybeLink(
                `Positive values discourage repeated token usage, promoting diversity and increasing the likelihood of introducing new topics. In contrast, negative values encourage repeated token usage, reinforcing existing patterns`,
                {}
            )}
            {secondaryTitleWithMaybeLink(`Must be a number between -2 and 2`, {})}
        </>
    );
}
