import { FlagRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'
import React from 'react'

type Props = {
    type: string
}

export default function StartNodeChip({ type }: Props) {
    return (
        <Chip icon={<FlagRounded />} label="Starting Step"
            sx={{
                width: '136px',
                position: 'absolute',
                top: '-35px',
                left: '50%',
                transform: 'translateX(-50%)', 
                backgroundColor: "rgb(51, 51, 51)",
                color: 'white',
                paddingBottom: '5px',
                height: '40px',
                borderRadius: '5px',
                zIndex: 1,
            }} />
    )
}