import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectMappedTaskGroups } from "@common-reducers/AutomationsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useCallback, useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import CustomDropdown from "../automation-dropdown";
import { setCurrentAction } from "../automations-helper";

const DuplicateGroupAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [text, setText] = useState('group');
    const prefix = index === 0 ? 'Then duplicate' : 'and then duplicate'
    const groups = useAppSelector(selectMappedTaskGroups);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const getSelectedGroupName = useCallback(() => {
        const selectedGroupId = actions[index].groupId;
        const group = groups.find(item => item._id === selectedGroupId);
        setText(group?.label ?? "group");
    }, [actions, index, groups, setText]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionSelect = (option) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType,  groupId: option._id})));
        setText(option.label);
        handleClose()
    };




    useEffect(() => {
        if (actions) {
            getSelectedGroupName();
            dispatch(automation.fieldValidityChanged({ component: 'DuplicateGroupAction', index, field: 'groupId', isValid: !!actions[index].groupId }));
        }
    }, [actions, dispatch, getSelectedGroupName, index]);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix}
                <AutomationButton
                    componentName='DuplicateGroupAction'
                    index={index}
                    option='groupId'
                    label={text}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <AddAndRemove elements={actions} index={index} componentName="DuplicateGroupAction" type={"actions"} />

            <CustomDropdown
                options={groups}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={null}            />
        </Stack>
    );
};

export default DuplicateGroupAction;
