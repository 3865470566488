
import { MaybeUser } from "@common/types/interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColumnData } from "@common/components/virtualized-table/virtualized-table";
import { Label, LabelContactInfo, LabeledChat } from "@common/models/label";
import { copyLabel, spliceIfExists } from "@common/reusable/common";
import { ThemeType } from "./WhatsAppReducer";
import { CommonRootState } from "@common/types/common-root-state-type";

interface LabelsIdsListActionPayload {
  chatId: string;
  labelId: string | undefined;
}

export interface LabelsState {
  user: MaybeUser;
  isCreateLabelPopupOpen: boolean;
  isManageLabelsPopupOpen: boolean;
  newLabeldisplayName?: string | undefined;
  globalLabelList?: Label[];
  chatsStateList?: LabeledChat[];
  chatFilterLabelsIdList?: Array<string>;
  manageLabelsSelectedLabelsIdList: Array<string>;
  manageLabelsFilteredLabelList?: Label[];
  manageLabelsFilterValue?: string;
  manageLabelsShowSelected: boolean;
  manageLabelsImportedLabelsSnackbarOpen: boolean;
  manageLabelsImportedLabelSnackbarMessage?: string;
  manageLabelsColumnList: ColumnData<Label>[];

  themeType?: ThemeType;

  labelsFilter: any;
  editableNameByLabelId: string;
}

const initialState: LabelsState = {
  labelsFilter: {
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  },

  editableNameByLabelId: "",

  user: null,
  isCreateLabelPopupOpen: false,
  isManageLabelsPopupOpen: false,
  globalLabelList: [
    {
      _id: "a1",
      displayName: "חשוב",
    },
    {
      _id: "b1",
      displayName: "משפחה",
    },
    {
      _id: "c1",
      displayName: "עבודה",
    },
    {
      _id: "d1",
      displayName: "פרסום",
    },
    {
      _id: "e1",
      displayName: "שליחים",
    },
  ],
  chatsStateList: [
    {
      chatId: "972548275828-1620720320@g.us",
      labelsIdList: ["b1", "c1"],
    },
    {
      chatId: "972548275828@c.us",
      labelsIdList: ["d1", "e1"],
    },
  ],
  chatFilterLabelsIdList: [],
  manageLabelsSelectedLabelsIdList: [],
  manageLabelsShowSelected: false,
  manageLabelsImportedLabelsSnackbarOpen: false,
  manageLabelsColumnList: [],
};

export interface AddRemoveLabelContactInfoOptions {
  label: Label;
  labelContactInfo: LabelContactInfo;
}

export const addRemoveLabelContactInfo = createAsyncThunk<
  void,
  AddRemoveLabelContactInfoOptions,
  { state: CommonRootState }
>("addRemoveLabelContactInfo", async (options, thunkAPI) => {
  console.log(`addRemoveLabelContactInfo triggered`);



  const waContactsIds = [...options.label.waContactsIds];

  const index = waContactsIds.findIndex(
    (labelContactInfo: LabelContactInfo) =>
      labelContactInfo.id === options.labelContactInfo.id
  );
  if (index !== -1) {
    waContactsIds.splice(index, 1);
  } else {
    waContactsIds.push(options.labelContactInfo);
  }
});

const LabelsSlice = createSlice({
  name: "Labels",
  initialState,
  reducers: {
    setLabelFilterName(state, action: PayloadAction<string>) {
      state.labelsFilter.displayName = action.payload;
      if (state.labelsFilter.displayName === "") {
        delete state.labelsFilter.displayName;
      }
    },

    /* old code */
    setUser: (state, action: PayloadAction<MaybeUser>) => {
      state.user = action.payload;
    },
    createLabelPopupOpen: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.isCreateLabelPopupOpen = true;
      state.newLabeldisplayName = payload;
    },
    createLabelPopupClose: (state) => {
      state.isCreateLabelPopupOpen = false;
      state.newLabeldisplayName = undefined;
    },
    manageLabelsOpen: (state) => {
      state.isManageLabelsPopupOpen = true;
    },

    manageLabelsClose: (state) => {
      state.isManageLabelsPopupOpen = false;
    },
    // adds or removes a label globally:
    globalLabelListAddOrRemove: (
      state,
      { payload }: PayloadAction<Label | undefined>
    ) => {
      if (payload === undefined || payload._id === undefined) {
        return;
      }

      const index: number | undefined = state.globalLabelList?.findIndex(
        (label: Label) => {
          return label._id === payload._id;
        }
      );

      if (index === undefined) {
        return;
      }

      if (index === -1) {
        state.globalLabelList?.push(payload);
      } else {
        // iterate all chat lists (chatsStateList) and remove the label id from them
        state.chatsStateList?.forEach((labeledChat: LabeledChat) => {
          spliceIfExists(labeledChat.labelsIdList, payload._id);
        });

        // remove from filter menu: (chatFilterLabelsIdList)
        spliceIfExists(state.chatFilterLabelsIdList, payload._id);

        // remove from possibly selected labels in table: (manageLabelsSelectedLabelsIdList)
        spliceIfExists(state.manageLabelsSelectedLabelsIdList, payload._id);

        const manageLabelsFilteredLabelListIndex: number | undefined =
          state.manageLabelsFilteredLabelList?.findIndex(
            (label: Label) => label._id === payload._id
          );

        if (
          manageLabelsFilteredLabelListIndex !== undefined &&
          manageLabelsFilteredLabelListIndex !== -1
        ) {
          state.manageLabelsFilteredLabelList?.splice(
            manageLabelsFilteredLabelListIndex,
            1
          );
        }
        // finally, remove from main list:
        state.globalLabelList?.splice(index, 1);
      }
    },
    // edits a label:
    globalLabelListEdit: (
      state,
      { payload }: PayloadAction<Label | undefined>
    ) => {
      if (state.globalLabelList === undefined || payload === undefined) {
        return;
      }

      const index: number | undefined = state.globalLabelList?.findIndex(
        (label: Label) => {
          return label._id === payload._id;
        }
      );

      if (index === undefined || index === -1) {
        return;
      }

      copyLabel(payload, state.globalLabelList[index]);
    },
    // adds or removes label from the labelsIdList of a chat
    chatLabelIdListToggleAddRemove: (
      state,
      { payload }: PayloadAction<LabelsIdsListActionPayload>
    ) => {
      if (state?.chatsStateList === undefined) {
        return;
      }

      const labelsIdList: Array<string> | undefined = state.chatsStateList.find(
        (labeledChat: LabeledChat, index: number) =>
          labeledChat.chatId === payload.chatId
      )?.labelsIdList;

      if (labelsIdList === undefined) {
        return;
      }

      if (payload.labelId === undefined) {
        return;
      }

      const index: number = labelsIdList.indexOf(payload.labelId);

      if (index === -1) {
        labelsIdList.push(payload.labelId);
      } else {
        labelsIdList.splice(index, 1);
      }
    },
    // adds or removes label from the filter list
    chatFilterListLabelAddOrRemove: (
      state,
      { payload }: PayloadAction<Label | undefined>
    ) => {
      if (payload === undefined || payload._id === undefined) {
        return;
      }

      const index: number | undefined = state.chatFilterLabelsIdList?.indexOf(
        payload._id
      );

      if (index === undefined) {
        return;
      }

      if (index === -1) {
        state.chatFilterLabelsIdList?.push(payload._id);
      } else {
        state.chatFilterLabelsIdList?.splice(index, 1);
      }
    },
    // manage labels:
    updateManageLabelsSelectedLabelsIdList: (
      state,
      { payload }: PayloadAction<Array<string>>
    ) => {
      state.manageLabelsSelectedLabelsIdList = payload;
    },
    setManageLabelsFilteredLabelList: (
      state,
      { payload }: PayloadAction<Label[] | undefined>
    ) => {
      state.manageLabelsFilteredLabelList = payload;
    },
    setManageLabelsFilterValue: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.manageLabelsFilterValue = payload;
    },
    setManageLabelsShowSelected: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.manageLabelsShowSelected = payload;
    },

    setManageLabelsImportedLabelsSnackbarOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.manageLabelsImportedLabelsSnackbarOpen = payload;
    },
    setManageLabelsImportedLabelSnackbarMessage: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.manageLabelsImportedLabelSnackbarMessage = payload;
    },
    setManageLabelsColumnList: (
      state,
      { payload }: PayloadAction<ColumnData<Label>[]>
    ) => {
      state.manageLabelsColumnList = payload;
    },
    updateThemeType: (state, { payload }: PayloadAction<ThemeType>) => {
      state.themeType = payload;
    },

    setEditableNameByLabelId(state, action: PayloadAction<string>) {
      state.editableNameByLabelId = action.payload;
    },
  },
});

export const {
  setLabelFilterName,
  setUser,
  createLabelPopupOpen,
  createLabelPopupClose,
  globalLabelListAddOrRemove,
  globalLabelListEdit,
  manageLabelsOpen,
  manageLabelsClose,
  chatLabelIdListToggleAddRemove,
  chatFilterListLabelAddOrRemove,
  updateManageLabelsSelectedLabelsIdList,
  setManageLabelsFilteredLabelList,
  setManageLabelsFilterValue,
  setManageLabelsShowSelected,
  setManageLabelsImportedLabelsSnackbarOpen,
  setManageLabelsImportedLabelSnackbarMessage,
  setManageLabelsColumnList,
  updateThemeType,
} = LabelsSlice.actions;
export default LabelsSlice.reducer;

export const labelsActions = LabelsSlice.actions;
