import CellWrapper from '@common-components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@common-components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';


type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function FilesColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );

    let status
    if (log.oldValue && log.newValue) { status = 'change data' }
    if (!log.oldValue && log.newValue) { status = 'add data' }
    if (log.oldValue && !log.newValue) { status = 'remove data' }
    return (
        <LogStack direction='row'>
            {status === 'add data' &&
                <>
                    <span>{log.actionType} &nbsp; </span>
                    <CellWrapper
                        sx={{ display: 'contents', height: "20px" }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                    <span> &nbsp;to &nbsp;</span>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span> removed &nbsp;</span>
                    <CellWrapper
                        sx={{ display: 'contents', height: "20px" }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                    <span>&nbsp; from&nbsp; </span>
                </>
            }
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}