import BTButton from '@common-components/bt-button/bt-button'
import { Stack } from '@mui/system'
import { getCurrentBoard } from '@common-reducers/BoardsSelectors'
import { addNewColumnThunk } from '@common-reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { MdAdd } from 'react-icons/md'

export default function NewTimelineColumnButton() {
    const currentBoard = useAppSelector(getCurrentBoard)
    const dispatch = useAppDispatch();
   
    async function newPickerColumn(type, title) {
        const newColumn = await dispatch(addNewColumnThunk({
            type: type,
            title: title,
            boardId: currentBoard?._id,
        })) 
    }
    return (
        <>
            <Stack direction='column'>
                <BTButton sx={{
                    width: '100%',
                    maxWidth: "250px",
                    margin: '8px'
                }} onClick={() => newPickerColumn('timeline-picker', 'Timeline')} startIcon={<MdAdd />} >
                    Add Timeline Column
                </BTButton>

            </Stack>
        </>

    );
}