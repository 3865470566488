import { Workspace } from "@common-models/workspace";
import { createSelector } from "@reduxjs/toolkit";
import { workspaceSelectors } from "@common-reducers/DBServiceThunks";
import { CommonRootState } from "@common/types/common-root-state-type";


export const testSelector = createSelector(
  [(state) => state,
  (_, xxx: string) => xxx],
  (state, xxx) => {
    console.log("param:" + xxx);
    return state.WorkspaceReducer.selectedWorkspaceId
  }
)

export const getSelectedWorkspace = createSelector(
  (state) => state,
  (state: CommonRootState): Workspace => {
    let selectedWorkspace: Workspace = null;
    if (state.WorkspaceReducer.selectedWorkspaceId) {
      selectedWorkspace = workspaceSelectors.selectById(state, state.WorkspaceReducer.selectedWorkspaceId)
    }

    return selectedWorkspace;
  }
);

export const getWorkSpaceById = (workSpaceId) => createSelector(
  (state) => state,
  (state): Workspace => {
    let selectedWorkspace;
    selectedWorkspace = workspaceSelectors.selectById(state, workSpaceId);

    return selectedWorkspace;
  }
);