import freeTrial from "@common-assets/free2.png";
import rocket from "@common-assets/pngrocket.png";

import BTButton from '@common-components/bt-button/bt-button';
import { Box, Stack } from '@mui/material';
import { DBSubscriptionThunks, DBUserThunks, DBWorkspaceThunks } from "@common-reducers/DBServiceThunks";
import { modalActions } from "@common-reducers/ModalReducer";
import { useStartOrExtendTrialMutation } from "@common-reducers/backend-api/backend-api";
import { getRuntimeUrl } from "@common-services/storage";
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { useMemo, useState } from "react";

const ExtendTrialModal = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [startOrExtendTrial] = useStartOrExtendTrialMutation();

    const startUserTrial = async () => {
        setIsLoading(true)
        const trial: any = {
            subscription_id: "",
            referenceNumber: "",
            plan_id: "trial",
            status: "active",
            type: 'trial'
        }

        await startOrExtendTrial({ trial }).unwrap()
      //  await dispatch(DBUserThunks.find({}));
        await dispatch(DBSubscriptionThunks.find({}));
        await dispatch(DBWorkspaceThunks.find({}));

            setIsLoading(false);
            dispatch(modalActions.setComponentToRender(''));
    };

    const rocketStyle = isLoading ? {
        //filter:"drop-shadow(1px 0px 4px black)",
        animation: 'liftOff 5s forwards',
        '@keyframes liftOff': {
            '0%': {
                transform: 'translateY(0) scale(1)',
                opacity: 1
            },
            '100%': {
                transform: 'translateY(-800px) scale(1.5)',
                opacity: 0
            }
        },
    
    } : {};

    const fadeOut = isLoading ? {
        animation: 'fade 1s forwards',
        '@keyframes fade': {
            '0%': {
                opacity: 1
            },
            '100%': {
                opacity: 0
            }
        },
    
    } : {};


    return (
        <Stack sx={{borderRadius:"8px"}} position={"relative"} justifyContent={"center"} alignItems={"center"} p={4}>

            <Box sx={{...fadeOut}}> <img width="250" height="300" src={freeTrial} alt="Gift Given" /></Box>
            <Stack  spacing={2} sx={{ maxWidth: "450px", lineHeight: 1.6,...fadeOut }} mt={2} mb={4}>
                <Box  textAlign={"center"} fontWeight={600}>
                Hey there! 🎉 Surprise!
                </Box>
                <Box textAlign={"center"} fontWeight={600}>
                 We're giving you an extra 14 days of free trial to keep exploring all the cool features of Tudoboard. 
                </Box>
                <Stack spacing={2}>
                    <Box textAlign={"center"} fontWeight={800}>No Credit card required </Box>
                    <Box textAlign={"center"}>Don't worry after 14 days, you will still be able to use Tudoboard</Box>
                </Stack>
            </Stack>
            {!isLoading ? <BTButton onClick={() => startUserTrial()} sx={{ boxShadow: "none",  }} variant="contained" color="primary"  >
            Unlock Additional 14 Days for Free!
            </BTButton>
                : <Box sx={{...rocketStyle,position:"absolute",bottom:"-20px"}}><img  src={rocket} alt="Rocket launch" /></Box>
            }

        </Stack>
    );
};

export default ExtendTrialModal;