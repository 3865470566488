import { DBTasksThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { Circle } from "@mui/icons-material";
import { Box, Popover, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";

const GroupDropdown = ({ boardId, taskId, groups }) => {
    const dispatch = useAppDispatch()
    const [groupColor, setGroupColor] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('Select group')
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [groupAnchor, setGroupAnchor] = useState(null);
    const [filterName, setFilterName] = useState<string>('');

    const moveTaskToGroup = (group) => {
        setGroupName(group.name);
        setGroupColor(group.colorAccent);
        dispatch(DBTasksThunks.patch({
            entity: { _id: taskId, groupId: group._id }
        }));
    }
    const handleGroupClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsMenuOpen(!isMenuOpen);
        event.stopPropagation();
    };

    function handleSearch({ target }: any) {
        setFilterName(target.value)
    }

    useEffect(() => {



        if (boardId) {
            setGroupName(groups[0]?.name)
            setGroupColor(groups[0]?.colorAccent)
        }


    }, [boardId, groups]);

    return (
        <Stack direction="row" justifyContent={"space-between"} sx={{
            backgroundColor: "white",
            margin: "10px",
            padding: "10px",
            borderRadius: "8px",
        }} >
            <Stack direction="row" alignItems={"center"}>
                <Circle sx={{ fontSize: "13px", color: groupColor ?? 'black', marginRight: "5px" }} />
                <Typography sx={{ textAlign: "center", fontSize: "13px" }} >Group</Typography>
            </Stack>
            <Box
                id={`${taskId}`}
                key={`${taskId}`}
                sx={{

                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "60%",
                    border: '1px dashed  #d0d4e4',
                    padding: "4px",

                }}
                onClick={(e) => {
                    e.stopPropagation()
                    setGroupAnchor(e.currentTarget);
                    handleGroupClick(e)
                }}
            >
                {groupName}
                {isMenuOpen &&
                    <Popover sx={{ "& .MuiPopover-paper": { padding: "5px 10px 10px 10px" } }} open={true} anchorEl={groupAnchor}>
                        <p style={{ fontWeight: "600" }}>Choose group</p>
                        <Stack direction="row" alignItems={"center"} sx={{
                            marginTop: "8px",
                            borderRadius: " 3px",
                            border: "1px solid var(--secondary-background-color)",
                            borderBottomWidth: "2px",

                        }}
                            onClick={(e) => e.stopPropagation()}>
                            <input type="text" style={{ background: "var(--field-background-color)", border: "none" }} onChange={handleSearch} placeholder={`Search`} />

                            <BsSearch style={{ border: "none", background: "var(--field-background-color)", paddingRight: "10px" }} />

                        </Stack>
                        {groups.filter(group => group.name.toLowerCase().includes(filterName.toLowerCase()))
                            .map((group, index) => (
                                <Stack sx={{
                                    cursor: "pointer", borderRadius: "3px", "&:hover": {
                                        background: "var(--button-hovered-color)"
                                    },
                                }} direction="row" alignItems={"center"} px={1} py={1.5} key={index}
                                    onClick={() => moveTaskToGroup(group)}>
                                    <Box sx={{
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "5px",
                                        borderRadius: 50,
                                        backgroundColor: group.colorAccent ?? '#00c875'
                                    }}></Box>
                                    <button style={{ background: "none", border: "none", width: "90%", textAlign: "left" }}>{group.name}</button>
                                </Stack>
                            ))}
                    </Popover>
                }
            </Box>
        </Stack >
    );
};

export default GroupDropdown;