import { Notification } from "@common-models/notification";
import { createSelector } from "@reduxjs/toolkit";
import { DBNotificationsThunks, notificationsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppThunkDispatch} from "@common/types/common-root-state-type";
import { selectUser } from "./UserSelectors";
import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { CommonRootState } from "@common/types/common-root-state-type";


export const getNumberOfUnseenNotifications = createDeepEqualSelector(
  [
    (state) => state,
  ],
  (state) => {
    const user = selectUser(state);

    const notifications: Notification[] = notificationsSelectByQuery(state.DBNotificationsReducer, {
      owner: user._id,
      deleted: false,
    });

    const newNotifications = notifications.filter((notification) => {
      const isSeen = !notification.seen
      return isSeen
    })

    return newNotifications.length
  }
);



export const updateAllUnseenNotificationsToSeenThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "tasks/undoTasksSort",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const user = selectUser(state);
    const newNotificationsIdArray = notificationsSelectByQuery(state.DBNotificationsReducer,
      { deleted: false, seen: false, owner: user._id })
      .filter((notification) => !notification.seen)
      .map((notification) => notification._id);

    if (newNotificationsIdArray.length > 0) {
      const changes = newNotificationsIdArray.map((notificationId) => (
        {
          id: notificationId,
          changes: {
            seen: true
          }
        }
      ))

      await dispatch(DBNotificationsThunks.patchMany(changes ?? []));

      await dispatch(DBNotificationsThunks.find({
        owner: user._id,
        deleted: false,
      }))
    }

  }
);