import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import classes from './import-excel.module.scss';

import { startImportToBoardThunk } from '@common-reducers/BoardsThunks';
import { setIsImportModalOpen } from '@common-reducers/CampaignReducer';
import { importItemsActions } from '@common-reducers/ImportItemsReducer';
import { modalActions } from '@common-reducers/ModalReducer';
import { dynamicColumnList } from '@common-reducers/TaskThunks';
import { namespace } from '@common/config/constants';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ImportButton from './import-button';
import { useImportExcelToBoardMutation } from '@common-reducers/backend-api/backend-api';

type Props = {
    selectedBoardId: any;
    importJsonData: any[];
    handleFinishImporting?: () => void;
    goNext: (isFromSheets?: boolean) => void;
}

export default function ImportNavButtons({
    selectedBoardId,
    importJsonData,
    handleFinishImporting,
    goNext,
}: Props) {
    const { t } = useTranslation(namespace);

    const dispatch = useAppDispatch()

    const step = useAppSelector((state) => state.ImportItemsReducer.step);
    const openedby = useAppSelector((state) => state.ModalReducer.openedby)
    const importType = useAppSelector((state) => state.ModalReducer.importType)
    const importFileName = useAppSelector((state) => state.ImportItemsReducer.importFileName);
    const selectedColumn = useAppSelector((state) => state.ImportItemsReducer.selectedColumn);
    const importDataMapping = useAppSelector((state) => state.ImportItemsReducer.importDataMapping);
    const importedContactsArray = useAppSelector(state => state.WhatsAppReducer.importedContactsArray)
    const columnsList = useAppSelector((state) => dynamicColumnList(state, selectedBoardId))

    const [importExcelToBoard] = useImportExcelToBoardMutation();



    const nextButtonString = () => {
        if ((openedby === 'campaign' && step === 2) || importType === 'work') {
            return t('importExcelToBlueticks.import')
        }
        else {
            return t('importExcelToBlueticks.next')
        }
    }




    const isMappingRequired = useCallback(() => {
        const isFirstNameColumnExist = (columnsList ?? []).filter(column => !column.deleted && column.type === 'text-cell'
            && column?.title?.toLowerCase().includes('first'))?.length > 0
        const isLastNameColumnExist = (columnsList ?? []).filter(column => !column.deleted && column.type === 'text-cell'
            && column?.title?.toLowerCase().includes('last'))?.length > 0

        return (!isFirstNameColumnExist || !isLastNameColumnExist) && importType === 'whatsapp' && selectedBoardId
    }, [columnsList, importType, selectedBoardId])



    const startImport = async () => {
        dispatch(startImportToBoardThunk({ selectedBoardId, handleFinishImporting, importExcelToBoard }))
    };


    const isCSVOrSheetsNotReadyToImport = useMemo(() => {
        const mappedColumnIdsArray = Object.values(importDataMapping).filter(value => value && value !== '')
        const isSelectedColumnExists = !!selectedColumn && selectedColumn !== ''
        return (!(['whatsapp', 'whatsappWithMapping'].includes(importType)) && (!isSelectedColumnExists && mappedColumnIdsArray.length === 0))
    }, [importDataMapping, importType, selectedColumn])

    const isWhatsappNotReadyToImport = useMemo(() => {
        return (['whatsapp', 'whatsappWithMapping'].includes(importType)) && (!importedContactsArray || importedContactsArray.length === 0)
    }, [importType, importedContactsArray])

    const isShowNextButton = useMemo(() => {
        if (step !== 2) {
            if (importType === 'whatsapp' && isMappingRequired()) {
                return true
            }
            if (!(['whatsapp', 'whatsappWithMapping'].includes(importType))) { // csv or google sheets
                if (importType && importType !== '') { // only after selecting import method
                    if (importFileName && importFileName !== "") { // only after selecting a file
                        return true
                    }
                }
            }
        }
        return false
    }, [importFileName, importType, isMappingRequired, step])

    return (
        <div className={classes.nextBtnWrap} style={{ margin: importType === 'whatsapp' && 0, }}>
            {/* {importFileName && step !== 0 && */}
            {/* // ( */}
            {((step !== 2 && importType && importType !== '') ||
                (step === 2)) &&
                <button className={classes.backBtn} onClick={() => {
                    if (importType === 'whatsapp') {
                        dispatch(modalActions.setImportType())
                    }
                    if (importType === 'whatsappWithMapping') {
                        dispatch(modalActions.setImportType('whatsapp'))
                    }
                    if (step > 0) {
                        if (step === 2) {
                            if (openedby !== 'campaign') {
                                dispatch(importItemsActions.setSelectedColumn(''))
                            }
                            dispatch(importItemsActions.setStep(step - 2))
                        }
                        else {
                            dispatch(importItemsActions.setStep(step - 1))
                        }

                    }
                    if (step === 0 && importType) {
                        dispatch(modalActions.setImportType());
                        dispatch(importItemsActions.resetImport())
                    }
                    if (step === 0 && !importType) {
                        dispatch(importItemsActions.setStep(0))
                        dispatch(modalActions.setComponentToRender(''))
                        dispatch(setIsImportModalOpen(false))
                    }
                }}>{t('importExcelToBlueticks.back')}</button>
            }

            {isShowNextButton &&
                < button disabled={(step === 0 && importFileName === "" && importType !== 'whatsapp') || (step === 2 && selectedColumn === "")} className={classes.nextBtn} onClick={() => goNext()}>{nextButtonString()}</button>
            }
            {(step === 2 || (importType === 'whatsapp' && !isMappingRequired()) || importType === 'whatsappWithMapping') &&
                <ImportButton
                    startImport={() => startImport()}
                    isDisabled={(isWhatsappNotReadyToImport || isCSVOrSheetsNotReadyToImport)} />
            }
        </div>
    )
}