// Import necessary types
import { TaskColumn } from '@common-models/task-column';
import { isTempId, tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks";
import { usedComponentSelector } from '@common-reducers/TaskSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { memo } from 'react';
import CellWrapper from "../columns-cells/cell-wrapper";
import { Skeleton } from '@mui/material';

// Define an interface for the props
interface ColumnCellProps {
  column: TaskColumn;
  taskId: string;
}

export const ColumnCell = memo<ColumnCellProps>(({ column, taskId }) => {
  const groupId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "groupId"));
  const boardId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "boardId"));
  const usedComponent = useAppSelector((state) => usedComponentSelector(state, column.type));
  
  if (!usedComponent) return null;

  return (
    column.static && isTempId(column._id) ? <Skeleton /> : <CellWrapper
      columnId={column._id}
      taskId={taskId}
      groupId={groupId}
      boardId={boardId}
      cellComponent={usedComponent}
    />
  );
});
