import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { updateCellValueThunk } from "@common-reducers/TaskThunks";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import moment, { Moment } from "moment";
import { useState } from "react";
import { PickerProps } from "../columns-cells/cell-interface";
import classes from "./datepicker.module.scss";

export default function Datepicker({
  cellValue: date,
  columnId,
  taskId
}: PickerProps) {
  const dispatch = useAppDispatch()

  const [value, setValue] = useState<Moment>(date);
  const minDate = moment().subtract(5, "years");

  const toggleMenu = () => {
    dispatch(pickerDialogActions.closePickerDialog());
  }

  const onValueUpdate = (value: string) => {
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value
      }
    }));
    toggleMenu();
  }


  return (
    <div className={classes.datePicker} >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDatePicker
          value={(moment(value) || date) ?? ''}
        
          minDate={minDate}
          onChange={(newValue) => {
            setValue(moment(newValue))
          //  const utcDateString = newValue.utc().format();
            onValueUpdate(moment(newValue).toString());
          }}

        slotProps={{
          actionBar: {
            actions: ['today'],
    
          },
             toolbar:{
              hidden: true,
             }
             
        }}

        />
      </LocalizationProvider>
    </div>
  );
}
