import React, {
  memo, useState,
} from "react";

import cx from "classnames";

import styles from "./bar-relation-handle.module.css";

type BarRelationHandleProps = {
  isRelationDrawMode: boolean;
  radius: number;
  startDrawRelation: () => void;
  x: number;
  y: number;
};

const BarRelationHandleInner: React.FC<BarRelationHandleProps> = ({
  isRelationDrawMode,
  radius,
  startDrawRelation,
  x,
  y,
}) => {
  const[mouseOn,setMouseOn] = useState<boolean>(false)
  return (
    // show noam the problem
    // <rect className={cx(styles.barRelationHandle, {
    //     [styles.barRelationHandle_drawMode]: isRelationDrawMode,
    //   })} x={x}  width="100" height="50" rx="2"
    <circle
    onMouseEnter={()=>setMouseOn(true)}
    onMouseOut={()=>setMouseOn(false)}

      cx={x}
      cy={y}
      r={isRelationDrawMode || mouseOn ? 10 : radius}
      className={cx(styles.barRelationHandle, {
        [styles.barRelationHandle_drawMode]: isRelationDrawMode,
      })}
      onMouseDown={startDrawRelation}
      onTouchStart={startDrawRelation}
      data-draw-mode={isRelationDrawMode}
    />
  );
};

export const BarRelationHandle = memo(BarRelationHandleInner);
