export const withTimeout = async <T>(
  promise: Promise<T>, 
  functionName: string,
  ms: number = 7000, // Default to 7000 milliseconds (7 seconds)
): Promise<T> => {
  return Promise.race([
    promise,
    new Promise<never>((_, reject) =>
      setTimeout(() => reject(new Error(`Timeout in ${functionName} after ${ms} ms`)), ms)
    ),
  ]);
};




export async function executeCodeWithErrorReporting<T>(
  params: {
    operation: () => Promise<T> | T;
    errorReportingMetadata: {
      fileName?: string;
      functionName?: string;
      serviceName?: string;
    };
  }
): Promise<T | undefined> {
  const { operation, errorReportingMetadata } = params;
  try {
    return await operation();
  } catch (error) {
    handleErrorReporting(errorReportingMetadata, error);
    return undefined;
  }
}


export const handleErrorReporting = (errorReportingMetadata, error) => {

  let wid
  try {
    wid = (window as any).Store.User.assertGetMeUser()._serialized
  } catch (error) {
    console.log('unable to fetch userWID')
  }
  const tracingData = JSON.stringify({
    error: {
      message: error?.message,
      stack: error?.stack,
    },
    errorReportingMetadata,
    wid,
  });

  (window as any).postMessage(
    {
      cmd: "tracingError",
      tracingData,
    },
    "*"
  );
}
