import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiBaseQuery, ApiTagTypes } from "./backend-api";

export const ItemTypeList = ['task']
export type ItemType = typeof ItemTypeList[number];

export interface UndoItem {
    type: ItemType;
    id: string;
    deletedOrArchived?:string;
}

export const recycleApiBuilder = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'backendApi'>) => ({
    requestRecycle: builder.mutation<
        {
            recycleId: string
        },
        {
            workspaceId: string;
            itemList: UndoItem[];
            deletedOrArchived?:string;
            fallBackNames?:any
        }
    >({
        query: (params) => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "recycle-request",
                ...params
            },
        }),
    }),
    restoreRequest: builder.mutation<
        void,
        {
            recycleId: string,
            deletedOrArchived?:string
        }
    >({
        query: (params) => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "restore-request",
                ...params
            },
        }),
    }),
})