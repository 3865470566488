import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectPersonPickerColumns } from "@common-reducers/AutomationsSelectors";
import { getSelectedBoardCompleteUserListEqual, getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import CustomDropdown from "../automation-dropdown";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentCondition } from "../automations-helper";
export const extractName = arr => {
    return arr.map(obj => {
        const firstName = obj.profile?.firstName || '';
        const lastName = obj.profile?.lastName || '';
        const label = `${firstName} ${lastName}`.trim();
        const profileImg = obj.profile?.profileImg || "";
        return { ...obj, label, profileImg };
    });
};

const PersonIsSomeoneCondition = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnName, setColumnName] = React.useState<string>('person');
    const [optionName, setOptionName] = React.useState<string>('someone');
    const [dropdown, setDropdown] = React.useState<string>('');

    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectPersonPickerColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
        setDropdown(dropdownOption);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const userList = useAppSelector(getSelectedBoardCompleteUserListEqual);
    const mappedUserList = extractName(userList);


    const handleColumnChange = (id, name) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, columnId: id })));
        setColumnName(name);
        setOptionName('something')
        handleClose();

    };

    const clickedOption = (option) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, optionId: option._id })));
        setOptionName(option.label)
        handleClose();
    };

    useEffect(() => {
        if (conditions) {
            const selectedColumnId = conditions[index].columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnName(column?.name ?? "person")
            const selectedOptionSelectedId = conditions[index].optionId;
            const optionSelected = mappedUserList.find(item => item._id === selectedOptionSelectedId);
            setOptionName(optionSelected?.label ?? "someone");
            dispatch(automation.fieldValidityChanged({ component: 'PersonIsSomeoneCondition', index, field: 'columnId', isValid: !!selectedColumnId }));
            dispatch(automation.fieldValidityChanged({ component: 'PersonIsSomeoneCondition', index, field: 'optionId', isValid: !!selectedOptionSelectedId }));
        }
    }, [conditions, dispatch, filteredColumns, index, mappedUserList]);

    return (
        <Stack>
            <Stack key={index} sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    and only if
                    <AutomationButton
                        componentName='PersonIsSomeoneCondition'
                        index={index}
                        option='columnId'
                        label={columnName}
                        handleClick={(e) => handleClick(e, 'pickerColumn')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> is assigned to
                    <AutomationButton
                        componentName='PersonIsSomeoneCondition'
                        index={index}
                        option='optionId'
                        label={optionName}
                        handleClick={(e) => handleClick(e, 'columnOptions')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                </Box>
                <AddAndRemove elements={conditions} index={index} type={"conditions"} />
            </Stack>

            {dropdown === 'pickerColumn' && (
                <PickerColumn
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filteredColumns={filteredColumns}
                    handleColumnChange={handleColumnChange}
                />)}
            {dropdown === 'columnOptions' && (
                <CustomDropdown
                    options={mappedUserList}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onOptionSelect={clickedOption}
                    onHoverOption={null}
                />
            )}
        </Stack>


    )
}
export default PersonIsSomeoneCondition