import { Box, Stack } from "@mui/system";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import AddAndRemove from '../add-and-remove';

const ExtraDataUpdateTrigger = () => {
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    return (
        <Stack direction="row" justifyContent={"space-between"} sx={{ fontSize: "24px" }}>
            <Box>When an update is created</Box>
            <AddAndRemove elements={conditions} index={0} type={"trigger"} />
        </Stack>

    )
}
export default ExtraDataUpdateTrigger