import { createSlice } from "@reduxjs/toolkit";
import { Bot } from "@common-models/bot";
import { getChatbotTempId } from "@common-services/utils";
import {
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    OnConnect,
    OnEdgesChange,
    OnNodesChange,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
} from 'reactflow';
import { create } from 'zustand';
import { initialNodes } from '@common/components/bt-chatbot/bt-nodes/bt-nodes-def';


type RFState = {
    nodes: Node[];
    edges: Edge[];
    onNodesChange: OnNodesChange;
    onEdgesChange: OnEdgesChange;
    onConnect: OnConnect;
    setNodes: (nodes: Node[]) => void;
    setEdges: (edges: Edge[]) => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useStore = create<RFState>((set, get) => {
    return ({

        nodes: initialNodes,
        edges: [],
        onNodesChange: (changes: NodeChange[]) => {
            set({
                nodes: applyNodeChanges(changes, get().nodes),
            });
        },
        onEdgesChange: (changes: EdgeChange[]) => {
            console.log('onEdgesChange', changes)
            set({
                edges: applyEdgeChanges(changes, get().edges),
            });
        },
        onConnect: (connection: Connection) => {
            if (connection.target !== connection.source) {
                const customEdge = {
                    ...connection,
                    id: getChatbotTempId(),
                    type: 'custom-edge',
                };
                set({
                    edges: addEdge(customEdge, get().edges),
                });
            }
        },
        setNodes: (nodes: Node[]) => {
            set({ nodes });
        },
        setEdges: (edges: Edge[]) => {
            set({ edges });
        },
    })
});




const initialBot = {
    name: 'New Chatbot (unsaved)',
    botSettings: {
        blockSaveNewBotSettings: false,
        isFallbackMessage: true,
        fallbackMessageTrigger: 1,
        fallbackMessage: 'Use the number of the option you want to choose',
        isChatbotTimer: true,
        chatbotTimerCountdown: 30,
        chatbotTimerEndMessage: 'Chatbot session will be closed soon',
        chatbotTimerMessageBeforeEnd: 5,
    },
}



export interface ChatbotState {
    currentBot: Bot;
    deletedEntities: Record<string, string>; // [entityType]: entityId
    isReadyToLoadFlow: boolean;
    currentReactFlowPosition: { x: number, y: number };
    menuPosition: { x: number, y: number };
    startNode: any;
    isLocked: boolean;
}



const initialState: ChatbotState = {
    currentBot: initialBot,
    deletedEntities: {},
    isReadyToLoadFlow: false,
    currentReactFlowPosition: { x: 0, y: 0 },
    menuPosition: { x: 0, y: 0 },
    startNode: null,
    isLocked: false,
};



const ChatbotSlice = createSlice({
    name: "Chatbot",
    initialState,
    reducers: {
        setCurrentBot: (state, action) => {
            state.currentBot = action.payload ?? initialBot;
            clearDeletedEntities(state);
        },
        patchDeletedEntities: (state, action) => {
            state.deletedEntities[action.payload[0]] = action.payload[1];
        },
        clearDeletedEntities: (state, action) => {
            state.deletedEntities = {};
        },
        setIsReadyToLoadFlow: (state, action) => {
            state.isReadyToLoadFlow = action.payload;
        },
        setCurrentReactFlowPosition: (state, action) => {
            state.currentReactFlowPosition = action.payload;
        },
        setMenuPosition: (state, action) => {
            state.menuPosition = action.payload;
        },
        setStartNode: (state, action) => {
            state.startNode = action.payload;
        },
        setIsLocked: (state, action) => {
            state.isLocked = action.payload;
        },
    },
    extraReducers: (builder) => {
    },
});


export const {
    setCurrentBot,
    patchDeletedEntities,
    clearDeletedEntities,
    setIsReadyToLoadFlow,
    setCurrentReactFlowPosition,
    setMenuPosition,
    setStartNode,
    setIsLocked,
} = ChatbotSlice.actions;
export default ChatbotSlice.reducer;


