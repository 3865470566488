import { Stack } from "@mui/material";
import { NullableId } from "@common/types/interface";
import { memo } from "react";
interface Props {
    values: any;
    columnId: NullableId;
}
function RatingSumCell({ values, columnId }: Props) {

    const roundToHalf = (num) => {
        return Math.round(num * 2) / 2;
    };

    const calculateAverageRating = () => {
        const filteredRatings = values.data.filter(rating => typeof rating === 'number');

        const totalRating = filteredRatings.reduce((acc, rating) => acc + rating, 0);

        if (values.length > 0) {
            return totalRating / filteredRatings.length;
        } else {
            return 0;
        }
    };

    const averageRating = calculateAverageRating();
    const formatRating = (rating) => {
        return rating % 1 === 0 ? rating.toString() : rating.toFixed(1);
    };
    return (
        <Stack sx={{ position: "relative" }} alignItems={"center"} flexGrow={1} textAlign={"center"}>


            {isNaN(averageRating) ? "0 / 5" : `${formatRating(averageRating)} / 5`}
        </Stack>
    );
}

export default memo(RatingSumCell)