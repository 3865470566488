import BtEditableBox from "@common-components/tasks/bt-editable-box/bt-editable-box";
import { CircularProgress, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { boardsActions } from "@common-reducers/BoardsReducer";
import { getSelectedBoardId, isBoardPanelDrawerOpen } from "@common-reducers/BoardsSelectors";
import { setSelectedBoardId } from "@common-reducers/BoardsThunks";
import { bulkActions } from "@common-reducers/BulkActionsReducer";
import { boardsSelectOneFieldByQuery, DBBoardsThunks, DBUserThunks, isTempId } from "@common-reducers/DBServiceThunks";
import { useRouter } from "@common-reducers/hooks/use-router";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { memo } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import classes from "./boards-panel.module.scss";
import { resetMenusState } from "@common-reducers/TaskThunks";
import { selectUserId } from "@common-reducers/UserSelectors";

type BoardListItemProps = {
    provided: any,
    isDragging: boolean,
    boardId: string;
};

function BoardListItem({ provided, isDragging, boardId }: BoardListItemProps) {

    const dispatch = useAppDispatch()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up(786))
    const router = useRouter();
    const selectedBoardId = useAppSelector(getSelectedBoardId);
    const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
    const boardEditModeByBoardId = useAppSelector((state) => state.BoardsReducer.boardEditModeByBoardId);
    const boardName = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, { _id: boardId }, "name"))
    const boardTitleIcon = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, { _id: boardId }, "titleIcon"))
    const isBoardEditingMode: boolean = (selectedBoardId !== "") && (boardId === boardEditModeByBoardId);
    const viewMode = useAppSelector(state => state.TasksReducer.viewMode);

    const anchorId = `board_panel_item_${boardId}`;
    const direction = useAppSelector((state) => state.UserReducer.direction);
    const userId = useAppSelector(selectUserId)


    function clearBoardEditing() {
        dispatch(boardsActions.setBoardEditModeByBoardId(''))
    }

    function selectBoard(boardId: string) {
        dispatch(taskActions.setViewMode('tasks'))
        dispatch(setSelectedBoardId({ boardId }));
        dispatch(taskActions.setCurrentBoardView({
            type: 'board',
            id: boardId
        }
        ))
        dispatch(taskActions.setPersonFilter(""));
        dispatch(taskActions.clearDeletedBoardName());
        dispatch(bulkActions.setBulkTasksMap({}));
        dispatch(bulkActions.setMovingTo(''));
        dispatch(resetMenusState());
        router.navigate(`/tasks/?boardID=${boardId}`);
        if (!isDesktop){
           dispatch(DBUserThunks.patch({
            entity: {
              _id: userId,
              isBoardsPanelOpen: !drawerIsOpen,
            }
          }));
        }
    }

    function updateBoardName(value) {
        dispatch(DBBoardsThunks.patch({ entity: { _id: boardId, name: value } }));
        dispatch(boardsActions.setBoardEditModeByBoardId(''))
    }

    function openMenu() {

        dispatch(
            pickerDialogActions.setPickerTypeAndId({
                pickerAnchorId: anchorId,
                pickerProps: {
                    boardId: boardId,
                    type: "board-menu-picker",
                },
            })
        );
    }

    return (

        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        display: drawerIsOpen ? "none" : "flex",
                        bgcolor: "#fff",
                        padding: "4px 18px",
                        maxWidth: "150px",
                        height: "35px",
                        justifyContent: "center",
                        border: '1px solid transparent',
                        alignItems: "center",
                        color: "rgb(0, 55, 80)",
                        fontSize: "14px",
                        fontFamily: "'Inter', sans-serif",
                        boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                        "& .MuiTooltip-arrow": {
                            color: "#fff",
                        },
                    },
                },
            }}
            arrow
            title={boardName}
            placement="right-start">
            <ul
                className={classes.boardUL}

                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={{
                    ...provided.draggableProps.style,
                    boxSizing: 'border-box',
                    width: drawerIsOpen ? "" : "100%"
                }}
            >

                <li
                    onClick={() => selectBoard(boardId)}
                    id={anchorId}
                    className={`
                        ${classes.listItem}  
                        ${(selectedBoardId === boardId && viewMode === 'tasks' ) && classes.listItemActive} 
                        ${isBoardEditingMode && classes.editMode}
                `}

                    style={{
                        border: isDragging && "1px solid var(--border-color)",
                        backgroundColor: isDragging && "var(--border-color)"
                    }}

                >
                    <div
                        style={{
                            alignItems: drawerIsOpen ? "center" : "normal",
                            margin: "0 5px",
                            fontSize: !drawerIsOpen && 18,
                        }}

                        className={classes.board_icon}>
                        {isTempId(boardId) ? (
                            <CircularProgress size={20} />
                        ) : (boardTitleIcon ?? '😜')}
                    </div>

                    <div data-testid="board-name-bteditablebox">
                        <BtEditableBox
                            inputSx={{
                                width: '100%',
                                cursor: 'text',
                                border: '1px solid #53bdeb',
                                borderRadius: '4px',
                                fontWeight: isBoardEditingMode ? '700' : '400',
                                color: "var(--primary)",
                                background: 'var(--board-item-menu-hover)',
                                fontSize: '14px',
                                padding: '0 5px',
                                fontFamily: "'Inter', 'Assistant', sans-serif",
                                "& .MuiInputBase-input": {
                                    padding: "0"
                                }
                            }}
                            boxSx={{
                                cursor: 'pointer',
                                color: "var(--primary)",
                                fontSize: '14px',
                                border: '1px solid transparent',
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontFamily: "'Inter', 'Assistant', sans-serif",
                                width: '200px',
                                padding: '0 7px',
                            }}
                            preventClickEdit={true}
                            onBlur={clearBoardEditing}

                            style={{ display: !drawerIsOpen ? "none" : "flex" }}
                            forceEditMode={boardEditModeByBoardId === boardId}
                            text={boardName}
                            onValueUpdate={(value) => updateBoardName(value)} />
                    </div>

                    {(drawerIsOpen && isDesktop && boardEditModeByBoardId !== boardId) && (
                        <Box sx={{
                            insetInlineEnd:"22px",
                            position: "relative"
                        }} data-testid="board-panel-item-three-dots">
                            <button
                                hidden={isBoardEditingMode}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openMenu()
                                }}
                                className={classes.board_item_menu}
                            >
                                <BsThreeDots size={35} color={"var(--primary)"} />
                            </button>
                        </Box>
                    )}
                </li>
            </ul>
        </Tooltip>

    );
};

export default memo(BoardListItem);