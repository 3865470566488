import BTInputField from '@common-components/bt-input-field/bt-input-field'
import { taskActions } from '@common-reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import classes from "./search-tasks.module.scss";
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { ClearIcon } from '@mui/x-date-pickers/icons';

function SearchTasks() {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState('' as string)
  const textFilter = useAppSelector((state) => state.TasksReducer.tasksFilter.text);


  function filterTasks(query: string) {
    dispatch(taskActions.setTasksFilterName(query))
    setSearchQuery(query)

  }

  const clearSearch = () => {
    dispatch(taskActions.setTasksFilterName(''))
    setSearchQuery('')
  }


  return (
    <BTInputField className={classes.search_input}
      sx={{ marginInlineStart: '10px' }} value={searchQuery} type="text" onChange={({ target }: any) => filterTasks(target.value)} placeholder={t('searchTasks.searchTasks')} startAdornment={<SearchIcon />}
      endAdornment={
        textFilter && (
          <IconButton size='small' style={{ fontSize: '12px' }} onClick={clearSearch}>
            <ClearIcon style={{ fontSize: '14px' }} />
          </IconButton>
        )
      }
    />
  )
}

export default SearchTasks