import React from "react";
import { Box, Stack } from "@mui/material";
import { SubscriptionObject } from "@common/models/subscription";
import { User } from "@common/models/user";

export interface PlanDescriptionProps {
    subscription?: SubscriptionObject;
}
 
export interface PlanDescriptionState {
    user?: User;
}
 
class PlanDescription extends React.Component<PlanDescriptionProps, PlanDescriptionState> {
    constructor(props: PlanDescriptionProps) {
        super(props);
        this.state = {};
    }
    render() { 
        return (
            <Stack>
                <Stack direction="row">
                    <Box flexBasis={120} flexGrow={0} flexShrink={0}>Current plan:</Box>
                    <Box flex="auto">{this.props.subscription?.planName}</Box>
                </Stack>
                <Stack direction="row">
                    <Box flexBasis={120} flexGrow={0} flexShrink={0}>Renewal date:</Box>
                    <Box flex="auto" >{this.props.subscription?.renewalTime?.format('MMM D, YYYY')}</Box>
                </Stack>
            </Stack>
        );
    }
}
 
export default PlanDescription;