import BTInputField from "@common-components/bt-input-field/bt-input-field";
import OverflowTooltip from "@common-components/tasks/columns-cells/text-cell/overflow-tooltip";
import { TaskColumnOption } from "@common-models/task-column-option";
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { DBTaskColumnOptionThunks, isTempId } from "@common-reducers/DBServiceThunks";
import { selectDropdownOptionsAndPickedValues } from "@common-reducers/TaskSelectors";
import { updateCellValueThunk } from "@common-reducers/TaskThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import { PickerProps } from "../../columns-cells/cell-interface";
import classes from "./dropdown.module.scss";



export default function DropDown({
  taskId,
  boardId,
  columnId,

}: PickerProps) {
  const { t } = useTranslation(namespace);
  const inputRef: any = useRef();
  const dispatch = useAppDispatch()
  const [searchOrCreateValue, setSearchOrCreateValue] = useState(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setButtonDisabled] = useState(false);
  const [localOptions, setLocalOptions] = useState<TaskColumnOption[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { columnOptions, optionsTopick, pickedOptions, dropdownValuesIds } = useAppSelector((state) => selectDropdownOptionsAndPickedValues(state, { taskId: taskId, columnId: columnId, boardId: boardId }))


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    checkIfValueExistsOnOptions(event.target.value);
    if (isTempId(columnId)) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  };


  useEffect(() => {
    setLocalOptions(columnOptions)
  }, [columnOptions]);


  const saveOptions = async () => {
    const updatedOptions = localOptions.filter(option => option._id)
      .map(option => ({
        id: option._id,
        changes: option,
      }))
    dispatch(DBTaskColumnOptionThunks.patchMany(updatedOptions));

    const deletedOptions = columnOptions.filter(({ _id: id1 }) => !updatedOptions.some(({ id: id2 }) => id2 === id1)).map(option => ({
      id: option._id,
      changes: {
        deleted: true
      }
    }));
    if (deletedOptions) {
      dispatch(DBTaskColumnOptionThunks.patchMany(deletedOptions));

    }
    setIsEditing(false);
  }

  const updateTask = (option: TaskColumnOption) => {
    const cellValueClone = pickedOptions ? [...pickedOptions, option] : [option];
    const mappedValues = cellValueClone.map(item => ({ _id: item._id, label: item.label, order: item.order }));
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value: mappedValues
      }
    }));
  }

  const checkIfValueExistsOnOptions = (value) => {
    const currentValue = value;
    setSearchOrCreateValue(currentValue);
    const labelExist = columnOptions.some(el => el.label === currentValue)
    if (labelExist) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
    return labelExist;
  }

  const addLabels = async () => {
    const labelExists = checkIfValueExistsOnOptions(inputValue);
    if (labelExists) return;

    setIsLoading(true);
    const res = await dispatch(DBTaskColumnOptionThunks.create({
      boardId,
      columnId,
      label: inputValue,
      bgcolor: "",
      order: columnOptions.length,
    }))
    setInputValue('');
    setSearchOrCreateValue(undefined);
    if (res) {
      setIsLoading(false);
    }
  };

  const editLabels = (index: number, label: string) => {
    const clonedOptionsList = [...localOptions];
    clonedOptionsList[index] = {
      ...clonedOptionsList[index],
      label
    }
    setLocalOptions(clonedOptionsList ?? []);
  }


  const deleteLabel = (i) => {
    let labelsFilterd = pickedOptions.filter((_, index) => index !== i);
    labelsFilterd = labelsFilterd.length >= 1 ? labelsFilterd : undefined
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value: labelsFilterd ?? []
      }
    }));
  }


  const deleteOptionLabel = (index: number, optionId) => {
    if (dropdownValuesIds.includes(optionId)) return;
    const clonedOptionsList = [...localOptions];
    clonedOptionsList[index] = {
      ...clonedOptionsList[index],
      deleted: true
    }
    clonedOptionsList.splice(index, 1);
    setLocalOptions(clonedOptionsList ?? []);
  }

  return (
    <Stack className={classes.dropDownContainer}>
      {pickedOptions.length > 0 && (<div className={classes.tagsCont}>
        {pickedOptions?.map((option, i) => {
          return (
            <div className={classes.labelsContainer} key={i}>
              <Stack direction="row" className={classes.labelTag}>
                <OverflowTooltip value={option.label}>
                  <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {option.label}
                  </Box>
                </OverflowTooltip>


                <Box className={classes.removeButton}>
                  <RiCloseCircleFill onClick={() => { deleteLabel(i) }} className={classes.clearIcon} />
                </Box>
              </Stack>
            </div>
          );
        })}
      </div>)}


      <Stack mb={1}>
        {/* <input onChange={checkIfValueExistsOnOptions} ref={inputRef} onKeyPress={(e) => e.key === "Enter" && addLabels()} type="text" placeholder={t('dropDown.searchCreateLabels')} /> */}

        <BTInputField
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t('dropDown.createOrFindLabelsPlaceholder')}
          onKeyPress={(e) => e.key === "Enter" && addLabels()}

          type="text"
        />
      </Stack>

      <Stack sx={{ maxHeight: "250px", overflowY: "auto", overflowX: "hidden" }}>
        {
          !isLoading ? (
            <>
              {!isEditing ? (
                optionsTopick?.filter(option => option.label?.includes(inputValue) && !isTempId(option._id)).map((option, i) => {
                  return (
                    <MenuItem onClick={() => updateTask(option)} className={classes.labelsContainer} key={i}> <Typography noWrap>{option.label}</Typography></MenuItem>
                  );
                }))
                :
                localOptions?.filter(option => option.label?.includes(inputValue)).map((option, i) => {
                  return (
                    <Stack spacing={2} key={i} direction="row" justifyContent={"space-between"} alignItems={"center"} px={1} >
                      <Box my={0.8}>
                        <BTInputField
                          key={i}
                          onChange={(e) => editLabels(i, e.target.value)}
                          placeholder={t('dropDown.searchTasksPlaceholder')}
                          value={option.label}
                          type="text"
                        />
                      </Box>
                      <Tooltip PopperProps={{ style: { zIndex: 999999999 } }} disableInteractive arrow placement={"top"} title={dropdownValuesIds.includes(option._id) ? t('dropDown.deleteLabelTooltip') : t('dropDown.delete')}>
                        <IconButton size={"small"} sx={{ cursor: dropdownValuesIds.includes(option._id) ? 'not-allowed' : 'pointer' }} onClick={() => deleteOptionLabel(i, option._id)}>
                          <MdClose style={{ color: dropdownValuesIds.includes(option._id) ? '#aaaaaa' : "#787878" }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )
                })
              }</>


          ) : <Stack direction={"row"} justifyContent={"center"} sx={{ margin: "10px 0" }}> <CircularProgress size={30} /> </Stack>
        }


      </Stack>


      {searchOrCreateValue !== undefined && searchOrCreateValue !== "" && !isLoading && (
        <div className={classes.addLabelCont}>
          <Tooltip PopperProps={{ style: { zIndex: 999999999 } }} arrow placement="top" disableInteractive title={disabled ? t('dropDown.labelOptionExists') : ''}>
            <Button className={disabled ? classes.disabled : ''} onClick={() => addLabels()}> {t('dropDown.createNewLabelButton')} </Button>
          </Tooltip>
        </div>
      )}


      <Divider />
      {columnOptions.length > 0 ? (<div className={classes.editLabelCont}>
        {!isEditing ? (

          <Button onClick={() => setIsEditing(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Inter', 'Assistant', sans-serif",
              height: "32px",
              borderRadius: "5px",
              fontSize: "14px",
              fontWeight: 600,
              color: "var(--option-picker-button)",
              width: "100%",
              margin: "0",
              padding: "4%",
              textTransform: "initial",
              "&:hover": {
                fontWeight: 600,
                backgroundColor: "var(--button-hovered-color) !important",
              },
            }}>
            <BiPencil size={16} />
            <span style={{ marginInlineStart: "6px" }}>{t('dropDown.editLabelsButton')}</span>
          </Button>
        ) :
          <Button sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "'Inter', 'Assistant', sans-serif",
            height: "32px",
            borderRadius: "5px",
            fontSize: "14px",
            fontWeight: 600,
            color: "var(--option-picker-button)",
            width: "100%",
            margin: "0",
            padding: "4%",
            textTransform: "initial",
            "&:hover": {
              fontWeight: 600,
              backgroundColor: "var(--button-hovered-color) !important",
            },
          }} onClick={saveOptions} >{t('dropDown.applyButton')}</Button>
        }


      </div>) : null}

    </Stack>
  );
}
