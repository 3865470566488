import { TaskColumn } from "@common-models/task-column";
import { TaskColumnOption } from "@common-models/task-column-option";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import { getSelectedBoardId } from "./BoardsSelectors";
import {
  DBNewTabFocusThunks,
  DBTasksThunks,
  newTabFocusSelectOneObjectByQuery,
  taskColumnOptionSelectByQuery,
  taskColumnSelectByQuery,
  taskGroupsSelectByQuery,
  tasksSelectByQueryOnlyMaxMin,
  tasksSelectors,
} from "@common-reducers/DBServiceThunks";
import { selectElementsList } from "./TaskSelectors";
import { selectUserId } from "./UserSelectors";

export const selectNewTabFocusByOwnerId = createSelector(
  [(state) => state, selectUserId],
  (state, ownerId) => {
    const newTabFocus = newTabFocusSelectOneObjectByQuery(
      state.DBNewTabFocusReducer,
      {
        owner: ownerId,
      }
    );
    return newTabFocus;
  }
);


export const getIsFocused = createSelector(
  [(params) => params],
  (params: { state: CommonRootState; taskId: string }) => {
    const tabId = selectNewTabFocusByOwnerId(params.state);
    const focusedTaskId = tabId?.taskId;
    return focusedTaskId === params.taskId;
  }
);


export const selectGroupsWithTaskLength = createSelector(
  [
    (state) => state.DBTaskgroupsReducer,
    (state) => state.DBTasksReducer,
    (state) => getSelectedBoardId(state),
  ],
  (taskGroupReducer, tasksReducer, boardId) => {
    const groups = taskGroupsSelectByQuery(taskGroupReducer, {
      boardId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });

    const groupId = groups[groups.length - 1]?._id;

    if (!groups.length) {
      return { groups: [], lastGroupTaskListLength: 0 };
    }

    const lastGroupId = groups[groups.length - 1]?._id;
    const lastGroupTaskListLength =
      tasksSelectByQueryOnlyMaxMin(
        tasksReducer,
        {
          groupId: lastGroupId,
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          taskType: { $ne: "subTask" },
        },
        "order",
        "max"
      ) ?? 0;

    return { groupId, lastGroupTaskListLength };
  }
);

export const getCountOfTasksLeftToDo = createSelector(
  [
    (state) => selectElementsList(state),
    (state) => getColumnOptionByValueDone(state),
  ],
  (elementList, columnOptions) => {
    const { firstStatusColumn, doneColumnOption } = columnOptions;

    const fullElementList = elementList.filter((e) => e?.type === "task");

    if (!fullElementList.length) {
      return 0;
    }

    const tasksLeftToDo = fullElementList.filter(
      (e) =>
        e.customData?.[firstStatusColumn?._id]?.value !== doneColumnOption?._id
    );

    return tasksLeftToDo.length;
  }
);

export const getColumnOptionByValueDone = createSelector(
  [(state) => state, (state) => getSelectedBoardId(state)],
  (state, boardId) => {
    const boardColumns: TaskColumn[] = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const firstStatusColumn = boardColumns.filter(
      (column) => column.type === "status-option-picker"
    )[0];

    const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: firstStatusColumn?._id,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      undefined,
      {
        order: 1,
      }
    );

    const doneColumnOption = columnOptions.filter(
      (columnOption) => columnOption.label === "Done"
    )[0];

    return { firstStatusColumn, doneColumnOption };
  }
);

export const getFocusPreviousAndNextTaskIdByCurrentTaskId =
  createSelector(
    [
      (state, taskId) => state,
      (state, taskId) => taskId,
      (state, taskId) => getColumnOptionByValueDone(state),
    ],
    (state, taskId, { firstStatusColumn, doneColumnOption }) => {
      const fullElementList = selectElementsList(state).filter(
        (e) => e?.type === "task"
      );

      if (!taskId && fullElementList.length > 0) {
        taskId = fullElementList[0]?.id;
      }

      const filteredElementList = fullElementList.filter((e) => {
        return (
          e.customData?.[firstStatusColumn?._id]?.value !==
          doneColumnOption?._id
        );
      });

      const currentTaskIndex = fullElementList.findIndex(
        (e) => e?.id === taskId
      );

      const findNextValidTask = (startIndex, direction) => {
        let index = startIndex;
        let iterations = 0;

        while (iterations < fullElementList.length) {
          index += direction;

          if (index >= fullElementList.length) {
            index = 0;
          } else if (index < 0) {
            index = fullElementList.length - 1;
          }

          const potentialTask = fullElementList[index];
          if (filteredElementList.includes(potentialTask)) {
            return potentialTask?.id;
          }

          iterations++;
        }

        return null;
      };

      return {
        previous: findNextValidTask(currentTaskIndex, -1),
        next: findNextValidTask(currentTaskIndex, 1),
      };
    }
  );

export const getGroupFocusLength = createSelector(
  [(state) => state, (_, groupId) => ({ groupId })],
  (state: CommonRootState, { groupId }) => {
    if (!groupId) return "";

    const groupTasksLength =
      tasksSelectByQueryOnlyMaxMin(
        state.DBTasksReducer,
        {
          groupId: groupId,
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          taskType: { $ne: "subTask" },
        },
        "order",
        "max"
      ) ?? 0;

    return groupTasksLength;
  }
);

export const getIsFocusedTaskDone = createSelector(
  [
    (state, focusedTaskId) => tasksSelectors.selectById(state, focusedTaskId),
    (state) => getColumnOptionByValueDone(state),
  ],
  (task, { firstStatusColumn, doneColumnOption }) => {
    return (
      task?.customData?.[firstStatusColumn?._id]?.value ===
      doneColumnOption?._id
    );
  }
);

export const setTaskDoneThunk = createAsyncThunk(
  "tasks/setTaskDone",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const tabData = selectNewTabFocusByOwnerId(state);
    const task = tasksSelectors.selectById(state, tabData.taskId);
    const countOfTasksLeft = getCountOfTasksLeftToDo(state);
    const { firstStatusColumn, doneColumnOption } =
      getColumnOptionByValueDone(state);

    if (!task || !firstStatusColumn || !doneColumnOption) {
      throw new Error("Required data is missing");
    }

    await dispatch(
      DBTasksThunks.patch({
        entity: {
          _id: task._id,
          customData: {
            ...task?.customData,
            [firstStatusColumn._id]: {
              value: doneColumnOption._id,
            },
          },
        },
      })
    );
  
  }
);

export const clearTaskThunk = createAsyncThunk(
  "newTabFocus/clearTask",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const tabData = selectNewTabFocusByOwnerId(state);

    dispatch(
      DBNewTabFocusThunks.patch({
        entity: {
          _id: tabData._id,
          taskId: null,
          timerShown: false,
          isRunning: false,
          expiryTimestamp: null,
          pauseTimestamp: null,
          remainingTime: null,
          stopwatchRunning: false,
          stopwatchStartTime: null,
          stopwatchPausedTime: null,
        },
      })
    );
  }
);


export const focusTabCreateNewTaskThunk = createAsyncThunk(
  "newTabFocus/focusTabCreateNewTaskThunk",
  async (text: string, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();

    const boardId = getSelectedBoardId(state);
    const { groupId, lastGroupTaskListLength } =
      selectGroupsWithTaskLength(state);

    try {
      const newTask = {
        text: text,
        boardId: boardId,
        groupId: groupId,
        order: lastGroupTaskListLength + 1,
      };

      const res = await dispatch(DBTasksThunks.create(newTask)).unwrap();
      dispatch(setFocusedTask({ taskId: res?._id }));
      return res;
    } catch (error) {
      // Handle any errors here
      console.error("Failed to create new task:", error);
      throw error; // Rethrow the error to indicate failure in the thunk
    }
  }
);

export const switchTaskThunk = createAsyncThunk(
  "newTabFocus/focusTabCreateNewTaskThunk",
  async (nextOrPrevious: string, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();

    const tabData = selectNewTabFocusByOwnerId(state);
    const { previous: previousTask, next: nextTask } =
      getFocusPreviousAndNextTaskIdByCurrentTaskId(state, tabData?.taskId);
    const taskId = nextOrPrevious === "next" ? nextTask : previousTask;
    dispatch(setFocusedTask({ taskId }));
  }
);

export const setFocusedTask = createAsyncThunk<
  void,
  { taskId: string },
  { state: any }
>("newTabFocus/selectRowItemWithThunk", async (params, thunkAPI) => {
  const { taskId } = params;
  const state: CommonRootState = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  const tabId = selectNewTabFocusByOwnerId(state);
  const task = tasksSelectors.selectById(state, taskId);
  if (taskId) {
    dispatch(
      DBNewTabFocusThunks.patch({
        entity: {
          _id: tabId._id,
          taskId: task._id,
          isRunning: false,
          expiryTimestamp: null,
          pauseTimestamp: null,
          remainingTime: null,
          stopwatchRunning: false,
          stopwatchStartTime: null,
          stopwatchPausedTime: null,
        },
      })
    );
  }
  // else {
  //   dispatch(
  //     DBNewTabFocusThunks.patch({
  //       entity: {
  //         _id: tabId._id,
  //         taskId: null,
  //         isRunning:false,
  //                   expiryTimestamp:null,
  //                   pauseTimestamp:null,
  //                   remainingTime:null,
  //       },
  //     })
  //   );
  // }
});


export const updateOrCreateTabFocus  = createAsyncThunk<
  void,
  void,
  {
    state: CommonRootState;
  }
>("newTabFocus/updateOrCreateTabFocus ", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  const tabId = selectNewTabFocusByOwnerId(state);
  if (tabId?._id) {
    dispatch(
      DBNewTabFocusThunks.patch({
        entity: {
          _id: tabId._id,
          taskId: tabId.taskId,
        },
      })
    );
  } else {
    dispatch(
      DBNewTabFocusThunks.create({
        timerShown: false,
        stopWatchOrTimer: "timer",
        expanded: false,
        isRunning: false,
      })
    );
  }
});



export const createTabFocusThunk = createAsyncThunk(
  "newTabFocus/createTabFocus",
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      DBNewTabFocusThunks.create({
        timerShown: false,
        stopWatchOrTimer: "timer",
        expanded: false,
        isRunning: false,
      })
    );
  }
);

export const toggleTimerThunk = createAsyncThunk(
  "newTabFocus/toggleTimerThunk",
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const tabId = selectNewTabFocusByOwnerId(state);
    dispatch(
      DBNewTabFocusThunks.patch({
        entity: {
          _id: tabId._id,
          timerShown: !tabId.timerShown,
        },
      })
    );
  }
);