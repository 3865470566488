import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BoardsState {
  newWaBoardStatus: string;
  openMenuByBoardId: string;
  boardsFilterName: string;
  selectedBoardId: string;
  isShowAll: boolean;
  boardEditModeByBoardId: string;
  isBoardNameEditting: Boolean;
  isBoardItemMenuOpen: boolean;
  menuAnchorEl: any;
  resizing?: boolean;
  resizingColumn?: string;
  boardTheme?: string;
  isRenameBoard?: boolean;
}

const initialState: BoardsState = {
  newWaBoardStatus: "",
  openMenuByBoardId: "",
  boardsFilterName: "",
  selectedBoardId: "",
  isShowAll: false,
  boardEditModeByBoardId: "",
  isBoardNameEditting: false,
  isBoardItemMenuOpen: false,
  menuAnchorEl: null,
  boardTheme: "",
  isRenameBoard: false,
};

export interface SetSelectedBoardIdOptions {
  boardId: string;
}

const BoardsSlice = createSlice({
  name: "boards",
  initialState,
  reducers: {
    setNewWaBoardStatus(state, action: PayloadAction<string>) {
      state.newWaBoardStatus = action.payload;
    },
    setOpenMenuByBoardId(state, action: PayloadAction<string>) {
      state.openMenuByBoardId = action.payload;
    },
    setBoardsFilterName(state, action: PayloadAction<string>) {
      state.boardsFilterName = action.payload;
    },
    privateSetSelectedBoardId(state, { type, payload }: PayloadAction<string>) {
      state.selectedBoardId = payload;
    },
    toggleShowAllElements(state, { type, payload }: PayloadAction<any>) {
      state.isShowAll = payload;
    },
    openBoardItemMenu(state, { payload }: PayloadAction<boolean>) {
      state.isBoardItemMenuOpen = payload;
    },
    setMenuAnchorEl(state, { payload }: PayloadAction<any>) {
      state.menuAnchorEl = payload;
    },
    setBoardEditModeByBoardId(state, { payload }: PayloadAction<string>) {
      state.boardEditModeByBoardId = payload;
    },
    setResizing(state, { payload }: PayloadAction<boolean>) {
      state.resizing = payload;
    },
    setBoardTheme(state, { payload }: PayloadAction<string>) {
      state.boardTheme = payload;
    },
    setRenameBoard(state, { payload }: PayloadAction<boolean>) {
      state.isRenameBoard = payload;
    },
  },
});

export const boardsActions = BoardsSlice.actions;
export default BoardsSlice.reducer;
