import BulkSearchField from '@extension/context/content/components/bt-bulk-dashboard/bulk-search-field';
import LazyLoadingVirtuoso from '@extension/context/content/components/bt-bulk-dashboard/lazy-loading-virtuoso';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { getBoardUser } from '@common-reducers/BoardsSelectors';
import { DBNotificationsThunks, notificationsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useEffect, useState } from 'react';
import NotificationRow from './notification-row';
import classes from "./notifications-drawer.module.scss";
import { selectUser } from '@common-reducers/UserSelectors';





export default function NotificationssDrawer() {
    const dispatch = useAppDispatch()

    const boardUserId = useAppSelector((state) => getBoardUser(state))
    const user = useAppSelector(selectUser);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterObj, setFilterObj] = useState({});
    const [activeTab, setActiveTab] = useState('all');


    const notificationsTabs = [
        { label: 'All', value: 'all', onClick: () => setActiveTab('all') },
        { label: 'Unread', value: 'unread', onClick: () => setActiveTab('unread') },
        { label: 'I was mentioned', value: 'iWasMentioned', onClick: () => setActiveTab('iWasMentioned') },
        { label: 'Assigned to me', value: 'assignedToMe', onClick: () => setActiveTab('assignedToMe') },
    ]

    const updateFilterObj = () => {
        if (activeTab === 'all') {
            setFilterObj({})
        }
        if (activeTab === 'unread') {
            setFilterObj({
                seen: false,
            })
        }
        if (activeTab === 'iWasMentioned') {
            setFilterObj({
                actionType: 'mentioned',
            })
        }
        if (activeTab === 'assignedToMe') {
            setFilterObj({
                notificationUpdateType: 'person-picker',
                actionType: 'assigned',
                newValue: {
                    _id: boardUserId,
                },
            })
        }
        if (searchTerm !== '') {
            let searchTermRegex = new RegExp(searchTerm, 'i');
            setFilterObj({
                ...filterObj,
                $or: [
                    { triggerByData: { $regex: searchTermRegex } },
                    { actionType: { $regex: searchTermRegex } },
                    { actionOnEntityData: { $regex: searchTermRegex } },
                    { boardName: { $regex: searchTermRegex } },
                    { taskName: { $regex: searchTermRegex } }
                ]
            })
        }
    }

    useEffect(() => {
        updateFilterObj()
    }, [activeTab, searchTerm])

    useEffect(() => {
        dispatch(DBNotificationsThunks.find({
            owner: user._id,
            deleted: false,
        }))
    }, [dispatch])

    return (
        <Stack className={classes.notifications_wrapper}>
            <h1>Notifications</h1>
            <Stack direction='row' className={classes.notifications_tabs_wrapper}>
                {
                    notificationsTabs.map((tab, i) => {
                        return (
                            <Button key={i}
                                className={classes.notifications_tab}
                                sx={{
                                    flexGrow: 1,
                                    opacity: tab.value === activeTab && 1,
                                    fontWeight: tab.value === activeTab && 600,
                                    borderBottom: tab.value === activeTab && '2px rgb(87, 155, 252) solid !important',
                                }}
                                onClick={tab.onClick}
                            >
                                {tab.label}
                            </Button>
                        )
                    })
                }
            </Stack>
            <BulkSearchField
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                placeHolderString="Search notifications"
                sx={{ marginBottom: '10px', border: '1px rgba(0, 0, 0, 0.1) solid' }}
            />
            <LazyLoadingVirtuoso
                height={790}
                // rowHeightString='auto'
                filterObj={{
                    notificationUpdateType: { $in: allowedNotificationUpdateTypes },
                    ...filterObj
                }}
                DBThunks={DBNotificationsThunks}
                DBReducer={state => state.DBNotificationsReducer}
                selectByQuery={notificationsSelectByQuery}
                Component={NotificationRow}
                isFullWidth={true}
            />
        </Stack>
    )
}

const allowedNotificationUpdateTypes = [
    'new extra data',
    'updated extra data',
    'deleted extra data',
    'extra data reaction',
    'new task',
    'delete task',
    'restore task',
    'task name',
    'board name',
    'group name',
    'column name',
    'person-picker',
    'subscription',
]