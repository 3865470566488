import CreateGoogleSheetsAudience from '@extension/context/content/components/bt-bulk-dashboard/audiences/create-google-sheets-audience'
import classes from './import-excel.module.scss'
import React from 'react'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { BsCheckCircleFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import Dropzone from './dropzone'
import { importItemsActions } from '@common-reducers/ImportItemsReducer'
import { getRuntimeUrl } from '@common-services/storage'
import FileSaver from 'file-saver'
import { getBoardSampleCSVThunk } from '@common-reducers/BoardsThunks'
import { supportedColumnTypes } from './import-excel'
import { useTranslation } from 'react-i18next'
import { namespace } from '@common/config/constants'


type Props = {
    goNext: (isFromSheets?: boolean) => void;
}

export default function FecthDataToImportDialog({ goNext }: Props) {
    const { t } = useTranslation(namespace);

    const dispatch = useAppDispatch()

    const importType = useAppSelector((state) => state.ModalReducer.importType)
    const importFileName = useAppSelector((state) => state.ImportItemsReducer.importFileName);
    const openedby = useAppSelector((state) => state.ModalReducer.openedby)


    const downloadFile = async () => {
        if (openedby === 'campaign') {
            const src = getRuntimeUrl('img/sample.csv');
            FileSaver.saveAs(src, 'sample.csv');
        } else {
            const donwloadSampleFile = await dispatch(getBoardSampleCSVThunk({ supportedColumnTypes }))
        }
    };


    return (
        <>
            <div className={classes.columnDescription} style={{ width: 'unset' }}>
                <h3 style={{ display: 'flex', alignItems: 'center', fontWeight: 700, }}>
                    {t('importExcelToBlueticks.importItemsFromSpreadsheet')}</h3>
                <p>
                    {t('importExcelToBlueticks.transferRecords')}
                </p>
            </div>
            {importType === 'csv' && importFileName &&
                <div className={classes.fileToImport}>
                    <div style={{
                        marginBottom: 15,
                        display: 'flex',
                        justifyContent: 'center', flexBasis: '100%'
                    }}>
                        <BsCheckCircleFill color='#00c875' size={35} />
                    </div>
                    <p style={{ margin: '20px', }}> {t('importExcelToBlueticks.yourFileHasBeenUploadedSuccessfully')}</p>
                    <div className={classes.importFile}>
                        {importFileName}
                        <button onClick={() =>
                            dispatch(importItemsActions.resetImport())}>
                            <MdClose size={18} /></button>
                    </div>
                </div>
            }
            {importType === 'csv' && !importFileName &&
                <div className={classes.dropzoneCompWrap}>
                    <Dropzone />
                </div>
            }


            {importType === 'google' &&
                <CreateGoogleSheetsAudience goNext={goNext} />
            }

            <div className={classes.learnMore}>
                <h4>{t('importExcelToBlueticks.wonderingHowToGetStarted')}</h4>
                <p> <span style={{ color: '#1f76c2', cursor: 'pointer' }} onClick={downloadFile}>{t('importExcelToBlueticks.download')}</span>  {t('importExcelToBlueticks.sampleExcelFile')}</p>
            </div>
        </>
    )
}