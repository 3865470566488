import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { Box, ListItemIcon, MenuItem, Stack, TextField } from "@mui/material";
import { AugmentedBlueticksBoardUser } from "@common-reducers/BoardsSelectors";
import React from 'react';
import classes from "./person_picker.module.scss";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";



type Props = {
  cellPersonList: AugmentedBlueticksBoardUser[];
  clearValue?: (value: AugmentedBlueticksBoardUser) => void;
  searchedPersonName: string;
  filterPeopleList: (e: any) => void;
  setValue: (value: React.SetStateAction<string>) => void;
  filteredBoardMemberList: AugmentedBlueticksBoardUser[];
  onValueUpdate: (e: any, targetPerson: AugmentedBlueticksBoardUser) => void;
  boardMemberList: AugmentedBlueticksBoardUser[];
  ctrlOrMetakey: "⌘" | "Ctrl";
  isEditing?: boolean;
}

export default function PersonPickerMappedOptions({
  cellPersonList,
  clearValue,
  searchedPersonName,
  filterPeopleList,
  setValue,
  filteredBoardMemberList,
  onValueUpdate,
  boardMemberList,
  ctrlOrMetakey,
  isEditing,
}: Props) {
  const { t } = useTranslation(namespace);
  // const dispatch = useAppDispatch()
  const inputTheme = {
    width: '100%',
    ".MuiFormControl-root": {
      width: '100%'
    },
    ".MuiInputBase-root": {
      background: 'var(--default-background)',
      border: '1px solid #DEEEF2',
      borderRadius: '8px',
      '& .MuiInputBase-input': {
        padding: '6.5px 10px',
        fontSize: '14px'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        boxShadow: '2px 6px 12px rgba(80, 187, 238, 0.0625)'
      },
    }
  }

  // const handleMockPersonDelete = (Id) => {
  //   dispatch(DBTaskColumnOptionThunks.delete({
  //     entity: {
  //       _id: Id,
  //     }
  //   }))
  // }


  return (
    <Box sx={{ width: '250px' }}
      onClick={(event) => event.stopPropagation()}
      className={classes.personPicker}>
      {cellPersonList &&
        <Stack sx={{ padding: "5px" }} direction="row" justifyContent="center" alignItems="center" flexWrap="wrap">
          {cellPersonList?.map((person, index) => {
            return (
              <Stack key={index} justifyContent="center" alignItems="center" direction="row" sx={{ marginRight: "10px", padding: "5px 0" }}>
                <PersonAvatar
                  email={person?.email}
                  profileImg={person?.profile?.profileImg}
                  lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                  firstName={person?.profile?.firstName} toolTip={true} />
                <CancelIcon sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {clearValue(person)}} color="action" />
              </Stack>
            )
          })}
        </Stack>
      }

      <Box sx={{ padding: "5px" }}>
        <TextField
          sx={inputTheme}
          hiddenLabel
          value={searchedPersonName}
          placeholder={t('personPickerMappedOptions.searchPerson')}
          onChange={(e) => filterPeopleList(e)}
          className={classes.search_input}
          InputProps={{
            endAdornment: searchedPersonName === '' ? <SearchIcon sx={{ fontSize: 16 }} /> : <CloseIcon onClick={() => setValue('')}
              sx={{ fontSize: 12, cursor: 'pointer' }}
            />
          }}
        />
      </Box>

      <div style={{
        maxHeight: "400px",
        overflowX: "auto"
      }}>
        {filteredBoardMemberList.filter(item => {
          return `${item?.profile?.firstName} ${item?.profile?.lastName}`.toLowerCase().includes(searchedPersonName.toLowerCase())
        })
          ?.map((person, i) => {
            return (
              <Box key={i} onClick={(e) => onValueUpdate(e, person)}>
                <MenuItem sx={{ fontSize: 12 }}>
                  <Stack direction='row' sx={{ display: 'flex', justifyContent: 'space-between', }}>
                    <ListItemIcon>
                      <PersonAvatar
                        email={person?.email}
                        profileImg={person?.profile?.profileImg}
                        lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                        firstName={person?.profile?.firstName}
                        isName={true} 
                        style={{ alignItems: 'center' }}
                        />
                    </ListItemIcon>
                  </Stack>
                </MenuItem>
              </Box>
            );
          })}

        {filteredBoardMemberList.filter(item => {
          return `${item?.profile?.firstName} ${item?.profile?.lastName}`.toLowerCase().includes(searchedPersonName.toLowerCase())
        }).length === 0 && searchedPersonName !== '' && (
            <div style={{ padding: "10px", textAlign: "center" }}>{t('personPickerMappedOptions.noResultsFound')}</div>
          )}

        {boardMemberList.length > 1 &&
          <Stack direction={'row'} alignItems={'center'} sx={{ padding: '10px 16px', fontSize: 12, borderRadius: 0 }}>
            <TouchAppIcon sx={{ fontSize: 16, padding: '0 7px', marginBottom: '3px' }} />
            <Box> {t('personPickerMappedOptions.hold')}
              <b style={{
                border: '1px dashed',
                padding: "1px 5px",
                margin: '0 3px',
              }}>{ctrlOrMetakey}</b>
              {t('personPickerMappedOptions.toPickMultiple')}
            </Box>
          </Stack>
        }
      </div>





    </Box>
  )
}