import React, { memo, useEffect, useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import classes from "./group_header.module.scss";
import SortButton from "./sort-button/sort-button";
import ColumnCellResizeable from "../columns-cells/column-cell-resizeable";
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { DEFAULT_COLUMN_WIDTH, TaskColumn } from "@common-models/task-column";

import CellWrapper from "../columns-cells/cell-wrapper";
import EditableColumnHeaderCell from "../columns-cells/editable-column-header-cell/editable-column-header-cell";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { columnTypeList } from "../columns-cells/column-cell-defs";
import { getBlueticksThemeSelector } from '@common-reducers/BoardsSelectors';

const ColumnItem = ({ column, index, tasksGroup, board, isGroupDragging, columnListLength, isDraggingOver, parentProvided }) => {
  const columnKey = `column_${tasksGroup?._id}_${column._id}_${isDraggingOver}`;

  const theme = useAppSelector((state) => getBlueticksThemeSelector(state));
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const customColumnCell = (column, provided, snapshot) => {
    const cellId = `column_${tasksGroup?._id}_${column._id}`;
    const draggingStyle = snapshot?.isDragging ? {
      borderRight: '1px dashed',
      borderLeft: '1px dashed',
      borderTop: '1px dashed',
      borderBottom: '1px dashed',
    } : {};

    let cell;
    const columnType = columnTypeList.find(type => type.type === column.type);

    if (columnType) {
      cell = (
        <Box sx={{ flex: 'auto', backgroundColor: theme?.boards?.tasksPanel?.background }} data-testid={column.type}>
          <CellWrapper
            columnId={column?._id}
            groupId={tasksGroup?._id}
            boardId={tasksGroup?.boardId}
            cellComponent={columnType.headerComponent ?? EditableColumnHeaderCell} />
        </Box>
      );
    }

    const headerHandleProps = columnType?.private ? parentProvided?.dragHandleProps : provided?.dragHandleProps;
    const columnClass = `column_${column._id}`

    return (
      <Stack
        direction="row"
        alignItems="center"
        className={`${classes.date} ${!snapshot ? classes.placeholder : ''} ${columnClass}`}
        id={cellId}
        {...provided?.draggableProps}
        {...headerHandleProps}
        style={{
          ...provided?.draggableProps?.style,
          width: column.type === 'row-prefix' ? board?.textColumnWidth ?? 400 : column.width ?? DEFAULT_COLUMN_WIDTH,
          borderInlineEnd: '1px solid transparent',
          borderInlineStart: direction === "rtl" && column.type === 'row-prefix' ? '1px solid transparent' : undefined,
          boxSizing: 'border-box',
         
          //transition: '0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
          //transition: 'width 0.1s'

          ...draggingStyle,
        }}
        ref={provided?.innerRef}
      >
        {cell}
      </Stack>
    );
  };



  return (
    <ColumnCellResizeable
      columnId={column?._id}
      key={columnKey}
      disabled={column.static && column._id !== "row-prefix"}
    >
      <Draggable
        isDragDisabled={column?.static}
        key={columnKey}
        index={index}
        draggableId={`column_${tasksGroup?._id}_${column?._id}`}

      >
        {(provided, snapshot) => (
          <div
            className={classes.todoCell}
            data-testid={`column-header-type-${column.type}`}
          >
            {customColumnCell(column, provided, snapshot)}
            {!isGroupDragging && !tasksGroup?.isCollapsed && column.type !== 'row-prefix' && column.type !== 'context-menu' && column.type !== 'chat' && column.type !== 'row-suffix' && columnListLength > 2 && <SortButton columnId={column?._id} />}
          </div>
        )}
      </Draggable>
    </ColumnCellResizeable>
  );
};

export default ColumnItem;
