import { getSelectedBoardId } from '@common-reducers/BoardsSelectors';
import { DBTaskgroupsThunks, DBTasksThunks, boardsSelectByQuery, taskGroupsSelectByQuery, tasksSelectors } from "@common-reducers/DBServiceThunks";
import { mainSliceActions } from '@common-reducers/MainReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import classes from './move-to-menu.module.scss';


const MoveGroupToBoardSelector = () => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const [filterName, setFilterName] = useState<string>('')
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const tasksList = useAppSelector(tasksSelectors.selectAll)
    const menuActiveGroupId = useAppSelector((state) => state.MainReducer.menuActiveGroupId)
    const boards = useAppSelector((state) => boardsSelectByQuery(
        state.DBBoardsReducer,
        {
            name: new RegExp(filterName, "i"),
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
        [
            "_id",
            "updatedAt",
            "name",
            "titleIcon",
        ],
        {
            updatedAt: -1,
        }
    ))

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer,
        {
            boardId:  boardId,
            name: new RegExp(filterName, "i"),
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    ))
    const moveGroupToBoardThunk = (boardId: string) => {
        const groupToMove = groups.find((group) => group._id === menuActiveGroupId);
        const tasksOfThegroup = tasksList.filter((task) => task.groupId === menuActiveGroupId);
        
        if (tasksOfThegroup.length) {
            const changes = tasksOfThegroup.map((task) => {
                return {
                    id: task._id,
                    changes: {
                        boardId
                    },
                };
            });

            dispatch(DBTasksThunks.patchMany(changes));
        }
        
        dispatch(DBTaskgroupsThunks.patch({ entity: { ...groupToMove, boardId } }));
        dispatch(mainSliceActions.toggleGroupMenu(''));
    };

    const listItemStyles: any = {
        width: 198,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    
    function handleSearch({ target }: any) {
        setFilterName(target.value)
    }
    return (
        <>
             <Box className={classes.filter_container} onClick={(e) => e.stopPropagation()}>
                    <input type="text" onChange={handleSearch} placeholder={t('moveGroupToBoardSelector.searchPlaceholder')} />
                    <button>
                        <BsSearch />
                    </button>
                </Box>
            {boards.map((board, index) => (
                <button key={index}
                    style={listItemStyles}
                    onClick={() => moveGroupToBoardThunk(board._id)}>
                    {board?.titleIcon} {board.name}
                </button>
            ))}
        </>
    );
}

export default MoveGroupToBoardSelector;
