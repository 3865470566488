import { MenuItem, Select } from '@mui/material';
import { getColumnList } from '@common-reducers/TaskSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React from 'react';

type FilterRowColumnFieldProps = {
    columnField: string;
    onColumnChange: (columnItemId: string, columnItemValue: string) => void;
};

export const FilterRowColumnField: React.FC<FilterRowColumnFieldProps> = ({ columnField, onColumnChange }) => {
    const columnList = useAppSelector(getColumnList);

    const handleChange = (value, id) => {
        onColumnChange(id, value);
    };

    return (
        <Select
            id=""
            label=""
            name={'column'}
            value={columnField || ''}
            sx={{ width: '100%', height: '47px', }}
            placeholder="Column"
        >
            <MenuItem disabled value="">
                <span style={{ fontSize: "14px" }}>Column</span>
            </MenuItem>
            <MenuItem onClick={() => handleChange('Group', 'groupColumn')}
                key={"groupColumn"} value='Group'>
                <span style={{ fontSize: "14px" }}>Group</span>
            </MenuItem>
            {columnList.map((column) => (

                <MenuItem onClick={() => handleChange(column.title, column._id)}
                    key={column._id} value={column.title} >
                    <span style={{ fontSize: "14px" }}>{column.title}</span>
                </MenuItem>
            ))}
        </Select>
    );
}
