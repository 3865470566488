import { Box, Stack } from "@mui/system";
import { automation } from "@common-reducers/AutomationReducer";
import { selectMappedTaskGroups } from "@common-reducers/AutomationsSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import CustomDropdown from "../automation-dropdown";
import { setCurrentTrigger } from "../automations-helper";

const TaskMovedToGroupTrigger = () => {
    const dispatch = useAppDispatch()
    const [groupName, setGroupName] = React.useState<string>('Group');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
    };
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const groups = useAppSelector(selectMappedTaskGroups);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColumnChange = (option) => {
      
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, {groupId: option._id})));

        setGroupName(option.label);

        handleClose();
    };

 
    useEffect(() => {
        const selectedGroupId = triggerObj.groupId;
        const group = groups.find(item => item._id === selectedGroupId);
        setGroupName(group?.label ?? "Group")
        dispatch(automation.fieldValidityChanged({ component: 'TaskMovedToGroupTrigger', index: 0, field: 'groupId', isValid: !!selectedGroupId }));
    }, [triggerObj, groups, dispatch]);
    return (
        <Stack direction="row" justifyContent={"space-between"} sx={{ fontSize: "24px" }}>
            <Box>When a task is moved to
                <AutomationButton
                    componentName='TaskMovedToGroupTrigger'
                    index={0}
                    option='groupId'
                    label={groupName}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <AddAndRemove elements={conditions} index={0} type={"trigger"} />


            <CustomDropdown
                options={groups}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleColumnChange}
                onHoverOption={null}
            />
        </Stack>

    )
}
export default TaskMovedToGroupTrigger