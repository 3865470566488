import { Paper, PaperProps } from '@mui/material';
import React from 'react';

export const BTPaperProps = {
    color: 'var(--primary)',
    backgroundColor: "var(--background-default)",
    borderRadius: '5px !important',
    boxShadow: '0 5px 12px var(--shadow-default-color)',
    overflow: 'hidden',
};

export const BTPaper = React.forwardRef(({ children, ...props }: PaperProps, ref: any) => {
    return <Paper ref={ref} sx={{
        ...props.sx,
        ...BTPaperProps,
    }} {...props}>{children}</Paper>
});
