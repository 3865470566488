import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import { IconButton } from '@mui/material';
import { getSelectedPersons } from '@common-reducers/TaskSelectors';
import { taskActions } from '@common-reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { FC } from "react";

export interface PersonProp {
    person: any;
    size?:number;
}

export const MemberListFilter: FC<PersonProp> = (props) => {
    const dispatch = useAppDispatch();
    const personUserId = props.person._id;
    const isPersonSelected = useAppSelector((state) => getSelectedPersons(state));
    function filterByPerson(e, person: any) {
        e.stopPropagation();
        dispatch(taskActions.setPersonFilter(isPersonSelected !== personUserId  ? personUserId : ''  ))
    
    }
    return (
        <IconButton sx={{ padding: '0',border: isPersonSelected === personUserId  ? '1px solid #006dff':'',boxShadow:isPersonSelected === personUserId  ? '0 5px 12px rgb(0 0 0 / 15%)' : '' }} onClick={(e) => filterByPerson(e, props.person)}>
            <PersonAvatar 
            size={props.size ? props.size : 28} 
            email={props.person.email}
            profileImg={props.person?.profile?.profileImg}
            lastName={props.person?.profile?.lastName}
            firstName={props.person?.profile?.firstName}
            toolTip={true} />
        </IconButton>
    )
}

