import { ExtraDataItem } from "@common-models/ExtraDataItem";
import { EntitySubscriber } from "@common-models/entity-subscriber";
import { AppThunkDispatch } from "@common/types/common-root-state-type";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import { createSelector } from "@reduxjs/toolkit";
import { getSelectedBoardId, getSelectedBoardUserList } from "./BoardsSelectors";
import { DBEntitySubscribersThunks, DBTasksExtraDataThunks, entitySubscribersSelectByQuery, entitySubscribersSelectOneObjectByQuery, tasksSelectors } from "@common-reducers/DBServiceThunks";
import { selectUserObject } from "./UserSelectors";




const handleSubscritionCreationOrUpdate = async (newEntitySubscriber: EntitySubscriber, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const boardId = getSelectedBoardId(state)
    const entitySubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            ...newEntitySubscriber,
        })

    /// check if the is an entity subscriber with entityType = 'board' and same userId and boardId with deleted= false
    const boardSubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            userId: newEntitySubscriber.userId,
            boardId,
            entityType: 'board',
        })

    if (!boardSubscriber) {
        if (entitySubscriber && entitySubscriber.deleted) {

            await dispatch(DBEntitySubscribersThunks.patch({ entity: { ...entitySubscriber, deleted: false } }))
        }
        if (!entitySubscriber) {
            await dispatch(DBEntitySubscribersThunks.create(newEntitySubscriber))
        }
    }
    await dispatch(DBEntitySubscribersThunks.find({
        $paginate: false,
        boardId,
        entityType: 'board',
        deleted: false,
    }))
    await dispatch(DBEntitySubscribersThunks.find({
        $paginate: false,
        boardId,
        taskId: newEntitySubscriber.taskId,
        entityType: 'task',
        deleted: false,
    }))
}


const handleSubscritionCancelation = async (newEntitySubscriber: EntitySubscriber, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();

    const entitySubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            ...newEntitySubscriber,
        })

    if (entitySubscriber && !entitySubscriber.deleted) {
        const newEntitySubscriber = {
            ...entitySubscriber,
            deleted: true
        }
        await dispatch(DBEntitySubscribersThunks.patch({ entity: newEntitySubscriber }))
    }
    await dispatch(DBEntitySubscribersThunks.find({
        $paginate: false,
        boardId: newEntitySubscriber.boardId,
        entityType: 'board',
        deleted: false,
    }))
    await dispatch(DBEntitySubscribersThunks.find({
        $paginate: false,
        boardId: newEntitySubscriber.boardId,
        taskId: newEntitySubscriber.taskId,
        entityType: 'task',
        deleted: false,
    }))

}


export const CreateExtraDataAndEntitySuscribersThunk = createAsyncThunk<
    void,
    {
        editorValue: any;
        taskId: string;
    },
    { state: CommonRootState }
>("tasks/addNewTaskGroupThunk", async ({ editorValue, taskId }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const task = tasksSelectors.selectById(state, taskId)
    const boardId = getSelectedBoardId(state)
    const user = selectUserObject(state)

    let _editorValue = editorValue.editorValue;
    let parentItemId = editorValue.parentItemId;

    const mentionedUsersIds = []
    for (let children of _editorValue) {
        for (let descendant of children?.children) {
            if (descendant?.type && descendant?.type === 'mention') {
                mentionedUsersIds.push(descendant.userId)
            }
        }
    }


    const extraDataItem: ExtraDataItem = {
        taskId: task._id,
        groupId: task.groupId,
        boardId: task.boardId,
        author: "",
        extraData: _editorValue,
        isUpdating: false,
        isPinedToTop: false,
        parentItemId,
        seenByIdArray: [user._id],
    };
    dispatch(DBTasksExtraDataThunks.create(extraDataItem));

    for (let userId of mentionedUsersIds) {
        await handleSubscritionCreationOrUpdate({
            userId,
            entityType: 'task' as 'task' | 'board',
            taskId: task._id,
            boardId,
        }, thunkAPI)
    }

});


export const createSubscriptionThunk = createAsyncThunk<
    void,
    { userId },
    { state: CommonRootState }
>("tasks/addNewTaskGroupThunk", async ({ userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
    const boardId = getSelectedBoardId(state)

    await handleSubscritionCreationOrUpdate({
        userId,
        entityType: 'task' as 'task' | 'board',
        taskId,
        boardId,
    }, thunkAPI)
});


export const cancelSubscriptionThunk = createAsyncThunk<
    void,
    { userId },
    { state: CommonRootState }
>("tasks/addNewTaskGroupThunk", async ({ userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
    const boardId = getSelectedBoardId(state)

    await handleSubscritionCancelation({
        userId,
        entityType: 'task' as 'task' | 'board',
        taskId,
        boardId,
    }, thunkAPI)

});




export const getTaskSubscribedUsersList = createSelector(
    [
        (state) => state,
        (state) => getSelectedBoardUserList(state)
    ],
    (state, boardUserList) => {
        const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
        const boardId = getSelectedBoardId(state);
        const taskSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                taskId,
                entityType: 'task',
                deleted: false,
            }
        );
        const boardSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                entityType: 'board',
                deleted: false,
            }
        );
        const userIdsList = [...taskSubscribersList, ...boardSubscribersList]
            .map(subscriber => subscriber.userId)


        return boardUserList.filter(user => userIdsList?.includes(user.userId))

    }
);


export const getFilteredUserListForSubscriptionManagement = createSelector(
    [
        (state, searchTerm) => state,
        (state, searchTerm) => searchTerm,
        (state, searchTerm) => getSelectedBoardUserList(state),
    ],
    (state, searchTerm, boardUserList) => {

        const subscribersIdList = getTaskSubscribedUsersList(state).map(subscriber => subscriber.userId)

        const filteredList = boardUserList
            .filter(user => !subscribersIdList?.includes(user.userId))
            .filter(user => user?.profile?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase())
                || user?.profile?.lastName?.toLowerCase().includes(searchTerm?.toLowerCase())
                || user?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
            )


        return filteredList
    }
);


export const getIsUserSubscribed = createSelector(
    [
        (state, userId) => state,
        (state, userId) => userId,
        (state, userId) => getTaskSubscribedUsersList(state),
    ],
    (state, userId, subscribersList) => {
        const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
        const boardId = getSelectedBoardId(state);

        const boardSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                entityType: 'board',
                deleted: false,
            })
        const isSubscribed = subscribersList?.map(user => user?.userId)?.includes(userId)
        const isSubscribedToBoard = boardSubscribersList?.filter(subscriber => subscriber.userId === userId).length !== 0

        return [isSubscribed, isSubscribedToBoard]

    }
);

