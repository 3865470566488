import { Box, Stack, display } from '@mui/system'
import classes from './import-excel.module.scss'

import React from 'react'
import BTButton from '@common-components/bt-button/bt-button'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { modalActions } from '@common-reducers/ModalReducer'
import { setImportedContactsArray } from '@common-reducers/WhatsAppReducer'
import { getRuntimeUrl } from '@common-services/storage'
import { Description, TextSnippet, WhatsApp } from '@mui/icons-material'
import { updateSearchValue } from '@common-reducers/ContactTableReducer'
import { importItemsActions } from '@common-reducers/ImportItemsReducer'
import BTtextFieldWithLabel from '@common-components/bt-input-field-with-label/bt-input-field-with-label'

const srcBulkWASVG = getRuntimeUrl("/img/bulkWASVG.svg")
const srcBulkCSVSVG = getRuntimeUrl("/img/bulkCSVSVG.svg")
const srcBulkGoogleSheetsSVGb = getRuntimeUrl("/img/bulkGoogleSheetsSVG.svg")


export default function SelectImportTypeDialog() {
    const openedby = useAppSelector((state) => state.ModalReducer.openedby)
    const dispatch = useAppDispatch()
    const newAudienceName = useAppSelector((state) => state.ImportItemsReducer.newAudienceName)


    const setImportType = (type: 'csv' | 'google' | 'whatsapp') => {
        if (type === 'whatsapp') {
            dispatch(setImportedContactsArray([]))
        }
        dispatch(modalActions.setImportType(type));
    }

    return (
        <Stack sx={{ height: '100%', }}>
            <div className={classes.columnDescription} style={{ width: 'unset' }}>
                <h3 style={{ display: 'flex', alignItems: 'center', fontWeight: 700, }}>
                    {openedby === 'campaign' ? 'Import contacts to your audience' : 'Import data to your board'}
                </h3>
            </div>


            <Stack sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '80%',
                marginTop: '3%',
            }}>

                {newAudienceName !== null &&

                    <BTtextFieldWithLabel
                        // --background-default
                        divStyle={{
                            marginBottom: '50px',
                            width: "350px",
                        }}

                        label="Audience Name"
                        placeholder="Enter Audience name"
                        onChange={(e) => dispatch(importItemsActions.setNewAudienceName(e.target.value))}
                        name="firstName"
                        value={newAudienceName}
                        sx={{
                            backgroundColor: 'var(--background-default)',
                        }}
                    />
                }

                <p style={{ marginBottom: 0, }}>
                    Select import method:
                </p>
                <BTButton
                    sx={{
                        marginTop: '20px',
                        padding: "8px",
                        width: '250px',
                    }}
                    onClick={() => setImportType('csv')}>
                    <div style={{
                        width: '150px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                        <TextSnippet />
                        CSV, XLSX, XLS
                    </div>
                </BTButton>
                <BTButton
                    sx={{
                        marginTop: '20px',
                        padding: "8px",
                        width: '250px',
                    }}
                    onClick={() => setImportType('google')}>
                    <div style={{
                        width: '150px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                        <Description />
                        Google Sheets
                    </div>
                </BTButton>
                {openedby === 'campaign' && <BTButton
                    sx={{
                        marginTop: '20px',
                        padding: "8px",
                        width: '250px',
                    }}
                    onClick={() => {
                        dispatch(updateSearchValue(''))
                        setImportType('whatsapp')
                    }}>
                    <div style={{
                        width: '150px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                        <WhatsApp />
                        Whatsapp Contacts
                    </div>
                </BTButton>}
            </Stack>
        </Stack >
    )
}