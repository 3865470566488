import React, { useState } from 'react';
import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { title, secondaryTitleWithMaybeLink } from './utils';

type Props = {
    localChatGPTState: any;
    setLocalChatGPTState: React.Dispatch<any>;
}

const formatArray = ['Empty', 'Text', 'JSON Object'];

export default function ResponseFormat({ localChatGPTState, setLocalChatGPTState }: Props) {
    const [isSelectResponseFormatMenuOpen, setIsSelectResponseFormatMenuOpen] = useState(false);

    const toggleSelectResponseFormatMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsSelectResponseFormatMenuOpen(!isSelectResponseFormatMenuOpen);
    }

    const handleCloseSelectResponseFormatMenu = (formatIndex: number) => {
        setLocalChatGPTState({
            ...localChatGPTState,
            advanced: {
                ...localChatGPTState?.advanced,
                responseFormat: formatArray[formatIndex]
            }
        });
        setIsSelectResponseFormatMenuOpen(false);
    }

    return (
        <>
            {title('Response Format', { marginTop: '20px', })}
            <BotInputField
                onClick={(e) => toggleSelectResponseFormatMenu(e)}
                value={localChatGPTState.advanced?.responseFormat ?? ''}
                sx={{ cursor: 'pointer' }}
                endAdornment={
                    <>
                        <IconButton disableRipple sx={{
                            zIndex: 2,
                            width: '30px',
                            color: '#666666',
                            ".MuiSvgIcon-root": {
                                width: '20px',
                                height: '20px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }}>
                            {!isSelectResponseFormatMenuOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                        </IconButton>
                    </>
                }
            />
            {isSelectResponseFormatMenuOpen && formatArray.map((format, formatIndex) => (
                <BotInputField
                    key={`f_${formatIndex}`}
                    value={format}
                    onClick={() => handleCloseSelectResponseFormatMenu(formatIndex)}
                    sx={{
                        ".MuiOutlinedInput-input": {
                            zIndex: 3,
                            lineHeight: '19px',
                            cursor: 'pointer',
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "0px",
                            backgroundColor: 'rgb(245, 246, 250)',
                            cursor: 'pointer'
                        },
                        "&:hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                backgroundColor: 'rgb(204, 208, 222)',
                            },
                        },
                    }} />
            ))}
            {secondaryTitleWithMaybeLink(
                'When utilizing a JSON Object, it is essential to instruct the model to produce JSON through a System or User message. Failing to do so may cause the model to generate an excessive amount of whitespace until the token limit is reached, leading to a long-running request that appears to be "stuck."',
                {}
            )}
        </>
    );
}
