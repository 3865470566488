import { AppThunkDispatch} from "@common/types/common-root-state-type";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CommonRootState } from "@common/types/common-root-state-type";
import moment from "moment";
import { mediaManager } from "./MediaManagerReducer";
import { cellValueSelector } from "./TaskSelectors";
import { updateCellValueThunk } from "./TaskThunks";

export const addNewVersionOrFileThunk = createAsyncThunk<
  void,
  { isNewVersion: boolean; currentFile: any },
  { state: CommonRootState }
>(
  "versioning/addNewVersionOrFileThunk",
  async ({ isNewVersion, currentFile }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const duplicateFilesClone = [
      ...state.MediaManagerReducer.filesToDecideNewVersionOrNewFile,
    ];
    const existingFiles = cellValueSelector(
      state,
      state.MediaManagerReducer.taskId,
      state.MediaManagerReducer.columnId
    );
    let filesToAddOrUpdate = [];
    if (isNewVersion) {
      const index = existingFiles?.findIndex((object) => {
        return object.filename === currentFile.filename;
      });
      filesToAddOrUpdate = JSON.parse(JSON.stringify(existingFiles));
      filesToAddOrUpdate[index].url = currentFile.url;
      filesToAddOrUpdate[index].type = currentFile.type;
      filesToAddOrUpdate[index].updatedAt = moment().format(
        "DD/MM/YYYY HH:mm:ss"
      );
      const version =
        filesToAddOrUpdate[index].versions[
          filesToAddOrUpdate[index].versions.length - 1
        ].version + 1;
      filesToAddOrUpdate[index].version = version;
      const currentVersion = {
        url: currentFile.url,
        type: currentFile.type,
        updatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
        version: version,
      };
      filesToAddOrUpdate[index].versions.push(currentVersion);
    } else {
      filesToAddOrUpdate = existingFiles.concat(currentFile);
    }
    dispatch(
      updateCellValueThunk({
        taskId: state.MediaManagerReducer.taskId,
        columnId: state.MediaManagerReducer.columnId,
        data: {
          value: filesToAddOrUpdate,
        }
      })
    );
    dispatch(
      mediaManager.updateDuplicateFilesState(duplicateFilesClone.slice(1))
    );
  }
);

export const setCurrentVersionAsPrimary = createAsyncThunk<
  void,
  { currentVersionFile: any; index: any },
  { state: CommonRootState }
>(
  "versioning/setCurrentVersionAsPrimary",
  async ({ currentVersionFile, index }, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const existingFiles = cellValueSelector(
      state,
      state.MediaManagerReducer.taskId,
      state.MediaManagerReducer.columnId
    );
    let existingFilesClone = JSON.parse(JSON.stringify(existingFiles));
    existingFilesClone[state.MediaManagerReducer.index].url =
      currentVersionFile.url;
    existingFilesClone[state.MediaManagerReducer.index].updatedAt =
      currentVersionFile.updatedAt;
    existingFilesClone[state.MediaManagerReducer.index].type =
      currentVersionFile.type;
    existingFilesClone[state.MediaManagerReducer.index].version =
      currentVersionFile.version;
    const fileVersions =
      existingFilesClone[state.MediaManagerReducer.index].versions;
    swapElements(
      existingFilesClone[state.MediaManagerReducer.index].versions,
      index,
      fileVersions.length - 1
    );
    const versionStateUpdate = {};
    versionStateUpdate["isVersionPreview"] = false;
    versionStateUpdate["versionIndex"] = -1;
    dispatch(mediaManager.setVersionPreview(versionStateUpdate));
    dispatch(
      updateCellValueThunk({
        taskId: state.MediaManagerReducer.taskId,
        columnId: state.MediaManagerReducer.columnId,
        data: {
          value: existingFilesClone,
        }
      })
    );
  }
);
export const deleteFile = createAsyncThunk<void, any, { state: CommonRootState }>(
  "mediaManager/deleteFile",
  async (files, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    dispatch(
      updateCellValueThunk({
        taskId: state.MediaManagerReducer.taskId,
        columnId: state.MediaManagerReducer.columnId,
        data: {
          value: files,
        }
      })
    );
  }
);

export const deleteVersion = createAsyncThunk<void, any, { state: CommonRootState }>(
  "versioning/deleteVersion",
  async (index, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppThunkDispatch;
    const state = thunkAPI.getState();
    const existingFiles = cellValueSelector(
      state,
      state.MediaManagerReducer.taskId,
      state.MediaManagerReducer.columnId
    );
    let existingFilesClone = JSON.parse(JSON.stringify(existingFiles));
    existingFilesClone[state.MediaManagerReducer.index].versions.splice(
      index,
      1
    );
    dispatch(
      updateCellValueThunk({
        taskId: state.MediaManagerReducer.taskId,
        columnId: state.MediaManagerReducer.columnId,
        data: {
          value: existingFilesClone,
        }
      })
    );
  }
);

const swapElements = (array, index1, index2) => {
  let temp = array[index1];
  array[index1] = array[index2];
  array[index2] = temp;
};

