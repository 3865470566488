import React from 'react';
import Stack from '@mui/material/Stack';

const RowStack = ({ children, ...props }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            {...props}
        >
            {children}
        </Stack>
    );
};

export default RowStack;
