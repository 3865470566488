import React, { useState } from 'react';
import classes from "./chatgpt-node.module.scss";
import axios from 'axios';

interface Connection {
    apiKey: string;
    organizationId?: string;
}

interface Model {
    id: string;
    object: string;
    created: number;
    owned_by: string;
}

interface Messages {
    role: string;
    message: string;
}

interface AdvancedSettings {
    temperature?: number;
    topP?: number;
    number?: number;
    frequencyPenalty?: number;
    presencePenalty?: number;
    stopSequence?: string[];
}

interface RequestObject {
    connection: Connection;
    method: 'chat completion' | 'prompt completion';
    model: Model;
    prompt?: string;
    messages?: Messages[];
    maxTokens?: number;
    advancedSettings?: boolean;
    advanced?: AdvancedSettings;
}

interface TestButtonProps {
    localChatGPTState: RequestObject;
}

const TestButton: React.FC<TestButtonProps> = ({ localChatGPTState }) => {
    const [response, setResponse] = useState<string>('');



    const secondaryTitleWithMaybeLink = (
        secondaryTitle: string,
        style?: React.CSSProperties,
        linkString?: string,
        linkURL?: string
    ) => {
        return (
            <div className={classes.secondary_title} style={style}>
                {secondaryTitle}
                {linkString && linkURL && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {linkString}
                    </a>
                )}
            </div>
        );
    };

    const validateRequestObject = () => {
        const { connection, method, model, prompt, messages, advancedSettings } = localChatGPTState;

        if (!connection.apiKey) {
            throw new Error('API Key is missing.');
        }

        if (!method) {
            throw new Error('Method is missing.');
        }

        if (!model.id) {
            throw new Error('Model ID is missing.');
        }

        if (method === 'chat completion' && (!messages || messages.length === 0)) {
            throw new Error('Messages are required for chat completion.');
        }

        if (method === 'prompt completion' && !prompt) {
            throw new Error('Prompt is required for prompt completion.');
        }

        if (advancedSettings && localChatGPTState.advanced) {
            const { temperature, topP, number, frequencyPenalty, presencePenalty, stopSequence } = localChatGPTState.advanced;
            if (temperature !== undefined && (temperature < 0 || temperature > 1)) {
                throw new Error('Temperature must be between 0 and 1.');
            }
            if (topP !== undefined && (topP < 0 || topP > 1)) {
                throw new Error('topP must be between 0 and 1.');
            }
            if (number !== undefined && number < 1) {
                throw new Error('Number must be at least 1.');
            }
            if (frequencyPenalty !== undefined && (frequencyPenalty < -2 || frequencyPenalty > 2)) {
                throw new Error('Frequency penalty must be between -2 and 2.');
            }
            if (presencePenalty !== undefined && (presencePenalty < -2 || presencePenalty > 2)) {
                throw new Error('Presence penalty must be between -2 and 2.');
            }
            if (stopSequence && stopSequence.length > 4) {
                throw new Error('Stop sequence can have at most 4 items.');
            }
        }
    };


    function convertStringToInteger(str) {
        if (str === 0) return 0
        const parsedInt = parseInt(str, 10);

        // Check if the parsed value is a number and if the original string represents a valid integer
        if (!isNaN(parsedInt) && str?.trim() !== '' && parsedInt.toString() === str?.trim()) {
            return parsedInt;
        } else {
            return 0; // or you can return any default value if the conversion fails
        }
    }

    const handleClick = async () => {
        try {
            validateRequestObject();

            const { method, model, prompt, messages, maxTokens, advancedSettings, advanced } = localChatGPTState;

            const requestData: any = {
                model: model.id,
                max_tokens: convertStringToInteger(maxTokens) || 50,
            };

            if (method === 'chat completion') {
                requestData.messages = messages.map(message => ({ role: message.role.toLowerCase(), content: message.message }));
            } else if (method === 'prompt completion') {
                requestData.prompt = prompt;
            }

            if (advancedSettings && advanced) {
                Object.assign(requestData, advanced);
            }

            console.log('Request Payload:', requestData);

            const response = await axios.post(
                `https://api.openai.com/v1/${method === 'chat completion' ? 'chat/completions' : 'completions'}`,
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${localChatGPTState.connection.apiKey}`,
                        'Content-Type': 'application/json',
                        ...(localChatGPTState.connection.organizationId && {
                            'OpenAI-Organization': localChatGPTState.connection.organizationId,
                        }),
                    },
                }
            );

            console.log('API Response:', response.data);
            setResponse(JSON.stringify(response.data, null, 2));
        } catch (error: any) {
            console.error('Error:', error.message);

            if (error.response) {
                console.log('Error Response Data:', error.response.data);
                console.log('Error Status:', error.response.status);
                console.log('Error Headers:', error.response.headers);
            }

            setResponse(`Error: ${error.message}`);
        }
    };

    return (
        <div style={{ marginTop: '20px', }}>
            <button onClick={handleClick}>Test API Request</button>
            {response &&
                <>
                    {secondaryTitleWithMaybeLink(`* If you choose to save ChatGPT's response in a variable, we will only store response.choices.content field into the variable.This way, you can use that as a response in your chatbot`)}
                    < pre style={{ maxWidth: '100%', overflowX: 'auto' }}>{response}</pre>
                </>
            }
        </div >
    );
};

export default TestButton;
