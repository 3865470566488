import { Box } from "@mui/system";
import { QuickFilterOptionButton } from "./quick_filter_option_button";
import classes from './filter_styles.module.scss'
type QuickFilterColumnOptionProps = {
    columnWithFilterOptions: any;
};
export const QuickFilterColumnOptions = ({ columnWithFilterOptions }: QuickFilterColumnOptionProps) => {
    return (
        <Box sx={{ display: 'flex' }}>
            {columnWithFilterOptions?.columnTitle !== 'Files' &&
                (
                    <div className={classes.scrollContainer}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '130px',
                            width: '130px',
                            maxHeight: '300px',
                        }}>

                        <span style={{ fontSize: '12px', margin: '10px 0 4px 0', textAlign: 'start' }}>
                            {columnWithFilterOptions?.columnTitle ?? 'No Title'}
                        </span>
                        <div className={classes.scrollContainer}>
                            {
                                columnWithFilterOptions?.colmunFilterOptionsWithUsageList
                                    ?.sort((a, b) => {
                                        const aIsBlank = a?.isBlank;
                                        const bIsBlank = b?.isBlank;
                                        if (aIsBlank === bIsBlank) return 0;
                                        if (aIsBlank) return 1;
                                        return -1;
                                    })
                                    .map((columnOptionInUse, index) => {
                                        return (
                                            <Box key={`${columnOptionInUse?.id}-${index}`} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <QuickFilterOptionButton
                                                    columnId={columnWithFilterOptions?.columnId}
                                                    columnOptionInUse={columnOptionInUse}
                                                />
                                            </Box>
                                        )
                                    })
                            }
                        </div>
                    </div>
                )
            }
        </Box >
    )
}