import { Stack } from '@mui/material';
import { DBKanbanThunks, kanbanSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import BTCheckboxList from "../checkbox-list/bt-checkbox-list";
import { getDynamicColumnListForBTCheckbox } from '@common-reducers/TaskSelectors';

export default function KanbanColumnPicker() {
    const dispatch = useAppDispatch()
    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
    const [kanban] = useAppSelector((state) => kanbanSelectByQuery(state.DBKanbanReducer, { _id: currentBoardView.id }, ['includedColumns']));
    const columnList = useAppSelector((state) => getDynamicColumnListForBTCheckbox(state, 'kanban'))



    const handleCheckBoxChange = (value, column) => {
        dispatch(
            DBKanbanThunks.patch({
                entity: {
                    _id: currentBoardView.id,
                    includedColumns: {
                        ...kanban?.includedColumns,
                        [column._id]: value,
                    }
                }
            })
        )
    }

    function handleAllCheckBoxChange(value) {
        const newIncludedColumnsList = columnList.map((column, radioListIndex) => ({
            NullableId: column._id, boolean: value
        }))
        let newIncludedColumns = {}
        for (let i in newIncludedColumnsList) {
            newIncludedColumns = {
                ...newIncludedColumns,
                [newIncludedColumnsList[i].NullableId]: newIncludedColumnsList[i].boolean
            }
        }

        dispatch(
            DBKanbanThunks.patch({
                entity: {
                    _id: currentBoardView.id,
                    includedColumns: newIncludedColumns
                }
            })
        )
    }


    const isChecked = (column) => {
        if (kanban?.includedColumns) {
            return (kanban?.includedColumns[column._id] ?? false)
        }
        else {
            return false
        }
    }

    const isCheckedRecord: Record<string, boolean> = columnList.reduce((isCheckedRecord, column) => ({
        ...isCheckedRecord,
        [column._id]: isChecked(column)
    }), {});


    return (
        <Stack direction="column" >
            <BTCheckboxList
                isCheckedRecord={isCheckedRecord}
                columnList={columnList}
                handleCheckBoxChange={handleCheckBoxChange}
                handleAllCheckBoxChange={handleAllCheckBoxChange}
            />
        </Stack>
    )
}   