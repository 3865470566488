import { createSlice } from "@reduxjs/toolkit";


export interface BoardViewState {
}

const initialState: BoardViewState = {
}

const boardViewSlice = createSlice({
  name: "task",
  initialState,
  reducers: {

  }
})
export default boardViewSlice.reducer;

export const boardViewActions = boardViewSlice.actions;