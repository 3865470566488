import { AccessTimeOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { differenceInMinutes, format, formatDistanceToNow, isBefore, parseISO, subYears } from 'date-fns';
import React from 'react'

type Props = {
    createdAt: string;
}

export default function ExtraDataCreatedAtTag({
    createdAt,
}: Props) {
    const [isHovered, setIsHovered] = React.useState(false);

    const _createdAt = createdAt ?? (new Date()).toISOString()
    // Parse the provided ISO string to a Date object
    const date = parseISO(_createdAt);

    // Check if the date is less than a minute ago
    const now = new Date();
    const lessThanAMinuteAgo = differenceInMinutes(now, date) < 1;

    // If less than a minute ago, return "now"
    // Otherwise, format the time difference to include the most significant unit
    const timeAgo = lessThanAMinuteAgo
        ? "now"
        : formatDistanceToNow(date, { addSuffix: false, includeSeconds: false })
            .replace('about ', '') // Remove the prefix "about" if present
            .replace('less than a minute', 'now') // Replace "less than a minute" with "now"
            .replace(/minutes?/, 'm')
            .replace(/hours?/, 'h')
            .replace(/days?/, 'd')
            .replace(/months?/, 'M')
            .replace(/years?/, 'y')
            .replace(/\s/g, ''); // Remove any spaces

    // Determine the date format based on if it's more than a year ago
    const moreThanAYearAgo = isBefore(date, subYears(new Date(), 1));
    const dateFormat = moreThanAYearAgo ? "d MMM yyyy 'at' HH:mm" : "d MMM 'at' HH:mm";
    const formattedDate = format(date, dateFormat);


    return (
        <div style={{ display: 'flex', alignItems: 'center', width: 'max-content', color: '#767787'}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <AccessTimeOutlined style={{ fontSize: '14px',marginRight:"2px" }} />
            <Tooltip title={formattedDate}>
                <span style={{
                    fontSize: '14px',
                    textDecoration: isHovered ? 'underline' : 'none',
                    borderBottom: '1px solid transparent',
                }}>
                    {timeAgo}
                </span>
            </Tooltip>
        </div>
    )
}