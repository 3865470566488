import { UserMessage } from "@common-models/user-message";
import { getMessageFromInputField } from "@common-services/utils";
import { getRandomMessageColor } from "@common-views/calendar/message-color";
import { accessFormPopupActions } from "@common/reducers/AccessFormPopupReducer";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { getCampaignStuckMessages } from "@common/reducers/CampaignSelectors";
import { DBBoardsThunks, DBUserMessageThunks } from "@common-reducers/DBServiceThunks";
import { taskActions } from "@common/reducers/TasksReducer";
import { selectUser } from "@common/reducers/UserSelectors";
import { isWaSocketsLimitReached } from "@common/reducers/backend-api/backend-api";
import { AnalyticsService } from "@common/services/analytics-service";
import { AppThunkDispatch, CommonRootState } from "@common/types/common-root-state-type";
import { MessageState } from "@common/types/interface";
import { AddScheduledMessageButtonState } from "@extension/context/content/components/add-scheduled-message-button/add-scheduled-message-button";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { subscribeCurrentBoardToSocket } from "@extension/context/content/thunks/WhatsappThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import randomstring from "randomstring";

const analyticsService = new AnalyticsService();


export const scheduleMessage = createAsyncThunk<
    void,
    UserMessage,
    { state: CommonRootState; dispath: AppThunkDispatch }
>("scheduleMessage", async (_message, thunkApi) => {
    const state = thunkApi.getState()
    const replyData = state.WhatsAppReducer.isReply

    let poll
    if (_message.isPoll) {
        poll = {
            ..._message.poll,
            options: _message.poll.options
                .filter(o => o.text && o.text !== ''),
        }
    } else {
        poll = {
            question: '',
            options: [
                { id: Math.floor(Math.random() * 1000000000000000000000000), text: '', order: 0 },
                { id: Math.floor(Math.random() * 1000000000000000000000000), text: '', order: 1 },
            ],
            allowMultiple: false,
        }
    }

    const message = {
        ..._message,
        id: randomstring.generate({ length: 8, charset: "alphanumeric" }),
        dueDate: moment(_message.dueDate).startOf("minute").toISOString(),
        status: 'pending',
        state: MessageState.pending,
        replyData,
        poll,
        messageColor: _message.messageColor ? _message.messageColor : getRandomMessageColor(),
    } as UserMessage;

    if (message?.replyData) {
        setTimeout(() => {
            const iconElement = document.querySelectorAll("footer [data-icon='x']")[1];

            if (iconElement instanceof HTMLElement) {
                iconElement.click();
            }
        }, 0); // A delay of 0ms to defer the action until the next tick
    }

    if (message._id) {
        thunkApi.dispatch<any>(DBUserMessageThunks.patch({ entity: message }));
    } else {
        message.disableOptimisticUpdates = true;
        thunkApi.dispatch<any>(DBUserMessageThunks.create(message));
    }
});



type HandleClickScheduleMessageThunkParams = {
    isSchedulePollButton?: boolean;
}

export const handleClickScheduleMessageThunk = createAsyncThunk<
    void,
    HandleClickScheduleMessageThunkParams,
    { state: CommonRootState }
>("scheduleMessage", async (HandleClickScheduleMessageThunkParams: HandleClickScheduleMessageThunkParams, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const { isSchedulePollButton } = HandleClickScheduleMessageThunkParams
    const user = selectUser(state)
    const _currentChat = state.WhatsAppReducer.currentChat;
    const currentChat = {
        ..._currentChat,
        participants: undefined,
    }

    const resetState: AddScheduledMessageButtonState = {
        showErrorDialog: false,
        open: false,
        message: {
            dueDate: undefined,
            message: "",
            isRecurring: false,
            state: MessageState.pending,
            cancelIfReceived: false,
        },
        isEmojiPicker: false,
    };

    if (!user) {

        analyticsService.event("login_before_schedule", {
            category: "Schedule",
            action: "login before schedule",
        });

        dispatch(accessFormPopupActions.setStatus('Login'));
        return;
    }



    dispatch(isWaSocketsLimitReached.initiate({})).unwrap().then((res) => {
        dispatch(addScheduledMessageButtonActions.setIsSocketsLimitReached(res));
    });

    const message = Object.assign({}, resetState.message);
    message.contactList = [
        {
            ...currentChat,
            id: currentChat?.id?._serialized,
            name: currentChat?.name ?? currentChat?.displayName,
        },
    ];
    if (isSchedulePollButton) {
        ContentMessageServiceActions.getPollData()
    } else {
        message.message = getMessageFromInputField();
    }

    message.dueDate = moment().toDate().toISOString();
    message.isTimeValid = true;

    analyticsService.event("open_schedule_dialog", {
        category: "Schedule",
        action: "open dialog",
    });

    dispatch(addScheduledMessageButtonActions.editMessage(message));
});



export const toggleTasksThunk = createAsyncThunk<
    void,
    UserMessage,
    { state: CommonRootState }
>("scheduleMessage", async (_message, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const user = selectUser(state)
    const isTasksPanelOpen = state.TasksReducer.isTasksPanelOpen

    const boardsLoaded = state.DBBoardsReducer.itemsLoaded

    if (!user) {
        dispatch(accessFormPopupActions.setStatus('Login'))
        return;
    }

    console.log("ToggleTasksButton: run only once");
    if (!boardsLoaded) {
        console.log("get boards");
        dispatch(DBBoardsThunks.find({})).then(() => {
            dispatch(subscribeCurrentBoardToSocket())
        });
    }

    dispatch(taskActions.setIsTasksPanelOpen(!isTasksPanelOpen));
});



// CREATE THUNK getUserMessagesAndCampaigns
export const getPendingUserMessages = createAsyncThunk(
    "getUserMessages",
    async (_, { dispatch }) => {
        const userMessages = dispatch(
            DBUserMessageThunks.find({
                deleted: { $ne: true },
                status: "pending",
                $sort: {
                    dueDate: 1,
                },
                $limit: -1,
                "contactList.0._id": { $exists: true },
            })
        );
        return userMessages;
    }
);






// Thunk to send all stuck messages for a campaign
export const sendAllStuckMessages = createAsyncThunk(
    'campaign/sendAllStuckMessages',
    async (campaignId: string, { dispatch, getState }) => {
        const stuckMessages = getCampaignStuckMessages(getState(), campaignId);

        // update message dueDate to now
        for (const message of stuckMessages) {
            dispatch(DBUserMessageThunks.patch({
                entity: {
                    _id: message._id,
                    dueDate: moment().toDate().toISOString(),
                }
            }));
        }
    }
);