import MaintenanceButton from '@common-components/maintenance-popup-button/maintenance-button';
import MaintenancePopup from '@common-components/maintenance-popup/maintenance-popup';
import SlateEditor from '@common-components/tasks/extraData/editor/SlateEditor';
import { Tab, Tabs, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { BulkView, setActiveBulkView } from '@common-reducers/CampaignReducer';
import { DBFeatureThunks, DBUserThunks, featureSelectOneObjectByQuery, userSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { appConfig } from '@common/config/app.config';
import { debounce } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FcCustomerSupport, FcInfo } from 'react-icons/fc';
import { Descendant } from 'slate';

interface MyQuery {
    $sort: { [key: string]: number };
    $skip: number;
    $limit: number;
    $and?: { [key: string]: { $regex: string; $options: string } }[];
}

const goToCampaignsManagement = (params) => {
    const userUrl = `${appConfig.APP_ENDPOINT}/campaigns?supportedUserId=${params.row.id}`;
    window.open(userUrl, '_blank');
};
const goToWhatsappViewManagement = (params) => {
    const userUrl = `${appConfig.APP_ENDPOINT}/whatsapp-view?supportedUserId=${params.row.id}`;
    window.open(userUrl, '_blank');
};



export default function Support() {
    const dispatch = useAppDispatch();
    const totalUsers = useAppSelector((state) => state.DBUserReducer.total);
    const [emailSearch, setEmailSearch] = useState('');
    const [firstNameSearch, setFirstNameSearch] = useState('');
    const [lastNameSearch, setLastNameSearch] = useState('');

    const [value1, setValue1] = useState<Descendant[]>();
    const [value2, setValue2] = useState<Descendant[]>();

    const [currentTab, setCurrentTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const maintenancePopupFeature = useAppSelector((state) => featureSelectOneObjectByQuery(state.DBFeatureReducer, {
        featureName: 'maintenancePopup',
    }))
    const [paginationModel, setPaginationModel] = React.useState<any>({
        pageSize: 10,
        page: 0,
    });
    const users = useAppSelector((state) => {
        const andQuery = [];
        if (emailSearch) {
            andQuery.push({ email: { $regex: emailSearch, $options: 'i' } });
        }
        if (firstNameSearch) {
            andQuery.push({ firstName: { $regex: firstNameSearch, $options: 'i' } });
        }
        if (lastNameSearch) {
            andQuery.push({ lastName: { $regex: lastNameSearch, $options: 'i' } });
        }

        const query = {
            ...(andQuery.length > 0 && { $and: andQuery })
        };
        return userSelectByQuery(state.DBUserReducer, query, {}, { createdAt: -1 });
    });


    const columns = [
        { field: 'id', headerName: 'ID', width: 300 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'createdAt', headerName: 'Created at', width: 260 },
        {
            field: 'campaignsManagement',
            headerName: 'Campaigns Management',
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <button onClick={() => {
                    goToCampaignsManagement(params)
                }}>Manage Campaigns</button>
            ),
        },
        {
            field: 'Whatsapp View Management',
            headerName: 'Whatsapp View Management',
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <button onClick={() => goToWhatsappViewManagement(params)}>Manage Whatsappview</button>
            ),
        },
    ];


    const handleRowDoubleClick = (params) => {
        const userUrl = `${appConfig.APP_ENDPOINT}/account?supportedUserId=${params.id}`;
        window.open(userUrl, '_blank');
    };


    const updateMaintanceMessage = async (updatedData) => {
        const updatedEntity = {
            _id: maintenancePopupFeature._id,
            customData: {
                ...maintenancePopupFeature.customData,
                ...updatedData
            }
        };

        await dispatch(DBFeatureThunks.patch({ entity: updatedEntity }));
    }

    const updateTitleValue = (titleValue) => {
        const titleData = { title: titleValue };
        updateMaintanceMessage(titleData);
    }

    const updateContentValue = (contentValue) => {
        const contentData = { content: contentValue };
        updateMaintanceMessage(contentData);
    }




    const debouncedSearch = useCallback(
        debounce((query) => dispatch(DBUserThunks.find(query)), 100),
        [],
    );

    useEffect(() => {
        let query: MyQuery = {
            $sort: { createdAt: -1 },
            $skip: paginationModel.page * paginationModel.pageSize,
            $limit: paginationModel.pageSize,
        };


        const andQuery = [];
        if (emailSearch) {
            andQuery.push({ email: { $regex: emailSearch, $options: 'i' } });
        }
        if (firstNameSearch) {
            andQuery.push({ firstName: { $regex: firstNameSearch, $options: 'i' } });
        }
        if (lastNameSearch) {
            andQuery.push({ lastName: { $regex: lastNameSearch, $options: 'i' } });
        }

        if (andQuery.length > 0) {
            query.$and = andQuery;
        }

        debouncedSearch(query);
    }, [dispatch, paginationModel.page, paginationModel.pageSize, emailSearch, firstNameSearch, lastNameSearch, debouncedSearch]);
    useEffect(() => {
        dispatch(
            DBFeatureThunks.find({
                featureName: 'maintenancePopup',
            })
        )
    }, [])

    return (
        <Stack sx={{ width: '100%', }}>
            <Tabs
                TabIndicatorProps={{ style: { backgroundColor: "#FDAB3D" } }}
                sx={{
                    padding: "0 100px",
                    borderBottom: "1px solid lightgrey",
                    background: "var(--default-background)",
                    "& .MuiTabs-flexContainer": { background: "var(--default-background)" },
                    "& .MuiTab-labelIcon.Mui-selected": { fontWeight: "600" },
                    "& .MuiTabs-root": { minHeight: "45px" },
                    "& .MuiTab-root.Mui-selected": { color: "var(--primary)", fontWeight: 600 },
                    "& .MuiTab-root": { fontFamily: "Poppins", textTransform: "capitalize", color: "#809BA8", minHeight: "50px" }
                }} value={currentTab} onChange={handleTabChange} aria-label="extra data tabs">
                <Tab icon={<FcCustomerSupport />
                } iconPosition="start" label="Support table" />
                <Tab icon={<FcInfo />
                } iconPosition="start" label="Maintance message" />


            </Tabs>
            <Stack spacing={1} sx={{ margin: "0 auto", width: '90%', p: 10 }}>
                {currentTab === 0 && (<>
                    <Stack direction="row" justifyContent={"space-between"} spacing={2}>
                        <TextField
                            fullWidth
                            label="Search by Email"
                            sx={{ background: "#fff" }}
                            variant="outlined"
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            placeholder="Search by email..."
                        />
                        <TextField
                            fullWidth
                            label="Search by First Name"
                            sx={{ background: "#fff" }}
                            variant="outlined"
                            value={firstNameSearch}
                            onChange={(e) => setFirstNameSearch(e.target.value)}
                            placeholder="Search by first name..."
                        />
                        <TextField
                            fullWidth
                            label="Search by Last Name"
                            sx={{ background: "#fff" }}
                            variant="outlined"
                            value={lastNameSearch}
                            onChange={(e) => setLastNameSearch(e.target.value)}
                            placeholder="Search by last name..."
                        />
                    </Stack>
                    <Box sx={{ height: 600, width: '100%', overflow: 'auto' }}>
                        <DataGrid
                            rows={users.map(user => ({
                                id: user._id,
                                ...user,
                            }))}
                            columns={columns}
                            pagination

                            initialState={{

                                pagination: { paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page } },
                            }}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[10, 25, 50]}
                            onRowDoubleClick={handleRowDoubleClick}
                            rowCount={totalUsers}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            sx={{
                                height: '100%',
                                background: '#fff',
                                '& .MuiDataGrid-row': {
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: '#ffffff',
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: '#fafafa',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#c9f5ff',
                                    },
                                },
                            }}
                        />
                    </Box>
                </>)}
                {currentTab === 1 && (
                    <Stack spacing={4} justifyContent={"space-between"} alignItems={"flex-start"} style={{
                        width: "100%",

                        margin: "0 auto", position: "relative"
                    }}>
                        <Stack direction="row" spacing={4} style={{ marginTop: "25px", margin: "0 auto" }}>
                            <div style={{ width: "510px", position: "relative" }}>
                                <MaintenanceButton />
                            </div>
                            {/* <BTButton onClick={updateMaintanceMessage}>Update Maintance message</BTButton> */}




                        </Stack>
                        <Stack sx={{ width: "100%" }} spacing={4} direction="row" >
                            <Box sx={{ width: "30%" }}>
                                Issue title
                                <SlateEditor value={maintenancePopupFeature?.customData?.title?.editorValue} onValueUpdate={(value) => updateTitleValue(value)} addBorder={false} isDraft={false} id={"title"} />
                            </Box>


                            <Box sx={{ width: "60%" }}>
                                Issue content
                                <SlateEditor value={maintenancePopupFeature?.customData?.content?.editorValue} onValueUpdate={(value) => updateContentValue(value)} addBorder={false} isDraft={false} id={"content"} />

                            </Box>

                        </Stack>
                        <MaintenancePopup />
                    </Stack>
                )}
            </Stack>


        </Stack>
    );
}
