import { MenuItem, Select, Stack, TextField, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


type SelectFilterValueProps = {
    options: any[];
    onSelectionChange?: (newValue: string) => void;
    valueField?: string;
};

const StyledExactDatePicker = styled(DesktopDatePicker)`
.MuiOutlinedInput-input {
    padding: 13px;
    font-size:14px;
}
`;

export const SelectFilterValue = ({ options, onSelectionChange, valueField, }: SelectFilterValueProps) => {
    const initialValueField = !Array.isArray(valueField) && valueField.startsWith('exactDate') ? valueField.replace('exactDate ', '') : valueField
    const [selectValue, setSelectValue] = useState<any>(initialValueField);
    const [exactDateIsChosen, setExactDateIsChosen] = useState<boolean>();
    const [exactDateValue, setExactDateValue] = useState<any>();
    const handleChange = (selectOptionValue) => {
        setExactDateIsChosen(false)
        setSelectValue(selectOptionValue);
        onSelectionChange(selectOptionValue)
    };

    const exactDateChoosen = () => {
        setSelectValue(['Exact Date']);
        setExactDateIsChosen(true);
    }
    const exactDateHandleChange = (exactDateSelectValue) => {
        setExactDateIsChosen(true);
        setExactDateValue(exactDateSelectValue);
        onSelectionChange(`exactDate${exactDateSelectValue}`)
    }

    useEffect(() => {
        if (!Array.isArray(initialValueField) && initialValueField.startsWith('exactDate')) {
            setExactDateIsChosen(true);
            setSelectValue('Exact Date');
            setExactDateValue(initialValueField.replace('exactDate ', ''))
        }
        else {
            setExactDateIsChosen(false);
            setExactDateValue(initialValueField)
        }
    }, [])

    return (
        <Stack direction={'row'} gap={1} >
            <Select
                id=""
                value={selectValue}
                label=""
                sx={{
                    height: '47px',
                    width: exactDateIsChosen ? '50%' : '100%',
                    fontSize: '14px'
                }}
            >
                {options.map((option, i) => {
                    if (option !== 'Blank') {
                        return <MenuItem
                            sx={{
                                fontSize: '14px'
                            }}
                            onClick={() => handleChange(option)} key={i} value={option}>
                            {option}
                        </MenuItem>
                    }
                })}
                <MenuItem
                    sx={{
                        fontSize: '14px'
                    }}
                    selected={exactDateIsChosen}
                    onClick={exactDateChoosen}
                    value='Exact Date'>
                    Exact Date
                </MenuItem>
            </Select>
            {exactDateIsChosen &&

                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StyledExactDatePicker
                        format="DD/MM/YY"
                        value={exactDateValue}
                        onChange={exactDateHandleChange}
                        slots={(params) => <TextField {...params} />}
                        />
                </LocalizationProvider>
            }
        </Stack>
    );
};
