import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { recycleActions } from "@common-reducers/RecycleBinReducer";
import { fetchArchivedOrDeletedData } from "@common-reducers/TaskThunks";

export const ArchivedOrDeletedTabsAndHeader = () => {
    const dispatch = useAppDispatch();
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);
    const headerTexts = {
        Trash: ["Trash", "This is your account trash for deleted boards, groups, tasks, subtasks and columns.",
        "After 30 days from the deletion date, it will be deleted permanently and will no longer be accessible."],
        Archive: ["Archive", "boards, groups, tasks, subtasks and columns.",
        "items you archive will stay here so you can restore them later."]
    };

    const tabChange = (event, tab) => {
        dispatch(recycleActions.setArchivedOrDeletedData([]));
        dispatch(recycleActions.setCurrentTab(tab))
        dispatch(recycleActions.setSearchText(''));
        dispatch(recycleActions.setArchivedOrDeletedBulkItems([]));
        dispatch(recycleActions.setFilters({
            type: [],
            date: '',
            board: []
        }));


        dispatch(fetchArchivedOrDeletedData({
            mode: tab,
            itemId: "",
            itemUpdatedAt: "",
            searchQuery: '',
            showLoader: true
        }));
    };

    const [title, description,description2] = headerTexts[currentTab] || [];
    return (
        <>
            <Stack direction={"row"} justifyContent={"center"}>
                <Tabs
                    value={currentTab}
                    onChange={tabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    sx={{ background: 'var(--background-default)' }}
                >
                    <Tab value="Trash"
                        icon={
                            <Box display="flex" alignItems="center" flexDirection="row" textTransform={"capitalize"}>
                                <DeleteIcon sx={{ marginRight: 1 }} />
                                Trash
                            </Box>
                        }
                        sx={{ background: 'var(--background-default)' }}
                    />
                    <Tab value="Archive"
                        icon={
                            <Box textTransform={"capitalize"} display="flex" alignItems="center" flexDirection="row">
                                <ArchiveIcon sx={{ marginRight: 1 }} />
                                Archive
                            </Box>
                        }
                        sx={{ background: 'var(--background-default)' }}
                    />
                </Tabs>
            </Stack>
            <Box>
                <Typography variant="h5">{title}</Typography>
                <Typography>{description}<br/>{description2}</Typography>
            </Box>
        </>
    );
}
