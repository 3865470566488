import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box';
import { Box, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Stack, Switch, Tooltip } from '@mui/material';
import { DBFormThunks, formSelectOneFieldByQuery, isTempId } from "@common-reducers/DBServiceThunks";
import { getGroupListOnlyIdsAndNames } from '@common-reducers/TaskSelectors';
import { updateFormAfterSubmitEvent } from '@common-reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import classes from "./form.module.scss";

type Props = {
    formId: string
}

export default function FormSettingsDrawer({ formId }: Props) {
    const { t } = useTranslation(namespace);
    const isActive = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'isActive'))
    
    const groupListOnlyIdsAndNames = useAppSelector((state) => getGroupListOnlyIdsAndNames(state))

    const formColumnData = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'columnData'))

    const formGroupId = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'groupId'))


    const formAfterSubmit = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'afterSubmit'))


    const dispatch = useAppDispatch()

    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        height: '56px',
        boxSizing: "border-box",
        fontSize: "14px",
        borderRadius: "4px",
        border: "1px solid #53bdeb",
        fontFamily: "'Inter', sans-serif",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "16.5px 32px 16.5px 14px",
        background: "var(--boards-panel-background)",

    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "14px",
        width: "100%",
        height: '56px',
        boxSizing: "border-box",
        border: "1px solid var(--border-color)",
        borderRadius: "4px",
        "&:hover": {
            border: "1px solid var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'Inter', sans-serif",
        padding: "16.5px 32px 16.5px 14px",
        background: "var(--boards-panel-background)",

    };

    const handleSwitchChange = (value) => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    isActive: value
                }
            }))
        }
    }

    const ActivateFormSwitch = () => (
        <Box style={{
            borderRadius: "8px",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(83 189 235 / 10%)",
        }}>
            <Switch
                checked={isActive ?? false}
                onChange={(event) => handleSwitchChange(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <span style={{
                fontWeight: 700,
                color: '#53bdeb',
                fontFamily: "'Inter'",
            }}>{t('formSettingsDrawer.published')}</span>
        </Box>
    )

    const NameQuestion = () => (
        <Stack direction='column' className={classes.option + " " + classes.first}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <p>{t('formSettingsDrawer.questionNamesTitle')}</p>
                <Tooltip title={t('formSettingsDrawer.includeQuestionTooltip')}>
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <FormControlLabel
                disableTypography={true}
                sx={{
                    fontSize: "14px",
                    fontFamily: '"Inter", "Poppins", "Assistant", sans-serif',
                }}
                control={
                    <Checkbox
                        checked={(formColumnData['name'] && formColumnData['name'].visible !== undefined) ? formColumnData['name'].visible : true}
                        onChange={(e) => {
                            dispatch(updateFormIncludeNameQuestion({
                                formId,
                                value: e.target.checked,
                            }))

                        }
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={t('formSettingsDrawer.includeQuestionNamesInFormLabel')}
            />
        </Stack>
    )

    const handleDropDownChange = (event) => {
        if (!isTempId(event.target.value)) {
            dispatch(
                DBFormThunks.patch({
                    entity: {
                        _id: formId,
                        groupId: event.target.value,
                    }
                })
            )
        }
    }

    const GroupSelect = () => (
        <Stack direction='column' className={classes.option}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <p>{t('formSettingsDrawer.groupForAnswersTitle')}</p>
                <Tooltip title={t('formSettingsDrawer.selectToWhichGroup')}>
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <FormControl sx={{
                background: "var(--boards-panel-background)",
            }}
                fullWidth>
                <InputLabel sx={{
                    fontFamily: '"Inter", "Poppins", "Assistant", sans-serif',
                    fontSize: "14px",
                }} id="label-ID" >{t('formSettingsDrawer.selectGroup')}</InputLabel>
                <Select
                    labelId="label-ID"
                    id="id-of-???"
                    value={formGroupId ?? ""}
                    label={t('formSettingsDrawer.selectPriorityList')}
                    sx={{
                        "&>*": {
                            fontFamily: '"Inter", "Poppins", "Assistant", sans-serif',
                            fontSize: "14px",
                        },
                        "&:hover >*": {
                            borderColor: "var(--text-color) !important",
                        }
                    }}
                    onChange={handleDropDownChange}
                >
                    {groupListOnlyIdsAndNames
                        .map((group, groupIndex) => {
                            return (
                                <MenuItem
                                    value={group?._id}
                                    key={groupIndex}
                                    sx={{
                                        fontFamily: '"Inter", "Poppins", "Assistant", sans-serif',
                                        fontSize: "14px",
                                        "&:hover": {
                                            background: "var(--secondary-background-color)  ",
                                        },
                                    }}
                                >
                                    {group?.name ?? t('formSettingsDrawer.unnamedGroup')}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Stack>
    )

    const handleChange = (value, field: "title" | "message" | "link") => {

        dispatch(updateFormAfterSubmitEvent({
            formId,
            value,
            field,
        }))

    }

    const SuccessMessage = () => (
        <Stack direction='column' className={classes.option}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <p>{t('formSettingsDrawer.successMessageTitle')}</p>
                <Tooltip title={t('formSettingsDrawer.customizeSuccessMessage')}>
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <BtEditableBox
                inputSx={inputTheme}
                boxSx={textTheme}
                id="success-message-title"
                text={formAfterSubmit?.title}
                label={t('formSettingsDrawer.successMessageTitleLabel')}
                style={{ paddingBottom: '15px' }}
                placeholder={`&nbsp;${t('formSettingsDrawer.successMessageTitlePlaceholder')}`}
                onValueUpdate={(value) => handleChange(value, "title")} />
            <BtEditableBox
                inputSx={inputTheme}
                boxSx={textTheme}
                id="success-message-content"
                text={formAfterSubmit?.message}
                label={t('formSettingsDrawer.successMessageContentLabel')}
                placeholder={`&nbsp;${t('formSettingsDrawer.successMessageContentPlaceholder')}`}
                onValueUpdate={(value) => handleChange(value, "message")} />
        </Stack>
    )

    const RedirectLink = () => (
        <Stack direction='column' className={classes.option}>
            <Stack direction='row' className={classes.settings_drawer_title}>
                <p>{t('formSettingsDrawer.redirectLinkTitle')}</p>
                <Tooltip title={t('formSettingsDrawer.sendToDifferentWebsite')}>
                    <IconButton>
                        <FiHelpCircle style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <BtEditableBox
                inputSx={inputTheme}
                boxSx={textTheme}
                id="success-message-title"
                text={formAfterSubmit?.link ?? ''}
                placeholder="&nbsp;https://example.com"
                label={t('formSettingsDrawer.redirectLinkLabel')}
                onValueUpdate={(value) => handleChange(value, "link")}
            />
        </Stack>
    )

    return (
        <Stack direction='column' className={classes.settings_drawer}>
            <div className={classes.title}>
                {t('formSettingsDrawer.formSettings')}
            </div>
            <ActivateFormSwitch />
            <NameQuestion />
            <GroupSelect />
            <SuccessMessage />
            <RedirectLink />
        </Stack >
    )
}

function updateFormIncludeNameQuestion(arg0: { formId: string; value: boolean; }): any {
    throw new Error('Function not implemented.');
}
