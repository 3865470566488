import MaintenancePopupDialog from '@common-components/maintenance-popup/maintenance-popup-dialog'
import RenderUpdate from '@common-components/tasks/extraData/editor/RenderUpdate'
import { Modal } from '@mui/material'
import { Stack } from '@mui/system'
import { featureSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks"
import { getRuntimeUrl } from '@common-services/storage'
import { useAppSelector } from '@common-reducers/hooks/store.hook'
import { ReactElement, useState } from 'react'


export default function MaintenanceButton(): ReactElement {
    const maintenancePopupFeature = useAppSelector((state) => featureSelectOneObjectByQuery(state.DBFeatureReducer, {
        featureName: 'maintenancePopup',
    }))
    const isScheduledContactListOpen = useAppSelector((state) => state.MainReducer.scheduledContactListOpen)

    const [showMaintenancePopup, setShowMaintenancePopup] = useState(false)

    const srcAleryWhite = getRuntimeUrl("/img/alertSVGWhite.svg")

    return (
        <>
            {maintenancePopupFeature?.isActive && !isScheduledContactListOpen &&
                <Stack direction='row' sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '37px',
                    width: '100%',
                    backgroundColor: "#faa10b",
                }}>
                    <div style={{
                        padding: '25px',
                    }}>
                        <img src={srcAleryWhite} style={{ width: '16px', height: '16px', alignSelf: 'flex-start', }} alt="alertSVG" />
                    </div>
                    <div>
                    <RenderUpdate update={maintenancePopupFeature?.customData?.title?.editorValue ?? []} />
{/* 
                        <span style={{ color: 'white', fontWeight: 400, }}>Some features are not working</span> */}
                    </div>
                    <div style={{ position: 'absolute', right: '10px', zIndex: 9, }}>
                        <button style={{
                            width: '82px',
                            height: '26px',
                            fontWeight: 400,
                            fontSize: '12px',
                            textAlign: 'center',
                            border: '1px solid #e6e6e6',
                            borderRadius: '6px',
                            backgroundColor: 'white',
                        }}
                            onClick={() => {
                                console.log('Button clicked')
                                setShowMaintenancePopup(true)
                            }}>
                            Read more
                        </button>
                    </div>
                </Stack>
            }
            <Modal
                open={showMaintenancePopup}
                onClose={() => setShowMaintenancePopup(false)}
                closeAfterTransition
            >
                <div>
                    <MaintenancePopupDialog handlMaintenancePopupClose={() => setShowMaintenancePopup(false)} />
                </div>
            </Modal>
        </>
    )
}