import { Box } from '@mui/system'
import classes from './input.module.scss'


export default function Input(props) {
    const {errorMessage,label, ...rest} = props
    return (

        <Box className={classes.InputContainer}>
            <label htmlFor={label}>{label}</label>
            <input className={classes.Input}  {...rest} />
            <label
                htmlFor={label}
                style={{
                    color: '#f72371',
                    visibility: errorMessage ? 'visible' : 'hidden'
                }}>
                {errorMessage}
            </label>
        </Box>
    )
}