// GoogleLoginHandler.tsx
import React, { FC, useEffect, useState } from 'react';
import { FcGoogle } from "react-icons/fc";
import ProviderButton from '../providerButtons';
import { appConfig } from '@common/config/app.config';

interface GoogleLoginHandlerProps {
  onLoginSuccess: (credential: string) => void;
  onLoginError: () => void;
  isExtension: boolean;
  googleLoading?: boolean;
}

const GoogleLoginHandler: FC<GoogleLoginHandlerProps> = ({ onLoginSuccess, onLoginError, isExtension,googleLoading }) => {
  const [GoogleLogin, setGoogleLogin] = useState<any>(null);
  const [GoogleOAuthProvider, setGoogleOAuthProvider] = useState<any>(null);

  useEffect(() => {
    if (!process.env.REACT_APP_INCLUDE_GOOGLE_AUTH) {
      import('@react-oauth/google')
        .then(module => {
          console.log('@react-oauth/google Google Auth Loaded');
          setGoogleLogin(() => module.GoogleLogin);
          setGoogleOAuthProvider(() => module.GoogleOAuthProvider);
        });
    }
  }, []);

  if (isExtension) {
    return (
      <ProviderButton
        value="Signin with Google"
        clickHandler={(e) => {
          console.log('google clickHandler onSuccess');
          e.preventDefault();
          onLoginSuccess('');
        }}
        ProviderIcon={FcGoogle}
        loading={googleLoading}
      />
    );
  } else {
    return GoogleLogin && GoogleOAuthProvider ? (
      <GoogleOAuthProvider clientId={appConfig.google_client_id}>
        <GoogleLogin
          onSuccess={credentialResponse => {
            console.log('GoogleOAuthProvider Login onSuccess');
            onLoginSuccess(credentialResponse.credential);
          }}
          onError={error => {
            console.log('GoogleOAuthProvider Login Failed');
            console.log(error);
            onLoginError();
          }}
          useOneTap
        />
      </GoogleOAuthProvider>
    ) : null;
  }
};

export default GoogleLoginHandler;
