
import classes from "./created_cell.module.scss";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import moment from "moment";
import {
  boardUserSelectOneObjectByQuery,
  tasksSelectors
} from "@common-reducers/DBServiceThunks";
import { Box, Stack, Tooltip } from "@mui/material";
import { CellProps } from "../cell-interface";
import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";


export default function CreatedCell({ taskId }: CellProps) {
  const task = useAppSelector((state) =>
    tasksSelectors.selectById(state, taskId)
  );
  // selected user by task.createdBy

 //board user select by query
 const user = useAppSelector((state) => boardUserSelectOneObjectByQuery(
  state.DBBoardUserReducer,
  { userId: task.createdBy }
))

  const fullDate = moment(task?.createdAt).format('DD/MM/YYYY HH:mm:ss');
  const formattedDate = moment(task?.createdAt).isSame(new Date(), 'year') ? moment(task?.createdAt).format("MMM DD HH:mm") : moment(task?.createdAt).format("MMM DD, YYYY HH:mm");
  return (
    <div className={classes.createdCell} style={{alignSelf: 'center'}}>
  
   
        <Stack direction="row" alignItems={"center"} spacing={1}>
      {user &&
    <PersonAvatar textSize={10} size={24} email={user?.email}
    profileImg={user?.profile?.profileImg}
    toolTip={true}
    lastName={user?.profile?.lastName}
    firstName={user?.profile?.firstName} />
      }
    
    <Tooltip arrow placement="top" disableInteractive title={fullDate}>
         <Box> {formattedDate}</Box>
         </Tooltip>
        </Stack>

    </div>
  );
}

