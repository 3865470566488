import PersonAvatar from "@common-components/tasks/person-avatar/person-avatar";
import { Workspace } from "@common-models/workspace";
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import { Button, Menu, MenuItem } from "@mui/material";
import { workspaceSelectByQuery } from "@common-reducers/DBServiceThunks";
import { mainSliceActions } from "@common-reducers/MainReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { switchWorkspace, toggleDarkMode, toggleDomDirection } from "@common-reducers/UserThunks";
import { selectCurrentWorkspace, selectUserObject } from "@common-reducers/UserSelectors";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { useRouter } from "@common-reducers/hooks/use-router";
import { AnalyticsService } from "@common-services/analytics-service";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";

import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./user-control-panel.module.scss";
import { ImLtr, ImRtl } from "react-icons/im";
import { isCurrentUserMemberOrOwner } from "@common-reducers/BoardsSelectors";
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";
import { logoutWrapperThunk } from "@common-reducers/UserThunks";




function SmallUserMenu() {
  const { t } = useTranslation(namespace);
  const analyticsService: AnalyticsService = useAnalyticsService();
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const userId = useAppSelector((state) => state.UserReducer.userId);
  const router = useRouter();
  const user = useAppSelector(selectUserObject);
  const userWorkspaces: Workspace[] = useAppSelector((state) =>
    workspaceSelectByQuery(state.DBWorkspaceReducer, {})
  );
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);
  const smallMenuRef = useRef(null);
  const isDarkMode = useAppSelector((state) => user?.isDarkMode);
  const ToggleModeIcon = (() => {
    if (!isDarkMode) {
      return (
        <>
          <DarkModeOutlinedIcon style={{ width: 16 }} />
          <span className={styles.userMenuLabel}>{t('smallUserMenu.changeToDarkMode')}</span>
        </>
      )
    }
    else {
      return (
        <>
          <LightModeOutlinedIcon style={{ width: 16 }} />
          <span className={styles.userMenuLabel}>{t('smallUserMenu.changeToLightMode')}</span>
        </>
      )
    }
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickSwitchWorkspace = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSwitchWorkspace = useCallback(async (workspaceId?: string) => {
    console.log('workspaceId', workspaceId);
    setAnchorEl(null);
    if (userId && workspaceId) {
      await dispatch(switchWorkspace({ workspaceId }))

      router.navigate({
        pathname: 'tasks',
        search: ''
      })
    }
  }, [userId, dispatch, router]);


  const setUserDirection = () => {
    dispatch(toggleDomDirection())
    setAnchorEl(null);
  }

  return (
    <div ref={smallMenuRef} className={styles.smallUserMenuContainer}>

      <div className={styles.workspaceItem}>
        <PersonAvatar
          size={30}
          email={user?.email}
          profileImg={user?.profileImg}
          lastName={user?.lastName}
          firstName={user?.firstName}
          toolTip={true} />
        <span className={styles.workspaceLabel}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'var(--primary)',
            fontWeight: 500,
          }}>{workspace?.name}</span>

        <Button
          style={{
            marginInlineStart: "auto",
            fontSize: 11,
            fontWeight: 500,
            textTransform: 'capitalize',
            fontFamily: "'Inter', sans-serif",
            background: "var(--secondary-background-color)",
            paddingInline: 12,
            borderRadius: 12,
            height: 24,
          }}
          onClick={handleClickSwitchWorkspace}
        >
          {t('smallUserMenu.switchWorkspace')}
          <KeyboardArrowRightIcon color="primary" style={{
            height: 16,
            marginInlineStart: -4, marginBottom: 1, marginInlineEnd: -8, rotate: direction === 'rtl' ? "180deg" : ""
          }} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => { handleCloseSwitchWorkspace() }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {userWorkspaces?.map((ws, index) => <MenuItem key={`switch-ws-${index}`} disabled={workspace?._id === ws._id} onClick={() => handleCloseSwitchWorkspace(ws._id as string)}>{ws.name}</MenuItem>)}
        </Menu>
      </div>

      <Link to="/account" style={{ marginTop: 8 }}
        onClick={() => {
          analyticsService.event('switch_to_my_account', {
            category: "smallUserMenu",
            action: 'switch to my account',
          });
        }}>
        <div className={styles.smallMenuItem} data-testid="my-account-button">
          <PersonOutlineRoundedIcon style={{ width: 16 }} />
          <span className={styles.userMenuLabel}>{t('smallUserMenu.myAccount')}</span>
        </div>
      </Link>
      {isMemberOrOwner && (
  window.location.pathname.indexOf("tasks") > -1 ? (
    <div 
      className={styles.smallMenuItem} 
      onClick={() => {
        analyticsService.event('switch_to_invite_people', {
          category: "smallUserMenu",
          action: 'switch to invite people',
        });
        dispatch(mainSliceActions.inviteModalOpen(true));
        dispatch(taskActions.setTaskViewSideDrawerOpenWith("InviteUserModal"));
      }}
    >
      <GroupAddOutlinedIcon style={{ width: 16 }} />
      <span className={styles.userMenuLabel}>{t('smallUserMenu.invitePeople')}</span>
    </div>
  ) : (
    <Link 
      to={"/user-management"} 
      onClick={() => {
        analyticsService.event('switch_to_invite_people', {
          category: "smallUserMenu",
          action: 'switch to invite people',
        });
      }}
    >
      <div className={styles.smallMenuItem}>
        <GroupAddOutlinedIcon style={{ width: 16 }} />
        <span className={styles.userMenuLabel}>{t('smallUserMenu.invitePeople')}</span>
      </div>
    </Link>
  )
)}
      <div className={styles.smallMenuItem} onClick={() => {
        analyticsService.event('switch_to_dark_light_mode', {
          category: "smallUserMenu",
          action: 'switch to dark light mode',
        });
        dispatch(toggleDarkMode())
      }}>
        <ToggleModeIcon />
      </div>

      {false &&
        <div className={styles.smallMenuItem}
          onClick={setUserDirection}>
          {direction === "rtl" ? (<ImLtr style={{ width: 16 }} />) : <ImRtl style={{ width: 16 }} />}

          <span className={styles.userMenuLabel}>{direction === 'ltr' ? t('smallUserMenu.rtl') : t('smallUserMenu.rtl')}</span>
        </div>
      }
      <Link to="/logout" onClick={() => {
        analyticsService.event('switch_to_log_out', {
          category: "smallUserMenu",
          action: 'switch to log out',
        });
      }}>
        <div
          className={styles.smallMenuItem}
          onClick={() => {
            dispatch(logoutWrapperThunk({accessToken}))
          }}
          data-testid="logout-button"
        >
          <LogoutOutlinedIcon style={{ width: 16 }} />
          <span className={styles.userMenuLabel}>{t('smallUserMenu.logOut')}</span>
        </div>
      </Link>

      <Link to="/account-subscription" onClick={() => {
        analyticsService.event('switch_to_upgrade', {
          category: "smallUserMenu",
          action: 'switch to log out',
        });
      }}>
        <div className={styles.smallMenuItem}>
          <UpgradeOutlinedIcon style={{ color: "#FF74B8", width: 16 }} />
          <span
            style={{ color: "#FF74B8", fontWeight: "600" }}
            className={styles.userMenuLabel}
          >
            {t('smallUserMenu.upgrade')}
          </span>
        </div>
      </Link>

    </div >
  );
}

export default SmallUserMenu;
