import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const BTTextFieldProps = {
    '&.MuiTextField-root': {
        '.MuiInputLabel-root': {
            opacity: 0.5,
            top: '3px',
            color: 'var(--primary)',

            '&.Mui-focused': {
                color: 'inherit'
            }
        },
        '.MuiFormHelperText-root': {
            color: 'var(--text-color)',
        },
        '.MuiInput-root': {
            '&.Mui-disabled': {
                color: 'var(--text-color)',
            },
            '&:before': {
                borderColor: 'var(--secondary)',
            },
            '&:hover:not(.Mui-disabled):before': {
                borderColor: 'var(--secondary)',
            }
        },
        '.MuiInputBase-input': {
            color: 'var(--primary)',

            '&.Mui-disabled': {
                color: 'var(--text-color)',
                WebkitTextFillColor: 'var(--text-color)',
            }
        },
        '.MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
                color: 'var(--primary)',
            }
        },
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--secondary)',
        }
    },
};

export const BTTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (props, ref) => {
      return (
        <TextField
          {...props}
          inputRef={ref}
          variant={props.variant ?? 'standard'}
        />
      );
    }
);

export default BTTextField;

