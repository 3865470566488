import { createAsyncThunk } from "@reduxjs/toolkit";


import { AppThunkDispatch, CommonRootState } from "@common/types/common-root-state-type";
import { automation } from "./AutomationReducer";
import { getSelectedBoardId } from "./BoardsSelectors";
import {
  DBAutomationsThunks,
  automationActionsSelectByQuery,
  automationConditionsSelectByQuery,
  automationTriggerSelectOneObjectByQuery,
} from "@common-reducers/DBServiceThunks";
import { createAndDeleteAutomation } from "./backend-api/backend-api";

export const setCurrentAutomation = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("automation/setCurrentAutomation", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppThunkDispatch;
  const state = thunkAPI.getState();
  dispatch(automation.setLoader(true));
  const currentAutomationId = state.AutomationReducer.selectedAutomationId;
  const automationsTrigger = automationTriggerSelectOneObjectByQuery(
    state.DBAutomationTriggerReducer,
    { automationId: currentAutomationId, deleted: false }
  );
  const automationsConditions = automationConditionsSelectByQuery(
    state.DBAutomationConditionsReducer,
    { automationId: currentAutomationId, deleted: false }
  );
  const automationsActions = automationActionsSelectByQuery(
    state.DBAutomationActionsReducer,
    { automationId: currentAutomationId, deleted: false }
  );
  const actionsArray = automationsActions.map((action) => ({
    ...action,
    _id: action._id,
  }));

  const conditionsArray = automationsConditions.map((condition) => ({
    ...condition,
    _id: condition._id,
  }));
  dispatch(automation.setTrigger(automationsTrigger));
  dispatch(automation.setConditions(conditionsArray));
  dispatch(automation.setActions(actionsArray));
  dispatch(automation.setLoader(false));
});

export const createAutomation = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("automation/createAutomation", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  const boardId = getSelectedBoardId(state);
  dispatch(automation.setLoader(true));
  const automationObject = {
    boardId: boardId,
    owner: state.UserReducer.userId,
  };
  const { payload }: any = await dispatch(
    DBAutomationsThunks.create(automationObject)
  );
  const automationId = payload._id;

  const { updatedAt, createdAt, __v, deleted, _id, ...trigger } = state
    .AutomationReducer.trigger as any;
  let combined = {
    trigger: trigger,
    conditions: state.AutomationReducer.conditions,
    actions: state.AutomationReducer.actions,
  };

  const res = await dispatch(
    createAndDeleteAutomation.initiate({
      combined,
      automationId,
    })
  ).unwrap();
  console.log(res);

  dispatch(automation.setLoader(false));
  dispatch(automation.setActiveView("automationsList"));
  dispatch(automation.resetAutomationState());
});

export const updateAutomationDetails = createAsyncThunk<
  void,
  any,
  { state: CommonRootState }
>("automation/updateAutomation", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  dispatch(automation.setLoader(true));

  const selectedAutomationId = state.AutomationReducer.selectedAutomationId.toString();
  const { updatedAt, createdAt, __v, deleted, _id, ...trigger } = state
    .AutomationReducer.trigger as any;

  let combined = {
    trigger: trigger,
    conditions: state.AutomationReducer.conditions,
    actions: state.AutomationReducer.actions,
  };
  const res = await dispatch(
    createAndDeleteAutomation.initiate({
      combined,
      automationId: selectedAutomationId,
    })
  ).unwrap();
  console.log(res);
  dispatch(automation.setLoader(false));
  dispatch(automation.setActiveView("automationsList"));
  dispatch(automation.resetAutomationState());
});

export const handleConditionOptionSelected = createAsyncThunk<
  void,
  { name: string }, // This assumes the option object contains a label property which is a string
  { state: CommonRootState }
>("automation/handleOptionSelected", async (option, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  const conditions = state.AutomationReducer.conditions;
  const clonedConditions = [...conditions].filter((item) => item.conditionType);
  const conditionObject = { conditionType: option.name };
  clonedConditions.push(conditionObject);
  dispatch(automation.setConditions(clonedConditions));
});

export const removeEmptyConditions = createAsyncThunk<
  void,
  void, // This thunk does not require any arguments, hence void
  { state: CommonRootState }
>("automation/setConditionsThunk", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  const conditions = state.AutomationReducer.conditions;
  const newCondition = [...conditions].filter((item) => item.conditionType);
  dispatch(automation.setConditions(newCondition));
});

export const handleActionOptionSelected = createAsyncThunk<
  void,
  { name: string },
  { state: CommonRootState }
>("automation/handleActionSelect", async (option, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  const actions = state.AutomationReducer.actions;
  const action = { actionType: option.name };
  let clonedActions = [...actions].filter((item) => item.actionType);
  clonedActions.push(action);
  dispatch(automation.setActions(clonedActions));
});
export const removeEmptyActions = createAsyncThunk<
  void,
  void, // This thunk does not require any arguments, hence void
  { state: CommonRootState }
>("automation/setConditionsThunk", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppThunkDispatch;
  const state = thunkApi.getState();
  const actions = state.AutomationReducer.actions;
  const newActions = [...actions].filter((item) => item.actionType);
  dispatch(automation.setActions(newActions));
});
