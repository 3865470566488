import { campaignReducerActions, userMessageReducerActions } from "@common-reducers/DBServiceThunks";
import { UserMessage } from "@common/models/user-message";
import { CommonRootState } from "@common/types/common-root-state-type";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { prepareMessageForWa } from "@extension/wa-communication-layer/proccess-message-app-context";
import { isMessageSendAttemptedWas3MinutesAgo } from "@extension/wa-communication-layer/process-message-utils";
import { deleteUserMessageAndCreateLog, getNextCampaignMessage, hasSendAttemptBeenMadeToMessage } from "@extension/wa-communication-layer/services/api-calls";
import { withTimeout } from "@extension/wa-communication-layer/wa-error-tracing";
import { WaMessage } from "@extension/wa-communication-layer/wa-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";

const timeoutMs = 10000; // Set your timeout duration (in ms)

export const sendMessageThunk = createAsyncThunk("sendMessageThunk", async (userMessage: UserMessage, thunkApi) => {

    if (!userMessage._id) {
        console.warn("[sendMessageThunk] userMessage._id is undefined");
        return;
    }

    const dispatch = thunkApi.dispatch;
    let sendMessageViaWhatsappResponse: any;

    const state = thunkApi.getState() as CommonRootState;
    const settings = state?.MainReducer?.settings;

    try {
        const preparedWaMessage: WaMessage = await withTimeout(prepareMessageForWa(userMessage), 'prepareMessageForWa', timeoutMs);

        let hasSendAttemptBeenMadeToMessageResponse = await withTimeout(hasSendAttemptBeenMadeToMessage(userMessage._id), 'hasSendAttemptBeenMadeToMessage', timeoutMs);
        if (!hasSendAttemptBeenMadeToMessageResponse) {
            console.warn("[sendMessageThunk] wasSendAttemptedRes is undefined");
            return;
        }

        if (hasSendAttemptBeenMadeToMessageResponse.wasSendAttempted === false) {
            await dispatch(sendViaWaAndLogMessageAndHandleRecurrenceThunk({ preparedWaMessage, userMessage }));
        }

        else if (hasSendAttemptBeenMadeToMessageResponse?.wasSendAttempted === true) {

            if (settings?.isSendMessageRetryEnabled === false) {
                console.log("[sendMessageThunk] [retry send message] isSendMessageRetryEnabled is false");
                dispatch(userMessageReducerActions.removeOne(userMessage._id));
                await dispatch(logMessageAndHandleRecurrenceThunk({ userMessageId: userMessage._id, waMessageKey: "RetryDisabled" }));
                return;
            }

            console.log("[sendMessageThunk] [retry send message] triggerd");
            if (!isMessageSendAttemptedWas3MinutesAgo(hasSendAttemptBeenMadeToMessageResponse.creationDate)) {
                return;
            }
            console.log("[sendMessageThunk] [retry send message] isMessageSendAttemptedWas3MinutesAgo passed");

            sendMessageViaWhatsappResponse = await withTimeout(ContentMessageServiceActions.getUnloggedWaMessageKeyIfExistInStore(
                preparedWaMessage.chatId,
                userMessage._id,
                new Date(userMessage.dueDate)
            ), 'getUnloggedWaMessageKeyIfExistInStore', timeoutMs);

            console.log("[sendMessageThunk] [getUnloggedWaMessageKeyIfExistInStore] [sendMessageViaWhatsappResponse]:", sendMessageViaWhatsappResponse);

            const waMessageKey = sendMessageViaWhatsappResponse?._serialized ?? sendMessageViaWhatsappResponse?.id?._serialized ?? "";
            console.log("[sendMessageThunk] [getUnloggedWaMessageKeyIfExistInStore] [waMessageKey]:", waMessageKey);

            if (!waMessageKey) {
                await dispatch(sendViaWaAndLogMessageAndHandleRecurrenceThunk({ preparedWaMessage, userMessage }));
            }
            else if (waMessageKey) {
                await dispatch(logMessageAndHandleRecurrenceThunk({ userMessageId: userMessage._id, waMessageKey }));
            }
        }
    } catch (error) {
        console.error(`Error in sendMessageThunk:`, error);
    }

    return sendMessageViaWhatsappResponse;
});


export const sendViaWaAndLogMessageAndHandleRecurrenceThunk = createAsyncThunk("sendViaWhatsappAndLogThunk",
    async ({ preparedWaMessage, userMessage, shouldSendMessage = true }:
        { preparedWaMessage: WaMessage; userMessage: UserMessage, shouldSendMessage?: boolean; },
        { dispatch }) => {

        let sendMessageViaWhatsappResponse: any = null;
        try {
            console.log(`[sendMessageThunk] [shouldSendMessage] ${shouldSendMessage} [userMessage id]: ${userMessage?._id} `);
            dispatch(userMessageReducerActions.removeOne(userMessage._id));

            if (shouldSendMessage) {
                sendMessageViaWhatsappResponse = await withTimeout(ContentMessageServiceActions.sendMessageViaWhatsapp(preparedWaMessage, userMessage._id),
                    "sendMessageViaWhatsapp",
                    timeoutMs
                );
            }
            console.log("[sendMessageThunk] [sendMessageViaWhatsapp] [sendMessageViaWhatsappResponse]:", sendMessageViaWhatsappResponse);

            const waMessageKey = sendMessageViaWhatsappResponse?._serialized ?? sendMessageViaWhatsappResponse?.id?._serialized ?? "";
            console.log(`[sendMessageThunk] [userMessage id]: ${userMessage?._id} [sendMessageViaWhatsappResponse]: ${waMessageKey}`);

            if (waMessageKey) {
                await dispatch(logMessageAndHandleRecurrenceThunk({ userMessageId: userMessage._id, waMessageKey }));
            }
            else {
                console.log(`[sendMessageThunk] message didnt sent - add it back to the state [userMessage id]: ${userMessage?._id} `);
                dispatch(userMessageReducerActions.addOne(userMessage));
            }

        } catch (error) {
            console.error(`Error in sendViaWhatsappAndLogThunk:`, error);
        }

        return sendMessageViaWhatsappResponse;
    }
);


export const logMessageAndHandleRecurrenceThunk = createAsyncThunk("handleMessageLogAndRecurrenceThunk", async (
    { userMessageId, waMessageKey, shouldDeleteMessage = true }: { userMessageId: string; waMessageKey: string; shouldDeleteMessage?: boolean; },
    { dispatch }
) => {
    try {

        let deleteUserMessageAndCreateLogResponse: any = null;
        if (shouldDeleteMessage) {
            deleteUserMessageAndCreateLogResponse = await deleteUserMessageAndCreateLog(userMessageId, waMessageKey);
        }

        console.log(`[sendMessageThunk] [shouldDeleteMessage] ${shouldDeleteMessage} [deleteUserMessageAndCreateLogResponse]: ${deleteUserMessageAndCreateLogResponse}`);
        // If the message is recurring and scheduled, add the new message
        if (deleteUserMessageAndCreateLogResponse?.isRecurringScheduled && deleteUserMessageAndCreateLogResponse?.newMessage) {
            dispatch(userMessageReducerActions.addOne(deleteUserMessageAndCreateLogResponse.newMessage));
        }

        return deleteUserMessageAndCreateLogResponse;
    } catch (error) {
        console.error(`Error in handleMessageLogAndRecurrenceThunk: ${error.message}`);
    }
}
);



export const nextCampaignMessageThunk = createAsyncThunk<
    void,
    string,
    { state }
>("nextCampaignMessageThunk", async (campaignId, thunkApi) => {

    const { dispatch } = thunkApi;
    const timeoutMs = 10000; // Set your timeout duration (in ms)

    try {
        let res = await withTimeout(getNextCampaignMessage(campaignId), 'getNextCampaignMessage', timeoutMs);
        if ("data" in res) {

            dispatch(campaignReducerActions.updateOne({
                id: campaignId,
                changes: res.data.campaign,
            }));

            if (res.data.campaignMessages?.length > 0) {
                dispatch(userMessageReducerActions.upsertMany(res.data.campaignMessages));
            }
        }

    } catch (error) {
        console.error(`Error in nextCampaignMessageThunk:`, error);
    }
});


