import { logsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
}

export default function NewDeleteRestoreTaskLog({
    logId,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))

    return (
        <LogStack>
            <span>{log.actionType}</span>&nbsp;
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}