import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { botSelectByQuery } from "@common-reducers/DBServiceThunks";

export const getNodeByNodeId = createDeepEqualSelector(
    [
        (state, id) => state,
        (id) => id,
    ],
    (state, id) => {
        const nodes = state.nodes;

        const node = nodes?.find(node => node.id === id);

        return node;
    }
);



export const getNodeDataByNodeId = createDeepEqualSelector(
    [
        (state, id) => state,
        (id) => id,
    ],
    (state, id) => {
        const node = getNodeByNodeId(state, id);

        return node?.data;
    }
);


export const getEdgeDataByEdgeId = createDeepEqualSelector(
    [
        (state, id) => state,
        (id) => id,
    ],
    (state, id) => {
        const edges = state.edges;

        const edge = edges?.find(edge => edge.id === id);

        return edge;
    }
);


export const isAlreadyActiveChatbot = createDeepEqualSelector(
    [
        (state) => state,
    ],
    (state) => {
        const bots = botSelectByQuery(state.DBBotReducer, {})
        const currentBot = state.ChatbotReducer.currentBot

        // return true if there is an active bot that is not the current bot
        return bots?.some(bot => bot.isActive && bot._id !== currentBot._id)

    }
);




export const getCurrentBotId = createDeepEqualSelector(
    [
        (state) => state,
    ],
    (state) => {
        const currentBot = state.ChatbotReducer.currentBot

        return currentBot?._id ?? '';
    }
);


export const getUserChatbotsIds = createDeepEqualSelector(
    [
        (state) => state,
    ],
    (state) => {
        const userChatbots = botSelectByQuery(state.DBBotReducer, {},) ?? []

        return userChatbots?.map(bot => ({ _id: bot._id, name: bot.name }))
    }
);


export const getIsStartNode = createDeepEqualSelector(
    [
        (state) => state,
        (state, id) => getNodeDataByNodeId(state, id),
    ],
    (data) => {
        return data?.isStartNode
    }
);


export const getIsOnlyNode = createDeepEqualSelector(
    [
        (state) => state,
    ],
    (state) => {
        const nodes = state.nodes;

        return nodes?.length === 0
    }
);

export const getHandleConnections = createDeepEqualSelector(
    [
        (state, id) => state,
        (state, id) => id,
    ],
    (state, id) => {
        const edges = state.edges;

        const handleEdges = edges?.filter(edge => edge.sourceHandle === id || edge.targetHandle === id);

        return handleEdges;
    }
);