import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, Button, Menu, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import classes from "./filter_button.module.scss";
import FilterSection from "./filter_section";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { getViewFiltersCount, isFiltersExist } from '@common-reducers/TasksFilterPanelThunks'
import { useTranslation } from 'react-i18next';
import { namespace } from '@common/config/constants';

function OpenFilterPanelButton() {
  const { t } = useTranslation(namespace);
  const usersMenuRef = React.useRef();
  const [isQuickFilter, setIsQuickFilter] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const advancedFilterIsExistSelector = useAppSelector(isFiltersExist);
  const currentFilter = useAppSelector((state) => getViewFiltersCount(state));

  const handleMenuOpenAdvanced = () => {
    setIsQuickFilter(false)
    setAnchorEl(usersMenuRef.current);
  }
  const handleMenuOpen = () => {
    setIsQuickFilter(true)

    setAnchorEl(usersMenuRef.current);
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  }


  return (
    <div className={classes.filterButtonContainer}>
      <Tooltip title={t('openFilterPanelButton.filterTooltip')} arrow placement='top'>
        <Stack sx={{ gap: '2px' }} direction={"row"} ref={usersMenuRef}
        >

      
          <Button
            startIcon={
              <FilterAltIcon />
            }
            onClick={handleMenuOpen}
            className={`${classes.button_col} ${currentFilter > 0 ? classes.button_col_selected : ''}`}
            sx={{ borderRadius: '8px 0px 0px 8px!important',

           }}
          >
            <Badge badgeContent={currentFilter} color="primary" 
              sx={{
                '& .MuiBadge-badge': {
                  color: '#fff',
                  backgroundColor: '#53bdeb',
                }
              }}
            >
              {t('openFilterPanelButton.filterButtonLabel')}
              
              
            </Badge>

          </Button>

          <Button onClick={handleMenuOpenAdvanced} 
          className={`${classes.button_col_expender} ${currentFilter > 0 ? classes.button_col_selected : ''}` } sx={{
            minWidth: '30px', display: 'flex',
            padding: 0,
            color: "var(--secondary-text-color)",
            borderRadius: '0px 8px 8px 0px',
            opacity: 0.5,
            justifyContent: 'center', alignItems: 'center', border: '0px solid', margin: 0, background: advancedFilterIsExistSelector ? '#fff' : ''

          }}>
            <KeyboardArrowDownIcon />
          </Button>
        </Stack>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose} >
        <FilterSection isQuickFilter={isQuickFilter} />
      </Menu>
    </div>
  );
}
export default OpenFilterPanelButton;