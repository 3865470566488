import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box'
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar'
import PhotoOrColorSelect from '@common-components/tasks/person-picker/photo-or-color-select'
import { userDisplayName } from '@common-models/user'
import { Popover } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DBTaskColumnOptionThunks, DBWorkloadThunks, workloadSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks"
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import React, { useState } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'
import classes from './bt-workload.module.scss';

type Props = {
    workloadId: string,
    person: any,
    index: number,
}

export default function WorkloadMockUserSettingsRow({
    workloadId,
    person,
    index,
}: Props) {

    const dispatch = useAppDispatch()

    const workloadPeople = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'people'))
    const defaultCapacity = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'defaultCapacity'))


    const [personId, setPersonId] = useState('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    const inputTheme = {
        color: "var(--primary)",
        width: "50px",
        height: '30px',
        fontSize: "15px",
        borderRadius: "4px",
        border: "2px solid #53bdeb",
        fontFamily: "'Poppins', sans-serif",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        display: 'flex',
        alignItems: 'center',
    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "15px",
        width: "50px",
        height: '30px',
        border: "2px solid var(--border-color)",
        borderRadius: "4px",
        "&:hover": {
            border: "2px solid var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
    };


    const handlePersCapChange = (id, value) => {
        dispatch(
            DBWorkloadThunks.patch({
                entity: {
                    _id: workloadId,
                    people: {
                        ...workloadPeople,
                        [id]: {
                            capacity: value,
                        }
                    }
                }
            })
        )
    }
    const handleMockPersonDelete = (Id) => {
        dispatch(DBTaskColumnOptionThunks.delete({
            entity: {
                _id: Id,
            }
        }))
    }


    const handlePersNameChange = (id, value) => {
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: id,
                label: value,
            }
        }))
    }

    const profileName = (profile) => {
        const fullName = userDisplayName(profile);
        return fullName
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const updateMockPersonImgPhoto = (url) => {
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: personId,
                imageUrl: url,
            }
        }))
        setAnchorEl(null);
    }

    const updateMockPersonImgColor = ({ hex }) => {
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: personId,
                imageUrl: hex,
            }
        }))
        setAnchorEl(null);
    }

    const openPhotoPicker = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (

        <Stack direction='row' sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "0 16px",
            gap: '10px',
        }}>
            <Box
                id={`photo-picker-${index}`}
                className={classes.header_button}
                style={{
                    textTransform: 'none',
                    width: '28px',
                    height: '28px',
                }}
                onClick={(e) => {
                    if (person.type === 'option') {
                        openPhotoPicker(e)
                        setPersonId(person._id)
                    }
                }}
            >
                <PersonAvatar
                    email={person?.email}
                    profileImg={person?.profile?.profileImg}
                    lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                    firstName={person?.profile?.firstName}
                    isName={false}
                />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <PhotoOrColorSelect handleColorChange={updateMockPersonImgColor} handlePhotoChange={updateMockPersonImgPhoto} />
            </Popover>
            {person.type === 'option' &&
                <BtEditableBox
                    shouldStretch={false}
                    inputSx={{
                        ...inputTheme, width: '140px',
                        border: "1px solid #53BDEB",
                        height: 34,
                        fontFamily: "'Assistant', 'Inter'",
                        fontWeight: 600,
                        flexShrink: 0,
                        padding: "0 8px",
                    }}
                    boxSx={{
                        ...textTheme, width: '140px',
                        border: "1px solid transparent",
                        height: 34,
                        fontFamily: "'Assistant', 'Inter'",
                        fontWeight: 600,
                        flexShrink: 0,
                        padding: "0 8px",
                        boxSizing: "border-box",
                        "&:hover": {
                            border: '1px dashed black',
                        },
                        "& .MuiBox-root:hover": {
                            border: '1px dashed black',
                        }
                    }}
                    text={() => profileName(person?.profile)}
                    onValueUpdate={(value) => { handlePersNameChange(person._id, value) }}
                />}
            {!person.type &&
                <span style={{
                    width: '140px',
                    border: "1px solid transparent",
                    height: 34,
                    fontFamily: "'Assistant', 'Inter'",
                    fontWeight: 600,
                    flexShrink: 0,
                    padding: "0 8px",
                    boxSizing: "border-box",
                    color: '#56beeb',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>{profileName(person?.profile)}</span>
            }
            <BtEditableBox
                inputSx={{
                    ...inputTheme,
                    width: "58px",
                    textAlign: "center",
                    justifyContent: "center",
                    border: "1px solid #DEEEF2",
                    height: 34,
                    fontFamily: "'Inter'",
                    fontWeight: 600,
                    flexShrink: 0,
                    color: "#809ba9",
                    "&:hover": {
                        border: '1px dashed black',
                    },
                    "&:focus": {
                        border: '1px dashed #53BDEB',
                    },
                    "& *": {
                        textAlign: "center",
                    },
                }}
                boxSx={{
                    ...textTheme,
                    width: "56px",
                    textAlign: "center",
                    justifyContent: "center",
                    border: "1px solid #DEEEF2",
                    height: 32,
                    fontFamily: "'Inter'",
                    fontWeight: 600,
                    flexShrink: 0,
                    color: "#809ba9",
                    "&:hover": {
                        border: '1px dashed black',
                    },
                    "& *": {
                        textAlign: "center",
                    }
                }}
                text={(workloadPeople && workloadPeople[person._id] && workloadPeople[person._id].capacity)
                    ?
                    workloadPeople[person._id].capacity
                    :
                    defaultCapacity ?? 100}
                inputProps={{ type: 'number' }}
                onValueUpdate={(value) => { handlePersCapChange(person._id, value) }}
            />
            {person.type === 'option' &&
                <RiCloseCircleFill
                    onClick={(e) => {
                        handleMockPersonDelete(person._id)
                        e.stopPropagation();
                    }}
                    className={classes.mock_person_delete_button}
                />
            }
        </Stack>
    )
}