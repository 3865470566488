import BotInputField from "@common-components/bt-chatbot/bot-components/bot-input-field";
import BotToggleSwitch from "@common-components/bt-chatbot/bot-components/bot-toggle-switch";
import BTbotbutton from "@common-components/bt-chatbot/bot-components/bt-bot-button";
import SelectVariable from "@common-components/bt-chatbot/bot-components/select-variable";
import RowStack from "@common-components/common/row-stack/RowStack";
import { RemoveCircle } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import { useState } from 'react';
import classes from "./webhook-node.module.scss";

type Props = {
    localWebhookState: any;
    setLocalWebhookState: (localWebhookState: any) => void;
}

export default function TestRequest({ localWebhookState, setLocalWebhookState }: Props) {
    const [testResponse, setTestResponse] = useState<any>(null);


    const title = (title: string, style?: any) => {
        return <div className={classes.title} style={style}>{title}</div>
    }

    const secondaryTitle = (secondaryTitle: string, style?: any) => {
        return <div className={classes.secondary_title} style={style}>{secondaryTitle}</div>
    }

    const modifyVariableName = (variableName: string) => {
        if (variableName.startsWith('@')) {
            return variableName.slice(1);
        } else return variableName;
    }

    const handleClickTestRequest = async () => {
        const { url, method, customizeHeaders, customizeBody, testRequest } = localWebhookState;

        const config: any = {
            method,
            url,
            headers: customizeHeaders.headers.reduce((acc: any, header: any) => {
                if (header.key) acc[header.key] = header.value;
                return acc;
            }, {}),
        };

        if (method === 'POST' || method === 'PATCH') {
            config.data = (customizeBody.body && JSON.parse(customizeBody.body)) || testRequest.testVariables.reduce((acc: any, variable: any) => {
                if (variable.variableName) acc[modifyVariableName(variable.variableName)] = variable.testValue;
                return acc;
            }, {});
        }

        try {
            const result = await axios(config);
            const resultData = JSON.stringify(result?.data, null, 2);
            const configData = JSON.stringify(JSON.parse(result?.config?.data ?? {}), null, 2);
            setTestResponse({
                code: result.status,
                message: `Result:\n${resultData}\n\nData:\n${configData}`
            });
        } catch (error) {
            setTestResponse(error);
        }
    };


    const handleClickRemoveTestVariable = (index) => {
        const testVariables = [...localWebhookState.testRequest.testVariables]
        testVariables.splice(index, 1)
        setLocalWebhookState({
            ...localWebhookState,
            testRequest: {
                ...localWebhookState.testRequest,
                testVariables: testVariables,
            }
        })
    }

    const setTestVariable = (type: 'variableName' | 'testValue', newValue, index) => {
        setLocalWebhookState({
            ...localWebhookState,
            testRequest: {
                ...localWebhookState.testRequest,
                testVariables: localWebhookState.testRequest.testVariables.map((testVariable, i) => {
                    if (i === index) {
                        return {
                            ...testVariable,
                            [type]: newValue,
                        }
                    } else {
                        return testVariable
                    }
                })
            }
        })
    }

    const testVariableRow = (testVariable, index) => {
        return (
            <RowStack key={index} sx={{ gap: 2, marginBottom: '5px', }}>
                <Stack sx={{ height: '110px', }}>
                    {secondaryTitle('Variable Name')}
                    <BotInputField
                        value={testVariable.variableName}
                        onChange={(newValue) => setTestVariable('variableName', newValue, index)}
                        placeholder='Search variables'
                    />
                    <div style={{ marginTop: '10px', }}>
                        <SelectVariable buttonSize='small' onSelect={(item) => {
                            setLocalWebhookState({
                                ...localWebhookState,
                                testRequest: {
                                    ...localWebhookState.testRequest,
                                    testVariables: localWebhookState.testRequest.testVariables.map((testVariable, i) => {
                                        if (i === index) {
                                            return {
                                                ...testVariable,
                                                variableName: testVariable.variableName + item,
                                            }
                                        } else {
                                            return testVariable
                                        }
                                    })
                                }
                            })
                        }} />
                    </div>
                </Stack>
                <Stack sx={{ height: '110px', }}>
                    {secondaryTitle('Test Value')}
                    <BotInputField
                        value={testVariable.testValue}
                        onChange={(newValue) => setTestVariable('testValue', newValue, index)}
                        placeholder='application/json'
                    />
                </Stack>
                <IconButton disableRipple sx={{
                    zIndex: 2,
                    width: '30px',
                    color: '#666666',
                    paddingBottom: '25px',
                    ".MuiSvgIcon-root": {
                        width: '20px',
                        height: '20px',
                    },
                    ".MuiSvgIcon-root:hover": {
                        color: 'red',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    }
                }} onClick={() => handleClickRemoveTestVariable(index)}><RemoveCircle /></IconButton>
            </RowStack>
        )
    }

    return (
        <Stack className={classes.divider}>
            <RowStack sx={{ justifyContent: 'space-between' }}>
                {title('Test Your Request')}
                <BotToggleSwitch
                    onChange={() => setLocalWebhookState({
                        ...localWebhookState,
                        testRequest: {
                            ...localWebhookState.testRequest,
                            isTestWithVariables: !localWebhookState.testRequest.isTestWithVariables,
                        }
                    })}
                    checked={localWebhookState.testRequest.isTestWithVariables}
                    isOptional={true}
                    sx={{ marginBottom: '20px', }} />
            </RowStack>
            {secondaryTitle('Manually set values for test variables', { marginBottom: '20px', })}
            {localWebhookState.testRequest.isTestWithVariables &&
                <>
                    {title('Edit Test Variables', { marginTop: '10px', })}
                    {(localWebhookState?.testRequest?.testVariables ?? []).map((testVariable, index) => (
                        testVariableRow(testVariable, index)
                    ))}
                    <BTbotbutton
                        sx={{ width: '155px', marginTop: '5px', }}
                        styleType="dashed"
                        onClick={() => setLocalWebhookState({
                            ...localWebhookState,
                            testRequest: {
                                ...localWebhookState.testRequest,
                                testVariables: [
                                    ...localWebhookState.testRequest.testVariables,
                                    { key: '', value: '' }
                                ],
                            }
                        })}>
                        Add Test Variable
                    </BTbotbutton>
                </>
            }
            <BTbotbutton
                sx={{ width: '140px', marginTop: '10px', }}
                size='medium'
                onClick={handleClickTestRequest}>
                Test the request
            </BTbotbutton>
            {testResponse &&
                <>
                    {title('Test Status Code', { marginTop: '10px', })}
                    <BotInputField
                        value={testResponse.code}
                    />
                    {title('Test Status Code', { marginTop: '10px', })}
                    <TextField
                        sx={{
                            borderRadius: '5px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '0 !important',
                            },
                            '& .MuiInputBase-input': {
                                padding: '5px 15px',
                                border: 0,
                                backgroundColor: '#F5F6FA',
                                borderRadius: '5px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#c5ceea #e3e6f2',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#2A2B4D',
                                    borderRadius: '5px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#1c1d34',
                                    borderRadius: '5px',
                                },
                            },
                        }}
                        InputProps={{
                            readOnly: true,
                            sx: {
                                padding: 0,
                            }
                        }}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        value={testResponse.message}
                    />
                </>
            }
        </Stack>
    )
}