import { Label } from "@common/models/label";

export const BLUETICKS_TOKEN_KEY = "bt-token";

export const POP_DIRECTIONAL_FORTMATTING_UNICODE: string = "\u202C";
export const TRANSPARENT_COLOR_RGBA: string = "rgba(0,0,0,0)";

export const labelsPossibleColors: string[] = [
  "#f44336",
  "#4caf50",
  "#3f51b5",
  "#9c27b0",
  "#4caf50",
  "#ff9800",
  "#ff5722",
];

export const labelsPossibleIconNames: string[] = [
  "School",
  "HomeOutlined",
  "WorkOutlineOutlined",
  "GroupOutlined",
  "SentimentVerySatisfiedOutlined",
  "RestaurantOutlined",
  "DriveEtaOutlined",
];

export function filterLabelsByTitle(
  labelList: Array<Label> | undefined,
  displayName: string
) {
  const filteredArray: Array<Label> =
    labelList?.filter((label: Label | undefined, index: number) =>
      label?.displayName?.includes(displayName)
    ) ?? [];

  return filteredArray;
}

export function findLabelIndexByTitle(
  labelList: Array<Label>,
  displayName: string
) {
  return labelList.findIndex(
    (label: Label, index: number) => label.displayName === displayName
  );
}

export function copyLabel(source: Label, target: Label) {
  target._id = source._id;
  target.displayName = source.displayName;
  target.color = source.color;
  target.icon = source.icon;
}

export function compareLabels(
  labelOne: Label,
  labelTwo: Label
) {
  return (
    labelOne._id === labelTwo._id &&
    labelOne.displayName === labelTwo.displayName &&
    labelOne.color === labelTwo.color &&
    labelOne.icon === labelTwo.icon
  );
}

export function spliceIfExists(
  array: string[] | undefined,
  element: string | undefined
) {
  if (array === undefined || element === undefined) {
    return;
  }
  const index: number | undefined = array.indexOf(element);

  if (index !== undefined && index !== -1) {
    array.splice(index, 1);
  }
}
