import { Select, MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { useState, useEffect } from "react";
import HourDropdown from "./hour-drop-down";

const SetTimePeriodForDateTrigger = ({ handleNumberChange, handleTimePeriodChange, handlecronBeforeOrAfter, handleHourSelected, selectedHour, cronNumberOfPeriod, timePeriod, cronBeforeOrAfter, disabled }) => {
    const [number, setNumber] = useState(1);
    const [period, setPeriod] = useState('days');
    const [beforeOrAfterOption, setBeforeOrAfter] = useState('before');

    const handleNumberPeriodChange = (number) => {
        setNumber(number);
        handleNumberChange(number);
    }
    const handlePeriodChange = (period) => {
        setPeriod(period);
        handleTimePeriodChange(period);
    }

    const handleBeforeOrAfterChange = (beforeOrAfterOption) => {
        setBeforeOrAfter(beforeOrAfterOption);
        handlecronBeforeOrAfter(beforeOrAfterOption);
    }

    useEffect(() => {
        setNumber(cronNumberOfPeriod ?? 1);
        setPeriod(timePeriod ?? "days");
        setBeforeOrAfter(beforeOrAfterOption ?? "before")

    }, [cronNumberOfPeriod, timePeriod, beforeOrAfterOption]);
    return (
        <Stack direction="row" alignItems={"center"} spacing={2} px={2}>
            <TextField
                disabled={disabled}
                sx={{ width: "60px" }}
                variant="standard"
                type="number"
                value={number}
                onChange={(e) => handleNumberPeriodChange(parseInt(e.target.value))}
            />

            <Select variant="standard"
                disabled={disabled}

                value={period}
                onChange={(e) => handlePeriodChange(e.target.value)}
            >
                <MenuItem value={"days"}>Days</MenuItem>
                <MenuItem value={"weeks"}>Weeks</MenuItem>
                <MenuItem value={"months"}>Months</MenuItem>
            </Select>

            <Select variant="standard"
                disabled={disabled}

                value={beforeOrAfterOption}
                onChange={(e) => handleBeforeOrAfterChange(e.target.value)}
            >
                <MenuItem value={"before"}>Before</MenuItem>
                <MenuItem value={"after"}>After</MenuItem>
            </Select>

            <div>date arrives at</div>


            <HourDropdown handleHourSelected={handleHourSelected} selectedHour={selectedHour} disabled={disabled} />

        </Stack>
    );
};
export default SetTimePeriodForDateTrigger;