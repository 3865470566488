import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box'
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar'
import PhotoOrColorSelect from '@common-components/tasks/person-picker/photo-or-color-select'
import { Popover } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { AugmentedBlueticksBoardUser } from '@common-reducers/BoardsSelectors'
import { DBTaskColumnOptionThunks, workloadSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks"

import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import React from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd'
import KanbanTask from '../kanban/kanban-task'
import UserCapacity from './user-capacity'
import classes from './bt-workload.module.scss';
import { userDisplayName } from '@common-models/user'
import { getOrderedTaskListForWorkloadUserCard, getColumnsForDisplayForWorkloadUserCard, getWorkloadUserForUserCard } from '@common-reducers/TaskSelectors'


type Props = {
    boardId: string;
    workloadId: string;
    userId: string;
    setKanbanCard: (event: React.MouseEvent<HTMLElement>, taskId: any) => void
    provided?: DraggableProvided;
    snapshot: DraggableStateSnapshot,
}

export default function UserCard({
    boardId,
    workloadId,
    userId,
    setKanbanCard,
    provided,
    snapshot
}: Props) {
    const emptyStateUser: AugmentedBlueticksBoardUser = {
        _id: "emptystateuserfordisplay", // 24 letters
        type: "user",
        boardId,
        name: "Empty Tasks",
        firstName: "Empty",
        lastName: "Tasks",
    }
    const tasksList = useAppSelector((state) => getOrderedTaskListForWorkloadUserCard(state, userId, workloadId))
    // const taskViewContainerOffset = useAppSelector(state => state.TasksReducer.taskViewContainerOffset)
    const complexityPickerId = useAppSelector(state => workloadSelectOneFieldByQuery(state.DBWorkloadReducer, {
        _id: workloadId
    }, 'complexityPickerId'))

    const columnsForDisplay = useAppSelector((state) => getColumnsForDisplayForWorkloadUserCard(state, boardId, workloadId))

    const user = useAppSelector(state => getWorkloadUserForUserCard(userId, state, workloadId));

    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // const [, translateXValue, , translateYValue] = provided.draggableProps.style.transform?.match(/(-?\d+)|\w+/g) ?? [];
    // const translateX = parseInt(translateXValue, 10) - taskViewContainerOffset.left;
    // const translateY = parseInt(translateYValue, 10) - taskViewContainerOffset.top;
    // const styleWithOffset = {
    //     ...provided.draggableProps.style,
    //     transform: snapshot.isDragging ? `translate(${translateX}px, ${translateY}px)` : '',
    // };


    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        padding: "0 10px",
        fontSize: "24px",
        fontWeight: "800",
        borderRadius: "8px",
        border: "1px solid #53bdeb",
        fontFamily: "'Poppins', 'Assistant', sans-serif",
        "& .MuiInputBase-input": {
            padding: "0",
        },
    };
    const textTheme = {
        color: "var(--primary)",
        fontSize: "24px",
        padding: "0 10px",
        border: "1px solid transparent",
        borderRadius: "8px",
        fontWeight: "800",
        fontFamily: "'Poppins', 'Assistant', sans-serif",
        "&:hover": {
            border: "1px dashed var(--text-color)",
        },
    };

    const profile = userId !== "emptystateuserfordisplay" ? user.profile : emptyStateUser

    const personHeader = ((profile) => {

        const fullName = userDisplayName(profile);
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const updateMockPersonImgPhoto = (url) => {
            dispatch(DBTaskColumnOptionThunks.patch({
                entity: {
                    _id: userId,
                    imageUrl: url,
                }
            }))
            setAnchorEl(null);
        }

        const updateMockPersonImgColor = ({ hex }) => {
            dispatch(DBTaskColumnOptionThunks.patch({
                entity: {
                    _id: userId,
                    imageUrl: hex,
                }
            }))
            setAnchorEl(null);
        }

        const handleClose = () => {
            setAnchorEl(null);
        };

        const openPhotoPicker = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
        };

        return (
            <Stack direction='column' sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                top: -35,
            }}
            >
                <Box
                    style={{
                        width: '80px',
                        height: '80px',
                        position: 'relative',
                        borderRadius: '50%',
                        backgroundColor: 'var(--tasks-header-background)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <Box
                        id={`photo-picker-${userId}`}
                        className={classes.header_button}
                        style={{
                            textTransform: 'none',
                            width: '28px',
                            height: '28px',
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                        }}
                        onClick={(e) => {
                            if (user.type === 'option') {
                                openPhotoPicker(e)
                            }
                        }}
                    >
                        {userId === "emptystateuserfordisplay" &&
                            <PersonAvatar
                                size={60}
                                email={profile?.email}
                            />}
                        {userId !== "emptystateuserfordisplay" &&
                            <PersonAvatar
                                size={60}
                                email={profile?.email}
                                profileImg={profile?.profileImg ?? userId}
                                lastName={(profile?.lastName === 'undefined' || profile?.lastName === undefined) ? ' ' : profile.lastName}
                                firstName={profile?.firstName}
                            />}
                    </Box>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <PhotoOrColorSelect handleColorChange={updateMockPersonImgColor} handlePhotoChange={updateMockPersonImgPhoto} />
                    </Popover>
                </Box>
                {user.type === 'option' &&
                    <BtEditableBox
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={fullName !== '' ? fullName : 'No Name'}
                        onValueUpdate={(value) => {
                            dispatch(DBTaskColumnOptionThunks.patch({
                                entity: {
                                    _id: userId,
                                    label: value,
                                }
                            }))
                        }}
                    />}
                {user.type !== 'option' &&
                    < span style={{ ...textTheme }}>
                        {fullName}
                    </span>
                }
            </Stack >
        )
    })


    return (
        <Stack direction='column'
            flex="0 0 260px"
            justifyContent={"flex-start"}
            sx={{
                width: '260px',
                minHeight: '100px',
                borderRadius: '12px',
                overflow: "visible",
                backgroundColor: 'var(--tasks-header-background)',
                paddingX: '16px',
                paddingBottom: '16px',
            }}
            {...provided?.draggableProps}
            // style={styleWithOffset}
            ref={provided?.innerRef}

        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
            }}
                {...provided?.dragHandleProps}
            >
                {personHeader(profile)}

                <Box sx={{
                    position: 'relative',
                    top: '-25px',
                    width: "100%",
                }}>
                    {userId !== "emptystateuserfordisplay" &&
                        <UserCapacity
                            boardId={boardId}
                            workloadId={workloadId}
                            complexityPickerId={complexityPickerId}
                            userId={userId}
                        />}
                </Box>
            </Box>

            <Droppable
                droppableId={`${userId}`}
                type="task">
                {(provided, snapshot) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                            width: '100%',
                            minHeight: '40px',
                            backgroundColor:
                                snapshot.isDraggingOver ? "var(--drag-over-bgcolor)" :
                                    "var(--tasks-header-background)",
                            borderRadius: "8px",
                        }}
                    >
                        <div
                            style={{
                                background: "transparent",
                                height: "100%",
                                boxSizing: "border-box",
                                position: "relative",
                            }}
                        >
                            {tasksList
                                .map((task, taskIndex) => {
                                    return (
                                        <Draggable
                                            key={`${task._id}_${taskIndex}_${taskIndex}`}
                                            draggableId={`${task._id}_${userId}_${taskIndex}`}
                                            index={taskIndex}
                                        >
                                            {(provided, snapshot) => (
                                                <KanbanTask
                                                    taskId={task._id}
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    setKanbanCard={setKanbanCard}
                                                    columnId={complexityPickerId}
                                                    _columnsForDisplay={columnsForDisplay}
                                                    key={`${taskIndex}_${taskIndex}_${task._id}`}
                                                />)
                                            }
                                        </Draggable>
                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    </Box>
                )}
            </Droppable>
        </Stack>
    )
}