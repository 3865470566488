export const appConfigDevServer = {
  DEFAULT_TITLE: 'Blueticks',
  asset_key: 'blueticks',
  google_client_id: '143723739146-oa75527ckplc3kb9nk8q41redtoq6t18.apps.googleusercontent.com',
  GTM_ID: '',
  GA_ID: '',
  CLARITY_ID:'',
  TIKTOK_ID:'',
  FB_PIXEL_ID: '',
  AW_ID: '',
  WS_ID: '',
  HJ_ID: 0,
  HJ_SV: 0,
  isProd: false,
  ENV: "devserver",
  EXTENTION_ID: "adgnjhngogijkkppficiiepmjebijinl",
  SEND_UNIT: false,
  GATEWAY_ENDPOINT: "https://gateway.blueticks.co",
  NOTIFIER_ENDPOINT: "https://blueticks-staging.herokuapp.com",
  VZNLYTICS_ENDPOINT: "https://analytics.blueticks.co",
  WS_ENDPOINT: "wss://api-dev.blueticks.co",
  API_ENDPOINT: "https://api-dev.blueticks.co",
  PRICING_PAGE: "https://app.blueticks.co",
  APP_ENDPOINT: "https://dev-blueticks.netlify.app",
  HOMEPAGE: "https://blueticks.co",
  LOGO:"/img/tudu-logo.svg",
  LOGO2:"/img/tudo-logo-icon.svg",
  PAYPAL_CLIENT_ID:
    "AafpAM2aA4Lqus7h_iAa2szoitZM7-hkyZaU5jjYOtv-KqcuZCpFFlpbluVp4_F-6fQhcIui7AWZs9Ah",
  BLUESNAP_ENDPOINT: "https://bluesnap.com",
  BLUESNAP_STORE_ID: "648495",
  BLUESNAP_REDIRECT_ENC:
    "y3BO7mSu04vXEh55QSFI51vnnO%2FrL2uZ10%2FPUF2Fh8urn%2BFdpIn4tyxpPqhcCnm%2BVOdueGkdnZpbpSjw%2Fj215nog250lMwUyqKry1OkfDap8daL1Nxv4dJeDeSXd9Ai6vUKYhCKeZaQ%2ByyfLkDPwJwlx1BGnPQYlRYkYiZBknRcVCl6kAgHv5ZTKEc3YMGPO4kc9iJJQnqXsotsbI3Z5jgJ5rXvslcQJXPihvCycO2%2FJfYH%2F2cr5s6ZLhSZZZaZY",

  extensionStoreLink: "https://chrome.google.com/webstore/detail/blueticks/adgnjhngogijkkppficiiepmjebijinl",
  PRODUCTS: {
    personal: "PROD-72S99618JL130302",
    professional: "PROD-47W322527S599700R",
  },
  BLUESNAP_PLANS: {
    // Bluesnap plans
    "3984540": {
      id: "3984540",
      key: "personal-monthly",
      planName: "Scheduler Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: false,
      order: 1,
      price: 5,
      planType: 'Scheduler'
    },
    "3984542": {
      id: "3984542",
      key: "personal-annual",
      planName: "Scheduler Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: false,
      order: 1,
      price: 36,
      planType: 'Scheduler'
    },
    "3984586": {
      id: "3984586",
      key: "tasks-monthly",
      planName: "Manager Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 29,
      order: 2,
      planType: 'Manager',
    },
    "3984588": {
      id: "3984588",
      key: "tasks-annual",
      planName: "Manager Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 240,
      order: 2,
      planType: 'Manager',
    },
    "4031094": {
      id: "4031094",
      key: "tasks-monthly",
      planName: "Manager Monthly M1",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 9,
      order: 2,
      planType: 'Manager',
    },
    "4031092": {
      id: "4031092",
      key: "tasks-annual",
      planName: "Manager Annual A1",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      price: 84,
      order: 2,
      planType: 'Manager',
    },
    "3984594": {
      id: "3984594",
      key: "professional-monthly",
      planName: "Campaigner Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
      price: 49,
      planType: 'Campaigner',
    },
    "3984596": {
      id: "3984596",
      key: "professional-annual",
      planName: "Campaigner Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
      price: 348,
      planType: 'Campaigner',
    },
    "4031090": {
      id: "4031090",
      key: "professional-monthly",
      planName: "Campaigner Monthly M1",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
      price: 19,
      planType: 'Campaigner',
    },
    "4031088": {
      id: "4031088",
      key: "professional-annual",
      planName: "Campaigner Annual A1",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
      price: 180,
      planType: 'Campaigner',
    },
  },
  PLANS: {
    "P-2EV08809U66066511L45HVQQ": {
      id: "P-2EV08809U66066511L45HVQQ",
      key: "personal-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: false,
      order: 1,
    },
    "P-4CT097158P9389945L45HWKY": {
      id: "P-4CT097158P9389945L45HWKY",
      key: "personal-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: false,
      order: 1,
    },
    "P-5LL88227H7791351XMC3LKOA": {
      id: "P-5LL88227H7791351XMC3LKOA",
      key: "tasks-annual",
      planName: "Tasks - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      order: 2,
    },
    "P-6BE32461V8593561LMC3LIMI": {
      id: "P-6BE32461V8593561LMC3LIMI",
      key: "tasks-monthly",
      planName: "Tasks - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      tasks: true,
      bulk: false,
      order: 2,
    },
    "P-1DS464590R230570GL52SZ7I": {
      id: "P-1DS464590R230570GL52SZ7I",
      key: "professional-monthly",
      planName: "Premium - Monthly",
      intervalCount: 1,
      intervalUnit: "month",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
    },
    "P-1CW95607EC450134SL52S6DY": {
      id: "P-1CW95607EC450134SL52S6DY",
      key: "professional-annual",
      planName: "Premium - Annual",
      intervalCount: 1,
      intervalUnit: "year",
      messageLimit: -1,
      bulk: true,
      tasks: true,
      order: 3,
    },
  },
};
export default appConfigDevServer;
