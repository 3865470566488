import NewTaskGroupButton from "@common-components/tasks-action-bar/add-task-group-button/new-task-group-button";
import { BoardRowType } from "@common-models/task";
import { Box, Stack } from "@mui/material";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { memo } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import GroupHeader from "../group-header/GroupHeader";
import GroupHeaderBoardView from "../group-header/GroupHeaderBoardView";
import { BoardRowElement } from "../helpers/elements-factory/elementsFactory";
import NewTaskRow from "../new-task-row/new-task-row";
import TaskRow from "../task-row/task-row";
import classes from "./board-row.module.scss";


interface BoardRowProps {
  item: BoardRowElement;
  provided: DraggableProvided;
  isDragging?: any;
  isClone?: boolean;
  isDisplayNewGroupButton?: boolean;
}

export function BoardRow({
  item,
  provided,
  isDragging,
  isClone,
  isDisplayNewGroupButton = true,
}: BoardRowProps) {
  const direction = useAppSelector((state) => state.UserReducer.direction);

  if (isClone) {
    //console.log('Rendering clone!')
  }
  return (
    <Box
      data-testid={item.type === BoardRowType.task ? 'task-row'
        :
        item.type === BoardRowType.subTask ? 'subTask-row'
          :
          item.type === BoardRowType.groupHeader ? 'groupHeader-row'
            :
            'other-row'}
      sx={{
        // contain: 'layout style',
        direction: direction,
        "&:focus-visible": {
          outline: 'none!important'
        },

      }}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
      style={provided?.draggableProps.style}
      className={`${classes.item} ${isDragging ? classes.isDragging : ""}`}>
      {
        item?.type === BoardRowType.groupHeader &&
        <GroupHeader provided={provided} groupId={item?.groupId} />
      }
      {
        item?.type === BoardRowType.boardHeader &&
        <GroupHeaderBoardView boardId={item?.boardId} tasksCount={item.tasksCount} />
      }

      {
        (item?.type === BoardRowType.task || item?.type === BoardRowType.subTask) &&
        <Box
          {...provided?.dragHandleProps}
        >
          <TaskRow
            taskId={item?.taskId}
            isFirstTaskInGroup={item?.isFirstTaskInGroup}
            isLastTaskInGroup={item?.isLastTaskInGroup}
            colorAccent={item?.colorAccent}
            isDragging={isDragging}
          />
        </Box>
      }
      {
        item?.type === BoardRowType.newTask &&
        <Stack direction="row">
          <NewTaskRow
            groupId={item?.groupId}
            parentTaskId={item?.parentTaskId}

            colorAccent={item?.colorAccent}
            isLastTaskInSubTasksGroup={item?.isLastTaskInSubTasksGroup}
          />

        </Stack>

      }

      {
        item?.type === BoardRowType.spacer &&
        <div style={{ backgroundColor: "var(--secondary-background-color)", height: '39px' }} >
          {/* {item?.groupId} */}
        </div>
      }

      {isDisplayNewGroupButton &&
        item?.type === BoardRowType.newGroup &&
        <Box sx={{ padding: "0 40px" }}> <NewTaskGroupButton position="bottom" /></Box>
      }




    </Box>
  );
}


export default memo(BoardRow);


