
import Checkbox from '@common-components/tasks/custom-checkbox/checkbox'
import EditTaskInput from '@common-components/tasks/edit-task-input/edit-task-input'
import { Stack } from '@mui/system'
import { tasksSelectOneFieldById } from "@common-reducers/DBServiceThunks"

import { ReactComponent as FocusIcon } from '@common-assets/focusIcon.svg'
import { getIsFocused, setFocusedTask } from '@common-reducers/NewTabFocusThunks'
import { getIsChecked, getIsShowSubTasks, getOneFieldFromTaskGroupByTaskId, getSubTasksCount, isFirstTaskInGroup, isLastTaskInGroup } from '@common-reducers/TaskSelectors'
import { selectRowItemWithThunk } from '@common-reducers/TaskThunks'
import { taskActions } from '@common-reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { useCallback, useMemo } from 'react'
import { BsArrow90DegUp } from 'react-icons/bs'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import classes from "../../task-row/task_row.module.scss"
import { CellProps } from '../cell-interface'



export default function RowPrefixCell({ taskId, }: CellProps) {
    const dispatch = useAppDispatch()
    const isShowSubTasks = useAppSelector((state) => getIsShowSubTasks({ state, taskId }))
    const isChecked = useAppSelector((state) => getIsChecked({ state, taskId }))
    const isFocused = useAppSelector((state) => getIsFocused({ state, taskId }))
    const subTasksCount = useAppSelector((state) => getSubTasksCount(state, taskId))
    const colorAccent = useAppSelector((state) => getOneFieldFromTaskGroupByTaskId({ state, taskId, field: 'colorAccent' }))
    const parentTaskId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "parentTaskId"))
    const isSubtask = parentTaskId ? true : false;
    const currentDraggedId = useAppSelector((state) => state.TasksReducer.currentDraggedId)
    const isFirstInGroup = useAppSelector((state) => isFirstTaskInGroup(state, taskId));
    const isLastInGroup = useAppSelector((state) => isLastTaskInGroup(state, taskId))
    const direction = useAppSelector((state) => state.UserReducer.direction);

    const isNewTab = useAppSelector((state) => state.TasksReducer.isNewTab)
    const toggleSubtasks = useCallback(() => {
        dispatch(taskActions.toggleTask(taskId))
    }, [dispatch, taskId])

    const selectRowItem = useCallback((e: any) => {
        dispatch(selectRowItemWithThunk({ shiftKey: e.shiftKey, taskId }))
    }, [dispatch, taskId])

    const selectFocusItem = useCallback((e: any) => {
        dispatch(setFocusedTask({ taskId }))
    }, [dispatch, taskId])

    const getBorderRadius = useMemo((): string => {
        if (direction === 'ltr') {
            return (!isSubtask && isFirstInGroup && isLastInGroup && !isShowSubTasks) ? "12px 0 0 12px" :
                (!isSubtask && isFirstInGroup && (!isLastInGroup || isShowSubTasks)) ? "12px 0 0 0" :
                    (!isSubtask && isLastInGroup && !isShowSubTasks) ? "0 0 0 12px" :
                        "0";
        } else {
            return (!isSubtask && isFirstInGroup && isLastInGroup && !isShowSubTasks) ? "0 12px 12px 0" :
                (!isSubtask && isFirstInGroup && (!isLastInGroup || isShowSubTasks)) ? "0 12px 0 0" :
                    (!isSubtask && isLastInGroup && !isShowSubTasks) ? "0 0 12px 0" :
                        "0";
        }
    }, [direction, isFirstInGroup, isLastInGroup, isShowSubTasks, isSubtask]);

    const color = colorAccent ?? '#00c875';

    return (
        <>
            {isSubtask && (
                <Stack width={29} alignItems="center"
                    style={{
                        marginLeft: "5px",
                        marginRight: "1px",
                    }} >
                    <BsArrow90DegUp color={color} size={14} />
                </Stack>
            )}


            <div style={{
                height: "100%",
                background: currentDraggedId !== taskId ? color : 'transparent',
                borderRadius: !isSubtask && isLastInGroup && !isShowSubTasks ? isFirstInGroup ? "12px 0 0 12px" : "0 0 0 12px" : "0",
            }}>
                {!isNewTab ?
                    <Stack width={isSubtask ? 33 : 38} height="100%"
                        data-testid='task-row-checkbox'
                        className={classes.background}
                        style={{
                            justifyContent: "center",
                            borderRadius: currentDraggedId !== taskId ? getBorderRadius : 0,
                            position: 'relative',
                            background: isChecked ? 'var(--task-edit-mode)' : 'var(--task-background-color) !important'
                        }}
                        alignItems="center"
                        onClick={selectRowItem}>
                        <Checkbox checked={isChecked} color={color} />
                    </Stack> :
                    <Stack alignItems="center" width={isSubtask ? 33 : 38} height="100%"
                        className={classes.background}
                        onClick={selectFocusItem}
                        sx={{
                            justifyContent: "center",
                            borderRadius: getBorderRadius,
                            position: 'relative',
                            background: isChecked ? 'var(--task-edit-mode)' : 'var(--task-background-color)',
                            filter: !isFocused ? "grayscale(1)" : ""

                        }}>
                        <FocusIcon />
                    </Stack>
                }
            </div>

            {!isSubtask && (
                <Stack
                    style={{
                        cursor: "pointer",
                        opacity: (isShowSubTasks || subTasksCount > 0) && 1,
                    }}
                    className={classes.subtasks_collapse_button}
                    onClick={toggleSubtasks}
                    alignItems="center">
                    {
                        isShowSubTasks ? <MdKeyboardArrowDown size={18} /> : <MdKeyboardArrowRight size={18} />
                    }
                </Stack>
            )}

            <Stack
                direction="row"
                alignItems="center"
                justifyContent='space-between'
                flexGrow={1}
                sx={{
                    overflow: 'hidden',
                    cursor: '',
                    paddingInlineStart: 0
                }}
            >
                {/* <span style={{ color: "darkblue" }}><b>{taskOrder}   </b></span>
                <span style={{ color: "darkblue" }}><b>{parentTaskId}</b></span> */}
                <div style={{width: '100%',overflow: 'hidden', }}> {/* This div takes up remaining space */}
                    <EditTaskInput taskId={taskId} />
                </div>
                {subTasksCount > 0 && (
                    <div className={classes.subTasksBadge}>
                        {/* <BsArrow90DegUp style={{marginRight: '2px'}} size={8} /> */}
                        {subTasksCount}
                    </div>
                )}
            </Stack>
        </>
    )
}