import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import RowStack from '@common-components/common/row-stack/RowStack';
import EditableOptions from '@common-components/tasks/columns-cells/option-picker/EditableOptions';
import MappedOptions from '@common-components/tasks/columns-cells/option-picker/MappedOptions';
import { HelpOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { setCurrentBot, useStore } from '@common/reducers/ChatbotReducer';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk, removeHandleAndConnectedEdgesByHandleIdArrayThunk } from '@common-reducers/ChatbotThunks';
import { getChatbotTempId } from '@common-services/utils';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import classes from "./status-node.module.scss";

type Props = {
    onClose: () => void
    id?: string;
}



export default function AddStatusDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch()
    const { setNodes } = useStore();


    const data = useAppSelector(state => getNodeDataByNodeId(state,id))
    const currentBot = useAppSelector(state => state.ChatbotReducer.currentBot)

    const [isEditing, setIsEditing] = useState(false)
    const [localNodeData, setLocalNodeData] = useState(data)
    const [localEntitiesArrayToDelete, setLocalEntitiesArrayToDelete] = useState([])
    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)


    // data: {
    //     handles:
    //     [
    //         inputs: ...,
    //         outputs: ...
    //     ]
    //     answers: {
    //         [handleId]: { _id: string, label: string, bgcolor: string, order: number, handleId: string }
    //     }
    // }







    const onChangeMessageEditor = (value) => {
        const dataToPatch = { question: value.message, questionAsset: value.asset }

        setLocalNodeData({
            ...localNodeData,
            ...dataToPatch
        })
    }

    const messageEditorDialog = () => {
        return (
            <>
                <div className={classes.add_status_title}>Status message text</div>
                <BotMessageEditor
                    _message={{
                        message: localNodeData?.question,
                        asset: localNodeData?.questionAsset
                    }}
                    onChange={(statusMessage) => onChangeMessageEditor(statusMessage)} />
            </>
        )
    }


    const onEditChange = (options: any[]) => {
        // handle updated answers
        const newOptions = options
            .map(option => {
                if (!option._id) {
                    return ({
                        ...option,
                        _id: getChatbotTempId(),
                        handleId: getChatbotTempId(),
                    })
                } else {
                    return option
                }
            })
            .sort((a, b) => a.order - b.order);
        const newHandles = {
            ...localNodeData.handles,
            outputs: newOptions.map(option => ({
                id: option.handleId,
                type: 'source',
            }))
        }
        // handle removed answers
        const newAnswersObject = newOptions.reduce((acc, item) => {
            acc[item.handleId] = { ...item };
            return acc;
        }, {})

        const deletedOptionshandleIds = Object.keys(localNodeData.answers)
            .filter(handleId => !newAnswersObject[handleId])
        setLocalEntitiesArrayToDelete([...localEntitiesArrayToDelete, ...deletedOptionshandleIds])


        setLocalNodeData({
            ...localNodeData,
            answers: newAnswersObject,
            handles: newHandles
        })
    }


    const mappedStatusOptions = () => {
        console.log(localNodeData)
        return (
            <Stack sx={{
                width: '100%',
                alignItems: 'center',
            }}>
                <Stack sx={{
                    width: '100%',
                    alignItems: 'flex-start',
                }}>
                    <div className={classes.add_status_title}>
                        Status name
                        <Tooltip
                            PopperProps={{ style: { direction: "ltr" } }}
                            title="Name the status column on your chatbot log">
                            <HelpOutlined sx={{ width: '15px', }} />
                        </Tooltip>
                    </div>
                    <BotInputField
                        value={localNodeData.title ?? ''}
                        onChange={(newValue) => setLocalNodeData({ ...localNodeData, title: newValue })}
                        placeholder={'Status name...'} />
                </Stack>
                {
                    isEditing &&
                    <EditableOptions
                        onChange={(options: any[]) => onEditChange(options)}
                        onCancel={() => setIsEditing(false)}
                        canEdit={true}
                        options={Object.values(localNodeData?.answers)}
                        isComplexity={false}
                        onSave={() => setIsEditing(false)}
                        boardId={id}
                        columnId={id}
                        saveString={'Save'}
                        newLabelString={'New Label'}
                        defaultLabelTooltipString={'You cannot change the color of the default label'}
                        labelPlaceholderString={'Label'}
                        complexityPlaceholderString={'Complexity'}
                        defaultLabelRemoveTooltipString={'You cannot remove the default label'}
                        removeInUseOptionTooltipString={'You cannot remove a used label'}
                    />
                }
                {
                    !isEditing &&
                    <MappedOptions
                        options={Object.values(localNodeData?.answers)}
                        handleValueSelected={() => { }}
                        canEdit={true}
                        onEdit={() => setIsEditing(true)}
                        isComplexity={false}
                        editLabelsString={'Edit Labels'}
                    />
                }
            </Stack >
        )
    }

    const onChangeVariableName = (value) => {
        setLocalCurrentBot({
            ...localCurrentBot,
            botSettings: {
                ...localCurrentBot?.botSettings,
                variables: {
                    ...localCurrentBot?.botSettings?.variables,
                    [id]: value
                }
            }

        })
    }

    const addVariable = () => {
        return (
            <Stack>
                <div className={classes.add_status_title}>Save answers in a variable</div>
                <BotInputField
                    value={localCurrentBot?.botSettings?.variables?.[id] ?? ''}
                    onChange={(newValue) => onChangeVariableName(newValue)}
                    placeholder={'Type a new variable'} />
            </Stack>
        )
    }


    const saveNewStatusData = () => {
        dispatch(changeNodeDataThunk({
            setNodes,
            id,
            dataToPatch: localNodeData
        }))

        dispatch(removeHandleAndConnectedEdgesByHandleIdArrayThunk(localEntitiesArrayToDelete))

        dispatch(setCurrentBot(localCurrentBot))

        onClose()
    }

    return (
        <div className={classes.add_status_message_editor_wrapper}>
            {/*message editor*/}
            {messageEditorDialog()}
            <div className={classes.divider} />
            {/*map status options*/}
            {mappedStatusOptions()}
            {/*add variable*/}
            <div className={classes.divider} />
            {addVariable()}
            {/*save and cancel button*/}
            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton onClick={saveNewStatusData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
            </RowStack>
        </div>
    )
}