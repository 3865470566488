import { setHoveredDate } from "@common-reducers/WhatsAppReducer";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { memo } from "react";
import classes from "./message-calendar.module.scss";




type Props = {
    date: any; // Consider using a more specific type for date if possible
    children?: React.ReactNode; // Marking children as optional
}

const CustomMonthDayBackground = ({ children, date }: Props) => {
    const dispatch = useAppDispatch()

    const handleMouseEnter = () => {
        dispatch(setHoveredDate(date))
    };
    const handleMouseLeave = () => {
        dispatch(setHoveredDate(undefined))
    };



    return (
        <div className={classes.monthDayBackground} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
        </div>
    );
};

export default memo(CustomMonthDayBackground)