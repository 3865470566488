import BtEditableBox from '@common-components/tasks/bt-editable-box/bt-editable-box';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import PhotoOrColorSelect from '@common-components/tasks/person-picker/photo-or-color-select';
import { userDisplayName } from '@common-models/user';
import { Box, Fade, IconButton, Popover, Stack, TableCell, TableRow } from '@mui/material';
import { AugmentedBlueticksBoardUser, isCurrentBoardOwnerSelector } from '@common-reducers/BoardsSelectors';
import { DBTaskColumnOptionThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';

type Props = {
    person: AugmentedBlueticksBoardUser;
    index: number;
}

export default function ShareBoardMockUserRow
    ({
        person,
        index,
    }: Props) {
    const dispatch = useAppDispatch()
    const isCurrentBoardOwner = useAppSelector(isCurrentBoardOwnerSelector);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [personId, setPersonId] = useState('')

    const handleMockPersonDelete = (Id) => {
        dispatch(DBTaskColumnOptionThunks.delete({
            entity: {
                _id: Id,
            }
        }))
    }




    const handlePersNameChange = (id, value) => {
        if (value === '') {
            return
        }
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: id,
                label: value,
            }
        }))
    }

    const profileName = (profile) => {
        const fullName = userDisplayName(profile);
        return fullName
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const updateMockPersonImgPhoto = (url) => {
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: personId,
                imageUrl: url,
            }
        }))
        setAnchorEl(null);
    }

    const updateMockPersonImgColor = ({ hex }) => {
        dispatch(DBTaskColumnOptionThunks.patch({
            entity: {
                _id: personId,
                imageUrl: hex,
            }
        }))
        setAnchorEl(null);
    }

    const openPhotoPicker = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const inputTheme = {
        color: "var(--primary)",
        width: "50px",
        height: '30px',
        fontSize: "15px",
        borderRadius: "4px",
        border: "2px solid #53bdeb",
        fontFamily: "'Poppins', sans-serif",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        display: 'flex',
        alignItems: 'center',
    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "15px",
        width: "50px",
        height: '30px',
        border: "2px solid var(--border-color)",
        borderRadius: "4px",
        "&:hover": {
            border: "2px solid var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
    };


    return (
        <>
            <TableRow key={index} sx={{ backgroundColor: 'var(--default-background)', }}>
                <TableCell key={2} sx={{ width: '100%'}}>
                    <Stack direction="row" alignItems={'center'} gap="10px" sx={{ width: '100%' }}>
                        {/* avatar */}
                        <Box
                            id={`photo-picker-${index}`}
                            sx={{
                                textTransform: 'none',
                                width: '28px',
                                height: '28px',
                                ':hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={(e) => {
                                if (person.type === 'option') {
                                    openPhotoPicker(e)
                                    setPersonId(person._id)
                                }
                            }}
                        >
                            <PersonAvatar
                                email={person?.email}
                                profileImg={person?.profile?.profileImg}
                                lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                                firstName={person?.profile?.firstName}
                                isName={false}
                            />
                        </Box>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 0 }}
                        >
                            <PhotoOrColorSelect
                                handleColorChange={updateMockPersonImgColor} handlePhotoChange={updateMockPersonImgPhoto} />
                        </Popover>
                        {/* username */}
                        <BtEditableBox
                            shouldStretch={false}
                            inputSx={{
                                ...inputTheme,
                                border: person.type === 'user' ? "1px solid transparent" :
                                    "1px solid #53BDEB",
                                height: 34,
                                fontFamily: "'Assistant', 'Inter'",
                                fontWeight: 600,
                                flexShrink: 0,
                                padding: "0 8px",
                                width: "100%",
                            }}
                            boxSx={{
                                ...textTheme,
                                width: '140px',
                                border: "1px solid transparent",
                                height: 34,
                                fontFamily: "'Assistant', 'Inter'",
                                fontWeight: 600,
                                flexShrink: 0,
                                padding: "0 8px",
                                boxSizing: "border-box",
                                "&:hover": {
                                    border: '1px dashed black',
                                },
                                "& .MuiBox-root:hover": {
                                    border: '1px dashed black',
                                }
                            }}
                            text={() => profileName(person?.profile)}
                            onValueUpdate={(value) => { handlePersNameChange(person._id, value) }}
                            disabled={person.type === 'user'}
                        />
                    </Stack>
                </TableCell>
                <TableCell key={5}>
                </TableCell>
                {/* delete button */}
                <TableCell key={4}>
                    {isCurrentBoardOwner &&
                        <IconButton
                            onClick={(e) => {
                                handleMockPersonDelete(person._id)
                                e.stopPropagation();
                            }}
                        >
                            <RiDeleteBinLine style={{ height: '16px', width: '16px' }} />
                        </IconButton>}
                </TableCell>

            </TableRow>
        </>
    )
}