import { createTheme, ThemeOptions } from "@mui/material";
import type { } from "@mui/x-date-pickers/themeAugmentation";
import { BTTextFieldProps } from "@common-components/atoms/text-field/bt-text-field";
import { BTPaperProps } from "@common-components/atoms/bt-paper/bt-paper";

export const colors = {
  green: '#00c875'
}

export const groupcolors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#00C875",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b"
];

const baseTheme: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      main: "#53bdeb",
    },
    secondary: {
      main: "#2C2E2F",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            // border: '1px solid var(--field-border-color)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--field-focused)',
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "var(--primary)",
        },
        body2: {
          color: "var(--primary)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ...BTPaperProps,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "var(--primary)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.75,
            color: "var(--bt-button-text-disabled)",
          },
        },
        containedPrimary: {
          color: "#FFFFFF",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "var(--primary)",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "var(--primary)",
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ".MuiTypography-root.ListItemText-secondary": {
            color: "var(--secondary)",
          },
          "& .ListItemText-secondary": {
            color: "var(--secondary)",
          },
        },
      },
    },
    // MuiPickerStaticWrapper: {
    //   styleOverrides: {
    //     content: {
    //       backgroundColor: 'var(--background-default)'
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: ({
          ...BTTextFieldProps,
        }),
      },
    },
    // MuiCalendarPicker: {
    //   styleOverrides: {
    //     root: ({
    //       color: "var(--primary)",
    //       ".MuiButtonBase-root": {
    //         color: "var(--primary)",
    //         "&.Mui-disabled": {
    //           opacity: 0.5,
    //           color: "var(--primary)",
    //         },
    //       },
    //       ".MuiTypography-root": {
    //         color: "var(--primary)",
    //       },
    //       ".MuiYearPicker-root": {
    //         ".Mui-disabled": {
    //           color: "var(--secondary)",
    //         },
    //       },
    //       ".MuiPickersDay-root": {
    //         color: "var(--primary)",
    //         backgroundColor: "var(--background-default)",

    //         "&.Mui-disabled": {
    //           opacity: 0.5,
    //           color: "var(--primary)",
    //         },
    //       },
    //     }),
    //   },
    // },
    // MuiClockPicker: {
    //   styleOverrides: {
    //     root: ({
    //       '[role="option"]': {
    //         color: "var(--primary)",
    //       },
    //     }),
    //   },
    // },
  },
};

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "light",
  },
});
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "dark",
  },
});

export const theme = lightTheme;



export const blueticksDefultTheme: any = {

  boards: {
    tasksHeader: {
      background: 'var(--tasks-header-background)',
    },
    tasksPanel: {
      background: 'var(--secondary-background-color)',
      tasks: {
        border: 'transparent',
      },
      tasksHeader: {
        backgorund: undefined,
      }
    },
  }

};

export const blueticksLeanTheme: any = {
  boards: {
    tasksHeader: {
      background: 'var(--default-background)',
    },
    tasksPanel: {
      background: 'var(--default-background)',
      tasks: {
        border: '1px solid var(--secondary-background-color)'
      },
      tasksHeader: {
        backgorund: 'var(--default-background)'
      }
    }
  }
};

