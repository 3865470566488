import NoResults from '@common-assets/lottie/no-results.json';
import GroupHeader from '@common-components/tasks/group-header/GroupHeader';
import { BoardRowElement } from "@common-components/tasks/helpers/elements-factory/elementsFactory";
import { BoardRowType, BTdragElement } from "@common-models/task";
import { Box, ClickAwayListener, Popper, Stack } from "@mui/material";
import { getBlueticksThemeSelector, getViewFiltersLength } from "@common-reducers/BoardsSelectors";
import { hasBulkTasks } from "@common-reducers/BulkActionsReducer";
import { selectElementsList } from "@common-reducers/TaskSelectors";
import { taskActions } from "@common-reducers/TasksReducer";
import { updateTasksOrderAfterTaskDragEnd } from '@common-reducers/TaskThunks';
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import ConditionalWrapper from "@common-components/conditional-wrapper/ConditionalWrapper";
import Lottie from "lottie-react";
import React, {
  DetailedHTMLProps,
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";
import { DragDropContext, Draggable, DragStart, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { Virtuoso } from "react-virtuoso";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { BoardRow } from "../tasks/board-row/BoardRow";
import BulkActionsDialog from "./bulk-actions-dialog/bulk-actions-dialog";
import MoveToMenu from "./bulk-actions-dialog/move-to/move-to-menu";
import classes from "./chat-task-list.module.scss";
import { debounce } from 'lodash';
import BoardsLoader from './recents-boards/boards-loader';
import FpsTracker from './FpsTracker';


// Virtuoso's resize observer can this error,
// which is caught by DnD and aborts dragging.
window.addEventListener("error", (e) => {
  if (
    e.message ===
    "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

type Props = {
  isDisplayNewGroupButton?: boolean;
  isFromCampaign?: boolean;
  height?: Number;
}


function ChatTaskList({ isDisplayNewGroupButton = true, isFromCampaign = false, height }: Props) {
  const dispatch = useAppDispatch();
  const [stickyHeader, setStickyHeader] = useState(null);
  const groupHeaderProvidedRef = useRef<any>();
  const theme = useAppSelector((state) => getBlueticksThemeSelector(state));
  const elementList = useAppSelector(selectElementsList);
  const textFilter = useAppSelector((state) => state.TasksReducer.tasksFilter.text);
  const viewFilterLength = useAppSelector((state) => getViewFiltersLength(state));
  const bulkActionMoveToId = useAppSelector((state) => state.BulkActionsReducer.bulkActionMoveToId);
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const isMoveTo = useAppSelector((state) => state.BulkActionsReducer.isMoveTo);


  const isTasksLoaded = useAppSelector((state) => state.DBTasksReducer.itemsLoaded);
  // const isTaskColumnsLoaded = useAppSelector((state) => state.DBTaskColumnReducer.itemsLoaded);
  // const isTaskGroupsLoaded = useAppSelector((state) => state.DBTaskgroupsReducer.itemsLoaded);
  // const isTaskColumnOptionsLoaded = useAppSelector((state) => state.DBTaskColumnOptionReducer.itemsLoaded);
  // const isBoardItemsLoaded = useMemo(() => {
  //   return isTaskColumnsLoaded && isTaskGroupsLoaded && isTaskColumnOptionsLoaded && isTasksLoaded
  // }, [])

  const scrollContainerRef = useRef(null);

  const params = new URLSearchParams(window.location.search);
  const paramsTaskId = params.get('taskId') ? params.get('taskId') : 0;






  const debouncedStopScrollDispatch = debounce((isScrollingNow) => {
    dispatch(taskActions.setTaskViewIsScrollingEnd(isScrollingNow));
  }, 500);


  const debouncedStartScrollDispatch = debounce((isScrollingNow) => {

    if (isScrollingNow) {
      dispatch(taskActions.setTaskViewIsScrollingStart(true));
    }

  }, 2000);

  // const setScrollVelocity = (scrollTop) => {
  //   const currentTimestamp = Date.now();
  //   const timeElapsed = currentTimestamp - lastTimestamp.current;

  //   if (timeElapsed > 0) {
  //     const scrollDifference = scrollTop - lastScrollTop.current;
  //     const velocity = Math.abs(scrollDifference / timeElapsed); // pixels per millisecond

  //     // You can dispatch the velocity or use it as needed
  //     dispatch(taskActions.setScrollVelocity(velocity));
  //   }

  //   lastScrollTop.current = scrollTop;
  //   lastTimestamp.current = currentTimestamp;
  // };

  const idToIndexMap = useMemo(() => {
    return elementList.reduce((acc, element, index) => {
      if (element.id) {
        acc[element.id] = index;
      }
      return acc;
    }, {});
  }, [elementList]);


  const getInitialIndex = (id) => {
    const index = idToIndexMap[id];
    if (index !== undefined) {
      return index;
    } else return 0;

  };

  const initIndex = getInitialIndex(paramsTaskId);



  console.log(`ChatTaskList rendering`);

  const [draggedItem, setDraggedItem] = useState<BoardRowElement>();
  let menuAnchorEl = null;
  if (bulkActionMoveToId) {
    menuAnchorEl = document.getElementById(`bulk-actions-menu-${bulkActionMoveToId}`);
  }

  const hasTaskResults = (elementList, textFilter) => {
    const containsTypes = elementList.some(element =>
      element.type === 'task' || element.type === 'groupHeader'
    );

    if (!containsTypes && (textFilter || viewFilterLength > 0)) {
      return false;
    }
    return true;
  };

  const foundTasks = hasTaskResults(elementList, textFilter);



  const updateStickyHeader = useCallback(({ startIndex, endIndex }) => {
    let newStickyHeader = null; // Default to null if no new header is found

    for (let i = startIndex; i >= 0; i--) {
      if (elementList[i].type === 'spacer' || elementList[i].type === 'newTask') {
        break; // Exit the loop as we're not updating for 'spacer' or 'newTask'
      }
      if (elementList[i].type === 'groupHeader') {
        newStickyHeader = elementList[i];
        break; // Found a groupHeader, so break the loop
      }
    }

    // Only update the stickyHeader state if the value has actually changed
    // This is checked by comparing the IDs (assuming each element has a unique ID)
    // Adjust the comparison condition based on how your elements are structured
    if (!stickyHeader || !newStickyHeader || stickyHeader.id !== newStickyHeader.id) {
      setStickyHeader(newStickyHeader);
    }
  }, [elementList, stickyHeader]); // Add stickyHeader to the dependency array


  const renderStickyHeader = () => {
    if (stickyHeader) {
      return (
        <div style={{
          position: "absolute", top: "0", zIndex: "10", transition: "ease-in 0.3s",
          background: "var(--secondary-background-color)"
        }}>
          <GroupHeader groupId={stickyHeader.groupId} provided={groupHeaderProvidedRef.current?.provided} />
        </div>
      );
    }
    return null;
  };

  const HeightPreservingItem = useCallback(
    ({
      children,
      ...props
    }: PropsWithChildren<
      DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    >) => {
      return (
        <div
          {...props}
          className="height-preserving-container"
          //dir={direction}
          style={{ height: "40px", width: 'fit-content' }}
        >
          {children}
        </div>
      );
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {

      const [_, itemType, index] = result.draggableId.split('-');

      if (!result.destination) {
        return;
      }

      const sourceEl = draggedItem;
      setDraggedItem(undefined);
      const destinationEl = elementList[result.destination.index];

      let source: BTdragElement;
      let destination: BTdragElement;
      let entity = "";
      let dragThresholdFromIndex = 0;

      if (itemType === BoardRowType.groupHeader) {
        source = {
          _id: sourceEl?.groupId,
          index: result.source.index,
        }
        destination = {
          _id: destinationEl?.groupId,
          index: result.destination.index,
          type: destinationEl?.type,
        }
        entity = "groups";
        dragThresholdFromIndex = 0;

      } else {
        source = {
          _id: sourceEl?.taskId,
          index: result.source.index,
        }
        destination = {
          _id: destinationEl?.taskId,
          index: result.destination.index,
          parent: destinationEl?.parentTaskId, // task is parent of subtask
          grandparent: destinationEl?.groupId, // group is grandparent of task
          type: destinationEl?.type,
          IgnoreElementTypesOnBottomTop: [BoardRowType.spacer, BoardRowType.groupHeader],
          IgnoreElementTypesOnTopBottom: [BoardRowType.spacer, BoardRowType.newTask],
        }
        entity = "tasks";
      }



      dispatch(updateTasksOrderAfterTaskDragEnd({ source, destination, entity, dragThresholdFromIndex }));

      if (itemType === BoardRowType.groupHeader) {
        dispatch(taskActions.setIsGroupDragging(false))
      }


      dispatch(taskActions.setCurrentDraggedId(''))

    },
    [dispatch, draggedItem, elementList]
  );


  const onBeforeDragStart = (initial: DragStart) => {
    setDraggedItem(elementList[initial.source.index])
    dispatch(taskActions.setCurrentDraggedId(elementList[initial.source.index].id))
  }

  //handle sort if sort is pending save or pending reorder
  function handleDragStart(initial: DragStart, provided: ResponderProvided) {
    const [_, itemType, index] = initial.draggableId.split('-')

    if (itemType === BoardRowType.groupHeader) {
      dispatch(taskActions.setIsGroupDragging(true))
    }
  }

  return (
    <>
      {!isTasksLoaded &&
        <BoardsLoader />
      }
      {isTasksLoaded && (
        <div
          dir={direction}
          style={{
            backgroundColor: theme?.boards?.tasksPanel?.background,
            position: "relative",
            // contain: "layout paint style",
          }}
        >
          {/* {renderStickyHeader()} */}

          {foundTasks ? (
            <DragDropContext
              onDragEnd={onDragEnd}
              onBeforeDragStart={onBeforeDragStart}
              onDragStart={handleDragStart}
            >
              <Droppable
                droppableId="board-list-droppable"
                mode="virtual"
                renderClone={(provided, snapshot) => (
                  <BoardRow
                    isDisplayNewGroupButton={isDisplayNewGroupButton}
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    item={draggedItem}
                    isClone
                  />
                )}
              >
                {(provided) => (
                  <Virtuoso
                    overscan={200}
                    components={{ Item: HeightPreservingItem }}
                    isScrolling={(isScrolling) => {
                      //console.log("isScrolling", isScrolling);
                      debouncedStopScrollDispatch(isScrolling);
                      debouncedStartScrollDispatch(isScrolling);

                    }}
                    initialTopMostItemIndex={initIndex}
                    scrollerRef={(ref: HTMLElement) => {
                      // Ensure Virtuoso's internal use of the ref is not disrupted
                      provided.innerRef(ref);

                      // Store the ref in your own useRef hook for additional uses
                      // if (ref) {
                      //   ref.addEventListener('scroll', () => {
                      //     setScrollVelocity(ref.scrollTop);
                      //   });
                      // }

                      scrollContainerRef.current = ref;
                    }}
                    data={elementList}
                    rangeChanged={updateStickyHeader}
                    style={{
                      height: isExtensionContextOrWhatsappView()
                        ? "calc(100vh - 407px)"
                        : "calc(100vh - 201px)", // add -69px for tabs
                    }}
                    className={classes.virtuoso_wrapper}
                    itemContent={(index, item) => {
                      let draggableId = `draggable-${item.type}-${item.id}`;
                      if (item.type === BoardRowType.spacer) {
                        draggableId = `draggable-${item.type}-${index}`;
                      }

                      // Important, key and draggableId props must be equal!
                      return (
                        <Draggable
                          key={draggableId}
                          draggableId={draggableId}
                          index={index}
                          isDragDisabled={[
                            BoardRowType.task,
                            BoardRowType.subTask,
                            BoardRowType.groupHeader,
                          ].indexOf(item.type) === -1}
                        >
                          {(provided) => (
                            <BoardRow
                              item={item}
                              provided={provided}
                              isDragging={false}
                              isDisplayNewGroupButton={isDisplayNewGroupButton}
                            />
                          )}
                        </Draggable>
                      );
                    }}
                  />
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Stack sx={{ height: "50vh" }} justifyContent={"center"} alignItems={"center"}>
              <Lottie autoPlay={true} loop={true} animationData={NoResults} style={{ width: '400px', height: '400px' }} />
              <Box fontSize="24px" textAlign={"center"}>
                We've looked everywhere but couldn't find any matching tasks or items.
                <br />
                Perhaps they're playing a game of hide-and-seek?
                <br /> Try refining your search or adjusting your filters to uncover them.
              </Box>
            </Stack>
          )}

          <ClickAwayListener onClickAway={() => { }}>
            <Popper open={isMoveTo && menuAnchorEl !== null} anchorEl={menuAnchorEl} style={{ zIndex: 1300 }}>
              <MoveToMenu />
            </Popper>
          </ClickAwayListener>

          <ConditionalWrapper shouldLoadSelector={hasBulkTasks}>
            <BulkActionsDialog />
          </ConditionalWrapper>
          <FpsTracker />
        </div>
      )}
    </>
  );
}

export default memo(ChatTaskList);
