import { Task } from "@common-models/task";
import { Stack } from "@mui/material";
import { isTempId, tasksSelectors } from "@common-reducers/DBServiceThunks";
import { columnListForDisplaySelector } from "@common-reducers/TaskSelectors";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import TaskCell from "./task-cell";
import classes from "./task_row.module.scss";
import { setExtraDataOfTaskId } from "@common-reducers/ExtraDataReducer";
import { taskActions } from "@common-reducers/TasksReducer";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";

export interface Props {
  taskId: string;
  colorAccent: string;
  subTasksCount?: number;
  isFirstTaskInGroup: boolean;
  isLastTaskInGroup: boolean;
  isDragging: boolean;
}

export interface TaskRowState {
  editing: boolean;
  internalValue?: string;
  showPlaceholder: boolean;
}

function TaskRow({
  taskId,
}: Props) {
  const task: Task = useSelector((state) =>
    tasksSelectors.selectById(state, taskId)
  );
  const dispatch = useAppDispatch()

  const columnList = useAppSelector(state => columnListForDisplaySelector(state, task?.boardId));
  const direction = useAppSelector((state) => state.UserReducer.direction);
  


  useEffect(() => {
    if (!isExtensionContextOrWhatsappView()) {
      const params = new URLSearchParams(window.location.search);
      const paramsTaskId = params.get('taskId') ? params.get('taskId') : 0;
    if (paramsTaskId === task?._id) {
      dispatch(setExtraDataOfTaskId(task._id));
      dispatch(taskActions.setTaskViewSideDrawerOpenWith("ExtraDataPanel"));
    }
  }
  }, [dispatch, task?._id])
  
  return (

    <Stack direction="row" className={classes.rowStack} sx={{
      direction: direction,
    }}>
      {
        columnList.map((column, index) => {
          return (
            <TaskCell
              key={index}
              column={column}
              index={index}
              taskId={taskId}
              columnListLength={columnList.length}
            />
          );
        })
      }
    </Stack>
  );
}

export default memo(TaskRow);
