import PromptDeleteAction from "@common-components/prompt-delete-action/prompt-delete-action";
import { appConfig } from "@common-config/app.config";
import { namespace } from "@common-config/constants";
import { boardsActions } from "@common-reducers/BoardsReducer";
import { isCurrentWorkspaceOwnerSelector, isUserBoardOwner } from "@common-reducers/BoardsSelectors";
import { boardsSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { MODAL, modalActions } from "@common-reducers/ModalReducer";
import { pickerDialogActions } from "@common-reducers/PickerDialogReducer";
import { UndoRedoAction } from "@common-reducers/UndoRedoReducer";
import { doActionThunk } from "@common-reducers/UndoRedoThunks";
import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { ClickAwayListener, Popper } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight, BsPencil, BsTrash } from "react-icons/bs";
import { MdFileDownload } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { PickerProps } from "../columns-cells/cell-interface";
import classes from "./board-menu-options.module.scss";


function BoardMenuOptions({ boardId }: PickerProps) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(namespace);

    const pickerAnchorId = useAppSelector((state) => state.PickerDialogReducer.pickerAnchorId);
    //const isBoardOwner = useAppSelector(isCurrentBoardOwnerSelector);
    const isBoardOwner = useAppSelector(state => isUserBoardOwner(state, { boardId }));
    const isCurrentWorkspaceOwner = useAppSelector(isCurrentWorkspaceOwnerSelector);

    const boardName = useAppSelector(state => boardsSelectOneFieldByQuery(state.DBBoardsReducer, {
        _id: boardId
    }, 'name'))
    const [deletePromptOpen, setDeletePromptOpen] = useState(false);
    let menuAnchorEl = document.getElementById(pickerAnchorId);
    const renameBoard = () => {
        dispatch(boardsActions.setRenameBoard(true))
        dispatch(pickerDialogActions.closePickerDialog())

    }
    // menu open board in new tab
    function openBoardInNewTab() {
        const boardURL = `${appConfig.APP_ENDPOINT}/tasks/?boardID=${boardId}`;
        if (isExtensionContextOrWhatsappView()) {
            window.open(boardURL, "_blank");
            //ContentMessageServiceActions.openTab(boardURL);
        } else {
            window.open(boardURL, '_blank')
        }
        dispatch(pickerDialogActions.closePickerDialog())

    }

    //menu delete board
    function deleteBoard(event: React.MouseEvent) {
        setDeletePromptOpen(true);
    }
    const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            const action: UndoRedoAction<string[], string> = {
                type: 'delete-board-list',
                data: [boardId],
                deletedOrArchived: "deleted"
            }
            dispatch(doActionThunk({ action }),)
        }
        dispatch(pickerDialogActions.closePickerDialog())

        setDeletePromptOpen(false);
        menuAnchorEl = null
    }

    function openExportModal() {
        dispatch(modalActions.setComponentToRender(MODAL.EXPORT_TO_EXCEL));
        dispatch(pickerDialogActions.closePickerDialog())

    }
    function openImportModal() {
        dispatch(
            modalActions.setComponentToRender(MODAL.IMPORT_EXCEL_TO_BLUETICKS)
        );
        dispatch(pickerDialogActions.closePickerDialog())

    }
    return (<>
        <PromptDeleteAction title={`${t('boardMenuOptions.deleteBoard')} ${boardName} ?`} handleClose={handleDeleteConfirmationClosed} open={deletePromptOpen} />
        <ClickAwayListener onClickAway={() => { dispatch(pickerDialogActions.closePickerDialog()) }}>
            <div style={{ position: 'relative', zIndex: 1300 }}>
                <Popper
                    anchorEl={menuAnchorEl}
                    open={!deletePromptOpen && menuAnchorEl !== null}
                    style={{ zIndex: 1300, position: 'absolute', direction: 'ltr' }}
                >
                    <div className={classes.menu_wrapper} style={{
                        position: pickerAnchorId.includes("board_panel_item") ? 'absolute' : 'unset',
                        left: pickerAnchorId.includes("board_panel_item") && 95
                    }}>
                        <div className={classes.menu_head}>
                            <button className={classes.menu_list_item} onClick={openBoardInNewTab}>
                                <BsBoxArrowUpRight />
                                <span style={{ marginLeft: 8 }}> {t('boardMenuOptions.openBoardInNewTab')}</span>
                            </button>

                            <button onClick={openImportModal} className={classes.menu_list_item}>
                                <MdFileDownload />
                                <span style={{ marginLeft: 8 }}>{t('boardMenuOptions.import')}</span>
                            </button>

                            <button onClick={openExportModal} className={classes.menu_list_item}>
                                <RiFileExcel2Fill />
                                <span style={{ marginLeft: 8 }}>{t('boardMenuOptions.exportBoardToExcel')}</span>
                            </button>



                        </div>
                        <button onClick={renameBoard} className={classes.menu_list_item}>
                            <BsPencil />
                            <span style={{ marginLeft: 8 }}>{t('boardMenuOptions.renameBoard')}</span>
                        </button>

                        <div className={classes.menu_footer}>
                            <div data-testid="board-panel-item-menu-delete-button">
                                <button
                                    style={{
                                        color: isBoardOwner || isCurrentWorkspaceOwner ? "" : 'lightgrey',
                                        cursor: isBoardOwner || isCurrentWorkspaceOwner ? 'pointer' : 'default'
                                    }}
                                    disabled={!(isBoardOwner || isCurrentWorkspaceOwner)} // Enable if either condition is true
                                    onClick={(e) => deleteBoard(e)}
                                    className={classes.menu_list_item}>
                                    <BsTrash />
                                    <span style={{ marginLeft: 8 }}>{t('boardMenuOptions.deleteBoard')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    </>
    );
}

export default memo(BoardMenuOptions);
