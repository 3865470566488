import { Gantt } from "@common-models/gantt";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { ganttSelectByQuery } from "@common-reducers/DBServiceThunks";

export const ganttIdList = createSelector(
  [
    (state, boardId) => ganttSelectByQuery(state.DBGanttReducer, { boardId })
  ],
  (ganttList: Gantt[]) => {
    const ganttIdList = ganttList.map((gantt, index) => gantt._id)
    return ganttIdList
  }
);

export interface GanttState {
  settingsMode: boolean;
  isPanelOpen:boolean
}

const initialState: GanttState = {
  settingsMode: false,
  isPanelOpen:true,
}


const ganttSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setSettingsMode(state, action: PayloadAction<boolean>) {
      state.settingsMode = action.payload;
    },
    setIsPanelOpen(state, action: PayloadAction<boolean>) {
      state.isPanelOpen = action.payload;
    },
  }
})

export default ganttSlice.reducer;

export const ganttActions = ganttSlice.actions;