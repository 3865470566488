import { QuickLaunch } from "@common-models/quick-launch";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuickLink } from "@common/reusable/interfaces";

interface State {
  targetComponent: string,
  quickLinks: QuickLink[]
  isNewQuickTask: boolean
  newTaskId: string
  newQuickLinkId: string
  alertPopper: AlertPopper,
  selectedData: QuickLaunch
  isErrorInput: boolean
  isDragging: boolean,
  clientX: number,
  ParentContainerWidth: number
  popperId: string,
  activeLink: QuickLaunch,
  isLoadingBoards: boolean
}

type AlertPopper = {
  type: string,
  message: string
  popperTargetId: string,
}

const initialState: State = {
  targetComponent: '',
  quickLinks: [],
  isNewQuickTask: false,
  newTaskId: '',
  newQuickLinkId: '',
  alertPopper: {
    type: '',
    message: '',
    popperTargetId: ''
  },
  selectedData: {
    boardId: "",
    groupId: "",
    groupName: "",
    colorAccent: "",
    firstName: '',
    lastName: ''
  },
  isErrorInput: false,
  isDragging: false,
  clientX: 0,
  ParentContainerWidth: 50,
  popperId: '',
  activeLink: {
    _id: '',
    boardId: '',
    groupId: '',
    firstName: '',
    lastName: ''
  },
  isLoadingBoards: false
};

const btQuickPanelSlice = createSlice({
  name: "btQuickPanelSlice",
  initialState,
  reducers: {
    setTargetComponent: (state, { payload }: PayloadAction<string>) => {
      state.targetComponent = payload
    },
    setQuickLinks: (state, { payload }: PayloadAction<QuickLink[]>) => {
      state.quickLinks = payload
    },
    setIsNewQuicktask: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewQuickTask = payload
    },
    setNewTaskId: (state, { payload }: PayloadAction<string>) => {
      state.newTaskId = payload
    },
    setNewQuickLinkId: (state, { payload }: PayloadAction<string>) => {
      state.newQuickLinkId = payload
    },
    setAlertPopper: (state, { payload }: PayloadAction<AlertPopper>) => {
      state.alertPopper = payload
    },
    setSelectedData: (state, { payload }: PayloadAction<QuickLaunch>) => {
      state.selectedData = payload
    },
    setIsErrorInput: (state, { payload }: PayloadAction<boolean>) => {
      state.isErrorInput = payload
    },
    setIsDragging: (state, { payload }: PayloadAction<boolean>) => {
      state.isDragging = payload
    },
    setClientX: (state, { payload }: PayloadAction<number>) => {
      state.clientX = payload
    },
    setParentContainerWidth: (state, { payload }: PayloadAction<number>) => {
      state.ParentContainerWidth = payload
    },
    setPopperId: (state, { payload }: PayloadAction<string>) => {
      state.popperId = payload
    },
    setActiveLink: (state, { payload }: PayloadAction<QuickLaunch>) => {
      state.activeLink = payload
    },
    setIsLoadingBoards: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingBoards = payload
    },
  },
});

export const btQuickPanelActions = btQuickPanelSlice.actions;
export default btQuickPanelSlice.reducer;
