import { ContactLabel } from "@common-models/contact-label"
import { _Label } from "@common-models/label"
import { createSlice, PayloadAction } from '@reduxjs/toolkit'


type LabelNetworkStatus =
    'create-label' | 'create-label-success' |
    'create-default-labels' | 'create-default-labels-success' |
    'fetching-labels' | 'fetching-labels-success' |
    'update-label' | 'update-label-success' |
    'delete-label' | 'delete-label-success' | ''


export type ContactBadge = {
    labelId: string
    labelName: string
    restCount: number
    waContactId: string
}

type Toast = {
    id: string;
    message: string;
    delay: string
}

type LabelsState = {
    activeLabelId: string
    isNewLabelModalOpen: boolean
    isSortChatToLabelModalOpen: boolean
    isLabelEditting: boolean
    isLabelDeletting: boolean
    label: _Label
    labelNetWorkStatus: LabelNetworkStatus
    contactLabels: ContactLabel[],
    openIssueContactLabels: ContactLabel[],
    loadingState: string[],
    customSnoozeWaContactId: string
    snoozeData: Snooze
    isSnoozePopperOpen: boolean
    toastList: Toast[]
    activeContactIds: string[]


}
export type Snooze = {
    expiryDate?: string,
    time?: string
}

const initialState: LabelsState = {
    activeLabelId: '',
    isNewLabelModalOpen: false,
    isSortChatToLabelModalOpen: false,
    isLabelEditting: false,
    isLabelDeletting: false,
    label: {
        icon: '',
        name: '',
    },
    labelNetWorkStatus: '',
    contactLabels: [],
    openIssueContactLabels: [],
    loadingState: [],
    customSnoozeWaContactId: '',
    snoozeData: {},
    isSnoozePopperOpen: false,
    toastList: [],
    activeContactIds: []
}

const labelSlice = createSlice({
    name: 'labelsSlice',
    initialState,
    reducers: {
        toggleNewLabel: (state, { payload }: PayloadAction<boolean>) => {
            state.isNewLabelModalOpen = payload
        },
        toggleSortChatToLabelModalOpen: (state, { payload }: PayloadAction<boolean>) => {
            state.isSortChatToLabelModalOpen = payload
        },
        toggleEditLabel: (state, { payload }: PayloadAction<boolean>) => {
            state.isLabelEditting = payload
        },
        toggleDeleteLabel: (state, { payload }: PayloadAction<boolean>) => {
            state.isLabelDeletting = payload
        },
        setActiveLabel: (state, { payload }: PayloadAction<string>) => {
            state.activeLabelId = payload
        },
        setLabel: (state, { payload }: PayloadAction<_Label>) => {
            state.label = payload
        },
        setLabelNetworkStatus: (state, { payload }: PayloadAction<LabelNetworkStatus>) => {
            state.labelNetWorkStatus = payload
        },
        setContactLabelIds: (state, { payload }: PayloadAction<ContactLabel[]>) => {
            state.contactLabels = payload
        },
        toggleActiveContactId: (state, { payload }: PayloadAction<string>) => {
            if (state.activeContactIds.includes(payload)) {
                state.activeContactIds = state.activeContactIds.filter((activeContactId) => activeContactId !== payload)
            } else {
                state.activeContactIds = [...state.activeContactIds, payload]
            }
        },
        setOpenIssuedContactLabel: (state, { payload }: PayloadAction<ContactLabel[]>) => {
            state.openIssueContactLabels = payload
        },
        addOpenIssuedContactLabel: (state, { payload }: PayloadAction<ContactLabel>) => {
            const isExists = state.openIssueContactLabels.find((item) => item.waChatId === payload.waChatId)
            if (isExists) return
            state.openIssueContactLabels.push(payload)
        },
        setLoadingState: (state, { payload }: PayloadAction<string[]>) => {
            state.loadingState = payload
        },
        addLoadingState: (state, { payload }: PayloadAction<string>) => {
            state.loadingState.push(payload)
        },
        setCustomSnoozeWaContactId: (state, { payload }: PayloadAction<string>) => {
            state.customSnoozeWaContactId = payload
        },
        setSnoozeData: (state, { payload }: PayloadAction<Snooze>) => {
            state.snoozeData = payload
        },
        setIsSnoozePopperOpen: (state, { payload }: PayloadAction<boolean>) => {
            state.isSnoozePopperOpen = payload
        },
        setToastMessage: (state, { payload }: PayloadAction<Toast>) => {
            state.toastList.push(payload)
        },
        deleteToastMessage: (state, { payload }: PayloadAction<string>) => {
            state.toastList = state.toastList.filter((toast) => toast.id !== payload)
        },
    }
})

export const labelActions = labelSlice.actions
export default labelSlice.reducer