const urlPolyfill = require('url-polyfill');

// Ensure URL is defined before exporting
const URL = urlPolyfill.URL || global.URL || window.URL;

// Create a minimal shim for the 'url' module
const urlShim = {
  URL: URL,
  parse: function(urlString) {
    const url = new URL(urlString);
    return {
      protocol: url.protocol,
      hostname: url.hostname,
      port: url.port,
      pathname: url.pathname,
      search: url.search,
      hash: url.hash,
      auth: url.username ? `${url.username}:${url.password}` : null,
    };
  },
  format: function(urlObject) {
    return new URL(urlObject).toString();
  },
  resolve: function(from, to) {
    return new URL(to, from).toString();
  }
};

// Export URL separately to ensure it's available for destructuring
module.exports = urlShim;
module.exports.URL = URL;