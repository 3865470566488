import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "dot-prop-immutable";

export interface UninstallState {
  uninstallForm: {
    ididntExpect: boolean;
    blueticksIsnt: boolean;
    whatDidYouExpect: string;
    workingCorrectly: boolean;
    scedualMessage: boolean;
    scedualMessageTA: string;
    bulkCampaign: boolean;
    bulkCampaignTA: string;
    taskManagement: boolean;
    taskManagementTA: string;
    apiIntegration: boolean;
    apiIntegrationTA: string;
    premiumPlan: boolean;
    tooExpensive: boolean;
    notEnoughFeatures:boolean;
    premiumOther:string;
    dontWantCreateAccount:boolean;
    missingFeatures:boolean;
    missingFeaturesTA: string;
    emailAddress:boolean;
    emailAddressTA: string;
  }
}

const initialState: UninstallState = {
  uninstallForm: {
    ididntExpect: false,
    blueticksIsnt: false,
    whatDidYouExpect: "",
    workingCorrectly: false,
    scedualMessage: false,
    scedualMessageTA: "",
    bulkCampaign: false,
    bulkCampaignTA : "",
    taskManagement: false,
    taskManagementTA: "",
    apiIntegration: false,
    apiIntegrationTA: "",
    premiumPlan: false,
    tooExpensive: false,
    notEnoughFeatures: false,
    premiumOther: "",
    dontWantCreateAccount: false,
    missingFeatures: false,
    missingFeaturesTA: "",
    emailAddress: false,
    emailAddressTA: "",
  }
};

export const UninstallReducerSlice = createSlice({
  name: "uninstallReducer",
  initialState,
  reducers: {
    updateUninstallState: (state, action: PayloadAction<{ path: string; value: any }>) => {
      state.uninstallForm = set(state.uninstallForm, action.payload.path, action.payload.value);
    },
  },
});

export const {
  updateUninstallState
} = UninstallReducerSlice.actions;


export default UninstallReducerSlice.reducer;
