import { getSeenByUsersProfile } from '@common-reducers/BoardsSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { CircularProgress } from '@mui/material';
import PersonAvatar from '../person-avatar/person-avatar';

type Props = {
    seenByIdArray: string[];
}

export default function SeenByTooltipComponent({
    seenByIdArray,
}: Props) {
    const seenByUsersProfile = useAppSelector((state) => getSeenByUsersProfile(state, seenByIdArray))
    const isLoading = useAppSelector((state) => !state.DBBoardUserReducer.itemsLoaded)
    return (
        <>
            {isLoading &&
                <CircularProgress />
            }
            {!isLoading &&
                seenByUsersProfile.map((user, index) => {
                    return (
                        <PersonAvatar
                            key={index}
                            size={32}
                            email={user.email}
                            profileImg={user?.profileImg}
                            lastName={(user?.lastName === 'undefined' || user?.lastName === undefined) ? ' ' : user.lastName}
                            firstName={user?.firstName}
                            toolTip={true}
                        />
                    )
                })
            }
        </>
    )
}