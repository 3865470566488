import { AutomationActions, AutomationConditions, AutomationTrigger } from "@common-models/automation";
import { createSelector } from '@reduxjs/toolkit';

import { automationActionsSelectByQuery, automationConditionsSelectByQuery, automationTriggerSelectOneObjectByQuery, taskGroupsSelectByQuery } from "@common-reducers/DBServiceThunks";

import { getSelectedBoardId } from "@common-reducers/BoardsSelectors";
import { dynamicColumnsListOfIdsTypesAndTitles } from './TaskSelectors';
import { CommonRootState } from "@common/types/common-root-state-type";




const selectBoardId = (state) => getSelectedBoardId(state);

export const getTrigger = createSelector(
  [(state, automationId) => triggersSelector(state, automationId)],
  (triggers: AutomationTrigger) => {
    return triggers;
  }
);

export const triggersSelector = createSelector(
  [
    (state, automationId) =>
      automationTriggerSelectOneObjectByQuery(
        state.DBAutomationTriggerReducer,
        {
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          automationId: automationId
        }
      ),
  ],
  (triggers) => {
    return triggers;
  }
);


export const selectOptionPickersColumns = createSelector(
  dynamicColumnsListOfIdsTypesAndTitles,
  (columnsListOfIds) => {
    const typesToFilter = ['status-option-picker', 'option-picker', 'complexity-picker'];

    return columnsListOfIds
      .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
      .filter(item => typesToFilter.includes(item.type.toString()));
  }
);


export const selectPersonPickerColumns = createSelector(
  dynamicColumnsListOfIdsTypesAndTitles,
  (columnsListOfIds) => {
    const typesToFilter = ['person-picker'];
    return columnsListOfIds
      .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
      .filter(item => typesToFilter.includes(item.type.toString()));
  }
);

export const selectNumberColumns = createSelector(
  dynamicColumnsListOfIdsTypesAndTitles,
  (columnsListOfIds) => {
    const typesToFilter = ['number'];
    return columnsListOfIds
      .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
      .filter(item => typesToFilter.includes(item.type.toString()));
  }
);

export const selectDatePickerColumns = createSelector(
  dynamicColumnsListOfIdsTypesAndTitles,
  (columnsListOfIds) => {
    const typesToFilter = ['datepicker', 'timeline-picker'];
    return columnsListOfIds
      .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
      .filter(item => typesToFilter.includes(item.type.toString()));
  }
);
export const selectAllColumns = createSelector(
  dynamicColumnsListOfIdsTypesAndTitles,
  (columnsListOfIds) => {
    return columnsListOfIds
      .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
  }
);


export const getColumnsByType = (boardId: any, typesToFilter: string[]) => {
  return createSelector(
    (state: any) => dynamicColumnsListOfIdsTypesAndTitles(state, boardId),
    (columnsListOfIds) => {
      if (typesToFilter.length === 0) {
        return columnsListOfIds
          .map(item => ({ id: item[0], type: item[1], name: item[2] as string }));
      } else {
        return columnsListOfIds
          .map(item => ({ id: item[0], type: item[1], name: item[2] as string }))
          .filter(item => typesToFilter.includes(item.type.toString()));
      }
    }
  );
}
export const getConditions = createSelector(
  [(state, automationId) => conditionsSelector(state, automationId)],
  (conditions: AutomationConditions[]) => {
    return conditions;
  }
);

export const conditionsSelector = createSelector(
  [
    (state, automationId) =>
      automationConditionsSelectByQuery(
        state.DBAutomationConditionsReducer,
        {
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          automationId: automationId
        }
      ),
  ],
  (conditions) => {
    return conditions;
  }
);

export const selectActionsLength = createSelector(
  (state: CommonRootState) => state.AutomationReducer.actions,
  (actions) => actions.length
);

export const getActions = createSelector(
  [(state, automationId) => actionsSelector(state, automationId)],
  (actions: AutomationActions[]) => {
    return actions;
  }
);


export const actionsSelector = createSelector(
  [
    (state, automationId) =>
      automationActionsSelectByQuery(
        state.DBAutomationActionsReducer,
        {
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          automationId: automationId
        }
      ),
  ],
  (actions) => {
    return actions;
  }
);



const selectTaskGroups = createSelector(
  [selectBoardId, (state) => state.DBTaskgroupsReducer],
  (boardId, DBTaskgroupsReducer) => taskGroupsSelectByQuery(DBTaskgroupsReducer,
    {
      boardId: boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }]
    })
);

export const selectMappedTaskGroups = createSelector(
  selectTaskGroups,
  (groups) => groups.map(group => {
    const { name, ...rest } = group;
    return { label: name, ...rest };
  })
);