
import { CellProps } from "../cell-interface";
import classes from "./last_updated_cell.module.scss";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import moment from "moment";
import {
  tasksSelectors
} from "@common-reducers/DBServiceThunks";
import { Tooltip } from "@mui/material";


export default function LastUpdatedCell({ taskId }: CellProps) {
  const task = useAppSelector((state) =>
    tasksSelectors.selectById(state, taskId)
  );
  const fullDate = moment(task?.updatedAt).format('DD/MM/YYYY HH:mm:ss');
  const formattedDate = moment(task?.updatedAt).isSame(new Date(), 'year') ? moment(task?.updatedAt).format("MMM DD HH:mm") : moment(task?.updatedAt).format("MMM DD, YYYY HH:mm");
  return (
    <div className={classes.createdCell} style={{alignSelf: 'center'}}>
      <Tooltip arrow placement="top" disableInteractive title={fullDate}>
        <span>
          {formattedDate}
        </span>
      </Tooltip>
    </div>
  );
}
